<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" :stroke-width="stoke && stroke.width?stroke.width:'1.5'" :stroke="stroke && stroke.color?stroke.color:'currentColor'" class="w-6 h-6">
        <path 
        :stroke-linecap="stroke && stroke.linecap?stroke.linecap:'round'" 
        :stroke-linejoin="stroke && stroke.linejoin?stroke.linejoin:'round'"
        d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
    </svg>
</template>
<script>
    export default{
        name: 'UserIcon',
        props: {
            stroke: Object
        }        
    }
</script>
