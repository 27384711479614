<template>
    <div @mousemove="idleReset" @click="idleReset" @mousedown="idleReset" @scroll="idleReset" @keypress="idleReset"
        @load="idleReset">
        <div class="fullscreen-wrapper">
            <div class="topbar">
                <nav class="
            relative
            w-full
            flex flex-wrap
            items-center
            justify-between
            py-3
            bg-gray-100
            text-gray-500
            hover:text-gray-700
            focus:text-gray-700
            shadow-lg
            navbar navbar-expand-lg navbar-light
            ">
                    <div class="container-fluid w-full flex flex-wrap items-center justify-between px-6">
                        <button class="
                navbar-toggler
                text-gray-500
                border-0
                hover:shadow-none hover:no-underline
                py-2
                px-2.5
                bg-transparent
                focus:outline-none focus:ring-0 focus:shadow-none focus:no-underline
              " type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" class="w-6"
                                role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path fill="currentColor"
                                    d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z">
                                </path>
                            </svg>
                        </button>
                        <div class="collapse navbar-collapse flex-grow items-center" id="navbarSupportedContent">
                            <a class="
                  flex
                  items-center
                  text-gray-900
                  hover:text-gray-900
                  focus:text-gray-900
                  mt-2
                  lg:mt-0
                  mr-1
                " href="#">
                                <img src="" style="height: 15px" alt="" loading="lazy" />
                            </a>
                            <!-- Left links -->
                            <ul class="navbar-nav flex flex-col pl-0 list-style-none mr-auto">


                            </ul>
                            <!-- Left links -->
                        </div>
                        <!-- Collapsible wrapper -->

                        <!-- Right elements -->
                        <div class="flex items-center relative">
                            <!-- Icon -->
                            <div class="timer">Session : {{ secondsToHms(idleCountdown) }}</div>

                            <div class="dropdown relative">
                                <a class="dropdown-toggle flex items-center hidden-arrow" href="#"
                                    id="dropdownMenuButton2" role="button" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    <profile-img></profile-img>
                                    &nbsp; {{ auth.user.name }}
                                </a>
                                <ul class="
                dropdown-menu
                min-w-max
                absolute
                bg-white
                text-base
                z-50
                float-left
                py-2
                list-none
                text-left
                rounded-lg
                shadow-lg
                mt-1
                hidden
                m-0
                bg-clip-padding
                border-none
                left-auto
                right-0
              " aria-labelledby="dropdownMenuButton2">

                                    <li>
                                        <Link
                                            class="block px-6 py-2 w-full text-left hover:text-white hover:bg-indigo-500"
                                            href="/backend/logout" method="delete" as="button">Logout</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- Right elements -->
                    </div>
                </nav>
            </div>
            <div class="content-page">

                <main-menu class="" />

                <div class="content">
                    <flash-messages />
                    <slot />
                </div>
            </div>

        </div>

        <!-- <Autologout/> -->
    </div>

</template>

<script>
import axios from 'axios';
import { Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Logo from '@/Shared/Logo'
import MainMenu from '@/Shared/MainMenu'
import FlashMessages from '@/Shared/FlashMessages'
import ProfileImg from '@/Shared/ProfileImg'
import Autologout from '@/Shared/AutoLogout'
export default {
    components: {
        FlashMessages,
        Icon,
        Link,
        Logo,
        MainMenu,
        ProfileImg,
        Autologout
    },
    props: {
        auth: Object,
    },
    el: 'body',
    data() {
        return {
            idleTime: 600,
            idleCountdown: null,
            idleTimeout: false,
            UITime: 120,
            UICountdown: null,
            UITimeout: false,
        }
    },
    methods: {
        startIdleCountdown() {
            this.idleCountdown = this.idleTime;
            this.setIdleTimer = setInterval(this.idleTimer, 500);
        },

        idleTimer() {
            this.idleCountdown = this.idleCountdown - 1;
            if (!this.idleCountdown) {
                clearInterval(this.setIdleTimer);
                this.idleTimeout = !this.idleTimeout;
                this.startUICountdown();
            }
        },
        idleReset() {
            if (!this.idleTimeout) {
                clearInterval(this.setIdleTimer);
                this.startIdleCountdown();
            }
        },
        startUICountdown() {
            this.$swal({
                title: 'Your session is about to expire!',
                text: 'Your session will expire in 1 minute, you can continue or end your session',
                icon: 'error',
                showCancelButton: true,
                confirmButtonColor: "#02a11f",
                cancelButtonColor: "#de0202",
                confirmButtonText: "Continue",
                cancelButtonText: "End",
                buttons: {
                    cancel: {
                        text: "End !",
                        value: null,
                        visible: true,
                        className: "#de0202",
                        closeModal: true,
                    },
                    confirm: {
                        text: "Continue .",
                        value: true,
                        visible: true,
                        className: "#02a11f",
                        closeModal: true
                    }
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    this.idleReset();
                    location.reload();
                } else {
                    axios.delete('/backend/logout');
                    location.reload();
                }
            });
            this.UICountdown = this.UITime;
            this.setUITimer = setInterval(this.UITimer, 500);
        },
        UITimer() {
            this.UICountdown = this.UICountdown - 1;
            if (!this.UICountdown) {
                clearInterval(this.setUITimer);
                this.UITimeout = !this.UITimeout;
                this.countdownExpired();
            }
        },
        UIReset() {
            clearInterval(this.setUITimer);                     // reset the UI timer
            this.idleTimeout = !this.idleTimeout;               // Toggle idleTimeout status
            this.startIdleCountdown();                          // start the idle countdown
        },
        countdownExpired() {
            axios.delete('/backend/logout');
            location.reload();
            //window.location = "/logout";
        },
        secondsToHms(d) {
            d = Number(d);
            var h = Math.floor(d / 3600);
            var m = Math.floor(d % 3600 / 60);
            var s = Math.floor(d % 3600 % 60);
            var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " h, ") : "";
            var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " m, ") : "";
            var sDisplay = s > 0 ? s + (s == 1 ? " second" : " s") : "";
            return hDisplay + mDisplay + sDisplay;
        }
    },
    created: function () {
        this.startIdleCountdown();
    }
}
</script>
<style>
.timer {
    padding: 0px 10px;
    font-size: 12px;
}
</style>
