<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
      <div class="flex justify-start mb-8 max-w-3xl">
        <img v-if="municipalities.logo" class="block ml-4 w-8 h-8 rounded-full" :src="municipalities.logo" />
      </div>
      <trashed-message v-if="municipalities.deleted_at" class="mb-6" @restore="restore"> This Municipality has been deleted. </trashed-message>
      <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="update">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.municipality_name" :error="form.errors.municipality_name" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Municipality" />
            <select-input-function v-model="form.geo_region_id" :error="form.errors.geo_region_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Region" :action="loadProvinces"  required="true">
              <option value=null >Select Region</option>
              <option v-for="region in regions" :key="region.id" :value='region.id'>{{ region.region_name }}</option>
            </select-input-function>
            <select-input v-model="form.geo_provience_id" :error="form.errors.geo_provience_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Province"  required="true">
              <option value='' >Select Provience</option>
              <option v-for="province in provinces" :key="province.id" :value='province.id'>{{ province.provience_name }}</option>
            </select-input>
            <text-input v-model="form.code" :error="form.errors.code" class="pb-8 pr-6 w-full lg:w-1/2" label="Code of Municipality" required="true" placeholder="i.e Buluan"/>
            <file-input v-model="form.logo" :error="form.errors.logo" class="pb-8 pr-6 w-full lg:w-1/2" type="file" accept="image/*" label="Logo" />
            <status-toogle-button v-model="form.is_city" :error="form.errors.is_city" label="Is City" class="mt-10 pb-8 pr-6 w-full lg:w-1/2" ></status-toogle-button>
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Municipality" class="mt-10 pb-8 pr-6 w-full lg:w-1/2" :checked="statusCheck"></status-toogle-button>
          </div>
          <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
            <button v-if="!municipalities.deleted_at && permission.delete>0" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
            <Button :type="type" :name="head" :url="createUrl" :loading="form.processing"></Button>
          </div>
        </form>
      </div>
    </div>
  </template>

  <script>
  import axios from 'axios';
  import { ref, toRefs, inject } from 'vue'
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import Icon from '@/Shared/Icon'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import TrashedMessage from '@/Shared/TrashedMessage'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import DeleteIcon from '@/Shared/DeleteIcon'
  import SelectInputFunction from '@/Shared/SelectInputFunction'
  import Button from '@/Shared/Button'
  import FileInput from '@/Shared/FileInput'
  export default {
    components: {
      Head,
      Icon,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      TrashedMessage,
      StatusToogleButton,
      Breadcrumbs,
      DeleteIcon,
      SelectInputFunction,
      Button,
      FileInput
    },
    layout: Layout,
    props: {
      municipalities: Object,
      provinces: Array,
      regions: Array,
      permission: Object,
    },
    remember: 'form',
    setup(props){
      const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
      const { municipalities, provinces ,regions, permission } = toRefs(props)
      // const regions = ref([])
      const type = ref('edit')
      const title = ref(municipalities.value.municipality_name)
      const head = ref('Municipality')
      const url = ref('/backend/municipalities')
      const createUrl = ref('')
      const swal = inject('$swal')
      const elm = ref(this)
      const form = useForm({
        municipality_name: municipalities.value.municipality_name,
        geo_region_id: municipalities.value.geo_region_id,
        geo_provience_id: municipalities.value.geo_provience_id,
        code: municipalities.value.code,
        status: municipalities.value.status,
        is_city: municipalities.value.is_city,
        logo:null,
      })
      const update = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}/${municipalities.value.id}`, {forceFormData: true,})
              title.value = form.municipality_name
              Swal.fire(
                'Updated!',
                'Your infomation has been Updated.',
                'success'
              )
            }
          });
      }

      const destroy = () => {
        swal({
            title: 'Are you sure?',
            text: "Do you really want to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.delete(`${url.value}/${municipalities.value.id}`)
              Swal.fire(
                'Deleted!',
                'Your infomation has been deleted.',
                'success'
              )
            }
          });
      }
      const restore = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to restore this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Resore it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.put(`${url.value}/${municipalities.value.id}/restore`)
              Swal.fire(
                'Restored!',
                'Your infomation has been Restored.',
                'success'
              )
            }
          });
      }
      const loadProvinces= async (e) =>{
        if(e.target.value>0){
          let data={
            geo_region_id:e.target.value
          }
          await axios.post(`/${backendPrefix.value}/provinces/activeprovinces`, data)
          .then(function (response) {
              provinces.value = response.data;
          }.bind(this));
        }else{
          provinces.value =[];
        }
      }
      return {
        form,
        regions,
        provinces,
        permission,
        municipalities,
        type,
        title,
        head,
        url,
        createUrl,
        update,
        destroy,
        restore,
        loadProvinces,
        swal
      }
    }
  }
  </script>
