<template>
    <div class="action_block">
        <i v-if="minimize" class="fa fa-plus cursor-pointer" title="Expand" @click="minimize_form_element(false)"></i>
        <i v-else class="fa fa-minus cursor-pointer" title="Minimize" @click="minimize_form_element(true)"></i>
        <i class="fa fa-up-down-left-right drag_cross cursor-move" title="Drag"></i>
        <template v-if="grid_span">
            <div class="relative grid_span_block">
                <i class="fa fa-table-cells-large cursor-pointer" title="Grid Span"></i>
                <div class="absolute grid grid-cols-4 span_list">
                    <span :class="['text-center cursor-pointer',{active:grid_size==n}]" v-for="n in 12" @click="grid_span_modify(n)">{{ n }}</span>
                </div>
            </div>
        </template>
        <i class="fa fa-clone cursor-pointer" @click="clone_form_element" title="Clone"></i>
        <i class="fa fa-trash-alt cursor-pointer" @click="remove_form_element" title="Remove"></i>
    </div>
</template>
<script setup>
    const emit = defineEmits(['minimizeFormElement','removeFormElement','cloneFormElement','gridSpanModify'])
    const props = defineProps({
        minimize: Boolean,
        grid_span: Boolean,
        grid_size: Number        
    })
    const minimize_form_element = (status) => {
        emit('minimizeFormElement', status)
    }
    const remove_form_element = () => {
        emit('removeFormElement')
    }
    const clone_form_element = () => {
        emit('cloneFormElement')
    }
    const grid_span_modify = (size) => {
        emit('gridSpanModify', size)
    }
</script>
<style lang="scss" scoped>
    $item_width: 22;
    .action_block{
        position: absolute;
        display: none;
        right: 15px; top: -1px;
        background-color: #f7f7f7;
        z-index: 1;
        
        & > i,
        & > * > i{
            font-size: 8px;
            background-color: #39bdff;
            color: #fff;
            width: #{$item_width}px;
            height: #{$item_width - 2}px;
            line-height: #{$item_width - 2}px;
            border-left: 1px solid #fff;
            text-align: center;
        }

        & > .grid_span_block{
            & > .span_list{
                display: none;
            }
            &:hover{
                & > .span_list{
                    display: grid;
                    width: #{$item_width * 4}px;
                    left: -#{$item_width}px;

                    & > span{
                        font-size: 8px;
                        background-color: #39bdff;
                        color: #fff;
                        height: #{$item_width - 2}px;
                        line-height: #{$item_width - 2}px;
                        border-left: 1px solid #fff;
                        border-top: 1px solid #fff;
                        &.active{
                            background-color: #CD0000;
                        }
                    }
                }
            }
        }
    }
</style>