<template>
    <div class="geo_municipality_block">        
        <!-- <pre>{{ GeoMunicipalities }}</pre> -->
        <!-- {{ backendPrefix }} -->
        <div v-if="(GeoMunicipalities.length>0)" class="label_tree">
            <div class="flex items-center gap-2 geo_label">
                <i v-if="municipality_tree_on" class="fa fa-minus" @click="tree_on_status(false)"></i>
                <i v-else class="fa fa-plus" @click="tree_on_status(true)"></i>
                <span>Municipality</span>
            </div>
            <div :class="['geo_tree',{active:municipality_tree_on}]">
                <div v-for="municipality in GeoMunicipalities">
                    <div :class="['flex','items-center','gap-2','input_block',{active:cur_office_layer_id==4 &&sel_municipalities && sel_municipalities[municipality.id]}]" @click="sel_municipality(cur_office_layer_id==4?municipality.id:'')">
                        <span v-if="(cur_office_layer_id>4)" class="square_block disable">
                            <i class="fa fa-cog check"></i>
                        </span>
                        <span v-else class="square_block">
                            <i class="fa fa-check check"></i>
                            <i class="fa fa-times remove"></i>
                        </span>
                        <div>{{ municipality.municipality_name }}</div>
                    </div>
                    <BarangayMapBlock v-if="(cur_office_layer_id>=5)"
                    :municipality_id="municipality.id"
                    :sel_barangays="sel_barangays"
                    @selBarangay="sel_barangay" />
                </div>
            </div>   
        </div>        
        <div v-else class="geo_tree active">
            <div class="flex items-center gap-2 input_block">
                <span class="square_block" @click="load_data">                    
                    <i v-if="preloader" class="fa fa-cog fa-spin show"></i>
                    <i v-else class="fa fa-plus show"></i>
                </span>
                <span v-if="preloader">Municipality Loading...wait</span>
            </div>
        </div>        
    </div>
</template>
<script>
    import axios from "axios"
    import { ref, toRefs, onMounted } from 'vue'
    import BarangayMapBlock from "./BarangayMap"
    export default {
        name: "GeoMunicipalityBlock",
        inject: ['office_layer_id'],
        props: {
            province_id: Number,
            sel_municipalities: Object,
            sel_barangays: Object
        },
        components: {
            BarangayMapBlock
        },
        data(){
            return {
                cur_office_layer_id: this.office_layer_id
            }
        },
        setup(props, { emit }) {
            const { province_id, sel_municipalities, sel_barangays } = toRefs(props)
            const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
            const preloader = ref(false)
            const GeoMunicipalities = ref([])
            const municipality_tree_on = ref(true)

            onMounted( () => {
                municipality_tree_on.value = true
                load_data()
            })

            const tree_on_status = (status) => {
                municipality_tree_on.value = status
            }

            const sel_municipality = (municipality_id) => {
                // //console.log(municipality_id)
                if(municipality_id) emit('selMunicipality', municipality_id)
            }

            const sel_barangay = (barangay_id) => {
                // //console.log('barangay', barangay_id)
                emit('selBarangay', barangay_id)
            }

            const load_data = () => {
                let formData = {
                    geo_provience_id: province_id.value
                }
                preloader.value = true
                axios.post(`/${backendPrefix.value}/municipalities/activemunicipalities`, formData).then( async (response) => {
                    //console.log(response)                   
                    GeoMunicipalities.value = response.data
                    preloader.value = false
                }).catch(error => {
                    preloader.value = false
                    //console.log(error.response.data.error)
                })
            }

            return {
                backendPrefix,
                preloader,
                province_id,
                GeoMunicipalities,
                sel_municipalities,
                sel_barangays,
                municipality_tree_on,
                sel_municipality,
                sel_barangay,
                load_data,
                tree_on_status
            }
        }
    }
</script>
<style lang="scss" scoped>
    $tree_layer_gap_x: 18;
    $tree_layer_gap_y: 16;
    .input_block{
        position: relative;
        height: 30px;
        cursor: pointer;

        & > .square_block{
            display: flex;
            align-items: center;
            justify-content: center;            
            width: 20px; height: 20px;
            border: 1px solid #999;
            border-radius: 5px;

            & > i{
                display: none;
                font-size: 10px;
                
                &.show{
                    display: block;
                }
            }

            &.disable{                
                & > i{
                    display: block;
                }
            }
        }

        &:hover{
            font-weight: 600;
            .square_block{
                & > i.check {
                    display: block;
                }
            }
        }

        &.active{
            color: #006699;
            font-weight: 600;
            .square_block{
                border-color: #006699;
                & > i.check{
                    display: block;
                    color: #006699
                }
            }
            &:hover{
                color: #CD0000;                    
                .square_block{
                    border-color: #CD0000;
                    & > i.check{
                        display: none;
                    }
                    & > i.remove{
                        display: block;
                        color: #CD0000
                    }
                }
            }
        }
    }

    .label_tree{
        position: relative;
        padding-left: #{$tree_layer_gap_x}px;
        margin-left: 10px;
        .geo_label{
            font-size: 14px;
            font-weight: 600;            
            height: 34px;

            &::before{
                position: absolute;
                content: "";
                width: 20px;
                height: 24px;
                border-bottom: 1px solid #999;
                border-left: 1px solid #999;
                border-radius: 0 0 0 5px;
                left: 0px;
                top: -5px;
            }

            & > i{
                position: relative;
                left: 0;
                width: 22px;
                height: 22px;
                border: 1px solid #999;
                background-color: #fff;
                border-radius: 5px;
                line-height: 22px;
                text-align: center;
                font-size: 10px;
                color: #666;
                cursor: pointer;
                z-index: 1;
            }
        }        
    }
    .geo_tree{
        position: relative;
        display: none;
        margin-left: 10px;
        transition: all 0.4s;
        &.active{
            display: block;
        }
        &::before{
            position: absolute;
            content: "";
            width: 5px;
            height: calc(100% - 8px);
            border-left: 1px solid #999;
            border-bottom: 1px solid #999;
            border-radius: 0 0 0 5px;
            left: 0;
            top: -6px;
        }
        & > div{
            position: relative;
            padding-left: #{$tree_layer_gap_x}px;

            &::before{
                position: absolute;
                content: "";
                width: #{$tree_layer_gap_x}px;
                height: #{$tree_layer_gap_y}px;
                border-bottom: 1px solid #999;
                border-left: 1px solid #999;
                border-radius: 0 0 0 5px;
                left: 0;
                top: 0;
            }            
        }
    }
</style>