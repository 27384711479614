<template>
  <div>
    <vue-tags-input
      v-model="tag"
      :tags="tags"
      @tags-changed="update"
      :autocomplete-items="autocompleteItems"
    />
   
    <!-- @tags-changed="newTags => tags = newTags"  -->
  </div>
</template>
<script>
import { ref, toRefs,onMounted, watchEffect } from 'vue'
import axios from 'axios';
import VueTagsInput from '@sipec/vue3-tags-input';

export default {
  components: {
    VueTagsInput,
  },
  props: {
    tag_info:Array,
    editvalue:Array
  },
  setup(props, { emit }){ 
    const { tag_info,editvalue } = toRefs(props)
    const tag=ref('')
    //const tags=tag_info
    const tags=ref([])
    const autocompleteItems=ref([])
    const debounce=ref(null)
    const update =(newTags)=>{
      autocompleteItems.value = []; 
      let data=ref([])     
      tags.value = newTags
      
      data.value=tags._rawValue; 
          
      let newArr = ref([])
      emit('setUpdateTags',newArr.value)
      data.value.forEach(element => {
        console.log(element);    
        newArr.value.push(element)
        emit('setUpdateTags', newArr.value)
      });
    }
    const initItems=()=> {      
      if (tag.value.length < 2) return;
      clearTimeout(debounce.value);
      let formData = { tag_title: tag.value,  limit: 5 }
      debounce.value = setTimeout(() => {
        axios.post('/backend/tags/active', formData).then(response => { 
          console.log(response);         
          autocompleteItems.value = response.data.map(a => {            
            return { text: a.tag_title,id: a.id};
          });
        }).catch(() => console.warn('Oh. Something went wrong'));
      }, 600);
    }
    const setvalue= () =>{
      tags.value=editvalue.value
    }
    return {
      tag,
      tags,
      tag_info,
      autocompleteItems,
      debounce,
      update,
      initItems,
      editvalue,
      setvalue
    }
  },
  watch: {
    'tag': 'initItems'
  },  
  created: function(){
    this.setvalue();
  }
};
</script>
<style>
.vue-tags-input.ti-focus {
  position: relative;
  display: block;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  border-width: 1px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  text-align: left;
  font-family: Cerebri Sans, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  line-height: 1.5;
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity));
}
.ti-input{
  border-radius: 0.25rem;
  border-width: 1px;
  border: 1px solid #ccc;
  padding: 0px  !important;
  flex-wrap: wrap;
  font-family: Cerebri Sans, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}
.ti-tags {
  width: 100%;
  line-height: 24px !important;
  font-family: Cerebri Sans, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}
.ti-new-tag-input-wrapper {  
  padding: 0px 5px !important;
  margin: 0px !important;
  font-size: 16px !important;
  margin-left: 5px;
}
.ti-new-tag-input-wrapper input {
  font-family: Cerebri Sans, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}
li.ti-valid.ti-tag {
  height: 22px;
  line-height: 18px;
  margin-top: 5px;
  
}
</style>