<template>
    <div :class="['form_layout_block',sel_device_id==2?'flex items-center justify-center':'block']">
        <div v-if="sel_device_id==2" class="flex flex-row items-center justify-center mobile_device_block">
            <MobileDeviceBgBlock />
        </div>
        <div :class="[sel_device_id==2?'absolute flex flex-row justify-center mobile_device_frame_block':'relative']">
            <div class="rounded-md">
                <draggable :list="data" group="container_group_block" handle=".drag_cross">
                    <div v-for="(element,elm_index) in data" :class="['block','relative','border','rounded-md','p-3','mb-4','element_layer_block',{active:sel_element_index==elm_index && sel_grid_column_index==-1 && sel_content_item_index==-1}]" :key="elm_index" @click="sel_element_layer($event, elm_index)">
                        <!-- <pre>{{ element }}</pre> -->
                        <div class="flex flex-row items-center gap-1 elm_action_btn_block">
                            <ActionButtons
                            @cloneFormElement="clone_form_element"
                            @minimizeFormElement="minimize_form_element"
                            @removeFormElement="remove_form_element"
                            :minimize="element.minimize?true:false" />
                        </div>
                        <div class="flex flex-row gap-2 font-semibold" v-if="element.minimize">
                            <i class="fa fa-info-circle"></i>
                            <span>{{ element.name }}</span>
                        </div>
                        <!-- <draggable :list="element.column" group="grid_group_block" handle=".drag_cross" :class="['grid','grid-cols-' + (element.column.length>3?3:element.column.length),'relative','gap-3']"> -->
                        <draggable :list="element.column" group="grid_group_block" handle=".drag_cross" :class="['grid','grid-cols-12','relative','gap-3',{hidden:element.minimize}]">
                            <div :class="['block',(sel_device_id==1 && column.size?'col-span-' + column.size:'col-span-' + (column.mobile_size?column.mobile_size:12)),'relative','border','rounded-md','p-3','grid_col_block',{active:sel_element_index==elm_index && sel_grid_column_index==col_index && sel_content_item_index==-1}]" v-for="(column,col_index) in element.column" :key="col_index" @click="sel_grid_col($event, elm_index, col_index)">
                                
                                <div class="flex flex-row items-center gap-1 grid_action_btn_block">
                                    <ActionButtons @cloneFormElement="clone_form_element"
                                    @minimizeFormElement="minimize_form_element" 
                                    @removeFormElement="remove_form_element" 
                                    @gridSpanModify="grid_span_modify"
                                    :minimize="column.minimize?true:false" 
                                    :grid_span="true"
                                    :grid_size="sel_device_id==1?column.size:(column.mobile_size?column.mobile_size:12)" />
                                </div>

                                <div class="flex flex-row gap-2 font-semibold" v-if="column.minimize">
                                    <i class="fa fa-info-circle"></i>
                                    <span>{{ column.name }}</span>
                                </div>
                                
                                <template v-if="column.data.length > 0">
                                    <draggable :list="column.data" group="input_form_group_block" handle=".drag_cross" :class="{hidden:column.minimize}">
                                        <div :class="['block','relative','border','rounded-md','p-3','mb-4','content_layer_block',{active:sel_element_index==elm_index && sel_grid_column_index==col_index && sel_content_item_index==index}]" v-for="(item,index) in column.data" :key="index" @click="sel_input_field_property($event, item.type, elm_index, col_index, index)">
                                            
                                            <div class="flex flex-row items-center gap-1 content_action_btn_block">
                                                <ActionButtons 
                                                @cloneFormElement="clone_form_element"
                                                @minimizeFormElement="minimize_form_element"
                                                @removeFormElement="remove_form_element"
                                                :minimize="item.minimize?true:false" />
                                            </div>

                                            <div class="flex flex-row gap-2 font-semibold" v-if="item.minimize">
                                                <i class="fa fa-info-circle"></i>
                                                <span>{{ item.name }}</span>
                                            </div>

                                            <template v-if="!item.minimize">
                                                <TxtInputFormBlock v-if="item.type=='txt_input'" :data="item" :index="index" />
                                            
                                                <NumberInputFormBlock v-else-if="item.type=='number_input'" :data="item" :index="index"  />

                                                <TextAreaFormBlock v-else-if="item.type=='textarea'" :data="item" :index="index"  />

                                                <SelectBoxFormBlock v-else-if="item.type=='selectbox'" :data="item" :index="index"  />

                                                <CheckboxInputFormBlock v-else-if="item.type=='checkbox'" :data="item" :index="index"  />

                                                <RadioInputFormBlock v-else-if="item.type=='radio'" :data="item" :index="index" />

                                                <DatePickerInputFormBlock v-else-if="item.type=='datepicker'" :data="item" :index="index" />
                                                
                                                <DateRangeInputFormBlock v-else-if="item.type=='daterange'" :data="item" :index="index" />

                                                <TimePickerInputFormBlock v-else-if="item.type=='timepicker'" :data="item" :index="index" />

                                                <SignatureInputFormBlock v-else-if="item.type=='signature'" :data="item" :index="index" />

                                                <GeoInputFormBlock v-else-if="item.type=='geo'" :data="item" :index="index" />

                                                <GenderInputFormBlock v-else-if="item.type=='gender'" :data="item" :index="index" />

                                                <ReligionInputFormBlock v-else-if="item.type=='religion'" :data="item" :index="index" />

                                                <NationalityInputFormBlock v-else-if="item.type=='nationality'" :data="item" :index="index" />

                                                <CountryInputFormBlock v-else-if="item.type=='country'" :data="item" :index="index" />

                                                <DisplayTextBlock v-else-if="item.type=='text'" :data="item" :index="index" />

                                                <DisplayTableBlock v-else-if="item.type=='table'" :data="item" :index="index" @selTableCell="sel_table_cell" />
                                            </template>
                                        </div>
                                    </draggable>
                                    <!-- <div class="flex flex-row items-center justify-center gap-1 border-none text-xs cursor-pointer mt-2" @click="add_content_block(elm_index,col_index)">
                                        <i class="fa fa-plus-circle"></i>
                                        <span>Add New Component</span>
                                    </div> -->
                                </template>
                                <template v-else>                            
                                    <div class="flex flex-row items-center gap-1 h-20 border-dashed bg-gray-100 rounded-md px-4 add_component_btn justify-center cursor-pointer" @click="add_content_block(elm_index,col_index)">
                                        <i class="fa fa-plus-circle"></i>
                                        <span>Add New Component</span>
                                    </div>
                                </template>
                            </div>
                        </draggable>
                        
                        <div :class="['flex flex-row items-center justify-center border-none mt-3 add_col_btn',{hidden:element.minimize}]">
                            <div class="flex flex-row items-center gap-1 w-40 px-5 text-xs cursor-pointer" @click="add_new_grid_column(elm_index)">
                                <i class="fa fa-table-cells"></i>
                                <span>Add Grid Column</span>
                            </div>
                        </div>
                    </div>
                </draggable>
                <div class="flex flex-row items-center justify-center gap-2 h-8 border-dashed rounded-md bg-gray-100 add_element_btn cursor-pointer" @click="add_element_layer">
                    <i class="fa fa-plus-circle"></i>
                    <span>Add New Element</span>
                </div>
            </div>
        </div>
        <!-- <pre>{{ data }}</pre> -->
    </div>
</template>
<script setup>
    import MobileDeviceBgBlock from './Icons/MobileDevice'
    import TxtInputFormBlock from './Forms/TextInput'
    import NumberInputFormBlock from './Forms/NumberInput'
    import TextAreaFormBlock from './Forms/TextArea'
    import SelectBoxFormBlock from './Forms/SelectBox'
    import CheckboxInputFormBlock from './Forms/CheckboxInput'
    import RadioInputFormBlock from './Forms/RadioInput'
    import DatePickerInputFormBlock from './Forms/DatePickerInput'
    import DateRangeInputFormBlock from './Forms/DateRangeInput'
    import TimePickerInputFormBlock from './Forms/TimePickerInput'
    import SignatureInputFormBlock from './Forms/SignatureInput'
    import GeoInputFormBlock from './Forms/GeoInput'
    import GenderInputFormBlock from './Forms/GenderInput'
    import ReligionInputFormBlock from './Forms/ReligionInput'
    import NationalityInputFormBlock from './Forms/NationalityInput'
    import CountryInputFormBlock from './Forms/CountryInput'
    import GridLayoutBlock from './Layouts/GridLayout'
    import DisplayTextBlock from './Displays/Text'
    import DisplayTableBlock from './Displays/Table'
    import ActionButtons from './ActionButtons'
    import { VueDraggableNext as draggable } from 'vue-draggable-next'
    import { ref } from 'vue'
    const emit = defineEmits(['addElementLayer','addNewGridColumn','selFormComponent','addFormElement','minimizeFormElement','removeFormElement','cloneFormElement','selInputFieldProperty','selTableCell'])
    const props = defineProps({
        data: Array,
        sel_device_id: Number
    })
    const sel_element_index = ref(-1)
    const sel_grid_column_index = ref(-1)
    const sel_content_item_index = ref(-1)

    const sel_table_cell = (sel_row_num, sel_column_num, sel_dynamic_content_index) => {
        emit('selTableCell', sel_row_num, sel_column_num, sel_dynamic_content_index)
    }

    const add_content_block = (elm_index,col_index) => {
        emit('selFormComponent', elm_index, col_index)
    }    
    
    const modify_form_element = (data, index) => {
        emit('modifyFormElement', data, index)
    }

    const grid_span_modify = (grid_span) => {
        emit('gridSpanModify', sel_element_index.value, sel_grid_column_index.value, grid_span)
    }

    const minimize_form_element = (status) => {
        emit('minimizeFormElement', sel_element_index.value, sel_grid_column_index.value, sel_content_item_index.value, status)
    }

    const remove_form_element = () => {
        emit('removeFormElement', sel_element_index.value, sel_grid_column_index.value, sel_content_item_index.value)
    }
    
    const clone_form_element = () => {
        emit('cloneFormElement', sel_element_index.value, sel_grid_column_index.value, sel_content_item_index.value)
    }

    const reset_sel_layout = () => {
        sel_element_index.value = -1
        sel_grid_column_index.value = -1
        sel_content_item_index.value = -1
    }
    
    const sel_input_field_property = (e, type, elm_index, col_index, index) => {
        e.stopPropagation()

        // if(sel_content_item_index.value > -1){
        //     reset_sel_layout()
        // }else{
            sel_element_index.value = elm_index
            sel_grid_column_index.value = col_index
            sel_content_item_index.value = index
        // }
        emit('selFormComponent', elm_index, col_index)
        emit('selInputFieldProperty', type, elm_index, col_index, index)
    }

    const sel_grid_col = (e, elm_index, col_index) => {
        e.stopPropagation()
        
        // if(sel_grid_column_index.value > -1){
        //     reset_sel_layout()
        // }else{
            sel_element_index.value = elm_index
            sel_grid_column_index.value = col_index
            sel_content_item_index.value = -1
        // }

        emit('selFormComponent', elm_index, col_index)
        emit('selInputFieldProperty', '', elm_index, col_index, -1)
    }

    const sel_element_layer = (e, elm_index) => {
        e.stopPropagation()
        
        // if(sel_element_index.value > -1){
        //     reset_sel_layout()
        // }else{
            sel_element_index.value = elm_index
            sel_grid_column_index.value = -1
            sel_content_item_index.value = -1
        // }
        
        emit('selFormComponent', elm_index, -1)
        emit('selInputFieldProperty', '', elm_index, -1, -1)
    }

    const add_new_grid_column = (elm_index) => {
        emit('addNewGridColumn', elm_index)
    }

    const add_element_layer = () => {
        emit('addElementLayer')
    }    
</script>
<style lang="scss" scoped>
    .form_layout_block{
        position: relative;
        position: sticky;
        top: 34px;
        border: 1px solid #eee;
        border-top: none;
        padding: 15px;
        border-radius: 0 0 5px 5px;
        
        .mobile_device_block {
            display: block;
            margin: 20px 0;
            width: 460px;
            height: 946px;
            box-shadow: rgba(0, 0, 0, 0.3) 0 0 20px;
            border-radius: 60px;
            backface-visibility: hidden;
        }

        .mobile_device_frame_block {
            top: 120px;
            left: 24px;
            padding: 0 25px;
            width: calc(100% - 50px);
            height: 750px;
            overflow: hidden;

            & > div{
                border: 2px solid #444;
                padding: 10px;
                width: 400px;
                height: calc(100% - 25px);
                overflow-x: hidden;
                overflow-y: hidden;                
                &:hover{
                    overflow-y: auto;
                }
            }
        }

        .element_layer_block{
            &.active{
                border: 1px solid #39bdff;

                & > .elm_action_btn_block{
                    &:deep(.action_block){
                        display: flex;
                    }
                }
            }                        
        
            .grid_col_block{
                &.col-span-1{
                    grid-column: span 1 / span 1;
                }
                &.col-span-2{
                    grid-column: span 2 / span 2;
                }
                &.col-span-3{
                    grid-column: span 3 / span 3;
                }
                &.col-span-4{
                    grid-column: span 4 / span 4;
                }
                &.col-span-5{
                    grid-column: span 5 / span 5;
                }
                &.col-span-6{
                    grid-column: span 6 / span 6;
                }
                &.col-span-7{
                    grid-column: span 7 / span 7;
                }
                &.col-span-8{
                    grid-column: span 8 / span 8;
                }
                &.col-span-9{
                    grid-column: span 9 / span 9;
                }
                &.col-span-10{
                    grid-column: span 10 / span 10;
                }
                &.col-span-11{
                    grid-column: span 11 / span 11;
                }
                &.col-span-12{
                    grid-column: span 12 / span 12;
                }
                &.active{
                    border: 1px solid #39bdff;

                    & > .grid_action_btn_block{
                        &:deep(.action_block){
                            display: flex;
                        }
                    }
                }

                .content_layer_block{
                    user-select: none;
                    &.active{
                        border: 1px solid #39bdff;

                        & > .content_action_btn_block{
                            &:deep(.action_block){
                                display: flex;
                            }
                        }
                    }
                }
            }
        }
        // & > div > div{
        //     position: relative;
        //     border: 1px dashed #ccc;
        //     border-radius: 5px;
        //     padding: 15px;

        //     &.add_col_btn{
        //         border: none;
        //         padding: 5px 15px;                
        //     }

        //     &.selected{
        //         border: 1px solid #39bdff;

        //         &:deep(.action_block){
        //             display: flex;
        //         }

        //         &:first-child {                
        //             &:deep(.action_block){
        //                 top: auto;
        //                 bottom: -35px;
        //             }
        //         }
        //     }            
        // }
    }
</style>