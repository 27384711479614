<template>
    <div class="photo_upload_block">
        <div v-if="getPhoto" class="flex items-center justify-center photo_area">
            <img :src="getPhoto" />
            <!-- <i v-else class="fa fa-circle-user"></i> -->
            <div v-if="preloader" class="flex items-center gap-1 preloader">
                <i class="fa fa-cog fa-spin"></i>
                <span>Wait</span>
            </div>
        </div>
        <div v-else>
            <label for="photo" class="block text-sm font-medium text-gray-700">Photo</label>
            <div class="mt-1 flex items-center h-[140px]">
                <span class="h-20 w-20 overflow-hidden rounded-full bg-gray-100">
                    <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                </span>
                <button type="button" class="ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"  @click="Photo_popup_status_update(true)">Change</button>
            </div> 
        </div>

        <template v-if="getPhoto && !preloader">
            <div class="flex items-center justify-center bg-green-600 text-white rounded-xl mt-3 gap-1 py-1 text-[11px] cursor-pointer" @click="Photo_popup_status_update(true)">
                <i class="fa fa-repeat"></i>
                <span>Change Photo</span>
            </div>
            <!-- <input type="file" ref="photo_input_form" class="hidden" @change="load_image" accept="image/jpg,image/jpeg,image/png" capture="environment" /> -->
        </template>
        <div v-if="file_size_exceeded" class="flex flex-row items-center gap-2 text-red-700 px-3 py-1 mt-2 bg-red-100 rounded-md err_msg">
            <i class="fa fa-check-circle"></i>
            <span>File size exceeded. Maxium allowed {{ max_file_size }} MB</span>
        </div>
        <div v-else-if="file_type_invalid" class="flex flex-row items-center gap-2 text-red-700 px-3 py-1 mt-2 bg-red-100 rounded-md err_msg">
            <i class="fa fa-check-circle"></i>
            <span>Only allowed JPG, JPEG, PNG</span>
        </div>
        <div v-else class="my-2" align="center">
            <p>JPG, JPEG, PNG up to {{ max_file_size }} MB</p>
        </div>
        <PhotoPopupBlock v-if="Photo_popup_open" @PhotoPopupStatusUpdate="Photo_popup_status_update" @createImage="create_image" />
    </div>
</template>
<script setup>
    import { ref} from 'vue'
    import PhotoPopupBlock from './popup/PhotoPopup'
    const emit = defineEmits(['SetPhoto'])
    const props = defineProps({
        getPhoto: String
    })
    const max_file_size = ref('2')
    const file_size_exceeded = ref(false)    
    const photo_input_form = ref('')
    const set_file_name = ref('')
    const allow_file_ext = ref(['image/jpg', 'image/jpeg', 'image/png'])
    const file_type_invalid = ref(false)
    const Photo_popup_open = ref(false)
    const formData = ref({
        photo: ''
    })

    const Photo_popup_status_update = (status) => {
        Photo_popup_open.value = status
    }

    const upload_photo = () =>{
        photo_input_form.value.click();
    }    
    
    const load_image = (e) => {
        file_size_exceeded.value = false
        file_type_invalid.value = false
        var files = e.target.files || e.dataTransfer.files
        if (!files.length) return
        // //console.log('File info', files[0])
        
        // Image type checking
        if(allow_file_ext.value.indexOf(files[0].type)==-1) {
            file_type_invalid.value = true
            return false
        }

        // File size checking
        let get_file_size = files[0].size;
        if(get_file_size / 1024 > max_file_size.value * 1024) {
            file_size_exceeded.value = true
            return false
        }

        get_file_name(files[0].name)
        CreateImage(files[0])
    }

    const get_file_name = (file) => {
        set_file_name.value = file.split('.').slice(0, -1).join('.')
    }
    
    const CreateImage = (file) => {
        var reader = new FileReader();

        reader.onload = (e) => {
            formData.value.photo = e.target.result
            // getUserData.value.CitizenInfo.photo = e.target.result
            
            // upload_profile_photo()
            emit('SetPhoto', e.target.result)
        }

        reader.readAsDataURL(file)
    }

    const create_image = (value) => {        
        formData.value.photo = value
        // getUserData.value.CitizenInfo.photo = value
        
        // upload_profile_photo()
        emit('SetPhoto', value)
    }

    const preloader = ref(false)
    const upload_profile_photo = async () => {
        // try{
        //     preloader.value = true
        //     const getData = await useNuxtApp().$apiFetch(`/api/citizen/photo-upload/${getUserData.value.uid}`,{
        //         method: 'POST',
        //         body: formData.value,                
        //         headers: {
        //             'Authorization': 'Bearer ' + getUserData.value.token,
        //             'Content-Type': 'application/json',
        //             'X-XSRF-TOKEN': getUserData.value.token
        //         }                
        //     })
            
        //     // //console.log(getData)
            
        //     if(getData.status) {
        //         let obj = getData.data;
        //         obj.uid = getUserData.value.uid
        //         obj.token = getUserData.value.token

        //         let getUserInfoData = JSON.stringify(obj)
        //         user_info.value = getUserInfoData
                
        //         // localStorage.setItem('user_info', JSON.stringify(obj))

        //         setUserData(obj)
        //     }

        //     preloader.value = false

        //     // //console.log('Get ', getData)            
        // } catch(e){
        //     //console.log('Get Message',e.message)
        //     preloader.value = false
        // }
    }
</script>
<style lang="scss" scoped>
.photo_upload_block{
    & > .photo_area{
        position: relative;
        width: 175px; height: 175px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 50%;
        cursor: pointer;

        & > .change_photo_btn,
        & > .preloader{
            position: absolute;
            display: none;
            font-size: 8px;
            background-color: #16a34a;
            color: #fff;
            border-radius: 25px;
            padding: 2px 5px;
            z-index: 1;

            &.preloader{
                display: flex;
            }
        }            

        & > i{
            font-size: 48px;
            color: #bac6bd;
        }

        & > img{
            width: 100%; height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }

        &:hover{
            & > .change_photo_btn{
                display: flex;
            }
        }
    }
}
</style>