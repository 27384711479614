<template>
    <div class="profile_edit_content_block">
      <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
      <div class="w-full bg-white rounded-md shadow overflow-hidden edit_form_block">
        <form @submit.prevent="store" autocomplete="off">
          <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 mt-3 p-4">
            <div class="sm:col-span-3">
                <div class="mt-1 flex items-center h-[225px]">
                    <PhotoBlock :getPhoto="form.photo" @SetPhoto="set_photo" />
                </div>
            </div>
            <div class="sm:col-span-3">
                <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Signature</label>
                <div class="mt-1 flex flex-wrap justify-center rounded-md border-2 border-dashed border-gray-300 dark:border-black dark:bg-white dark:text-black px-6 pt-5 pb-6">
                    <div class="space-y-1 text-center">
                        <SignatureBlock :getSignature="form.signature" @SetSignature="set_signature" />
                    </div>
                </div>
            </div>
            <text-input v-model="form.first_name" :error="form.errors.first_name" class="sm:col-span-2" label="First Name" required="true"  placeholder="e.g. MOHAMMAD"/>
            <text-input v-model="form.middle_name" :error="form.errors.middle_name" class="sm:col-span-2" label="Middle Name" placeholder="e.g. Kabir"/>
            <text-input v-model="form.last_name" :error="form.errors.last_name" class="sm:col-span-2" label="Last Name" required="true"  placeholder="e.g. Hasan"/>
            <text-input v-model="form.surfix" :error="form.errors.surfix" class="sm:col-span-2" label="Suffix" placeholder="i.e Jr" />

            <!-- <text-input v-model="form.email" :error="form.errors.email" class="sm:col-span-2" label="Email" placeholder="e.g. abc@gmail.com"/> -->
            <text-input v-model="form.dob" :error="form.errors.dob" class="sm:col-span-2" label="Date of Birth" type="date" required="true"  placeholder="e.g. 01/01/2000"/>
            <select-input v-model="form.gender" :error="form.errors.gender" class="sm:col-span-2" label="Sex"  required="true" >
                <option value='' >Choose One</option>
                <option v-for="gender in gender" :key="gender.id" :value='gender.id'>{{ gender.gender_name }}</option>
            </select-input>

            <text-input v-model="form.username" :error="form.errors.username"   class="sm:col-span-2" label="Username"  placeholder="e.g. XYZ123123" @keyup="check_username" required="true" autocomplete="off" />
            <div :class="['input_block','sm:col-span-2','password_block',{err_msg:err_msg}]">
                <label  class="block text-sm font-medium text-gray-700 dark:text-gray-400">New Password</label>

                <div class="mt-1 relative">
                    <input class="form-input" :class="{ err_msg:err_msg }" v-if="password_open" v-model="form.password" type="input" placeholder="i.e. password#123" @keyup="check_valid_password" required autocomplete="false"/>
                    <input class="form-input" :class="{ err_msg:err_msg }" v-else type="password" v-model="form.password" placeholder="i.e. password#123"  @keyup="check_valid_password" required autocomplete="false"/>
                    <i :class="[ 'absolute top-[32px] right-3', 'fa','fa-eye',{active:password_open}]" @click="password_view_status(false)"></i>
                    <i :class="['absolute top-[32px] right-3','fa','fa-eye-slash',{active:!password_open}]" @click="password_view_status(true)"></i>
                    <div v-if="form_data_error.require_password_len.status==true" class="form-error">{{ form_data_error.require_password_len.msg }}</div>
                    <div v-if=" form_data_error.require_password_type.status==true" class="form-error">{{ form_data_error.require_password_type.msg }}</div>
                </div>
            </div>
            <div :class="['input_block','sm:col-span-2','confirm_password_block',{err_msg:err_msg}]">
                <label  class="block text-sm font-medium text-gray-700 dark:text-gray-400">Confirm Password</label>

                <div class="mt-1 relative">
                    <input class="form-input" :class="{ err_msg:err_msg }" v-if="_password_open" v-model="form.con_password" type="input" placeholder="i.e. password#123" @keyup="check_valid_password" required autocomplete="false"/>
                    <input class="form-input" :class="{ err_msg:err_msg }" v-else type="password" v-model="form.con_password" placeholder="i.e. password#123" @keyup="check_valid_password" required autocomplete="false"/>
                    <i :class="['absolute top-[32px] right-3','fa','fa-eye',{active:_password_open}]" @click="_password_view_status(false)"></i>
                    <i :class="['absolute top-[32px] right-3','fa','fa-eye-slash',{active:!_password_open}]" @click="_password_view_status(true)"></i>
                    <div v-if=" form_data_error.con_password.status==true" class="form-error">{{ form_data_error.con_password.msg }}</div>
                </div>
            </div>

            <text-input v-model="form.father_name" :error="form.errors.father_name" class="sm:col-span-2" label="Father's Name"  placeholder="e.g. Abdulllah"/>
            <text-input v-model="form.mother_name" :error="form.errors.mother_name" class="sm:col-span-2" label="Mother's Name"  placeholder="e.g. Amina"/>
            <text-input v-model="form.occupation" :error="form.errors.occupation" class="sm:col-span-2" label="Occupation"  placeholder="e.g. Private Service"/>

            <select-input v-model="form.marital_status" :error="form.errors.marital_status" class="sm:col-span-2" label="Marital Status" required="true" >
                <option value='' >Choose One</option>
                <option v-for="marital_status in marital_statuses" :key="marital_status.id" :value='marital_status.id'>{{ marital_status.marital_statuse_name }}</option>
            </select-input>
            <select-input v-model="form.nationality_id" :error="form.errors.nationality_id" class="sm:col-span-2" label="Nationality" required="true" >
                <option value='' >Choose One</option>
                <option v-for="nationality in nationalities" :key="nationality.id" :value='nationality.id'>{{ nationality.nationality }}</option>
            </select-input>
            <select-input v-model="form.religion_id" :error="form.errors.religion_id" class="sm:col-span-2" label="Religion"  required="true">
                <option value='' >Choose One</option>
                <option v-for="religion in religions" :key="religion.id" :value='religion.id'>{{ religion.name }}</option>
            </select-input>

            <div class="sm:col-span-4">
                <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <select-input v-model="form.vulnerability" class="sm:col-span-3" label="Vulnerability"  >
                        <option value=null >Choose One</option>
                        <option v-for="vulnerability in vulnerabilityOptions" :key="vulnerability" :value='vulnerability'>{{ vulnerability }}</option>
                    </select-input>

                    <template v-if="form.vulnerability=='IP' || form.vulnerability=='PWD'">
                        <text-input v-model="form.vulnerability_specify" class="sm:col-span-3" :label="`Specify of ${form.vulnerability}`" placeholder="e.g. A-23124"/>
                    </template>

                    <select-input v-model="form.govt_issue_id_type" :error="form.errors.govt_issue_id_type" class="sm:col-span-3" label="Govt. Issued ID's"  >
                        <option value=null >Choose One</option>
                        <option v-for="govissuetype in governmentIssuedIdType" :key="govissuetype.id" :value='govissuetype.id'>{{ govissuetype.name }}</option>
                    </select-input>

                    <text-input v-model="form.govt_issue_id_number" :error="form.errors.govt_issue_id_number" class="sm:col-span-3" label="Govt. Issued ID Number"  placeholder="e.g. 122456678898"/>
                </div>
            </div>
            <div class="sm:col-span-2">
                <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Govt. Issued ID </label>
                <div class="mt-1 flex flex-wrap justify-center rounded-md border-2 border-dashed border-gray-300 dark:border-black dark:bg-white dark:text-black px-6 pt-5 pb-6">
                    <div class="space-y-1 text-center">
                        <GovidBlock :getGovid="form.govt_issue_id_image" @SetGovid="set_govid" />
                    </div>
                </div>
            </div>

            <div class="sm:col-span-6">
                <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Present Address</label>
                <div class="mt-1 rounded-md border border-gray-300 p-3">
                    <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div class="sm:col-span-2">
                            <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Country</label>
                            <div class="mt-1">
                                <select v-model="form.pre_country_id" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm" @change="check_per_country">
                                    <option value="">Choose One</option>
                                    <option v-for="country in countries" :key="country.id" :value='country.id'>{{ country.en_short_name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="sm:col-span-4">
                            <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Street Address</label>
                            <div class="mt-1">
                                <input type="text" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm" v-model="form.pre_address" placeholder="">
                            </div>
                        </div>
                        <template v-if="sel_default_pre_country">
                            <div class="sm:col-span-2">
                                <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose Region</label>
                                <div class="mt-1">
                                    <!-- <pre>{{ form }}</pre> -->
                                    <select v-model="form.pre_region_id" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm" @change="load_province(1)">
                                        <option value=null>Choose One</option>
                                        <option v-for="region in regions" :value="region.id">{{ region.region_name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="sm:col-span-2">
                                <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose Province</label>
                                <div class="mt-1">
                                    <!-- <pre>{{ form }}</pre> -->
                                    <select v-model="form.pre_province_id" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm" @change="load_municipality(1)">
                                        <option value=null>Choose One</option>
                                        <option v-for="province in pre_province_list" :value="province.id">{{ province.provience_name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="sm:col-span-2">
                                <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose Municipality</label>
                                <div class="mt-1">
                                    <select v-model="form.pre_municipality_id" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm" @change="load_barangay(1)">
                                        <option value=null>Choose One</option>
                                        <option v-for="municipality in pre_municipality_list" :value="municipality.id">{{ municipality.municipality_name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="sm:col-span-2">
                                <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose Barangay</label>
                                <div class="mt-1">
                                    <select v-model="form.pre_barangay_id" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm">
                                        <option value=null>Choose One</option>
                                        <option v-for="barangay in pre_barangay_list" :value="barangay.id">{{ barangay.barangay_name }}</option>
                                    </select>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="sm:col-span-2">
                                <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">City</label>
                                <div class="mt-1">
                                    <input type="text" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm" v-model="form.pre_city" placeholder=""/>
                                </div>
                            </div>
                            <div class="sm:col-span-2">
                                <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">State/Province</label>
                                <div class="mt-1">
                                    <input type="text" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm" v-model="form.pre_state" placeholder="">
                                </div>
                            </div>
                        </template>
                        <div class="sm:col-span-2">
                            <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Zip/Postal</label>
                            <div class="mt-1">
                                <input type="text" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm" v-model="form.pre_zip" placeholder="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sm:col-span-6">
                <status-toogle-button v-model="form.same_as_pre_address" :error="form.errors.same_as_pre_address" label="Same as Present Address" class="sm:col-span-2 mb-3"></status-toogle-button>
                <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Permanent Address</label>
                <div class="mt-1 rounded-md border border-gray-300 p-3">
                    <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div class="sm:col-span-2">
                            <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Country</label>
                            <div class="mt-1">
                                <select v-model="form.pre_country_id" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm" @change="check_pre_country">
                                    <option value="">Choose One</option>
                                    <option v-for="country in countries" :key="country.id" :value='country.id'>{{ country.en_short_name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="sm:col-span-4">
                            <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Street Address</label>
                            <div class="mt-1">
                                <input type="text" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm" v-model="form.per_address" placeholder="">
                            </div>
                        </div>
                        <template v-if="sel_default_per_country">
                            <div class="sm:col-span-2">
                                <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose Region</label>
                                <div class="mt-1">

                                    <select v-model="form.per_region_id" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm" @change="load_province(2)">
                                        <option value=null>Choose One</option>
                                        <option v-for="region in regions" :value="region.id">{{ region.region_name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="sm:col-span-2">
                                <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose Province</label>
                                <div class="mt-1">
                                    <select v-model="form.per_province_id" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm" @change="load_municipality(2)">
                                        <option value=null>Choose One</option>
                                        <option v-for="province in per_province_list" :value="province.id">{{ province.provience_name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="sm:col-span-2">
                                <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose Municipality</label>
                                <div class="mt-1">
                                    <select v-model="form.per_municipality_id" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm" @change="load_barangay(2)">
                                        <option value=null>Choose One</option>
                                        <option v-for="municipality in per_municipality_list" :value="municipality.id">{{ municipality.municipality_name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="sm:col-span-2">
                                <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose Barangay</label>
                                <div class="mt-1">
                                    <select v-model="form.per_barangay_id" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm">
                                        <option value=null>Choose One</option>
                                        <option v-for="barangay in per_barangay_list" :value="barangay.id">{{ barangay.barangay_name }}</option>
                                    </select>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="sm:col-span-2">
                                <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">City</label>
                                <div class="mt-1">
                                    <input type="text" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm" v-model="form.per_city" placeholder=""/>
                                </div>
                            </div>
                            <div class="sm:col-span-2">
                                <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">State/Province</label>
                                <div class="mt-1">
                                    <input type="text" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm" v-model="form.per_state" placeholder="">
                                </div>
                            </div>
                        </template>
                        <div class="sm:col-span-2">
                            <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Zip/Postal</label>
                            <div class="mt-1">
                                <input type="text" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm" v-model="form.per_zip" placeholder="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <!-- v-if="save_button==true" -->
            <Button :type="type" :name="title" :url="createUrl" :loading="form.processing" :method="resetForm"></Button>
        </form>
      </div>

      <!-- <pre>{{ form }}</pre> -->
    </div>
  </template>

  <script>
  import { ref, toRefs, inject, onMounted,onBeforeMount,onUnmounted , watch } from 'vue'
  import axios from 'axios';
  import { Head, Link,useForm } from '@inertiajs/inertia-vue3'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import SelectInputFunction from '@/Shared/SelectInputFunction'
  import LoadingButton from '@/Shared/LoadingButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Button from '@/Shared/Button'
  import FileInput from '@/Shared/FileInput'
  import PhotoBlock from './Photo';
  import SignatureBlock from './Signature';
  import GovidBlock from './Govid';
  import Multiselect from '@vueform/multiselect'
  export default {
    components: {
      Head,
      Link,
      LoadingButton,
      SelectInput,
      SelectInputFunction,
      TextInput,
      Breadcrumbs,
      StatusToogleButton,
      Button,
      FileInput,
      PhotoBlock,
      SignatureBlock,
      Multiselect,
      GovidBlock
    },
    layout: Layout,
    //remember: 'form',
    props: {
      regions: Array,
      provinces: Array,
      municipality: Array,
      barangay: Array,
      religions: Array,
      marital_statuses: Array,
      governmentIssuedIdType: Array,
      countries: Array,
      gender: Array,
      nationalities: Array,
    },
    setup(props){
        const { regions,provinces,municipality,barangay,religions,marital_statuses,governmentIssuedIdType,country } = toRefs(props)
        const form = useForm({
            first_name:null,
            middle_name: null,
            last_name: null,
            dob:null,
            father_name: null,
            mother_name: null,
            spouse_name: null,
            nationality_id:175,
            occupation: null,
            vulnerability: null,
            vulnerability_specify: null,
            marital_status:'',
            religion_id: '',
            gender: '',
            pre_region_id:  null,
            pre_province_id: null,
            pre_municipality_id:  null,
            pre_barangay_id:  null,
            pre_address: null,
            per_address: null,
            pre_state: null,
            pre_zip: null,
            per_region_id: null,
            per_province_id: null,
            per_municipality_id: null,
            per_barangay_id: null,
            per_zip: null,
            per_country_id:175,
            pre_country_id: 175,
            govt_issue_id_type:null,
            govt_issue_id_number:null,
            photo:null,
            signature:null,
            password:null,
            con_password:null,
            username:null,
            pre_city:null,
            per_city:null,
            per_state:null,
            govt_issue_id_image:null,
            same_as_pre_address:null
        })

        // const municipality = ref([])
        const type = ref('create')
        const title = ref('Citizen')
        const url = ref('/backend/citizens')
        const createUrl = ref('')
        const region_list = ref([])
        const user_msg = ref('')
        const user_status = ref(false)
        const save_button = ref(false)

        const form_data_error = ref({
            con_password: {
                status: false,
                msg: 'Password didn\'t match'
            },
            require_password_len: {
                status: false,
                msg: 'Be at least 8 characters in length'
            },
            require_password_type: {
                status: false,
                msg: 'Contain both upper and lowercase alphabetic characters [e.g. A-Z, a-z]'
            }
        })
        const password_len = ref(8)
        const password_open = ref(false)
        const _password_open = ref(false)
        const err_msg = ref(false)

        const vulnerabilityOptions = ref(['Elderly','Minor','IP','PWD','Pregnant'])
        const swal = inject('$swal')
        const store = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            }).then((result) => {
            if (result.isConfirmed) {
                form.post(`${url.value}`, {forceform: true,})
                Swal.fire(
                'Added!',
                'Your infomation has been Added.',
                'success'
                )
            }
            });
        }

        const pre_province_list = ref([])
        const per_province_list = ref([])
        const load_province = async (req_for) => {
            if(req_for==2) per_province_list.value = []
            else pre_province_list.value = []
            let data={
                geo_region_id:req_for==2?form.per_region_id:form.pre_region_id
            }
            await axios.post('../provinces/activeprovinces', data)
            .then(function (response) {
                provinces.value = response.data;
                if(req_for==2) {
                    per_province_list.value = response.data
                    form.per_province_id=null
                    form.per_municipality_id=null
                    form.per_barangay_id=null
                    if(form.per_province_id) load_municipality(2)

                }else{
                    pre_province_list.value = response.data
                    form.pre_province_id=null
                    form.pre_municipality_id=null
                    form.pre_barangay_id=null
                    if(form.pre_province_id) load_municipality(1)
                }
            }.bind(this));

        }
        const pre_municipality_list = ref([])
        const per_municipality_list = ref([])
        const load_municipality = async (req_for) => {
            if(req_for==2) per_municipality_list.value = []
            else pre_municipality_list.value = []
            let data={
                geo_provience_id:req_for==2?form.per_province_id:form.pre_province_id
            }
            await axios.post('../municipalities/activemunicipalities', data)
            .then(function (response) {
                if(req_for==2) {
                    form.per_municipality_id=null
                    form.per_barangay_id=null
                    per_municipality_list.value = response.data
                    if(form.per_municipality_id) load_barangay(2)
                }else{
                    form.pre_municipality_id=null
                    form.pre_barangay_id=null
                    pre_municipality_list.value = response.data
                    if(form.pre_municipality_id) load_barangay(1)
                }
            }.bind(this));
        }

        const pre_barangay_list = ref([])
        const per_barangay_list = ref([])
        const load_barangay = async (req_for) => {
            if(req_for==2) per_barangay_list.value = []
            else pre_barangay_list.value = []
            let data={
                geo_municipality_id:req_for==2?form.per_municipality_id:form.pre_municipality_id
            }
            await axios.post('../barangays/activebarangays', data)
            .then(function (response) {
                if(req_for==2) {
                    form.per_barangay_id=null
                    per_barangay_list.value = response.data
                }else {
                    form.pre_barangay_id=null
                    pre_barangay_list.value = response.data
                }
            }.bind(this));
        }

        const sel_default_pre_country = ref(false)
        const check_pre_country = () => {
            if(form.pre_country_id==175) {
                sel_default_pre_country.value = true
            }else{
                sel_default_pre_country.value = false
                pre_province_list.value = []
            }
        }

        const sel_default_per_country = ref(false)
        const check_per_country = () => {
            if(form.per_country_id==175) {
                sel_default_per_country.value = true
            }else{
                sel_default_per_country.value = false
                per_province_list.value = []
            }
        }

        const check_username = async () => {
                if(form.username!=null){
                    let data={
                        username:form.username
                    }
                    await axios.post('../citizens/user/check', data)
                    .then(function (response) {
                        if(response.data==1){
                            form.errors.username='This username is Not Available';
                        }else if(response.data==0){
                            form.errors.username=null;
                        }
                    }.bind(this));
                }else{
                    form.errors.username=null
                }

        }

        watch( () => form.pre_country_id, () => {
            check_pre_country()
        },{deep: true})

        watch( () => form.pre_country_id, () => {
            check_pre_country()
        },{deep: true})

        watch( () => form.username, () => {
            check_username()
        },{deep: true})

        watch( () => form, () => {
            sameAsPresentAddress()
            saveButtonActive()
        },{deep: true})

        watch( () => form.errors, () => {
           // alert('hi-766756');
            if(Object.keys(form.errors).length==0){
                resetForm()
            }
        },{deep: true})

        onMounted( () => {
          //  alert('hi');
            check_pre_country()
            check_per_country()
            load_province(1)
            load_province(2)
            if(Object.keys(form.errors).length==0){
                resetForm()
            }
        })

        const loadProvinces= async (e) =>{
            if(e.target.value>0){
            let data={
                geo_region_id:e.target.value
            }
            await axios.post('../provinces/activeprovinces', data)
            .then(function (response) {
                provinces.value = response.data;
            }.bind(this));
            }else{
            provinces.value =[];
            }
        }
        const loadMunicipality= async (e) =>{
            if(e.target.value>0){
            let data={
                geo_provience_id:e.target.value
            }
            await axios.post('../municipalities/activemunicipalities', data)
            .then(function (response) {
                municipality.value = response.data;
            }.bind(this));
            }else{
            municipality.value =[];
            }
        }
        const password_view_status = (status) => {
            password_open.value = status
        }

        const _password_view_status = (status) => {
            _password_open.value = status
        }
        const validateUpperLowerCase = (string) => {
            let uppercase = false, lowercase = false
            for(let i=0; i<string.length; i++) {
                if(/[A-Z]/.test(string[i]) && string[i]===string[i].toUpperCase()) lowercase = true
                else if(/[a-z]/.test(string[i]) && string[i]===string[i].toLowerCase()) uppercase = true

                if(uppercase && lowercase) break
            }
            return uppercase && lowercase ? true : false
        }
        const check_valid_password = () => {
            form_data_error.value.require_password_len.status = false
            form_data_error.value.require_password_type.status = false
            form_data_error.value.con_password.status = false
            err_msg.value = false
            if(form.password.length < password_len.value) {
                form_data_error.value.require_password_len.status = true
                if(!err_msg.value) err_msg.value = true
            }else if(!validateUpperLowerCase(form.password)) {
                form_data_error.value.require_password_type.status = true
                if(!err_msg.value) err_msg.value = true
            }else if(form.password!==form.con_password) {
                form_data_error.value.con_password.status = true
                if(!err_msg.value) err_msg.value = true
            }
        }

        const set_photo = (val) => {
            form.photo = val
        }

        const set_signature = (val) => {
            form.signature = val
        }
        const set_govid = (val) => {
            form.govt_issue_id_image = val
        }

        const resetForm=()=>{

            form.first_name=null,
            form.middle_name= null,
            form.last_name= null,
            form.dob=null,
            form.father_name= null,
            form.mother_name= null,
            form.spouse_name= null,
            form.nationality_id=175,
            form.occupation= null,
            form.vulnerability= [],
            form.marital_status=null,
            form.religion_id= null,
            form.gender= null,
            form.pre_region_id=  null,
            form.pre_province_id= null,
            form.pre_municipality_id=  null,
            form.pre_barangay_id=  null,
            form.pre_address= null,
            form.per_address= null,
            form.pre_state= null,
            form.pre_zip= null,
            form.per_region_id= null,
            form.per_province_id= null,
            form.per_municipality_id= null,
            form.per_barangay_id= null,
            form.per_zip= null,
            form.per_country_id=175,
            form.pre_country_id= 175,
            form.govt_issue_id_type=null,
            form.govt_issue_id_number=null,
            form.photo=null,
            form.signature=null,
            form.password=null,
            form.con_password=null,
            form.username=null,
            form.pre_city=null,
            form.per_city=null,
            form.per_state=null,
            form.govt_issue_id_image=null
        }



        onBeforeMount(() => {
            window.onbeforeunload = confirm_leaving
        })
        onUnmounted(() => {
            window.onbeforeunload = null
        })
        const dirty_form = ref(true)
        const confirm_leaving = (evt) => {
            //console.log(evt);
            if (dirty_form) {
                const unsaved_changes_warning = "Your changes will not be saved by this action, Do you want to continue?";
                //console.log(evt.returnValue);
                evt.returnValue = unsaved_changes_warning;
                //resetForm();
                return unsaved_changes_warning;
            };
        }
        const sameAsPresentAddress = () => {
            if(form.same_as_pre_address==1){
                per_province_list.value=pre_province_list.value
                per_municipality_list.value=pre_municipality_list.value
                per_barangay_list.value=pre_barangay_list.value
                form.per_country_id=form.pre_country_id
                form.per_state=form.pre_state
                form.per_region_id=form.pre_region_id
                form.per_province_id=form.pre_province_id
                form.per_municipality_id=form.pre_municipality_id
                form.per_barangay_id=form.pre_barangay_id
                form.per_address=form.pre_address
                form.per_zip=form.pre_zip
            }

        }

        const saveButtonActive = () => {
            if(form.first_name!=null && form.last_name!=null && form.dob!=null && form.username!=null && form.marital_status!=null && form.gender!=null && form.religion_id!=null && form.nationality_id!=null && form.password!=null && err_msg.value==false){
                save_button.value=true
            }else{
                save_button.value=false
            }


        }


      return {
        form,
        regions,
        type,
        title,
        url,
        createUrl,
        store,
        loadProvinces,
        loadMunicipality,
        provinces,
        municipality,
        check_pre_country,
        check_per_country,
        vulnerabilityOptions,
        sel_default_per_country,
        sel_default_pre_country,
        load_province,
        load_municipality,
        load_barangay,
        pre_province_list,
        per_province_list,
        pre_municipality_list,
        per_municipality_list,
        pre_barangay_list,
        per_barangay_list,
        form_data_error,
        password_view_status,
        _password_view_status,
        check_valid_password,
        validateUpperLowerCase,
        password_len,
        password_open,
        _password_open,
        err_msg,
        user_msg,
        user_status,
        check_username,
        set_photo,
        set_signature,
        set_govid,
        resetForm,
        dirty_form,
        confirm_leaving,
        sameAsPresentAddress,
        save_button,
        saveButtonActive
      }
    },

  }
  </script>
  <style lang="scss" scoped>
  $button_height: 36;
  .profile_edit_content_block{
      display: block;
        .edit_form_block{
            background-color: #ffffff80;
            input, textarea, select{
                width: 100%;
                border: 1px solid #ddd;
                padding: 5px 10px;
                border-radius: 5px;
            }
            i{
                display: none;
                width: 30px;
                text-align: center;
                transition: all 0.4s;
                color: #888;
                position: absolute;
                margin-left: 265px !important;
                margin-top: -23px;
                &.active{
                    display: block;
                }
            }
            .err_msg{
                border-color: #CD0000 !important
            }
        }
        }
      .save_btn,.cancel_btn{
          height: #{$button_height}px;
          background-color: #0f8050;
          color: #fff;
          cursor: pointer;
          border-radius: 5px;

          &.cancel_btn{
              border: 1px solid #CD0000;
              color: #CD0000;
              background-color: #fff;
          }
      }


  .dark {
      .profile_edit_content_block{
          input, textarea, select{
              background-color: #222;
          }
      }
  }
  .password_block,.confirm_password_block {
    p {
            align-items: baseline;
            color: #666;
            display: flex;
            gap: 5px;
            font-size: 10px !important;
            span{
                color: gold !important;
                font-size: 10px !important;
            }
        }
  }
</style>
