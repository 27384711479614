<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
        <div class="flex items-center justify-between mb-6">
            <search-filter v-model="form.search" class="mr-4 w-full max-w-xl" @reset="reset">
                <template v-slot:filter>
                    <select v-model="form.trashed" class="form-select w-28">
                        <option :value="null">Filter</option>
                        <option value="with">With Trashed</option>
                        <option value="only">Only Trashed</option>
                    </select>
                </template>
                <template v-slot:page>
                    <select v-model="form.length" class="form-select w-20">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                    </select>
                </template>
                <template v-slot:status>
                    <select v-model="form.status" class="form-select w-32">
                        <option :value="null">Status</option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                    </select>
                </template>
            </search-filter>
            <Button :type="type" :name="title" :url="createUrl" v-if="permission.add > 0"></Button>
        </div>
        <div class="bg-white rounded-md shadow">
            <table class="w-full whitespace-nowrap">
                <thead>
                    <tr class="text-left font-bold">
                        <th class="pb-1 pt-1 px-1" width="20">Sl.</th>
                        <th class="pb-1 pt-1 px-1">Info</th>
                        <th class="pb-1 pt-1 px-1 text-center">Status</th>
                        <th class="pb-1 pt-1 px-1 text-center">Action</th>
                        <th class="pb-1 pt-1 px-1 text-center flex items-center">
                            <input type="checkbox" @click="selectAll" v-model="allSelected">
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(project, index) in projects.data" :key="project.id"
                        class="hover:bg-gray-100 focus-within:bg-gray-100">
                        <td class="border-t pb-1 pt-1 px-3 text-center">
                            {{ index + projects.from }}
                        </td>
                        <td class="border-t pb-1 pt-1 px-1">
                            <div class="flex items-center gap-2">
                                <div>
                                    <p class="bold flex font-14 font-bold items-center">
                                        {{ project.project_name }}
                                    </p>
                                    <p class="flex items-center">
                                        <b>Proposing Ministry: &nbsp;</b> {{ project.proposing_ministry }}
                                    </p>
                                    <p class="flex items-center">
                                        <b>Implementing Ministry: &nbsp;</b> {{ project.implementing_ministry }}
                                    </p>
                                    <p class="flex items-center">
                                        <b>Amount: &nbsp;</b> {{ project.project_amount }},&nbsp;
                                        <b> Remain Amount: &nbsp;</b>
                                        {{ project.remain_project_amount ? project.remain_project_amount : 0 }}
                                    </p>
                                </div>
                            </div>
                            <icon v-if="project.deleted_at" name="trash" class="flex-shrink-0 ml-2 w-3 h-3 fill-gray-400" />
                        </td>
                        <td class="border-t pb-1 pt-1 px-1 text-center">
                            <status-level :status="project.status"></status-level>
                        </td>
                        <td class="w-px border-t text-center">
                            <div class="flex items-center px-2" v-if="permission.edit > 0">
                                <Link class="px-4 py-2" :href="`/backend/projects/${project.id}/edit`" tabindex="-1">
                                <i class="fa fa-edit"></i>
                                </Link>
                                <div @click="openmodal(project.id)" class="flex items-center gap-2 border px-4 py-1 rounded-sm fill-gray-400 organogram_btn" tabindex="0" ><i class="fa-solid fa-dollar-sign"></i><span >Amount</span></div>
                            </div>
                        </td>
                        <td class="w-px border-t text-center"><input type="checkbox" v-model="selectedIds" @click="select" :value="project.id"></td>
                    </tr>
                    <tr v-if="projects.data.length === 0">
                        <td class="px-6 py-4 border-t text-center" colspan="4">No project found.</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <pagination class="mt-6" :links="projects.links" />
        <Amount :project_id="project_id" :amountopen="amountopen"  @UpdateAmountPopup="update_amount_popup" ></Amount>
    </div>
</template>

<script>
import { ref, toRefs, inject } from "vue";
import { Head, Link } from "@inertiajs/inertia-vue3";
import Icon from "@/Shared/Icon";
import pickBy from "lodash/pickBy";
import Layout from "@/Shared/Layout";
import throttle from "lodash/throttle";
import mapValues from "lodash/mapValues";
import Pagination from "@/Shared/Pagination";
import SearchFilter from "@/Shared/SearchFilter";
import Breadcrumbs from "@/Shared/Breadcrumbs";
import StatusLevel from "@/Shared/StatusLevel";
import Button from "@/Shared/Button";
import ToolTip from "@/Shared/ToolTip";
import Amount from "./components/Amount";

export default {
    components: {
        Head,
        Icon,
        Link,
        Pagination,
        SearchFilter,
        Breadcrumbs,
        StatusLevel,
        Button,
        ToolTip,
        Amount
    },
    layout: Layout,
    props: {
        filters: Object,
        permission: Object,
        projects: Object,
    },
    setup(props) {
        const { filters, projects, permission } = toRefs(props);
        const form = ref({
            search: filters.value.search,
            trashed: filters.value.trashed,
            length: filters.value.length ? filters.value.length : 15,
            status: filters.value.status,
        });
        const type = ref("index");
        const title = ref("Projects");
        const url = ref("");
        const createUrl = ref("/backend/projects/create");
        const reset = () => {
            form.value = mapValues(form, () => null);
        };
        const amountopen= ref(false);
        const project_id= ref(0);

        const openmodal = (id) => {
            project_id.value = id
            if(project_id.value>0){
                amountopen.value = true
            }
        }
        const update_amount_popup = (val) => {
            amountopen.value = val
        }
        const selected= ref([])
        const allSelected= ref(false)
        const selectedIds= ref([])

        const selectAll =()=> {
            selectedIds.value = [];
            if(allSelected.value==false){
                projects.value.data.forEach((item, index) => {
                    selectedIds.value.push(item.id);
                })
            }else{
                selectedIds.value=[];
            }
        }
        const select =()=> {
            allSelected.value = false;
        }
        return {
            filters,
            projects,
            form,
            type,
            title,
            url,
            createUrl,
            reset,
            permission,
            amountopen,
            project_id,
            openmodal,
            update_amount_popup,
            selected,
            allSelected,
            selectedIds,
            selectAll,
            select
        };
    },
    watch: {
        form: {
            deep: true,
            handler: throttle(function () {
                this.$inertia.get("/backend/projects", pickBy(this.form), {
                    preserveState: true,
                });
            }, 150),
        },
    },
};
</script>
<style lang="scss" scoped>
.organogram_btn{
  background-color: #152b74;
  color: #fff;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.4s;
  height: 22px;
  margin: 10px;
  font-size: 10px;

  &:hover{
    background-color: #2d6f90;
  }
}
</style>
