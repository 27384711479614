<template>
    <div class="field_property_block">
        <div class="flex flex-col">
            <div class="property_block">
                <label class="flex flex-row items-center border gap-2 px-2" @click="block_collapse('field_property')">                    
                    <i v-if="field_property_collapsed" class="fa fa-chevron-right"></i>
                    <i v-else class="fa fa-chevron-down"></i>
                    <span>Field Properties</span>
                </label>
            </div>
        </div>        
        <div :class="['grid','grid-cols-1','field_properties',{hidden:field_property_collapsed}]">
            <TxtInputProperties v-if="input_type=='txt_input'" @addProperties="add_properties" />
            <NumberInputProperties v-else-if="input_type=='number_input'" @addProperties="add_properties" />
            <TextareaInputProperties v-else-if="input_type=='textarea_input'" @addProperties="add_properties" />
        </div>
    </div>
</template>
<script setup>
    import TxtInputProperties from "./FieldProperties/TxtInput"
    import NumberInputProperties from "./FieldProperties/NumberInput"
    import TextareaInputProperties from "./FieldProperties/TextareaInput"
    import { ref } from 'vue'
    const props = defineProps({
        input_type: String,
        index: Number
    })
    const field_property_collapsed = ref(false)
    const emit = defineEmits(['addFieldProperties'])

    const add_properties = (data) => {
        emit('addFieldProperties', data, props.index)
    }

    const block_collapse = (block) => {
        if(block=='field_property') field_property_collapsed.value = field_property_collapsed.value?false:true
    }
</script>
<style lang="scss" scoped>
.field_property_block{
    position: sticky;
    top: 0;
    .property_block{
        label{
            background-color: #eee;
            height: 30px;
            cursor: pointer;
        }
        .input_items{
            & > div{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 15px; font-size: 10px;
                width: 100%; height: 100px;
                border: 1px solid #ddd;
                cursor: pointer;
                user-select: none;

                & > i{
                    position: absolute;
                    opacity: 0.0;
                    font-size: 20px;
                    color: #fff;
                    z-index: 5;
                    transition: all 0.4s;
                }

                &:hover{
                    background-color: #00000080;
                    & > i{
                        opacity: 1.0;
                    }
                }
            }
        }
    }
}
</style>