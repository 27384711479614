<template>  
  <div :class="$attrs.class">
    <div class="flex ">
      <div class="form-check form-switch">
        <label class="form-check-label inline-block text-gray-800" :for="id">{{ label }}</label>
        <input class="form-check-input appearance-none w-9 ml-1 rounded-full float-right h-5 align-top  bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"  v-bind="{ ...$attrs, class: null }" type="checkbox" role="switch"  :id="id"  :class="{ error: error }" v-model="checked" :checked='checked' @change="onChange">      
      </div>
      <div v-if="error" class="form-error">{{ error }}</div>
    </div>  
  </div>  
</template>
<script>
  import { v4 as uuid } from 'uuid'
  export default {
    name: 'StatusToogleButton',
    inheritAttrs: false,
    props: {
      id: {
        type: String,
        default() {
          return `text-input-${uuid()}`
        },
      },
      type: {
        type: String,
        default: 'checkbox',
      },
      error: String,
      label: String,
      modelValue: [String, Number, Boolean]
    },   
    emits: ['update:modelValue'],
    data() {
      return {
        checked: this.modelValue?true:false,
      }
    },
    watch: {
       checked(checked) {
        if(checked==true){
          this.$emit('update:modelValue', 1)  
        }else{
          this.$emit('update:modelValue', 0)  
        }
       },
    }, 
    methods: {      
      focus() {
        this.$refs.input.focus()
      },      
      onChange(event) {        
        this.$emit("input", event.target.checked);
        if(event.target.checked==true){
          this.$emit('update:modelValue', 1)  
        }else{
          this.$emit('update:modelValue', 0)  
        }
      }
    },
  }
</script>
<style scoped>
  .form-switch {
      padding-left: 0em;
  }
</style>