<template>
  <div class="w-full">
    <fieldset fieldset class="border border-solid border-gray-300 mt-6 p-3 w-full lg:w-1/1">
      <legend class="text-sm">Decision Reason</legend>
      <div class="grid grid-cols-1 items-center">
        <Button :name="'Add'" :icon="'far fa-plus-circle'" :method="addDecisionReason"></Button>
        <template v-for="decisionReason, index in decisionReasons" :key="index">
          <div class="grid grid-cols-7 gap-y-2 gap-x-1 mb-1">
            <div class="col-span-6">
              <div class="grid md:grid-cols-2 items-center">
                <select name="" id="" v-model="decisionReason.decision_type" class="form-input">
                  <option value=null>Select Types</option>
                  <option :value="decision_info.id" v-for="decision_info, index in decision_infos" :key="index">{{decision_info.d_title}}</option>
                </select>
                <input v-model="decisionReason.decision_text" type="text" class="form-input" placeholder="Reason" />
              </div>
            </div>
            <div >
              <span class="cursor-pointer fa-solid fa-trash h-6 ml-3 mt-3 px-2cursor-pointer w-6"
                @click="deleteDecisionReason(index)"></span>
            </div>
          </div>
        </template>
      </div>
    </fieldset>
  </div>
</template>
<script>
import axios from "axios"
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, onMounted } from 'vue'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Button from '@/Shared/Button'
import ContentHeaderSection from "@/Shared/ContentHeaderSection"
import DeleteIcon from '@/Shared/DeleteIcon'
export default {
  components: {
    Head,
    Link,
    SelectInput,
    TextInput,
    StatusToogleButton,
    Button,
    ContentHeaderSection,
    DeleteIcon
  },
  remember: 'form',
  props: {
    decisionReasons: Array,
    decision_infos: Array,
  },
  setup(props) {
    const { decisionReasons,decision_infos } = toRefs(props)
    const addDecisionReason = () => {
      decisionReasons.value.push({
        decision_type: null,
        decision_text: null
      })
    }
    const url = ref('/backend/serviceinfos/project')

    const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
    const deleteDecisionReason = (counter, id = null) => {     
      decisionReasons.value.splice(counter, 1);
    }

    onMounted(() => {
    })
    return {
      decisionReasons,
      addDecisionReason,
      deleteDecisionReason,
      decision_infos,
      url
    }
  },
}
</script>
<style>
.w-6.h-6.text-green-500.cursor-pointer {
  font-size: 17px;
  margin-top: 6px;
}
</style>