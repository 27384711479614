<template>    
    <draggable :list="data" group="input_form_group_block" handle=".drag_cross" class="w-full">
        <div :class="['block','relative','border','rounded-md','p-3','mb-4','content_layer_block',{active:sel_row_num==(row_index + 1) && sel_column_num==(col_index + 1) && sel_dynamic_content_index==index}]" v-for="(item,index) in data" :key="index" @click="sel_input_field_property($event, item.type, index)">
                
            <div class="flex flex-row items-center gap-1 content_action_btn_block">
                <ActionButtons 
                @cloneFormElement="clone_form_element"
                @minimizeFormElement="minimize_form_element"
                @removeFormElement="remove_form_element"
                :minimize="item.minimize?true:false" />
            </div>

            <div class="flex flex-row gap-2 font-semibold" v-if="item.minimize">
                <i class="fa fa-info-circle"></i>
                <span>{{ item.name }}</span>
            </div>

            <template v-if="!item.minimize">
                <TxtInputFormBlock v-if="item.type=='txt_input'" :data="item" :index="index" :preview="preview" />

                <NumberInputFormBlock v-else-if="item.type=='number_input'" :data="item" :index="index" :preview="preview" />

                <TextAreaFormBlock v-else-if="item.type=='textarea'" :data="item" :index="index" :preview="preview" />

                <SelectBoxFormBlock v-else-if="item.type=='selectbox'" :data="item" :index="index" :preview="preview" />

                <CheckboxInputFormBlock v-else-if="item.type=='checkbox'" :data="item" :index="index" :preview="preview" />

                <RadioInputFormBlock v-else-if="item.type=='radio'" :data="item" :index="index" :preview="preview" />

                <DatePickerInputFormBlock v-else-if="item.type=='datepicker'" :data="item" :index="index" :preview="preview" />
                
                <DateRangeInputFormBlock v-else-if="item.type=='daterange'" :data="item" :index="index" :preview="preview" />

                <TimePickerInputFormBlock v-else-if="item.type=='timepicker'" :data="item" :index="index" :preview="preview" />

                <SignatureInputFormBlock v-else-if="item.type=='signature'" :data="item" :index="index" :preview="preview" />

                <GeoInputFormBlock v-else-if="item.type=='geo'" :data="item" :index="index" :preview="preview" />

                <GenderInputFormBlock v-else-if="item.type=='gender'" :data="item" :index="index" :preview="preview" />

                <ReligionInputFormBlock v-else-if="item.type=='religion'" :data="item" :index="index" :preview="preview" />

                <NationalityInputFormBlock v-else-if="item.type=='nationality'" :data="item" :index="index" :preview="preview" />

                <DisplayTextBlock v-else-if="item.type=='text'" :data="item" :index="index" :preview="preview" />
            </template>
        </div>
    </draggable>    
</template>
<script setup>
    import TxtInputFormBlock from '../Forms/TextInput'
    import NumberInputFormBlock from '../Forms/NumberInput'
    import TextAreaFormBlock from '../Forms/TextArea'
    import SelectBoxFormBlock from '../Forms/SelectBox'
    import CheckboxInputFormBlock from '../Forms/CheckboxInput'
    import RadioInputFormBlock from '../Forms/RadioInput'
    import DatePickerInputFormBlock from '../Forms/DatePickerInput'
    import DateRangeInputFormBlock from '../Forms/DateRangeInput'
    import TimePickerInputFormBlock from '../Forms/TimePickerInput'
    import SignatureInputFormBlock from '../Forms/SignatureInput'
    import GeoInputFormBlock from '../Forms/GeoInput'
    import GenderInputFormBlock from '../Forms/GenderInput'
    import ReligionInputFormBlock from '../Forms/ReligionInput'
    import NationalityInputFormBlock from '../Forms/NationalityInput'    
    import DisplayTextBlock from './Text' 
    import ActionButtons from '../ActionButtons'
    import { VueDraggableNext as draggable } from 'vue-draggable-next'
    
    const emit = defineEmits(['removeFormElement','selInputFieldProperty'])
    const props = defineProps({
        data: Array,
        row_index: Number,
        col_index: Number,
        sel_row_num: Number,
        sel_column_num: Number,
        sel_dynamic_content_index: Number,
        preview: Boolean
    })

    const minimize_form_element = (status) => {
        // emit('minimizeFormElement', sel_element_index.value, sel_grid_column_index.value, sel_content_item_index.value, status)
    }

    const remove_form_element = () => {
        emit('removeFormElement')
    }
    
    const clone_form_element = () => {
        // emit('cloneFormElement', sel_element_index.value, sel_grid_column_index.value, sel_content_item_index.value)
    }

    const sel_input_field_property = (e, type, index) => {
        e.stopPropagation()
        emit('selInputFieldProperty', index)
    }
</script>
<style lang="scss" scoped>
    .content_layer_block{
        user-select: none;
        &.active{
            border: 1px solid #39bdff;

            & > .content_action_btn_block{
                &:deep(.action_block){
                    display: flex;
                }
            }
        }
    }
</style>