<template>
    <div class="flex flex-row gap-4 my-5 form_builder_block">
        <!-- <pre>{{ certificateData }}</pre> -->
        <div v-if="!sel_preview_status" class="left_block">
            <FormComponentsBlock @addFormElement="add_form_element" />
        </div>
        <div :class="['content_block',{full:sel_preview_status}]">
            <div class="flex flex-row items-center header_bar_block">
                <div :class="['laptop_device',{active:sel_device_id==1}]" @click="sel_device(1)"><i class="fa fa-laptop" title="Laptop View"></i></div>
                <div :class="['mobile_device',{active:sel_device_id==2}]" @click="sel_device(2)" title="Mobile View"><i class="fa fa-mobile-screen"></i></div>
                <!-- <div class="both_device">
                    <i class="fa fa-mobile-screen _mobile"></i>
                    <i class="fa fa-laptop _laptop"></i>
                </div> -->
                <template v-if="formLayoutData.length > 0">
                    <div :class="['layout_btn ml-auto',{active:!sel_preview_status}]" @click="sel_preview(false)" title="Build Mode"><i class="fa fa-palette"></i></div>
                    <div :class="['preview_btn',{active:sel_preview_status}]" @click="sel_preview(true)" title="Preview Mode"><i class="fa fa-play"></i></div>
                    <!-- <div :class="['save_btn']" @click="submit_data" title="Save Form"><i class="fa fa-save"></i> Save</div> -->
                </template>
            </div>
            <FormPreviewBlock v-if="sel_preview_status" :data="formLayoutData" :sel_device_id="sel_device_id" />
            <FormLayoutBlock v-else :data="formLayoutData"
                @selFormComponent="sel_form_component"
                @selInputFieldProperty="sel_input_field_property"
                @selTableCell="sel_table_cell"
                @modifyFormElement="modify_form_element"                
                @gridSpanModify="grid_span_modify"
                @minimizeFormElement="minimize_form_element"
                @removeFormElement="remove_form_element"
                @cloneFormElement="clone_form_element"
                @addElementLayer="add_element_layer"
                @addNewGridColumn="add_new_grid_column"
                :sel_device_id="sel_device_id"
            />
        </div>
        <div v-if="!sel_preview_status" class="right_block">
            <FieldPropertyBlock
                :elm_index="sel_element_index"
                :elm_col_index="sel_element_col_index"
                :sel_table_row_number="sel_table_row_number"
                :sel_table_column_number="sel_table_column_number"
                :sel_table_content_index="sel_table_content_index"
                :input_type="sel_input_field_type"
                :elm_data="sel_elm_attr"
                :data="sel_input_field_attr"
                :certificate_data="certificateData"
                :qr_data="qrData"
                :assignLayerList="assignLayerList"
                :office_layer_list="office_layer_list"
                :service_id="service_id"
                :wf_form_data="wfLayoutData"
                :service_designation_list="service_designation_list"
                @addFieldProperties="add_field_properties"
                @assignAuthField="assign_auth_field"
                @modifyWfFormData="modify_wf_form_data"
                @modifyCertificateData="modify_certificate_data"
                @modifyQrData="modify_qr_data"
                @removeCertificateData="remove_certificate_data"
                @removeQrData="remove_qr_data"
            />
        </div>

        <FloatingActionBtn v-if="(Object.keys(formLayoutData).length > 0 && service_id && !sel_preview_status)" :loader="preloader" :icon="'fa fa-check'" :position="'fixed'" :message="message" @removeMessage="remove_msg" @dataSubmit="submit_data" />

        <!-- <pre>{{ office_layer_list }}</pre> -->
        <!-- <pre>{{ assignLayerList }}</pre> -->
        <!-- <pre>{{ assign_layer_list }}</pre> -->
        <!-- <pre>{{ service_designation_list }}</pre> -->
    </div>
</template>
<script setup>
    import axios from "axios"
    import { onMounted, ref } from 'vue'
    import FloatingActionBtn from "@/Shared/Buttons/FloatingActionBtn"
    import FormComponentsBlock from './Components/FormComponentsBlock'
    import FormLayoutBlock from './Components/FormLayoutBlock'    
    import FormPreviewBlock from './Components/FormPreviewBlock'
    import FieldPropertyBlock from './Components/FieldPropertyBlock'
    const props = defineProps({
        service_id: Number,
        service_app_form_data: Array,
        certificate_data: Object,
        qr_data: Object,
        assign_layer_list: Array,
        service_wf_form_data: Object,
        service_designation_list: Array
    })
    const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
    const formLayoutData = ref(props.service_app_form_data)
    const certificateData = ref(props.certificate_data?props.certificate_data:{})
    const qrData = ref(props.qr_data?props.qr_data:{})
    const assignLayerList = ref(props.assign_layer_list?props.assign_layer_list:[])
    const wfLayoutData = ref(props.service_wf_form_data)
    const sel_device_id = ref(1)
    const sel_preview_status = ref(props.service_app_form_data.length>0?true:false)
    const sel_element_index = ref(-1)
    const sel_element_col_index = ref(-1)
    const sel_elm_attr = ref({})
    const sel_input_field_type = ref('')
    const sel_input_field_attr = ref({})
    const sel_elm_layout_index = ref('')
    const sel_elm_col_layout_index = ref('')
    const sel_form_element_index = ref('')
    const sel_table_row_number = ref(0)
    const sel_table_column_number = ref(0)
    const sel_table_content_index = ref(-1)

    onMounted( () => {
        get_office_layer_list()
    })

    const sel_form_component = (elm_index, col_index) => {        
        sel_element_index.value = elm_index
        sel_element_col_index.value = col_index

        if(elm_index > -1 && col_index > -1) sel_elm_attr.value = formLayoutData.value[elm_index].column[col_index]
        else if(elm_index > -1) sel_elm_attr.value = formLayoutData.value[elm_index]
    }

    const sel_device = (val) => {
        sel_device_id.value = val
    }

    const sel_preview = (status) => {
        sel_preview_status.value = status
    }

    const sel_table_cell = (sel_row_num, sel_column_num, sel_dynamic_content_index) => {
        sel_table_row_number.value = sel_row_num
        sel_table_column_number.value = sel_column_num
        sel_table_content_index.value = sel_dynamic_content_index
    }

    const sel_input_field_property = (type, elm_index, col_index, index) => {
        // //console.log('Input field',type, elm_index, col_index, index)        
        sel_input_field_type.value = type
        sel_elm_layout_index.value = elm_index
        sel_elm_col_layout_index.value = col_index
        sel_form_element_index.value = index

        if(type && elm_index > -1 && col_index > -1 && index > -1) {
            sel_input_field_attr.value = formLayoutData.value[elm_index].column[col_index].data[index]
            //console.log(type, sel_input_field_attr.value)
            if(type!=='table'){
                sel_table_row_number.value = 0
                sel_table_column_number.value = 0
            }
        }
    }

    const add_field_properties = (data) => {
        // //console.log(data)
        let elm_index = sel_elm_layout_index.value
        let col_index = sel_elm_col_layout_index.value
        let index = sel_form_element_index.value
        // //console.log(elm_index, col_index, index)
        Object.keys(data).forEach(attr => {
            if(elm_index > -1 && col_index > -1 && index > -1) formLayoutData.value[elm_index].column[col_index].data[index][attr] = data[attr]
            else if(elm_index > -1 && col_index > -1) formLayoutData.value[elm_index].column[col_index][attr] = data[attr]
            else if(elm_index > -1) formLayoutData.value[elm_index][attr] = data[attr]
            // //console.log(formLayoutData.value[elm_index])
        })
    }    

    const assign_auth_field = (data) => {
        let elm_index = sel_elm_layout_index.value
        let col_index = sel_elm_col_layout_index.value
        let index = sel_form_element_index.value
        // //console.log(elm_index, col_index, index)
        formLayoutData.value[elm_index].column[col_index].data[index]['auth_field'] = data
    }

    const modify_wf_form_data = (data) => {
        wfLayoutData.value = data
    }

    const modify_form_element = (data,index) => {
        formLayoutData.value[index] = data
    }

    const modify_certificate_data = (index) => {
        certificateData.value[index] = index.replace(/_/g,' ').toUpperCase()
    }

    const remove_certificate_data = (index) => {
        delete certificateData.value[index]
    }

    const modify_qr_data = (index) => {
        qrData.value[index] = index.replace(/_/g,' ').toUpperCase()
    }

    const remove_qr_data = (index) => {
        delete qrData.value[index]
    }

    const grid_span_modify = (elm_index, col_index, grid_span) => {
        if(col_index > -1) {
            if(sel_device_id.value==1) formLayoutData.value[elm_index].column[col_index].size = grid_span
            else formLayoutData.value[elm_index].column[col_index].mobile_size = grid_span
        }
    }

    const minimize_form_element = (elm_index, col_index, index, status) => {
        if(index > -1) formLayoutData.value[elm_index].column[col_index].data[index].minimize = status
        else if(col_index > -1) formLayoutData.value[elm_index].column[col_index].minimize = status
        else formLayoutData.value[elm_index].minimize = status
    }

    const remove_form_element = (elm_index, col_index, index) => {
        // //console.log(elm_index, col_index)
        if(index > -1) formLayoutData.value[elm_index].column[col_index].data.splice(index, 1)
        else if(col_index > -1) formLayoutData.value[elm_index].column.splice(col_index, 1)
        else formLayoutData.value.splice(elm_index, 1)

        sel_input_field_type.value = ''
        sel_form_element_index.value = ''
    }

    const clone_form_element = (elm_index, col_index, index) => {
        if(index > -1) {
            let getData = formLayoutData.value[elm_index].column[col_index].data[index]
            formLayoutData.value[elm_index].column[col_index].data.splice(index, 0, getData)
        }else if(col_index > -1) {
            let getData = formLayoutData.value[elm_index].column[col_index]
            formLayoutData.value[elm_index].column.splice(col_index, 0, getData)
        }else {
            let getData = formLayoutData.value[elm_index]
            formLayoutData.value.splice(elm_index, 0, getData)
        }
    }

    const add_form_element = (obj) => {
        let elm_index = sel_element_index.value
        let col_index = sel_element_col_index.value
        let layout_data = formLayoutData.value[elm_index].column[col_index].data
        // //console.log(elm_index, col_index)
        // //console.log(formLayoutData.value[elm_index].column[col_index])
        //console.log('Layout data', layout_data)
        if(elm_index > -1 && col_index > -1) {
            if(layout_data[0] && layout_data[0].type=='table'){
                let x = sel_table_row_number.value - 1
                let y = sel_table_column_number.value - 1
                //console.log('Layout data',layout_data[0].html[x].cols[y])
                let getCellObject = layout_data[0].html[x].cols[y]
                if(getCellObject.content_type==3) getCellObject.dynamic_content.push(obj)
            } else {
                formLayoutData.value[elm_index].column[col_index].data.push(obj)
            }
        }
    }

    /**
     * Add New Grid Column
     * @param {*} index 
     */
    const add_new_grid_column = (index) => {
        let colObj = {
            name: 'Column',
            size: 6,
            data: []
        }
        formLayoutData.value[index].column.push(colObj)
    }

    const add_element_layer = () => {
        let elmObj = {
            name: 'Element',
            column: [
                {
                    name: 'Column',
                    size: 6,
                    data: []
                }
            ]
        }
        formLayoutData.value.push(elmObj)
    }
    
    const message = ref('')
    const remove_msg = () => {                
        message.value = ''
    }

    const preloader = ref(false)
    const submit_data = () => {
        let formData = {
            service_id: props.service_id,
            app_form_info: formLayoutData.value,
            certificate_col_info: certificateData.value,
            qr_col_info: qrData.value,
            assign_layer_list: assignLayerList.value,
            wf_form_info: wfLayoutData.value
        }
        preloader.value = true
        axios.post(`/${backendPrefix.value}/service-app-form-infos/store`, formData).then( async (response) => {
            //console.log(response)                        
            preloader.value = false
            message.value = response.data.message
        }).catch(error => {                    
            //console.log(error.response.data.error)
            preloader.value = false
        })
    }

    const office_layer_list = ref([])
    const get_office_layer_list = () => {
        axios.post(`/${backendPrefix.value}/officelayers/get-all`).then( async (response) => {
            // //console.log(response)
            office_layer_list.value = response.data.data
        }).catch(error => {                    
            //console.log(error.response.data.error)
        })
    }
</script>
<style lang="scss" scoped>
    $left_side_width: 200;
    $right_side_width: 225;
    .form_builder_block{        
        transition: all 0.4s;
        .left_block{
            width: #{$left_side_width}px;
        }
        .content_block{
            width: calc(100% - #{($left_side_width + $right_side_width + 50)}px);
            transition: all 0.4s;
            &.full{
                width: 100%
            }

            & > .header_bar_block{
                position: sticky;
                top: 0;
                background-color: #f7f7f7;
                border: 1px solid #eee;
                padding: 5px;
                z-index: 1;
                & > div{
                    position: relative;
                    padding: 0 5px;
                    height: 22px;
                    line-height: 22px;                
                    color: #666;
                    background-color: #fff;
                    border: 1px solid #ddd;
                    font-size: 12px;
                    
                    cursor: pointer;

                    &:hover{
                        color: #1890ff;
                        border-color: #1890ff;
                    }

                    &.active{
                        color: #ccc;
                        background: #eee;                    

                        &:hover{
                            color: #ccc;
                            border-color: #ddd;
                            cursor: auto;
                        }
                    }

                    // &.both_device{                    
                    //     background-color: #eee;
                    //     width: 36px;

                    //     & > i{
                    //         top: 6px;
                    //         left: 20%;
                    //         position: absolute;
                    //         background-color: #eee;
                    //         z-index: 1;
                    //         font-size: 10px;
                    //         &._laptop{
                    //             z-index: 2;
                    //         }
                    //     }
                    // }
                }
            }
        }
        .right_block{
            width: #{$right_side_width}px;
        }
    }
</style>