<template>
    <div class="relative business_permit_modal z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true"
        v-if="amountopen == true">
        <div class="fixed inset-0 shadow_gradient bg-opacity-50"></div>
        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div
                    class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all max-w-lg sm:my-8 sm:w-full p-6">
                    <div class="dec_title text-center mb-4">
                        <h3 class="text-xl dark:text-white">
                            Amount
                        </h3>
                    </div>

                    <form method="post" @submit.prevent="update">
                        <text-input required v-model="form.project_amount" :error="form.errors.project_amount" class="sm:col-span-2"
                            label="Project Amount" placeholder="i.e. 100" type="number"
                            oninput="this.value = Math.abs(this.value)" min="0" />

                        <!-- Modal Footer -->
                        <div class="pt-3 sm:flex sm:flex-row-reverse">
                            <button type="submit"
                                class="rounded-md bg-green-700 hover:bg-green-800 py-2 px-3 text-sm font-semibold text-white shadow-sm ml-3">Submit</button>

                            <button type="button"
                                class="rounded-md bg-white py-2 px-3.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 modalClose"
                                @click="update_amount_popup(false)">Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import axios from 'axios';
import { ref, toRefs, inject, onMounted, watch } from 'vue'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import Button from '@/Shared/Button'
import TextInput from '@/Shared/TextInput'
const props = defineProps(['amountopen', 'project_id'])
const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
const edit = ref(false)
const swal = inject('$swal')
const preloader = ref(false)
const message = ref('');
const url = ref('/backend/projects/amount-update')
const emit = defineEmits(['UpdateAmountPopup'])
const update_amount_popup = (status) => {
    emit('UpdateAmountPopup', status)
}

const form = useForm({
    project_id: null ,
    project_amount: null
});

const update = () => {
    form.project_id=props.project_id
    swal({
        title: 'Are you sure?',
        text: "You want to proceed with this action",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
    }).then((result) => {
        if (result.isConfirmed) {
            form.put(`${url.value}/${form.project_id}`)
            swal.fire(
                'Updated!',
                'Your infomation has been Updated.',
                'success'
            )
            emit('UpdateAmountPopup', false)
        }
    });
}

</script>
