<template>
    <div>
        <ContentHeaderSection :title="'Basic Info'" :icon="'fa fa-cogs'" />
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.service_name" :error="form.errors.service_name" class="pb-8 pr-6 w-full lg:w-1/2" label="Service Name"  required="true" placeholder="i.e Service Name"/> 
            <select-input v-model="form.scat_id" :error="form.errors.scat_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Service Category"  required="true">
              <option value=null >Select Service Category</option>
              <option v-for="servicecategory in servicecategories" :key="servicecategory.id" :value='servicecategory.id'>{{ servicecategory.cat_title }}</option>
            </select-input>
            <status-toogle-button v-model="form.has_attachment" :error="form.errors.has_attachment" label="Has Attachment" class="pb-8 pr-6 w-full lg:w-1/1"></status-toogle-button> 
            <textarea-input v-model="form.param" :error="form.errors.param" class="pb-8 pr-6 w-full lg:w-1/1" label="Param"  placeholder="i.e {'Url': 'https://www.xyz.com','usermane':'xyz','password':'*****''}"/>
        </div>
        
    </div>
</template>
<script>
    import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
    import { ref, toRefs, inject } from 'vue'

    export default {
    components: {
      Head,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      StatusToogleButton,
      Button,
      ContentHeaderSection,
    },    
    remember: 'form',
    props: {
      servicecategories: Array,
      serviceinfo_datas: Array,
    },
    setup(props){ 
      const { servicecategories,serviceinfo_datas } = toRefs(props)
      return {
        servicecategories,
        serviceinfo_datas,
        form,
      }
    },
  }
</script>