<template>
    <div>
      {{ form.errors }}
      <Breadcrumbs :type="type" :header="title" :url="url" />
      <div class="max-w-lg bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="store">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.name" :error="form.errors.name" class="pb-8 pr-6 w-full lg:w-1/1" label="Image Title" />
            <file-input v-model="form.media" :error="form.errors.media" class="pb-8 pr-6 w-full lg:w-1/1" type="file" accept="image/*" label="Image" />
                  
          </div>
          <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
            <loading-button :loading="form.processing" class="btn-indigo" type="submit"><i class="fa fa-check-circle"></i> Create New Media</loading-button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import { Head, Link } from '@inertiajs/inertia-vue3'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import FileInput from '@/Shared/FileInput'
  export default {
    components: {
      Head,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      Breadcrumbs,
      StatusToogleButton,
      FileInput
    },
    layout: Layout,
    remember: 'form',
    data() {
      return {
        form: this.$inertia.form({
          name: null,
          media:null,
        }),
        type:"create",
        title:"Media",
        url:'/backend/photo-galleries',
      }
    },
    methods: {
      store() {
        this.form.post('/backend/photo-galleries')
      },
    },
  }
  </script>
  