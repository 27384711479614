<template>
    <div class="block relative w-full h-full">
        <div class="flex items-center justify-center">
            <VueSignaturePad :width="`${width}px`" :height="`${height}px`" :customStyle="{ 'background-color':'#fff','border': '1px solid #ddd','border-radius':'5px'}" :options="{ onBegin, onEnd }" ref="signaturePad" />
        </div>
        <div class="flex flex-wrap items-center justify-center gap-3 text-[10px] my-2">
            <div class="flex flex-row items-center gap-1 cursor-pointer select-none" @click="undo">
                <i class="fas fa-rotate-right"></i>
                <span>Undo</span>
            </div>
            <div class="flex flex-row items-center gap-1 cursor-pointer select-none" @click="clear">
                <i class="fas fa-repeat"></i>
                <span>Clear</span>
            </div>
            <!-- <div class="flex flex-row items-center gap-1 cursor-pointer" @click="okay">
                <i class="fas fa-check"></i>
                <span>Okay</span>
            </div>             -->
        </div>
        <div class="flex items-center h-10 mt-4">
            <button v-if="signature" type="button" class="inline-flex w-full items-center justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 sm:col-start-2 gap-2" @click="submit">
                <i class="fa fa-check"></i>
                <span>Submit</span>
            </button>
        </div>
    </div>
</template>
<script setup> 
    import { ref } from 'vue'
    import { VueSignaturePad } from "vue-signature-pad";
    const emit = defineEmits(['createImage','closePopup'])
    const props = defineProps({
        width: Number,
        height: Number
    })    
    // const options = ref({
    //     penColor: '#444444',
    //     onBegin,
    //     onEnd
    // })
    const signature = ref('')
    const signaturePad = ref('')
    const onBegin = () => {
        signature.value = ''
        // //console.log('=== Begin ===');
    }
    const onEnd = () => {
    //   //console.log('=== End ===');
      okay()
    }
    const clear = () => {        
        signaturePad.value.clearSignature();
        okay()
    }
    const undo = () => {
        signaturePad.value.undoSignature();
        okay()
    }
    const okay = () => {
        const { isEmpty, data } = signaturePad.value.saveSignature();
        //   //console.log(isEmpty);
        //   //console.log(data);
        signature.value = data
    }
    
    const submit = () => {
        emit('createImage', signature.value)
        emit('closePopup')
    }
</script>