<template>
    <div class="form_layout_block">
        <!-- {{ data  }} -->        
        <draggable :list="data" handle=".drag_cross">
            <div :class="['mb-5',{selected:sel_element_index==index}]" v-for="(item,index) in data" :key="index" @click="sel_form_element(index)">            
                <TxtInputFormBlock v-if="item.type=='txt_input'" :data="item" :index="index"  @modifyFormElement="modify_form_element" @removeFormElement="remove_form_element" />

                <NumberInputFormBlock v-else-if="item.type=='number_input'" :data="item" :index="index"  @modifyFormElement="modify_form_element" @removeFormElement="remove_form_element" />

                <TextAreaFormBlock v-else-if="item.type=='textarea'" :data="item" :index="index"  @modifyFormElement="modify_form_element" @removeFormElement="remove_form_element" />
            </div>
        </draggable>
    </div>
</template>
<script>
    import TxtInputFormBlock from './Forms/TextInput'
    import NumberInputFormBlock from './Forms/NumberInput'
    import TextAreaFormBlock from './Forms/TextArea'
    import { VueDraggableNext } from 'vue-draggable-next'
    import { ref, toRefs, defineComponent } from 'vue'
    export default defineComponent({
        components: {
            TxtInputFormBlock,
            NumberInputFormBlock,
            TextAreaFormBlock,
            draggable: VueDraggableNext,
        },
        props: {
            data: Array
        },
        setup(props, { emit }){
            // const emit = defineEmits(['addFormElement'])
            // const props = defineProps({
            //     data: Array
            // })
            const { data } = toRefs(props)
            const sel_element_index = ref('-1')

            const sel_form_element = (index) => {
                sel_element_index.value = index
            }
            
            const modify_form_element = (data, index) => {
                emit('modifyFormElement', data, index)
            }

            const remove_form_element = (index) => {
                emit('removeFormElement', index)
            }

            return {
                data,
                sel_element_index,
                sel_form_element,
                modify_form_element,
                remove_form_element
            }
        }
    })
</script>
<style lang="scss" scoped>
    .form_layout_block{
        display: block;
        border: 1px solid #eee;
        border-top: none;
        padding: 15px;
        border-radius: 0 0 5px 5px;
        & > div > div{
            position: relative;
            border: 1px dashed #ccc;
            border-radius: 5px;
            padding: 15px;            

            &.selected{
                border: 1px solid #39bdff;

                &:deep(.action_block){
                    display: flex;
                }

                &:first-child {                
                    &:deep(.action_block){
                        top: auto;
                        bottom: -35px;
                    }
                }
            }            
        }
    }
</style>