<template>
    <div>        
        <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>        
        <nav class="bg-gray-100 px-5 py-3 rounded-md w-full">
            <strong class="text-3xl font-medium leading-tight">Activity Timeline of</strong> 
        </nav>
        <div class="grid pt-5 pb-5"> 
            <div class="grid grid-cols-4">
                <div class="title bg-gray-300 p-2 ">Name </div>
                <div class="descpriction col-span-3 bg-red-100 p-2 px-3">{{logtraker.user.name}}</div>
            
                <div class="title bg-gray-300 p-2">Designation </div>
                <div class="descpriction col-span-3 bg-red-100 p-2 px-3">{{logtraker.designation?logtraker.designation.designation_name:''}}</div>
           
                <div class="title bg-gray-300 p-2">Office </div>
                <div class="descpriction col-span-3 bg-red-100 p-2 px-3">{{logtraker.office?logtraker.office.office_name:''}}</div>
           
                <div class="title bg-gray-300 p-2">Ministry </div>
                <div class="descpriction col-span-3 bg-red-100 p-2 px-3">{{logtraker.office_ministries?logtraker.office_ministries.ministry_name:''}}</div>
            
                <div class="title bg-gray-300 p-2">Region</div>
                <div class="descpriction col-span-3 bg-red-100 p-2 px-3">{{logtraker.geo_region?logtraker.geo_region.region_name:''}}</div>
           
                <div class="title bg-gray-300 p-2">Provience</div>
                <div class="descpriction col-span-3 bg-red-100 p-2 px-3">{{logtraker.geo_provience?logtraker.geo_provience.provience_name:''}}</div>
            
                <div class="title bg-gray-300 p-2">Municipality</div>
                <div class="descpriction col-span-3 bg-red-100 p-2 px-3">{{logtraker.geo_municipality?logtraker.geo_municipality.municipality_name:''}}</div>
           
                <div class="title bg-gray-300 p-2">Barangay</div>
                <div class="descpriction col-span-3 bg-red-100 p-2 px-3">{{logtraker.geo_barangay?logtraker.geo_barangay.barangay_name:''}}</div>
            </div>
        </div>
        <div class="grid border  border-gray-300 pt-2 mb-5 ">
            <h1 class="px-2 mb-2 mt-0 text-3xl font-medium leading-tight text-primary">
                Information
            </h1>
            <div class="grid grid-cols-4">
           
                <div class="title1 bg-gray-300 p-2 border border-gray-100">Date and Time</div>
                <div class="descpriction1 col-span-3 bg-red-100 p-2 px-3 border border-gray-100">{{format_date(logtraker.log_date)}}</div>
            
                <div class="title1 bg-gray-300 p-2 border border-gray-100">Activity Type</div>
                <div class="descpriction1 col-span-3 bg-red-100 p-2 px-3 border border-gray-100">{{logtraker.log_type}}</div>
            
                <div class="title1 bg-gray-300 p-2 border border-gray-100">Table Name</div>
                <div class="descpriction1 col-span-3 bg-red-100 p-2 px-3 border border-gray-100">{{logtraker.table_name}}</div>
            
            </div>
        </div>
        <div class="grid ">
            <table class="table-auto w-full border-collapse border border-slate-400 ">
                <thead>
                    <tr class="text-left font-bold">
                        <th class="pb-3 pt-3 px-2 border border-orange-200 bg-orange-100">Field</th>
                        <th class="pb-3 pt-3 px-2 border border-orange-200 bg-orange-100"> Data</th>
                        <th v-if="logtraker.log_type=='edit'" class="pb-3 pt-3 px-2 border border-orange-200 bg-orange-100"> Update Data</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,col) in logtraker_data" :key="col">
                        <td class="border-t pb-2 pt-2 px-2 border border-orange-200 bg-orange-100 capitalize">{{ col }}</td>
                        <td class="border-t pb-2 pt-2 px-2 border border-slate-300 overflow-y-auto"><div  v-html="item"></div></td>
                        <td v-if="logtraker.log_type=='edit'" class="border-t pb-2 pt-2 px-2 border border-slate-300 overflow-y-auto"
                        :class="{ active: item!=logtraker_new_data[col] }"><div  v-html=" logtraker_new_data[col]"></div></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>        
</template>
  
<script>
import moment from 'moment';
import { onMounted, ref, toRefs } from 'vue'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import SearchFilter from '@/Shared/SearchFilter'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import StatusLevel from '@/Shared/StatusLevel'
import Button from '@/Shared/Button'
import TextareaInput from '@/Shared/TextareaInput'
export default {
  components: {
    Head,
    Icon,
    Link,
    Pagination,
    SearchFilter,
    Breadcrumbs,
    StatusLevel,
    Button,
    TextareaInput
  },
  layout: Layout,
  props: {
    logtraker:Object,
    att_url:String
  },
  setup(props){
    const { logtraker,att_url } = toRefs(props)
    const type = ref('edit')
    const title = ref(logtraker.value.table_name)
    const head = ref('Audit Log Details')
    const url = ref('/backend/logtrackers')
    const createUrl = ref('')
    const remarks = ref('')
    const permanent_reject = ref(Boolean)
    const logtraker_data =ref({})
    const logtraker_new_data =ref({})
    const format_date =(value)=>{
         if (value) {
           return moment(String(value)).format('DD/MM/YYYY hh:mm a')
          }
      }
    onMounted(() => {
        //alert('asdasd');
        logtraker_data.value=JSON.parse(logtraker.value.data);
        logtraker_new_data.value=JSON.parse(logtraker.value.new_data);

    })
    return {
        logtraker,
        att_url,
        url,
        type ,
        title,
        head,
        createUrl,
        remarks,
        permanent_reject,
        logtraker_data,
        logtraker_new_data,
        format_date
    }
  },
  
  
}
</script>
<style lang="scss" scoped>
  span{
    display: inline-block;    
    & > i{
      font-size: 20px;
      color: #ccc;
    }
    &.success > i{
      color: #009a66;
    }
    &.fail > i{
        color: #c32700;
      }
  }
  .flex.items-center.space-x-2.font-semibold.text-gray-900.leading-8 {
    background: #f1f5f9;
    padding: 5px;
}
 table tr th {   
    font-size: 13px;
    font-weight: bold;
}
.title{
    background: #5492A6;
    margin-bottom: 4px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
}
.title1{
    background: #ECEFF4;
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 400;
}
.descpriction{
    background: #BDE2EB;
    margin-bottom: 4px;
}
.descpriction1{
    background: #f9fafa;
    margin-bottom: 4px;
}
.active {
    background: #ff9664;
    color: #fff;
}
</style>