<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>

      <div class="max-w-screen-xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="update($event)">
          <div class="flex flex-col">
            <div class="shadow border-b border-gray-200 sm:rounded">
              <table class='min-w-full border '>
                  <thead class="border-b">
                      <tr>
                          <th scope="col" class="text-gray-900 pb-2 pt-3 px-3 border-r">Module/Node</th>
                          <th scope="col" class="text-gray-900 pb-2 pt-3 px-3 border-r text-center">View</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr class="border-b" v-for="(rolePermission, index) in permissions" :key="index">
                          <td class="px-4 py-2 whitespace-nowrap text-gray-900 border-r">
                              <strong v-if="rolePermission.pid == 0">{{ rolePermission.nodeName }}</strong>
                              <span v-else>{{ rolePermission.nodeName }}</span>
                          </td >
                          <td class="px-4 py-2 whitespace-nowrap text-gray-900 border-r text-center">
                              <input type='checkbox' :id="'p_view-' + rolePermission.view"
                                  v-model="form.viewPs" :value="rolePermission.view" />
                          </td>
                      </tr>
                  </tbody>
              </table>
            </div>
          </div>
          <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">

            <Button :type="type" :name="head" :url="createUrl" :loading="form.processing"></Button>
          </div>
        </form>
      </div>
    </div>
  </template>

  <script>
  import axios from "axios"
  import { ref, toRefs, inject, onMounted  } from 'vue'
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import Icon from '@/Shared/Icon'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import TrashedMessage from '@/Shared/TrashedMessage'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import DeleteIcon from '@/Shared/DeleteIcon'
  import Button from '@/Shared/Button'
  export default {
    components: {
      Head,
      Icon,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      TrashedMessage,
      StatusToogleButton,
      Breadcrumbs,
      DeleteIcon,
      Button
    },
    layout: Layout,
    props: {
      role: Object,
      permissions:Array
    },
    remember: 'form',
    setup(props){
      const { role, permissions} = toRefs(props)
      const type = ref('edit')
      const title = ref(role.value.role_name)
      const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
      const head = ref('Role Permission')
      const url = ref('/backend/roles')
      const createUrl = ref('')
      const elm = ref(this)
      const swal = inject('$swal')
      const preloader = ref(false)
      const form = useForm({
                viewPs: []
      })

      const update = () => {
        let permission_form = permissions.value.map(perm => {
                perm.role_id = role.value.id;
                if (form.viewPs.includes(perm.view)) {
                    perm.viewP = perm.view;
                } else {
                    perm.viewP = null;
                }
                return perm;
            });
            form.processing  = true
            swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              axios.post(`${url.value}/dashboardpremission/update`, permission_form).then( async (response) => {
                  if(response.data.status=='success'){
                    swal({
                          position: 'top-end',
                          icon: 'success',
                          title: response.data.message,
                          showConfirmButton: false,
                          timer: 1500
                      })
                  }else{
                    swal({
                          position: 'top-end',
                          icon: 'error',
                          title: response.data.message,
                          showConfirmButton: false,
                          timer: 1500
                      })
                  }
                  form.processing = false
              }).catch(error => {
                  swal({
                          position: 'top-end',
                          icon: 'error',
                          title: error,
                          showConfirmButton: false,
                          timer: 1500
                      })
                  //console.log(error.response.data.error)
                  form.processing = false
              })
            }
          });
      }
      const updateData = () => {
        permissions.value.forEach(item => {
            if (item.viewP) { form.viewPs.push(item.view); }
        });
      }
      onMounted(() => {
        updateData()
      })

      return {
        form,
        role,
        permissions,
        type,
        title,
        head,
        url,
        createUrl,
        swal,
        updateData,
        update
      }
    }
  }
  </script>

<style  lang="scss" scoped>
table {
	thead {
		top: 0;
		position: sticky;
    z-index: 999;
		th {
			&:first-child {
				position: sticky;
				left: 0;
			}
		}
	}

	tbody tr,
	thead tr {
		position: relative;
	}

	tbody th {
		position: sticky;
		left: 0;
	}
}

</style>
