<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>      
      <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="store">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <select-input-function v-model="form.geo_region_id" :error="form.errors.geo_region_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Region"  :action="loadProvinces"  required="true">
              <option value=null >Select Region</option>
              <option v-for="region in regions" :key="region.id" :value='region.id'>{{ region.region_name }}</option>
            </select-input-function>
            <select-input-function v-model="form.geo_provience_id" :error="form.errors.geo_provience_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Province" :action="loadMunicipality"  required="true">
              <option value='' >Select Provience</option>
              <option v-for="province in provinces" :key="province.id" :value='province.id'>{{ province.provience_name }}</option>
            </select-input-function> 
            <select-input v-model="form.geo_municipality_id" :error="form.errors.geo_municipality_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Municipality"  required="true">
              <option value='' >Select Municipality</option>
              <option v-for="municipal in municipality" :key="municipal.id" :value='municipal.id'>{{ municipal.municipality_name }}</option>
            </select-input>
            <text-input v-model="form.barangay_name" :error="form.errors.barangay_name" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Barangay" required="true" placeholder="i.e Alamada"/>             
            <text-input v-model="form.code" :error="form.errors.code" class="pb-8 pr-6 w-full lg:w-1/2" label="Code of Barangay"  placeholder="i.e 1001"/>    
            <file-input v-model="form.logo" :error="form.errors.logo" class="pb-8 pr-6 w-full lg:w-1/2" type="file" accept="image/*" label="Logo" />       
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Barangay" class="pb-8 pr-6 w-full lg:w-1/2"></status-toogle-button>       
          </div>
          <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  
  import { ref, toRefs, inject } from 'vue'
  import axios from 'axios';
  import { Head, Link,useForm } from '@inertiajs/inertia-vue3'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import SelectInputFunction from '@/Shared/SelectInputFunction'
  import LoadingButton from '@/Shared/LoadingButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Button from '@/Shared/Button'
  import FileInput from '@/Shared/FileInput'
  export default {
    components: {
      Head,
      Link,
      LoadingButton,
      SelectInput,
      SelectInputFunction,
      TextInput,
      Breadcrumbs,
      StatusToogleButton,
      Button,
      FileInput
    },
    layout: Layout,
    remember: 'form',
    props: {
      regions: Array,
      provinces: Array,
    },
    setup(props){
      const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
      const { regions,provinces } = toRefs(props)
      const form = useForm({
        barangay_name: null,
        geo_region_id: null,
        geo_provience_id: '',
        geo_municipality_id: '',
        code: null,
        status: true,
        logo:null
      }) 
      
      const municipality = ref([])
      const type = ref('create')
      const title = ref('Barangay')
      const url = ref('/backend/barangays')
      const createUrl = ref('')      
      const swal = inject('$swal')
      const store = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}`, {forceFormData: true,})
              Swal.fire(
                'Added!',
                'Your infomation has been Added.',
                'success'
              )
            }
          });
        
      }
      const loadProvinces= async (e) =>{
        if(e.target.value>0){
          let data={
            geo_region_id:e.target.value
          }
          await axios.post(`/${backendPrefix.value}/provinces/activeprovinces`, data)
          .then(function (response) {
              provinces.value = response.data;
          }.bind(this));
        }else{
          provinces.value =[];
        }
      }
      const loadMunicipality= async (e) =>{
        if(e.target.value>0){
          let data={
            geo_provience_id:e.target.value
          }
          await axios.post(`/${backendPrefix.value}/municipalities/activemunicipalities`, data)
          .then(function (response) {
              municipality.value = response.data;
          }.bind(this));
        }else{
          municipality.value =[];
        }
      }
      return {
        form,
        regions,
        type,
        title,
        url,
        createUrl,
        store,
        loadProvinces,
        loadMunicipality,
        provinces,
        municipality
      }
    },   
    
  }
  </script>
  