<template>
  <div>
    <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
    <div class="flex items-center justify-between mb-6">
      <search-filter  v-model="form.search" class="mr-4 w-full max-w-xl" @reset="reset">
        <template v-slot:filter>
          <select v-model="form.trashed" class="form-select  w-28">
            <option :value="null" >Filter</option>
            <option value="with">With Trashed</option>
            <option value="only">Only Trashed</option>
          </select>
        </template>
        <template v-slot:page>
          <select v-model="form.length" class="form-select  w-20">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="75">75</option>
            <option value="100">100</option>
            <option value="150">150</option>
            <option value="200">200</option>
          </select>
        </template>
        <template v-slot:status>
          <select v-model="form.status" class="form-select w-32">
            <option :value="null">Status</option>
            <option value="1">Active</option>
            <option value="2">Inactive</option>
          </select>
        </template>
      </search-filter>
      <!-- <Link class="btn-indigo" href="/backend/offices/create">
        <span>Create</span>
        <span class="hidden md:inline">&nbsp;Office</span>
      </Link> -->
      <Button :type="type" :name="title" :url="createUrl" v-if="permission.add>0"></Button>
    </div>
    <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left font-bold">
            <th class="pb-2 pt-3 px-3">Name</th>
            <th class="pb-2 pt-3 px-3">Logo</th>
            <th width="80" class="pb-2 pt-3 px-3">Status</th>
            <th class="pb-2 pt-3 px-3">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="office in offices.data" :key="office.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
            <td class="border-t px-3 py-1">             
                {{ office.office_name }}
                <icon v-if="office.deleted_at" name="trash" class="flex-shrink-0 ml-2 w-3 h-3 fill-gray-400" />
            </td> 
            <td class="border-t">              
                <img v-if="office.logo" class="block ml-4 w-8 h-8 rounded-full" :src="office.logo" />
            </td>          
            <td class="border-t" align="center">                              
                <status-level :status="office.status"></status-level>
            </td>
            <td class="w-px border-t">
              <div class="flex items-center px-2"  v-if="permission.edit>0">
                <Link :href="`/${backendPrefix}/offices/${office.id}/edit`" tabindex="-1">
                  <!-- <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" /> -->
                  <i class="fa fa-edit"></i>
                </Link>
                <div v-if="(office.layermap && office.layermap.length > 0)" class="flex items-center gap-2 border px-4 py-1 rounded-sm fill-gray-400 organogram_btn" tabindex="0" @click="organogram_config(office)">
                  <i class="fa fa-cogs"></i>
                  <span>Organogram Setup</span>
                </div>
                <div v-else class="flex items-center gap-2 px-4 py-1 rounded-sm fill-gray-400"><small><em>Office Layer Not Selected</em></small></div>
              </div>
            </td>            
          </tr>
          <tr v-if="offices.data.length === 0">
            <td class="px-6 py-4 border-t" colspan="4">No Office Office found.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination class="mt-6" :links="offices.links" />    
  </div>
  <template v-if="organogram_on">
    <div class="black_overlay" @click="remove_popup"></div>
    <div :class="['organogram_setup_block',{active:organogram_on}]">
      <div class="flex items-center justify-center close_btn" @click="remove_popup"><i class="fa fa-times"></i></div>
      <div class="office_info">{{ sel_office_name }}</div>
      <div class="max-w-2xl bg-white rounded-md shadow mt-4">        
        <OrganogramMap :layermap="sel_office_layer_map" :office_name="sel_office_name" :office_id="sel_office_id" />
      </div>
    </div>
  </template>
  <!-- <pre>{{ offices }}</pre> -->
</template>

<script>
  import { ref, toRefs, inject } from 'vue'
  import { Head, Link } from '@inertiajs/inertia-vue3'
  import Icon from '@/Shared/Icon'
  import pickBy from 'lodash/pickBy'
  import Layout from '@/Shared/Layout'
  import throttle from 'lodash/throttle'
  import mapValues from 'lodash/mapValues'
  import Pagination from '@/Shared/Pagination'
  import SearchFilter from '@/Shared/SearchFilter'
  import StatusLevel from '@/Shared/StatusLevel'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import Button from '@/Shared/Button'
  import OrganogramMap from './components/v3/OrganogramMap'
  export default {
    components: {
      Head,
      Icon,
      Link,
      Pagination,
      SearchFilter,
      StatusLevel,
      Breadcrumbs,
      Button,
      OrganogramMap
    },
    layout: Layout,
    props: {
      filters: Object,
      offices: Object,
      permission: Object,
    },
    setup(props){
      const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
      const { filters, offices,permission } = toRefs(props)
      const form = ref({
        search: filters.value.search,
        trashed: filters.value.trashed,
        length: filters.value.length?filters.value.length:15,
        status: filters.value.status,
      })
      const type = ref('index')
      const title = ref('Office')
      const url = ref('')
      const createUrl = ref(`/${backendPrefix.value}/offices/create`)
      const organogram_on = ref(false) 
      const sel_office_id = ref('')
      const sel_office_name = ref('')
      const sel_office_layer_map = ref([])
      
      const organogram_config = (office) => {
        sel_office_id.value = office.id
        sel_office_name.value = office.office_name
        sel_office_layer_map.value = office.layermap
        organogram_on.value = true
        document.getElementsByTagName( 'html' )[0].classList.add('hide-scrollbar-y');
      }

      const remove_popup = () => {
        organogram_on.value = false
        document.getElementsByTagName( 'html' )[0].classList.remove('hide-scrollbar-y');        
      }
      
      const reset = () => {
        form.value = mapValues(form.value, () => null)
      }

      return {
        backendPrefix,
        offices,
        form,
        type,
        title,
        url,
        createUrl,
        form,
        organogram_on,
        sel_office_id,
        sel_office_name,
        sel_office_layer_map,
        remove_popup,
        organogram_config,
        reset,
        permission        
      }
    },    
    watch: {
      form: {
        deep: true,
        handler: throttle(function () {
          this.$inertia.get('/backend/offices', pickBy(this.form), { preserveState: true })
        }, 150),
      },
    },
    
  }
</script>
<style lang="scss" scoped>
  .organogram_setup_block{
    position: fixed;
    min-width: 450px;
    background-color: #fff;
    padding: 25px;
    right: -400px; top: 0; height: 100%;
    overflow-y: auto;
    z-index: 10000;
    transition: all 0.4s;

    &.active{
      right: 0;
    }

    & > .office_info{
      font-size: 14px;
      font-weight: 600;
      height: 46px;
      color: #666;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      border-bottom: 1px solid #ddd;
      justify-content: center;
      border-radius: 15px;
      position: absolute;
      width: calc(100% - 70px);
      left: 40px;
      top: 0;
    }

    & > .close_btn{
      position: sticky;
      margin-left: -25px;
      margin-top: -25px;
      top: -25px;
      width: 40px;
      height: 47px;
      background-color: #00000080;      
      color: #fff;      
      cursor: pointer;
      border-radius: 0 0px 15px 0;
      z-index: 5;
    }
  }
  .organogram_btn{
    background-color: #152b74;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.4s;
    height: 22px;
    margin: 10px;
    font-size: 10px;

    &:hover{
      background-color: #2d6f90;      
    }
  }
</style>