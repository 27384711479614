<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
      <div class="flex items-center justify-between mb-6">
        <search-filter v-model="form.search" class="mr-4 w-full max-w-xl" @reset="reset">
          <template v-slot:filter>
            <select v-model="form.trashed" class="form-select  w-28">
              <option :value="null" >Filter</option>
              <option value="with">With Trashed</option>
              <option value="only">Only Trashed</option>
            </select>
          </template>
          <template v-slot:page>
            <select v-model="form.length" class="form-select  w-20">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
              <option value="150">150</option>
              <option value="200">200</option>
            </select>
          </template>
          <template v-slot:status>
            <select v-model="form.status" class="form-select w-32">
              <option :value="null">Status</option>
              <option value="1">Active</option>
              <option value="2">Inactive</option>
            </select>
          </template>
        </search-filter>
        <Button :type="type" :name="title" :url="createUrl" v-if="permission.add>0"></Button>
      </div>
      <div class="bg-white rounded-md shadow overflow-x-auto">
        <!-- <pre>{{ serviceinfos.data }}</pre> -->
        <table class="w-full whitespace-nowrap">
          <thead>
            <tr class="text-left font-bold">
              <th class="pb-2 pt-3 px-3">Service Name</th>
              <th class="pb-2 pt-3 px-3"></th>
              <th class="pb-2 pt-3 px-3 text-center">Status</th>
              <th class="pb-2 pt-3 px-3">Actions</th>
            </tr>
          </thead>
          <tbody>
            <!-- <pre>{{ serviceinfos.data }}</pre> -->
            <template v-if="serviceinfos.data.length>0">
              <template v-for="service_item in serviceinfos.data">
                <tr :class="['hover:bg-gray-100 focus-within:bg-gray-100',service_item.service_info.length>1?'bg-gray-200':'']">
                  <td class="border-t py-2" >
                    <div class="flex items-center px-3 py-1 focus:text-indigo-500" >
                      {{ service_item.service_name }}
                      <icon v-if="service_item.deleted_at" name="trash" class="flex-shrink-0 ml-2 w-3 h-3 fill-gray-400" />
                    </div>
                  </td>
                  <template v-if="service_item.service_info.length>1">
                    <td class="border-t py-2" colspan="3">
                      <div class="flex items-center px-3 py-1 focus:text-indigo-500" >
                        {{ service_item.service_category }}
                        <icon v-if="service_item.deleted_at" name="trash" class="flex-shrink-0 ml-2 w-3 h-3 fill-gray-400" />
                      </div>
                    </td>
                  </template>
                  <template v-else-if="service_item.service_info.length==1">
                    <td class="border-t">
                      <div class="flex items-center px-3 py-1 focus:text-indigo-500" >
                        {{ service_item.service_info[0].cat_title }}
                        <icon v-if="service_item.service_info[0].deleted_at" name="trash" class="flex-shrink-0 ml-2 w-3 h-3 fill-gray-400" />

                      </div>
                    </td>
                    <td class="border-t">
                      <div class="flex items-center px-3 py-1"  tabindex="-1">
                        <status-level :status="service_item.service_info[0].status"></status-level>
                      </div>
                    </td>
                    <td class="w-px border-t">
                      <div class="flex items-center px-2" v-if="permission.edit>0">
                        <Link class="flex items-center px-4" :href="`/backend/serviceinfos/${service_item.service_info[0].id}/edit`" tabindex="-1">
                          <i class="fa fa-edit"></i>
                        </Link>
                        <div class="flex items-center gap-2 border px-4 py-1 rounded-sm fill-gray-400 organogram_btn" tabindex="0" @click="service_map_config(service_item.service_info[0],service_item.service_name)">
                          <i class="fa fa-cogs"></i>
                          <span>Office Setup</span>
                        </div>
                        <div class="flex items-center gap-2 border px-4 py-1 rounded-sm fill-gray-400 organogram_btn" tabindex="0" @click="service_form_config(service_item.service_info[0],service_item.service_name)">
                          <i class="fa fa-cogs"></i>
                          <span>Form Setup</span>
                        </div>
                      </div>
                    </td>
                  </template>
                  <template v-else>
                    <td>
                      <div class="flex items-center px-3 py-1 focus:text-indigo-500" >
                        <div class="flex items-center px-3 py-1 focus:text-indigo-500" >

                      </div>
                      </div>
                    </td>
                    <td>
                      <div class="flex items-center px-3 py-1"  tabindex="-1">

                      </div>
                    </td>
                    <td class="w-px border-t"></td>
                  </template>
                </tr>
                <template v-if="service_item.service_info.length>1">
                  <tr v-for="service_info in service_item.service_info" class="hover:bg-gray-100 focus-within:bg-gray-100">
                    <td class="border-t ">
                      <div class="flex items-center px-4 py-1 focus:text-indigo-500" >
                        <i class="fa-solid fa-hand-point-right"></i> &nbsp; {{ service_info.service_name }}
                        <icon v-if="service_info.deleted_at" name="trash" class="flex-shrink-0 ml-2 w-3 h-3 fill-gray-400" />
                      </div>
                    </td>
                    <td class="border-t">
                      <div class="flex items-center px-3 py-1 focus:text-indigo-500" >
                        {{ service_info.cat_title }}
                        <icon v-if="service_info.deleted_at" name="trash" class="flex-shrink-0 ml-2 w-3 h-3 fill-gray-400" />
                      </div>
                    </td>
                    <td class="border-t">
                      <div class="flex items-center px-3 py-1"  tabindex="-1">
                        <status-level :status="service_info.status"></status-level>
                      </div>
                    </td>
                    <td class="w-px border-t">
                      <div class="flex items-center px-2" v-if="permission.edit>0">
                        <Link class="flex items-center px-4" :href="`/backend/serviceinfos/${service_info.id}/edit`" tabindex="-1">
                          <i class="fa fa-edit"></i>
                        </Link>
                        <div class="flex items-center gap-2 border px-4 py-1 rounded-sm fill-gray-400 organogram_btn" tabindex="0" @click="service_map_config(service_info,service_item.service_name)">
                          <i class="fa fa-cogs"></i>
                          <span>Office Setup</span>
                        </div>
                        <div class="flex items-center gap-2 border px-4 py-1 rounded-sm fill-gray-400 organogram_btn" tabindex="0" @click="service_form_config(service_info,service_item.service_name)">
                          <i class="fa fa-cogs"></i>
                          <span>Form Setup</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </template>
            </template>
            <tr v-else>
              <td class="px-3 py-1 border-t" colspan="4">No Service Info found.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <pagination class="mt-6" :links="serviceinfos.links" />
    </div>
    <template v-if="service_organogram_on">
      <div class="black_overlay" @click="remove_organogram_popup"></div>
      <div :class="['organogram_setup_block',{active:service_organogram_on}]">
        <div class="flex items-center justify-center close_btn" @click="remove_organogram_popup"><i class="fa fa-times"></i></div>
        <div class="office_info">Service Name : {{ sel_service_name }}</div>
          <div class="grid grid-flow-col gap-3">
            <div>
              <OfficeAssignGrid  :service_name="sel_service_name" :service_id="sel_service_id" :payment_data="sel_service_payment" />
            </div>
          </div>
      </div>
    </template>
    <template v-if="service_form_on">
      <div class="black_overlay" @click="remove_form_popup"></div>
      <div :class="['form_setup_block',{active:service_form_on}]">
        <div class="flex items-center justify-center close_btn" @click="remove_form_popup"><i class="fa fa-times"></i></div>
        <div class="office_info">Service Name : {{ sel_service_name }}</div>
          <div class="grid grid-flow-col gap-3">
            <div>
              <!-- <ServiceFormBlock  :service_name="sel_service_name" :service_id="sel_service_id" /> -->
              <!-- <pre>{{ service_designation_list }}</pre> -->
              <FormBuilderBlock :service_id="sel_service_id" :service_app_form_data="sel_service_app_form" :certificate_data="sel_certificate_data" :qr_data="sel_qr_data"
              :assign_layer_list="sel_assign_layer_list" :service_wf_form_data="sel_service_wf_form"
              :service_designation_list="service_designation_list" />
            </div>
          </div>
      </div>
    </template>

  </template>

  <script>
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import { ref, toRefs, inject } from 'vue'
  import Icon from '@/Shared/Icon'
  import pickBy from 'lodash/pickBy'
  import Layout from '@/Shared/Layout'
  import throttle from 'lodash/throttle'
  import mapValues from 'lodash/mapValues'
  import Pagination from '@/Shared/Pagination'
  import SearchFilter from '@/Shared/SearchFilter'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import StatusLevel from '@/Shared/StatusLevel'
  import Button from '@/Shared/Button'
  import OfficeAssignGrid from './components/AssignOffice/OfficeAssignGrid'
  import FormBuilderBlock from '@/Pages/Admin/FormBuilder'
  import ServiceFormBlock from '@/Pages/Admin/ServiceFormBlock'
  export default {
    components: {
      Head,
      Icon,
      Link,
      Pagination,
      SearchFilter,
      Breadcrumbs,
      StatusLevel,
      Button,
      OfficeAssignGrid,
      FormBuilderBlock,
      ServiceFormBlock
    },
    layout: Layout,
    props: {
      filters: Object,
      serviceinfos: Object,
      permission: Object,
    },
    setup(props){
      const { filters, serviceinfos,permission } = toRefs(props)
      const form = ref({
        search: filters.value.search,
        trashed: filters.value.trashed,
        length: filters.value.length?filters.value.length:15,
        status: filters.value.status,
      })
      const type = ref('index')
      const title = ref('Service')
      const url = ref('')
      const createUrl = ref('/backend/serviceinfos/create')
      const reset = () => {
        form.value = mapValues(form, () => null)
      }
      const service_organogram_on = ref(false)
      const service_form_on = ref(false)
      const sel_service_id = ref('')
      const sel_service_name = ref('')
      const sel_service_app_form = ref([])
      const sel_certificate_data = ref({})
      const sel_qr_data = ref({})
      const sel_assign_layer_list = ref([])
      const sel_service_wf_form = ref({})
      const sel_service_payment = ref({})
      const service_designation_list = ref([])

      const service_map_config = (serviceinfo,catname) => {
      //  //console.log(catname);
        let payment={}
        serviceinfo.payments.forEach(element => {
          payment=element
        });
      //  //console.log(payment);
        sel_service_id.value = serviceinfo.id
        sel_service_name.value = catname+' ('+serviceinfo.service_name+')'
        sel_service_payment.value = payment
        service_organogram_on.value = true
        document.getElementsByTagName( 'html' )[0].classList.add('hide-scrollbar-y');
      }

      const service_form_config = (serviceinfo,catname) => {
        // console.log('Service Info List', serviceinfo)
        sel_service_id.value = serviceinfo.id
        sel_service_name.value = catname+' ('+serviceinfo.service_name+')'
        sel_service_app_form.value = serviceinfo.service_app_form_info?JSON.parse(serviceinfo.service_app_form_info.app_form_info):[]
        // //console.log('service_app_form_info', serviceinfo.service_app_form_info)
        sel_certificate_data.value = serviceinfo.service_app_form_info && serviceinfo.service_app_form_info.certificate_col_info?JSON.parse(serviceinfo.service_app_form_info.certificate_col_info):{}
        sel_qr_data.value = serviceinfo.service_app_form_info && serviceinfo.service_app_form_info.qr_col_info?JSON.parse(serviceinfo.service_app_form_info.qr_col_info):{}
        sel_assign_layer_list.value = serviceinfo.service_app_form_info && serviceinfo.service_app_form_info.assign_layer_list?JSON.parse(serviceinfo.service_app_form_info.assign_layer_list):[]
        // //console.log('assign_layer_list', sel_assign_layer_list.value)

        sel_service_wf_form.value = serviceinfo.service_app_form_info && serviceinfo.service_app_form_info.wf_form_info?JSON.parse(serviceinfo.service_app_form_info.wf_form_info):{}

        service_designation_list.value = serviceinfo.service_designation

        service_form_on.value = true
        document.getElementsByTagName( 'html' )[0].classList.add('hide-scrollbar-y');
      }

      const remove_organogram_popup = () => {
        service_organogram_on.value = false
        document.getElementsByTagName( 'html' )[0].classList.remove('hide-scrollbar-y');
      }

      const remove_form_popup = () => {
        service_form_on.value = false
        document.getElementsByTagName( 'html' )[0].classList.remove('hide-scrollbar-y');
      }

      return {
        filters,
        serviceinfos,
        form,
        type,
        title,
        url,
        createUrl,
        service_organogram_on,
        service_form_on,
        sel_service_id,
        sel_service_name,
        sel_service_app_form,
        sel_assign_layer_list,
        sel_certificate_data,
        sel_qr_data,
        reset,
        service_map_config,
        service_form_config,
        remove_organogram_popup,
        remove_form_popup,
        sel_service_payment,
        service_designation_list,
        permission
      }
    },
    watch: {
      form: {
        deep: true,
        handler: throttle(function () {
          this.$inertia.get('/backend/serviceinfos', pickBy(this.form), { preserveState: true })
        }, 150),
      },
    }
  }
  </script>
  <style lang="scss" scoped>
  .organogram_setup_block,
  .form_setup_block{
    position: fixed;
    min-width:calc(100% - 240px);
    max-width: 100%;
    background-color: #fff;
    padding: 25px;
    right: -400px; top: 0; height: 100%;
    overflow-y: auto;
    z-index: 9000;
    transition: all 0.4s;

    // &.form_setup_block{
    //   width: 100%;
    // }

    &.active{
      right: 0;
    }

    & > .office_info{
      font-size: 14px;
      font-weight: 600;
      height: 46px;
      color: #666;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      border-bottom: 1px solid #ddd;
      justify-content: center;
      border-radius: 15px;
      position: absolute;
      width: calc(100% - 70px);
      left: 40px;
      top: 0;
    }

    & > .close_btn{
      position: sticky;
      margin-left: -25px;
      margin-top: -25px;
      top: -25px;
      width: 40px;
      height: 47px;
      background-color: #00000080;
      color: #fff;
      cursor: pointer;
      border-radius: 0 0px 15px 0;
      z-index: 5;
    }
  }
  .organogram_btn{
    background-color: #152b74;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.4s;
    height: 22px;
    margin: 10px;
    font-size: 10px;

    &:hover{
      background-color: #2d6f90;
    }
  }
</style>
