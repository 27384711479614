<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
        <trashed-message v-if="serviceCertificateTemplate.deleted_at" class="mb-6" @restore="restore"> This Certificate
            Template has been deleted. </trashed-message>
        <div class="max-w-5xl bg-white rounded-md shadow overflow-hidden">
            <form @submit.prevent="update">
                <div class="flex flex-wrap -mb-8 -mr-6 p-8">
                    <text-input v-model="form.name" :error="form.errors.name" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Name of Template" required="true" placeholder="i.e " />
                    <select-input-function v-model="form.service_id" :error="form.errors.service_id"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Service" required="true" :action="load_field">
                        <option value=null>Select Service</option>
                        <option v-for="service in services" :key="service.id" :value='service.id'>{{
            service.service_name }}</option>
                    </select-input-function>
                    <div class="pb-8 pr-6 w-full lg:w-1/1">
                        <label class="form-label" for="template">Content <span class="custom-error">*</span></label>
                        <label for="smsbody">Keyword:
                            <span class="text-data" v-for="(value, name, index) in selected_field"
                                v-html="'{{' + name + '}}'"></span>
                        </label>
                        <br>
                        <div class="my-5 edit_form_block relative">
                            <i @click="openModal"
                                class="fa-regular fa-pen-to-square right-0 text-green-700 top-1 absulate del"></i>
                            <div
                                class="mx-auto max-w-7xl px-0 sm:px-6 lg:px-8 min-h-[calc(60vh_-_10rem)] h-auto border overflow-y-auto p-4 rounded">
                                <div v-html="form.template"></div>
                            </div>
                        </div>
                        <Modal :data="form.template" :isOpen="isOpen" @UpdatePopup="update_popup"
                            @UpdateData="update_data"></Modal>
                    </div>
                    <status-toogle-button v-model="form.status" :error="form.errors.status" label="Template Status"
                        class="pb-8 pr-6 w-full lg:w-1/1"></status-toogle-button>
                </div>
                <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
                    <button v-if="!serviceCertificateTemplate.deleted_at && permission.edit > 0"
                        class="text-red-600 hover:underline" tabindex="-1" type="button"
                        @click="destroy"><delete-icon></delete-icon></button>
                    <Button v-if="permission.edit > 0" :type="type" :name="head" :url="createUrl"
                        :loading="form.processing"></Button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { ref, toRefs, onMounted, inject } from 'vue'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import axios from 'axios';
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import TrashedMessage from '@/Shared/TrashedMessage'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import DeleteIcon from '@/Shared/DeleteIcon'
import Button from '@/Shared/Button'
import TextareaInput from '@/Shared/TextareaInput'
import Modal from '@/Shared/Modal'
import SelectInputFunction from '@/Shared/SelectInputFunction'
export default {
    components: {
        Head,
        Icon,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        TrashedMessage,
        StatusToogleButton,
        Breadcrumbs,
        DeleteIcon,
        Button,
        TextareaInput,
        SelectInputFunction,
        Modal
    },
    layout: Layout,
    props: {
        services: Array,
        serviceCertificateTemplate: Object,
        permission: Object,
    },
    remember: 'form',
    setup(props) {
        const { serviceCertificateTemplate, services, permission } = toRefs(props)
        const type = ref('edit')
        const title = ref(serviceCertificateTemplate.value.name)
        const head = ref('Certificate Template')
        const url = ref('/backend/certificate-templates')
        const createUrl = ref('')
        const elm = ref(this)
        const swal = inject('$swal')
        const selected_field = ref({})
        const inits = ref({
            selector: 'textarea#open-source-plugins',
            plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
            imagetools_cors_hosts: ['picsum.photos'],
            menubar: 'file edit view insert format tools table help',
            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
            toolbar_sticky: true,
            autosave_ask_before_unload: true,
            autosave_interval: '30s',
            autosave_prefix: '{path}{query}-{id}-',
            autosave_restore_when_empty: false,
            autosave_retention: '2m',
            image_advtab: true,

            importcss_append: true,
            file_picker_callback: function (callback, value, meta) {
                if (meta.filetype === 'file') {
                    callback('https://www.google.com/logos/google.jpg', { text: 'My text' });
                }
                if (meta.filetype === 'image') {
                    callback('https://www.google.com/logos/google.jpg', { alt: 'My alt text' });
                }
                if (meta.filetype === 'media') {
                    callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg' });
                }
            },
            templates: [
                { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
                { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
                { title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
            ],
            template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
            template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
            height: 600,
            image_caption: true,
            quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
            noneditable_noneditable_class: 'mceNonEditable',
            toolbar_mode: 'sliding',
            contextmenu: 'link image imagetools table',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        })
        const form = useForm({
            name: serviceCertificateTemplate.value.name,
            template: serviceCertificateTemplate.value.template,
            status: serviceCertificateTemplate.value.status,
            service_id: serviceCertificateTemplate.value.service_id,
        })
        const update = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.put(`${url.value}/${serviceCertificateTemplate.value.id}`)
                    title.value = form.name
                    Swal.fire(
                        'Updated!',
                        'Your infomation has been Updated.',
                        'success'
                    )
                }
            });
        }
        const destroy = () => {
            swal({
                title: 'Are you sure?',
                text: "Do you really want to delete this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    form.delete(`${url.value}/${serviceCertificateTemplate.value.id}`)
                    Swal.fire(
                        'Deleted!',
                        'Your infomation has been deleted.',
                        'success'
                    )
                }
            });
        }
        const restore = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to restore this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Resore it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    form.put(`${url.value}/${serviceCertificateTemplate.value.id}/restore`)
                    Swal.fire(
                        'Restored!',
                        'Your infomation has been Restored.',
                        'success'
                    )
                }
            });
        }
        const statusCheck = () => {
            if (serviceCertificateTemplate.value.status == 1) {
                form.status = true;
                return true;
            } else {
                form.status = false;
                return false;
            }
        }
        const load_field = async (e) => {
            if (e.target.value > 0) {
                let data = {
                    service_id: e.target.value
                }
                await axios.post('../../service/get_certificate_info', data)
                    .then(function (response) {
                        selected_field.value = response.data.data;
                    }.bind(this));
            } else {
                selected_field.value = {};
            }
        }
        const isOpen = ref(false)
        const openModal = () => {
            isOpen.value = true
        }
        const update_popup = (val) => {
            isOpen.value = val
        }
        const update_data = (val) => {
            form.template = val
        }
        onMounted(() => {
            if (form.service_id > 0) {
                let data = {
                    service_id: form.service_id
                }
                axios.post('../../service/get_certificate_info', data)
                    .then(function (response) {
                        selected_field.value = response.data.data;
                    }.bind(this));
            }
        })
        return {
            form,
            serviceCertificateTemplate,
            type,
            title,
            head,
            url,
            createUrl,
            update,
            destroy,
            restore,
            statusCheck,
            services,
            inits,
            selected_field,
            load_field,
            swal,
            permission,
            isOpen,
            openModal,
            update_popup,
            update_data
        }
    },
    created: function () {
        this.statusCheck();
    }

}
</script>
<style>
.ql-toolbar .ql-formats .ql-image {
    display: none;
}

span.text-data {
    padding: 5px;
    line-height: 25px;
}
.edit_form_block i.del {
    position: absolute;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px #999;
    border: 1px solid #ddd;
    cursor: pointer;
    top: -5px;
    right: -5px;
}
</style>
