<template>
  <div>
    <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>      
    <div class="flex items-center justify-between mb-6">
      <search-filter  v-model="form.search" class="mr-4 w-full max-w-xl" @reset="reset">
        <template v-slot:filter>
          <select v-model="form.trashed" class="form-select  w-28">
            <option :value="null" >Filter</option>
            <option value="with">With Trashed</option>
            <option value="only">Only Trashed</option>
          </select>
        </template>
        <template v-slot:page>
          <select v-model="form.length" class="form-select  w-20">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="75">75</option>
            <option value="100">100</option>
            <option value="150">150</option>
            <option value="200">200</option>
          </select>
        </template>
        <template v-slot:status>
          <select v-model="form.status" class="form-select w-32">
            <option :value="null">Status</option>
            <option value="1">Active</option>
            <option value="2">Inactive</option>
          </select>
        </template>
      </search-filter>
      <Link class="btn-indigo" href="/backend/officeroles/create">
        <span>Create</span>
        <span class="hidden md:inline">&nbsp;Office Role</span>
      </Link>
    </div>
    <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left font-bold">
            <th class="pb-2 pt-3 px-3">office roles Name</th>
            <th class="pb-2 pt-3 px-3">Role Name</th>
            <th class="pb-2 pt-3 px-3" colspan="2">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="officerole in officeroles.data" :key="officerole.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
            <td class="border-t">
              <Link class="flex items-center px-3 py-1 focus:text-indigo-500" :href="`/backend/officeroles/${officerole.id}/edit`">
                {{ officerole.employee_name }}
                <icon v-if="officerole.deleted_at" name="trash" class="flex-shrink-0 ml-2 w-3 h-3 fill-gray-400" />
              </Link>
            </td>
            <td class="border-t">
              <Link class="flex items-center px-3 py-1" :href="`/backend/officeroles/${officerole.id}/edit`" tabindex="-1">
                {{ officerole.office_name }}
              </Link>
            </td>            
            <td class="border-t">
              <Link class="flex items-center px-3 py-1" :href="`/backend/officeroles/${officerole.id}/edit`" tabindex="-1">
                {{ officerole.role_name }}
              </Link>
            </td>
            <td class="border-t">
              <Link class="flex items-center px-3 py-1" :href="`/backend/officeroles/${officerole.id}/edit`" tabindex="-1">                 
                <status-level :status="officerole.status"></status-level>
              </Link>
            </td>
            <td class="w-px border-t">
              <Link class="flex items-center px-4" :href="`/backend/officeroles/${officerole.id}/edit`" tabindex="-1">
                <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
              </Link>
            </td>
          </tr>
          <tr v-if="officeroles.data.length === 0">
            <td class="px-3 py-1 border-t" colspan="4">No Office Roles found.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination class="mt-6" :links="officeroles.links" />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import SearchFilter from '@/Shared/SearchFilter'
import StatusLevel from '@/Shared/StatusLevel'
import Breadcrumbs from '@/Shared/Breadcrumbs'
export default {
  components: {
    Head,
    Icon,
    Link,
    Pagination,
    SearchFilter,
    StatusLevel,
    Breadcrumbs
  },
  layout: Layout,
  props: {
    filters: Object,
    officeroles: Object,
  },
  data() {
    return {
      form: {
          search: this.filters.search,
          trashed: this.filters.trashed,
          length: this.filterslength?this.filters.length:15,
          status: this.filters.status,
        },
      type:"index",
      title:"Office Role Setup",
      url:'',
    }
  },
  watch: {
    form: {
      deep: true,
      handler: throttle(function () {
        this.$inertia.get('/backend/officeroles', pickBy(this.form), { preserveState: true })
      }, 150),
    },
  },
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)
    },
  },
}
</script>
