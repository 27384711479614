<template>
    <div class="properties_block">
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Type</label>
            <div>Static Text</div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Name</label>
            <div><input class="px-2" type="text" v-model="property_form_data.name" placeholder="Enter Name" @keyup="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Content</label>
            <div><textarea class="p-2" v-model="property_form_data.content" placeholder="Enter Name" @keyup="add_properties"></textarea></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Mode</label>
            <div><select class="px-1" v-model="property_form_data.mode" @change="add_properties">
                <option v-for="(text,val) in mode_list" :value="val">{{ text }}</option>
            </select></div>
        </div>
        <div v-if="property_form_data.mode=='normal' || property_form_data.mode=='paragraph'" class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Font Size</label>
            <div class="flex items-center gap-1">
                <input class="text-center pl-1" type="number" v-model="property_form_data.font_size" min="10" @keyup="add_properties" @change="add_properties" />
                <select class="text-center" v-model="property_form_data.font_size_type" @change="add_properties">
                    <option v-for="text in font_size_types" :value="text">{{ text }}</option>
                </select>
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Font Weight</label>
            <div><select class="px-1" v-model="property_form_data.font_weight" @change="add_properties">
                <option v-for="(text,val) in font_weight_list" :value="val">{{ text }}</option>
            </select></div>
        </div>
        <div v-if="property_form_data.mode=='normal' || property_form_data.mode=='paragraph'" class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Line Height</label>
            <div class="flex items-center gap-1">
                <input class="text-center pl-1" type="number" v-model="property_form_data.line_height" min="10" @keyup="add_properties" @change="add_properties" />
                <select class="text-center" v-model="property_form_data.line_height_type" @change="add_properties">
                    <option v-for="text in font_size_types" :value="text">{{ text }}</option>
                </select>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { ref, watch } from 'vue'
    const props = defineProps({
        data: Object
    })
    const emit = defineEmits(['addProperties'])
    const property_form_data = ref({
        name: props.data && props.data.name?props.data.name:'',
        type: props.data && props.data.type?props.data.type:'text',
        value: props.data && props.data.value?props.data.value:'',
        content: props.data && props.data.content?props.data.content:'',
        mode: props.data && props.data.mode?props.data.mode:'normal',
        font_size: props.data && props.data.font_size?props.data.font_size:12,
        font_size_type: props.data && props.data.font_size_type?props.data.font_size_type:'px',
        font_weight: props.data && props.data.font_weight?props.data.font_weight:'normal',
        line_height: props.data && props.data.line_height?props.data.line_height:14,
        line_height_type: props.data && props.data.line_height_type?props.data.line_height_type:'px'
    })

    watch(() => props.data, (updateData, oldValue) => {
        // //console.log(updateData, oldValue)
        property_form_data.value = {
            name: updateData && updateData.name?updateData.name:'',
            type: updateData && updateData.type?updateData.type:'text',
            value: updateData && updateData.value?updateData.value:'',
            content: updateData && updateData.content?updateData.content:'',
            mode: updateData && updateData.mode?updateData.mode:'normal',
            font_size: updateData && updateData.font_size?updateData.font_size:12,
            font_size_type: updateData && updateData.font_size_type?updateData.font_size_type:'px',
            font_weight: updateData && updateData.font_weight?updateData.font_weight:'normal',
            line_height: updateData && updateData.line_height?updateData.line_height:14,
            line_height_type: updateData && updateData.line_height_type?updateData.line_height_type:'px'
        }
    });

    const mode_list = ref({
        'h1': 'H1',
        'h2': 'H2',
        'h3': 'H3',
        'h4': 'H4',
        'normal': 'Normal',
        'paragraph': 'Paragraph'
    })
    const font_size_types = ref(['%','em','rem','vh','px'])    
    const font_weight_list = ref({
        '100': '100',
        '200': '200',
        '300': '300',
        '400': '400',
        '500': '500',
        '600': '600',
        '700': '700',
        '800': '800',
        '900': '900',
        'bold': 'Bold',
        'bolder': 'Bolder',
        'lighter': 'Lighter',
        'normal': 'Normal'
    })

    const add_properties = () => {
        emit('addProperties', property_form_data.value)
    }
</script>
<style lang="scss" scoped>
    .properties_block{
        font-size: 12px;    
        input,textarea,select{
            width: 100%;
            height: 26px;
            border: 1px solid #ddd;
            border-radius: 5px;
        }
        textarea{
            height: 48px;
        }
    }
</style>