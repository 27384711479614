<template>
    <div class="table_display_block">
        <pre>{{ data }}</pre>
        <table class="w-full border border-gray-300 border-collapse">
            <template v-for="(item,index) in data.html">
                <tr>
                    <th class="px-2 py-1 w-10 bg-gray-100 border">
                        <span v-if="index==0"></span>
                        <i v-else class="fa fa-trash-alt text-[10px] text-red-800 cursor-pointer" @click="remove_row(index)"></i>
                    </th>
                    <template v-for="(col,col_index) in item.cols">
                        <th v-if="col.type=='th'" class="px-2 py-1 bg-gray-100 border">
                            <div class="flex flex-row gap-1">
                                <div class="flex flex-row items-center gap-1 h-20 border-dashed bg-gray-100 rounded-md px-4 add_component_btn justify-center cursor-pointer">
                                    <i class="fa fa-plus-circle"></i>
                                    <span>Add New Component</span>
                                </div>
                                <div class="ml-auto" v-if="index==0 && col_index==0">
                                    <i class="fa fa-trash-alt text-[10px] text-red-800 cursor-pointer" @click="remove_column(index,col_index)"></i>
                                </div>
                            </div>
                        </th>
                        <td v-else class="px-2 py-1 border">
                            <div class="flex flex-row gap-1">
                                <div class="flex flex-row items-center gap-1 h-20 border-dashed bg-gray-100 rounded-md px-4 add_component_btn justify-center cursor-pointer">
                                    <i class="fa fa-plus-circle"></i>
                                    <span>Add New Component</span>
                                </div>
                                <div class="ml-auto" v-if="index==0 && col_index==0">
                                    <i class="fa fa-trash-alt text-[10px] text-red-800 cursor-pointer" @click="remove_column(index,col_index)"></i>
                                </div>
                            </div>
                        </td>
                    </template>                
                </tr>
            </template>            
        </table>
        <div class="flex items-center justify-center flex-row gap-5 mt-5">
            <div class="flex items-center flex-row gap-1 cursor-pointer text-[10px] bg-gray-50 font-semibold" @click="add_new_row">
                <i class="fa fa-plus"></i>
                <span>Add Row</span>
            </div>

            <div class="flex items-center flex-row gap-1 cursor-pointer text-[10px] bg-gray-50 font-semibold" @click="add_new_column">
                <i class="fa fa-plus"></i>
                <span>Add Column</span>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { onMounted } from 'vue'
    const props = defineProps({
        data: Object,
        index: Number,
        preview: Boolean
    })    

    onMounted(() => {
        manage_table_setup()
    })

    const remove_row = (index) => {
        props.data.html.splice(index, 1)
        props.data.rows--
    }
    const remove_column = (index, col_index) => {
        props.data.html[index].cols.splice(col_index, 1)
        props.data.cols--
    }
    const add_new_row = () => { 
        props.data.rows++
        props.data.html.push({})
        manage_table_setup()
    }
    const add_new_column = () => {
        props.data.cols++
        manage_table_setup()
    }
    const manage_table_setup = () => {
        let obj = {
            type: 'td',
            value: '',
            rowspan: 1,
            colspan: 1
        }
        for(var i = 0; i < props.data.rows; i++){
            if(!props.data.html[i].cols) props.data.html[i].cols = []
            for(var j = 0; j < props.data.cols; j++){
                if(!props.data.html[i].cols[j]) props.data.html[i].cols.push(obj)
            }
        }
    }
</script>
<style lang="scss" scoped> 
    .table_display_block{
        display: block;
        position: relative
    }
</style>