<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
        <div class="max-w-lg bg-white rounded-md shadow overflow-hidden">
            <form @submit.prevent="store">
                <div class="flex flex-wrap -mb-8 -mr-6 p-8">

                    <template v-for="column in col">
                        <template v-if="column == 'English'">
                            <text-input v-model="form[column]" :error="form.errors.column" class="pb-8 pr-6 w-full lg:w-1/1"
                                :label="column" required="true" placeholder="i.e Logo" @keyup="slugBuild" />
                            <text-input v-model="form.language_short_code" :error="form.errors.language_short_code"
                                class="pb-8 pr-6 w-full lg:w-1/1" label="Short Code" required="true" placeholder="i.e logo"
                                disabled />
                        </template>
                        <template v-else>
                            <text-input v-model="form[column]" :error="form.errors.column" class="pb-8 pr-6 w-full lg:w-1/1"
                                :label="column" placeholder="i.e Mga Logo" />
                        </template>
                    </template>
                </div>
                <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>
            </form>
        </div>
    </div>
</template>

<script>
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject } from 'vue'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Button from '@/Shared/Button'
import axios from "axios"
export default {
    components: {
        Head,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        Breadcrumbs,
        StatusToogleButton,
        Button
    },
    layout: Layout,
    remember: 'form',
    props: {
        col: Object,
        lan: Object
    },
    setup(props) {
        const { col, lan } = toRefs(props)
        const form = useForm({
            language_short_code: null,
            English: null,
            Maguindanaon:null,
            Maranao:null,
            Filipino:null
        })
        const type = ref('create')
        const title = ref('Language Translation')
        const url = ref('/backend/language-translations')
        const createUrl = ref('')
        const swal = inject('$swal')
        const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)

        const store = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
                console.log(form);
              form.post(`${url.value}`, {forceFormData: true,})
              swal.fire(
                'Added!',
                'Your infomation has been Added.',
                'success'
              )

            }
          });

      }



        // const store = async () => {
        //     await axios.post(`/${backendPrefix.value}${url.value}`, form).then(function (response) {
        //         console.log(response);
        //         window.location.href ='/backend/language-translations';
        //     });
        // }
        const slugBuild = () => {
            form.language_short_code = form.English.replace(/\s/g, '_').toLowerCase()
        }
        return {
            form,
            type,
            title,
            url,
            createUrl,
            col,
            lan,
            store,
            slugBuild,backendPrefix
        }
    }
}
</script>
