<template>
    <div :class="['flex items-center justify-center profile']">
      <img src="/images/profile.png" alt="profile"> 
    </div>
    
  </template>
  <script>
    export default {
      props: {
        _for: String
      },
    }
  </script>
  <style lang="scss" scoped>
    .profile{
      img{
        width: 22px;  object-fit: contain;
      }     
    }
  </style>
  