<template>
    <TransitionRoot as="template" :show="designationopen">
        <Dialog as="div" class="relative z-[9999]" @click="update_designation_popup(false)">
            <div class="fixed inset-0" />
            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                        <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from="translate-x-full" enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
                            leave-to="translate-x-full">
                            <DialogPanel class="pointer-events-auto w-screen max-w-md">
                                <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                    <div class="h-0 flex-1 overflow-y-auto">
                                        <div class="bg-indigo-700 px-4 py-6 sm:px-6">
                                            <div class="flex items-center justify-between">
                                                <DialogTitle class="text-base font-semibold leading-6 text-white">
                                                    Designation
                                                </DialogTitle>
                                                <div class="ml-3 flex h-7 items-center">
                                                    <button type="button"
                                                        class="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                        @click="update_designation_popup(false)">
                                                        <span class="absolute -inset-2.5" />
                                                        <span class="sr-only">Close panel</span>
                                                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="mt-1">
                                                <p class="text-sm text-indigo-300">Select Designations for this project.</p>
                                            </div>
                                        </div>
                                        <div class="flex flex-1 flex-col justify-between">
                                            <div class="divide-y divide-gray-200">
                                                <div class="space-y-6 pb-1 pt-1">
                                                    <div class="emp_organogram_block">
                                                        <div class="list_block m-5">
                                                            <!-- {{ organogram }} -->
                                                            <ul>
                                                                <li v-for="item in organogram">
                                                                    <div v-if="item.section != null"
                                                                        class="flex items-center gap-2 px-3 section_block">
                                                                        <i class="fa fa-info-circle"></i>
                                                                        <span>{{ item.section.name }}</span>
                                                                    </div>
                                                                    <ul v-if="(item.designations.length > 0) && item.section != null"
                                                                        class="px-4 py-3">
                                                                        <li v-for="designation in item.designations">
                                                                            <div :class="['flex', 'items-center', 'gap-2', 'px-4', 'designation_block', { active: designations_data[designation.id] }]"
                                                                                @click="add_designation(designation.id)">
                                                                                <div class="input_block">
                                                                                    <i class="fa fa-check check"></i>
                                                                                    <i class="fa fa-times remove"></i>
                                                                                </div>
                                                                                <div class="designation">{{ designation.name
                                                                                }}</div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-shrink-0 justify-end px-4 py-4" v-if="edit == false">
                                        <button type="button" @click="save()"
                                            class="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
                                    </div>
                                </form>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>


import axios from 'axios';
import { ref, toRefs, inject, onMounted, watch } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import Button from '@/Shared/Button'
const props = defineProps(['designations', 'designationopen', 'implementing_ministry'])
const designations_data = ref(props.designations)
const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
const edit = ref(false)
const swal = inject('$swal')
const preloader = ref(false)
const message = ref('');

const emit = defineEmits(['UpdateDesignationPopup', 'UpdateDesignation'])

const designations_list = ref([])
const organogram = ref([])
const load_designations = async () => {
    let form = {
        'id': props.service_info_id
    }
    let data = {
        'ministry_id': props.implementing_ministry
    }
    if (props.implementing_ministry > 0) {
        await axios.post(`/${backendPrefix.value}/designations/ministry-designation`, data)
            .then(function (response) {
                designations_list.value = response.data
            }.bind(this));
    } else {
        designations_list.value = []
    }
}

const load_designations2 = async () => {
    let form = {
        'id': props.service_info_id
    }
    let data = {
        'ministry_id': props.implementing_ministry
    }
    if (props.implementing_ministry > 0) {
        await axios.post(`/${backendPrefix.value}/offices/office-organogram/ministry`, data)
            .then(function (response) {
                organogram.value = response.data
            }.bind(this));
    } else {
        organogram.value = []
    }
}

const open_edit = () => {
    edit.value = true
}

const update_designation_popup = (status) => {
    emit('UpdateDesignationPopup', status)
}

const save = () => {
    emit('UpdateDesignationPopup', false)
    emit('UpdateDesignation', designations_data)
}


const add_designation = (id) => {
    // alert(id);
    if (designations_data.value[id]) delete designations_data.value[id]
    else designations_data.value[id] = true
}
watch(() => designations_data, () => {
    emit('UpdateDesignation', designations_data)
}, { deep: true })

watch(() => props.designationopen, () => {
    emit('UpdateDesignationPopup', props.designationopen)
}, { deep: true })
watch(() => props.implementing_ministry, () => {
    load_designations();
    load_designations2()
}, { deep: true }
);
onMounted(() => {
    load_designations()
    load_designations2()
})
const remove_msg = () => {
    message.value = ''
}

</script>
<style lang="scss" scoped>
.emp_organogram_block {
    position: relative;
    padding-bottom: 50px;

    :deep(.action_btn) {
        bottom: 10px
    }

    .list_block {
        border: 1px solid #ddd;
        border-radius: 5px;

        .section_block {
            height: 30px;
            background-color: #f7f7f7;
            border-bottom: 1px solid #ddd;
            border-radius: 5px 5px 0 0;
        }

        .designation_block {
            height: 34px;
            cursor: pointer;

            &>.input_block {
                width: 22px;
                height: 22px;
                display: flex;
                align-items: center;
                border: 1px solid #999;
                justify-content: center;
                border-radius: 5px;

                &>i {
                    display: none;
                    font-size: 10px;
                }
            }

            &.active,
            &:hover {
                color: #006699;

                &>.input_block {
                    border-color: #006699;

                    &>i.remove {
                        display: none;
                    }

                    &>i.check {
                        display: block;
                        color: #006699
                    }
                }
            }

            &.active {
                &:hover {
                    color: #CD0000;

                    &>.input_block {
                        border-color: #CD0000;

                        &>i.check {
                            display: none;
                        }

                        &>i.remove {
                            display: block;
                            color: #CD0000;
                        }
                    }
                }
            }
        }
    }
}
</style>
