<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>  
      <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="store">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.employee_type_name" :error="form.errors.employee_type_name" class="pb-8 pr-6 w-full lg:w-1/2" label="Name Of Type" />
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Type Status" class="pb-8 pr-6 w-full lg:w-1/2"></status-toogle-button>          
          </div>
          <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
            <loading-button :loading="form.processing" class="btn-indigo" type="submit">Create Type</loading-button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import { Head, Link } from '@inertiajs/inertia-vue3'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  export default {
    components: {
      Head,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      Breadcrumbs,
      StatusToogleButton
    },
    layout: Layout,
    remember: 'form',
    data() {
      return {
        form: this.$inertia.form({
          employee_type_name: null,
          status: true
        }),
        type:"create",
        title:"Employee Type",
        url:'/backend/employeetypes',
      }
    },
    methods: {
      store() {
        this.form.post('/backend/employeetypes')
      },
    },
  }
  </script>
  