<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
      <trashed-message v-if="employee.deleted_at" class="mb-6" @restore="restore"> This Employee has been deleted. </trashed-message>
      <div class="container m-auto grid grid-cols-3">
        <div class="col-span-2 max-w-2xl">
          <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
            <form @submit.prevent="update">
              <div class="flex flex-wrap -mb-8 -mr-6 p-8">
                <text-input v-model="form.first_name" :error="form.errors.first_name" class="pb-8 pr-6 w-full lg:w-1/2" label="First Name" required="true" placeholder="i.e John " />
                <text-input v-model="form.middle_name" :error="form.errors.middle_name" class="pb-8 pr-6 w-full lg:w-1/2" label="Middle Name"  placeholder="i.e Alden " />
                <text-input v-model="form.last_name" :error="form.errors.last_name" class="pb-8 pr-6 w-full lg:w-1/2" label="Last Name" required="true" placeholder="i.e Doe" />
                <text-input v-model="form.surfix" :error="form.errors.surfix" class="pb-8 pr-6 w-full lg:w-1/2" label="Suffix" placeholder="i.e Jr" />
                <text-input v-model="form.employee_id" :error="form.errors.employee_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Employee ID" required="true" placeholder="i.e 1234"/>
                <select-input v-model="form.employee_role" :error="form.errors.employee_role" class="pb-8 pr-6 w-full lg:w-1/2" label="Employee Role" required="true">
                  <option value=null >Select Role</option>
                  <option v-for="employee_role in employee_roles" :key="employee_role.id" :value='employee_role.id'>{{ employee_role.role_name }}</option>
                </select-input>
                <select-input v-model="form.gender_id" :error="form.errors.gender_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Gender" required="true">
                  <option value='0' >Select Gender</option>
                  <option v-for="gender in genders" :key="gender.id" :value='gender.id'>{{ gender.gender_name }}</option>
                </select-input>
                <text-date-picker v-model="form.dob" :error="form.errors.dob" class="pb-8 pr-6 w-full lg:w-1/2" label="Date of Birth" placeholder="i.e 01-01-2022"/>
                <text-input v-model="form.father_name" :error="form.errors.father_name" class="pb-8 pr-6 w-full lg:w-1/2" label="Father's Name" placeholder="i.e John"/>
                <text-input v-model="form.mother_name" :error="form.errors.mother_name" class="pb-8 pr-6 w-full lg:w-1/2" label="Mother's Name" placeholder="i.e Kate William"/>
                <select-input v-model="form.issued_type_id" :error="form.errors.issued_type_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Government Issued ID Type" required="true">
                  <option value=null >Select Government Issued ID Type</option>
                  <option v-for="governmentIssued in governmentIssuedIdType" :key="governmentIssued.id" :value='governmentIssued.id'>{{ governmentIssued.name }}</option>
                </select-input>
                <text-input v-model="form.nid" :error="form.errors.nid" class="pb-8 pr-6 w-full lg:w-1/2" label="Government Issued ID" placeholder="i.e 1234567890123" required="true"/>

                <text-input-email v-model="form.email" :error="form.errors.email" :type="email" class="pb-8 pr-6 w-full lg:w-1/2" label="Email"  required="true" placeholder="i.e example@test.com"/>
                <text-input v-model="form.mobile" :error="form.errors.mobile" :type="text" class="pb-8 pr-6 w-full lg:w-1/2" label="Mobile"  required="true" placeholder="i.e +63-2-1234-5678"/>
                <select-input v-model="form.religion_id" :error="form.errors.religion_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Religion" required="true">
                  <option value='0' >Select Religion</option>
                  <option v-for="religion in religions" :key="religion.id" :value='religion.id'>{{ religion.name }}</option>
                </select-input>
                <select-input v-model="form.marital_status_id" :error="form.errors.marital_status_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Marital Status">
                  <option value='0' >Select Marital Status</option>
                  <option v-for="marital_status in marital_statuses" :key="marital_status.id" :value='marital_status.id'>{{ marital_status.marital_statuse_name }}</option>
                </select-input>

                <text-date-picker v-model="form.date_of_joining" :error="form.errors.date_of_joining" class="pb-8 pr-6 w-full lg:w-1/2" label="Date of Joining" required="true" placeholder="i.e 01-01-2022"/>
                <text-date-picker v-model="form.date_of_retirement" :error="form.errors.date_of_retirement" class="pb-8 pr-6 w-full lg:w-1/2" label="Date of Retirement" placeholder="i.e 01-01-2022"/>
                <textarea-input v-model="form.pre_address" :error="form.errors.pre_address" class="pb-8 pr-6 w-full lg:w-1/2" label="Present Address" required="true" placeholder="i.e Huwit-Huwit, Omar"/>

                <textarea-input v-model="form.per_address" :error="form.errors.per_address" class="pb-8 pr-6 w-full lg:w-1/2" label="Permanent Address" required="true" placeholder="i.e Huwit-Huwit, Omar"/>

                <file-input v-model="form.picture" :error="form.errors.picture" class="pb-8 pr-6 w-full lg:w-1/2" type="file" accept="image/*" label="Profile Picture" />

                <file-input v-model="form.signature" :error="form.errors.signature" class="pb-8 pr-6 w-full lg:w-1/2" type="file" accept="image/*" label="Signature" />

                <div class="pb-8 pr-6 w-full lg:w-1/2" ></div>
                <status-toogle-button v-model="form.email_notification" :error="form.errors.email_notification" label="Email Notification" class="pb-8 pr-6 w-full lg:w-1/2 "></status-toogle-button>
                <status-toogle-button v-model="form.sms_notification" :error="form.errors.sms_notification" label="SMS Notification" class="pb-8 pr-6 w-full lg:w-1/2 "></status-toogle-button>

                <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Employee" class="pb-8 pr-6 w-full lg:w-1/2 "></status-toogle-button>


                <div class="grid grid-cols-2 gap-4">
                  <div class="flex justify-start mb-8 ">
                    <img v-if="employee.picture" class="block ml-4  " :src="employee.picture" />
                    Profile Picture
                  </div>
                  <div class="flex justify-start mb-8">
                    <img v-if="employee.signature" class="block ml-4 " :src="employee.signature" />
                    Signature
                  </div>
                </div>
              </div>
              <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
                <button v-if="!employee.deleted_at && permission.edit>0" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
                <Button v-if="permission.edit>0" :type="type" :name="head" :url="createUrl" :loading="form.processing"></Button>
              </div>
            </form>
          </div>
        </div>
        <div class="max-w-2xl bg-white rounded-md shadow ml-4">
          <EmployeeGeoMap :regions="regions" :provinces="provinces" :officelayers="officelayers" :employee_id="form.id" :ministries="ministries" :employee_geo_map="employee_geo_map" @getOrganograms="get_organogram"
          @getGeoInfos="get_geo_infos" />

          <EmployeeOrganogramBlock v-if="(organograms.length>0)" :designation_ids="designation_ids" :emp_id="form.id" :geo_infos="geoInfos" :permission="permission" :organogram="organograms" />
        </div>
      </div>
    </div>
  </template>

  <script>
  import axios from "axios"
  import { ref, toRefs, inject } from 'vue'
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import Icon from '@/Shared/Icon'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import TrashedMessage from '@/Shared/TrashedMessage'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import DeleteIcon from '@/Shared/DeleteIcon'
  import SelectInputFunction from '@/Shared/SelectInputFunction'
  import TextDatePicker from '@/Shared/TextDatePicker'
  import TextareaInput from '@/Shared/TextareaInput'
  import Button from '@/Shared/Button'
  import EmployeeGeoMap from './components/EmployeeGeoMap'
  import EmployeeOrganogramBlock from "./components/EmployeeOrganogram"
  import TextInputEmail from '@/Shared/TextInputEmail'
  import FileInput from '@/Shared/FileInput'
  export default {
    components: {
      Head,
      Icon,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      TrashedMessage,
      StatusToogleButton,
      Breadcrumbs,
      DeleteIcon,
      SelectInputFunction,
      TextDatePicker,
      Button,
      EmployeeGeoMap,
      EmployeeOrganogramBlock,
      TextInputEmail,
      TextareaInput,
      FileInput
    },
    layout: Layout,
    props: {
      employee_geo_map: Array,
      employee_types: Array,
      genders: Array,
      religions: Array,
      marital_statuses: Array,
      regions: Array,
      provinces: Array,
      municipality: Array,
      barangays: Array,
      ministries: Array,
      officelayers: Array,
      offices: Array,
      employee: Object,
      permission: Object,
      governmentIssuedIdType: Array,
      employee_roles:Array
    },
    remember: 'form',
    setup(props){
      const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
      const { employee_geo_map,employee,employee_types,genders,religions,marital_statuses,regions,provinces,municipality,barangays,ministries,officelayers,offices,governmentIssuedIdType,permission,employee_roles} = toRefs(props)
      const type = ref('edit')
      const title = ref(employee.value.first_name)
      const head = ref('Employee')
      const url = ref('/backend/employees')
      const createUrl = ref('')
      const swal = inject('$swal')
      const geoInfos = ref({})
      const organograms = ref([])
      const designation_ids = ref({})

      const form = useForm({
        id: employee.value.id,
        first_name: employee.value.first_name,
        middle_name: employee.value.middle_name,
        last_name: employee.value.last_name,
        surfix: employee.value.surfix,
        gender_id: employee.value.gender_id,
        date_of_joining: employee.value.date_of_joining,
        date_of_retirement: employee.value.date_of_retirement,
        employee_id: employee.value.employee_id,
        employee_type_id: employee.value.employee_type_id,
        father_name: employee.value.father_name,
        mother_name: employee.value.mother_name,
        nid: employee.value.nid,
        dob: employee.value.dob,
        religion_id: employee.value.religion_id,
        email: employee.value.email,
        mobile: employee.value.mobile,
        marital_status_id: employee.value.marital_status_id,
        status: employee.value.status,
        deleted_at: employee.value.deleted_at,
        pre_address:employee.value.pre_address,
        per_address:employee.value.per_address,
        issued_type_id:employee.value.issued_type_id,
        picture:null,
        signature:null,
        employee_role:employee.value.employee_role,
        email_notification:employee.value.email_notification,
        sms_notification:employee.value.sms_notification,
      })

      const get_geo_infos = (obj) => {
        geoInfos.value = obj
      }

      const get_organogram = (data) => {
        organograms.value = data
        if(employee_geo_map.value!=null){
          if(employee_geo_map.value.DesignationIds){
            employee_geo_map.value.DesignationIds.forEach(v => {
              designation_ids.value[v.designation_id] = v.designation_id
            })
          }
        }
      }
      const update = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}/${employee.value.id}`, {forceFormData: true,})
              title.value = form.first_name
              Swal.fire(
                'Updated!',
                'Your infomation has been Updated.',
                'success'
              )
            }
          });
      }


      const destroy = () => {
        swal({
            title: 'Are you sure?',
            text: "Do you really want to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.delete(`${url.value}/${employee.value.id}`)
              Swal.fire(
                'Deleted!',
                'Your infomation has been deleted.',
                'success'
              )
            }
          });
      }
      const restore = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to restore this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Resore it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.put(`${url.value}/${employee.value.id}/restore`)
              Swal.fire(
                'Restored!',
                'Your infomation has been Restored.',
                'success'
              )
            }
          });
      }

      const statusCheck = () => {
        if(employee.value.status==1){
          form.status=true;
          return true;
        }else{
          form.status=false;
          return false;
        }
      }

      return {
        form,
        employee_geo_map,
        employee,
        employee_types,
        genders,
        religions,
        marital_statuses,
        type,
        title,
        head,
        url,
        createUrl,
        update,
        destroy,
        restore,
        statusCheck,
        regions,
        provinces,
        municipality,
        barangays,
        ministries,
        officelayers,
        offices,
        geoInfos,
        get_geo_infos,
        organograms,
        get_organogram,
        designation_ids,
        governmentIssuedIdType,
        swal,
        permission,
        employee_roles
      }
    },
    created: function(){
      this.statusCheck();
    }
  }
  </script>
