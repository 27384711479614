<template>
    <div :style="'width:' + width + 'px; height:' + height + 'px; color: ' + fill_color">
        <i class="fa fa-map-location-dot"></i>
    </div>
</template>
<script setup>
    import { ref } from "vue"
    const width = ref(60)
    const height = ref(40)
    const fill_color = ref('#1890ff')
</script>
<style lang="scss" scoped>
    div{        
        border: 1px solid #999;
        border-radius: 5px;
        color: rgb(24, 144, 255);
        text-align: center;

        & > i{
            font-size: 20px;
            line-height: 40px;
        }
    }
</style>