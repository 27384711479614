<template>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="14.000000pt" height="14.000000pt"
        viewBox="0 0 32.000000 32.000000" preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)" fill="#0ed356" stroke="none">
            <path d="M80 205 c-24 -25 -38 -45 -31 -45 10 0 12 -14 9 -55 l-5 -55 109 0
c65 0 108 4 108 10 0 6 -4 10 -10 10 -5 0 -10 11 -10 24 0 14 5 28 10 31 18
11 9 115 -10 115 -9 0 -22 -44 -24 -83 0 -15 2 -27 7 -27 4 0 7 -13 7 -30 0
-23 -4 -30 -20 -30 -17 0 -20 7 -20 45 0 29 4 45 12 45 7 0 -8 20 -32 45 -24
25 -47 45 -50 45 -3 0 -26 -20 -50 -45z m38 -63 c2 -7 -3 -12 -12 -12 -9 0
-16 7 -16 16 0 17 22 14 28 -4z m42 3 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8
23 11 12 23 8 23 -8z m-42 -37 c-6 -18 -28 -21 -28 -4 0 9 7 16 16 16 9 0 14
-5 12 -12z m42 -4 c0 -17 -22 -14 -28 4 -2 7 3 12 12 12 9 0 16 -7 16 -16z" />
        </g>
    </svg>
</template>
