<template>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="14.000000pt" height="14.000000pt"
        viewBox="0 0 32.000000 32.000000" preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)" fill="#0ed356" stroke="none">
            <path d="M115 291 c-53 -23 -80 -85 -64 -144 11 -42 84 -127 109 -127 7 0 32
19 55 43 72 71 79 155 17 210 -23 21 -87 31 -117 18z m113 -47 c41 -47 20
-139 -43 -180 -23 -15 -27 -15 -50 0 -79 52 -86 175 -11 206 32 14 79 3 104
-26z" />
            <path d="M127 222 c-20 -22 -22 -63 -5 -80 15 -15 61 -15 76 0 18 18 14 63 -6
81 -24 22 -45 21 -65 -1z m51 -11 c8 -4 12 -19 10 -32 -2 -18 -9 -24 -28 -24
-19 0 -26 6 -28 24 -3 20 11 41 28 41 3 0 11 -4 18 -9z" />
        </g>
    </svg>
</template>
