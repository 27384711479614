<template>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="14.000000pt" height="12.000000pt"
        viewBox="0 0 32.000000 32.000000" preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)" fill="#0ed356" stroke="none">
            <path d="M137 272 c-10 -10 -17 -24 -17 -30 0 -7 -16 -12 -40 -12 l-40 0 0
-95 0 -95 120 0 120 0 0 65 0 65 -40 0 -40 0 0 43 c0 30 -6 49 -18 60 -23 21
-25 21 -45 -1z m33 -32 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
10 6 0 10 -4 10 -10z m-80 -55 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5
15 10 15 6 0 10 -7 10 -15z m80 0 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8
5 15 10 15 6 0 10 -7 10 -15z m-80 -50 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10
15 0 8 5 15 10 15 6 0 10 -7 10 -15z m80 0 c0 -8 -4 -15 -10 -15 -5 0 -10 7
-10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m80 0 c0 -8 -4 -15 -10 -15 -5 0 -10
7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-160 -55 c0 -5 -4 -10 -10 -10 -5
0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m80 0 c0 -5 -4 -10 -10 -10
-5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m80 0 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z" />
        </g>
    </svg>
</template>
