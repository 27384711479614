<template>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="14.000000pt" height="14.000000pt"
            viewBox="0 0 32.000000 32.000000" preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)" fill="#0ed356" stroke="none">
                <path d="M110 280 c-34 -34 -21 -99 29 -144 21 -19 21 -19 42 0 72 65 59 164
    -21 164 -17 0 -39 -9 -50 -20z m78 -22 c18 -18 14 -53 -8 -79 l-20 -24 -20 24
    c-33 38 -21 91 20 91 9 0 21 -5 28 -12z" />
                <path d="M261 216 c-8 -10 -9 -16 -1 -21 5 -3 10 -31 10 -60 0 -51 -2 -56 -31
    -71 -30 -15 -34 -15 -80 7 -41 19 -53 20 -74 11 -14 -7 -28 -12 -30 -12 -10 0
    -4 107 6 117 16 16 -8 28 -26 13 -11 -9 -15 -34 -15 -91 0 -87 5 -93 59 -62
    29 17 31 17 77 -5 l48 -24 48 23 48 22 0 84 c0 64 -3 83 -14 83 -7 0 -19 -6
    -25 -14z" />
            </g>
        </svg>
</template>
