<template>
    <div>
   
      <Breadcrumbs :type="type" :header="title" :url="url" :head="head" />
      
      <trashed-message v-if="feature.deleted_at" class="mb-6" @restore="restore"> This Category has been deleted. </trashed-message>
      <div class="max-w-2xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="update">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.feature_title" :error="form.errors.feature_title" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Feature" required="true" placeholder="i.e Smoke Detectors"/>           
            <select-input v-model="form.group_id" :error="form.errors.group_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Group" >
              <option value=null >Select Group</option>
              <option v-for="propertyGroup in propertyGroups" :key="propertyGroup.id" :value='propertyGroup.id'>{{ propertyGroup.group_title }}</option>
            </select-input>            
            <text-input v-model="form.hierarchy" :error="form.errors.hierarchy" class="pb-8 pr-6 w-full lg:w-1/2" label="Hierarchy" placeholder="i.e 1" type="number" oninput="this.value = Math.abs(this.value)" min="0"/>
            
            <div class="pb-8 pr-6 w-full lg:w-1/2 grid grid-cols-12">
              <text-input v-model="form.icon" :error="form.errors.icon" class="col-span-11" label="Icon" placeholder="i.e 1" disabled/>
              <vue3-icon-picker v-model="form.icon" class="mt-5"/>
            </div> 
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Category" class="pb-8 pr-6 w-full lg:w-1/2"></status-toogle-button>          
          </div>
          
          <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
            <button v-if="!feature.deleted_at && permission.delete>0" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
            <Button v-if="permission.edit>0" :type="type" :name="head" :url="createUrl" :loading="form.processing"></Button>
            <!-- <loading-button :loading="form.processing" class="btn-indigo ml-auto" type="submit"><i class="fa fa-check-circle"></i> Update Province</loading-button> -->
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { ref, toRefs, inject  } from 'vue'
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import Icon from '@/Shared/Icon'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import TrashedMessage from '@/Shared/TrashedMessage'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import DeleteIcon from '@/Shared/DeleteIcon'
  import Button from '@/Shared/Button'
  import FileInput from '@/Shared/FileInput'
  import Vue3IconPicker from '@/Shared/components/Vue3IconPicker'
  export default {
    components: {
      Head,
      Icon,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      TrashedMessage,
      Breadcrumbs,
      DeleteIcon,
      StatusToogleButton,
      Button,
      FileInput,
      Vue3IconPicker
    },
    layout: Layout,
    props: {
      feature: Object,
      permission: Object,
      propertyGroups: Array,
    },
    remember: 'form',
    setup(props){
      const { feature, permission,propertyGroups } = toRefs(props)
      const type = ref('edit')
      const title = ref(feature.value.feature_title)
      const head = ref('Tourist Safety Property Features')
      const url = ref('/backend/safety-property-features')
      const createUrl = ref('')
      const elm = ref(this)
      const swal = inject('$swal')
      const form = useForm({
        group_id: feature.value.group_id,
        feature_title: feature.value.feature_title,
        icon: feature.value.icon,
        status: feature.value.status,
        hierarchy: feature.value.hierarchy,
        image_path:null,
      }) 
      const update = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}/${feature.value.id}`, {forceFormData: true,})
              title.value = form.feature_title
              Swal.fire(
                'Updated!',
                'Your infomation has been Updated.',
                'success'
              )
            }
          });
      }
      const destroy = () => {
        swal({
            title: 'Are you sure?',
            text: "Do you really want to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.delete(`${url.value}/${feature.value.id}`)
              Swal.fire(
                'Deleted!',
                'Your infomation has been deleted.',
                'success'
              )
            }
          });
      }
      const restore = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to restore this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Resore it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.put(`${url.value}/${feature.value.id}/restore`)
              Swal.fire(
                'Restored!',
                'Your infomation has been Restored.',
                'success'
              )
            }
          });
      }
      return {
        form,
        propertyGroups,
        type,
        title,
        head,
        url,
        createUrl,
        update,
        destroy,
        restore,
        swal,
        permission
      }
    }
  }
  </script>
  