<template>
    <div class="action_block">
        <i class="fa fa-up-down-left-right drag_cross"></i>
        <i class="fa fa-trash-alt" @click="remove_form_element"></i>
    </div>
</template>
<script setup>
    const emit = defineEmits(['removeFormElement'])
    const remove_form_element = () => {
        emit('removeFormElement')
    }
</script>
<style lang="scss" scoped>
    .action_block{
        position: absolute;
        display: none;
        right: 0; top: -35px;
        background-color: #f7f7f7;
        
        & > i{
            font-size: 8px;
            background-color: #39bdff;
            color: #fff;
            padding: 0 7px;
            height: 20px;
            line-height: 20px;
        }
    }
</style>