<template>
    <div>      
      <Breadcrumbs :type="type" :header="title" :url="url" />
      <div class="max-w-2xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="store">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.room_title" :error="form.errors.room_title" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Room" required="true" placeholder="i.e Bedroom"/>
            <text-input v-model="form.hierarchy" :error="form.errors.hierarchy" class="pb-8 pr-6 w-full lg:w-1/2" label="Hierarchy" placeholder="i.e 1" type="number" oninput="this.value = Math.abs(this.value)" min="0"/>
            <div class="pb-8 pr-6 w-full lg:w-1/2 grid grid-cols-12">
              <text-input v-model="form.icon" :error="form.errors.icon" class="col-span-11" label="Icon" placeholder="i.e 1" disabled/>
              <vue3-icon-picker v-model="form.icon" class="mt-5"/>
            </div> 
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Category" class="pb-8 pr-6 w-full lg:w-1/2 mt-5"></status-toogle-button>          
          </div>
          <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>         
        </form>
      </div>
    </div>
    
  </template>
  
  <script>
  import axios from 'axios';
  import { ref, toRefs,inject } from 'vue'
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Button from '@/Shared/Button'
  import FileInput from '@/Shared/FileInput'
  import Vue3IconPicker from '@/Shared/components/Vue3IconPicker'
  export default {
    props: {
    },
    components: {
      Head,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      Breadcrumbs,
      StatusToogleButton,
      Button,
      FileInput,
      Vue3IconPicker      
    },
    layout: Layout,
    remember: 'form',
    setup(props){
      const { propertyTypes } = toRefs(props)      
      const form = useForm({        
        room_title: null,
        icon: 'fas fa-upload',
        hierarchy: null,
        status: true,
      })    
       
      // const regions = ref([])
      const type = ref('create')
      const title = ref('Room Info')
      const url = ref('/backend/room-infos')
      const createUrl = ref('')  
      const swal = inject('$swal')  
      const store = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}`, {forceFormData: true,})
              Swal.fire(
                'Added!',
                'Your infomation has been Added.',
                'success'
              )
            }
          });
        
      }  
      return {
        form,
        type,
        title,
        url,
        createUrl,
        store
      }
    }
  }
  </script>
  