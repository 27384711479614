<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
      <div class="max-w-5xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="store">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.title" :error="form.errors.title" class="pb-8 pr-6 w-full lg:w-1/1" label="Name of Page" required="true" placeholder="i.e About"/>
            <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full lg:w-1/1" label="Slug of Page" required="true" placeholder="i.e about"/>
            <select-input v-model="form.menu_position" :error="form.errors.menu_position" class="pb-8 pr-6 w-full lg:w-1/1" label="Menu Position" >
              <option value=null >Select Menu Position</option>
              <option value='1' >Top</option>
              <option value='2' >Bottom</option>  
              <option value='3' >All</option>            
            </select-input>
            <select-input v-model="form.linktype" :error="form.errors.linktype" class="pb-8 pr-6 w-full lg:w-1/1" label="Link type" >
              <option value=null >Select Link type</option>
              <option value='Internal' >Internal</option>
              <option value='External' >External</option>              
            </select-input>
            <select-input v-model="form.type" :error="form.errors.type" class="pb-8 pr-6 w-full lg:w-1/1" label="Type" >
              <option value=null >Select type</option>
              <option value='1' >Ministry</option>
              <option value='2' >Agency</option>              
              <option value='3' >Others</option>              
            </select-input>
            <template v-if="form.linktype=='External'">
              <text-input v-model="form.url" :error="form.errors.url" class="pb-8 pr-6 w-full lg:w-1/1" label="External Url " required="true" placeholder="i.e "/>
            </template>
            <template v-if="form.linktype=='Internal'">
              <div class="pb-8 pr-6 w-full lg:w-1/1">
                <label class="form-label" for="content">Content <span class="custom-error">*</span></label>
                <vue-editor  v-model="form.content" />
              </div>
            </template>
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Page Status" class="pb-8 pr-6 w-full lg:w-1/1"></status-toogle-button>
          </div>
          <div class="w-full mt-3 px-6">
            <div class="flex flex-row items-center justify-center gap-2 bg-gray-400 text-white h-8 rounded-md cursor-pointer" @click="content_builder_status(true)">
              <i class="fa fa-cogs"></i>
              <span>Content Builder</span>
            </div>
          </div>
          <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>          
        </form>
      </div>
      <div v-if="content_builder_show" class="fixed left-0 top-0 w-full h-full bg-[#00000080] overflow-y-auto z-[9999]">
        <div class="relative w-[80%] h-auto left-[10%] top-10 bg-white rounded-md shadow-md">
          <span class="absolute flex items-center justify-center -right-[10px] -top-[10px] w-[30px] h-[30px] rounded-full bg-white text-red-400 border border-b-[#ccc] cursor-pointer z-10" @click="content_builder_status(false)">x</span>
          <ContentBuilder :content="form.page_layout_contents" @setContent="set_content" />
        </div>
      </div>      
    </div>
  </template>

  <script>
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import Button from '@/Shared/Button'
  import Layout from '@/Shared/Layout'
  import LoadingButton from '@/Shared/LoadingButton'
  import SelectInput from '@/Shared/SelectInput'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import TextareaInput from '@/Shared/TextareaInput'
  import TextInput from '@/Shared/TextInput'

  import ContentBuilder from '@/Pages/Admin/ContentBuilder/Index'
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import { ref, toRefs,inject } from 'vue'
  import { Quill, VueEditor } from "vue3-editor"
  export default {
    components: {
      Head,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      Breadcrumbs,
      StatusToogleButton,
      Button,
      TextareaInput,
      VueEditor,
      Quill,
      ContentBuilder
    },
    layout: Layout,
    remember: 'form',

    setup(props){
      const form = useForm({
        title:null,
        slug:null,
        content: null,
        menu_position: null,
        linktype: null,
        url: null,
        type: null,
        status: true
      })
      const type = ref('create')
      const title = ref('Page')
      const url = ref('/backend/pages')
      const createUrl = ref('')
      const content_builder_show = ref(false)
      const content_builder_status = (status) => {
        content_builder_show.value = status
        document.getElementsByTagName('html')[0].style.overflow = status?"hidden":"auto"
      }
      const set_content = (data) => {
        form.page_layout_contents = data
      }
      const swal = inject('$swal')
      const store = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}`, {forceFormData: true,})
              Swal.fire(
                'Added!',
                'Your infomation has been Added.',
                'success'
              )
            }
          });
        
      }
      return {
        form,
        type,
        title,
        url,
        createUrl,
        content_builder_show,
        content_builder_status,
        set_content,
        store
      }
    }
  }
  </script>
<style>

  .ql-toolbar .ql-formats .ql-image {
    display: none;
  }
</style>
