<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url" />
      <div class="flex items-center justify-between mb-6">
        <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
          <template v-slot:filter>
            <select v-model="form.trashed" class="form-select  w-28">
              <option :value="null" >Filter</option>
              <option value="with">With Trashed</option>
              <option value="only">Only Trashed</option>
            </select>
          </template>
          <template v-slot:page>
            <select v-model="form.length" class="form-select  w-20">
              <option value="6">6</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
              <option value="150">150</option>
              <option value="200">200</option>
            </select>
          </template>
          <template v-slot:status>
            <select v-model="form.status" class="form-select w-32">
              <option :value="null">Status</option>
              <option value="1">Active</option>
              <option value="2">Inactive</option>
            </select>
          </template>
        </search-filter>
        <Button :type="type" :name="title" :url="createUrl" v-if="permission.add>0"></Button>        
      </div>
      <div class="bg-white rounded-md shadow overflow-x-auto">
        <section class="overflow-hidden text-neutral-700">
          <div class="container mx-auto px-5 py-2 ">
            <div class="-m-1 flex flex-wrap md:-m-2" >
              <div class="flex w-1/3 flex-wrap" v-for="(photogallery,index) in photogalleries.data" :key="photogallery.id" style="position: relative;">
                <div class="w-full p-1 md:p-2 photo_thumbnail">
                  <img
                    alt="gallery"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    :src="photogallery.media1" />
                    <div class="info ">
                      <div class="credits">{{photogallery.name}}</div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <pagination class="mt-2 mb-2" :links="photogalleries.links" /> 
    </div>
  </template>
  
  <script>
  import { Head, Link } from '@inertiajs/inertia-vue3'
  import Icon from '@/Shared/Icon'
  import pickBy from 'lodash/pickBy'
  import Layout from '@/Shared/Layout'
  import throttle from 'lodash/throttle'
  import mapValues from 'lodash/mapValues'
  import Pagination from '@/Shared/Pagination'
  import SearchFilter from '@/Shared/SearchFilter'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import StatusLevel from '@/Shared/StatusLevel'
  import Button from '@/Shared/Button'
  import { ref, toRefs, inject } from 'vue'
  export default {
    components: {
      Head,
      Icon,
      Link,
      Pagination,
      SearchFilter,
      Breadcrumbs,
      StatusLevel,
      Button
    },
    layout: Layout,
    props: {
      filters: Object,
      photogalleries: Object,
      permission: Object,
    },
    setup(props){
      const { filters, photogalleries, permission } = toRefs(props)
      const form = ref({
        search: filters.value.search,
        trashed: filters.value.trashed,
        length: filters.value.length?filters.value.length:6,
        status: filters.value.status,
      })
      const type = ref('index')
      const title = ref('Media')
      const url = ref('')
      const createUrl = ref('/backend/photo-galleries/create')
      const reset = () => {
        form.value = mapValues(form, () => null)
      }
      return {
        filters,
        photogalleries,
        permission,
        form,
        type,
        title,
        url,
        createUrl,
        reset        
      }
    },
    watch: {
      form: {
        deep: true,
        handler: throttle(function () {          
          this.$inertia.get('/backend/photo-galleries', pickBy(this.form), { preserveState: true })
        }, 150),
      },
    }
  }
  </script>
  <style>
  .info, .bottom_info, .price_info {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      opacity: 0;		
      text-align: left;		
      padding: 15px;
      pointer-events: none;
      background: -webkit-gradient(linear, left bottom, left top, from(transparent), to(rgba(0,0,0,0.6)));
      background: -webkit-linear-gradient(bottom, transparent 0%, rgba(0,0,0,0.6) 100%);
      background: -moz-linear-gradient(bottom, transparent 0%, rgba(0,0,0,0.6) 100%);
      background: linear-gradient(to top, transparent 0%, rgba(0,0,0,0.6) 100%);
      -webkit-transition: opacity 0.4s;
      -moz-transition: opacity 0.4s;
      transition: opacity 0.4s;
      padding: 20px;
      margin: 10px;
      border-radius: 5px;
      color: #fff;
  }
.info .credits{overflow: hidden}
.info .credits .avatar, .info .credits .photo_info_wrap{}
.info .credits .avatar{float: left; width: 45px; height: 45px; background-color: #ccc; background-size: cover; border-radius: 50%; -moz-border-radius: 50%; -webkit-border-radius: 50%; text-shadow: 0 0 25px #000;}
.info .credits .photo_info_wrap{
    position: relative;
    float: left;
    max-width: -webkit-calc(100% - 55px);
    max-width: -moz-calc(100% - 55px);
    max-width: calc(100% - 55px);        
    vertical-align: middle;
    margin-left: 10px;
}
.info .credits .photo_info_wrap > a{display: inline-block; max-width: 100%; font-size: 15px; line-height: 16px; color: #fff; overflow: hidden; text-overflow: ellipsis; white-space: nowrap}
.info .credits .photo_info_wrap > a:hover{text-decoration: underline;}
.info .credits .photo_info_wrap > div{
    line-height: 1.4em;
    display: block;
    max-width: 100%;
    font-size: 14px;
    font-weight: bold;
    font-weight: 500;
    color: #fff;
    position: relative;
    position: static;
    pointer-events: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}    
.info .credits .photo_info_wrap > div span.by{display: inline-block; color: #fff; font-size: 12px; padding-right: 7px}
.info .credits .photo_info_wrap > div a{display: inline-block; color: #fff; font-size: 13px}
.info .credits .photo_info_wrap > a.photographer{font-size: 12px; padding-left: 5px}
.info .credits .photo_info_wrap > a.photo-cat{font-size: 12px; padding-left: 7px; color: #fff}
.info .credits .cover_photo_setup{
    position: absolute;
    right: 15px;
    color: #fff;
    text-shadow: 0 0 5px #000;
    font-size: 22px;    
    pointer-events: auto;
}
.photo_thumbnail:hover .info, .photo_thumbnail:hover .bottom_info, .photo_thumbnail:hover .price_info{opacity: 1.0}
.photo_thumbnail .sell_info{
    position: absolute;
    z-index: 9999;
    background: #CD0000;
    color: #fff;
    padding: 1px 5px 1px 10px;
    right: 0px;
    top: 40%;
    font-size: 13px;
    /* font-weight: bold; */
    border-radius: 25px 0 0 25px;
}
</style>