<template>
  <div class="flex items-center">
    <div class="flex w-40  bg-white rounded shadow"> 
      <!-- <slot name="filter"/>     -->
      <slot name="search"/> 
      <i class="fa fa-search"></i>
      <input class="relative px-4 py-2 w-full rounded-r focus:none" autocomplete="off" type="text" name="search" placeholder="Search…" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" />      
    </div>    
    <slot name="status"/> 
    <slot name="page"/>   
     
    <!-- <button class="ml-3 text-gray-500 hover:text-gray-700 focus:text-indigo-500 text-sm" type="button" @click="$emit('reset')"><re-icon></re-icon></button> -->
  </div>
  <slot name="stat"/>  
</template>

<script>
import Dropdownnew from '@/Shared/Dropdownnew'
import SelectInputSearch from '@/Shared/SelectInputSearch'
import ReIcon from '@/Shared/ReIcon'
import TextDatePicker from '@/Shared/TextDatePicker'
import SelectInput from '@/Shared/SelectInput'
export default {
  components: {
    Dropdownnew,
    ReIcon,
    SelectInputSearch,
    TextDatePicker,
    SelectInput
  },
  props: {
    modelValue: String,
    maxWidth: {
      type: Number,
      default: 300,
    },
  },
  emits: ['update:modelValue', 'reset'],
}
</script>
<style lang="scss" scoped>
  i{
    text-align: center;
    height: 33.33px;
    display: flex;
    align-items: center;
    padding-left: 15px;
  }
</style>