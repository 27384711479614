<template>
    <div class="emp_organogram_block">
        <ContentHeaderSection :title="'Organogram List'" :icon="'fa fa-bar'" />
        <div class="list_block mx-0 my-3 mt-2">
            <!-- {{ organogram }} -->
            <ul  v-if="(organogram.length>0)" >
                <li v-for="item in organogram">
                    <div  v-if="item.section!=null" class="flex items-center gap-2 px-3 section_block">
                        <i class="fa fa-info-circle"></i>
                        <span>{{ item.section.name }}</span>
                    </div>
                    <ul v-if="(item.designations.length>0 && item.section!=null)" class="px-2 py-3">
                        <li v-for="designation in item.designations">
                            <div :class="['flex','items-center','gap-2','px-2','designation_block',{active:sel_designations[designation.id]}]" @click="add_designation(designation.id,designation.name,designation.laravel_through_key)">
                                <div class="input_block">

                                    <i class="fa fa-check check"></i>
                                    <i class="fa fa-times remove"></i>
                                </div>
                                <div class="designation">{{ designation.name }}

                                </div>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>

            <div v-else class="office_block mt-4 p-4" >
                <div class="empty_content_block" >
                    <div >
                        <i class="fa fa-box-open"></i>
                    </div>
                    <div class="mt-2" >There is no data. Choose office to get Organogram List</div>
                </div>
            </div>
        </div>
        <!-- <pre>
        {{ designation_ids }}
        {{ designation_data }}
        </pre> -->
        <!-- {{ organogram }} -->
        <!-- {{ Object.keys(sel_designations).length }} -->
        <!-- {{ geo_infos }} -->
        <!-- {{ designation_id_infos }} -->
        <!-- <FloatingActionBtn :loader="preloader" :icon="'fa fa-check'" :message="message" @dataSubmit="data_submit" @removeMessage="remove_msg" /> -->
    </div>
</template>
<script>
    import axios from 'axios'
    import { ref, toRefs, inject } from 'vue'
    import ContentHeaderSection from "@/Shared/ContentHeaderSection"
    import FloatingActionBtn from "@/Shared/Buttons/FloatingActionBtn"
    export default {
        name: 'EmpOrganogramBlock',
        props: {
            service_id: Number,
            geo_infos: Object,
            organogram: Array,
            designation_ids: Object,
            designation_data:Array
        },
        components: {
            ContentHeaderSection,
            FloatingActionBtn
        },
        setup(props, { emit }){
            const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
            const { service_id, geo_infos, organogram, designation_ids,designation_data } = toRefs(props)
            const preloader = ref(false)
            const sel_designations = ref(designation_ids)
            const sel_designations_data = ref(designation_data)
            const swal = inject('$swal')
            const message = ref('')

            const add_designation = (id,name,office_section_map_id) => {
                if(sel_designations.value[id]){
                    delete sel_designations.value[id]
                    deleteObj(id);
                }
                else{
                    sel_designations.value[id] = true
                    let obj = {
                        serial:sel_designations_data.value.length+1,
                        id: id,
                        can_print:0,
                        draft_print:0,
                        summary_view:0,
                        name: name,
                        service_id: service_id,
                        decision: [],
                        alldecision:[],
                        office_section_map_id:office_section_map_id,
                    }
                 sel_designations_data.value.push(obj)
                }
                emit('update_designation', sel_designations_data,sel_designations)


            }
            const data_submit = () => {
                swal({
                    title: 'Are you sure?',
                    text: "You want to proceed with this action",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'Cancel',
                }).then((result) => {
                    if (result.isConfirmed) {
                        preloader.value = true
                        axios.post(`/${backendPrefix.value}/employees/designation`, {
                            service_id: service_id.value,
                            geos: geo_infos.value,
                            designations: sel_designations.value
                        }).then( async (response) => {
                            message.value = response.data.message
                            preloader.value = false
                        }).catch(error => {
                            preloader.value = false
                        })
                    }
                });

            }
            const remove_msg = () => {
                message.value = ''
            }
            const deleteObj = (id) => {
                var index = sel_designations_data.value.findIndex(function(o){
                    return o.id === id;
                })
                if (index !== -1) sel_designations_data.value.splice(index, 1);
            }
            return {
                preloader,
                service_id,
                geo_infos,
                organogram,
                sel_designations,
                message,
                add_designation,
                data_submit,
                remove_msg,
                sel_designations_data,
                designation_data,
                deleteObj
            }
        }
    }
</script>
<style lang="scss" scoped>
.emp_organogram_block{
    position: relative;
    padding-bottom: 50px;

    :deep(.action_btn) {
        bottom: 10px
    }
    .list_block{
        border: 1px solid #ddd;
        border-radius: 5px;
        .section_block {
            height: 30px;
            background-color: #f7f7f7;
            border-bottom: 1px solid #ddd;
            border-radius: 5px 5px 0 0;
        }
        .designation_block{
            height: 34px;
            cursor: pointer;
            & > .input_block{
                width: 22px;
                height: 22px;
                display: flex;
                align-items: center;
                border: 1px solid #999;
                justify-content: center;
                border-radius: 5px;

                & > i{
                    display: none;
                    font-size: 10px;
                }
            }

            &.active,&:hover{
                color: #006699;
                & > .input_block{
                    border-color: #006699;
                    & > i.remove{
                        display: none;
                    }
                    & > i.check{
                        display: block;
                        color: #006699
                    }
                }
            }

            &.active{
                &:hover{
                    color: #CD0000;
                    & > .input_block{
                        border-color: #CD0000;
                        & > i.check{
                            display: none;
                        }
                        & > i.remove{
                            display: block;
                            color: #CD0000;
                        }
                    }
                }
            }
        }
    }
}

.office_block {
    border-radius: 10px;
}
.empty_content_block > div i, .loader_content_block > div i {
    font-size: 34px;
    color: #ccc;
}
.empty_content_block > div, .loader_content_block > div{
    display: block;
    text-align: center;
}
</style>
