<template>
    <div class="form_layout_block">
        <!-- {{ data  }} -->        
        <draggable :list="data" handle=".drag_cross">
            <div :class="['mb-5',{selected:sel_element_index==index}]" v-for="(item,index) in data" :key="index" @click="sel_input_field_property(item.type,index)" @mouseover="sel_form_element(index)" @mouseleave="sel_form_element(-1)">            
                <TxtInputFormBlock v-if="item.type=='txt_input'" :data="item" :index="index"  @modifyFormElement="modify_form_element" @removeFormElement="remove_form_element" />

                <NumberInputFormBlock v-else-if="item.type=='number_input'" :data="item" :index="index"  @modifyFormElement="modify_form_element" @removeFormElement="remove_form_element" />

                <TextAreaFormBlock v-else-if="item.type=='textarea'" :data="item" :index="index"  @modifyFormElement="modify_form_element" @removeFormElement="remove_form_element" />

                <SelectBoxFormBlock v-else-if="item.type=='selectbox'" :data="item" :index="index"  @modifyFormElement="modify_form_element" @removeFormElement="remove_form_element" />

                <CheckboxInputFormBlock v-else-if="item.type=='checkbox'" :data="item" :index="index"  @modifyFormElement="modify_form_element" @removeFormElement="remove_form_element" />

                <RadioInputFormBlock v-else-if="item.type=='radio'" :data="item" :index="index"  @modifyFormElement="modify_form_element" @removeFormElement="remove_form_element" />

                <DatePickerInputFormBlock v-else-if="item.type=='datepicker'" :data="item" :index="index" @modifyFormElement="modify_form_element" @removeFormElement="remove_form_element" />

                <template v-else-if="item.type=='gridlayout'">
                    <div :class="['grid','grid-cols-' + item.column.length ]">
                        <template v-for="grid in item.column">
                            <GridLayoutBlock :data="grid" :index="index" @modifyFormElement="modify_form_element" @removeFormElement="remove_form_element" />
                        </template>
                    </div>
                </template>
            </div>
        </draggable>
    </div>
</template>
<script setup>
    import TxtInputFormBlock from './Forms/TextInput'
    import NumberInputFormBlock from './Forms/NumberInput'
    import TextAreaFormBlock from './Forms/TextArea'
    import SelectBoxFormBlock from './Forms/SelectBox'
    import CheckboxInputFormBlock from './Forms/CheckboxInput'
    import RadioInputFormBlock from './Forms/RadioInput'
    import DatePickerInputFormBlock from './Forms/DatePickerInput'
    import GridLayoutBlock from './Layouts/GridLayout'
    import { VueDraggableNext as draggable } from 'vue-draggable-next'
    import { ref } from 'vue'
    const emit = defineEmits(['addFormElement','selInputFieldProperty'])
    const props = defineProps({
        data: Array
    })
    const sel_element_index = ref('-1')

    const sel_form_element = (index) => {
        sel_element_index.value = index
    }

    const sel_input_field_property = (type, index) => {
        emit('selInputFieldProperty', type, index)
    }
    
    const modify_form_element = (data, index) => {
        emit('modifyFormElement', data, index)
    }

    const remove_form_element = (index) => {
        emit('removeFormElement', index)
    }
</script>
<style lang="scss" scoped>
    .form_layout_block{        
        display: block;
        position: sticky;
        top: 34px;
        border: 1px solid #eee;
        border-top: none;
        padding: 15px;
        border-radius: 0 0 5px 5px;
        & > div > div{
            position: relative;
            border: 1px dashed #ccc;
            border-radius: 5px;
            padding: 15px;            

            &.selected{
                border: 1px solid #39bdff;

                &:deep(.action_block){
                    display: flex;
                }

                &:first-child {                
                    &:deep(.action_block){
                        top: auto;
                        bottom: -35px;
                    }
                }
            }            
        }
    }
</style>