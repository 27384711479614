<template>
    <div>
   
      <Breadcrumbs :type="type" :header="title" :url="url" :head="head" />
      <div class="flex justify-start mb-8 max-w-3xl">
        <img v-if="amenity.image_path" class="block ml-4 w-8 h-8 rounded-full" :src="amenity.logo" />
      </div> 
      <trashed-message v-if="amenity.deleted_at" class="mb-6" @restore="restore"> This amenity has been deleted. </trashed-message>
      <div class="max-w-2xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="update">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.amenity_title" :error="form.errors.amenity_title" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Amenity" required="true" placeholder="i.e Bathtub"/>
            <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Slug" required="true" placeholder="i.e bathtub"/>
            <select-input v-model="form.group_id" :error="form.errors.group_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Group" >
              <option value=null >Select Group</option>
              <option v-for="amenityGroup in amenityGroups" :key="amenityGroup.id" :value='amenityGroup.id'>{{ amenityGroup.group_title }}</option>
            </select-input>
            <text-input v-model="form.info" :error="form.errors.info" class="pb-8 pr-6 w-full lg:w-1/2" label="Info" placeholder="i.e 1"/>
            <text-input v-model="form.hierarchy" :error="form.errors.hierarchy" class="pb-8 pr-6 w-full lg:w-1/2" label="Hierarchy" placeholder="i.e 1" type="number" oninput="this.value = Math.abs(this.value)" min="0"/>
            <file-input v-model="form.image_path" :error="form.errors.image_path" class="pb-8 pr-6 w-full lg:w-1/2" type="file" accept="image/*" label="Image" />
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Amenity" class="pb-8 pr-6 w-full lg:w-1/2"></status-toogle-button>          
          </div>
          
          <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
            <!-- <button v-if="!amenity.deleted_at && permission.delete>0" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button> -->
            <Button v-if="permission.edit>0" :type="type" :name="head" :url="createUrl" :loading="form.processing"></Button>
            <!-- <loading-button :loading="form.processing" class="btn-indigo ml-auto" type="submit"><i class="fa fa-check-circle"></i> Update Province</loading-button> -->
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { ref, toRefs, inject  } from 'vue'
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import Icon from '@/Shared/Icon'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import TrashedMessage from '@/Shared/TrashedMessage'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import DeleteIcon from '@/Shared/DeleteIcon'
  import Button from '@/Shared/Button'
  import FileInput from '@/Shared/FileInput'
  export default {
    components: {
      Head,
      Icon,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      TrashedMessage,
      Breadcrumbs,
      DeleteIcon,
      StatusToogleButton,
      Button,
      FileInput
    },
    layout: Layout,
    props: {
      amenity: Object,
      permission: Object,
      amenityGroups: Array,
    },
    remember: 'form',
    setup(props){
      const { amenity, amenities, permission,amenityGroups } = toRefs(props)
      const type = ref('edit')
      const title = ref(amenity.value.amenity_title)
      const head = ref('Amenity')
      const url = ref('/backend/amenities')
      const createUrl = ref('')
      const elm = ref(this)
      const swal = inject('$swal')
      const form = useForm({
        pid: amenity.value.pid,
        group_id: amenity.value.group_id,
        amenity_title: amenity.value.amenity_title,
        slug: amenity.value.slug,
        status: amenity.value.status,
        hierarchy: amenity.value.hierarchy,
        image_path:null,
      }) 
      const update = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}/${amenity.value.id}`, {forceFormData: true,})
              title.value = form.amenity_title
              Swal.fire(
                'Updated!',
                'Your infomation has been Updated.',
                'success'
              )
            }
          });
      }
      const destroy = () => {
        swal({
            title: 'Are you sure?',
            text: "Do you really want to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.delete(`${url.value}/${amenity.value.id}`)
              Swal.fire(
                'Deleted!',
                'Your infomation has been deleted.',
                'success'
              )
            }
          });
      }
      const restore = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to restore this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Resore it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.put(`${url.value}/${amenity.value.id}/restore`)
              Swal.fire(
                'Restored!',
                'Your infomation has been Restored.',
                'success'
              )
            }
          });
      }
      return {
        form,
        amenities,
        amenityGroups,
        type,
        title,
        head,
        url,
        createUrl,
        update,
        destroy,
        restore,
        swal,
        permission
      }
    }
  }
  </script>
  