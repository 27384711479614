<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
      <div class="max-w-5xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="store">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.title" :error="form.errors.title" class="pb-8 pr-6 w-full lg:w-1/2" @keyup="slugBuild" label="Title" required="true" placeholder="i.e The history of the Philippines in 12 minutes"/>
            <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full lg:w-1/2" label="Slug" required="true" placeholder="i.e the-history-of-the-philippines-in-12-minutes"/>
            <text-input v-model="form.embed_code" :error="form.errors.embed_code" class="pb-8 pr-6 w-full lg:w-1/2" label="Embed Code" required="true" placeholder="i.e https://www.youtube.com/watch?v=P-I4Bay5SXo"/>
            <select-input v-model="form.cat_id" :error="form.errors.cat_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Category" required="true">
              <option :value=0>Select Category</option>
              <option v-for="tutorialC in tutorialCat" :key="tutorialC.id" :value='tutorialC.id'>{{ tutorialC.title }}</option>
            </select-input>
            <file-input v-model="form.cover_photo" :error="form.errors.cover_photo" class="pb-8 pr-6 w-full lg:w-1/1" type="file" accept="image/*" label="Cover Photo" />
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status" class="pb-8 pr-6 w-full lg:w-1/1"></status-toogle-button>
          </div>
          <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>
        </form>
      </div>
    </div>
  </template>

  <script>
  import Breadcrumbs from '@/Shared/Breadcrumbs'
import Button from '@/Shared/Button'
import FileInput from '@/Shared/FileInput'
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import TextareaInput from '@/Shared/TextareaInput'
import TextInput from '@/Shared/TextInput'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject } from 'vue'
  export default {
    components: {
      Head,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      Breadcrumbs,
      StatusToogleButton,
      Button,
      TextareaInput,
      FileInput
    },
    layout: Layout,
    remember: 'form',
    props:{
        tutorialCat: Array
    },
    setup(props){
        const { tutorialCat } = toRefs(props)
        const form = useForm({
            title:null,
            cat_id: 0,
            embed_code:null,
            slug: null,
            status: true,
            cover_photo:null
        })
        const type = ref('create')
        const title = ref('Tutorials')
        const url = ref('/backend/tutorials')
        const createUrl = ref('')
        const swal = inject('$swal')
      const store = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}`, {forceFormData: true,})
              Swal.fire(
                'Added!',
                'Your infomation has been Added.',
                'success'
              )
            }
          });
        
      }
        const slugBuild = () => {
            form.slug = form.title.replace(/\s/g,'-').toLowerCase()
        }
        return {
            form,
            type,
            title,
            url,
            createUrl,
            store,
            slugBuild,
            tutorialCat
        }
    }
  }
  </script>
