<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>       
      <trashed-message v-if="officerole.deleted_at" class="mb-6" @restore="restore"> This Employee has been deleted. </trashed-message>
      <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="update">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <select-input v-model="form.office_layer_id" :error="form.errors.office_layer_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Office layer">
              <option value='0' >Select Office Layer</option>
              <option v-for="officelayer in officelayers" :key="officelayer.id" :value='officelayer.id'>{{ officelayer.office_layer_name }}</option>
            </select-input>
            <select-input v-model="form.ministry_id" :error="form.errors.ministry_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Ministry">
              <option value='0' >Select Ministry</option>
              <option v-for="ministry in ministries" :key="ministry.id" :value='ministry.id'>{{ ministry.ministry_name }}</option>
            </select-input>
            <select-input-function v-model="form.region_id" :error="form.errors.region_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Region"  :action="loadProvinces">
              <option value='0' >Select Region</option>
              <option v-for="region in regions" :key="region.id" :value='region.id'>{{ region.region_name }}</option>
            </select-input-function>
            <select-input-function v-model="form.provience_id" :error="form.errors.provience_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Provience" :action="loadMunicipality">
              <option value='0' >Select Provience</option>
              <option v-for="province in provinces" :key="province.id" :value='province.id'>{{ province.provience_name }}</option>
            </select-input-function> 
            <select-input-function v-model="form.municipality_id" :error="form.errors.municipality_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Municipality" :action="loadBarangay">
              <option value='0' >Select Municipality</option>
              <option v-for="municipal in municipality" :key="municipal.id" :value='municipal.id'>{{ municipal.municipality_name }}</option>
            </select-input-function>
            <select-input v-model="form.barangay_id" :error="form.errors.barangay_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Barangay" >
              <option value='0' >Select Barangay</option>
              <option v-for="barangay in barangays" :key="barangay.id" :value='barangay.id'>{{ barangay.barangay_name }}</option>
            </select-input>  
            <select-input v-model="form.office_id" :error="form.errors.office_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Office" >
              <option value='0' >Select Office</option>
              <option v-for="office in offices" :key="office.id" :value='office.id'>{{ office.office_name }}</option>
            </select-input>  
            <select-input v-model="form.employee_id" :error="form.errors.employee_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Employee">
              <option value='0' >Select Employee</option>
              <option v-for="employee in employees" :key="employee.id" :value='employee.id'>{{ employee.employee_name }}</option>
            </select-input>
            <select-input v-model="form.role_id" :error="form.errors.role_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Role">
              <option value='0' >Select Role</option>
              <option v-for="role in roles" :key="role.id" :value='role.id'>{{ role.role_name }}</option>
            </select-input>
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status Of Office Role" class="pb-8 pr-6 w-full lg:w-1/2"></status-toogle-button>          
          </div>
          <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
            <button v-if="!officerole.deleted_at" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
            <loading-button :loading="form.processing" class="btn-indigo ml-auto" type="submit">Update Office Role</loading-button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { Head, Link } from '@inertiajs/inertia-vue3'
  import Icon from '@/Shared/Icon'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import TrashedMessage from '@/Shared/TrashedMessage'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import DeleteIcon from '@/Shared/DeleteIcon'
  import SelectInputFunction from '@/Shared/SelectInputFunction'
  import TextDatePicker from '@/Shared/TextDatePicker'
  export default {
    components: {
      Head,
      Icon,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      TrashedMessage,
      StatusToogleButton,
      Breadcrumbs,
      DeleteIcon,
      SelectInputFunction,
      TextDatePicker
    },
    layout: Layout,
    props: {
      regions: Array,
      provinces: Array,
      municipality: Array,
      barangays: Array,
      ministries: Array,
      officelayers: Array,
      offices: Array,
      employees: Array,
      roles: Array,
      officerole: Object
    },
    remember: 'form',
    data() {     
      return {
        form: this.$inertia.form({
          id: this.officerole.id,
          office_layer_id: this.officerole.office_layer_id,
          ministry_id: this.officerole.ministry_id,
          region_id: this.officerole.region_id,
          provience_id: this.officerole.provience_id,
          municipality_id: this.officerole.municipality_id,
          barangay_id: this.officerole.barangay_id,
          office_id: this.officerole.office_id,
          employee_id: this.officerole.employee_id,
          role_id: this.officerole.role_id,
          status: this.officerole.status
        }),
        type:"edit",
        title:this.officerole.id,
        head:'Office Role',
        url:'/backend/officeroles',
      }
    },
    methods: {
      statusCheck(){
        if(this.officerole.status==1){
          this.form.status=true;
          return true;
        }else{
          this.form.status=false;
          return false;
        }
      },
      update() {
        this.form.put(`/backend/officeroles/${this.officerole.id}`)
      },
      destroy() {
        if (confirm('Do you really want to delete this Office Role?')) {
          this.$inertia.form.delete(`/backend/officeroles/${this.officerole.id}`)
        }
      },
      restore() {
        if (confirm('Are you sure you want to restore this Office Role?')) {
          this.$inertia.put(`/backend/officeroles/${this.officerole.id}/restore`)
        }
      },
      async loadProvinces(event){
        if(event.target.value>0){
          let data={
            geo_region_id:event.target.value
          }
          await axios.post('../provinces/activeprovinces', data)
          .then(function (response) {           
              this.provinces = response.data;
          }.bind(this));   
        }else{
          this.provinces =[];
        }
      },
      async loadMunicipality(event){
        if(event.target.value>0){
          let data={
            geo_provience_id:event.target.value
          }
          await axios.post('../municipalities/activemunicipalities', data)
          .then(function (response) {
              this.municipality = response.data;
          }.bind(this));   
        }else{
          this.municipality =[];
        }
      },
      async loadBarangay(event){
        if(event.target.value>0){
          let data={
            geo_municipality_id:event.target.value
          }
          await axios.post('../barangays/activebarangays', data)
          .then(function (response) {
            ////console.log(response);
              this.barangays = response.data;
          }.bind(this));   
        }else{
          this.barangays =[];
        }
      },
      
    },
    created: function(){
      this.statusCheck();
    }
  }
  </script>
  