<template>
    <div>
        <div class="flex items-center gap-2 px-4 caption_header_block">
            <i class="fa fa-cogs"></i>
            <span>Organogram Setup</span>
        </div>
        <div class="p-5 organogram_setup_block">
            <template v-for="(content,s_index) in data">
                <OfficeSection :index="s_index" :content="content.section" />
                <div class="designation_tree ml-5">
                    <template v-for="(designation,d_index) in content.designations">
                        <OfficeDesignation :index="s_index" :_index="d_index" :content="designation" />
                    </template>
                    <OfficeDesignation :index="s_index" :content="''" />
                </div>
            </template>
            <OfficeSection :content="''" />
        </div>
    </div>
</template>
<script>
    import OfficeSection from './OfficeSection'
    import OfficeDesignation from './OfficeDesignation'
    export default {
        name: "OfficeOrganogram",
        components: {
            OfficeSection,
            OfficeDesignation
        },
        data() {
            return {
                data: []
            }
        },
        methods: {
            add_section: function(content) {
                let obj = {
                    section: content,
                    designations: []
                }
                this.data.push(obj)
            },
            update_section: function(s_index,content){
                this.data[s_index].section = content
            },
            add_designation: function(s_index,content){
                this.data[s_index].designations.push(content)
            },
            update_designation: function(s_index,d_index,content){
                this.data[s_index].designations[d_index] = content
            }
        }
    }
</script>
<style lang="scss" scoped>
    .caption_header_block{
        height: 36PX;        
        font-size: 14px;
        font-weight: 600;        
        border-bottom: 1px solid #ddd;
        background: #f7f7f7;
    }

    .designation_tree{
        position: relative;
        margin-left: 9px;
        &::before{
            position: absolute;
            content: "";
            width: 5px;
            height: calc(100% - 8px);
            border-left: 1px solid #999;
            border-bottom: 1px solid #999;
            border-radius: 0 0 0 5px;
            left: 0;
            top: -6px;
        }
        & > div{
            position: relative;
            padding-left: 18px;

            &::before{
                position: absolute;
                content: "";
                width: 18px;
                height: 5px;                
                border-bottom: 1px solid #999;
                border-left: 1px solid #999;
                border-radius: 0 0 0 5px;
                left: 0px;
                top: 11px;
            }            
        }
    }
</style>