<template>
    <div>Are you still with us?</div>
</template>

<script>
import axios from 'axios';
export default {
    components: {
        axios
    },
    name: 'AutoLogout',
    data: function () {
        return {
            events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
            warningTimer: null,
            logoutTimer: null,
            warningZone: false,
        }
    },
    mounted() {
        this.events.forEach(function (event) {
            window.addEventListener(event, this.resetTimer);
        }, this);
        this.setTimers();
    },
    destroyed() {
        this.events.forEach(function (event) {
            window.removeEventListener(event, this.resetTimer);
        }, this);
        this.resetTimer();
    },
    methods: {
        setTimers: function() {
            this.warningTimer = setTimeout(this.warningMessage, 9 * 60 * 1000); // 14 minutes - 14 * 60 * 1000
            this.logoutTimer = setTimeout(this.logoutUser, 10 * 60 * 1000); // 15 minutes - 15 * 60 * 1000
            this.warningZone = false;
        },
        warningMessage: function() {
            this.warningZone = true;
        },
        logoutUser: function() {
            axios.delete('/backend/logout');  
            location.reload(); 
        },
        resetTimer: function() {
            clearTimeout(this.warningTimer);
            clearTimeout(this.logoutTimer);
            this.setTimers();
        }
    }
}
</script>