<template>
    <div class="properties_block">
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Type</label>
            <div>Dynamic Geo Input</div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Name</label>
            <div><input type="text" class="px-2" v-model="property_form_data.name" placeholder="Enter Name" @keyup="add_properties" @change="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Label</label>
            <div><input type="text" class="px-2" v-model="property_form_data.label" placeholder="Enter Label" @keyup="add_properties" @change="add_properties" /></div>
        </div>        
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Required</label>
            <div class="form-check form-switch">
                <input class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.required" @change="add_properties" />
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>For Certificate <sup v-if="certificate_data[property_form_data.name]">*</sup></label>
            <div class="form-check form-switch">                
                <input class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.for_certificate" @change="add_properties" />
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Auto Fill</label>
            <div>
                <select class="px-2" v-model="property_form_data.auto_fill_by" @change="add_properties">
                    <option value="0">None</option>
                    <option value="1">By Profile</option>
                    <option value="2">By Office</option>
                </select>
            </div>
        </div>
        <div v-if="qr_data" class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>For QR Data <sup v-if="qr_data[property_form_data.name]">*</sup></label>
            <div class="form-check form-switch">
                <input class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.for_qr" @change="add_properties" />
            </div>
        </div>
        <div v-if="!property_form_data.hide_municipality" class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Hide Barangay</label>
            <div class="form-check form-switch">
                <input class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.hide_barangay" @change="add_properties" />
            </div>
        </div>
        <div v-if="!property_form_data.hide_provience && property_form_data.hide_barangay" class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Hide Municipality/City</label>
            <div class="form-check form-switch">
                <input class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.hide_municipality" @change="add_properties" />
            </div>
        </div>
        <div v-if="!property_form_data.hide_region && property_form_data.hide_municipality" class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Hide Provience</label>
            <div class="form-check form-switch">
                <input class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.hide_provience" @change="add_properties" />
            </div>
        </div>
        <div v-if="property_form_data.hide_provience" class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Hide Region</label>
            <div class="form-check form-switch">
                <input class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.hide_region" @change="add_properties" />
            </div>
        </div>
    </div>
</template>
<script setup>
    import { ref, watch } from 'vue'
    const props = defineProps({
        data: Object,
        certificate_data: Object,
        qr_data: Object,
        field_validation_types: Object,
    })
    const emit = defineEmits(['addProperties','modifyCertificateData','removeCertificateData'])
    const name_key_press = ref(false)
    const property_form_data = ref({
        name: props.data && props.data.name?props.data.name:'',
        type: props.data && props.data.type?props.data.type:'geo',
        value: props.data && props.data.value?props.data.value:'',
        label: props.data && props.data.label?props.data.label:'',
        required: props.data && props.data.required?props.data.required:false,
        auto_fill_by: props.data && props.data.auto_fill_by?props.data.auto_fill_by:0,
        for_certificate: props.data && props.data.for_certificate?props.data.for_certificate:false,
        for_qr: props.data && props.data.for_qr?props.data.for_qr:false,
        hide_barangay: props.data && props.data.hide_barangay?props.data.hide_barangay:false,
        hide_municipality: props.data && props.data.hide_municipality?props.data.hide_municipality:false,
        hide_provience: props.data && props.data.hide_provience?props.data.hide_provience:false,
        hide_region: props.data && props.data.hide_region?props.data.hide_region:false
    })

    watch(() => props.data, (updateData, oldValue) => {
        // //console.log(updateData, oldValue)
        name_key_press.value = false
        property_form_data.value = {
            name: updateData && updateData.name?updateData.name:'',
            type: updateData && updateData.type?updateData.type:'geo',
            value: updateData && updateData.value?updateData.value:'',
            label: updateData && updateData.label?updateData.label:'',
            required: updateData && updateData.required?updateData.required:false,
            auto_fill_by: updateData && updateData.auto_fill_by?updateData.auto_fill_by:0,
            for_certificate: updateData && updateData.for_certificate?updateData.for_certificate:false,
            for_qr: updateData && updateData.for_qr?updateData.for_qr:false,
            hide_barangay: updateData && updateData.hide_barangay?updateData.hide_barangay:false,
            hide_municipality: updateData && updateData.hide_municipality?updateData.hide_municipality:false,
            hide_provience: updateData && updateData.hide_provience?updateData.hide_provience:false,
            hide_region: updateData && updateData.hide_region?updateData.hide_region:false
        }
    })

    watch(() => property_form_data.value.name, (updateName, oldName) => {
        // //console.log(updateName, oldName)
        if(updateName!==oldName && name_key_press.value) {
            let col_name_old = oldName.replace(/\s/g,'_').toLowerCase()
            emit('removeCertificateData', col_name_old)
            
            let col_name_new = updateName.replace(/\s/g,'_').toLowerCase()
            emit('modifyCertificateData', col_name_new)
        }
    })

    watch(() => property_form_data.value.for_certificate, (updateData, oldData) => {
        //console.log(updateData, oldData)
        /**
         * Certificate data push
         */
        let col_name = property_form_data.value.name.replace(/\s/g,'_').toLowerCase()
        if(updateData) emit('modifyCertificateData', col_name)
        else emit('removeCertificateData', col_name)        
    })

    watch(() => property_form_data.value.for_qr, (updateData, oldData) => {
        //console.log(updateData, oldData)
        /**
         * QR data push
         */
        let col_name = property_form_data.value.name.replace(/\s/g,'_').toLowerCase()
        if(updateData) emit('modifyQrData', col_name)
        else emit('removeQrData', col_name)        
    })

    const add_properties = () => {
        name_key_press.value = true
        emit('addProperties', property_form_data.value)        
    }
</script>
<style lang="scss" scoped>
    .properties_block{
        font-size: 12px;    
        input,select{
            width: 100%;
            height: 26px;
            border: 1px solid #ddd;
            border-radius: 5px;

            &.switch_btn{
                width: 50px;
                height: 26px;
                border-radius: 25px;
                margin-left: -30px;
            }
        }
    }
</style>