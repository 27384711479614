<template>
    <div class="grid_layout_block">
        <div class="flex flex-row items-center gap-1">
            <ActionButtons @removeFormElement="remove_form_element" />
        </div>
        <!-- {{ data }} -->
        <div :class="['layout_box',{preview:preview}]">{{ data.name }}</div>
    </div>
</template>
<script setup>
    import ActionButtons from '../ActionButtons'
    const emit = defineEmits(['modifyFormElement','removeFormElement'])
    const props = defineProps({
        data: Object,
        index: Number,
        preview: Boolean
    })

    const remove_form_element = () => {
        emit('removeFormElement', props.index)
    }
</script>
<style lang="scss" scoped> 
    .grid_layout_block{
        display: block;
        position: relative;
        & > .layout_box{
            input{
                pointer-events: none;
                padding: 2px 8px;
                width: 100%; border: 1px solid #ddd;
                border-radius: 5px;
            }

            &.preview > input{
                pointer-events: all;
            }
        }
    }
</style>