<template>
    <div class="properties_block">
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Type</label>
            <div>Datepicker Input</div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Name</label>
            <div><input type="text" class="px-2" v-model="property_form_data.name" placeholder="Enter Name" @keyup="add_properties" @change="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Label</label>
            <div><input type="text" class="px-2" v-model="property_form_data.label" placeholder="Enter Label" @keyup="add_properties" @change="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Required</label>
            <div class="form-check form-switch">
                <input class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.required" @change="add_properties" />
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>For Certificate <sup v-if="certificate_data[property_form_data.name]">*</sup></label>
            <div class="form-check form-switch">
                <input class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.for_certificate" @change="add_properties" />
            </div>
        </div>
        <div v-if="qr_data" class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>For QR Data <sup v-if="qr_data[property_form_data.name]">*</sup></label>
            <div class="form-check form-switch">
                <input class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.for_qr" @change="add_properties" />
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>For Readonly</label>
            <div class="form-check form-switch">
                <input class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.for_readonly" @change="add_properties" />
            </div>
        </div>
        <div class="grid gap-2 p-2 border border-collapse">
            <label class="font-semibold">Min Date Configuration <i class="fa fa-cogs"></i></label>
            <div>
                <ul>
                    <li class="flex flex-row gap-1 mt-2 cursor-pointer" v-for="item in date_conditions" @click="sel_min_date_type(item.value)">
                        <i v-if="item.value==property_form_data.min_date_type" class="far fa-check-circle"></i>
                        <i v-else class="far fa-circle"></i>
                        <span>{{ item.label }}</span>
                    </li>
                </ul>
            </div>
            <div v-if="property_form_data.min_date_type==1" class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                <label>Enter days</label>
                <div><input type="number" class="px-2" v-model="property_form_data.min_days" placeholder="i.e. 15" @keyup="add_properties" @change="add_properties" /></div>
            </div>
            <div v-else-if="property_form_data.min_date_type==2" class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                <label>Choose date</label>
                <div><input type="date" class="px-2" v-model="property_form_data.min_date" @keyup="add_properties" @change="add_properties" /></div>
            </div>
            <div v-else-if="property_form_data.min_date_type==3" class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                <label>Dependency Field Name</label>
                <div><input type="text" class="px-2" v-model="property_form_data.min_dependency_col_name" @keyup="add_properties" @change="add_properties" /></div>
            </div>
        </div>
        <div class="grid gap-2 p-2 border border-collapse">
            <label class="font-semibold">Max Date Configuration <i class="fa fa-cogs"></i></label>
            <div>
                <ul>
                    <li class="flex flex-row gap-1 mt-2 cursor-pointer" v-for="item in date_conditions" @click="sel_max_date_type(item.value)">
                        <i v-if="item.value==property_form_data.max_date_type" class="far fa-check-circle"></i>
                        <i v-else class="far fa-circle"></i>
                        <span>{{ item.label }}</span>
                    </li>
                </ul>
            </div>
            <div v-if="property_form_data.max_date_type==1" class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                <label>Enter days</label>
                <div><input type="number" class="px-2" v-model="property_form_data.max_days" placeholder="i.e. 15" @keyup="add_properties" @change="add_properties" /></div>
            </div>
            <div v-else-if="property_form_data.max_date_type==2" class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                <label>Choose date</label>
                <div><input type="date" class="px-2" v-model="property_form_data.max_date" @keyup="add_properties" @change="add_properties" /></div>
            </div>
            <div v-else-if="property_form_data.max_date_type==3" class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                <label>Dependency Field Name</label>
                <div><input type="text" class="px-2" v-model="property_form_data.max_dependency_col_name" @keyup="add_properties" @change="add_properties" /></div>
            </div>
        </div>
        <!-- <pre>{{ property_form_data }}</pre> -->
    </div>
</template>
<script setup>
    import { ref, watch } from 'vue'
    const props = defineProps({
        data: Object,
        certificate_data: Object,
        qr_data: Object
    })
    const emit = defineEmits(['addProperties','modifyCertificateData','removeCertificateData'])
    const date_conditions = ref([
        {
            label: 'No Restriction',
            value: 0
        },
        {
            label: 'Select day from current date',
            value: 1
        },
        {
            label: 'Particular date',
            value: 2
        },
        {
            label: 'Select Dependency Input Field',
            value: 3
        }
    ])
    const name_key_press = ref(false)
    const property_form_data = ref({
        name: props.data && props.data.name?props.data.name:'',
        type: props.data && props.data.type?props.data.type:'datepicker',
        value: props.data && props.data.value?props.data.value:'',
        label: props.data && props.data.label?props.data.label:'',
        required: props.data && props.data.required?props.data.required:false,
        for_certificate: props.data && props.data.for_certificate?props.data.for_certificate:false,
        for_qr: props.data && props.data.for_qr?props.data.for_qr:false,
        for_readonly: props.data && props.data.for_readonly?props.data.for_readonly:false,
        min_date_type: props.data && props.data.min_date_type?props.data.min_date_type:0,
        min_days: props.data && props.data.min_days?props.data.min_days:0,
        min_date: props.data && props.data.min_date?props.data.min_date:'',
        min_dependency_col_name: props.data && props.data.min_dependency_col_name?props.data.min_dependency_col_name:'',
        max_date_type: props.data && props.data.max_date_type?props.data.max_date_type:0,
        max_days: props.data && props.data.max_days?props.data.max_days:0,
        max_date: props.data && props.data.max_date?props.data.max_date:'',
        max_dependency_col_name: props.data && props.data.max_dependency_col_name?props.data.max_dependency_col_name:''
    })

    watch(() => props.data, (updateData, oldValue) => {
        // //console.log(updateData, oldValue)
        name_key_press.value = false
        property_form_data.value = {
            name: updateData && updateData.name?updateData.name:'',
            type: updateData && updateData.type?updateData.type:'datepicker',
            value: updateData && updateData.value?updateData.value:'',
            label: updateData && updateData.label?updateData.label:'',
            required: updateData && updateData.required?updateData.required:false,
            for_certificate: updateData && updateData.for_certificate?updateData.for_certificate:false,
            for_qr: updateData && updateData.for_qr?updateData.for_qr:false,
            for_readonly: updateData && updateData.for_readonly?updateData.for_readonly:false,
            min_date_type: updateData && updateData.min_date_type?updateData.min_date_type:0,
            min_days: updateData && updateData.min_days?updateData.min_days:0,
            min_date: updateData && updateData.min_date?updateData.min_date:'',
            min_dependency_col_name: updateData && updateData.min_dependency_col_name?updateData.min_dependency_col_name:'',
            max_date_type: updateData && updateData.max_date_type?updateData.max_date_type:0,
            max_days: updateData && updateData.max_days?updateData.max_days:0,
            max_date: updateData && updateData.max_date?updateData.max_date:'',
            max_dependency_col_name: updateData && updateData.max_dependency_col_name?updateData.max_dependency_col_name:''
        }
    },{deep: true});

    watch(() => property_form_data.value.name, (updateName, oldName) => {
        // //console.log(updateName, oldName)
        if(updateName!==oldName && name_key_press.value) {
            let col_name_old = oldName.replace(/\s/g,'_').toLowerCase()
            emit('removeCertificateData', col_name_old)
            
            let col_name_new = updateName.replace(/\s/g,'_').toLowerCase()
            emit('modifyCertificateData', col_name_new)
        }
    })

    watch(() => property_form_data.value.for_certificate, (updateData, oldData) => {
        // //console.log(updateData, oldData)
        /**
         * Certificate data push
         */
        let col_name = property_form_data.value.name.replace(/\s/g,'_').toLowerCase()
        if(updateData) emit('modifyCertificateData', col_name)
        else emit('removeCertificateData', col_name)        
    })

    watch(() => property_form_data.value.for_qr, (updateData, oldData) => {
        // //console.log(updateData, oldData)
        /**
         * QR data push
         */
        let col_name = property_form_data.value.name.replace(/\s/g,'_').toLowerCase()
        if(updateData) emit('modifyQrData', col_name)
        else emit('removeQrData', col_name)        
    })

    const sel_min_date_type = (val) => {
        // //console.log('sel min date type', val)
        property_form_data.value.min_date_type = val
        add_properties()
    }

    const sel_max_date_type = (val) => {
        property_form_data.value.max_date_type = val
        add_properties()
    }

    const add_properties = () => {
        name_key_press.value = true
        emit('addProperties', property_form_data.value)
    }
</script>
<style lang="scss" scoped>
    .properties_block{
        font-size: 12px;    
        input{
            width: 100%;
            height: 26px;
            border: 1px solid #ddd;
            border-radius: 5px;

            &.switch_btn{
                width: 50px;
                height: 26px;
                border-radius: 25px;
                margin-left: -30px;
            }
        }
    }
</style>