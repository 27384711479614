<template>
    <div class="properties_block">
        <!-- {{ data }} -->
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Name</label>
            <div><input class="px-2" type="text" v-model="property_form_data.name" placeholder="Enter Name" @keyup="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Has Dependency</label>
            <div class="form-check form-switch">
                <input class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.dependency" @change="add_properties" />
            </div>
        </div>                
        <div v-if="property_form_data.dependency" class="grid grid-cols-1 items-center border border-collapse p-2">
            <template v-if="property_form_data.dependency_items.length>0">
                <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                    <div class="font-bold">Field Name</div>
                    <div class="font-bold">Value</div>
                </div>
                <div v-for="(item,index) in property_form_data.dependency_items" class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                    <div>
                        <div><input class="px-2" type="text" v-model="property_form_data.dependency_items[index].name" placeholder="Enter Name" @keyup="add_properties" /></div>
                    </div>
                    <div class="flex flex-row items-center gap-2">
                        <div><input class="px-2" type="text" v-model="property_form_data.dependency_items[index].value" placeholder="Enter Value" @keyup="add_properties" /></div>
                        <i v-if="property_form_data.dependency_items.length > 1" class="fa fa-trash-alt" @click="remove_dependency_item(index)"></i>
                    </div>                    
                </div>
            </template>
            <div class="flex flex-row items-center justify-center gap-2 border border-collapse bg-gray-100 cursor-pointer rounded-md h-6 mt-4" @click="add_dependency_item">
                <i class="fa fa-plus"></i>
                <span>Add Dependency Item</span>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { ref, watch } from 'vue'
    const props = defineProps({
        data: Object
    })
    const emit = defineEmits(['addProperties','addDependencyItem'])
    const property_form_data = ref({
        name: props.data && props.data.name?props.data.name:'',
        dependency: props.data && props.data.dependency?props.data.dependency:false,
        dependency_items: props.data && props.data.dependency_items?props.data.dependency_items:[{name:'',value:''}]
    })

    watch(() => props.data, (updateData, oldValue) => {
        // //console.log('Update Data',updateData, oldValue)
        property_form_data.value = {
            name: updateData && updateData.name?updateData.name:'',
            dependency: updateData && updateData.dependency?updateData.dependency:false,
            dependency_items: updateData && updateData.dependency_items?updateData.dependency_items:[{name:'',value:''}]
        }
    });
    
    const remove_dependency_item = (index) => {
        property_form_data.value.dependency_items.splice(index, 1)
    }

    const add_dependency_item = () => {
        let obj = {
            name: '',
            value: ''
        }
        property_form_data.value.dependency_items.push(obj)
    }

    const add_properties = () => {
        emit('addProperties', property_form_data.value)
    }
</script>
<style lang="scss" scoped>
    .properties_block{
        font-size: 12px;
        input{
            width: 100%;
            height: 26px;
            border: 1px solid #ddd;
            border-radius: 5px;

            &.switch_btn{
                width: 50px;
                height: 26px;
                border-radius: 25px;
                margin-left: -30px;
            }
        }
    }
</style>