<template>
    <div :class="['flex flex-row items-center gap-1 p-2 min-h-7',`text-[${data.font_size}${data.font_size_type}]`,`text-${data.align}`,`align-${data.valign}`,`font-${data.font_weight}`,!preview && sel_row_num==(index + 1) && sel_column_num==(col_index + 1)?'border border-[#39bdff] rounded-md':'']">
        <div :class="[`w-full`,`text-${data.align}`,`leading-[${data.line_height}${data.line_height_type}]`,`align-${data.valign}`,`text-${data.align}`,`align-${data.valign}`]" v-if="data.content_type==1" v-text="data.content"></div>
        <div v-else-if="data.content_type==2" v-html="data.content" :class="['w-full whitespace-pre-line',`leading-[${data.line_height}${data.line_height_type}]`,`text-${data.align}`,`align-${data.valign}`]"></div>
        <template v-else-if="data.content_type==3">
            <FormLayoutBlock v-if="data.dynamic_content && data.dynamic_content.length"
                :data="data.dynamic_content"
                :row_index="index"
                :col_index="col_index"
                :sel_row_num="sel_row_num"
                :sel_column_num="sel_column_num"
                :sel_dynamic_content_index="sel_dynamic_content_index"
                :preview="preview"
                @removeFormElement="remove_form_element"
                @selInputFieldProperty="sel_input_field_property"
            />
            <div v-else class="flex flex-col items-center gap-1 w-full h-16 border-dashed bg-gray-100 rounded-md px-4 add_component_btn justify-center cursor-pointer">
                <i class="fa fa-plus-circle"></i>
                <span class="text-[10px] leading-[12px] text-center">Add New Component {{ data.align }}</span>
            </div>
        </template>
        <div class="ml-auto" v-if="index==0 && cols>1 && !preview">
            <i class="fa fa-trash-alt text-[10px] text-red-800 cursor-pointer" @click="remove_column"></i>
        </div>
    </div>
</template>
<script setup>
    import FormLayoutBlock from '../FormLayoutBlock'

    const emit = defineEmits(['selInputFieldProperty','RemoveFormElement','RemoveColumn'])
    const props = defineProps({
        data: Object,
        rows: Number,
        cols: Number,
        index: Number,
        col_index: Number,
        sel_row_num: Number,
        sel_column_num: Number,
        sel_dynamic_content_index: Number,
        preview: Boolean
    })
    const remove_column = () => {
        emit('RemoveColumn', props.col_index)
    }
    const remove_form_element = () => {
        emit('RemoveFormElement')
    }
    const sel_input_field_property = (index) => {
        emit('selInputFieldProperty', index)
    }
</script>