<template>
  <TransitionRoot as="template" :show="accessoriesopen">
    <Dialog as="div" class="relative z-[9999]" @click="update_accessories_popup(false)">
      <div class="fixed inset-0" />
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
              leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                  <div class="h-0 flex-1 overflow-y-auto">
                    <div class="bg-indigo-700 px-4 py-6 sm:px-6">
                      <div class="flex items-center justify-between">
                        <DialogTitle class="text-base font-semibold leading-6 text-white">accessories</DialogTitle>
                        <div class="ml-3 flex h-7 items-center">
                          <button type="button"
                            class="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            @click="update_accessories_popup(false)">
                            <span class="absolute -inset-2.5" />
                            <span class="sr-only">Close panel</span>
                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div class="mt-1">
                        <p class="text-sm text-indigo-300">Add accessories for this post.</p>
                      </div>
                    </div>
                    <div class="flex flex-1 flex-col justify-between">
                      <div class="divide-y divide-gray-200 px-4 sm:px-6">
                        <div class="space-y-6 pb-5 pt-6">
                          <div class="mt-1 rounded-md border border-gray-300 p-3 max-h-[485px] overflow-y-auto">

                            <div v-if="message" class="flex items-center gap-2 message_block error"><i class="fa fa-check"></i> {{ message }}<i class="fa fa-times close_btn" @click="remove_msg"></i></div>
                            <form @submit.prevent="data_submit">
                                                        
                              <select-input v-model="form.bed_id" :error="form.errors.bed_id"
                                class="pb-8 pr-6 w-full " label="Name of Accessories ">
                                <option value=null>Select Accessories </option>
                                <option v-for="accessories in accessories_list" :key="accessories.id"
                                  :value='accessories.id'>{{ accessories.bed_title }}</option>
                              </select-input>   
                              <div class="pb-8 pr-6 w-full  grid grid-cols-12">
                                <text-input v-model="form.icon" :error="form.errors.icon" class="col-span-11" label="Icon" placeholder="i.e 1" disabled/>
                                <vue3-icon-picker v-model="form.icon" class="mt-5"/>
                              </div>                          
                              <div class="sm:col-span-2">                
                                <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Pictute </label>
                                <div class="mt-1 flex flex-wrap justify-center rounded-md border-2 border-dashed border-gray-300 dark:border-black dark:bg-white dark:text-black px-6 pt-5 pb-6">
                                    <div class="space-y-1 text-center">
                                      <PhotoBlock :getPhoto="form.image_path" @SetPhoto="set_photo" />  
                                    </div>
                                </div>
                              </div> 
                                               
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-shrink-0 justify-end px-4 py-4" v-if="edit == false">
                    <button type="button" @click="saveaccessoriesData()"
                      class="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>


import axios from 'axios';
import { ref, toRefs, inject, onMounted, watch } from 'vue'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import FileInput from '@/Shared/FileInput'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import Button from '@/Shared/Button'
import PhotoBlock from './Photo';
import Vue3IconPicker from '@/Shared/components/Vue3IconPicker'
import FloatingActionBtnFix from "@/Shared/Buttons/FloatingActionBtnFix"
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/vue/20/solid'
const props = defineProps(['accessories', 'accessoriesopen','acc_index'])
const accessories_data = ref(props.accessories)
const ropen = ref(props.accessoriesopen)
const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
const edit = ref(false)
const swal = inject('$swal')  
const preloader = ref(false)

const message = ref('');
const form = useForm({ 
    bed_id: null,
    image_path: null,
    icon: 'fas fa-upload',
})
const emit = defineEmits(['UpdateAccessoriesPopup', 'UpdateAccessories'])

const accessories_list = ref([])        
      const load_accessories = async () => {
          await axios.post(`/${backendPrefix.value}/room-accessories/activeaccessories`)
              .then(function (response) {
                  accessories_list.value = response.data
              }.bind(this));
      }


const open_edit = () => {
  edit.value = true
}

const save = () => {
  emit('UpdateAccessoriesPopup', props.accessoriesopen)
  emit('UpdateAccessories', accessories_data)
}

const update_accessories_popup = (status) => {
  emit('UpdateAccessoriesPopup', status)
}

const set_photo = (val) => {
            form.image_path = val
        }
watch(() => props.accessoriesopen, () => {
  emit('UpdateAccessoriesPopup', props.accessoriesopen)
}, { deep: true })
watch(() => accessories_data, () => {
  emit('UpdateAccessories', accessories_data)
}, { deep: true })

const saveaccessoriesData = (val) => {
    if(form.bed_id!=null){
     
      let obj = {
        id: null,
        post_id: null,
        post_accessories_id: null,
        bed_id: form.bed_id,
        image: form.image_path,
        icon: form.icon,
      }
      emit('UpdateAccessories',obj)
      emit('UpdateAccessoriesPopup', false)
      form.bed_id=null
      form.image_path=null
      form.icon='fas fa-upload'
    }else{
      message.value= 'Please fill mendatory filed'
    }
    
}

onMounted(() => {
  load_accessories()
})
const remove_msg = () => {
            message.value = ''
        }

</script>
<style lang="scss" scoped>
    .message_block{
        position: fixed;
        z-index: 9999;
        left: -300px;
        bottom: 25px;        
        color: #fff;
        height: 40px;        
        padding: 0 15px;
        border-radius: 5px;
        transition: all 0.4s;

        & > .close_btn{
            position: absolute;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            background-color: #941212;
            border: 1px solid #fff;
            border-radius: 50%;
            cursor: pointer;
            right: 5px;
            top: -10px;
            font-size: 8px;
            z-index: 5;
        }
        &.error{
            left: auto;
            right: 10%;
            background-color: #fc4949;
        }
    }
</style>