<template>
    <div class="ministry_office_list_map_block">
        <ContentHeaderSection :title="'Ministry Office Mapping'" :icon="'fa fa-cogs'" />
        <div class="p-5 ministry_office_map_block">
            <!-- <pre>{{ MinistryOffices }}</pre> -->
            <!-- {{ sel_ministry_offices }} -->
            <div v-for="office in MinistryOffices" :class="['flex','items-center','gap-2','input_block',{active:sel_ministry_offices[office.id]}]" @click="sel_office(office.id)">
                <span class="square_block">
                    <i class="fa fa-check check"></i>
                    <i class="fa fa-times remove"></i>
                </span>
                <div>{{ office.name_eng_short }} ({{ office.ministry_name }})</div>
            </div>
        </div>
    </div>
</template>

<script>    
    import { ref, toRefs, inject } from 'vue'
    import ContentHeaderSection from "@/Shared/ContentHeaderSection"
    export default {
        name: "MinistryOfficeListMapBlock",
        props: {
            sel_ministry_offices: Object,
            MinistryOffices: Array
        },
        components: {
            ContentHeaderSection
        },
        setup(props, { emit }) {
            const { sel_ministry_offices, MinistryOffices } = toRefs(props)
            const sel_office = (id) => {                
                emit('selMinistryOffice', id)
            }

            return {
                MinistryOffices,
                sel_ministry_offices,
                sel_office
            }
        }
    }
</script>
<style lang="scss" scoped>
    .input_block{
        position: relative;
        height: 32px;
        cursor: pointer;

        & > .square_block{
            display: flex;
            align-items: center;
            justify-content: center;            
            width: 20px; height: 20px;
            border: 1px solid #999;
            border-radius: 5px;

            & > i{
                display: none;
                font-size: 10px;                    
            }
        }

        &:hover{
            font-weight: 600;
            .square_block{
                & > i.check {
                    display: block;
                }
            }
        }

        &.active{
            color: #006699;
            font-weight: 600;
            .square_block{
                border-color: #006699;
                & > i.check{
                    display: block;
                    color: #006699
                }
            }
            &:hover{
                color: #CD0000;                    
                .square_block{
                    border-color: #CD0000;
                    & > i.check{
                        display: none;
                    }
                    & > i.remove{
                        display: block;
                        color: #CD0000
                    }
                }
            }
        }
    }
</style>