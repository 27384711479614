<template>
    <div class="form_components_block">
        <div class="flex flex-col">
            <div class="inputs_block">
                <label class="flex flex-row items-center border gap-2 px-2" @click="block_collapse(1)">
                    <i v-if="blocks_expanded!=1" class="fa fa-chevron-right"></i>
                    <i v-else class="fa fa-chevron-down"></i>
                    <span>Inputs</span>
                </label>
                <div :class="['grid','grid-cols-2','input_items',{hidden:blocks_expanded!=1}]">
                    <div @click="addItem('txt_input')">
                        <i class="fa fa-plus-circle"></i>
                        <TxtInputIcon />
                        <span>Text Input</span>
                    </div>
                    <div @click="addItem('textarea')">
                        <i class="fa fa-plus-circle"></i>
                        <TextareaIcon />
                        <span>Textarea</span>
                    </div>
                    <div @click="addItem('number_input')">
                        <i class="fa fa-plus-circle"></i>
                        <NumberInputIcon />
                        <span>Number Input</span>
                    </div>
                    <div @click="addItem('selectbox')">
                        <i class="fa fa-plus-circle"></i>
                        <SelectboxIcon />
                        <span>Select Box</span>
                    </div>
                    <div @click="addItem('checkbox')">
                        <i class="fa fa-plus-circle"></i>
                        <CheckboxIcon />
                        <span>Checkbox</span>
                    </div>
                    <div @click="addItem('radio')">
                        <i class="fa fa-plus-circle"></i>
                        <RadioIcon />
                        <span>Radio</span>
                    </div>
                    <div @click="addItem('date_picker')">
                        <i class="fa fa-plus-circle"></i>
                        <DatePickerIcon />
                        <span>Datepicker</span>
                    </div>
                    <div @click="addItem('date_range')">
                        <i class="fa fa-plus-circle"></i>
                        <DateRangeIcon />
                        <span>Daterange</span>
                    </div>                    
                    <div @click="addItem('time_picker')">
                        <i class="fa fa-plus-circle"></i>
                        <TimePickerIcon />
                        <span>Timepicker</span>
                    </div>
                    <div @click="addItem('signature')">
                        <i class="fa fa-plus-circle"></i>
                        <!-- <TextIcon /> -->
                        <SignatureIcon />
                        <span>Signature</span>
                    </div>
                </div>                
            </div>
            <!-- <div class="layouts_block">
                <label class="flex flex-row items-center border gap-2 px-2" @click="block_collapse(2)">
                    <i v-if="blocks_expanded!=2" class="fa fa-chevron-right"></i>
                    <i v-else class="fa fa-chevron-down"></i>
                    <span>Layouts</span>
                </label>
                <div :class="['grid','grid-cols-2','layout_items',{hidden:blocks_expanded!=2}]">
                    <div @click="addItem('grid_layout')">
                        <i class="fa fa-plus-circle"></i>
                        <GridIcon />
                        <span>Grid</span>
                    </div>
                </div>                
            </div> -->
            <div class="display_block">
                <label class="flex flex-row items-center border gap-2 px-2" @click="block_collapse(2)">
                    <i v-if="blocks_expanded!=2" class="fa fa-chevron-right"></i>
                    <i v-else class="fa fa-chevron-down"></i>
                    <span>Dynamic Collections</span>
                </label>
                <div :class="['grid','grid-cols-2','display_items',{hidden:blocks_expanded!=2}]">                    
                    <div @click="addItem('gender')">
                        <i class="fa fa-plus-circle"></i>
                        <GenderIcon />
                        <span>Gender</span>
                    </div>
                    <div @click="addItem('religion')">
                        <i class="fa fa-plus-circle"></i>
                        <ReligionIcon />
                        <span>Religion</span>
                    </div>
                    <div @click="addItem('geo')">
                        <i class="fa fa-plus-circle"></i>
                        <GeoIcon />
                        <span>Geo</span>
                    </div>
                    <div @click="addItem('nationality')">
                        <i class="fa fa-plus-circle"></i>
                        <NationalityIcon />
                        <span>Nationality</span>
                    </div>
                    <div @click="addItem('country')">
                        <i class="fa fa-plus-circle"></i>
                        <NationalityIcon />
                        <span>Country</span>
                    </div>
                </div>                
            </div>
            <div class="display_block">
                <label class="flex flex-row items-center border gap-2 px-2" @click="block_collapse(3)">
                    <i v-if="blocks_expanded!=3" class="fa fa-chevron-right"></i>
                    <i v-else class="fa fa-chevron-down"></i>
                    <span>Displays</span>
                </label>
                <div :class="['grid','grid-cols-2','display_items',{hidden:blocks_expanded!=3}]">
                    <div @click="addItem('text')">
                        <i class="fa fa-plus-circle"></i>
                        <TextIcon />
                        <span>Text</span>
                    </div>
                    <div @click="addItem('table')">
                        <i class="fa fa-plus-circle"></i>
                        <TableIcon />
                        <span>Table</span>
                    </div>
                </div>                
            </div>
        </div>
    </div>
</template>
<script setup>
    import TxtInputIcon from './Icons/TxtInput'
    import TextareaIcon from './Icons/Textarea'
    import NumberInputIcon from './Icons/NumberInput'
    import SelectboxIcon from './Icons/Selectbox'
    import CheckboxIcon from './Icons/Checkbox'
    import RadioIcon from './Icons/Radio'
    import DatePickerIcon from './Icons/DatePicker'
    import TimePickerIcon from './Icons/TimePicker'
    import DateRangeIcon from './Icons/DateRange'
    import SignatureIcon from './Icons/Signature'
    import GridIcon from './Icons/Grid'
    import TableIcon from './Icons/Table'
    import GeoIcon from './Icons/Geo'
    import GenderIcon from './Icons/Gender'
    import ReligionIcon from './Icons/Religion'
    import NationalityIcon from './Icons/Nationality'
    import TextIcon from './Icons/Text'
    import { ref } from 'vue'
    const emit = defineEmits(['addFormElement'])    
    const blocks_expanded = ref(1)
    const addItem = (type) => {
        let obj = {}
        if(type=='txt_input') {
            obj = {
                'name': '',
                'type': 'txt_input',
                'label': 'Input Label',
                'placeholder': '',
                'value': ''
            }
        }else if(type=='number_input') {
            obj = {
                'name': '',
                'type': 'number_input',
                'label': 'Input Label',
                'placeholder': '',
                'value': ''
            }
        }else if(type=='textarea') {
            obj = {
                'name': '',
                'type': 'textarea',
                'label': 'Content Label',
                'placeholder': '',
                'value': ''
            }
        }else if(type=='selectbox') {
            obj = {
                'name': '',
                'type': 'selectbox',
                'label': 'Choose Item',
                'options': ''
            }
        }else if(type=='checkbox') {
            obj = {
                'name': '',
                'type': 'checkbox',
                'label': 'Select Items',
                'options': ''
            }
        }else if(type=='radio') {
            obj = {
                'name': '',
                'type': 'radio',
                'label': 'Choose One',
                'options': ''
            }
        }else if(type=='date_picker') {
            obj = {
                'name': '',
                'type': 'datepicker',
                'label': 'Choose date',
                'placeholder': '',
                'value': ''
            }
        }else if(type=='date_range') {
            obj = {
                'name': '',
                'type': 'daterange',
                'label': 'Choose daterange',
                'placeholder': '',
                'value': '',
                'total_days': 0
            }
        }else if(type=='time_picker') {
            obj = {
                'name': '',
                'type': 'timepicker',
                'label': 'Choose time',
                'placeholder': '',
                'value': ''
            }
        }else if(type=='signature') {
            obj = {
                'name': '',
                'type': 'signature',
                'label': 'Upload Signature',
                'placeholder': '',
                'value': ''
            }
        }else if(type=='geo') {
            obj = {
                'name': '',
                'type': 'geo',
                'label': 'Choose Geo Location',                                
                'value': ''
            }
        }else if(type=='gender') {
            obj = {
                'name': '',
                'type': 'gender',
                'label': 'Choose Gender',
                'value': '',
                'title': ''
            }
        }else if(type=='religion') {
            obj = {
                'name': '',
                'type': 'religion',
                'label': 'Choose Religion',                
                'value': '',
                'title': ''
            }
        }else if(type=='nationality') {
            obj = {
                'name': '',
                'type': 'nationality',
                'label': 'Choose Nationality',                
                'value': '',
                'title': ''
            }
        }else if(type=='country') {
            obj = {
                'name': '',
                'type': 'country',
                'label': 'Choose Country',                 
                'value': '',
                'title': ''
            }
        }else if(type=='text') {
            obj = {
                'name': '',
                'type': 'text',
                'content': '',
                'mode': ''
            }
        }else if(type=='table') {
            obj = {
                'name': '',                
                'type': 'table',
                'label': '',
                'rows': 1,
                'cols': 1,
                'html': [{}],
                'allow_add_more_rows': false,
                'add_more_row_number': 2
            }
        }

        emit('addFormElement', obj)
    }
    const block_collapse = (val) => {
        blocks_expanded.value = val
    }
</script>
<style lang="scss" scoped>
    .form_components_block{
        position: sticky;
        top: 0;    
        .inputs_block,
        .layouts_block,
        .display_block{
            label{
                background-color: #eee;
                height: 30px;
                cursor: pointer;
            }
            .input_items,
            .layout_items,
            .display_items{
                max-height: 400px;
                overflow: hidden;
                &:hover{
                    overflow-y: auto;                    
                }
                & > div{
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 15px; font-size: 10px;
                    width: 100%; height: 100px;
                    border: 1px solid #ddd;
                    cursor: pointer;
                    user-select: none;
                    overflow: hidden;

                    & > i{
                        position: absolute;
                        opacity: 0.0;
                        font-size: 20px;
                        color: #fff;
                        z-index: 5;
                        transition: all 0.4s;
                    }

                    &:hover{
                        background-color: #00000080;
                        & > i{
                            opacity: 1.0;
                        }
                    }
                }
            }
        }
    }
</style>