<template>
    <span :class="{active:status}"><i class="fa fa-check-circle"></i></span>    
</template>
<script>
export default {
  name: 'StatusLevel',  
  props:{
    status: [String, Number, Boolean],
  }
}
</script>
<style lang="scss" scoped>
  span{
    display: inline-block;    
    & > i{
      font-size: 20px;
      color: #ccc;
    }
    &.active > i{
      color: #009a66;
    }
  }
</style>