<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
      <div class="container m-auto grid grid-cols-3">
        <div class="col-span-2 max-w-2xl">
          <div class="max-w-2xl bg-white rounded-md shadow overflow-hidden">
            <form @submit.prevent="store">
              <div class="flex flex-wrap -mb-8 -mr-6 p-8">
                <text-input v-model="form.office_name" :error="form.errors.office_name" class="pb-8 pr-6 w-full lg:w-1/1" label="Name of office" placeholder="i.e. LGU Butig" required="true"/>
                <text-input v-model="form.hierarchy" :error="form.errors.hierarchy" class="pb-8 pr-6 w-full lg:w-1/1" type="number" label="Hierarchy" placeholder="i.e. 1" oninput="this.value = Math.abs(this.value)"/>
                <file-input v-model="form.logo" :error="form.errors.logo" class="pb-8 pr-6 w-full lg:w-1/1" type="file" accept="image/*" label="Logo" />
                <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Office" class="pb-8 pr-6 w-full lg:w-1/1" />
              </div>
              <!-- {{ layerLevels }} -->
              <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>
            </form>
          </div>
        </div>
        <div class="max-w-2xl bg-white rounded-md shadow ml-4">
          <OfficeLayerMap :officelayers="officelayers" :layermap="layermap" :office_id="office_id" @getLayers="get_layers" />
          <MinistryOffice :officeMinistries="officeMinistries" :ministrymap="ministrymap" :office_id="office_id" @getMinistry="get_ministry" />
        </div>
        <!-- {{ form }} -->
      </div>
    </div>
  </template>

  <script>
  import axios from 'axios';
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import { ref, toRefs, inject } from 'vue'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import SelectInputFunction from '@/Shared/SelectInputFunction'
  import OrganogramMap from './components/v3/OrganogramMap'
  import OfficeLayerMap from './components/v3/OfficeLayerMap'
  import MinistryOffice from './components/v3/MinistryOffice'
  import Button from '@/Shared/Button'
  import FileInput from '@/Shared/FileInput'
  export default {
    components: {
      Head,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      StatusToogleButton,
      Breadcrumbs,
      SelectInputFunction,
      OrganogramMap,
      OfficeLayerMap,
      MinistryOffice,
      Button,
      FileInput
    },
    layout: Layout,
    remember: 'form',
    props: {
      regions: Array,
      ministries: Array,
      officelayers: Array,
      officeMinistries: Array,
      offices: Array,
      layerLevels: Array,
      layerMap: Array
    },
    setup(props){
      const { regions,layerLevels,layerMap,offices,ministries,officelayers,officeMinistries } = toRefs(props)
      const form = useForm({
          office_name: null,
          superior_office: null,
          hierarchy: null,
          status: true,
          office_layers: {},
          office_ministries:{},
          logo:null
      })
      const type = ref('create')
      const title = ref('Office')
      const url = ref('/backend/offices')
      const createUrl = ref('')

      const store = () => {
       // form.post(`${url.value}`)
        form.post(`${url.value}`, {forceFormData: true,})
      }

      const get_layers = (obj) => {
        //console.log(obj)
        form.office_layers = obj
      }

      const get_ministry = (obj) => {
        form.office_ministries = obj
      }

      return {
        form,
        layerLevels,
        regions,
        offices,
        ministries,
        officelayers,
        officeMinistries,
        layerMap,
        type,
        title,
        url,
        createUrl,
        get_layers,
        store,
        get_ministry
      }
    }

  }
  </script>
