<template>
    <div class="properties_block">
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Name</label>
            <div><input type="text" v-model="property_form_data.name" placeholder="Enter Name" @keyup="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Label</label>
            <div><input type="text" v-model="property_form_data.label" placeholder="Enter Label" @keyup="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Placeholder</label>
            <div><input type="text" v-model="property_form_data.placeholder" placeholder="Enter Placeholder" @keyup="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Maxlength</label>
            <div><input type="number" v-model="property_form_data.maxlength" placeholder="e.g. 30" min="1" @keyup="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Required</label>
            <div class="form-check form-switch">
                <input class="form-check-input appearance-none w-9 -ml-8 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.required" @change="add_properties" />
            </div>
        </div>
    </div>
</template>
<script setup>
    import { ref } from 'vue'
    const emit = defineEmits(['addProperties'])
    const property_form_data = ref({
        name: '',
        label: '',
        placeholder: '',
        maxlength: '',
        required: false
    })

    const add_properties = () => {
        emit('addProperties', property_form_data.value)
    }
</script>
<style lang="scss">
    .properties_block{
        font-size: 12px;    
        input{
            max-width: 100%;
        }
    }
</style>