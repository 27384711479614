<template>
    <div class="form_components_block">
        <div class="flex flex-col">
            <div class="inputs_block">
                <label class="flex flex-row items-center border gap-2 px-2" @click="block_collapse('inputs')">
                    <i v-if="input_blocks_collapsed" class="fa fa-chevron-right"></i>
                    <i v-else class="fa fa-chevron-down"></i>
                    <span>Inputs</span>
                </label>
                <div :class="['grid','grid-cols-2','input_items',{hidden:input_blocks_collapsed}]">
                    <div @click="addItem('txt_input')">
                        <i class="fa fa-plus-circle"></i>
                        <TxtInputIcon />
                        <span>Text Input</span>
                    </div>
                    <div @click="addItem('textarea')">
                        <i class="fa fa-plus-circle"></i>
                        <TextareaIcon />
                        <span>Textarea</span>
                    </div>
                    <div @click="addItem('number_input')">
                        <i class="fa fa-plus-circle"></i>
                        <NumberInputIcon />
                        <span>Number Input</span>
                    </div>
                    <div @click="addItem('selectbox')">
                        <i class="fa fa-plus-circle"></i>
                        <SelectboxIcon />
                        <span>Select Box</span>
                    </div>
                    <div @click="addItem('checkbox')">
                        <i class="fa fa-plus-circle"></i>
                        <CheckboxIcon />
                        <span>Checkbox</span>
                    </div>
                    <div @click="addItem('radio')">
                        <i class="fa fa-plus-circle"></i>
                        <RadioIcon />
                        <span>Radio</span>
                    </div>
                    <div @click="addItem('date_picker')">
                        <i class="fa fa-plus-circle"></i>
                        <DatePickerIcon />
                        <span>Datepicker</span>
                    </div>
                    <div @click="addItem('date_range')">
                        <i class="fa fa-plus-circle"></i>
                        <DateRangeIcon />
                        <span>Daterange</span>
                    </div>
                </div>                
            </div>
            <div class="layouts_block">
                <label class="flex flex-row items-center border gap-2 px-2" @click="block_collapse('layouts')">
                    <i v-if="layout_blocks_collapsed" class="fa fa-chevron-right"></i>
                    <i v-else class="fa fa-chevron-down"></i>
                    <span>Layouts</span>
                </label>
                <div :class="['grid','grid-cols-2','layout_items',{hidden:layout_blocks_collapsed}]">
                    <div @click="addItem('grid_layout')">
                        <i class="fa fa-plus-circle"></i>
                        <GridIcon />
                        <span>Grid</span>
                    </div>
                </div>                
            </div>
        </div>
    </div>
</template>
<script setup>
    import TxtInputIcon from './Icons/TxtInput'
    import TextareaIcon from './Icons/Textarea'
    import NumberInputIcon from './Icons/NumberInput'
    import SelectboxIcon from './Icons/Selectbox'
    import CheckboxIcon from './Icons/Checkbox'
    import RadioIcon from './Icons/Radio'
    import DatePickerIcon from './Icons/DatePicker'
    import DateRangeIcon from './Icons/DateRange'
    import GridIcon from './Icons/Grid'
    import { ref } from 'vue'
    const emit = defineEmits(['addFormElement'])
    const inputTypeData = ref({
        'txt_input': {
            'name': '',
            'type': 'txt_input',
            'label': 'Input Label',
            'placeholder': ''
        },
        'number_input': {
            'name': '',
            'type': 'number_input',
            'label': 'Input Label',
            'placeholder': ''
        },
        'textarea': {
            'name': '',
            'type': 'textarea',
            'label': 'Content Label',
            'placeholder': ''
        },
        'selectbox': {
            'name': '',
            'type': 'selectbox',
            'label': 'Choose Item',
            'options': ''
        },
        'checkbox': {
            'name': '',
            'type': 'checkbox',
            'label': 'Select Items',
            'options': ''
        },
        'radio': {
            'name': '',
            'type': 'radio',
            'label': 'Choose One',
            'options': ''
        },
        'date_picker': {
            'name': '',
            'type': 'datepicker',
            'label': 'Choose date',
            'placeholder': ''
        },
        'date_range': {
            'name': '',
            'type': 'daterange',
            'label': 'Choose Item',
            'placeholder': ''
        },
        'grid_layout': {
            'name': '',
            'type': 'gridlayout',
            'column': [
                {
                    'name': 'Grid Column',
                    'data': []
                },
                {
                    'name': 'Grid Column',
                    'data': []
                },
                {
                    'name': 'Grid Column',
                    'data': []
                }
            ]
        }
    })
    const input_blocks_collapsed = ref(false)
    const layout_blocks_collapsed = ref(false)
    const addItem = (type) => {
        emit('addFormElement', inputTypeData.value[type])
    }
    const block_collapse = (block) => {
        if(block=='inputs') input_blocks_collapsed.value = input_blocks_collapsed.value?false:true
        else if(block=='layouts') layout_blocks_collapsed.value = layout_blocks_collapsed.value?false:true
    }
</script>
<style lang="scss" scoped>
    .form_components_block{
        position: sticky;
        top: 0;    
        .inputs_block,.layouts_block{
            label{
                background-color: #eee;
                height: 30px;
                cursor: pointer;
            }
            .input_items,.layout_items{
                & > div{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 15px; font-size: 10px;
                    width: 100%; height: 100px;
                    border: 1px solid #ddd;
                    cursor: pointer;
                    user-select: none;
                    overflow: hidden;

                    & > i{
                        position: absolute;
                        opacity: 0.0;
                        font-size: 20px;
                        color: #fff;
                        z-index: 5;
                        transition: all 0.4s;
                    }

                    &:hover{
                        background-color: #00000080;
                        & > i{
                            opacity: 1.0;
                        }
                    }
                }
            }
        }
    }
</style>