<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs> 
      <trashed-message v-if="tag.deleted_at" class="mb-6" @restore="restore"> This tag has been deleted. </trashed-message>
      <div class="max-w-lg bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="update">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.tag_title" :error="form.errors.tag_title" class="pb-8 pr-6 w-full lg:w" label="Tag Title"  required="true" placeholder="i.e License" />           
           
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Tag Status" class="pb-8 pr-6 w-full lg:w" :checked="statusCheck"></status-toogle-button>             
          </div>
          <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
            <!-- <button v-if="!tag.deleted_at" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button> -->
            <Button v-if="permission.edit>0" :type="type" :name="head" :url="createUrl" :loading="form.processing"></Button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, toRefs, inject } from 'vue'
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import Icon from '@/Shared/Icon'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import TrashedMessage from '@/Shared/TrashedMessage'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import DeleteIcon from '@/Shared/DeleteIcon'
  import Button from '@/Shared/Button'
  export default {
    components: {
      Head,
      Icon,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      TrashedMessage,
      StatusToogleButton,
      Breadcrumbs,
      DeleteIcon,
      Button
    },
    layout: Layout,
    props: {
      tag: Object,
      permission: Object,
    },
    remember: 'form',
    setup(props){
      const { tag, permission} = toRefs(props)
      const type = ref('edit')
      const title = ref(tag.value.tag_title)
      const head = ref('Tag')
      const url = ref('/backend/tags')
      const createUrl = ref('')
      const elm = ref(this)
      const swal = inject('$swal')
      const form = useForm({
        tag_title: tag.value.tag_title,
        status: tag.value.status
      })      
      const update = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.put(`${url.value}/${tag.value.id}`)
              title.value = form.tag_title
              Swal.fire(
                'Updated!',
                'Your infomation has been Updated.',
                'success'
              )
            }
          });
      }
      const destroy = () => {
        swal({
            title: 'Are you sure?',
            text: "Do you really want to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.delete(`${url.value}/${tag.value.id}`)
              Swal.fire(
                'Deleted!',
                'Your infomation has been deleted.',
                'success'
              )
            }
          });
      }
      const restore = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to restore this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Resore it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.put(`${url.value}/${tag.value.id}/restore`)
              Swal.fire(
                'Restored!',
                'Your infomation has been Restored.',
                'success'
              )
            }
          });
      }
      const statusCheck = () => {
        if(tag.value.status==1){
          form.status=true;
          return true;
        }else{
          form.status=false;
          return false;
        }        
      }
      return {
        form,
        tag,
        type,
        title,
        head,
        url,
        createUrl,
        update,
        destroy,
        restore,
        statusCheck,
        swal,
        permission
      }
    },    
    created: function(){
      this.statusCheck();
    }
  }
  </script>
  