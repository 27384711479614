<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
        <div class="flex items-center justify-between mb-6">
            <search-filter v-model="form.search" class="mr-4 w-full max-w-xl" @reset="reset">
                <template v-slot:filter>
                    <select v-model="form.trashed" class="form-select  w-28">
                        <option :value="null">Filter</option>
                        <option value="with">With Trashed</option>
                        <option value="only">Only Trashed</option>
                    </select>
                </template>
                <template v-slot:page>
                    <select v-model="form.length" class="form-select  w-20">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                    </select>
                </template>
                <template v-slot:status>
                    <!-- <select v-model="form.status" class="form-select w-32">
                        <option :value="null">Status</option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                    </select> -->
                </template>
            </search-filter>
            <Button :type="type" :name="title" :url="createUrl" v-if="permission.add > 0"></Button>
        </div>
        <div class="bg-white rounded-md shadow overflow-x-auto">
            <table class="w-full table-auto">
                <thead>
                    <tr class="text-left font-bold">
                        <th class="pb-2 pt-3 px-3 font-bold">Slug</th>
                        <th class="pb-2 pt-3 px-3 font-bold" v-for="column in col">
                            {{ column }}
                        </th>
                        <th class="pb-2 pt-3 px-3 text-center font-bold">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="language in languages.data" :key="language.id"
                        class="hover:bg-gray-100 focus-within:bg-gray-100">
                        <td class="border-t pb-1 pt-1 px-3 font-bold">
                            {{ language.language_short_code }}
                            <input type="hidden" class="form-input" v-model="language.language_short_code"
                                v-show="language.edit">
                        </td>
                        <td class="border-t pb-1 pt-1 px-3" v-for="column in col">
                            <span v-show="!language.edit">{{ language[column] }}</span>
                            <textarea-input type="text" class="form-input" v-model="language[column]"
                                v-show="language.edit" />

                        </td>
                        <td class="w-px border-t pb-1 pt-1 px-3 text-center">
                            <div class="flex items-center px-2" v-if="permission.edit > 0">
                                <button class="px-4 py-2" v-show="!language.edit" v-on:click="editLanguage(language)"
                                    tabindex="-1" title="Edit">
                                    <i class="fa fa-edit" style="color: #0381e2;"></i>
                                </button>
                                <button class="px-4 py-2" v-show="language.edit" v-on:click="saveLanguage(language)"
                                    title="Save"><i class="fa-solid fa-floppy-disk" style="color: #029267;"></i></button>
                                <button class="px-4 py-2" v-show="language.edit" v-on:click="cancelLanguage(language)"
                                    title="Cancel"><i class="fa-solid fa-ban" style="color: #9e0008;"></i></button>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="languages.data.length === 0">
                        <td class="px-3 py-1 border-t" colspan="4">No Language Translation found.</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <pagination class="mt-6" :links="languages.links" />
    </div>
</template>

<script>
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject } from 'vue'
import Icon from '@/Shared/Icon'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import SearchFilter from '@/Shared/SearchFilter'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import StatusLevel from '@/Shared/StatusLevel'
import Button from '@/Shared/Button'
import axios from "axios"
import TextareaInput from '@/Shared/TextareaInput'
import TextInput from '@/Shared/TextInput'
export default {
    components: {
        Head,
        Icon,
        Link,
        Pagination,
        SearchFilter,
        StatusLevel,
        Breadcrumbs,
        Button,
        TextareaInput,
        TextInput
    },
    layout: Layout,
    props: {
        filters: Object,
        languages: Object,
        permission: Object,
        col: Object,
        lan: Object
    },

    setup(props) {
        const { filters, languages, permission, col, lan } = toRefs(props)
        const form = ref({
            search: filters.value.search,
            trashed: filters.value.trashed,
            length: filters.value.length ? filters.value.length : 15,
            status: filters.value.status,
        })
        const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
        const _originalLanguage = ref(null);
        const type = ref('index')
        const title = ref('Language Translation')
        const url = ref('')
        const swal = inject('$swal')
        const createUrl = ref('/backend/language-translations/create')
        const reset = () => {
            form.value = mapValues(form, () => null)
        }

        const editLanguage = (language) => {
            _originalLanguage.value = { ...language }; // Using the spread operator for a shallow copy
            language.edit = true;
        }
        const cancelLanguage = (language) => {
            Object.assign(language, _originalLanguage.value);
            language.edit = false;
        }
        const saveLanguage = (language) => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    language.edit = false;
                    axios.post(`/${backendPrefix.value}/language-translations/land-update`, language).then(function (response) {
                        language.edit = false;
                    });
                    swal.fire(
                        'Updated !',
                        'Your infomation has been Updated.',
                        'success'
                    )

                }
            });
        }
        return {
            filters,
            languages,
            form,
            type,
            title,
            url,
            createUrl,
            reset,
            permission,
            lan,
            col,
            editLanguage,
            cancelLanguage,
            _originalLanguage,
            saveLanguage,
            backendPrefix
        }
    },
    watch: {
        form: {
            deep: true,
            handler: throttle(function () {
                this.$inertia.get('/backend/language-translations', pickBy(this.form), { preserveState: true })
            }, 150),
        },
    }
}
</script>
