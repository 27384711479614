import { createApp, h } from 'vue'
import { InertiaProgress } from '@inertiajs/progress'
import { createInertiaApp } from '@inertiajs/inertia-vue3'
import VueSweetalert2 from 'vue-sweetalert2';
import 'tw-elements';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import '@vueform/multiselect/themes/default.css'

InertiaProgress.init()

createInertiaApp({
  resolve: name => require(`./Pages/${name}`),
  title: title => title ? `${title}` : '',
  setup({ el, App, props, plugin }) {
    createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(VueSweetalert2)
      .mount(el)
  },
})
