<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
      <trashed-message v-if="tutorialCategory.deleted_at" class="mb-6" @restore="restore"> This Category has been deleted. </trashed-message>
      <div class="max-w-lg bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="update">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.title" :error="form.errors.title" class="pb-8 pr-6 w-full lg:w-1/1" @keyup="slugBuild" label="Category Title" required="true" placeholder="i.e PHP Course"/>
            <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full lg:w-1/1" label="Slug" required="true" placeholder="i.e php-course"/>
            <select-input v-model="form.parent_id" :error="form.errors.parent_id" class="pb-8 pr-6 w-full lg:w-1/1" label="Parent Category">
              <option :value=0>Select Parent</option>
              <option v-for="parentC in parentCat" :key="parentC.id" :value='parentC.id'>{{ parentC.title }}</option>
            </select-input>

            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status" class="pb-8 pr-6 w-full lg:w-1/1"></status-toogle-button>
          </div>
          <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
            <button v-if="!tutorialCategory.deleted_at && permission.edit>0" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
            <Button v-if="permission.edit>0" :type="type" :name="head" :url="createUrl" :loading="form.processing"></Button>
          </div>
        </form>
      </div>
    </div>
  </template>

  <script>
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import Button from '@/Shared/Button'
  import DeleteIcon from '@/Shared/DeleteIcon'
  import Icon from '@/Shared/Icon'
  import Layout from '@/Shared/Layout'
  import LoadingButton from '@/Shared/LoadingButton'
  import SelectInput from '@/Shared/SelectInput'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import TextareaInput from '@/Shared/TextareaInput'
  import TextInput from '@/Shared/TextInput'
  import TrashedMessage from '@/Shared/TrashedMessage'
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import { ref, toRefs,inject } from 'vue'
    export default {
      components: {
        Head,
        Icon,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        TrashedMessage,
        StatusToogleButton,
        Breadcrumbs,
        DeleteIcon,
        Button,
        TextareaInput
      },
      layout: Layout,
      props: {
          tutorialCategory: Object,
          permission: Object,
          parentCat: Array
      },
      remember: 'form',
      setup(props){
        const { tutorialCategory,parentCat,permission} = toRefs(props)
        const type = ref('edit')
        const title = ref(tutorialCategory.value.title)
        const head = ref('Tutorial Category')
        const url = ref('/backend/tutorial-categories')
        const createUrl = ref('')
        const swal = inject('$swal')
        const elm = ref(this)
        const form = useForm({
          title:tutorialCategory.value.title,
          slug: tutorialCategory.value.slug,
          parent_id: tutorialCategory.value.parent_id,
          status: tutorialCategory.value.status
        })
        const slugBuild = () => {
          form.slug = form.title.replace(/\s/g,'-').toLowerCase()
        }
        const update = () => {
          swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.put(`${url.value}/${tutorialCategory.value.id}`)
              title.value = form.title
              Swal.fire(
                'Updated!',
                'Your infomation has been Updated.',
                'success'
              )
            }
          });
        }
        
        const destroy = () => {
          swal({
              title: 'Are you sure?',
              text: "Do you really want to delete this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
              if (result.isConfirmed) {
                form.delete(`${url.value}/${tutorialCategory.value.id}`)
                Swal.fire(
                  'Deleted!',
                  'Your infomation has been deleted.',
                  'success'
                )
              }
            });
        }
        const restore = () => {
          swal({
              title: 'Are you sure?',
              text: "You want to restore this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, Resore it!'
            }).then((result) => {
              if (result.isConfirmed) {
                form.put(`${url.value}/${tutorialCategory.value.id}/restore`)
                Swal.fire(
                  'Restored!',
                  'Your infomation has been Restored.',
                  'success'
                )
              }
            });
        }
        const statusCheck= () => {
          if(tutorialCategory.value.status==1){
            form.status=true;
            return true;
          }else{
            form.status=false;
            return false;
          }
        }
        return {
          form,
          tutorialCategory,
          type,
          title,
          head,
          url,
          createUrl,
          parentCat,
          update,
          destroy,
          restore,
          statusCheck,
          slugBuild,
          swal,
          permission
        }
      },
      created: function(){
        this.statusCheck();
      }

    }
  </script>
