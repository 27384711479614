<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
      <div class="max-w-lg bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="store">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.title" :error="form.errors.title" class="pb-8 pr-6 w-full lg:w-1/1" @keyup="slugBuild" label="Category Title" required="true" placeholder="i.e PHP Course"/>
            <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full lg:w-1/1" label="Slug" required="true" placeholder="i.e php-course"/>
            <select-input v-model="form.parent_id" :error="form.errors.parent_id" class="pb-8 pr-6 w-full lg:w-1/1" label="Parent Category">
              <option :value=0 >Select Parent</option>
              <option v-for="parentC in parentCat" :key="parentC.id" :value='parentC.id'>{{ parentC.title }}</option>
            </select-input>
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status" class="pb-8 pr-6 w-full lg:w-1/1"></status-toogle-button>
          </div>
          <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>
        </form>
      </div>
    </div>
  </template>

  <script>
  import Breadcrumbs from '@/Shared/Breadcrumbs'
import Button from '@/Shared/Button'
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import TextInput from '@/Shared/TextInput'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject } from 'vue'
  export default {
    components: {
      Head,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      Breadcrumbs,
      StatusToogleButton,
      Button
    },
    layout: Layout,
    remember: 'form',
    props:{
        parentCat: Array
    },
    setup(props){
        const { parentCat } = toRefs(props)
      const form = useForm({
        title:null,
        parent_id: 0,
        slug: null,
        status: true
      })
      const type = ref('create')
      const title = ref('Tutorial Category')
      const url = ref('/backend/tutorial-categories')
      const createUrl = ref('')
      const swal = inject('$swal')
      const store = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}`, {forceFormData: true,})
              Swal.fire(
                'Added!',
                'Your infomation has been Added.',
                'success'
              )
            }
          });
        
      }
      const slugBuild = () => {
        form.slug = form.title.replace(/\s/g,'-').toLowerCase()
      }
      return {
        form,
        type,
        title,
        url,
        createUrl,
        store,
        slugBuild,
        parentCat
      }
    }
  }
  </script>
