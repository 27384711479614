<template>
    <div>
      <!-- <Head :title="form.provience_name" />
      <h1 class="mb-8 text-3xl font-bold">
        <Link class="text-indigo-400 hover:text-indigo-600" href="/backend/provinces">Province</Link>
        <span class="text-indigo-400 font-medium">/</span>
        {{ form.provience_name }}
      </h1> -->
      <Breadcrumbs :type="type" :header="title" :url="url" :head="head" />
      <div class="flex justify-start mb-8 max-w-3xl">
        <img v-if="province.logo" class="block ml-4 w-8 h-8 rounded-full" :src="province.logo" />
      </div> 
      <trashed-message v-if="province.deleted_at" class="mb-6" @restore="restore"> This Province has been deleted. </trashed-message>
      <div class="max-w-2xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="update">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.provience_name" :error="form.errors.provience_name" class="pb-8 pr-6 w-full lg:w-1/1" label="Name of Province" required="true" placeholder="i.e Lanao Del Sur"/>
            <select-input v-model="form.geo_region_id" :error="form.errors.geo_region_id" class="pb-8 pr-6 w-full lg:w-1/1" label="Name of Region" >
              <option value=null >Select Region</option>
              <option v-for="region in regions" :key="region.id" :value='region.id'>{{ region.region_name }}</option>
            </select-input>
            <text-input v-model="form.code" :error="form.errors.code" class="pb-8 pr-6 w-full lg:w-1/1" label="Code of Province" placeholder="i.e 1001"/>
            <file-input v-model="form.logo" :error="form.errors.logo" class="pb-8 pr-6 w-full lg:w-1/1" type="file" accept="image/*" label="Logo" />  
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Province" class="mt-8 pb-8 pr-6 w-full lg:w-1/1" :checked="statusCheck"></status-toogle-button>             
          </div>
          
          <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
            <button v-if="!province.deleted_at && permission.delete>0" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
            <Button v-if="permission.edit>0" :type="type" :name="head" :url="createUrl" :loading="form.processing"></Button>
            <!-- <loading-button :loading="form.processing" class="btn-indigo ml-auto" type="submit"><i class="fa fa-check-circle"></i> Update Province</loading-button> -->
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { ref, toRefs, inject  } from 'vue'
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import Icon from '@/Shared/Icon'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import TrashedMessage from '@/Shared/TrashedMessage'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import DeleteIcon from '@/Shared/DeleteIcon'
  import Button from '@/Shared/Button'
  import FileInput from '@/Shared/FileInput'
  export default {
    components: {
      Head,
      Icon,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      TrashedMessage,
      Breadcrumbs,
      DeleteIcon,
      StatusToogleButton,
      Button,
      FileInput
    },
    layout: Layout,
    props: {
      province: Object,
      permission: Object,
      regions: Array,
    },
    remember: 'form',
    setup(props){
      const { province, regions, permission } = toRefs(props)
      const type = ref('edit')
      const title = ref(province.value.provience_name)
      const head = ref('Province')
      const url = ref('/backend/provinces')
      const createUrl = ref('')
      const elm = ref(this)
      const swal = inject('$swal')
      const form = useForm({
        provience_name: province.value.provience_name,
        geo_region_id: province.value.geo_region_id,
        code: province.value.code,
        status: province.value.status,
        logo:null,
      })     
      const update = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}/${province.value.id}`, {forceFormData: true,})
              title.value = form.provience_name
              Swal.fire(
                'Updated!',
                'Your infomation has been Updated.',
                'success'
              )
            }
          });
      }
      const destroy = () => {
        swal({
            title: 'Are you sure?',
            text: "Do you really want to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.delete(`${url.value}/${province.value.id}`)
              Swal.fire(
                'Deleted!',
                'Your infomation has been deleted.',
                'success'
              )
            }
          });
      }
      const restore = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to restore this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Resore it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.put(`${url.value}/${province.value.id}/restore`)
              Swal.fire(
                'Restored!',
                'Your infomation has been Restored.',
                'success'
              )
            }
          });
      }
      return {
        form,
        regions,
        type,
        title,
        head,
        url,
        createUrl,
        update,
        destroy,
        restore,
        swal,
        permission
      }
    }
  }
  </script>
  