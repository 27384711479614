<template>
    <div>
        <pre>
        {{ designation }}
        </pre>
        
    </div>
    
</template>
<script>
import axios from 'axios'
import { ref, toRefs,watchEffect,onMounted } from 'vue'
export default {
name: 'DecisionDetails',
    props: {
        designation: Object,
        decison: Array,
        service_id: Number,
        designation_data:Array,
        dec_data:Array
    },    
    setup(props){
            const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)            
            const { service_id, designation,decison,designation_data } = toRefs(props)
            const approvedatas = ref([])
            const dec_data = ref({});
            const preloader = ref(false)

            const tabsDataSetup = () => {
                props.dec_data.forEach((v,i) => {
                    let obj = {
                        index: i,
                        name: v.service_name,
                        current: props.sel_tab_index==i?true:false
                    }
                    tabs.value.push(obj)
                })
            }

            
            onMounted(() => {
                
            })
            return {
                preloader,
                service_id,
                backendPrefix,
                approvedata,
                approvedatas,
                dec_data,
                designation,
                decison,
                designation_data
            }
    }
    
}
</script>