<template>  
<div class="block">
  <form  @submit.prevent="login">   
    <div class="mb-4">
        <label for="login_id" class="block text-sm font-medium text-gray-700">Email</label>
        <div class="relative mt-1 rounded-md shadow-sm">
            <input v-model="form.username" placeholder="i.e. 9560091781 / yousuf@gmail.com" type="text" :class="['block w-full rounded-md focus:border-green-500 focus:ring-green-500 sm:text-sm form-input',form.errors.username?'border-red-300':'border-gray-300']"  />
            <div v-if="form.errors.username" class="form-error">{{ form.errors.username }}</div>
        </div>
    </div>
    <div class="mb-4">
        <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
        <div class="relative mt-1 rounded-md shadow-sm">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <i class="fa-solid fa-lock h-5 w-5 text-gray-400"></i>
            </div>
            <input v-if="password_open" type="text" v-model="form.password" :class="['block w-full rounded-md pl-10 focus:border-green-500 focus:ring-green-500 sm:text-sm ',form.errors.password?'border-red-300':'border-gray-300']" placeholder="i.e. password#123"/>
            <input v-else type="password" v-model="form.password" :class="['block w-full rounded-md pl-10 focus:border-green-500 focus:ring-green-500 sm:text-sm ',form.errors.password?'border-red-300':'border-gray-300']" placeholder="i.e. password#123" />
            <i :class="['absolute top-[13px] right-3 text-gray-600 fa fa-eye',!password_open?'hidden':'']" @click="password_view_status(false)"></i>
            <i :class="['absolute top-[13px] right-3 fa fa-eye-slash text-gray-400',password_open?'hidden':'']" @click="password_view_status(true)"></i>
            <div v-if="form.errors.password" class="form-error">{{ form.errors.password }}</div>
        </div>
    </div>
    <div class="pt-4">
        <div v-if="preloader" class="flex flex-row items-center justify-center gap-1">
            <i class="fa fa-cog fa-spin"></i>
            <span>Loading...wait</span>
        </div>
        <button v-else type="submit" class="flex w-full justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2" role="button" tabindex="0">Sign in</button>
    </div>
  </form>     
</div>
        
</template>
<script>
import { ref, toRefs } from 'vue'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import Logo from '@/Shared/Logo'
import ErrorMsg from '@/Shared/ErrorMsg'
import TextInput from '@/Shared/TextInput'
import LoadingButton from '@/Shared/LoadingButton'
import UserIcon from '@/svg/UserIcon'
import LockIcon from '@/svg/LockIcon'

export default {
  components: {
    Head,
    LoadingButton,
    Logo,
    TextInput,
    UserIcon,
    LockIcon,
    ErrorMsg,
  },
  data() {
    return {
      form: this.$inertia.form({
        username: '',
        password: '',
        remember: false,
      }),
      window: {
        width: 0,
        height: 0
      },
      title :'SignIn',
      total_slides: 2,
      showPassword:false,
      sel_slide: 1,
      slider_interval: '',
      mobilestat:false,
      password_open:false,
      preloader : false
    }
  },
  props:{
    error: Object
  },
  created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
  },
  destroyed() {
      window.removeEventListener('resize', this.handleResize);
  },
  mounted(){
    this.bgSliderOn()
  },
  computed: {
    buttonLabel() {
      return (this.showPassword) ? "Hide" : "Show";
    }
  },
  methods: {
    bgSliderOn(){
      this.slider_interval = setInterval(() => {
        if(this.sel_slide==this.total_slides) this.sel_slide = 1
        else this.sel_slide++
      }, 1000 * 10)
    }, 
    password_view_status(status){
        this.password_open = status
    },
    password_view_status() {
        this.password_open = !this.password_open;
    },
    async login() {
      this.preloader = true
      await this.form.post('/backend/login')
      this.preloader = false
    },
    handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
            if(this.window.width<750){
              this.mobilestat= false;
            }else{
              this.mobilestat= true;
            }
            
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    }
  },
  beforeDestory(){
    clearInterval(this.slider_interval)
  }
}
</script>
<style>
  .hidden{
    display: none;
  }
</style>