<template>
    <div class="emp_organogram_block">
        <ContentHeaderSection :title="'Organogram List'" :icon="'fa fa-bar'" />
        <div class="list_block m-5">
            <!-- {{ organogram }} -->
            <ul>
                <li v-for="item in organogram">
                    <div v-if="item.section != null" class="flex items-center gap-2 px-3 section_block">
                        <i class="fa fa-info-circle"></i>
                        <span>{{ item.section.name }}</span>
                    </div>
                    <ul v-if="(item.designations.length > 0) && item.section != null" class="px-4 py-3">
                        <li v-for="designation in item.designations">
                            <div :class="['flex', 'items-center', 'gap-2', 'px-4', 'designation_block', { active: sel_designations[designation.id] }]"
                                @click="add_designations(designation.id)">
                                <div class="input_block">
                                    <i class="fa fa-check check"></i>
                                    <i class="fa fa-times remove"></i>
                                </div>
                                <div class="designation">{{ designation.name }}</div>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <FloatingActionBtn v-if="permission.edit > 0" :loader="preloader" :icon="'fa fa-check'" :message="message"
            @dataSubmit="data_submit" @removeMessage="remove_msg" />
    </div>
</template>
<script>
import axios from 'axios'
import { ref, toRefs, onMounted, inject } from 'vue'
import ContentHeaderSection from "@/Shared/ContentHeaderSection"
import FloatingActionBtn from "@/Shared/Buttons/FloatingActionBtn"
export default {
    name: 'EmpOrganogramBlock',
    props: {
        emp_id: Number,
        geo_infos: Object,
        organogram: Array,
        designation_ids: Array,
        permission: Object
    },
    components: {
        ContentHeaderSection,
        FloatingActionBtn
    },
    setup(props) {
        const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
        const { emp_id, geo_infos, organogram, designation_ids } = toRefs(props)
        const preloader = ref(false)
        const sel_designations = ref(designation_ids)
        const message = ref('')
        const add_designations = (id) => {
            if (sel_designations.value[id]) delete sel_designations.value[id]
            else sel_designations.value[id] = true
        }

        const data_submit = () => {
            preloader.value = true
            axios.post(`/${backendPrefix.value}/employees/designation`, {
                employee_id: emp_id.value,
                geos: geo_infos.value,
                designations: sel_designations.value
            }).then(async (response) => {
                //console.log(response)
                message.value = response.data.message
                preloader.value = false
            }).catch(error => {
                preloader.value = false
                //console.log(error.response.data.error)
            })
        }

        const remove_msg = () => {
            message.value = ''
        }

        return {
            preloader,
            emp_id,
            geo_infos,
            organogram,
            sel_designations,
            message,
            add_designations,
            data_submit,
            remove_msg
        }
    }
}
</script>
<style lang="scss" scoped>
.emp_organogram_block {
    position: relative;
    padding-bottom: 50px;

    :deep(.action_btn) {
        bottom: 10px
    }

    .list_block {
        border: 1px solid #ddd;
        border-radius: 5px;

        .section_block {
            height: 30px;
            background-color: #f7f7f7;
            border-bottom: 1px solid #ddd;
            border-radius: 5px 5px 0 0;
        }

        .designation_block {
            height: 34px;
            cursor: pointer;

            &>.input_block {
                width: 22px;
                height: 22px;
                display: flex;
                align-items: center;
                border: 1px solid #999;
                justify-content: center;
                border-radius: 5px;

                &>i {
                    display: none;
                    font-size: 10px;
                }
            }

            &.active,
            &:hover {
                color: #006699;

                &>.input_block {
                    border-color: #006699;

                    &>i.remove {
                        display: none;
                    }

                    &>i.check {
                        display: block;
                        color: #006699
                    }
                }
            }

            &.active {
                &:hover {
                    color: #CD0000;

                    &>.input_block {
                        border-color: #CD0000;

                        &>i.check {
                            display: none;
                        }

                        &>i.remove {
                            display: block;
                            color: #CD0000;
                        }
                    }
                }
            }
        }
    }
}</style>
