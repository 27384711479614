<template>

        <div class="container">
            <div class="row text-center">
                <div class="col-lg-6 offset-lg-3 col-sm-6 offset-sm-3 col-12 p-3 error-main">
                    <div class="row">
                        <div class="error_page col-lg-8 col-12 col-sm-10 offset-lg-2 offset-sm-1">
                            <i class="fa fa-lock"></i>
                            <h1 class="m-0">403</h1>
                            <h6>Forbidden!</h6>
                            <p>You don't have permission to access on this page </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
</template>
<script>
import Layout from '@/Shared/Layout'

export default {
  components: {   
  },
  layout: Layout, 
  
}
</script>
<style>
    .error_page>i{
        font-size:90px !important;
        color:#5D6572;
        margin-top:20px;
        padding-bottom: 20px;
    }
   
    .error-main h1{
        font-weight: bold;
        color: #444444;
        font-size: 100px;
        text-shadow: 2px 4px 5px #6E6E6E;
    }
    .error-main h6{
        color: #42494F;
    }
    .error-main p{
        color: #9897A0;
        font-size: 14px; 
    }
    .error_page.col-lg-8.col-12.col-sm-10.offset-lg-2.offset-sm-1 {
       
        margin-top: 89px;
    }
</style>
