<template>
    <span v-if="status==1" class="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">Govt. Employee</span>
    <span v-else class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">Citizen</span>
</template>
<script>
export default {
  name: 'StatusLevelCitizen',  
  props:{
    status: [String, Number, Boolean],
  }
}
</script>
<style lang="scss" scoped>
  span{
    display: inline-block;    
    & > i{
      font-size: 20px;
      color: #ccc;
    }
    &.active > i{
      color: #009a66;
    }
  }
</style>