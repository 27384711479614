<template>

    <div class="flex min-h-screen flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">

            <Head :title="'Login | Gambia'"></Head>
            <NuxtLink class="flex justify-center items-center" :to="{ path: '/' }">
                <AppLogo />
            </NuxtLink>
            <h2 class="mt-6 text-center text-[26px] font-bold tracking-tight text-gray-900">Sign in to your account</h2>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 border border-gray-100 shadow sm:rounded-lg sm:px-10">
                <LoginForm />
            </div>
        </div>
    </div>

</template>
<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import LoginForm from './LoginForm'
import AppLogo from './AppLogo'
export default {
    components: {
        LoginForm,
        AppLogo,
        Head
    }
}
</script>
<style lang="scss" scoped>
$side_width: 400;
$side_height: 450;

.login_block {
    margin: 5% 0;
    box-shadow: 0 0 25px #ccc;
    border-radius: 5px;

    &>* {
        position: relative;
        width: #{$side_width}px;
        height: #{$side_height}px;
        background-color: #fff;
    }

    .left_side {
        &>.bg_shade {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-color: transparent;
            z-index: 3;
        }

        &>.form_info_block {
            position: relative;
            display: block;
            width: 100%;
            z-index: 5;
        }

        &>.left_side_bottom_bg {
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 75%;
            z-index: 1;

            &>img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        &>.right_side_top_bg {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 75%;
            z-index: 1;

            &>img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .login_block {
        margin: 15% 25px;

        &>* {
            width: 100%
        }
    }
}

.right_side_content {
    position: relative;
    width: 100%;
    height: 100%;

    &>.img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 0 5px 5px 0;

        &>img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    & .info {
        position: absolute;
        bottom: 15%;
        width: 100%;
        left: 0;
        text-align: center;
        padding: 0 20%;
        color: #fff;

        &>h2 {
            font-size: 32px;
            font-weight: 600;
            line-height: 40px;
        }

        &>h4 {
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
        }
    }
}
</style>
