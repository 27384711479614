<template>
    <div class="content_view_layout mt-4">
        <!-- {{ data }} -->
        <div v-for="(element,elm_index) in data" class="block relative element_layer_block" :key="elm_index">
            <div class="grid grid-cols-12 relative grid_cols_block">
                <div :class="['block','col-span-' + (column.size?column.size:6),'relative','rounded-md']" v-for="(column,col_index) in element.column" :key="col_index">
                    <template v-if="column.data.length > 0">
                        <div :class="['block','relative','content_layer_block']" v-for="(item,index) in column.data" :key="index">
                            <div v-if="item.type=='rich_txtarea' || item.type=='embed'" v-html="item.content"></div>
                            <div v-else-if="item.type=='image'">
                                <img :src="item.content" :alt="'image'" />
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div style="display: none;">
            <vue-editor :editorToolbar="customToolbar" />
        </div>
    </div>
</template>
<script>
import { Quill, VueEditor } from "vue3-editor"
export default {
    name: 'ContentViewBlock',
    props: {
        data: Array
    },
    components: {
        VueEditor,
        Quill,
    },
    data(){
        return {
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline", "strike"], // toggled buttons
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                // ["link", "image", "video"],
                ["link"],
                ["clean"] // remove formatting button
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
    .grid_cols_block{
        gap: 15px;        

        .content_layer_block{
            margin-top: 15px;
            &:first-child{
                margin-top: 0;
            }
            :deep(p){
                margin: 0; padding: 0
            }
            :deep(h1){
                font-size: 30px;
            }
            :deep(h2){
                font-size: 26px;
            }
            :deep(h3){
                font-size: 22px;
            }
            :deep(h4){
                font-size: 18px;
            }
            img{
                width: 100%; height: 100%; object-fit: contain;
            }
        }
    }
</style>