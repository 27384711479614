<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
      <trashed-message v-if="tutorial.deleted_at" class="mb-6" @restore="restore"> This Category has been deleted. </trashed-message>
      <div class="flex justify-start mb-8 max-w-3xl">
        <img v-if="tutorial.cover_photo" class="block ml-4 w-8 h-8 rounded-full" :src="tutorial.cover_photo" />
      </div>
      <div class="max-w-5xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="update">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.title" :error="form.errors.title" class="pb-8 pr-6 w-full lg:w-1/2" @keyup="slugBuild" label="Title" required="true" placeholder="i.e The history of the Philippines in 12 minutes"/>
            <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full lg:w-1/2" label="Slug" required="true" placeholder="i.e the-history-of-the-philippines-in-12-minutes"/>
            <text-input v-model="form.embed_code" :error="form.errors.embed_code" class="pb-8 pr-6 w-full lg:w-1/2" label="Embed Code" required="true" placeholder="i.e https://www.youtube.com/watch?v=P-I4Bay5SXo"/>
            <select-input v-model="form.cat_id" :error="form.errors.cat_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Category" required="true">
              <option :value=null>Select Category</option>
              <option v-for="tutorialC in tutorialCat" :key="tutorialC.id" :value='tutorialC.id'>{{ tutorialC.title }}</option>
            </select-input>
            <file-input v-model="form.cover_photo" :error="form.errors.cover_photo" class="pb-8 pr-6 w-full lg:w-1/1" type="file" accept="image/*" label="Cover Photo" />
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status" class="pb-8 pr-6 w-full lg:w-1/1"></status-toogle-button>
          </div>
          <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
            <button v-if="!tutorial.deleted_at && permission.edit>0" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
            <Button v-if="permission.edit>0" :type="type" :name="head" :url="createUrl" :loading="form.processing"></Button>
          </div>
        </form>
      </div>
    </div>
  </template>

  <script>
  import Breadcrumbs from '@/Shared/Breadcrumbs'
import Button from '@/Shared/Button'
import DeleteIcon from '@/Shared/DeleteIcon'
import FileInput from '@/Shared/FileInput'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import TextareaInput from '@/Shared/TextareaInput'
import TextInput from '@/Shared/TextInput'
import TrashedMessage from '@/Shared/TrashedMessage'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject } from 'vue'
  export default {
    components: {
      Head,
      Icon,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      TrashedMessage,
      StatusToogleButton,
      Breadcrumbs,
      DeleteIcon,
      Button,
      TextareaInput,
      FileInput
    },
    layout: Layout,
    props: {
        tutorial: Object,
        permission: Object,
        tutorialCat: Array
    },
    remember: 'form',
    setup(props){
      const { tutorial,tutorialCat,permission} = toRefs(props)
      const type = ref('edit')
      const title = ref(tutorial.value.title)
      const head = ref('Tutorials')
      const url = ref('/backend/tutorials')
      const createUrl = ref('')
      const swal = inject('$swal')
      const elm = ref(this)
      const form = useForm({
        title:tutorial.value.title,
        slug: tutorial.value.slug,
        cat_id: tutorial.value.cat_id,
        embed_code: tutorial.value.embed_code,
        cover_photo: null,
        status: tutorial.value.status
      })
      const slugBuild = () => {
        form.slug = form.title.replace(/\s/g,'-').toLowerCase()
    }
      const update = () => {
        form.post(`${url.value}/${tutorial.value.id}`, {forceFormData: true,})
        title.value = form.title
      }
      const destroy = () => {
        swal({
            title: 'Are you sure?',
            text: "Do you really want to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.delete(`${url.value}/${tutorial.value.id}`)
              Swal.fire(
                'Deleted!',
                'Your infomation has been deleted.',
                'success'
              )
            }
          });
      }
      const restore = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to restore this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Resore it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.put(`${url.value}/${tutorial.value.id}/restore`)
              Swal.fire(
                'Restored!',
                'Your infomation has been Restored.',
                'success'
              )
            }
          });
      }
      const statusCheck= () => {
        if(tutorial.value.status==1){
          form.status=true;
          return true;
        }else{
          form.status=false;
          return false;
        }
      }
      return {
        form,
        tutorial,
        type,
        title,
        head,
        url,
        createUrl,
        tutorialCat,
        update,
        destroy,
        restore,
        statusCheck,
        slugBuild,
        swal,
        permission
      }
    },
    created: function(){
      this.statusCheck();
    }

  }
  </script>
