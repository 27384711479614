<template>
  <div>
    <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>      
    <div class="flex items-center justify-between mb-6">
      <search-filter-new  v-model="form.search"  @reset="reset">
        <template v-slot:search>        
            <text-date-picker v-model="form.start"  class="pr-3 pb-2 lg:w-1/5 sm:w-full"  required="true" placeholder="i.e 01-01-2022" />
            <text-date-picker v-model="form.end" class="pr-3 lg:w-1/5 sm:w-full" required="true" placeholder="i.e 01-01-2022" />
            <select-input v-model="form.office_id" class="pr-3 lg:w-1/5 sm:w-full"   >
                <option value=null >Select Office</option>
                <option v-for="office in offices" :key="office.id" :value='office.id'>{{ office.office_name }}</option>
            </select-input>
            <select-input v-model="form.ministry_id" class="pr-3 lg:w-1/5 sm:w-full"   >
                <option value=null >Select Ministry</option>
                <option v-for="ministry in ministries" :key="ministry.id" :value='ministry.id'>{{ ministry.ministry_name }}</option>
            </select-input>
            <select-input v-model="form.region_id" class="pr-3 lg:w-1/5 sm:w-full"   >
                <option value=null >Select Region</option>
                <option v-for="region in regions" :key="region.id" :value='region.id'>{{ region.region_name }}</option>
            </select-input>      
            <select-input v-model="form.province_id" class="pr-3 lg:w-1/5 sm:w-full">
                <option value=null >Select Province</option>  
                <option v-for="province in provinces" :key="province.id" :value='province.id'>{{ province.provience_name }}</option>            
            </select-input>
            <select-input v-model="form.municipality_id" class="pr-3 lg:w-1/5 sm:w-full">
                <option value=null >Select Municipality</option>
                <option v-for="municipality in municipalities" :key="municipality.id" :value='municipality.id'>{{ municipality.municipality_name }}</option>
            </select-input>
            <select-input v-model="form.barangay_id" class="pr-3 lg:w-1/5 sm:w-full">
                <option value=null >Select Barangay</option>
                <option v-for="barangay in barangays" :key="barangay.id" :value='barangay.id'>{{ barangay.barangay_name }}</option>              
            </select-input>
            <div class="pr-3 lg:w-1/5 sm:w-full flex">
              <select-input v-model="form.length" class="pr-3 lg:w-4/5 ">
              <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="75">75</option>
                <option value="100">100</option>
                <option value="150">150</option>
                <option value="200">200</option>             
            </select-input> 
            <div class="pr-3 lg:w-1/4 sm:w-full mt-2">
              <span @click="cleanFilter()">
                <svg class="h-5 w-5 text-gray-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -5v5h5" />  <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 5v-5h-5" /></svg>
              </span>
             </div>  
            </div>
                     
        </template>
      </search-filter-new>
     
    </div>
    <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left font-bold">
            <th class="pb-2 pt-3 px-3">Sl.</th>
            <th class="pb-2 pt-3 px-3">Date</th>
            <th class="pb-2 pt-3 px-3">Table Name</th>
            <th class="pb-2 pt-3 px-3">Log type</th>
            <th class="pb-2 pt-3 px-3">Responsible</th>
            <th class="pb-2 pt-3 px-3">Action</th>
          </tr>
        </thead>
        <tbody>            
          <tr v-for="(log,index) in logs.data" :key="log.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
            <td class="border-t pb-2 pt-3 px-3">
              {{ (index + logs.from) }}
            </td>
            <td class="border-t">
             {{log.log_date }}
            </td>
            <td class="border-t">               
                {{ log.table_name }}
              
            </td>
            <td class="border-t">               
                {{ log.log_type }}
            </td>
           
            <td class="border-t">               
                {{ log.users }}
            </td>
            <td class="w-px border-t">
              <div class="flex items-center px-2" v-if="permission.edit>0">
                <Link class="px-4 py-2" :href="`/backend/logtrackers/${log.id}`" tabindex="-1">
                  <i class="fa-solid fa-eye"></i>
                </Link>                
              </div>
            </td>
          </tr>
          <tr v-if="logs.data.length === 0">
            <td class="px-6 py-4 border-t" colspan="4">No Log found.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination class="mt-2 mb-2" :links="logs.links" />
  </div>
</template>

<script>
import axios from "axios"
import { ref, toRefs, onMounted,onUpdated } from 'vue'
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import SearchFilterNew from '@/Shared/SearchFilterNew'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import StatusLevel from '@/Shared/StatusLevel'
import Button from '@/Shared/Button'
import TextDatePicker from '@/Shared/TextDatePicker'
import SelectInput from '@/Shared/SelectInput'
export default {
  components: {
    Head,
    Icon,
    Link,
    Pagination,
    SearchFilterNew,
    Breadcrumbs,
    StatusLevel,
    Button,
    TextDatePicker,
    SelectInput
  },
  layout: Layout,
  props: {
    filters: Object,
    officeLayers: Array,
    offices: Array,
    regions: Array,
    ministries: Array,
    logs: Object,
    permission: Object,
  },
  setup(props){
    const { filters, logs, permission,officeLayers,offices,regions,ministries } = toRefs(props)
    const form = ref({
      search: filters.value.search,
      trashed: filters.value.trashed,
      length: filters.value.length?filters.value.length:15,
      status: filters.value.status,
      start: filters.value.start,
      end: filters.value.end,
      office_id: filters.value.office_id,
      ministry_id: filters.value.ministry_id,
      office_layer_id: filters.value.office_layer_id,
      region_id: filters.value.region_id,
      province_id: filters.value.province_id,
      municipality_id: filters.value.municipality_id,
      barangay_id: filters.value.barangay_id      
    })
    const provinces=ref([]);
    const municipalities=ref([]);
    const barangays=ref([]);
    const loadProvinces = async () =>{
      //console.log(form.value.region_id);
      if(form.value.region_id>0){
      let data={
        geo_region_id:form.value.region_id
      }
      await axios.post('../../backend/provinces/activeprovinces', data)
      .then(function (response) {                    
          provinces.value = response.data;
      });
      }else{
          provinces.value =[];   
          municipalities.value =[];
          barangays.value = [];
          form.value.province_id=null                
          form.value.municipality_id=null     
          form.value.barangay_id=null            
      }
    }
    const loadMunicipality = async (e) =>{
        if(form.value.province_id>0){
        let data={
          geo_provience_id:form.value.province_id
        }        
        await axios.post('../../backend/municipalities/activemunicipalities', data)
        .then(function (response) {
            municipalities.value = response.data;  
        });
        }else{
            municipalities.value =[];
            barangays.value = [];              
            form.value.municipality_id=null              
            form.value.barangay_id=null
            
        }
    }
    const loadBarangay= async () =>{
        if(form.value.municipality_id>0){
        let data={
            geo_municipality_id:form.value.municipality_id
        }
        await axios.post('../../backend/barangays/activebarangays', data)
        .then(function (response) {
            barangays.value = response.data;
        }.bind(this));   
        }else{                 
          barangays.value = [];
          form.value.barangay_id=null
        }
    }
    const cleanFilter= async () =>{
      form.value.search=null;
      form.value.trashed=null;
      form.value.length= filters.value.length?filters.value.length:15,
      form.value.start=null;
      form.value.end=null;
      form.value.office_id=null;
      form.value.ministry_id=null;
      form.value.office_layer_id=null;
      form.value.region_id=null;
      form.value.province_id=null;
      form.value.municipality_id=null;
      form.value.barangay_id=null;
    }
    const type = ref('index')
    const title = ref('Audit Log')
    const url = ref('')
    const createUrl = ref('')
    const reset = () => {
      form.value = mapValues(form, () => null)
    }
    onMounted(() => {
      loadProvinces();
      loadMunicipality();
      loadBarangay();
    })
    onUpdated(() => {
      //console.log('paichi');
      loadProvinces();
      loadMunicipality();
      loadBarangay();
    })
    return {
      filters,
      logs,
      officeLayers,
      offices,
      regions,
      ministries,
      permission,
      form,
      type,
      title,
      url,
      createUrl,
      reset,
      loadProvinces,
      provinces,
      loadMunicipality,
      municipalities,
      barangays,
      loadBarangay ,
      cleanFilter       
    }
  },
  watch: {
    form: {
      deep: true,
      handler: throttle(function () {         
        this.$inertia.get('/backend/logtrackers', pickBy(this.form), { preserveState: true })
      }, 150),
    },
  }
  
}
</script>
