<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url" />
      <div class="max-w-2xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="store">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">

            <text-input v-model="form.social_title" :error="form.errors.social_title" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Social Link" required="true" placeholder="i.e Facebook"/>
            <text-input v-model="form.url" :error="form.errors.url" class="pb-8 pr-6 w-full lg:w-1/2" label="URL of Social Link" required="true" placeholder="i.e https://www.facebook.com/"/>
            <text-input v-model="form.hierarchy" :error="form.errors.hierarchy" class="pb-8 pr-6 w-full lg:w-1/2" label="Hierarchy" placeholder="i.e 1" type="number" oninput="this.value = Math.abs(this.value)" min="0"/>
            <div class="pb-8 pr-6 w-full lg:w-1/2 grid grid-cols-12">
              <text-input v-model="form.icon" :error="form.errors.icon" class="col-span-11" label="Icon" placeholder="i.e 1" disabled/>
              <vue3-icon-picker v-model="form.icon" class="mt-5"/>
            </div>
            <select-input v-model="form.display" :error="form.errors.display" class="pb-8 pr-6 w-full lg:w-1/2" label="Display" >
              <option value=null >Select Display</option>
              <option value='Icon' >Icon</option>
              <option value='Text' >Text</option>
              <option value='Icon & Text' >Icon & Text</option>
            </select-input>
            <text-input v-model="form.color" :error="form.errors.color" class="pb-8 pr-6 w-full lg:w-1/2" label="Color of Social Link" required="true" placeholder="i.e " type="color"/>
            <text-input v-model="form.size" :error="form.errors.size" class="pb-8 pr-6 w-full lg:w-1/2" label="Size of Social Link" required="true" placeholder="i.e 20px"/>
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Social Link" class="pb-8 pr-6 w-full lg:w-1/2 mt-5"></status-toogle-button>
          </div>
          <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>
        </form>
      </div>
    </div>

  </template>

  <script>
  import axios from 'axios';
  import { ref, toRefs,inject } from 'vue'
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import TextareaInput from '@/Shared/TextareaInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Button from '@/Shared/Button'
  import FileInput from '@/Shared/FileInput'
  import Vue3IconPicker from '@/Shared/components/Vue3IconPicker'
  export default {
    props: {
      propertyTypes: Array,
    },
    components: {
      Head,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      Breadcrumbs,
      StatusToogleButton,
      Button,
      FileInput,
      Vue3IconPicker,
      TextareaInput
    },
    layout: Layout,
    remember: 'form',
    setup(props){
      const { propertyTypes } = toRefs(props)
      const form = useForm({
        social_title: null,
        url: null,
        hierarchy: null,
        icon: 'fab fa-facebook',
        display: null,
        color: '#2a6ff8',
        size: null,
        status: true,
      })
      // const regions = ref([])
      const type = ref('create')
      const title = ref('Social Link')
      const url = ref('/backend/social-links')
      const createUrl = ref('')
      const swal = inject('$swal')
      const store = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}`, {forceFormData: true,})
              Swal.fire(
                'Added!',
                'Your infomation has been Added.',
                'success'
              )
            }
          });
      }
      return {
        form,
        propertyTypes,
        type,
        title,
        url,
        createUrl,
        store
      }
    }
  }
  </script>
