<template>
    <span v-if="type == 'index'">

        <Head :title="header + ' | Gambia'" />
        <h1 class="mb-8 text-3xl">{{ header }}</h1>
    </span>
    <span v-if="type == 'create'">

        <Head :title="header + ' | Gambia'" />
        <h1 class="mb-8 text-3xl">
            <Link class="text-indigo-400 hover:text-indigo-600" :href="url">{{ header }}</Link>
            <span class="text-indigo-400 font-medium"> / </span>Create
        </h1>
    </span>
    <span v-if="type == 'edit'">

        <Head :title="head + ' | Gambia'" />
        <h1 class="mb-8 text-3xl">
            <Link class="text-indigo-400 hover:text-indigo-600" :href="url">{{ head }}</Link>
            <span class="text-indigo-400 font-medium"> / </span>
            {{ header }}
        </h1>
    </span>
</template>
<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
export default {
    components: {
        Head,
        Link
    },
    name: 'Breadcrumbs',
    props: {
        type: String,
        header: String,
        url: String,
        head: String
    }
}
</script>
