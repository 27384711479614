<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs> 
        <div class="flex items-center justify-between mb-6">
            <search-filter v-model="form.search" class="mr-4 w-full max-w-xl" @reset="reset">
            <template v-slot:filter>
                <select v-model="form.trashed" class="form-select  w-28">
                <option :value="null" >Filter</option>
                <option value="with">With Trashed</option>
                <option value="only">Only Trashed</option>
                </select>
            </template>
            <template v-slot:page>
                <select v-model="form.length" class="form-select  w-20">
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="75">75</option>
                <option value="100">100</option>
                <option value="150">150</option>
                <option value="200">200</option>
                </select>
            </template>
            <template v-slot:status>
                <select v-model="form.status" class="form-select w-32">
                <option :value="null">Status</option>
                <option value="1">Active</option>
                <option value="2">Inactive</option>
                </select>
            </template>
            </search-filter>
            <Button :type="type" :name="title" :url="createUrl"></Button>
        </div>
        <div class="bg-white rounded-md shadow overflow-x-auto">
            <table class="w-full whitespace-nowrap">
                <thead>
                    <tr class="text-left font-bold">
                        <th class="pb-2 pt-3 px-3">Service Name</th>
                        <th class="pb-2 pt-3 px-3">Category Name</th>
                        <th class="pb-2 pt-3 px-3 text-center">Status</th>
                        <th class="pb-2 pt-3 px-3">Actions</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>
        <pagination class="mt-6" :links="serviceinfos.links" />
    </div>
</template>
<script>
import { Head } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject } from 'vue'
import Layout from '@/Shared/Layout'
export default {
    components: {
        Head,
    },
    layout: Layout,
    props: {
      filters: Object,
      serviceinfos: Object
    },
    setup(props){
      const { filters, serviceinfos } = toRefs(props)
      const form = ref({
        search: filters.value.search,
        trashed: filters.value.trashed,
        length: filters.value.length?filters.value.length:15,
        status: filters.value.status,
      })
      const type = ref('index')
      const title = ref('Service')
      const url = ref('')
      const createUrl = ref('/backend/serviceinfos/create')

      return {
        filters,
        serviceinfos,
        form,
        type,
        title,
        url,
        createUrl,
      }
    }
}
</script>