<template>
  <draggable :class="['dragArea',{has_child:menus.length > 0}]" handle=".section_drag_cross" tag="ul" :list="menus" :group="{ name: 'g1' }">
    <template v-for="element in menus" >
      <li :class="{has_child:element.menus.length > 0}">
        <div :class="element.status==1
        ? 'flex items-center gap-2 bg-gray-100 input_block'
        : 'flex items-center gap-2 bg-yellow-400 input_block'">
          <!-- <i class="fa fa-check section_checkbox rounded-full"></i> -->
          <i :class="element.icon+' section_checkbox rounded-full'"></i>
          <i class="fa fa-up-down-left-right rounded-full section_drag_cross"></i>
          <span class="nodename">{{ element.nodeName }}</span>
          <span class="noderoute ml-auto">{{ element.slug }}</span>
          <span class="flex items-center gap-1 text-[10px] action_button">
              <i class="fa fa-pencil edit_btn text-green-800" role="button" @click="edit_item(element)" ></i>
              <!-- @click="emit('updateForm', element)" -->
              <i v-if="element.status==1" class="fa fa-trash del_btn text-red-800" role="button" @click="del_item(element.id,0)"></i>
              <i v-else class="fas fa-trash-restore success_btn text-green-800" role="button" @click="del_item(element.id,1)"></i>
          </span>
        </div>
        <nested-draggable :menus="element.menus" :fromData="fromData" />
      </li>
    </template>
  </draggable>
</template>
<script>
  import axios from 'axios';
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import { ref, toRefs, onMounted , inject} from 'vue'
  import { VueDraggableNext as draggable } from 'vue-draggable-next'
  import EventBus from '@/services/EventBus.js';
  export default {
    props: {
      menus: Array,
      fromData: Object,
      handleClick: Function
    },
    emits: ["event"],
    components: {
      draggable,
      toRefs,
      EventBus
    },
    name: "nested-draggable",
    setup(props, { emit }){
      const { menus,fromData} = toRefs(props)
      const swal = inject('$swal')
      const url = ref('/backend/dashboard-managements')
      const edit_item = (fromdata) => {
       EventBus.emit('fromdata', fromdata);
      }
      const del_item = (id,status) => {
        let data={
          id:id,
          status:status
        }
        axios.post(`${url.value}/delete-dashboard`, data)
        .then(function (response) {
            ////console.log(response);
            swal({
                position: 'top-end',
                icon: 'success',
                title: 'Dashboard successfully updated.',
                showConfirmButton: false,
                timer: 1500
            })
        }).catch(error => {
            swal({
                position: 'top-end',
                icon: 'error',
                title: error,
                showConfirmButton: false,
                timer: 1500
            })
        });
        EventBus.emit('deleteStatus', id);
      }

      return {
        menus,
        fromData,
        edit_item,
        del_item
      }
    },
  };
</script>
<style lang="scss" scoped>
  $input_height: 30;
  .layer_info_block{
      display: block;
      padding: 15px;
      select {
          width: 100%;
          height: 30px;
          border: 1px solid #ddd;
          padding: 0 10px;
          border-radius: 5px;
      }
  }
  li{
      position: relative;
      margin-left: 9px;
      &.has_child{
        &::before{
            position: absolute;
            content: "";
            width: 5px;
            // height: calc(100% - 8px);
            height: 24px;
            border-left: 1px solid #999;
            border-bottom: 1px solid #999;
            border-radius: 0 0 0 5px;
            left: 14px;
            top: 28px;
        }
      }
      .input_block{
        position: relative;
        height: #{$input_height}px;
        user-select: none;
        margin: 5px 0;
        padding: 0 12px 0 0;
        border: 1px solid #ddd;
        border-radius: 25px;
        & > i{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            width: 28px;
            height: 28px;
            font-size: 10px;
            &.rounded-full{
                border: 1px solid #999;
            }
            &.section_drag_cross{
                display: none;
                cursor: move;
            }
        }
        &:hover{
            i.section_checkbox{
              display: none;
            }
            i.section_drag_cross{
                display: flex;
            }
        }
      }
      & > ul{
          position: relative;
          padding-left: 24px;

          &.has_child{
            &::before{
                position: absolute;
                content: "";
                width: 20px;
                height: 5px;
                border-bottom: 1px solid #999;
                border-left: 1px solid #999;
                border-radius: 0 0 0 5px;
                left: 14px;
                top: 12px;
            }
          }
      }
  }
</style>
