<template>
  <TransitionRoot as="template" :show="categoryopen">
    <Dialog as="div" class="relative z-[9999]" @click="categoryopen = false">
      <div class="fixed inset-0" />
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
              leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                  <div class="h-0 flex-1 overflow-y-auto">
                    <div class="bg-indigo-700 px-4 py-6 sm:px-6">
                      <div class="flex items-center justify-between">
                        <DialogTitle class="text-base font-semibold leading-6 text-white">Category</DialogTitle>
                        <div class="ml-3 flex h-7 items-center">
                          <button type="button"
                            class="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            @click="categoryopen = false">
                            <span class="absolute -inset-2.5" />
                            <span class="sr-only">Close panel</span>
                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div class="mt-1">
                        <p class="text-sm text-indigo-300">Select Categories for this post.</p>
                      </div>
                    </div>
                    <div class="flex flex-1 flex-col justify-between">
                      <div class="divide-y divide-gray-200 px-4 sm:px-6">
                        <div class="space-y-6 pb-5 pt-6">
                          <div class="mt-1 rounded-md border border-gray-300 p-3" v-if="edit == false">
                            <div class="grid grid-cols-2 gap-y-2 gap-x-1 mb-1">
                              <div class="font-14 pt-3 pb-3 font-bold">List Of Categories</div>
                              <div class="text-right mt-3">
                                <Button :name="'Add'" :icon="'fa-solid fa-plus'" :method="open_edit"></Button>
                              </div>
                            </div>
                            <hr>
                            <div class="grid grid-cols-1 gap-y-2 gap-x-1 mt-1  max-h-[322px] overflow-y-auto">

                              <div class="form-check form-check-inline flex" v-for="category in category_list"
                                :key="category.id">
                                <div class=" ">
                                  <input
                                    class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                    type="checkbox" :id="'decisioninfo-' + category.id" v-model="category_data"
                                    :value='category.id'>
                                </div>
                                <div>
                                  <label class="form-check-label inline-block text-gray-800"
                                    :for="'decisioninfo-' + category.id">
                                    {{ category.category_title }}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-if="edit == true"
                            class="mt-1 rounded-md border border-gray-300 p-3 max-h-[485px] overflow-y-auto">
                            <div class="clearfix">
                              <a class=" float-right font-semibold  rounded-[50%] shadow-sm text-sm text-red-800 cursor-pointer" @click="edit = false">
                                <i class="fa-solid fa-xmark p-1"></i>
                              </a>
                            </div>
                            
                            <form @submit.prevent="data_submit">
                              <text-input v-model="form.category_title" :error="form.errors.category_title"
                                class="pb-8 pr-6 w-full " label="Name of Category" required="true"
                                placeholder="i.e National Parks" />
                              <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full "
                                label="Name of Slug" required="true" placeholder="i.e national-parks" />
                              <select-input v-model="form.group_id" :error="form.errors.group_id"
                                class="pb-8 pr-6 w-full " label="Name of Group" required="true">
                                <option value=null>Select Group</option>
                                <option v-for="categoryGroup in category_group_list" :key="categoryGroup.id"
                                  :value='categoryGroup.id'>{{ categoryGroup.group_title }}</option>
                              </select-input>
                              <select-input v-model="form.pid" :error="form.errors.pid" class="pb-8 pr-6 w-full" label="Name of Parents" >
                                <option value=null >Select Parents</option>
                                <option v-for="categor in category_list" :key="categor.id" :value='categor.id'>{{ categor.category_title }}</option>
                              </select-input>
                              <text-input v-model="form.hierarchy" :error="form.errors.hierarchy"
                                class="pb-8 pr-6 w-full " label="Hierarchy" placeholder="i.e 1" type="number"
                                oninput="this.value = Math.abs(this.value)" min="0" />
                              <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Category" class="pb-8 pr-6 w-full "></status-toogle-button>
                              <div class="sm:col-span-2">                
                                <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Pictute <span class="custom-error">*</span></label>
                                <div class="mt-1 flex flex-wrap justify-center rounded-md border-2 border-dashed border-gray-300 dark:border-black dark:bg-white dark:text-black px-6 pt-5 pb-6">
                                    <div class="space-y-1 text-center">
                                      <PhotoBlock :getPhoto="form.image_path" @SetPhoto="set_photo" />  
                                    </div>
                                </div>
                              </div>
                              <div class="px-4 py-4">
                                <FloatingActionBtnFix :loader="preloader" :icon="'fa fa-check'" :message="message"
                                  @dataSubmit="data_submit" @removeMessage="remove_msg" />
                              </div>
                            </form>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="flex flex-shrink-0 justify-end px-4 py-4" v-if="edit == false">
                   

                    <button type="button" @click="categoryopen = false"
                      class="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>


import axios from 'axios';
import { ref, toRefs, inject, onMounted, watch } from 'vue'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import FileInput from '@/Shared/FileInput'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import Button from '@/Shared/Button'
import PhotoBlock from './Photo';
import FloatingActionBtnFix from "@/Shared/Buttons/FloatingActionBtnFix"
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/vue/20/solid'
const props = defineProps(['categories', 'category_list', 'categoryopen'])
const category_data = ref(props.categories)
const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
const edit = ref(false)
const swal = inject('$swal')  
const preloader = ref(false)
const message = ref('');
const form = useForm({
  group_id: null,
  category_title: null,
  slug: null,
  hierarchy: null,
  pid: null,
  status: true,
  image_path: null
})
const emit = defineEmits(['UpdateCategoryPopup', 'UpdateCategory'])

const category_list = ref([])
const load_categories = async () => {
  await axios.post(`/${backendPrefix.value}/categories/activecategories`)
    .then(function (response) {
      ////console.log(response);
      category_list.value = response.data
    }.bind(this));
}
const category_group_list = ref([])
const load_category_group = async () => {
  await axios.post(`/${backendPrefix.value}/category-groups/activecategory-groups`)
    .then(function (response) {
      ////console.log(response);
      category_group_list.value = response.data
    }.bind(this));
}

const open_edit = () => {
  edit.value = true
}

const save = () => {
  emit('UpdateCategoryPopup', props.categoryopen)
  emit('UpdateCategory', category_data)
}

const set_photo = (val) => {
            form.image_path = val
        }
watch(() => props.categoryopen, () => {
  emit('UpdateCategoryPopup', props.categoryopen)
}, { deep: true })
watch(() => category_data, () => {
  emit('UpdateCategory', category_data)
}, { deep: true })

onMounted(() => {
  load_categories()
  load_category_group()
})
const remove_msg = () => {
            message.value = ''
        }
const data_submit = () => {
    preloader.value = true
    axios.post(`/${backendPrefix.value}/categories/store-api`, form).then(async (response) => {        
        load_categories()        
        message.value = response.data.message
        preloader.value = false
        edit.value=false
    }).catch(error => { 
        preloader.value = false
    })
}
</script>