<template>
    <div>
        <fieldset class="border border-solid border-gray-300 ">
            <legend class="text-sm">Project Info</legend> 
            <div class="flex flex-wrap -mb-8 -mr-6 p-8 previous" 
            v-for="(project, counter) in projects"
            v-bind:key="counter">
                <span class="deleteAttachment" @click="deleteProject(counter,project.id)"><delete-icon/></span>
                <text-input v-model="project.title"  class="pb-8 pr-6 w-full lg:w-1/2" label="Project Title"   placeholder="i.e Concreting of Ungus Matata-Sapa Road (1km)"/>
                <text-input v-model="project.project_location"  class="pb-8 pr-6 w-full lg:w-1/2" label="Project Location"  placeholder="i.e Ballak, Sapa, Tandubas"/>                
                <select-input v-model="project.project_year" class="pb-8 pr-6 w-full lg:w-1/2" label="Project Year"  >
                  <option value=null >Select Project Year</option>
                  <option v-for="(year, y) in yearsList" :key="y" :value="year">
                    {{year}}
                  </option>
                </select-input>
                <text-input v-model="project.project_amount" class="pb-8 pr-6 w-full lg:w-1/2" label="Project Amount" type="number"  placeholder="i.e 100" oninput="this.value = Math.abs(this.value)" />
                <status-toogle-button v-model="project.online"  label="Online Status" class="pb-8 mt-6 pr-6 w-full lg:w-1/2"></status-toogle-button> 
            </div>
            <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
              <button class="btn-indigo" @click="addProject" type="button">
              <i class="fa fa-plus-circle"></i>
                <span>Add</span>
                <span class="hidden md:inline">&nbsp;{{ name }}</span>More</button>
            </div>
        </fieldset>
    </div>
</template>
<script>

    import axios from "axios" 
    import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
    import { ref, toRefs, onMounted } from 'vue'
    import TextInput from '@/Shared/TextInput'
    import SelectInput from '@/Shared/SelectInput'
    import StatusToogleButton from '@/Shared/StatusToogleButton'
    import Button from '@/Shared/Button'
    import ContentHeaderSection from "@/Shared/ContentHeaderSection"
    import DeleteIcon from '@/Shared/DeleteIcon'
    export default {
    components: {
      Head,
      Link,
      SelectInput,
      TextInput,
      StatusToogleButton,
      Button,
      ContentHeaderSection,
      DeleteIcon
    },    
    remember: 'form',
    props: {
      projects: Array,
    },
    setup(props){ 
      const { projects } = toRefs(props)
      const addProject =()=>{
        projects.value.push({
          title: null,
          project_location: null,
          project_year: null,
          project_amount: null,
          online: true
        })
      }
      const url = ref('/backend/serviceinfos/project')
      const yearsList = ref([])
      const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
      const deleteProject= (counter,id=null)=>{
        if(id!=null){
          let data={
            id:id
          }
          axios.post(`/${backendPrefix.value}/serviceinfos/projectdelete`, data).then( async (response) => {
             //   //console.log(response)                                                     
            }).catch(error => {                    
              //  //console.log(error.response.data.error)                   
            })
        }
        projects.value.splice(counter,1);

      }
      const getYearsList = () => {
          const startYear = 1990;
          const endYear = new Date().getFullYear();
          for (let i = endYear; i >= startYear; i--) {
              yearsList.value = [...yearsList.value, i]
          }
      }
      onMounted(() => {
        getYearsList()
      })
      return {
        projects,
        addProject,
        deleteProject,
        yearsList,
        getYearsList,
        url
      }
    },
  }
</script>
<style>
.deleteAttachment {
  position: absolute;
  right: 53px;
  top: 22px;
}
.previous {
  position: relative;
}
.deleteAttachment {
    position: absolute;
    right: 53px;
    top: 22px;
}
legend.text-sm {
  font-size: 14px;
  font-weight: 500;
  padding-left: 5px;
  padding-right: 5px;
}
</style>