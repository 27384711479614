<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
      <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="store">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.title" :error="form.errors.title" class="pb-8 pr-6 w-full lg:w-1/2" label="Title" required="true" placeholder="i.e First Name"/>
            <text-input v-model="form.name" :error="form.errors.name" class="pb-8 pr-6 w-full lg:w-1/2" label="Name Of Field" required="true" placeholder="i.e first_name"/>
            <status-toogle-button v-model="form.required" :error="form.errors.required" label="Required Status" class="pb-8 mt-6 pr-6 w-full lg:w-1/2"></status-toogle-button>
            <status-toogle-button v-model="form.can_edit" :error="form.errors.can_edit" label="Can Edit Field " class="pb-8 mt-6 pr-6 w-full lg:w-1/2"></status-toogle-button>
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Data" class="pb-8 mt-6 pr-6 w-full lg:w-1/2"></status-toogle-button>
          </div>
          <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>
        </form>
      </div>
    </div>
  </template>

  <script>
  import { ref, toRefs, inject } from 'vue'
  import axios from 'axios';
  import { Head, Link,useForm } from '@inertiajs/inertia-vue3'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import SelectInputFunction from '@/Shared/SelectInputFunction'
  import LoadingButton from '@/Shared/LoadingButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Button from '@/Shared/Button'
  export default {
    components: {
      Head,
      Link,
      LoadingButton,
      SelectInput,
      SelectInputFunction,
      TextInput,
      Breadcrumbs,
      StatusToogleButton,
      Button,
    },
    layout: Layout,
    remember: 'form',
    props: {
    },
    setup(props){
      const form = useForm({
        required: null,
        can_edit: null,
        title: null,
        name: null,
        status: true,
      })

      const municipality = ref([])
      const type = ref('create')
      const title = ref('Citizen Profile Update Field')
      const url = ref('/backend/citizen-profiles')
      const createUrl = ref('')
      const swal = inject('$swal')
      const store = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}`, {forceFormData: true,})
              Swal.fire(
                'Added!',
                'Your infomation has been Added.',
                'success'
              )
            }
          });

      }

      return {
        form,
        type,
        title,
        url,
        createUrl,
        store
      }
    },

  }
  </script>
