<template>
    <div class="flex items-center gap-2 px-4 caption_header_block">
        <i :class="icon"></i>
        <span>{{ title }}</span>
    </div>
</template>
<script>
    import { toRefs } from 'vue'
    export default {
        name: 'CaptionHeaderBlock',
        props: {
            title: String,
            icon: String
        },
        setup(props){
            const { title, icon } = toRefs(props)
            return {
                title,
                icon
            }
        }
    }
</script>
<style lang="scss" scoped>
    .caption_header_block{
        height: 36PX;        
        font-size: 14px;
        font-weight: 600;        
        border-bottom: 1px solid #ddd;
        background: #f7f7f7;
    }
</style>