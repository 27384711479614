<template>
    <div class="office_list_map_block">
        <ContentHeaderSection :title="'Office Mapping'" :icon="'fa fa-cogs'" />
        <div class="p-5 office_map_block">
            <!-- <pre>{{ offices }}</pre> -->
            <div class="flex items-center gap-1 office_layer_block">
                <i class="fa fa-cog"></i>                
                <select v-model="sel_office_layer" @change="load_offices">
                    <option value="">Choose office layer</option>
                    <option v-for="layer in OfficeLayers" :key="layer.id" :value="layer.id">{{ layer.office_layer_name }} ({{ layer.name_eng_short }})</option>
                </select>                
            </div>

            <div class="office_block mt-4 p-4">
                <div v-if="preloader" class="loader_content_block">
                    <div><i class="fa fa-cog fa-spin"></i></div>
                    <div class="mt-2">Content loading...wait</div>
                </div>
                <div v-else-if="(offices.length>0)" class="flex items-baseline gap-2">
                    <div class="office_item_list">
                        <div v-for="office in offices" :class="['flex','items-center','gap-2','input_block',{active:sel_offices && sel_offices[office.id]}]" @click="sel_office(office)">
                            <span class="circle_block">
                                <i class="fa fa-check check"></i>
                                <i class="fa fa-times remove"></i>
                            </span>
                            <div>{{ office.office_name }} <template v-if="preloader"><i class="fa fa-cog fa-spin"></i> Checking...</template></div>
                        </div>
                    </div>

                    <div v-if="(get_office_organogram.length>0)" class="office_organogram_list">
                        <!-- <pre>{{ get_office_organogram }}</pre> -->
                        <ul>
                            <li v-for="organogram in get_office_organogram">
                                <div>{{ organogram.section?organogram.section.name:'' }}</div>
                                <ul v-if="organogram.designations.length>0">
                                    <li v-for="designation in organogram.designations">
                                        <div>{{ designation.name }}</div>
                                    </li>
                                </ul>
                            </li>                            
                        </ul>
                    </div>
                </div>
                <div v-else class="empty_content_block">
                    <div><i class="fa fa-box-open"></i></div>
                    <div class="mt-2">There is no data. Choose office layer to get office list</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios"
    import { ref, toRefs, inject } from 'vue'
    import ContentHeaderSection from "@/Shared/ContentHeaderSection"
    export default {
        name: "OfficeListMapBlock",        
        props: {
            OfficeLayers: Array,
            sel_offices: Object
        },
        components: {
            ContentHeaderSection
        },
        setup( props, { emit }) {
            const preloader = ref(false)
            const { OfficeLayers, sel_offices } = toRefs(props)
            const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
            const sel_office_layer = ref('')
            const offices = ref([])
            const get_office_organogram = ref([])
            
            const sel_office = (office) => {
                // //console.log(office_id)
                get_office_organogram.value = office.organogram
                emit('selOffice', office.id)            
            }
            
            const load_offices = () => {
                preloader.value = true
                if(sel_office_layer.value=='') {
                    offices.value = []
                    return false
                }
                let formData = {                    
                    office_layer_id: sel_office_layer.value
                }
                
                emit('selOfficeLayer', sel_office_layer.value)
                emit('resetData')

                axios.post(`/${backendPrefix.value}/office-layer-map/load-offices`, formData).then( async (response) => {
                    //console.log(response)
                    offices.value = response.data
                    preloader.value = false                                        
                }).catch(error => {                    
                    //console.log(error.response.data.error)
                    preloader.value = false
                })
            }

            return {
                preloader,
                OfficeLayers,
                sel_office_layer,
                offices,
                sel_offices,
                get_office_organogram,
                load_offices,
                sel_office                
            }
        }
    }
</script>
<style lang="scss" scoped>
    .empty_content_block,.loader_content_block{
        & > div {
            display: block;
            text-align: center;
            i{
                font-size: 34px;
                color: #ccc
            }
        }
    }
    .office_block{
        border: 1px solid #ddd;        
        border-radius: 10px;    
        .input_block{
            position: relative;
            height: 32px;
            cursor: pointer;

            & > .circle_block{
                display: flex;
                align-items: center;
                justify-content: center;            
                width: 20px; height: 20px;
                border: 1px solid #999;
                border-radius: 50%;

                & > i{
                    display: none;
                    font-size: 10px;                    
                }
            }

            &:hover{
                // font-weight: 600;
                .circle_block{
                    & > i.check {
                        display: block;
                    }
                }
            }

            &.active{
                color: #006699;
                font-weight: 600;
                .circle_block{
                    border-color: #006699;
                    & > i.check{
                        display: block;
                        color: #006699
                    }
                }
                &:hover{
                    color: #CD0000;                    
                    .circle_block{
                        border-color: #CD0000;
                        & > i.check{
                            display: none;
                        }
                        & > i.remove{
                            display: block;
                            color: #CD0000
                        }
                    }
                }
            }
        }

        .office_item_list{
            width: -webkit-fill-available;
        }
        .office_organogram_list{
            border-left: 1px solid #ddd;
            padding: 15px 0 15px 15px;
            border-radius: 15px;
            background-image: linear-gradient(to right, #eee, transparent);

            ul{
                li{
                    & > div{                
                        display: flex;
                        align-items: center;
                        height: 20px;
                        font-size: 12px;
                        white-space: nowrap;
                    }

                    & > ul{
                        padding-left: 15px;

                        & > li > div{
                            color: #666
                        }
                    }
                }

                & > li > div{                    
                    color: #997272;
                }                
            }
        }
    }
</style>