<template>    
    <Link class="btn-indigo" :href="url" v-if="type=='index'">
        <i class="fa fa-plus-circle"></i>
        <span>Create</span>
        <span class="hidden md:inline">&nbsp;{{ name }}</span>
    </Link>    
    <div v-else-if="type=='create'" class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
        <loading-button :loading="loading" class="btn-indigo" type="submit">
            <i class="fa fa-check-circle"></i> Create {{ name }}
        </loading-button>
    </div>   
    <div v-else-if="type=='createReset'" class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">      
        <loading-button :loading="loading" class="btn-orange m-r-10" type="button"  @click="method" >
            <i class="fa-solid fa-repeat"></i> Reset {{ name }}
        </loading-button>
        <loading-button :loading="loading" class="btn-indigo" type="submit">
            <i class="fa fa-check-circle"></i> Create {{ name }}
        </loading-button>
    </div>   
    <loading-button :loading="loading" class="btn-indigo ml-auto" type="submit" v-else-if="type=='edit'"><i class="fa fa-check-circle"></i> Update {{ name }}</loading-button>    

    <loading-button v-else :loading="loading" class="gap-1 ml-auto text-[11px] font-semibold" type="button"  @click="method" >
        <i :class="icon"></i>  {{ name }}
    </loading-button>

</template>
<script>
    import { Head, Link } from '@inertiajs/inertia-vue3'
    import LoadingButton from '@/Shared/LoadingButton'
    export default {
    components: {
        Head,
        Link,
        LoadingButton
    },
    name: 'Breadcrumbs',    
    props:{
        type:String,
        name:String,
        url:String,
        loading:Boolean,
        icon:String,
        method: { type: Function },
    }
    }
</script>