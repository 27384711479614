<template>
    <div :class="['form_layout_block',sel_device_id==2?'flex items-center justify-center':'block']">
        <div v-if="sel_device_id==2" class="flex flex-row items-center justify-center mobile_device_block">
            <MobileDeviceBgBlock />
        </div>
        <div :class="[sel_device_id==2?'absolute flex flex-row justify-center mobile_device_frame_block':'relative']">
            <div class="rounded-md">
                <div v-if="form_request_steps.length > 0" class="block py-5">
                    <FormStepsBlock :data="form_request_steps" :cur_step="cur_sel_form_step" @selCurFormStep="sel_cur_form_step" />
                </div>
                <template v-for="(element,elm_index) in data">
                    <div :class="['block','relative','element_layer_block']" v-if="!element.step || (element.step && cur_sel_form_step==(elm_index+1))">
                        <div :class="['grid','grid-cols-12','relative','gap-3']">
                            <!-- <div :class="['block','relative','grid_col_block']" v-for="(column,col_index) in element.column"> -->
                            <div :class="['block',(sel_device_id==1 && column.size?'col-span-' + column.size:'col-span-' + (column.mobile_size?column.mobile_size:12)),'relative','grid_col_block']" v-for="(column,col_index) in element.column">
                                
                                <template v-if="column.data.length > 0">                    
                                    <div :class="['block','relative','border','rounded-md','p-3','mb-4','content_layer_block']" v-for="(item,index) in column.data">
                                        <TxtInputFormBlock v-if="item.type=='txt_input'" :data="item" :index="index" :preview="true" />

                                        <NumberInputFormBlock v-else-if="item.type=='number_input'" :data="item" :index="index" :preview="true" />

                                        <TextAreaFormBlock v-else-if="item.type=='textarea'" :data="item" :index="index" :preview="true" />

                                        <SelectBoxFormBlock v-else-if="item.type=='selectbox'" :data="item" :index="index" :preview="true" />

                                        <CheckboxInputFormBlock v-else-if="item.type=='checkbox'" :data="item" :index="index" :preview="true" />

                                        <RadioInputFormBlock v-else-if="item.type=='radio'" :data="item" :index="index" :preview="true" />

                                        <DatePickerInputFormBlock v-else-if="item.type=='datepicker'" :data="item" :index="index" :preview="true" />

                                        <DateRangeInputFormBlock v-else-if="item.type=='daterange'" :data="item" :index="index" :preview="true" />

                                        <TimePickerInputFormBlock v-else-if="item.type=='timepicker'" :data="item" :index="index" :preview="true" />

                                        <SignatureInputFormBlock v-else-if="item.type=='signature'" :data="item" :index="index" :preview="true" />

                                        <GeoInputFormBlock v-else-if="item.type=='geo'" :data="item" :index="index" :preview="true" />

                                        <GenderInputFormBlock v-else-if="item.type=='gender'" :data="item" :index="index" :preview="true" />

                                        <ReligionInputFormBlock v-else-if="item.type=='religion'" :data="item" :index="index" :preview="true" />

                                        <NationalityInputFormBlock v-else-if="item.type=='nationality'" :data="item" :index="index" :preview="true" />

                                        <DisplayTextBlock v-else-if="item.type=='text'" :data="item" :index="index" :preview="true" />

                                        <DisplayTableBlock v-else-if="item.type=='table'" :data="item" :index="index" :preview="true" />
                                    </div>                    
                                </template>                
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <!-- <pre>{{ data }}</pre> -->
    </div>
</template>
<script setup>
    import MobileDeviceBgBlock from './Icons/MobileDevice'
    import FormStepsBlock from './FormSteps'
    import TxtInputFormBlock from './Forms/TextInput'
    import NumberInputFormBlock from './Forms/NumberInput'
    import TextAreaFormBlock from './Forms/TextArea'
    import SelectBoxFormBlock from './Forms/SelectBox'
    import CheckboxInputFormBlock from './Forms/CheckboxInput'
    import RadioInputFormBlock from './Forms/RadioInput'
    import DatePickerInputFormBlock from './Forms/DatePickerInput'
    import DateRangeInputFormBlock from './Forms/DateRangeInput'
    import TimePickerInputFormBlock from './Forms/TimePickerInput'
    import SignatureInputFormBlock from './Forms/SignatureInput'
    import GeoInputFormBlock from './Forms/GeoInput'
    import GenderInputFormBlock from './Forms/GenderInput'
    import ReligionInputFormBlock from './Forms/ReligionInput'
    import NationalityInputFormBlock from './Forms/NationalityInput'
    import GridLayoutBlock from './Layouts/GridLayout'
    import DisplayTextBlock from './Displays/Text'
    import DisplayTableBlock from './Displays/Table'

    import { ref, onMounted } from 'vue'

    const props = defineProps({
        data: Array,
        sel_device_id: Number
    })

    onMounted( () => {
        steps_organize()
    })

    const cur_sel_form_step = ref('1')
    const form_request_steps = ref([])    
    const sel_cur_form_step = (val) => {
        cur_sel_form_step.value = val
    }
    const steps_organize = () => {
        let step_id = 1;
        props.data.forEach((elm,elm_index) => {
            if(elm.step){
                let obj = {
                    id: step_id,
                    elm_index: elm_index,
                    name: elm.step_name?elm.step_name:`Step-${step_id}`,
                    description: elm.step_description?elm.step_description:``,
                    href: ``,
                    status: ''
                }
                form_request_steps.value.push(obj)
                step_id++
            }
        })
    }
</script>
<style lang="scss" scoped>
    .form_layout_block{
        position: sticky;
        top: 34px;
        border: 1px solid #eee;
        border-top: none;
        padding: 15px;
        border-radius: 0 0 5px 5px;

        .mobile_device_block {
            display: block;
            margin: 20px 0;
            width: 460px;
            height: 946.667px;
            box-shadow: rgba(0, 0, 0, 0.3) 0 0 20px;
            border-radius: 60px;
            backface-visibility: hidden;
        }

        .mobile_device_frame_block {
            top: 120px;
            left: 24px;
            padding: 0 25px;
            width: calc(100% - 50px);
            height: 750px;
            overflow: hidden;

            & > div{
                border: 2px solid #444;
                padding: 10px;
                width: 400px;
                height: calc(100% - 25px);
                overflow-x: hidden;
                overflow-y: hidden;                
                &:hover{
                    overflow-y: auto;
                }
            }
        }

        .element_layer_block{
            &.active{
                border: 1px solid #39bdff;

                & > .elm_action_btn_block{
                    &:deep(.action_block){
                        display: flex;
                    }
                }
            }            
        
            .grid_col_block{
                &.col-span-1{
                    grid-column: span 1 / span 1;
                }
                &.col-span-2{
                    grid-column: span 2 / span 2;
                }
                &.col-span-3{
                    grid-column: span 3 / span 3;
                }
                &.col-span-4{
                    grid-column: span 4 / span 4;
                }
                &.col-span-5{
                    grid-column: span 5 / span 5;
                }
                &.col-span-6{
                    grid-column: span 6 / span 6;
                }
                &.col-span-7{
                    grid-column: span 7 / span 7;
                }
                &.col-span-8{
                    grid-column: span 8 / span 8;
                }
                &.col-span-9{
                    grid-column: span 9 / span 9;
                }
                &.col-span-10{
                    grid-column: span 10 / span 10;
                }
                &.col-span-11{
                    grid-column: span 11 / span 11;
                }
                &.col-span-12{
                    grid-column: span 12 / span 12;
                }
                &.active{
                    border: 1px solid #39bdff;

                    & > .grid_action_btn_block{
                        &:deep(.action_block){
                            display: flex;
                        }
                    }
                }

                .content_layer_block{
                    user-select: none;
                    &.active{
                        border: 1px solid #39bdff;

                        & > .content_action_btn_block{
                            &:deep(.action_block){
                                display: flex;
                            }
                        }
                    }
                }
            }
        }
    }
</style>