<template>
    <div class="flex items-center gap-2 input_block">        
        <template v-if="content && !edit_mode">
            <i class="fa fa-check rounded-full"></i>
            <span>{{ content }}</span>
            <i class="fa fa-pencil edit_btn" role="button" @click="edit_mode=true"></i>
        </template>
        <template v-else>
            <i v-if="edit_mode" class="fa fa-edit rounded-full"></i>
            <i v-else class="fa fa-plus rounded-full"></i>
            <input type="text" v-model="data" placeholder="Enter/Choose designation" @keypress.enter="add_content()" />
            <!-- <i v-if="content!=data" class="fa fa-check action_btn" role="button" @click="$parent.add_content()"></i> -->
        </template>
    </div>
</template>
<script>
    export default {
        name: 'OfficeDesignationBlock',
        props: {
            index: Number,
            _index: Number,
            content: String
        },
        data(){
            return {
                data: this.content?this.content:'',
                edit_mode: false
            }
        },
        methods: {
            add_content(){
                if(this.edit_mode) {
                    this.$parent.update_designation(this.index,this._index,this.data)
                    this.edit_mode = false
                }else{
                    this.$parent.add_designation(this.index,this.data)
                    this.data = ''
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .input_block{
        height: 30px;
        i{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 18px;
            height: 18px;
            font-size: 10px;
            &.rounded-full{
                border: 1px solid #999;                
            }
        }
        input{
            width: calc(100% - 20px);
        }
        .action_btn{
            cursor: pointer;
            color: #217e21
        }
        .edit_btn{
            display: none;
            cursor: pointer;
            color: #666
        }
        &:hover{
            .edit_btn{
                display: inline-block;
            }
        }
    }
</style>