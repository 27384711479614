<template>
  <Head title="Login" />  
  <div class="grid">
    <div class="bg-slider">
      <div class="shade-overlay"></div>
      <div class="sliders">
        <div v-for="n in total_slides" :class="[{active:n==sel_slide},{inactive:n!==sel_slide}]" :style="{'background-image': 'url(/images/gallery/' + n +'.jfif)'}"></div>
        <!-- <img src="/images/gallery/1.jfif" /> -->
      </div>
    </div>
    
    <div class="login-block">
      <div class="flex items-center justify-center min-h-screen">
        <div class="w-full p-10 content-block">
          <div class="flex rounded-md overflow-hidden content-items-block">           
            <div class="login_info_bg p-10" v-if="(mobilestat==true)">
              <img src="/images/login-bg-01.png" />
            </div>
            <form class="shadow-xl bg-white overflow-hidden" @submit.prevent="login">
              <div class="px-10 pt-10">
                <logo :_for="'login'" />
                <h1 class="text-center text-2xl font-bold">Welcome Back!</h1>
                <!-- <div class="mt-6 mx-auto w-24 border-b-2" /> -->
                <div class="mt-6 flex rounded-md shadow-sm">
                  <span class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                    <UserIcon />
                  </span>
                  <!-- <input type="text" name="company-website" id="company-website" class="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="www.example.com"> -->
                  <input v-model="form.username" class="block w-full flex-1 outline-none rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm px-3 py-3" type="text" placeholder="Enter Username" autofocus autocapitalize="off" />
                </div>
                <!-- <text-input v-model="form.username" :error="form.errors.username" class="mt-10" type="text" placeholder="Enter username" autofocus autocapitalize="off" /> -->
                <div class="mt-6 flex rounded-md shadow-sm">
                  <span class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                    <LockIcon />
                  </span>
                  <span class="password_field">
                    <input v-if="showPassword"  v-model="form.password" class="block w-full flex-1 ouline-none rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm px-3 py-3" type="text" placeholder="Enter Password" />                  
                    <input v-else  v-model="form.password" class="block w-full flex-1 ouline-none rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm px-3 py-3" type="password" placeholder="Enter Password" />
                    <span class="vieweye"  @click="toggleShow">
                      <span class="icon is-small is-right">
                          <i class="fas" :class="{ 'fa-eye': showPassword, 'fa-eye-slash': !showPassword }"></i>
                      </span>
                    </span>
                  </span>
                  
                  
                  
                </div>
                <!-- <label class="flex items-center mt-6 select-none" for="remember">
                  <input id="remember" v-model="form.remember" class="mr-1" type="checkbox" />
                  <span class="text-sm">Remember Me</span>
                </label> -->
              </div>
              <div v-if="form.errors.username || form.errors.password" class="px-10 py-5">
                <div v-for="error in form.errors" class="flex items-center gap-1 err">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                  </svg>
                  <small v-if="error">{{ error }}</small>
                  <!-- <small v-if="form.errors.password">{{ form.errors.password }}</small> -->
                </div>
              </div>
              <div class="flex px-10 py-4 mt-4 border-t border-gray-200">
                <loading-button :loading="form.processing" :icon="'login'" class="flex items-center gap-2 login-btn ml-auto py-2 px-3 rounded-full" type="submit"><i class="fa fa-sign-in"></i> Login</loading-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import { ref, toRefs } from 'vue'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import Logo from '@/Shared/Logo'
import TextInput from '@/Shared/TextInput'
import LoadingButton from '@/Shared/LoadingButton'
import UserIcon from '@/svg/UserIcon'
import LockIcon from '@/svg/LockIcon'
export default {
  components: {
    Head,
    LoadingButton,
    Logo,
    TextInput,
    UserIcon,
    LockIcon
  },
  data() {
    return {
      form: this.$inertia.form({
        username: '',
        password: '',
        remember: false,
      }),
      window: {
        width: 0,
        height: 0
      },
      total_slides: 2,
      showPassword:false,
      sel_slide: 1,
      slider_interval: '',
      mobilestat:false
    }
  },
  props:{
    error: Object
  },
  created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
  },
  destroyed() {
      window.removeEventListener('resize', this.handleResize);
  },
  mounted(){
    this.bgSliderOn()
  },
  computed: {
    buttonLabel() {
      return (this.showPassword) ? "Hide" : "Show";
    }
  },
  methods: {
    bgSliderOn(){
      this.slider_interval = setInterval(() => {
        if(this.sel_slide==this.total_slides) this.sel_slide = 1
        else this.sel_slide++
      }, 1000 * 10)
    }, 
    login() {
      this.form.post('/backend/login')
    },
    handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
            if(this.window.width<750){
              this.mobilestat= false;
            }else{
              this.mobilestat= true;
            }
            
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    }
  },
  beforeDestory(){
    clearInterval(this.slider_interval)
  }
}
</script>
<style lang="scss" scoped>
    .bg-slider{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        .shade-overlay{
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          // background-image: linear-gradient(to left, #009a66aa, #ffd800aa);
          background-image: linear-gradient(to top, #00000080, transparent);
          z-index: 5;
        }
        .sliders{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            & > div{                
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;
                transition: all 5s;
                opacity: 0;

                &.active{
                  opacity: 1;
                }
                &.inactive{
                  opacity: 0;
                }
            }
        }
    }
    .login-block{
        position: relative;
        z-index: 10;

        .content-block{
          background-color: #ffffff80;
          max-width: 720px;

          .content-items-block > *{
            width: 100%;

            &.login_info_bg{
              background-color: #eee;

              & > img{
                width: 100%; height: 100%; object-fit: contain;
              }
            }

            input{
              border: 1px solid #ddd
            }

            .err{
              color: #CD0000;

              small{
                font-size: 12px;
              }
            }

            .login-btn{
              color: #fff;
              background-color: #0096e1;
              transition: all 0.4s;

              &:hover{
                background-color: #013582;
              }
            }
          }
        }
    }
    span.password_field {
        position: relative;
        width: 100%;
    }
    span.vieweye {
        position: absolute;
        margin-top: -22px;
        margin-left: 87%;
    }
</style>