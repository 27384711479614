<template>
    <div class="field_property_block">
        <!-- {{ elm_data }} -->
        <div class="flex flex-col">
            <div class="property_block">
                <label class="flex flex-row items-center border gap-2 px-2" @click="block_collapse('field_property')">                    
                    <i v-if="field_property_collapsed" class="fa fa-chevron-right"></i>
                    <i v-else class="fa fa-chevron-down"></i>
                    <span>Field Properties</span>
                </label>
            </div>
        </div>
        <!-- {{  data }} -->        
        <div :class="['grid','grid-cols-1','max-h-[300px]','overflow-y-auto','field_properties',{hidden:field_property_collapsed}]">
            <TxtInputProperties v-if="input_type=='txt_input'" :data="data" :certificate_data="certificate_data" :qr_data="qr_data" :field_validation_types="field_validation_types" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data"
            @modifyQrData="modify_qr_data" @removeQrData="remove_qr_data" />
            
            <NumberInputProperties v-else-if="input_type=='number_input'" :data="data" :certificate_data="certificate_data" :qr_data="qr_data" :field_validation_types="field_validation_types" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data"
            @modifyQrData="modify_qr_data" @removeQrData="remove_qr_data" />
            
            <TextareaInputProperties v-else-if="input_type=='textarea'" :data="data" :certificate_data="certificate_data" :qr_data="qr_data" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data"
            @modifyQrData="modify_qr_data" @removeQrData="remove_qr_data" />
            
            <SelectboxInputProperties v-else-if="input_type=='selectbox'" :data="data" :certificate_data="certificate_data" :qr_data="qr_data" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data"
            @modifyQrData="modify_qr_data" @removeQrData="remove_qr_data" />
            
            <CheckboxInputProperties v-else-if="input_type=='checkbox'" :data="data" :certificate_data="certificate_data" :qr_data="qr_data" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data"
            @modifyQrData="modify_qr_data" @removeQrData="remove_qr_data" />
            
            <RadioInputProperties v-else-if="input_type=='radio'" :data="data" :certificate_data="certificate_data" :qr_data="qr_data" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data"
            @modifyQrData="modify_qr_data" @removeQrData="remove_qr_data" />
            
            <DatePickerProperties v-else-if="input_type=='datepicker'" :data="data" :certificate_data="certificate_data" :qr_data="qr_data" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data"
            @modifyQrData="modify_qr_data" @removeQrData="remove_qr_data" />

            <DateRangeProperties v-else-if="input_type=='daterange'" :data="data" :certificate_data="certificate_data" :qr_data="qr_data" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data"
            @modifyQrData="modify_qr_data" @removeQrData="remove_qr_data" />

            <TimePickerProperties v-else-if="input_type=='timepicker'" :data="data" :certificate_data="certificate_data" :qr_data="qr_data" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data"
            @modifyQrData="modify_qr_data" @removeQrData="remove_qr_data" />

            <SignatureProperties v-else-if="input_type=='signature'" :data="data" :certificate_data="certificate_data" :qr_data="qr_data" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data"
            @modifyQrData="modify_qr_data" @removeQrData="remove_qr_data" />

            <GenderInputProperties v-else-if="input_type=='gender'" :data="data" :certificate_data="certificate_data" :qr_data="qr_data" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data"
            @modifyQrData="modify_qr_data" @removeQrData="remove_qr_data" />

            <ReligionInputProperties v-else-if="input_type=='religion'" :data="data" :certificate_data="certificate_data" :qr_data="qr_data" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data"
            @modifyQrData="modify_qr_data" @removeQrData="remove_qr_data" />

            <GeoInputProperties v-else-if="input_type=='geo'" :data="data" :certificate_data="certificate_data" :qr_data="qr_data" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data"
            @modifyQrData="modify_qr_data" @removeQrData="remove_qr_data" />

            <NationalityInputProperties v-else-if="input_type=='nationality'" :data="data" :certificate_data="certificate_data" :qr_data="qr_data" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data"
            @modifyQrData="modify_qr_data" @removeQrData="remove_qr_data" />

            <CountryInputProperties v-else-if="input_type=='country'" :data="data" :certificate_data="certificate_data" :qr_data="qr_data" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data"
            @modifyQrData="modify_qr_data" @removeQrData="remove_qr_data" />
            
            <TextProperties v-else-if="input_type=='text'"
                :data="data"
                @addProperties="add_properties"
                @modifyCertificateData="modify_certificate_data"
                @removeCertificateData="remove_certificate_data"
            />

            <TableProperties v-else-if="input_type=='table'"
                :data="data"
                :certificate_data="certificate_data"
                :sel_table_row_number="sel_table_row_number"
                :sel_table_column_number="sel_table_column_number"
                :sel_table_content_index="sel_table_content_index"
                @addProperties="add_properties"
                @modifyCertificateData="modify_certificate_data"
                @removeCertificateData="remove_certificate_data"
            />

            <ColumnProperties v-else-if="elm_col_index>=0" :data="elm_data" @addProperties="add_properties" />
            
            <ElementProperties v-else-if="elm_index>=0" :data="elm_data" :service_designation_list="service_designation_list" @addProperties="add_properties" />
        </div>

        <div class="flex flex-col">
            <div class="property_block">
                <label class="flex flex-row items-center border gap-2 px-2" @click="block_collapse('assign_auth_field')">                    
                    <i v-if="assign_auth_field_collapsed" class="fa fa-chevron-right"></i>
                    <i v-else class="fa fa-chevron-down"></i>
                    <span>Assign Auth Field</span>
                </label>
            </div>
        </div>
        <div :class="['grid','grid-cols-1','field_properties','max-h-[300px]','overflow-y-auto',{hidden:assign_auth_field_collapsed}]">
            <template v-if="input_type">
                <div v-for="(field,value) in auth_field_list" class="flex items-center gap-2 border px-4 h-10" @click="assign_auth_field(sel_auth_field==value?'':value)">
                    <i v-if="sel_auth_field==value" class="far fa-circle-check text-[14px] text-green-800 cursor-pointer" />
                    <i v-else class="far fa-circle cursor-pointer" />
                    <span :class="{'text-green-800':sel_auth_field==value}">{{ field }}</span>
                </div>
            </template>
        </div>
        
        <div class="flex flex-col">
            <div class="property_block">
                <label class="flex flex-row items-center border gap-2 px-2" @click="block_collapse('certificate_field')">                    
                    <i v-if="certificate_field_collapsed" class="fa fa-chevron-right"></i>
                    <i v-else class="fa fa-chevron-down"></i>
                    <span>Certificate Fields</span>
                </label>
            </div>
        </div>
        <div :class="['grid','grid-cols-1','field_properties','h-[300px]','overflow-y-auto',{hidden:certificate_field_collapsed}]">            
            <div v-for="(field,index) in certificate_data" class="flex items-center gap-2 border px-4 h-10">
                <i class="fa fa-check-circle"></i>
                <span :class="text-green-800">{{ field }}</span>
                <i v-if="!field" class="fa fa-trash-alt" @click="remove_certificate_data(index)"></i>
            </div>
        </div>

        <div class="flex flex-col">
            <div class="property_block">
                <label class="flex flex-row items-center border gap-2 px-2" @click="block_collapse('qr_data_field')">                    
                    <i v-if="qr_data_field_collapsed" class="fa fa-chevron-right"></i>
                    <i v-else class="fa fa-chevron-down"></i>
                    <span>QR Data Fields</span>
                </label>
            </div>
        </div>
        <div :class="['grid','grid-cols-1','field_properties','h-[300px]','overflow-y-auto',{hidden:qr_data_field_collapsed}]">            
            <div v-for="(field,index) in qr_data" class="flex items-center gap-2 border px-4 h-10">
                <i class="fa fa-check-circle"></i>
                <span :class="text-green-800">{{ field }}</span>
                <i v-if="!field" class="fa fa-trash-alt" @click="remove_qr_data(index)"></i>
            </div>
        </div>

        <div class="flex flex-col">
            <div class="property_block">
                <label class="flex flex-row items-center border gap-2 px-2" @click="block_collapse('assign_layer_to_wf')">                    
                    <i v-if="assign_layer_to_wf_collapsed" class="fa fa-chevron-right"></i>
                    <i v-else class="fa fa-chevron-down"></i>
                    <span>Assign Layer to WF</span>
                </label>
            </div>
        </div>
        <div :class="['grid grid-cols-1 field_properties max-h-[300px] overflow-y-auto border border-collapse p-2',{hidden:assign_layer_to_wf_collapsed}]">            
            <template v-if="assign_layer_list.length>0">
                <template v-for="(assign_layer,assign_index) in assign_layer_list">
                    <div class="relative grid grid-cols-1 mb-2">
                        <div class="flex items-center h-6 bg-ash py-1 px-2 gap-2">
                            <i v-if="assign_layer.show" class="fa fa-minus cursor-pointer" @click="assign_layer.show=false"></i>
                            <i v-else class="fa fa-plus cursor-pointer" @click="assign_layer.show=true"></i>
                            <span>WF Layer - {{ (assign_index + 1) }}</span>
                            <i class="fa-regular fa-circle-xmark ml-auto cursor-pointer text-red-600" @click="remove_assign_layer(assign_index)"></i>
                        </div>
                        <template v-if="assign_layer.show">
                            <table class="table border border-collapse border-gray-300 bg-gray-50 text-[10px]">
                                <tr>
                                    <th class="bg-gray-100 border border-gray-300 p-1">Col</th>
                                    <th class="bg-gray-100 border border-gray-300 p-1">Opt</th>
                                    <th class="bg-gray-100 border border-gray-300 p-1">Val</th>
                                    <th class="bg-gray-100 border border-gray-300 p-1 text-center">#</th>
                                </tr>
                                <template v-if="assign_layer.col_dependencies.length>0">
                                    <tr v-for="(layer_dependency,index) in assign_layer.col_dependencies">
                                        <td class="border border-gray-300 p-1">
                                            <div><input class="px-1 border rounded-md w-14 h-6" type="text" v-model="layer_dependency.col_name" placeholder="i.e. Sex" /></div>
                                        </td>
                                        <td class="border border-gray-300 p-1">
                                            <div><select class="px-1 border rounded-md w-11 h-6" type="text" v-model="layer_dependency.opt">
                                                <option v-for="(opt_val,opt_index) in logical_operation_list" :value="opt_index">{{ opt_val }}</option>
                                            </select></div>
                                        </td>
                                        <td class="border border-gray-300 p-1">
                                            <div><input class="px-1 border rounded-md w-14 h-6" type="text" v-model="layer_dependency.value" placeholder="i.e. Male" /></div>
                                        </td>
                                        <td class="text-center">
                                            <i class="fa-solid fa-trash cursor-pointer text-red-600 z-10" @click="remove_layer_dependency(index,assign_index)"></i>
                                        </td>
                                    </tr>                                
                                </template>
                                <tr v-else>
                                    <td colspan="4" class="border border-gray-300 p-2 text-center"><em>Empty</em></td>
                                </tr>
                                <tr>
                                    <td colspan="4" class="border border-gray-300 p-2 text-center">
                                        <div class="flex flex-row items-center justify-center gap-2 border border-collapse bg-gray-100 cursor-pointer rounded-md h-6" @click="add_new_layer_to_wf(assign_index)">
                                            <i class="fa fa-plus"></i>
                                            <span>Add New</span>
                                        </div>
                                    </td>
                                </tr>                            
                                <tr>
                                    <td colspan="4">
                                        <div class="p-2">
                                            <label>Choose Designation Type</label>
                                            <div class="mt-2">
                                                <select v-model="assign_layer_list[assign_index].designation_type_id" class="w-full h-6 px-1 rounded-md border border-gray-400">
                                                    <option value=null>All</option>
                                                    <option v-for="designation_type in designation_types" :value="designation_type.id">{{ designation_type.title }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <div class="p-2">
                                            <label>Choose Office Layer</label>
                                            <div class="mt-2">
                                                <select v-model="assign_layer_list[assign_index].office_layer_id" class="w-full h-6 px-1 rounded-md border border-gray-400" @change="get_office_list(assign_index)">
                                                    <option value="">Select One</option>
                                                    <option v-for="layer_item in office_layer_list" :value="layer_item.id">{{ layer_item.office_layer_name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div v-if="office_list[assign_index]" class="p-2">
                                            <label>Choose Office</label>
                                            <div class="mt-2">
                                                <select v-model="assign_layer_list[assign_index].office_id" class="w-full h-6 px-1 rounded-md border border-gray-400">
                                                    <option value="">Select One</option>
                                                    <option v-for="item in office_list[assign_index]" :value="item.office_id">{{ item.office_name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </template>
                    </div>
                </template>
            </template>
            <div class="flex flex-row items-center justify-center gap-2 border border-collapse bg-gray-100 cursor-pointer rounded-md h-6 mt-4" @click="assign_new_layer_to_wf">
                <i class="fa fa-plus"></i>
                <span>Assign New Layer</span>
            </div>
        </div>
        <div class="flex flex-col">
            <div class="property_block">
                <label class="flex flex-row items-center border gap-2 px-2" @click="block_collapse('workflow_property')">                    
                    <i v-if="workflow_property_collapsed" class="fa fa-chevron-right"></i>
                    <i v-else class="fa fa-chevron-down"></i>
                    <span>Workflow Properties</span>
                </label>
            </div>
        </div>
        <div :class="['grid','grid-cols-1','max-h-[300px]','overflow-y-auto','field_properties',{hidden:workflow_property_collapsed}]">
            <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                <label>Form Available</label>
                <div class="form-check form-switch">
                    <!-- {{ property_form_data.step }} -->
                    <input class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="get_wf_form_data.status" @change="modify_wf_form_data" />
                </div>
            </div>
        </div>
        <!-- <pre>{{ certificate_data }}</pre> -->
        <!-- <pre>{{ qr_data }}</pre> -->
        <!-- <pre>{{ assign_layer_list }}</pre> -->
        <!-- <pre>{{ service_designation_list }}</pre> -->
    </div>
</template>
<script setup>
    import TxtInputProperties from "./FieldProperties/TxtInput"
    import NumberInputProperties from "./FieldProperties/NumberInput"
    import TextareaInputProperties from "./FieldProperties/TextareaInput"
    import SelectboxInputProperties from "./FieldProperties/SelectBoxInput"
    import CheckboxInputProperties from "./FieldProperties/CheckboxInput"
    import RadioInputProperties from "./FieldProperties/RadioInput"
    import DatePickerProperties from "./FieldProperties/DatePickerInput"
    import DateRangeProperties from "./FieldProperties/DateRangeInput"
    import TimePickerProperties from "./FieldProperties/TimePickerInput"
    import SignatureProperties from './FieldProperties/SignatureInput'
    import GenderInputProperties from "./FieldProperties/GenderInput"
    import ReligionInputProperties from "./FieldProperties/ReligionInput"
    import GeoInputProperties from "./FieldProperties/GeoInput"
    import NationalityInputProperties from "./FieldProperties/NationalityInput"
    import TextProperties from "./FieldProperties/Text"
    import TableProperties from "./FieldProperties/Table"
    import ColumnProperties from "./FieldProperties/Column"
    import ElementProperties from "./FieldProperties/Element"
    import { onMounted, ref, watch } from 'vue'
    import axios from "axios"
    const props = defineProps({
        elm_data: Object,
        data: Object,
        service_id: Number,
        certificate_data: Object,
        qr_data: Object,
        assignLayerList: Array,
        office_layer_list: Object,
        wf_form_data: Object,
        service_designation_list: Array,
        input_type: String,
        elm_index: Number,
        elm_col_index: Number,
        sel_table_row_number: Number,
        sel_table_column_number: Number,
        sel_table_content_index: Number
    })
    const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
    const emit = defineEmits(['addFieldProperties','assignAuthField','modifyCertificateData','modifyWfFormData','modifyQrData','removeCertificateData','removeQrData'])
    const field_property_collapsed = ref(false)
    const assign_auth_field_collapsed = ref(true)
    const certificate_field_collapsed = ref(true)
    const qr_data_field_collapsed = ref(true)
    const assign_layer_to_wf_collapsed = ref(true)
    const workflow_property_collapsed = ref(true)
    const sel_auth_field = ref('')    
    const get_wf_form_data = ref(props.wf_form_data && Object.keys(props.wf_form_data).length?props.wf_form_data:{
        status: false,
        form_name: ''
    })
    const auth_field_list = ref({
        'first_name': 'First Name',
        'middle_name': 'Middle Name',
        'last_name': 'Last Name',        
        'full_name': 'Full Name',
        'suffix': 'Suffix',
        'email': 'Email',
        'mobile': 'Mobile',
        'father_name': 'Father\'s Name',
        'mother_name': 'Mother\'s Name',
        'spouse_name': 'Spouse Name',
        'dob': 'Date of birth',
        'gender': 'Gender',
        'nationality': 'Nationality',
        'country': 'Country',
        'occupation': 'Occupation',
        'religion': 'Religion',
        'cur_address': 'Current Address',
        'per_address': 'Permanent Address',
        'signature': 'Signature',
        'wf_emp_signature': 'WF Employee Signature',
        'office_name': 'Office Name',
        'designation': 'Designation',
        'govt_issued_id': 'Government Issued ID',
        'vulnerability': 'Vulnerability'
    })
    const field_validation_types = ref({
        'email': 'Email',
        'mobile': 'Mobile'
    })
    watch(() => props.data, (updateData) => {
        // //console.log('update data', updateData.auth_field)
        if(updateData && updateData.auth_field) sel_auth_field.value = updateData.auth_field
        else sel_auth_field.value = ''
    })
    onMounted( async () => {
        if(props.assignLayerList.length>0){
            props.assignLayerList.forEach( async (v,i) => {
                if(v.office_layer_id) await get_office_list(i)
            })
        }
    })
    const add_properties = (data) => {
        emit('addFieldProperties', data)
    }
    const assign_auth_field = (value) => {
        sel_auth_field.value = value
        emit('assignAuthField', sel_auth_field.value)
    }
    const modify_wf_form_data = () => {        
        emit('modifyWfFormData', get_wf_form_data.value)
    }
    const logical_operation_list = ref({
        '=':'=',
        '>':'>',
        '>=':'>=',
        '<':'<',
        '<=':'<=',
        '!=':'!='
    })
    const designation_types = ref([
        {
            id: 1,
            title: 'Senior'
        },
        {
            id: 2,
            title: 'Others'
        }
    ])
    const assign_layer_list = ref(props.assignLayerList)
    const assign_new_layer_to_wf = () => {
        let obj = {
            col_dependencies: [],
            designation_type_id: null,
            office_layer_id: '',
            office_id: '',
            show: true
        }
        assign_layer_list.value.push(obj)
    }
    const add_new_layer_to_wf = (index) => {
        let obj = {
            col_name: '',
            opt: '=',
            value: ''
        }
        assign_layer_list.value[index].col_dependencies.push(obj)
    }
    const remove_assign_layer = (index) => {
        assign_layer_list.value.splice(index, 1)
    }
    const remove_layer_dependency = (index, layer_index) => {
        assign_layer_list.value[layer_index].col_dependencies.splice(index, 1)
    }
    const office_list = ref([])
    const get_office_list = (index) => {
        axios.post(`/${backendPrefix.value}/offices/get-all`,{
            office_layer_id: assign_layer_list.value[index].office_layer_id,
            service_id: props.service_id
        }).then( async (response) => {
            // //console.log(response)
            office_list.value[index] = response.data.data
        }).catch(error => {                    
            //console.log(error.response.data.error)
        })
    }
    const block_collapse = (block) => {
        if(block=='field_property') {
            assign_auth_field_collapsed.value = true
            certificate_field_collapsed.value = true
            qr_data_field_collapsed.value = true
            assign_layer_to_wf_collapsed.value = true
            workflow_property_collapsed.value = true
            field_property_collapsed.value = field_property_collapsed.value?false:true
        }else if(block=='assign_auth_field') {
            field_property_collapsed.value = true
            certificate_field_collapsed.value = true
            qr_data_field_collapsed.value = true
            assign_layer_to_wf_collapsed.value = true
            workflow_property_collapsed.value = true
            assign_auth_field_collapsed.value = assign_auth_field_collapsed.value?false:true
        }else if(block=='certificate_field') {
            field_property_collapsed.value = true
            assign_auth_field_collapsed.value = true
            assign_layer_to_wf_collapsed.value = true
            workflow_property_collapsed.value = true
            certificate_field_collapsed.value = certificate_field_collapsed.value?false:true
        }else if(block=='qr_data_field') {
            field_property_collapsed.value = true
            assign_auth_field_collapsed.value = true
            assign_layer_to_wf_collapsed.value = true
            workflow_property_collapsed.value = true
            qr_data_field_collapsed.value = qr_data_field_collapsed.value?false:true
        }else if(block=='assign_layer_to_wf') {
            field_property_collapsed.value = true
            assign_auth_field_collapsed.value = true    
            certificate_field_collapsed.value = true
            qr_data_field_collapsed.value = true
            workflow_property_collapsed.value = true
            assign_layer_to_wf_collapsed.value = assign_layer_to_wf_collapsed.value?false:true
        }else if(block=='workflow_property') {
            field_property_collapsed.value = true
            assign_auth_field_collapsed.value = true
            certificate_field_collapsed.value = true
            qr_data_field_collapsed.value = true
            assign_layer_to_wf_collapsed.value = true            
            workflow_property_collapsed.value = workflow_property_collapsed.value?false:true
        }
    }
    const modify_certificate_data = (index) => {
        emit('modifyCertificateData', index)
    }
    const remove_certificate_data = (index) => {        
        emit('removeCertificateData', index)
    }
    const modify_qr_data = (index) => {
        emit('modifyQrData', index)
    }
    const remove_qr_data = (index) => {        
        emit('removeQrData', index)
    }
</script>
<style lang="scss" scoped>
.field_property_block{
    position: sticky;
    top: 0;
    .property_block{
        label{
            background-color: #eee;
            height: 30px;
            cursor: pointer;
        }
        .input_items{
            & > div{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 15px; font-size: 10px;
                width: 100%; height: 100px;
                border: 1px solid #ddd;
                cursor: pointer;
                user-select: none;

                & > i{
                    position: absolute;
                    opacity: 0.0;
                    font-size: 20px;
                    color: #fff;
                    z-index: 5;
                    transition: all 0.4s;
                }

                &:hover{
                    background-color: #00000080;
                    & > i{
                        opacity: 1.0;
                    }
                }
            }
        }
    }

    input[type="radio"] {                
        width: 16px !important;
        height: 16px !important;
        padding: 2px 8px;
        width: 100%; border: 1px solid #ddd;
        border-radius: 5px;            
    }
    
    .w-11{
        width: 45px
    }
    .w-14{
        width: 55px
    }
    .top-1{
        top: 3px;
    }
    .-right-1{
        right: -3px
    }
    .bg-ash{
        background-color: #eee;
    }
}
</style>