<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
        <trashed-message v-if="serviceinfo.deleted_at" class="mb-6" @restore="restore"> This Service Info has been
            deleted. </trashed-message>
        <div class="w-full bg-white rounded-md shadow overflow-hidden">
            <form @submit.prevent="update">
                <div class="flex flex-wrap -mb-8 -mr-6 p-8">
                    <select-input v-model="form.service_id" :error="form.errors.service_id"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Service " required="true">
                        <option value=null>Select Service</option>
                        <option v-for="service in services" :key="service.id" :value='service.id'>{{
            service.service_name }}</option>
                    </select-input>
                    <status-toogle-button v-model="form.has_subservice" :error="form.errors.status"
                        label="Has Sub-service &nbsp;&nbsp;&nbsp;&nbsp;"
                        class="pb-8 mt-6 pr-6 w-full lg:w-1/2"></status-toogle-button>
                    <template v-if="form.has_subservice == 1">
                        <text-input v-model="form.service_name" :error="form.errors.service_name"
                            class="pb-8 pr-6 w-full lg:w-1/2" label="Sub-Service Name" required="true"
                            placeholder="i.e New" />
                    </template>

                    <!-- <select-input v-model="form.scat_id" :error="form.errors.scat_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Service Category"  required="true">
              <option value=null >Select Service Category</option>
              <option v-for="servicecategory in servicecategories" :key="servicecategory.id" :value='servicecategory.id'>{{ servicecategory.cat_title }}</option>
            </select-input>
            <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full lg:w-1/2" label="Service Slug"  required="true" placeholder="i.e business-permit"/>
            <file-input v-model="form.logo" :error="form.errors.logo" class="pb-8 pr-6 w-full lg:w-1/2" type="file" accept="image/*" label="Logo" /> -->
                    <text-input v-model="form.duration" :error="form.errors.duration" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Service Duration (In day)" type="number" oninput="this.value = Math.abs(this.value)"
                        min="0" max="365" />
                    <select-input v-model="form.pre_req_sid" :error="form.errors.pre_req_sid"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Pre-requisite Service">
                        <option value=null>Select Pre-requisite Service</option>
                        <option v-for="serviceinfo in serviceinfo_data" :key="serviceinfo.id" :value='serviceinfo.id'>{{
            serviceinfo.service_name }}</option>
                    </select-input>
                    <div class="pb-8 pr-6 w-full lg:w-1/2">
                        <label class="form-label">Tags</label>
                        <input-tag :tag_info="form.tag_info" :editvalue="form.tag_info"
                            @setUpdateTags="set_update_tags" />
                    </div>
                    <div class="pb-8 pr-6 w-full lg:w-1/2 flex">
                        <text-input v-model="form.apply_within" :error="form.errors.apply_within"
                            class="pr-6 pb-8  w-full lg:w-1/2" label="Within (In Day)" type="number"
                            oninput="this.value = Math.abs(this.value)" min="0" max="365" />
                        <text-input v-model="form.apply_after" :error="form.errors.apply_after"
                            class="pr-6 pb-8  w-full lg:w-1/2" label="After (In Day)" type="number"
                            oninput="this.value = Math.abs(this.value)" min="0" max="365" />
                    </div>
                    <text-input v-model="form.number_of_download" :error="form.errors.number_of_download"
                        class="pr-6 pb-8  w-full lg:w-1/2" label="Max certificate download" type="number"
                        oninput="this.value = Math.abs(this.value)" min="0" max="365" />
                    <textarea-input v-model="form.apply_within_comments" :error="form.errors.apply_within_comments"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Service Apply Within Comments" type="text" />



                    <div class="pb-8 pr-6 w-full lg:w-1/2">
                        <label class="form-label">Recipient</label>
                        <div class="flex ">
                            <div class="form-check form-check-inline">
                                <input
                                    class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                    type="radio" v-model="form.recipient_id" id="recipient_id1" value="1">
                                <label class="form-check-label inline-block text-gray-800"
                                    for="recipient_id1">Citizen</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    class="form-check-input  appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                    type="radio" v-model="form.recipient_id" id="recipient_id2" value="2">
                                <label class="form-check-label inline-block text-gray-800"
                                    for="recipient_id2">Government</label>
                            </div>
                        </div>
                    </div>

                    <div class="pb-8 pr-6 w-full lg:w-1/2" v-if="form.recipient_id == 2">
                        <label class="form-label">Designation Type</label>
                        <div class="flex ">
                            <div class="form-check form-check-inline">
                                <input
                                    class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                    type="radio" v-model="form.designation_type" id="designation_id1" value="1">
                                <label class="form-check-label inline-block text-gray-800"
                                    for="designation_id1">All</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    class="form-check-input  appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                    type="radio" v-model="form.designation_type" id="designation_id2" value="2">
                                <label class="form-check-label inline-block text-gray-800"
                                    for="designation_id2">Specific</label>
                            </div>
                        </div>
                    </div>

                    <fieldset v-if="form.designation_type == 2 && form.recipient_id == 2"
                        class="border border-solid border-gray-300 mt-6 p-3 w-full lg:w-1/1">
                        <legend class="text-sm">Designations</legend>
                        <div class="flex justify-center">
                            <div class="text-justify">
                                <div class="form-check form-check-inline" v-for="designation in designations"
                                    :key="designations.id">
                                    <input
                                        class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                        type="checkbox" :id="'designation-' + designation.id" v-model="form.designations"
                                        :value='designation.id'>
                                    <label class="form-check-label inline-block text-gray-800"
                                        :for="'designation-' + designation.id">
                                        {{ designation.designation_name }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <template v-if="form.recipient_id == 2">
                        <status-toogle-button v-model="form.active_employee_check"
                            :error="form.errors.active_employee_check"
                            label="Check Elected User &nbsp;&nbsp;&nbsp;&nbsp;"
                            class="pb-8 mt-6 pr-6 w-full lg:w-1/2"></status-toogle-button>

                        <status-toogle-button v-model="form.employee_data" :error="form.errors.employee_data"
                            label="Take Employee Data &nbsp;&nbsp;&nbsp;&nbsp;"
                            class="pb-8 mt-6 pr-6 w-full lg:w-1/2"></status-toogle-button>
                    </template>
                    <more-content :contents="form.contents" />
                    <status-toogle-button v-model="form.has_attachment" :error="form.errors.has_attachment"
                        label="Has Attachment" class="pb-8 mt-5 pr-6 w-full lg:w-1/1"></status-toogle-button>
                    <required-attachment :attachments="form.attachments" v-if="showattachment == true" />
                    <status-toogle-button v-model="form.has_payment" :error="form.errors.has_payment"
                        label="Has Payment &nbsp;&nbsp;&nbsp;&nbsp;"
                        class="pb-8 pr-6 mt-6 w-full lg:w-1/1"></status-toogle-button>

                    <payment-config :payment_gateways="payment_gateways" :payments="form.payments"
                        v-if="showpayment == true" />

                    <status-toogle-button v-model="form.has_project" :error="form.errors.has_project"
                        label="Has Project &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                        class="pb-8 pr-6 mt-6 w-full lg:w-1/1"></status-toogle-button>
                    <add-project :projects="form.projects" v-if="showproject == true" />
                    <fieldset class="border border-solid border-gray-300 mt-6 p-3 w-full lg:w-1/1">
                        <legend class="text-sm">Decision Config</legend>
                        <div class="text-justify">
                            <div class="form-check form-check-inline" v-for="decision_info in decision_infos"
                                :key="decision_info.id">

                                <input
                                    class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                    type="checkbox" :id="'decisioninfo-' + decision_info.id" v-model="form.decisions"
                                    :value='decision_info.id'>
                                <label class="form-check-label inline-block text-gray-800"
                                    :for="'decisioninfo-' + decision_info.id">
                                    {{ decision_info.d_title }}
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <FinalApproval :approvals="form.approvals" />
                    <DecisionReason :decisionReasons="form.decisionReasons" :decision_infos="decision_infos" />


                    <status-toogle-button v-model="form.status" :error="form.errors.status"
                        label="Service Status &nbsp;&nbsp;&nbsp;&nbsp;"
                        class="pb-8 mt-6 pr-6 w-full lg:w-1/2"></status-toogle-button>
                </div>
                <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
                    <button v-if="!serviceinfo.deleted_at && permission.edit > 0" class="text-red-600 hover:underline"
                        tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
                    <Button v-if="permission.edit > 0" :type="type" :name="head" :url="createUrl"
                        :loading="form.processing"></Button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, onMounted, watchEffect, inject } from 'vue'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import TextareaInput from '@/Shared/TextareaInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import ContentHeaderSection from "@/Shared/ContentHeaderSection"
import InputTag from '@/Shared/InputTag'
import RequiredAttachment from './components/RequiredAttachment'
import PaymentConfig from './components/PaymentConfig'
import AddProject from './components/AddProject'
import MoreContent from './components/MoreContent'
import Button from '@/Shared/Button'
import FileInput from '@/Shared/FileInput'
import FinalApproval from './components/FinalApproval'
import DecisionReason from './components/DecisionReason'
export default {
    components: {
        Head,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        Breadcrumbs,
        StatusToogleButton,
        Button,
        ContentHeaderSection,
        InputTag,
        RequiredAttachment,
        PaymentConfig,
        AddProject,
        MoreContent,
        Button,
        FileInput,
        FinalApproval,
        TextareaInput,
        DecisionReason
    },
    layout: Layout,
    props: {
        servicecategories: Array,
        serviceinfo_data: Array,
        payment_gateways: Array,
        decision_infos: Array,
        serviceinfo: Object,
        info: Array,
        decisionsdata: Array,
        tagdata: Array,
        allattachments: Array,
        services: Array,
        allpayments: Array,
        permission: Object,
        designations: Array,
        contents: Array,
    },
    // remember: 'form',
    setup(props) {
        const { serviceinfo, servicecategories, serviceinfo_data, payment_gateways, decision_infos, info, decisionsdata, tagdata, allattachments, allpayments, permission, services, designations, contents } = toRefs(props)
        const type = ref('edit')
        const title = ref(serviceinfo.value.service_name)
        const head = ref('Service')
        const url = ref('/backend/serviceinfos')
        const createUrl = ref('')
        const elm = ref(this)
        const swal = inject('$swal')
        const form = useForm({
            id: serviceinfo.value.id,
            service_name: serviceinfo.value.service_name,
            has_subservice: serviceinfo.value.service_name == 'Default' ? 0 : 1,
            recipient_id: serviceinfo.value.recipient_id,
            status: serviceinfo.value.status,
            scat_id: serviceinfo.value.scat_id,
            service_id: serviceinfo.value.service_id,
            duration: serviceinfo.value.duration,
            pre_req_sid: serviceinfo.value.pre_req_sid,
            app_type: serviceinfo.value.app_type,
            has_attachment: serviceinfo.value.has_attachment,
            has_payment: serviceinfo.value.has_payment,
            has_project: serviceinfo.value.has_project,
            status: serviceinfo.value.status,
            apply_within: serviceinfo.value.apply_within,
            apply_after: serviceinfo.value.apply_after,
            apply_within_comments: serviceinfo.value.apply_within_comments,
            logo: null,
            slug: serviceinfo.value.slug,
            tag_info: tagdata.value,
            designation_type: serviceinfo.value.designation_type,
            designations: serviceinfo.value.designations ? serviceinfo.value.designations : 1,
            attachments: allattachments.value.length > 0 ? allattachments.value : [{
                title: '',
                max_file_size: null,
                file_type: [],
                marital_status: [],
                mandatory: true,
                for_certificate: false,
            }],
            payments: allpayments.value.length ? allpayments.value : {
                payment_method: [],
                payment_type: 1,
                gateway_id: null,
                instruction: null,
                fixed_amount: null,
                pre_amount: 0,
                amount: 0,
            },
            projects: info.value.projects.length ? info.value.projects : [{
                title: null,
                project_location: null,
                project_year: null,
                project_amount: null,
                online: true
            }],
            contents: contents.value.data.length ? contents.value.data : [{
                title: null,
                type: null,
                description: null
            }],
            approvals: serviceinfo.value.approvals ? serviceinfo.value.approvals : [{
                field_name: null,
                display_name: null,
                type: null,
                min: null,
                max: null,
                minlength: null,
                maxlength: null,
                max_size: null,
                accept: '.jpg, .jpeg, .png'
            }],

            decisionReasons: info.value.decision_reasons.length ? info.value.decision_reasons : [{
                decision_type: null,
                decision_text: null
            }],
            decisions: decisionsdata.value,
            active_employee_check: serviceinfo.value.active_employee_check ? serviceinfo.value.active_employee_check : 0,
            number_of_download: serviceinfo.value.number_of_download ? serviceinfo.value.number_of_download : 0,
            employee_data: serviceinfo.value.employee_data ? serviceinfo.value.employee_data : 0,
        })
        const showattachment = ref(false)
        const showpayment = ref(false)
        const showproject = ref(false)
        const update = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.post(`${url.value}/${serviceinfo.value.id}`, { forceFormData: true, })
                    title.value = form.service_name
                    Swal.fire(
                        'Updated!',
                        'Your infomation has been Updated.',
                        'success'
                    )
                }
            });
        }
        const destroy = () => {
            swal({
                title: 'Are you sure?',
                text: "Do you really want to delete this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    form.delete(`${url.value}/${serviceinfo.value.id}`)
                    Swal.fire(
                        'Deleted!',
                        'Your infomation has been deleted.',
                        'success'
                    )
                }
            });
        }
        const restore = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to restore this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Resore it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    form.put(`${url.value}/${serviceinfo.value.id}/restore`)
                    swal.fire(
                        'Restored!',
                        'Your infomation has been Restored.',
                        'success'
                    )
                }
            });
        }
        const statusCheck = () => {
            if (serviceinfo.value.status == 1) {
                form.status = true;
                return true;
            } else {
                form.status = false;
                return false;
            }
        }
        const hasAttachment = () => {
            if (serviceinfo.value.has_attachment == 1) {
                form.has_attachment = true;
                return true;
            } else {
                form.has_attachment = false;
                return false;
            }
        }
        const hasProject = () => {
            if (serviceinfo.value.has_project == 1) {
                form.has_project = true;
                return true;
            } else {
                form.has_project = false;
                return false;
            }
        }
        const hasPayment = () => {
            if (serviceinfo.value.has_payment == 1) {
                form.has_payment = true;
                return true;
            } else {
                form.has_payment = false;
                return false;
            }
        }
        const set_update_tags = (obj) => {
            ////console.log('Parent Info',obj)
            form.tag_info = obj
        }
        watchEffect(() => {
            if (form.has_attachment == 1) {
                showattachment.value = true
            } else {
                showattachment.value = false
            }
            if (form.has_payment == 1) {
                showpayment.value = true
            } else {
                showpayment.value = false
            }
            if (form.has_project == 1) {
                showproject.value = true
            } else {
                showproject.value = false
            }
        });
        return {
            form,
            serviceinfo,
            type,
            title,
            head,
            url,
            createUrl,
            services,
            update,
            destroy,
            restore,
            statusCheck,
            servicecategories,
            serviceinfo_data,
            payment_gateways,
            decision_infos,
            info,
            hasAttachment,
            hasProject,
            hasPayment,
            showattachment,
            showpayment,
            showproject,
            decisionsdata,
            tagdata,
            set_update_tags,
            allattachments,
            allpayments,
            swal,
            permission,
            contents
        }
    },
    created: function () {
        this.statusCheck();
        this.hasAttachment();
        this.hasPayment();
        this.hasProject();
    }
}
</script>
