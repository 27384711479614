<template>
    <div class="organogram_map_block">
        <div class="layer_info_block">
            <label>Choose Office Layer</label>
            <div class="mt-2">
                <select v-model="sel_layer_id" @change="load_data">
                    <option value="">Choose One</option>
                    <option v-for="layer in layermap" :value="layer.id">{{ layer.office_layer_name }}</option>
                </select>
            </div>
        </div>        
        <template v-if="sel_layer_id">
            <ContentHeaderSection :title="'Office Organogram Setup'" :icon="['fa','fa-cogs',{'fa-spin':preloader}]" />
            <div class="p-5 organogram_setup_block">
                <draggable :list="data" group="section" handle=".section_drag_cross">                    
                    <div v-for="(content,s_index) in data">
                        <!-- {{ content }} -->    
                        <span v-if="content.section!=null">
                            <OfficeSection :index="s_index" :content="content.section.name" @addSection="add_section" @updateSection="update_section" @delSection="del_section" />
                            <div class="designation_tree ml-5">
                                <draggable :list="content.designations" :group="'designation-' + s_index" handle=".designation_drag_cross">
                                    <div v-for="(designation,d_index) in content.designations">
                                        <OfficeDesignation :index="s_index" :_index="d_index" :section_id="content.section.id" :content="designation.name" @addDesignation="add_designation" @updateDesignation="update_designation" @delDesignation="del_designation" />
                                    </div>
                                </draggable>
                                <OfficeDesignation :index="s_index" :section_id="content.section.id" :content="''" @addDesignation="add_designation" @updateDesignation="update_designation" />
                            </div>
                        </span>
                    </div>                    
                </draggable>
                <OfficeSection :content="''" @addSection="add_section" @updateSection="update_section" />
            </div>
            <FloatingActionBtn v-if="(data.length > 0)" :loader="preloader" :icon="'fa fa-check'" :message="message" @dataSubmit="submit_data" @removeMessage="remove_msg" />
        </template>
    </div>
</template>
<script>
    import { ref, toRefs, onMounted } from "vue"
    import axios from "axios"    
    import { VueDraggableNext } from 'vue-draggable-next'
    import OfficeSection from './OfficeSection'    
    import OfficeDesignation from './OfficeDesignation'
    import ContentHeaderSection from "@/Shared/ContentHeaderSection"
    import FloatingActionBtn from "@/Shared/Buttons/FloatingActionBtn"
    export default {
        name: "OfficeOrganogram",
        props: {
            layermap: Array,            
            office_id: Number
        },
        components: {       
            draggable: VueDraggableNext,     
            OfficeSection,            
            OfficeDesignation,
            ContentHeaderSection,
            FloatingActionBtn
        },
        setup(props){
            const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
            const { layermap, office_id } = toRefs(props)
            const enabled = ref(true)
            const dragging = ref(false)
            const data = ref([])
            const preloader = ref(false)
            const message = ref('')
            const sel_layer_id = ref(layermap.value?layermap.value[0].id:'')

            onMounted( () => {
                load_data()
            })

            const load_data = () => {                
                if(sel_layer_id.value=='') return false

                let formData = {
                    office_id: office_id.value,
                    office_layer_id: sel_layer_id.value
                }
                preloader.value = true
                axios.post(`/${backendPrefix.value}/offices/layer-organogram`, formData).then( async (response) => {
                    //console.log(response)
                    data.value = response.data.organogram
                    preloader.value = false
                }).catch(error => {
                    preloader.value = false
                    //console.log(error.response.data.error)
                })
            }

            const add_section = (content) => {
                // //console.log('content', content)
                let obj = {
                    section: {
                        name: content.section_name,
                        id: content.id
                    },
                    designations: []
                }
                data.value.push(obj)
            }
            const update_section = (s_index,content) => {
                data.value[s_index].section = {
                    id: content.id,
                    name: content.section_name
                }                                        
            }
            const del_section = (s_index) => {
                // //console.log(s_index)
                data.value.splice(s_index, 1)
            }
            const add_designation = (s_index,content) => {
                let obj = {
                    id: content.id,
                    name: content.designation_name
                }
                data.value[s_index].designations.push(obj)
            }
            const del_designation = (s_index,d_index) => {
                data.value[s_index].designations.splice(d_index, 1)
            }
            const update_designation = (s_index,d_index,content) => {
                data.value[s_index].designations[d_index] = {
                    id: content.id,
                    name: content.designation_name
                }
            }

            const submit_data = async () => {
                // //console.log('Office organogram data', data)
                const data_ids = ref({})
                await data.value.map( async (v,i) => {
                    if(v.section){
                        data_ids.value[v.section.id] = {}
                        await v.designations.map(d => {
                            data_ids.value[v.section.id][d.id] = true
                        })
                    }else{
                        data.value.splice(i,1)
                    }
                   
                })

                let formData = {
                    office_id: office_id.value,
                    office_layer_id: sel_layer_id.value,
                    organogram: data.value,
                    data_ids: data_ids.value
                }

                preloader.value = true
                axios.post(`/${backendPrefix.value}/office-organogram-map`, {
                    data: formData
                }).then( async (response) => {
                    //console.log(response)
                    message.value = response.data.message
                    preloader.value = false
                }).catch(error => {
                    preloader.value = false
                    //console.log(error.response.data.error)
                })
            }

            const remove_msg = () => {                
                message.value = ''
            }

            return {
                layermap,
                data,
                preloader,
                message,
                office_id,
                sel_layer_id,
                add_section,
                update_section,
                del_section,
                add_designation,
                update_designation,
                del_designation,
                submit_data,
                remove_msg,
                load_data
            }
        }        
    }
</script>
<style lang="scss" scoped>
    .layer_info_block{
        display: block;
        padding: 15px;
        select {
            width: 100%;
            height: 30px;
            border: 1px solid #ddd;
            padding: 0 10px;
            border-radius: 5px;
        }
    }
    .organogram_map_block{
        display: block;
        position: relative;
        padding-bottom: 50px;
        .organogram_setup_block{
            display: block;
            min-height: 250px;
            .designation_tree{
                position: relative;
                margin-left: 9px;
                &::before{
                    position: absolute;
                    content: "";
                    width: 5px;
                    height: calc(100% - 8px);
                    border-left: 1px solid #999;
                    border-bottom: 1px solid #999;
                    border-radius: 0 0 0 5px;
                    left: 0;
                    top: -6px;
                }
                & > div{
                    position: relative;
                    padding-left: 18px;

                    &::before{
                        position: absolute;
                        content: "";
                        width: 18px;
                        height: 5px;                
                        border-bottom: 1px solid #999;
                        border-left: 1px solid #999;
                        border-radius: 0 0 0 5px;
                        left: 0px;
                        top: 11px;
                    }            
                }
            }
        }        
    }
</style>