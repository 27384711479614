<template>
    <div class="flex items-center gap-2 input_block section_drag_cross">
        <!-- content {{ content }} -->
        <template v-if="content && !edit_mode">
            <i class="fa fa-check rounded-full"></i>
            <span>{{ content }}</span>
            <span class="action_button">
                <i class="fa fa-pencil edit_btn" role="button" @click="edit_item"></i>
                <i class="fa fa-trash del_btn" role="button" @click="del_item"></i>
            </span>            
        </template>
        <template v-else>
            <i v-if="edit_mode" class="fa fa-edit rounded-full"></i>
            <i v-else class="fa fa-plus rounded-full"></i>
            <input type="text" v-model="data" placeholder="Enter/Choose section" @keypress.enter="save_content()" @keyup="check_data($event)" />
            <!-- <i v-if="content!=data" class="fa fa-check action_btn" role="button" @click="$parent.save_content()"></i> -->
            <small v-if="draft_on" class="draft_info"><i class="fa fa-pen"></i> draft</small>
            <i v-if="preloader" class="fa fa-rotate fa-spin"></i>
            <ul :class="['list',{show:srch_list_show}]" @blur="onListLostFocus()">
                <li v-for="item in srch_items" @click="sel_item(item)" @keypress.enter="sel_item(item)" tabindex="0">{{ item.section_name }}</li>
            </ul>
        </template>
    </div>
</template>
<script>
    import axios from "axios"
    import { ref, toRefs } from "vue"
    export default {
        name: 'OfficeSectionBlock',
        props: {
            index: Number,
            content: String
        },
        setup(props, { emit }) {
            const data = ref('')
            const srch_items = ref('')
            const srch_list_show = ref(false)            
            const preloader = ref(false)            
            const edit_mode = ref(false)
            const draft_on = ref(false)
            const idle = ref(false)
            const { index, content } = toRefs(props)

            const onListLostFocus = () => {
                srch_list_show.value = false
            }
            const edit_item = () => {
                edit_mode.value = true
                data.value = content.value
            }
            const del_item = () => {
                if(confirm('Are you sure to delete it?')){
                    emit('delSection', index.value)
                }
            }
            const sel_item = (item) => {
                add_content(item)
            }
            const check_data = async (e) => {
                //console.log(e)
                if(e.key=='Enter') return false
                else if(e.key=='ArrowDown') {
                    document.querySelector('[tabindex="0"]').focus();
                    return false
                }

                let formData = {
                    section_name: data.value,
                    limit: 5
                }

                // //console.log(formData)
                
                draft_on.value = true
                
                if(!idle.value){
                    idle.value = true                                        
                    axios.post('/backend/sections/getallsection', formData)
                    .then( async (response) => {
                        // //console.log(response)
                        srch_items.value = response.data
                        idle.value = false

                        if(response.data.length > 0) srch_list_show.value = true
                        else srch_list_show.value = false
                    })
                }
            }
            const save_content = () => {
                preloader.value = true
                axios.post('/backend/sections/savesection', {
                    section_name: data.value
                })
                .then( async (response) => {
                    // //console.log(response)                    
                    add_content(response.data)

                    preloader.value = false
                })
            }
            const add_content = (obj) => {
                if(edit_mode.value){
                    emit('updateSection', index.value, obj)
                    edit_mode.value = false
                }else{
                    emit('addSection', obj)
                    data.value = ''
                }
                draft_on.value = false
                srch_list_show.value = false
            }

            return {
                data,
                edit_mode,
                draft_on,
                srch_items,
                srch_list_show,
                preloader,
                edit_item,                
                del_item,
                save_content,
                add_content,
                check_data,
                sel_item,
                onListLostFocus
            }
        }
    }
</script>
<style lang="scss" scoped>
    $input_height: 30;
    .input_block{
        position: relative;
        height: #{$input_height}px;
        i{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 18px;
            height: 18px;
            font-size: 10px;
            &.rounded-full{
                border: 1px solid #999;                
            }
        }
        input{
            width: calc(100% - 40px);
        }
        .draft_info{
            display: flex;
            align-items: center;
            border-bottom: 1px dashed #ccc;
        }
        .list{
            position: absolute;
            display: none;
            top: #{$input_height}px;            
            left: 25px;
            border: 1px solid #ddd;
            background-color: #fff;
            box-shadow: 0 0 10px #ccc;
            width: 150px;
            border-radius: 5px;
            z-index: 5;

            &.show{
                display: block;
            }

            & > li{
                display: flex;
                padding: 5px 15px;                
                align-items: center;
                cursor: pointer;
                line-height: 16px;
                border-bottom: 1px solid #ddd;

                &:focus,&:hover{
                    font-weight: 600;
                    box-shadow: 0 0 5px #ccc;
                }
            }
        }
        .action_button{
            display: none;
            gap: 3px;
            align-items: center;
            .edit_btn,.del_btn{                
                cursor: pointer;
                color: #666
            }            
        }

        &:hover{
            .action_button{
                display: flex
            }            
        }
    }
</style>