<template>
    <svg viewBox="0 0 1024 1024" :height="height" :width="width" fill="currentColor" focusable="false" aria-hidden="true"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><path d="M944,0 C988.18278,0 1024,35.81722 1024,80 L1024,944 C1024,988.18278 988.18278,1024 944,1024 L80,1024 C35.81722,1024 0,988.18278 0,944 L0,80 C0,35.81722 35.81722,0 80,0 L944,0 Z M944,20 L80,20 C47.1942859,20 20.5378857,46.328343 20,79.0077903 L20,80 L20,944 C20,976.805714 46.328343,1003.46211 79.0077903,1004 L80,1004 L944,1004 C976.805714,1004 1003.46211,977.671657 1004,944.99221 L1004,944 L1004,80 C1004,47.1942859 977.671657,20.5378857 944.99221,20 L944,20 Z" fill="#999999" fill-rule="nonzero"></path><path d="M947.487373,862.63456 C951.392616,866.539803 951.392616,872.871452 947.487373,876.776695 L876.776695,947.487373 C872.871452,951.392616 866.539803,951.392616 862.63456,947.487373 C858.729317,943.58213 858.729317,937.250481 862.63456,933.345238 L933.345238,862.63456 C937.250481,858.729317 943.58213,858.729317 947.487373,862.63456 Z M912.132034,784.852814 C916.037277,788.758057 916.037277,795.089706 912.132034,798.994949 L798.994949,912.132034 C795.089706,916.037277 788.758057,916.037277 784.852814,912.132034 C780.947571,908.226791 780.947571,901.895142 784.852814,897.989899 L897.989899,784.852814 C901.895142,780.947571 908.226791,780.947571 912.132034,784.852814 Z M876.776695,707.071068 C880.681938,710.976311 880.681938,717.307961 876.776695,721.213203 L721.213203,876.776695 C717.307961,880.681938 710.976311,880.681938 707.071068,876.776695 C703.165825,872.871452 703.165825,866.539803 707.071068,862.63456 L862.63456,707.071068 C866.539803,703.165825 872.871452,703.165825 876.776695,707.071068 Z M150,90 C155.522847,90 160,94.4771525 160,100 L160,380 C160,385.522847 155.522847,390 150,390 C144.477153,390 140,385.522847 140,380 L140,100 C140,94.4771525 144.477153,90 150,90 Z" :fill="fill_color"></path></g></svg>
</template>
<script setup>
    import { ref } from "vue"
    const width = ref(150)
    const height = ref(40)
    const fill_color = ref('#1890ff')
</script>
<style lang="scss" scoped>
</style>