<template>
    <div class="table_display_block">        
        <div class="block overflow-x-auto">
            <table class="w-full border border-gray-200 border-collapse">
                <template v-for="(item,index) in data.html">
                    <tr>
                        <th v-if="!preview" class="px-2 h-7 w-10 bg-gray-200 border">
                            <div v-if="index==0">1</div>
                            <div v-else class="flex flex-col gap-1">
                                <span>{{ (index + 1) }}</span>
                                <i class="fa fa-trash-alt text-[10px] text-red-800 cursor-pointer" @click="remove_row(index)"></i>                                
                            </div>
                        </th>
                        <template v-for="(col,col_index) in item.cols">
                            <template v-if="!col.hidden">
                                <th v-if="col.type=='th'" :rowspan="col.rowspan" :colspan="col.colspan" :class="['bg-gray-100 border border-gray-200',`text-${col.align}`,`align-${col.valign}`]" @click="sel_column(index, col_index)">
                                    <ColsBlock
                                        :data="col"
                                        :rows="data.rows"
                                        :cols="data.cols"
                                        :index="index"
                                        :col_index="col_index"
                                        :sel_row_num="sel_row_num"
                                        :sel_column_num="sel_column_num"
                                        :sel_dynamic_content_index="sel_dynamic_content_index"
                                        :preview="preview"
                                        @selInputFieldProperty="sel_input_field_property"
                                        @RemoveFormElement="remove_form_element"
                                        @RemoveColumn="remove_column"
                                    />
                                </th>
                                <td v-else :class="['border border-gray-200',`text-${col.align}`,`align-${col.valign}`]" :rowspan="col.rowspan" :colspan="col.colspan" @click="sel_column(index, col_index)">
                                    <ColsBlock
                                        :data="col"
                                        :rows="data.rows"
                                        :cols="data.cols"
                                        :index="index"
                                        :col_index="col_index"
                                        :sel_row_num="sel_row_num"
                                        :sel_column_num="sel_column_num"
                                        :sel_dynamic_content_index="sel_dynamic_content_index"
                                        :preview="preview"
                                        @selInputFieldProperty="sel_input_field_property"
                                        @RemoveFormElement="remove_form_element"
                                        @RemoveColumn="remove_column"
                                    />
                                </td>
                            </template>
                        </template>                
                    </tr>
                </template>            
            </table>
        </div>
        <div class="flex items-center justify-center flex-row gap-5 mt-5">
            <div class="flex items-center flex-row gap-1 cursor-pointer text-[10px] bg-gray-50 font-semibold" @click="add_new_row">
                <i class="fa fa-plus"></i>
                <span>Add Row</span>
            </div>

            <div class="flex items-center flex-row gap-1 cursor-pointer text-[10px] bg-gray-50 font-semibold" @click="add_new_column">
                <i class="fa fa-plus"></i>
                <span>Add Column</span>
            </div>
        </div>        
        <!-- <pre>{{ data }}</pre> -->
    </div>
</template>
<script setup>
    import { onMounted, ref } from 'vue'
    import ColsBlock from './ColsBlock'
    const emit = defineEmits(['selTableCell'])
    const props = defineProps({
        data: Object,        
        index: Number,
        preview: Boolean
    })

    onMounted(() => {
        manage_table_setup()
    })

    const sel_row_num = ref(0)
    const sel_column_num = ref(0)
    const sel_dynamic_content_index = ref(-1)
    const sel_cell_cotent_number = ref('')
    const sel_column = (index, col_index) => {
        if(sel_row_num.value == (index + 1) && sel_column_num.value == (col_index + 1)){
            sel_row_num.value = 0
            sel_column_num.value = 0
        }else{
            sel_row_num.value = index + 1
            sel_column_num.value = col_index + 1
        }

        emit('selTableCell', sel_row_num.value, sel_column_num.value, sel_dynamic_content_index.value)
    }
    const sel_input_field_property = (index) => {
        let sel_item_str = (sel_row_num.value - 1).toString() + (sel_column_num.value - 1).toString() + index
        // //console.log(sel_cell_cotent_number.value,sel_item_str)
        if(sel_cell_cotent_number.value===sel_item_str){
            sel_dynamic_content_index.value = -1
            sel_cell_cotent_number.value = ''
        }else{
            sel_dynamic_content_index.value = index
            sel_cell_cotent_number.value = sel_item_str
        }
        
        emit('selTableCell', sel_row_num.value, sel_column_num.value, sel_dynamic_content_index.value)
    }
    const remove_form_element = () => {
        props.data.html[sel_row_num.value - 1].cols[sel_column_num.value - 1].dynamic_content.splice(sel_dynamic_content_index.value, 1)
        
        sel_input_field_property(sel_dynamic_content_index.value)
    }
    const remove_row = (index) => {
        props.data.html.splice(index, 1)
        props.data.rows--
    }
    const remove_column = (col_index) => {
        for(var i = 0; i < props.data.rows; i++){
            props.data.html[i].cols.splice(col_index, 1)
        }
        props.data.cols--
    }
    const add_new_row = () => { 
        props.data.rows++
        props.data.html.push({})
        manage_table_setup()
    }
    const add_new_column = () => {
        props.data.cols++
        manage_table_setup()
    }
    const manage_table_setup = () => {        
        for(var i = 0; i < props.data.rows; i++){
            if(!props.data.html[i].cols) props.data.html[i].cols = []
            for(var j = 0; j < props.data.cols; j++){
                let obj = {
                    type: 'td',
                    content_type: 1,
                    content: 'Title',
                    dynamic_content: [],
                    rowspan: 1,
                    colspan: 1,
                    align: 'center',
                    valign: 'middle',
                    hidden: false
                }
                if(!props.data.html[i].cols[j]) props.data.html[i].cols.push(obj)
            }
        }
    }
</script>
<style lang="scss" scoped> 
    .table_display_block{
        display: block;
        position: relative
    }

    ::-webkit-scrollbar {
        width: 10px; transition: all 0.4s ease-in;
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    }

    ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    }
</style>