<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
      <trashed-message v-if="citizen_application_status.deleted_at" class="mb-6" @restore="restore"> This Religion has been deleted. </trashed-message>
      <div class="w-full bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="update">
            <div class="flex flex-wrap -mb-8 -mr-6 p-8">
                <fieldset class="border border-solid border-gray-300 p-3 w-full lg:w-1/2"
                >
                  <legend class="text-sm">Citizen</legend>
                  <div>
                    <text-input
                      v-model="form.title_citizen"
                      :error="form.errors.title_citizen"
                      class="pb-8 pr-6 w-full lg:w-1/1"
                      label="Title"
                      required="true"
                      placeholder="i.e Approved"
                    />
                    <textarea-input
                      v-model="form.description_citizen"
                      :error="form.errors.description_citizen"
                      class="pb-8 pr-6 w-full lg:w-1/1"
                      label="Description"
                      required="true"
                      placeholder="i.e Approved"
                    />
                    <text-input
                      v-model="form.color_citizen"
                      :error="form.errors.color_citizen"
                      class="pb-8 pr-6 w-full lg:w-1/1"
                      label="Color"
                      required="true"
                      type="color"
                    />
                    <div class="pb-8 pr-6 w-full lg:w-1/1">
                      <Multiselect
                        v-model="form.citizen_decision_steps"
                        mode="tags"
                        placeholder="Decisions"
                        :options="desionStep"
                        :limit="20"
                        :search="true"
                      />
                    </div>
                  </div>
                </fieldset>
                <fieldset class="border border-solid border-gray-300 p-3 w-full lg:w-1/2"
                >
                  <legend class="text-sm">Workflow</legend>
                  <div>
                    <text-input
                      v-model="form.title_workflow"
                      :error="form.errors.title_workflow"
                      class="pb-8 pr-6 w-full lg:w-1/1"
                      label="Title"
                      required="true"
                      placeholder="i.e Approved"
                    />
                    <textarea-input
                      v-model="form.description_workflow"
                      :error="form.errors.description_workflow"
                      class="pb-8 pr-6 w-full lg:w-1/1"
                      label="Description"
                      required="true"
                      placeholder="i.e Approved"
                    />
                    <text-input
                      v-model="form.color_workflow"
                      :error="form.errors.color_workflow"
                      class="pb-8 pr-6 w-full lg:w-1/1"
                      label="Status Color"
                      required="true"
                      type="color"
                    />
                    <div class="pb-8 pr-6 w-full lg:w-1/1">
                      <Multiselect
                        v-model="form.workflow_decision_steps"
                        mode="tags"
                        placeholder="Decisions"
                        :options="desionStep"
                        :limit="20"
                        :search="true"
                      />
                    </div>
                  </div>
                </fieldset>
                <status-toogle-button
                  v-model="form.status"
                  :error="form.errors.status"
                  label="Status of Decision Step"
                  class="mt-10 pb-8 pr-6 w-full lg:w-1/1"
                ></status-toogle-button>
              </div>
          <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
            <button v-if="!citizen_application_status.deleted_at && permission.edit>0" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
            <Button v-if="permission.edit>0" :type="type" :name="head" :url="createUrl" :loading="form.processing"></Button>
          </div>
        </form>
      </div>
    </div>
  </template>

  <script>
  import { ref, toRefs, onMounted, inject } from 'vue'
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import axios from 'axios';
  import Icon from '@/Shared/Icon'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import TextareaInput from "@/Shared/TextareaInput";
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import TrashedMessage from '@/Shared/TrashedMessage'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import DeleteIcon from '@/Shared/DeleteIcon'
  import Button from '@/Shared/Button'
  import Multiselect from '@vueform/multiselect'
  export default {
    components: {
      Head,
      Icon,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      TextareaInput,
      TrashedMessage,
      StatusToogleButton,
      Breadcrumbs,
      DeleteIcon,
      Button,
      Multiselect
    },
    layout: Layout,
    props: {
      citizen_application_status: Object,
      desionStep: Array,
      permission: Object,
    },
    remember: 'form',
    setup(props){
      const { citizen_application_status,desionStep, permission} = toRefs(props)
      const type = ref('edit')
      const title = ref(citizen_application_status.value.title_citizen)
      const head = ref('Application Status')
      const url = ref('/backend/citizen-application-statuses')
      const createUrl = ref('')
      const elm = ref(this)
      const swal = inject('$swal')
      const form = useForm({
        title_citizen: citizen_application_status.value.title_citizen,
        description_citizen: citizen_application_status.value.description_citizen,
        color_citizen: citizen_application_status.value.color_citizen,
        citizen_decision_steps: citizen_application_status.value.citizen_decision_steps? citizen_application_status.value.citizen_decision_steps:[],
        title_workflow: citizen_application_status.value.title_workflow,
        description_workflow: citizen_application_status.value.description_workflow,
        color_workflow: citizen_application_status.value.color_workflow,
        workflow_decision_steps: citizen_application_status.value.workflow_decision_steps? citizen_application_status.value.workflow_decision_steps:[],
        status: citizen_application_status.value.status,
      })
      const update = () => {
          swal({
              title: 'Are you sure?',
              text: "You want to proceed with this action",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Confirm',
              cancelButtonText: 'Cancel',
            }).then((result) => {
              if (result.isConfirmed) {
                form.put(`${url.value}/${citizen_application_status.value.id}`)
                title.value = form.title_citizen
                Swal.fire(
                  'Updated!',
                  'Your infomation has been Updated.',
                  'success'
                )
              }
            });
      }
      const destroy = () => {
        swal({
            title: 'Are you sure?',
            text: "Do you really want to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.delete(`${url.value}/${citizen_application_status.value.id}`)
              Swal.fire(
                'Deleted!',
                'Your infomation has been deleted.',
                'success'
              )
            }
          });
      }
      const restore = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to restore this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Resore it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.put(`${url.value}/${citizen_application_status.value.id}/restore`)
              Swal.fire(
                'Restored!',
                'Your infomation has been Restored.',
                'success'
              )
            }
          });
      }

      const statusCheck = () => {
        if(citizen_application_status.value.status==1){
          form.status=true;
          return true;
        }else{
          form.status=false;
          return false;
        }
      }
      return {
        form,
        citizen_application_status,
        type,
        title,
        head,
        url,
        createUrl,
        update,
        destroy,
        restore,
        statusCheck,
        desionStep,
        swal,
        permission
      }
    },
    created: function(){
      this.statusCheck();
    }
  }
  </script>
