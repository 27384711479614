<template>
    <svg viewBox="0 0 1024 1024" :height="height" :width="width" fill="currentColor" focusable="false" aria-hidden="true"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><path d="M944,0 C988.18278,0 1024,35.81722 1024,80 L1024,944 C1024,988.18278 988.18278,1024 944,1024 L80,1024 C35.81722,1024 0,988.18278 0,944 L0,80 C0,35.81722 35.81722,0 80,0 L944,0 Z M944,20 L80,20 C47.1942859,20 20.5378857,46.328343 20,79.0077903 L20,80 L20,944 C20,976.805714 46.328343,1003.46211 79.0077903,1004 L80,1004 L944,1004 C976.805714,1004 1003.46211,977.671657 1004,944.99221 L1004,944 L1004,80 C1004,47.1942859 977.671657,20.5378857 944.99221,20 L944,20 Z" fill="#999999" fill-rule="nonzero"></path><rect fill="#999999" transform="translate(512.000000, 252.000000) scale(-1, 1) rotate(630.000000) translate(-512.000000, -252.000000) " x="502" y="-148" width="20" height="800" rx="10"></rect><rect :fill="fill_color" transform="translate(512.000000, 512.000000) scale(-1, 1) rotate(630.000000) translate(-512.000000, -512.000000) " x="502" y="112" width="20" height="800" rx="10"></rect><rect fill="#999999" transform="translate(512.000000, 772.000000) scale(-1, 1) rotate(630.000000) translate(-512.000000, -772.000000) " x="502" y="372" width="20" height="800" rx="10"></rect><rect fill="#999999" transform="translate(772.000000, 512.000000) scale(-1, 1) rotate(720.000000) translate(-772.000000, -512.000000) " x="762" y="112" width="20" height="800" rx="10"></rect><rect :fill="fill_color" transform="translate(512.000000, 512.000000) scale(-1, 1) rotate(720.000000) translate(-512.000000, -512.000000) " x="502" y="112" width="20" height="800" rx="10"></rect><rect fill="#999999" transform="translate(252.000000, 512.000000) scale(-1, 1) rotate(720.000000) translate(-252.000000, -512.000000) " x="242" y="112" width="20" height="800" rx="10"></rect></g></svg>
</template>
<script setup>
    import { ref } from "vue"
    const width = ref(150)
    const height = ref(40)
    const fill_color = ref('#1890ff')
</script>
<style lang="scss" scoped>
</style>