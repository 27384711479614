export default {
  fontAwesome: {
    title: 'Font Awesome',
    variants: {
      regular: {
        title: 'Regular',
        prefix: 'far fa-',
        iconstyle: 'far',
        listicon: 'far fa-flag',        
        icons: [
          "far fa-0",
          "far fa-1",
          "far fa-2",
          "far fa-3",
          "far fa-4",
          "far fa-5",
          "far fa-6",
          "far fa-7",
          "far fa-8",
          "far fa-9",
          "far fa-00",
          "far fa-360-degrees",
          "far fa-a",
          "far fa-abacus",
          "far fa-accent-grave",
          "far fa-acorn",
          "far fa-address-book",
          "far fa-address-card",
          "far fa-air-conditioner",
          "far fa-airplay",
          "far fa-alarm-clock",
          "far fa-alarm-exclamation",
          "far fa-alarm-plus",
          "far fa-alarm-snooze",
          "far fa-album",
          "far fa-album-circle-plus",
          "far fa-album-circle-user",
          "far fa-album-collection",
          "far fa-album-collection-circle-plus",
          "far fa-album-collection-circle-user",
          "far fa-alicorn",
          "far fa-alien",
          "far fa-alien-8bit",
          "far fa-align-center",
          "far fa-align-justify",
          "far fa-align-left",
          "far fa-align-right",
          "far fa-align-slash",
          "far fa-alt",
          "far fa-amp-guitar",
          "far fa-ampersand",
          "far fa-anchor",
          "far fa-anchor-circle-check",
          "far fa-anchor-circle-exclamation",
          "far fa-anchor-circle-xmark",
          "far fa-anchor-lock",
          "far fa-angel",
          "far fa-angle",
          "far fa-angle-90",
          "far fa-angle-down",
          "far fa-angle-left",
          "far fa-angle-right",
          "far fa-angle-up",
          "far fa-angles-down",
          "far fa-angles-left",
          "far fa-angles-right",
          "far fa-angles-up",
          "far fa-ankh",
          "far fa-apartment",
          "far fa-aperture",
          "far fa-apostrophe",
          "far fa-apple-core",
          "far fa-apple-whole",
          "far fa-archway",
          "far fa-arrow-down",
          "far fa-arrow-down-1-9",
          "far fa-arrow-down-9-1",
          "far fa-arrow-down-a-z",
          "far fa-arrow-down-arrow-up",
          "far fa-arrow-down-big-small",
          "far fa-arrow-down-from-dotted-line",
          "far fa-arrow-down-from-line",
          "far fa-arrow-down-left",
          "far fa-arrow-down-left-and-arrow-up-right-to-center",
          "far fa-arrow-down-long",
          "far fa-arrow-down-right",
          "far fa-arrow-down-short-wide",
          "far fa-arrow-down-small-big",
          "far fa-arrow-down-square-triangle",
          "far fa-arrow-down-to-arc",
          "far fa-arrow-down-to-bracket",
          "far fa-arrow-down-to-dotted-line",
          "far fa-arrow-down-to-line",
          "far fa-arrow-down-to-square",
          "far fa-arrow-down-triangle-square",
          "far fa-arrow-down-up-across-line",
          "far fa-arrow-down-up-lock",
          "far fa-arrow-down-wide-short",
          "far fa-arrow-down-z-a",
          "far fa-arrow-left",
          "far fa-arrow-left-from-line",
          "far fa-arrow-left-long",
          "far fa-arrow-left-long-to-line",
          "far fa-arrow-left-to-line",
          "far fa-arrow-pointer",
          "far fa-arrow-right",
          "far fa-arrow-right-arrow-left",
          "far fa-arrow-right-from-arc",
          "far fa-arrow-right-from-bracket",
          "far fa-arrow-right-from-line",
          "far fa-arrow-right-long",
          "far fa-arrow-right-long-to-line",
          "far fa-arrow-right-to-arc",
          "far fa-arrow-right-to-bracket",
          "far fa-arrow-right-to-city",
          "far fa-arrow-right-to-line",
          "far fa-arrow-rotate-left",
          "far fa-arrow-rotate-right",
          "far fa-arrow-trend-down",
          "far fa-arrow-trend-up",
          "far fa-arrow-turn-down",
          "far fa-arrow-turn-down-left",
          "far fa-arrow-turn-down-right",
          "far fa-arrow-turn-up",
          "far fa-arrow-up",
          "far fa-arrow-up-1-9",
          "far fa-arrow-up-9-1",
          "far fa-arrow-up-a-z",
          "far fa-arrow-up-arrow-down",
          "far fa-arrow-up-big-small",
          "far fa-arrow-up-from-arc",
          "far fa-arrow-up-from-bracket",
          "far fa-arrow-up-from-dotted-line",
          "far fa-arrow-up-from-ground-water",
          "far fa-arrow-up-from-line",
          "far fa-arrow-up-from-square",
          "far fa-arrow-up-from-water-pump",
          "far fa-arrow-up-left",
          "far fa-arrow-up-left-from-circle",
          "far fa-arrow-up-long",
          "far fa-arrow-up-right",
          "far fa-arrow-up-right-and-arrow-down-left-from-center",
          "far fa-arrow-up-right-dots",
          "far fa-arrow-up-right-from-square",
          "far fa-arrow-up-short-wide",
          "far fa-arrow-up-small-big",
          "far fa-arrow-up-square-triangle",
          "far fa-arrow-up-to-dotted-line",
          "far fa-arrow-up-to-line",
          "far fa-arrow-up-triangle-square",
          "far fa-arrow-up-wide-short",
          "far fa-arrow-up-z-a",
          "far fa-arrows-cross",
          "far fa-arrows-down-to-line",
          "far fa-arrows-down-to-people",
          "far fa-arrows-from-dotted-line",
          "far fa-arrows-from-line",
          "far fa-arrows-left-right",
          "far fa-arrows-left-right-to-line",
          "far fa-arrows-maximize",
          "far fa-arrows-minimize",
          "far fa-arrows-repeat",
          "far fa-arrows-repeat-1",
          "far fa-arrows-retweet",
          "far fa-arrows-rotate",
          "far fa-arrows-spin",
          "far fa-arrows-split-up-and-left",
          "far fa-arrows-to-circle",
          "far fa-arrows-to-dot",
          "far fa-arrows-to-dotted-line",
          "far fa-arrows-to-eye",
          "far fa-arrows-to-line",
          "far fa-arrows-turn-right",
          "far fa-arrows-turn-to-dots",
          "far fa-arrows-up-down",
          "far fa-arrows-up-down-left-right",
          "far fa-arrows-up-to-line",
          "far fa-asterisk",
          "far fa-at",
          "far fa-atom",
          "far fa-atom-simple",
          "far fa-audio-description",
          "far fa-audio-description-slash",
          "far fa-austral-sign",
          "far fa-avocado",
          "far fa-award",
          "far fa-award-simple",
          "far fa-axe",
          "far fa-axe-battle",
          "far fa-b",
          "far fa-baby",
          "far fa-baby-carriage",
          "far fa-backpack",
          "far fa-backward",
          "far fa-backward-fast",
          "far fa-backward-step",
          "far fa-bacon",
          "far fa-bacteria",
          "far fa-bacterium",
          "far fa-badge",
          "far fa-badge-check",
          "far fa-badge-dollar",
          "far fa-badge-percent",
          "far fa-badge-sheriff",
          "far fa-badger-honey",
          "far fa-badminton",
          "far fa-bag-shopping",
          "far fa-bagel",
          "far fa-bags-shopping",
          "far fa-baguette",
          "far fa-bahai",
          "far fa-baht-sign",
          "far fa-ball-pile",
          "far fa-balloon",
          "far fa-balloons",
          "far fa-ballot",
          "far fa-ballot-check",
          "far fa-ban",
          "far fa-ban-bug",
          "far fa-ban-parking",
          "far fa-ban-smoking",
          "far fa-banana",
          "far fa-bandage",
          "far fa-bangladeshi-taka-sign",
          "far fa-banjo",
          "far fa-barcode",
          "far fa-barcode-read",
          "far fa-barcode-scan",
          "far fa-bars",
          "far fa-bars-filter",
          "far fa-bars-progress",
          "far fa-bars-sort",
          "far fa-bars-staggered",
          "far fa-baseball",
          "far fa-baseball-bat-ball",
          "far fa-basket-shopping",
          "far fa-basket-shopping-simple",
          "far fa-basketball",
          "far fa-basketball-hoop",
          "far fa-bat",
          "far fa-bath",
          "far fa-battery-bolt",
          "far fa-battery-empty",
          "far fa-battery-exclamation",
          "far fa-battery-full",
          "far fa-battery-half",
          "far fa-battery-low",
          "far fa-battery-quarter",
          "far fa-battery-slash",
          "far fa-battery-three-quarters",
          "far fa-bed",
          "far fa-bed-bunk",
          "far fa-bed-empty",
          "far fa-bed-front",
          "far fa-bed-pulse",
          "far fa-bee",
          "far fa-beer-mug",
          "far fa-beer-mug-empty",
          "far fa-bell",
          "far fa-bell-concierge",
          "far fa-bell-exclamation",
          "far fa-bell-on",
          "far fa-bell-plus",
          "far fa-bell-school",
          "far fa-bell-school-slash",
          "far fa-bell-slash",
          "far fa-bells",
          "far fa-bench-tree",
          "far fa-bezier-curve",
          "far fa-bicycle",
          "far fa-billboard",
          "far fa-binary",
          "far fa-binary-circle-check",
          "far fa-binary-lock",
          "far fa-binary-slash",
          "far fa-binoculars",
          "far fa-biohazard",
          "far fa-bird",
          "far fa-bitcoin-sign",
          "far fa-blanket",
          "far fa-blanket-fire",
          "far fa-blender",
          "far fa-blender-phone",
          "far fa-blinds",
          "far fa-blinds-open",
          "far fa-blinds-raised",
          "far fa-block",
          "far fa-block-brick",
          "far fa-block-brick-fire",
          "far fa-block-question",
          "far fa-block-quote",
          "far fa-blog",
          "far fa-blueberries",
          "far fa-bluetooth",
          "far fa-bold",
          "far fa-bolt",
          "far fa-bolt-auto",
          "far fa-bolt-lightning",
          "far fa-bolt-slash",
          "far fa-bomb",
          "far fa-bone",
          "far fa-bone-break",
          "far fa-bong",
          "far fa-book",
          "far fa-book-arrow-right",
          "far fa-book-arrow-up",
          "far fa-book-atlas",
          "far fa-book-bible",
          "far fa-book-blank",
          "far fa-book-bookmark",
          "far fa-book-circle-arrow-right",
          "far fa-book-circle-arrow-up",
          "far fa-book-copy",
          "far fa-book-font",
          "far fa-book-heart",
          "far fa-book-journal-whills",
          "far fa-book-medical",
          "far fa-book-open",
          "far fa-book-open-cover",
          "far fa-book-open-reader",
          "far fa-book-quran",
          "far fa-book-section",
          "far fa-book-skull",
          "far fa-book-sparkles",
          "far fa-book-tanakh",
          "far fa-book-user",
          "far fa-bookmark",
          "far fa-bookmark-slash",
          "far fa-books",
          "far fa-books-medical",
          "far fa-boombox",
          "far fa-boot",
          "far fa-boot-heeled",
          "far fa-booth-curtain",
          "far fa-border-all",
          "far fa-border-bottom",
          "far fa-border-bottom-right",
          "far fa-border-center-h",
          "far fa-border-center-v",
          "far fa-border-inner",
          "far fa-border-left",
          "far fa-border-none",
          "far fa-border-outer",
          "far fa-border-right",
          "far fa-border-top",
          "far fa-border-top-left",
          "far fa-bore-hole",
          "far fa-bottle-droplet",
          "far fa-bottle-water",
          "far fa-bow-arrow",
          "far fa-bowl-chopsticks",
          "far fa-bowl-chopsticks-noodles",
          "far fa-bowl-food",
          "far fa-bowl-hot",
          "far fa-bowl-rice",
          "far fa-bowl-scoop",
          "far fa-bowl-scoops",
          "far fa-bowl-soft-serve",
          "far fa-bowl-spoon",
          "far fa-bowling-ball",
          "far fa-bowling-ball-pin",
          "far fa-bowling-pins",
          "far fa-box",
          "far fa-box-archive",
          "far fa-box-ballot",
          "far fa-box-check",
          "far fa-box-circle-check",
          "far fa-box-dollar",
          "far fa-box-heart",
          "far fa-box-open",
          "far fa-box-open-full",
          "far fa-box-taped",
          "far fa-box-tissue",
          "far fa-boxes-packing",
          "far fa-boxes-stacked",
          "far fa-boxing-glove",
          "far fa-bracket-curly",
          "far fa-bracket-curly-right",
          "far fa-bracket-round",
          "far fa-bracket-round-right",
          "far fa-bracket-square",
          "far fa-bracket-square-right",
          "far fa-brackets-curly",
          "far fa-brackets-round",
          "far fa-brackets-square",
          "far fa-braille",
          "far fa-brain",
          "far fa-brain-arrow-curved-right",
          "far fa-brain-circuit",
          "far fa-brake-warning",
          "far fa-brazilian-real-sign",
          "far fa-bread-loaf",
          "far fa-bread-slice",
          "far fa-bread-slice-butter",
          "far fa-bridge",
          "far fa-bridge-circle-check",
          "far fa-bridge-circle-exclamation",
          "far fa-bridge-circle-xmark",
          "far fa-bridge-lock",
          "far fa-bridge-suspension",
          "far fa-bridge-water",
          "far fa-briefcase",
          "far fa-briefcase-arrow-right",
          "far fa-briefcase-blank",
          "far fa-briefcase-medical",
          "far fa-brightness",
          "far fa-brightness-low",
          "far fa-bring-forward",
          "far fa-bring-front",
          "far fa-broccoli",
          "far fa-broom",
          "far fa-broom-ball",
          "far fa-broom-wide",
          "far fa-browser",
          "far fa-browsers",
          "far fa-brush",
          "far fa-bucket",
          "far fa-bug",
          "far fa-bug-slash",
          "far fa-bugs",
          "far fa-building",
          "far fa-building-circle-arrow-right",
          "far fa-building-circle-check",
          "far fa-building-circle-exclamation",
          "far fa-building-circle-xmark",
          "far fa-building-columns",
          "far fa-building-flag",
          "far fa-building-lock",
          "far fa-building-ngo",
          "far fa-building-shield",
          "far fa-building-un",
          "far fa-building-user",
          "far fa-building-wheat",
          "far fa-buildings",
          "far fa-bullhorn",
          "far fa-bullseye",
          "far fa-bullseye-arrow",
          "far fa-bullseye-pointer",
          "far fa-buoy",
          "far fa-buoy-mooring",
          "far fa-burger",
          "far fa-burger-cheese",
          "far fa-burger-fries",
          "far fa-burger-glass",
          "far fa-burger-lettuce",
          "far fa-burger-soda",
          "far fa-burrito",
          "far fa-burst",
          "far fa-bus",
          "far fa-bus-school",
          "far fa-bus-simple",
          "far fa-business-time",
          "far fa-butter",
          "far fa-c",
          "far fa-cabin",
          "far fa-cabinet-filing",
          "far fa-cable-car",
          "far fa-cactus",
          "far fa-cake-candles",
          "far fa-cake-slice",
          "far fa-calculator",
          "far fa-calculator-simple",
          "far fa-calendar",
          "far fa-calendar-arrow-down",
          "far fa-calendar-arrow-up",
          "far fa-calendar-check",
          "far fa-calendar-circle-exclamation",
          "far fa-calendar-circle-minus",
          "far fa-calendar-circle-plus",
          "far fa-calendar-circle-user",
          "far fa-calendar-clock",
          "far fa-calendar-day",
          "far fa-calendar-days",
          "far fa-calendar-exclamation",
          "far fa-calendar-heart",
          "far fa-calendar-image",
          "far fa-calendar-lines",
          "far fa-calendar-lines-pen",
          "far fa-calendar-minus",
          "far fa-calendar-pen",
          "far fa-calendar-plus",
          "far fa-calendar-range",
          "far fa-calendar-star",
          "far fa-calendar-week",
          "far fa-calendar-xmark",
          "far fa-calendars",
          "far fa-camcorder",
          "far fa-camera",
          "far fa-camera-cctv",
          "far fa-camera-movie",
          "far fa-camera-polaroid",
          "far fa-camera-retro",
          "far fa-camera-rotate",
          "far fa-camera-security",
          "far fa-camera-slash",
          "far fa-camera-viewfinder",
          "far fa-camera-web",
          "far fa-camera-web-slash",
          "far fa-campfire",
          "far fa-campground",
          "far fa-can-food",
          "far fa-candle-holder",
          "far fa-candy",
          "far fa-candy-bar",
          "far fa-candy-cane",
          "far fa-candy-corn",
          "far fa-cannabis",
          "far fa-capsules",
          "far fa-car",
          "far fa-car-battery",
          "far fa-car-bolt",
          "far fa-car-building",
          "far fa-car-bump",
          "far fa-car-burst",
          "far fa-car-bus",
          "far fa-car-circle-bolt",
          "far fa-car-garage",
          "far fa-car-mirrors",
          "far fa-car-on",
          "far fa-car-rear",
          "far fa-car-side",
          "far fa-car-side-bolt",
          "far fa-car-tilt",
          "far fa-car-tunnel",
          "far fa-car-wash",
          "far fa-car-wrench",
          "far fa-caravan",
          "far fa-caravan-simple",
          "far fa-card-club",
          "far fa-card-diamond",
          "far fa-card-heart",
          "far fa-card-spade",
          "far fa-cards",
          "far fa-cards-blank",
          "far fa-caret-down",
          "far fa-caret-left",
          "far fa-caret-right",
          "far fa-caret-up",
          "far fa-carrot",
          "far fa-cars",
          "far fa-cart-arrow-down",
          "far fa-cart-arrow-up",
          "far fa-cart-circle-arrow-down",
          "far fa-cart-circle-arrow-up",
          "far fa-cart-circle-check",
          "far fa-cart-circle-exclamation",
          "far fa-cart-circle-plus",
          "far fa-cart-circle-xmark",
          "far fa-cart-flatbed",
          "far fa-cart-flatbed-boxes",
          "far fa-cart-flatbed-empty",
          "far fa-cart-flatbed-suitcase",
          "far fa-cart-minus",
          "far fa-cart-plus",
          "far fa-cart-shopping",
          "far fa-cart-shopping-fast",
          "far fa-cart-xmark",
          "far fa-cash-register",
          "far fa-cassette-betamax",
          "far fa-cassette-tape",
          "far fa-cassette-vhs",
          "far fa-castle",
          "far fa-cat",
          "far fa-cat-space",
          "far fa-cauldron",
          "far fa-cedi-sign",
          "far fa-cent-sign",
          "far fa-certificate",
          "far fa-chair",
          "far fa-chair-office",
          "far fa-chalkboard",
          "far fa-chalkboard-user",
          "far fa-champagne-glass",
          "far fa-champagne-glasses",
          "far fa-charging-station",
          "far fa-chart-area",
          "far fa-chart-bar",
          "far fa-chart-bullet",
          "far fa-chart-candlestick",
          "far fa-chart-column",
          "far fa-chart-gantt",
          "far fa-chart-line",
          "far fa-chart-line-down",
          "far fa-chart-line-up",
          "far fa-chart-mixed",
          "far fa-chart-network",
          "far fa-chart-pie",
          "far fa-chart-pie-simple",
          "far fa-chart-pyramid",
          "far fa-chart-radar",
          "far fa-chart-scatter",
          "far fa-chart-scatter-3d",
          "far fa-chart-scatter-bubble",
          "far fa-chart-simple",
          "far fa-chart-simple-horizontal",
          "far fa-chart-tree-map",
          "far fa-chart-user",
          "far fa-chart-waterfall",
          "far fa-check",
          "far fa-check-double",
          "far fa-check-to-slot",
          "far fa-cheese",
          "far fa-cheese-swiss",
          "far fa-cherries",
          "far fa-chess",
          "far fa-chess-bishop",
          "far fa-chess-bishop-piece",
          "far fa-chess-board",
          "far fa-chess-clock",
          "far fa-chess-clock-flip",
          "far fa-chess-king",
          "far fa-chess-king-piece",
          "far fa-chess-knight",
          "far fa-chess-knight-piece",
          "far fa-chess-pawn",
          "far fa-chess-pawn-piece",
          "far fa-chess-queen",
          "far fa-chess-queen-piece",
          "far fa-chess-rook",
          "far fa-chess-rook-piece",
          "far fa-chestnut",
          "far fa-chevron-down",
          "far fa-chevron-left",
          "far fa-chevron-right",
          "far fa-chevron-up",
          "far fa-chevrons-down",
          "far fa-chevrons-left",
          "far fa-chevrons-right",
          "far fa-chevrons-up",
          "far fa-child",
          "far fa-child-combatant",
          "far fa-child-dress",
          "far fa-child-reaching",
          "far fa-children",
          "far fa-chimney",
          "far fa-chopsticks",
          "far fa-church",
          "far fa-circle",
          "far fa-circle-0",
          "far fa-circle-1",
          "far fa-circle-2",
          "far fa-circle-3",
          "far fa-circle-4",
          "far fa-circle-5",
          "far fa-circle-6",
          "far fa-circle-7",
          "far fa-circle-8",
          "far fa-circle-9",
          "far fa-circle-a",
          "far fa-circle-ampersand",
          "far fa-circle-arrow-down",
          "far fa-circle-arrow-down-left",
          "far fa-circle-arrow-down-right",
          "far fa-circle-arrow-left",
          "far fa-circle-arrow-right",
          "far fa-circle-arrow-up",
          "far fa-circle-arrow-up-left",
          "far fa-circle-arrow-up-right",
          "far fa-circle-b",
          "far fa-circle-bolt",
          "far fa-circle-book-open",
          "far fa-circle-bookmark",
          "far fa-circle-c",
          "far fa-circle-calendar",
          "far fa-circle-camera",
          "far fa-circle-caret-down",
          "far fa-circle-caret-left",
          "far fa-circle-caret-right",
          "far fa-circle-caret-up",
          "far fa-circle-check",
          "far fa-circle-chevron-down",
          "far fa-circle-chevron-left",
          "far fa-circle-chevron-right",
          "far fa-circle-chevron-up",
          "far fa-circle-d",
          "far fa-circle-dashed",
          "far fa-circle-divide",
          "far fa-circle-dollar",
          "far fa-circle-dollar-to-slot",
          "far fa-circle-dot",
          "far fa-circle-down",
          "far fa-circle-down-left",
          "far fa-circle-down-right",
          "far fa-circle-e",
          "far fa-circle-ellipsis",
          "far fa-circle-ellipsis-vertical",
          "far fa-circle-envelope",
          "far fa-circle-euro",
          "far fa-circle-exclamation",
          "far fa-circle-exclamation-check",
          "far fa-circle-f",
          "far fa-circle-g",
          "far fa-circle-h",
          "far fa-circle-half",
          "far fa-circle-half-stroke",
          "far fa-circle-heart",
          "far fa-circle-i",
          "far fa-circle-info",
          "far fa-circle-j",
          "far fa-circle-k",
          "far fa-circle-l",
          "far fa-circle-left",
          "far fa-circle-location-arrow",
          "far fa-circle-m",
          "far fa-circle-microphone",
          "far fa-circle-microphone-lines",
          "far fa-circle-minus",
          "far fa-circle-n",
          "far fa-circle-nodes",
          "far fa-circle-notch",
          "far fa-circle-o",
          "far fa-circle-p",
          "far fa-circle-parking",
          "far fa-circle-pause",
          "far fa-circle-phone",
          "far fa-circle-phone-flip",
          "far fa-circle-phone-hangup",
          "far fa-circle-play",
          "far fa-circle-plus",
          "far fa-circle-q",
          "far fa-circle-quarter",
          "far fa-circle-quarter-stroke",
          "far fa-circle-quarters",
          "far fa-circle-question",
          "far fa-circle-r",
          "far fa-circle-radiation",
          "far fa-circle-right",
          "far fa-circle-s",
          "far fa-circle-small",
          "far fa-circle-sort",
          "far fa-circle-sort-down",
          "far fa-circle-sort-up",
          "far fa-circle-star",
          "far fa-circle-sterling",
          "far fa-circle-stop",
          "far fa-circle-t",
          "far fa-circle-three-quarters",
          "far fa-circle-three-quarters-stroke",
          "far fa-circle-trash",
          "far fa-circle-u",
          "far fa-circle-up",
          "far fa-circle-up-left",
          "far fa-circle-up-right",
          "far fa-circle-user",
          "far fa-circle-v",
          "far fa-circle-video",
          "far fa-circle-w",
          "far fa-circle-waveform-lines",
          "far fa-circle-x",
          "far fa-circle-xmark",
          "far fa-circle-y",
          "far fa-circle-yen",
          "far fa-circle-z",
          "far fa-citrus",
          "far fa-citrus-slice",
          "far fa-city",
          "far fa-clapperboard",
          "far fa-clapperboard-play",
          "far fa-clarinet",
          "far fa-claw-marks",
          "far fa-clipboard",
          "far fa-clipboard-check",
          "far fa-clipboard-list",
          "far fa-clipboard-list-check",
          "far fa-clipboard-medical",
          "far fa-clipboard-prescription",
          "far fa-clipboard-question",
          "far fa-clipboard-user",
          "far fa-clock",
          "far fa-clock-desk",
          "far fa-clock-eight",
          "far fa-clock-eight-thirty",
          "far fa-clock-eleven",
          "far fa-clock-eleven-thirty",
          "far fa-clock-five",
          "far fa-clock-five-thirty",
          "far fa-clock-four-thirty",
          "far fa-clock-nine",
          "far fa-clock-nine-thirty",
          "far fa-clock-one",
          "far fa-clock-one-thirty",
          "far fa-clock-rotate-left",
          "far fa-clock-seven",
          "far fa-clock-seven-thirty",
          "far fa-clock-six",
          "far fa-clock-six-thirty",
          "far fa-clock-ten",
          "far fa-clock-ten-thirty",
          "far fa-clock-three",
          "far fa-clock-three-thirty",
          "far fa-clock-twelve",
          "far fa-clock-twelve-thirty",
          "far fa-clock-two",
          "far fa-clock-two-thirty",
          "far fa-clone",
          "far fa-closed-captioning",
          "far fa-closed-captioning-slash",
          "far fa-clothes-hanger",
          "far fa-cloud",
          "far fa-cloud-arrow-down",
          "far fa-cloud-arrow-up",
          "far fa-cloud-bolt",
          "far fa-cloud-bolt-moon",
          "far fa-cloud-bolt-sun",
          "far fa-cloud-check",
          "far fa-cloud-drizzle",
          "far fa-cloud-exclamation",
          "far fa-cloud-fog",
          "far fa-cloud-hail",
          "far fa-cloud-hail-mixed",
          "far fa-cloud-meatball",
          "far fa-cloud-minus",
          "far fa-cloud-moon",
          "far fa-cloud-moon-rain",
          "far fa-cloud-music",
          "far fa-cloud-plus",
          "far fa-cloud-question",
          "far fa-cloud-rain",
          "far fa-cloud-rainbow",
          "far fa-cloud-showers",
          "far fa-cloud-showers-heavy",
          "far fa-cloud-showers-water",
          "far fa-cloud-slash",
          "far fa-cloud-sleet",
          "far fa-cloud-snow",
          "far fa-cloud-sun",
          "far fa-cloud-sun-rain",
          "far fa-cloud-word",
          "far fa-cloud-xmark",
          "far fa-clouds",
          "far fa-clouds-moon",
          "far fa-clouds-sun",
          "far fa-clover",
          "far fa-club",
          "far fa-coconut",
          "far fa-code",
          "far fa-code-branch",
          "far fa-code-commit",
          "far fa-code-compare",
          "far fa-code-fork",
          "far fa-code-merge",
          "far fa-code-pull-request",
          "far fa-code-pull-request-closed",
          "far fa-code-pull-request-draft",
          "far fa-code-simple",
          "far fa-coffee-bean",
          "far fa-coffee-beans",
          "far fa-coffee-pot",
          "far fa-coffin",
          "far fa-coffin-cross",
          "far fa-coin",
          "far fa-coin-blank",
          "far fa-coin-front",
          "far fa-coin-vertical",
          "far fa-coins",
          "far fa-colon",
          "far fa-colon-sign",
          "far fa-columns-3",
          "far fa-comet",
          "far fa-comma",
          "far fa-command",
          "far fa-comment",
          "far fa-comment-arrow-down",
          "far fa-comment-arrow-up",
          "far fa-comment-arrow-up-right",
          "far fa-comment-captions",
          "far fa-comment-check",
          "far fa-comment-code",
          "far fa-comment-dollar",
          "far fa-comment-dots",
          "far fa-comment-exclamation",
          "far fa-comment-heart",
          "far fa-comment-image",
          "far fa-comment-lines",
          "far fa-comment-medical",
          "far fa-comment-middle",
          "far fa-comment-middle-top",
          "far fa-comment-minus",
          "far fa-comment-music",
          "far fa-comment-pen",
          "far fa-comment-plus",
          "far fa-comment-question",
          "far fa-comment-quote",
          "far fa-comment-slash",
          "far fa-comment-smile",
          "far fa-comment-sms",
          "far fa-comment-text",
          "far fa-comment-xmark",
          "far fa-comments",
          "far fa-comments-dollar",
          "far fa-comments-question",
          "far fa-comments-question-check",
          "far fa-compact-disc",
          "far fa-compass",
          "far fa-compass-drafting",
          "far fa-compass-slash",
          "far fa-compress",
          "far fa-compress-wide",
          "far fa-computer",
          "far fa-computer-classic",
          "far fa-computer-mouse",
          "far fa-computer-mouse-scrollwheel",
          "far fa-computer-speaker",
          "far fa-container-storage",
          "far fa-conveyor-belt",
          "far fa-conveyor-belt-boxes",
          "far fa-conveyor-belt-empty",
          "far fa-cookie",
          "far fa-cookie-bite",
          "far fa-copy",
          "far fa-copyright",
          "far fa-corn",
          "far fa-corner",
          "far fa-couch",
          "far fa-cow",
          "far fa-cowbell",
          "far fa-cowbell-circle-plus",
          "far fa-crab",
          "far fa-crate-apple",
          "far fa-crate-empty",
          "far fa-credit-card",
          "far fa-credit-card-blank",
          "far fa-credit-card-front",
          "far fa-cricket-bat-ball",
          "far fa-croissant",
          "far fa-crop",
          "far fa-crop-simple",
          "far fa-cross",
          "far fa-crosshairs",
          "far fa-crosshairs-simple",
          "far fa-crow",
          "far fa-crown",
          "far fa-crutch",
          "far fa-crutches",
          "far fa-cruzeiro-sign",
          "far fa-crystal-ball",
          "far fa-cube",
          "far fa-cubes",
          "far fa-cubes-stacked",
          "far fa-cucumber",
          "far fa-cup-straw",
          "far fa-cup-straw-swoosh",
          "far fa-cup-togo",
          "far fa-cupcake",
          "far fa-curling-stone",
          "far fa-custard",
          "far fa-d",
          "far fa-dagger",
          "far fa-dash",
          "far fa-database",
          "far fa-deer",
          "far fa-deer-rudolph",
          "far fa-delete-left",
          "far fa-delete-right",
          "far fa-democrat",
          "far fa-desktop",
          "far fa-desktop-arrow-down",
          "far fa-dharmachakra",
          "far fa-diagram-cells",
          "far fa-diagram-lean-canvas",
          "far fa-diagram-nested",
          "far fa-diagram-next",
          "far fa-diagram-predecessor",
          "far fa-diagram-previous",
          "far fa-diagram-project",
          "far fa-diagram-sankey",
          "far fa-diagram-subtask",
          "far fa-diagram-successor",
          "far fa-diagram-venn",
          "far fa-dial",
          "far fa-dial-high",
          "far fa-dial-low",
          "far fa-dial-max",
          "far fa-dial-med",
          "far fa-dial-med-low",
          "far fa-dial-min",
          "far fa-dial-off",
          "far fa-diamond",
          "far fa-diamond-exclamation",
          "far fa-diamond-half",
          "far fa-diamond-half-stroke",
          "far fa-diamond-turn-right",
          "far fa-dice",
          "far fa-dice-d10",
          "far fa-dice-d12",
          "far fa-dice-d20",
          "far fa-dice-d4",
          "far fa-dice-d6",
          "far fa-dice-d8",
          "far fa-dice-five",
          "far fa-dice-four",
          "far fa-dice-one",
          "far fa-dice-six",
          "far fa-dice-three",
          "far fa-dice-two",
          "far fa-diploma",
          "far fa-disc-drive",
          "far fa-disease",
          "far fa-display",
          "far fa-display-arrow-down",
          "far fa-display-code",
          "far fa-display-medical",
          "far fa-display-slash",
          "far fa-distribute-spacing-horizontal",
          "far fa-distribute-spacing-vertical",
          "far fa-ditto",
          "far fa-divide",
          "far fa-dna",
          "far fa-do-not-enter",
          "far fa-dog",
          "far fa-dog-leashed",
          "far fa-dollar-sign",
          "far fa-dolly",
          "far fa-dolly-empty",
          "far fa-dolphin",
          "far fa-dong-sign",
          "far fa-donut",
          "far fa-door-closed",
          "far fa-door-open",
          "far fa-dove",
          "far fa-down",
          "far fa-down-from-dotted-line",
          "far fa-down-from-line",
          "far fa-down-left",
          "far fa-down-left-and-up-right-to-center",
          "far fa-down-long",
          "far fa-down-right",
          "far fa-down-to-bracket",
          "far fa-down-to-dotted-line",
          "far fa-down-to-line",
          "far fa-download",
          "far fa-dragon",
          "far fa-draw-circle",
          "far fa-draw-polygon",
          "far fa-draw-square",
          "far fa-dreidel",
          "far fa-drone",
          "far fa-drone-front",
          "far fa-droplet",
          "far fa-droplet-degree",
          "far fa-droplet-percent",
          "far fa-droplet-slash",
          "far fa-drum",
          "far fa-drum-steelpan",
          "far fa-drumstick",
          "far fa-drumstick-bite",
          "far fa-dryer",
          "far fa-dryer-heat",
          "far fa-duck",
          "far fa-dumbbell",
          "far fa-dumpster",
          "far fa-dumpster-fire",
          "far fa-dungeon",
          "far fa-e",
          "far fa-ear",
          "far fa-ear-deaf",
          "far fa-ear-listen",
          "far fa-ear-muffs",
          "far fa-earth-africa",
          "far fa-earth-americas",
          "far fa-earth-asia",
          "far fa-earth-europe",
          "far fa-earth-oceania",
          "far fa-eclipse",
          "far fa-egg",
          "far fa-egg-fried",
          "far fa-eggplant",
          "far fa-eject",
          "far fa-elephant",
          "far fa-elevator",
          "far fa-ellipsis",
          "far fa-ellipsis-stroke",
          "far fa-ellipsis-stroke-vertical",
          "far fa-ellipsis-vertical",
          "far fa-empty-set",
          "far fa-engine",
          "far fa-engine-warning",
          "far fa-envelope",
          "far fa-envelope-circle-check",
          "far fa-envelope-dot",
          "far fa-envelope-open",
          "far fa-envelope-open-dollar",
          "far fa-envelope-open-text",
          "far fa-envelopes",
          "far fa-envelopes-bulk",
          "far fa-equals",
          "far fa-eraser",
          "far fa-escalator",
          "far fa-ethernet",
          "far fa-euro-sign",
          "far fa-exclamation",
          "far fa-expand",
          "far fa-expand-wide",
          "far fa-explosion",
          "far fa-eye",
          "far fa-eye-dropper",
          "far fa-eye-dropper-full",
          "far fa-eye-dropper-half",
          "far fa-eye-evil",
          "far fa-eye-low-vision",
          "far fa-eye-slash",
          "far fa-eyes",
          "far fa-f",
          "far fa-face-angry",
          "far fa-face-angry-horns",
          "far fa-face-anguished",
          "far fa-face-anxious-sweat",
          "far fa-face-astonished",
          "far fa-face-awesome",
          "far fa-face-beam-hand-over-mouth",
          "far fa-face-clouds",
          "far fa-face-confounded",
          "far fa-face-confused",
          "far fa-face-cowboy-hat",
          "far fa-face-diagonal-mouth",
          "far fa-face-disappointed",
          "far fa-face-disguise",
          "far fa-face-dizzy",
          "far fa-face-dotted",
          "far fa-face-downcast-sweat",
          "far fa-face-drooling",
          "far fa-face-exhaling",
          "far fa-face-explode",
          "far fa-face-expressionless",
          "far fa-face-eyes-xmarks",
          "far fa-face-fearful",
          "far fa-face-flushed",
          "far fa-face-frown",
          "far fa-face-frown-open",
          "far fa-face-frown-slight",
          "far fa-face-glasses",
          "far fa-face-grimace",
          "far fa-face-grin",
          "far fa-face-grin-beam",
          "far fa-face-grin-beam-sweat",
          "far fa-face-grin-hearts",
          "far fa-face-grin-squint",
          "far fa-face-grin-squint-tears",
          "far fa-face-grin-stars",
          "far fa-face-grin-tears",
          "far fa-face-grin-tongue",
          "far fa-face-grin-tongue-squint",
          "far fa-face-grin-tongue-wink",
          "far fa-face-grin-wide",
          "far fa-face-grin-wink",
          "far fa-face-hand-over-mouth",
          "far fa-face-hand-peeking",
          "far fa-face-hand-yawn",
          "far fa-face-head-bandage",
          "far fa-face-holding-back-tears",
          "far fa-face-hushed",
          "far fa-face-icicles",
          "far fa-face-kiss",
          "far fa-face-kiss-beam",
          "far fa-face-kiss-closed-eyes",
          "far fa-face-kiss-wink-heart",
          "far fa-face-laugh",
          "far fa-face-laugh-beam",
          "far fa-face-laugh-squint",
          "far fa-face-laugh-wink",
          "far fa-face-lying",
          "far fa-face-mask",
          "far fa-face-meh",
          "far fa-face-meh-blank",
          "far fa-face-melting",
          "far fa-face-monocle",
          "far fa-face-nauseated",
          "far fa-face-nose-steam",
          "far fa-face-party",
          "far fa-face-pensive",
          "far fa-face-persevering",
          "far fa-face-pleading",
          "far fa-face-pouting",
          "far fa-face-raised-eyebrow",
          "far fa-face-relieved",
          "far fa-face-rolling-eyes",
          "far fa-face-sad-cry",
          "far fa-face-sad-sweat",
          "far fa-face-sad-tear",
          "far fa-face-saluting",
          "far fa-face-scream",
          "far fa-face-shush",
          "far fa-face-sleeping",
          "far fa-face-sleepy",
          "far fa-face-smile",
          "far fa-face-smile-beam",
          "far fa-face-smile-halo",
          "far fa-face-smile-hearts",
          "far fa-face-smile-horns",
          "far fa-face-smile-plus",
          "far fa-face-smile-relaxed",
          "far fa-face-smile-tear",
          "far fa-face-smile-tongue",
          "far fa-face-smile-upside-down",
          "far fa-face-smile-wink",
          "far fa-face-smiling-hands",
          "far fa-face-smirking",
          "far fa-face-spiral-eyes",
          "far fa-face-sunglasses",
          "far fa-face-surprise",
          "far fa-face-swear",
          "far fa-face-thermometer",
          "far fa-face-thinking",
          "far fa-face-tired",
          "far fa-face-tissue",
          "far fa-face-tongue-money",
          "far fa-face-tongue-sweat",
          "far fa-face-unamused",
          "far fa-face-viewfinder",
          "far fa-face-vomit",
          "far fa-face-weary",
          "far fa-face-woozy",
          "far fa-face-worried",
          "far fa-face-zany",
          "far fa-face-zipper",
          "far fa-falafel",
          "far fa-family",
          "far fa-family-dress",
          "far fa-family-pants",
          "far fa-fan",
          "far fa-fan-table",
          "far fa-farm",
          "far fa-faucet",
          "far fa-faucet-drip",
          "far fa-fax",
          "far fa-feather",
          "far fa-feather-pointed",
          "far fa-fence",
          "far fa-ferris-wheel",
          "far fa-ferry",
          "far fa-field-hockey-stick-ball",
          "far fa-file",
          "far fa-file-arrow-down",
          "far fa-file-arrow-up",
          "far fa-file-audio",
          "far fa-file-binary",
          "far fa-file-certificate",
          "far fa-file-chart-column",
          "far fa-file-chart-pie",
          "far fa-file-check",
          "far fa-file-circle-check",
          "far fa-file-circle-exclamation",
          "far fa-file-circle-info",
          "far fa-file-circle-minus",
          "far fa-file-circle-plus",
          "far fa-file-circle-question",
          "far fa-file-circle-xmark",
          "far fa-file-code",
          "far fa-file-contract",
          "far fa-file-csv",
          "far fa-file-dashed-line",
          "far fa-file-excel",
          "far fa-file-exclamation",
          "far fa-file-export",
          "far fa-file-heart",
          "far fa-file-image",
          "far fa-file-import",
          "far fa-file-invoice",
          "far fa-file-invoice-dollar",
          "far fa-file-lines",
          "far fa-file-lock",
          "far fa-file-magnifying-glass",
          "far fa-file-medical",
          "far fa-file-minus",
          "far fa-file-music",
          "far fa-file-pdf",
          "far fa-file-pen",
          "far fa-file-plus",
          "far fa-file-plus-minus",
          "far fa-file-powerpoint",
          "far fa-file-prescription",
          "far fa-file-shield",
          "far fa-file-signature",
          "far fa-file-slash",
          "far fa-file-spreadsheet",
          "far fa-file-user",
          "far fa-file-video",
          "far fa-file-waveform",
          "far fa-file-word",
          "far fa-file-xmark",
          "far fa-file-zipper",
          "far fa-files",
          "far fa-files-medical",
          "far fa-fill",
          "far fa-fill-drip",
          "far fa-film",
          "far fa-film-canister",
          "far fa-film-simple",
          "far fa-film-slash",
          "far fa-films",
          "far fa-filter",
          "far fa-filter-circle-dollar",
          "far fa-filter-circle-xmark",
          "far fa-filter-list",
          "far fa-filter-slash",
          "far fa-filters",
          "far fa-fingerprint",
          "far fa-fire",
          "far fa-fire-burner",
          "far fa-fire-extinguisher",
          "far fa-fire-flame",
          "far fa-fire-flame-curved",
          "far fa-fire-flame-simple",
          "far fa-fire-hydrant",
          "far fa-fire-smoke",
          "far fa-fireplace",
          "far fa-fish",
          "far fa-fish-bones",
          "far fa-fish-cooked",
          "far fa-fish-fins",
          "far fa-fishing-rod",
          "far fa-flag",
          "far fa-flag-checkered",
          "far fa-flag-pennant",
          "far fa-flag-swallowtail",
          "far fa-flag-usa",
          "far fa-flashlight",
          "far fa-flask",
          "far fa-flask-round-poison",
          "far fa-flask-round-potion",
          "far fa-flask-vial",
          "far fa-flatbread",
          "far fa-flatbread-stuffed",
          "far fa-floppy-disk",
          "far fa-floppy-disk-circle-arrow-right",
          "far fa-floppy-disk-circle-xmark",
          "far fa-floppy-disk-pen",
          "far fa-floppy-disks",
          "far fa-florin-sign",
          "far fa-flower",
          "far fa-flower-daffodil",
          "far fa-flower-tulip",
          "far fa-flute",
          "far fa-flux-capacitor",
          "far fa-flying-disc",
          "far fa-folder",
          "far fa-folder-arrow-down",
          "far fa-folder-arrow-up",
          "far fa-folder-bookmark",
          "far fa-folder-closed",
          "far fa-folder-gear",
          "far fa-folder-grid",
          "far fa-folder-heart",
          "far fa-folder-image",
          "far fa-folder-magnifying-glass",
          "far fa-folder-medical",
          "far fa-folder-minus",
          "far fa-folder-music",
          "far fa-folder-open",
          "far fa-folder-plus",
          "far fa-folder-tree",
          "far fa-folder-user",
          "far fa-folder-xmark",
          "far fa-folders",
          "far fa-fondue-pot",
          "far fa-font",
          "far fa-font-awesome",
          "far fa-font-case",
          "far fa-football",
          "far fa-football-helmet",
          "far fa-fork",
          "far fa-fork-knife",
          "far fa-forklift",
          "far fa-fort",
          "far fa-forward",
          "far fa-forward-fast",
          "far fa-forward-step",
          "far fa-frame",
          "far fa-franc-sign",
          "far fa-french-fries",
          "far fa-frog",
          "far fa-function",
          "far fa-futbol",
          "far fa-g",
          "far fa-galaxy",
          "far fa-gallery-thumbnails",
          "far fa-game-board",
          "far fa-game-board-simple",
          "far fa-game-console-handheld",
          "far fa-game-console-handheld-crank",
          "far fa-gamepad",
          "far fa-gamepad-modern",
          "far fa-garage",
          "far fa-garage-car",
          "far fa-garage-open",
          "far fa-garlic",
          "far fa-gas-pump",
          "far fa-gas-pump-slash",
          "far fa-gauge",
          "far fa-gauge-circle-bolt",
          "far fa-gauge-circle-minus",
          "far fa-gauge-circle-plus",
          "far fa-gauge-high",
          "far fa-gauge-low",
          "far fa-gauge-max",
          "far fa-gauge-min",
          "far fa-gauge-simple",
          "far fa-gauge-simple-high",
          "far fa-gauge-simple-low",
          "far fa-gauge-simple-max",
          "far fa-gauge-simple-min",
          "far fa-gavel",
          "far fa-gear",
          "far fa-gears",
          "far fa-gem",
          "far fa-genderless",
          "far fa-ghost",
          "far fa-gif",
          "far fa-gift",
          "far fa-gift-card",
          "far fa-gifts",
          "far fa-gingerbread-man",
          "far fa-glass",
          "far fa-glass-citrus",
          "far fa-glass-empty",
          "far fa-glass-half",
          "far fa-glass-water",
          "far fa-glass-water-droplet",
          "far fa-glasses",
          "far fa-glasses-round",
          "far fa-globe",
          "far fa-globe-snow",
          "far fa-globe-stand",
          "far fa-goal-net",
          "far fa-golf-ball-tee",
          "far fa-golf-club",
          "far fa-golf-flag-hole",
          "far fa-gopuram",
          "far fa-graduation-cap",
          "far fa-gramophone",
          "far fa-grapes",
          "far fa-grate",
          "far fa-grate-droplet",
          "far fa-greater-than",
          "far fa-greater-than-equal",
          "far fa-grid",
          "far fa-grid-2",
          "far fa-grid-2-plus",
          "far fa-grid-4",
          "far fa-grid-5",
          "far fa-grid-dividers",
          "far fa-grid-horizontal",
          "far fa-grill",
          "far fa-grill-fire",
          "far fa-grill-hot",
          "far fa-grip",
          "far fa-grip-dots",
          "far fa-grip-dots-vertical",
          "far fa-grip-lines",
          "far fa-grip-lines-vertical",
          "far fa-grip-vertical",
          "far fa-group-arrows-rotate",
          "far fa-guarani-sign",
          "far fa-guitar",
          "far fa-guitar-electric",
          "far fa-guitars",
          "far fa-gun",
          "far fa-gun-slash",
          "far fa-gun-squirt",
          "far fa-h",
          "far fa-h1",
          "far fa-h2",
          "far fa-h3",
          "far fa-h4",
          "far fa-h5",
          "far fa-h6",
          "far fa-hammer",
          "far fa-hammer-crash",
          "far fa-hammer-war",
          "far fa-hamsa",
          "far fa-hand",
          "far fa-hand-back-fist",
          "far fa-hand-back-point-down",
          "far fa-hand-back-point-left",
          "far fa-hand-back-point-ribbon",
          "far fa-hand-back-point-right",
          "far fa-hand-back-point-up",
          "far fa-hand-dots",
          "far fa-hand-fingers-crossed",
          "far fa-hand-fist",
          "far fa-hand-heart",
          "far fa-hand-holding",
          "far fa-hand-holding-box",
          "far fa-hand-holding-dollar",
          "far fa-hand-holding-droplet",
          "far fa-hand-holding-hand",
          "far fa-hand-holding-heart",
          "far fa-hand-holding-magic",
          "far fa-hand-holding-medical",
          "far fa-hand-holding-seedling",
          "far fa-hand-holding-skull",
          "far fa-hand-horns",
          "far fa-hand-lizard",
          "far fa-hand-love",
          "far fa-hand-middle-finger",
          "far fa-hand-peace",
          "far fa-hand-point-down",
          "far fa-hand-point-left",
          "far fa-hand-point-ribbon",
          "far fa-hand-point-right",
          "far fa-hand-point-up",
          "far fa-hand-pointer",
          "far fa-hand-scissors",
          "far fa-hand-sparkles",
          "far fa-hand-spock",
          "far fa-hand-wave",
          "far fa-handcuffs",
          "far fa-hands",
          "far fa-hands-asl-interpreting",
          "far fa-hands-bound",
          "far fa-hands-bubbles",
          "far fa-hands-clapping",
          "far fa-hands-holding",
          "far fa-hands-holding-child",
          "far fa-hands-holding-circle",
          "far fa-hands-holding-diamond",
          "far fa-hands-holding-dollar",
          "far fa-hands-holding-heart",
          "far fa-hands-praying",
          "far fa-handshake",
          "far fa-handshake-angle",
          "far fa-handshake-simple",
          "far fa-handshake-simple-slash",
          "far fa-handshake-slash",
          "far fa-hanukiah",
          "far fa-hard-drive",
          "far fa-hashtag",
          "far fa-hashtag-lock",
          "far fa-hat-chef",
          "far fa-hat-cowboy",
          "far fa-hat-cowboy-side",
          "far fa-hat-santa",
          "far fa-hat-winter",
          "far fa-hat-witch",
          "far fa-hat-wizard",
          "far fa-head-side",
          "far fa-head-side-brain",
          "far fa-head-side-cough",
          "far fa-head-side-cough-slash",
          "far fa-head-side-goggles",
          "far fa-head-side-headphones",
          "far fa-head-side-heart",
          "far fa-head-side-mask",
          "far fa-head-side-medical",
          "far fa-head-side-virus",
          "far fa-heading",
          "far fa-headphones",
          "far fa-headphones-simple",
          "far fa-headset",
          "far fa-heart",
          "far fa-heart-circle-bolt",
          "far fa-heart-circle-check",
          "far fa-heart-circle-exclamation",
          "far fa-heart-circle-minus",
          "far fa-heart-circle-plus",
          "far fa-heart-circle-xmark",
          "far fa-heart-crack",
          "far fa-heart-half",
          "far fa-heart-half-stroke",
          "far fa-heart-pulse",
          "far fa-heat",
          "far fa-helicopter",
          "far fa-helicopter-symbol",
          "far fa-helmet-battle",
          "far fa-helmet-safety",
          "far fa-helmet-un",
          "far fa-hexagon",
          "far fa-hexagon-check",
          "far fa-hexagon-divide",
          "far fa-hexagon-exclamation",
          "far fa-hexagon-image",
          "far fa-hexagon-minus",
          "far fa-hexagon-plus",
          "far fa-hexagon-vertical-nft",
          "far fa-hexagon-vertical-nft-slanted",
          "far fa-hexagon-xmark",
          "far fa-high-definition",
          "far fa-highlighter",
          "far fa-highlighter-line",
          "far fa-hill-avalanche",
          "far fa-hill-rockslide",
          "far fa-hippo",
          "far fa-hockey-mask",
          "far fa-hockey-puck",
          "far fa-hockey-stick-puck",
          "far fa-hockey-sticks",
          "far fa-holly-berry",
          "far fa-honey-pot",
          "far fa-hood-cloak",
          "far fa-horizontal-rule",
          "far fa-horse",
          "far fa-horse-head",
          "far fa-horse-saddle",
          "far fa-hose",
          "far fa-hose-reel",
          "far fa-hospital",
          "far fa-hospital-user",
          "far fa-hospitals",
          "far fa-hot-tub-person",
          "far fa-hotdog",
          "far fa-hotel",
          "far fa-hourglass",
          "far fa-hourglass-clock",
          "far fa-hourglass-end",
          "far fa-hourglass-half",
          "far fa-hourglass-start",
          "far fa-house",
          "far fa-house-blank",
          "far fa-house-building",
          "far fa-house-chimney",
          "far fa-house-chimney-blank",
          "far fa-house-chimney-crack",
          "far fa-house-chimney-heart",
          "far fa-house-chimney-medical",
          "far fa-house-chimney-user",
          "far fa-house-chimney-window",
          "far fa-house-circle-check",
          "far fa-house-circle-exclamation",
          "far fa-house-circle-xmark",
          "far fa-house-crack",
          "far fa-house-day",
          "far fa-house-fire",
          "far fa-house-flag",
          "far fa-house-flood-water",
          "far fa-house-flood-water-circle-arrow-right",
          "far fa-house-heart",
          "far fa-house-laptop",
          "far fa-house-lock",
          "far fa-house-medical",
          "far fa-house-medical-circle-check",
          "far fa-house-medical-circle-exclamation",
          "far fa-house-medical-circle-xmark",
          "far fa-house-medical-flag",
          "far fa-house-night",
          "far fa-house-person-leave",
          "far fa-house-person-return",
          "far fa-house-signal",
          "far fa-house-tree",
          "far fa-house-tsunami",
          "far fa-house-turret",
          "far fa-house-user",
          "far fa-house-water",
          "far fa-house-window",
          "far fa-hryvnia-sign",
          "far fa-hundred-points",
          "far fa-hurricane",
          "far fa-hyphen",
          "far fa-i",
          "far fa-i-cursor",
          "far fa-ice-cream",
          "far fa-ice-skate",
          "far fa-icicles",
          "far fa-icons",
          "far fa-id-badge",
          "far fa-id-card",
          "far fa-id-card-clip",
          "far fa-igloo",
          "far fa-image",
          "far fa-image-landscape",
          "far fa-image-polaroid",
          "far fa-image-polaroid-user",
          "far fa-image-portrait",
          "far fa-image-slash",
          "far fa-image-user",
          "far fa-images",
          "far fa-images-user",
          "far fa-inbox",
          "far fa-inbox-full",
          "far fa-inbox-in",
          "far fa-inbox-out",
          "far fa-inboxes",
          "far fa-indent",
          "far fa-indian-rupee-sign",
          "far fa-industry",
          "far fa-industry-windows",
          "far fa-infinity",
          "far fa-info",
          "far fa-inhaler",
          "far fa-input-numeric",
          "far fa-input-pipe",
          "far fa-input-text",
          "far fa-integral",
          "far fa-interrobang",
          "far fa-intersection",
          "far fa-island-tropical",
          "far fa-italic",
          "far fa-j",
          "far fa-jack-o-lantern",
          "far fa-jar",
          "far fa-jar-wheat",
          "far fa-jedi",
          "far fa-jet-fighter",
          "far fa-jet-fighter-up",
          "far fa-joint",
          "far fa-joystick",
          "far fa-jug",
          "far fa-jug-detergent",
          "far fa-k",
          "far fa-kaaba",
          "far fa-kazoo",
          "far fa-kerning",
          "far fa-key",
          "far fa-key-skeleton",
          "far fa-key-skeleton-left-right",
          "far fa-keyboard",
          "far fa-keyboard-brightness",
          "far fa-keyboard-brightness-low",
          "far fa-keyboard-down",
          "far fa-keyboard-left",
          "far fa-keynote",
          "far fa-khanda",
          "far fa-kidneys",
          "far fa-kip-sign",
          "far fa-kit-medical",
          "far fa-kitchen-set",
          "far fa-kite",
          "far fa-kiwi-bird",
          "far fa-kiwi-fruit",
          "far fa-knife",
          "far fa-knife-kitchen",
          "far fa-l",
          "far fa-lacrosse-stick",
          "far fa-lacrosse-stick-ball",
          "far fa-lambda",
          "far fa-lamp",
          "far fa-lamp-desk",
          "far fa-lamp-floor",
          "far fa-lamp-street",
          "far fa-land-mine-on",
          "far fa-landmark",
          "far fa-landmark-dome",
          "far fa-landmark-flag",
          "far fa-language",
          "far fa-laptop",
          "far fa-laptop-arrow-down",
          "far fa-laptop-code",
          "far fa-laptop-file",
          "far fa-laptop-medical",
          "far fa-laptop-mobile",
          "far fa-laptop-slash",
          "far fa-lari-sign",
          "far fa-lasso",
          "far fa-lasso-sparkles",
          "far fa-layer-group",
          "far fa-layer-minus",
          "far fa-layer-plus",
          "far fa-leaf",
          "far fa-leaf-heart",
          "far fa-leaf-maple",
          "far fa-leaf-oak",
          "far fa-leafy-green",
          "far fa-left",
          "far fa-left-from-line",
          "far fa-left-long",
          "far fa-left-long-to-line",
          "far fa-left-right",
          "far fa-left-to-line",
          "far fa-lemon",
          "far fa-less-than",
          "far fa-less-than-equal",
          "far fa-life-ring",
          "far fal-ceiling",
          "far fal-emergency",
          "far fal-emergency-on",
          "far fal-switch",
          "far fal-switch-off",
          "far fal-switch-on",
          "far falbulb",
          "far falbulb-cfl",
          "far falbulb-cfl-on",
          "far falbulb-dollar",
          "far falbulb-exclamation",
          "far falbulb-exclamation-on",
          "far falbulb-on",
          "far falbulb-slash",
          "far fals-holiday",
          "far fa-line-columns",
          "far fa-line-height",
          "far fa-lines-leaning",
          "far fa-link",
          "far fa-link-horizontal",
          "far fa-link-horizontal-slash",
          "far fa-link-simple",
          "far fa-link-simple-slash",
          "far fa-link-slash",
          "far fa-lips",
          "far fa-lira-sign",
          "far fa-list",
          "far fa-list-check",
          "far fa-list-dropdown",
          "far fa-list-music",
          "far fa-list-ol",
          "far fa-list-radio",
          "far fa-list-timeline",
          "far fa-list-tree",
          "far fa-list-ul",
          "far fa-litecoin-sign",
          "far fa-loader",
          "far fa-lobster",
          "far fa-location-arrow",
          "far fa-location-check",
          "far fa-location-crosshairs",
          "far fa-location-crosshairs-slash",
          "far fa-location-dot",
          "far fa-location-dot-slash",
          "far fa-location-exclamation",
          "far fa-location-minus",
          "far fa-location-pen",
          "far fa-location-pin",
          "far fa-location-pin-lock",
          "far fa-location-pin-slash",
          "far fa-location-plus",
          "far fa-location-question",
          "far fa-location-smile",
          "far fa-location-xmark",
          "far fa-lock",
          "far fa-lock-a",
          "far fa-lock-hashtag",
          "far fa-lock-keyhole",
          "far fa-lock-keyhole-open",
          "far fa-lock-open",
          "far fa-locust",
          "far fa-lollipop",
          "far fa-loveseat",
          "far fa-luchador-mask",
          "far fa-lungs",
          "far fa-lungs-virus",
          "far fa-m",
          "far fa-mace",
          "far fa-magnet",
          "far fa-magnifying-glass",
          "far fa-magnifying-glass-arrow-right",
          "far fa-magnifying-glass-chart",
          "far fa-magnifying-glass-dollar",
          "far fa-magnifying-glass-location",
          "far fa-magnifying-glass-minus",
          "far fa-magnifying-glass-plus",
          "far fa-mailbox",
          "far fa-mailbox-flag-up",
          "far fa-manat-sign",
          "far fa-mandolin",
          "far fa-mango",
          "far fa-manhole",
          "far fa-map",
          "far fa-map-location",
          "far fa-map-location-dot",
          "far fa-map-pin",
          "far fa-marker",
          "far fa-mars",
          "far fa-mars-and-venus",
          "far fa-mars-and-venus-burst",
          "far fa-mars-double",
          "far fa-mars-stroke",
          "far fa-mars-stroke-right",
          "far fa-mars-stroke-up",
          "far fa-martini-glass",
          "far fa-martini-glass-citrus",
          "far fa-martini-glass-empty",
          "far fa-mask",
          "far fa-mask-face",
          "far fa-mask-snorkel",
          "far fa-mask-ventilator",
          "far fa-masks-theater",
          "far fa-mattress-pillow",
          "far fa-maximize",
          "far fa-meat",
          "far fa-medal",
          "far fa-megaphone",
          "far fa-melon",
          "far fa-melon-slice",
          "far fa-memo",
          "far fa-memo-circle-check",
          "far fa-memo-circle-info",
          "far fa-memo-pad",
          "far fa-memory",
          "far fa-menorah",
          "far fa-mercury",
          "far fa-merge",
          "far fa-message",
          "far fa-message-arrow-down",
          "far fa-message-arrow-up",
          "far fa-message-arrow-up-right",
          "far fa-message-bot",
          "far fa-message-captions",
          "far fa-message-check",
          "far fa-message-code",
          "far fa-message-dollar",
          "far fa-message-dots",
          "far fa-message-exclamation",
          "far fa-message-heart",
          "far fa-message-image",
          "far fa-message-lines",
          "far fa-message-medical",
          "far fa-message-middle",
          "far fa-message-middle-top",
          "far fa-message-minus",
          "far fa-message-music",
          "far fa-message-pen",
          "far fa-message-plus",
          "far fa-message-question",
          "far fa-message-quote",
          "far fa-message-slash",
          "far fa-message-smile",
          "far fa-message-sms",
          "far fa-message-text",
          "far fa-message-xmark",
          "far fa-messages",
          "far fa-messages-dollar",
          "far fa-messages-question",
          "far fa-meteor",
          "far fa-meter",
          "far fa-meter-bolt",
          "far fa-meter-droplet",
          "far fa-meter-fire",
          "far fa-microchip",
          "far fa-microchip-ai",
          "far fa-microphone",
          "far fa-microphone-lines",
          "far fa-microphone-lines-slash",
          "far fa-microphone-slash",
          "far fa-microphone-stand",
          "far fa-microscope",
          "far fa-microwave",
          "far fa-mill-sign",
          "far fa-minimize",
          "far fa-minus",
          "far fa-mistletoe",
          "far fa-mitten",
          "far fa-mobile",
          "far fa-mobile-button",
          "far fa-mobile-notch",
          "far fa-mobile-retro",
          "far fa-mobile-screen",
          "far fa-mobile-screen-button",
          "far fa-mobile-signal",
          "far fa-mobile-signal-out",
          "far fa-money-bill",
          "far fa-money-bill-1",
          "far fa-money-bill-1-wave",
          "far fa-money-bill-simple",
          "far fa-money-bill-simple-wave",
          "far fa-money-bill-transfer",
          "far fa-money-bill-trend-up",
          "far fa-money-bill-wave",
          "far fa-money-bill-wheat",
          "far fa-money-bills",
          "far fa-money-bills-simple",
          "far fa-money-check",
          "far fa-money-check-dollar",
          "far fa-money-check-dollar-pen",
          "far fa-money-check-pen",
          "far fa-money-from-bracket",
          "far fa-money-simple-from-bracket",
          "far fa-monitor-waveform",
          "far fa-monkey",
          "far fa-monument",
          "far fa-moon",
          "far fa-moon-cloud",
          "far fa-moon-over-sun",
          "far fa-moon-stars",
          "far fa-moped",
          "far fa-mortar-pestle",
          "far fa-mosque",
          "far fa-mosquito",
          "far fa-mosquito-net",
          "far fa-motorcycle",
          "far fa-mound",
          "far fa-mountain",
          "far fa-mountain-city",
          "far fa-mountain-sun",
          "far fa-mountains",
          "far fa-mouse-field",
          "far fa-mp3-player",
          "far fa-mug",
          "far fa-mug-hot",
          "far fa-mug-marshmallows",
          "far fa-mug-saucer",
          "far fa-mug-tea",
          "far fa-mug-tea-saucer",
          "far fa-mushroom",
          "far fa-music",
          "far fa-music-note",
          "far fa-music-note-slash",
          "far fa-music-slash",
          "far fa-mustache",
          "far fa-n",
          "far fa-naira-sign",
          "far fa-narwhal",
          "far fa-nesting-dolls",
          "far fa-network-wired",
          "far fa-neuter",
          "far fa-newspaper",
          "far fa-nfc",
          "far fa-nfc-lock",
          "far fa-nfc-magnifying-glass",
          "far fa-nfc-pen",
          "far fa-nfc-signal",
          "far fa-nfc-slash",
          "far fa-nfc-symbol",
          "far fa-nfc-trash",
          "far fa-nose",
          "far fa-not-equal",
          "far fa-notdef",
          "far fa-note",
          "far fa-note-medical",
          "far fa-note-sticky",
          "far fa-notebook",
          "far fa-notes",
          "far fa-notes-medical",
          "far fa-o",
          "far fa-object-exclude",
          "far fa-object-group",
          "far fa-object-intersect",
          "far fa-object-subtract",
          "far fa-object-ungroup",
          "far fa-object-union",
          "far fa-objects-align-bottom",
          "far fa-objects-align-center-horizontal",
          "far fa-objects-align-center-vertical",
          "far fa-objects-align-left",
          "far fa-objects-align-right",
          "far fa-objects-align-top",
          "far fa-objects-column",
          "far fa-octagon",
          "far fa-octagon-check",
          "far fa-octagon-divide",
          "far fa-octagon-exclamation",
          "far fa-octagon-minus",
          "far fa-octagon-plus",
          "far fa-octagon-xmark",
          "far fa-oil-can",
          "far fa-oil-can-drip",
          "far fa-oil-temperature",
          "far fa-oil-well",
          "far fa-olive",
          "far fa-olive-branch",
          "far fa-om",
          "far fa-omega",
          "far fa-onion",
          "far fa-option",
          "far fa-ornament",
          "far fa-otter",
          "far fa-outdent",
          "far fa-outlet",
          "far fa-oven",
          "far fa-overline",
          "far fa-p",
          "far fa-page",
          "far fa-page-caret-down",
          "far fa-page-caret-up",
          "far fa-pager",
          "far fa-paint-roller",
          "far fa-paintbrush",
          "far fa-paintbrush-fine",
          "far fa-paintbrush-pencil",
          "far fa-palette",
          "far fa-pallet",
          "far fa-pallet-box",
          "far fa-pallet-boxes",
          "far fa-pan-food",
          "far fa-pan-frying",
          "far fa-pancakes",
          "far fa-panel-ews",
          "far fa-panel-fire",
          "far fa-panorama",
          "far fa-paper-plane",
          "far fa-paper-plane-top",
          "far fa-paperclip",
          "far fa-paperclip-vertical",
          "far fa-parachute-box",
          "far fa-paragraph",
          "far fa-paragraph-left",
          "far fa-party-bell",
          "far fa-party-horn",
          "far fa-passport",
          "far fa-paste",
          "far fa-pause",
          "far fa-paw",
          "far fa-paw-claws",
          "far fa-paw-simple",
          "far fa-peace",
          "far fa-peach",
          "far fa-peanut",
          "far fa-peanuts",
          "far fa-peapod",
          "far fa-pear",
          "far fa-pedestal",
          "far fa-pegasus",
          "far fa-pen",
          "far fa-pen-circle",
          "far fa-pen-clip",
          "far fa-pen-clip-slash",
          "far fa-pen-fancy",
          "far fa-pen-fancy-slash",
          "far fa-pen-field",
          "far fa-pen-line",
          "far fa-pen-nib",
          "far fa-pen-nib-slash",
          "far fa-pen-paintbrush",
          "far fa-pen-ruler",
          "far fa-pen-slash",
          "far fa-pen-swirl",
          "far fa-pen-to-square",
          "far fa-pencil",
          "far fa-pencil-mechanical",
          "far fa-pencil-slash",
          "far fa-people",
          "far fa-people-arrows",
          "far fa-people-carry-box",
          "far fa-people-dress",
          "far fa-people-dress-simple",
          "far fa-people-group",
          "far fa-people-line",
          "far fa-people-pants",
          "far fa-people-pants-simple",
          "far fa-people-pulling",
          "far fa-people-robbery",
          "far fa-people-roof",
          "far fa-people-simple",
          "far fa-pepper",
          "far fa-pepper-hot",
          "far fa-percent",
          "far fa-period",
          "far fa-person",
          "far fa-person-arrow-down-to-line",
          "far fa-person-arrow-up-from-line",
          "far fa-person-biking",
          "far fa-person-biking-mountain",
          "far fa-person-booth",
          "far fa-person-breastfeeding",
          "far fa-person-burst",
          "far fa-person-cane",
          "far fa-person-carry-box",
          "far fa-person-chalkboard",
          "far fa-person-circle-check",
          "far fa-person-circle-exclamation",
          "far fa-person-circle-minus",
          "far fa-person-circle-plus",
          "far fa-person-circle-question",
          "far fa-person-circle-xmark",
          "far fa-person-digging",
          "far fa-person-dolly",
          "far fa-person-dolly-empty",
          "far fa-person-dots-from-line",
          "far fa-person-dress",
          "far fa-person-dress-burst",
          "far fa-person-dress-simple",
          "far fa-person-drowning",
          "far fa-person-falling",
          "far fa-person-falling-burst",
          "far fa-person-from-portal",
          "far fa-person-half-dress",
          "far fa-person-harassing",
          "far fa-person-hiking",
          "far fa-person-military-pointing",
          "far fa-person-military-rifle",
          "far fa-person-military-to-person",
          "far fa-person-pinball",
          "far fa-person-praying",
          "far fa-person-pregnant",
          "far fa-person-rays",
          "far fa-person-rifle",
          "far fa-person-running",
          "far fa-person-seat",
          "far fa-person-seat-reclined",
          "far fa-person-shelter",
          "far fa-person-sign",
          "far fa-person-simple",
          "far fa-person-skating",
          "far fa-person-ski-jumping",
          "far fa-person-ski-lift",
          "far fa-person-skiing",
          "far fa-person-skiing-nordic",
          "far fa-person-sledding",
          "far fa-person-snowboarding",
          "far fa-person-snowmobiling",
          "far fa-person-swimming",
          "far fa-person-through-window",
          "far fa-person-to-door",
          "far fa-person-to-portal",
          "far fa-person-walking",
          "far fa-person-walking-arrow-loop-left",
          "far fa-person-walking-arrow-right",
          "far fa-person-walking-dashed-line-arrow-right",
          "far fa-person-walking-luggage",
          "far fa-person-walking-with-cane",
          "far fa-peseta-sign",
          "far fa-peso-sign",
          "far fa-phone",
          "far fa-phone-arrow-down-left",
          "far fa-phone-arrow-right",
          "far fa-phone-arrow-up-right",
          "far fa-phone-flip",
          "far fa-phone-hangup",
          "far fa-phone-intercom",
          "far fa-phone-missed",
          "far fa-phone-office",
          "far fa-phone-plus",
          "far fa-phone-rotary",
          "far fa-phone-slash",
          "far fa-phone-volume",
          "far fa-phone-xmark",
          "far fa-photo-film",
          "far fa-photo-film-music",
          "far fa-pi",
          "far fa-piano",
          "far fa-piano-keyboard",
          "far fa-pickaxe",
          "far fa-pickleball",
          "far fa-pie",
          "far fa-pig",
          "far fa-piggy-bank",
          "far fa-pills",
          "far fa-pinata",
          "far fa-pinball",
          "far fa-pineapple",
          "far fa-pipe",
          "far fa-pipe-circle-check",
          "far fa-pipe-collar",
          "far fa-pipe-section",
          "far fa-pipe-smoking",
          "far fa-pipe-valve",
          "far fa-pizza",
          "far fa-pizza-slice",
          "far fa-place-of-worship",
          "far fa-plane",
          "far fa-plane-arrival",
          "far fa-plane-circle-check",
          "far fa-plane-circle-exclamation",
          "far fa-plane-circle-xmark",
          "far fa-plane-departure",
          "far fa-plane-engines",
          "far fa-plane-lock",
          "far fa-plane-prop",
          "far fa-plane-slash",
          "far fa-plane-tail",
          "far fa-plane-up",
          "far fa-plane-up-slash",
          "far fa-planet-moon",
          "far fa-planet-ringed",
          "far fa-plant-wilt",
          "far fa-plate-utensils",
          "far fa-plate-wheat",
          "far fa-play",
          "far fa-play-pause",
          "far fa-plug",
          "far fa-plug-circle-bolt",
          "far fa-plug-circle-check",
          "far fa-plug-circle-exclamation",
          "far fa-plug-circle-minus",
          "far fa-plug-circle-plus",
          "far fa-plug-circle-xmark",
          "far fa-plus",
          "far fa-plus-large",
          "far fa-plus-minus",
          "far fa-podcast",
          "far fa-podium",
          "far fa-podium-star",
          "far fa-police-box",
          "far fa-poll-people",
          "far fa-pompebled",
          "far fa-poo",
          "far fa-poo-storm",
          "far fa-pool-8-ball",
          "far fa-poop",
          "far fa-popcorn",
          "far fa-popsicle",
          "far fa-pot-food",
          "far fa-potato",
          "far fa-power-off",
          "far fa-prescription",
          "far fa-prescription-bottle",
          "far fa-prescription-bottle-medical",
          "far fa-prescription-bottle-pill",
          "far fa-presentation-screen",
          "far fa-pretzel",
          "far fa-print",
          "far fa-print-magnifying-glass",
          "far fa-print-slash",
          "far fa-projector",
          "far fa-pump",
          "far fa-pump-medical",
          "far fa-pump-soap",
          "far fa-pumpkin",
          "far fa-puzzle",
          "far fa-puzzle-piece",
          "far fa-puzzle-piece-simple",
          "far fa-q",
          "far fa-qrcode",
          "far fa-question",
          "far fa-quote-left",
          "far fa-quote-right",
          "far fa-quotes",
          "far fa-r",
          "far fa-rabbit",
          "far fa-rabbit-running",
          "far fa-racquet",
          "far fa-radar",
          "far fa-radiation",
          "far fa-radio",
          "far fa-radio-tuner",
          "far fa-rainbow",
          "far fa-raindrops",
          "far fa-ram",
          "far fa-ramp-loading",
          "far fa-ranking-star",
          "far fa-raygun",
          "far fa-receipt",
          "far fa-record-vinyl",
          "far fa-rectangle",
          "far fa-rectangle-ad",
          "far fa-rectangle-barcode",
          "far fa-rectangle-code",
          "far fa-rectangle-history",
          "far fa-rectangle-history-circle-plus",
          "far fa-rectangle-history-circle-user",
          "far fa-rectangle-list",
          "far fa-rectangle-pro",
          "far fa-rectangle-terminal",
          "far fa-rectangle-vertical",
          "far fa-rectangle-vertical-history",
          "far fa-rectangle-wide",
          "far fa-rectangle-xmark",
          "far fa-rectangles-mixed",
          "far fa-recycle",
          "far fa-reel",
          "far fa-refrigerator",
          "far fa-registered",
          "far fa-repeat",
          "far fa-repeat-1",
          "far fa-reply",
          "far fa-reply-all",
          "far fa-reply-clock",
          "far fa-republican",
          "far fa-restroom",
          "far fa-restroom-simple",
          "far fa-retweet",
          "far fa-rhombus",
          "far fa-ribbon",
          "far fa-right",
          "far fa-right-from-bracket",
          "far fa-right-from-line",
          "far fa-right-left",
          "far fa-right-long",
          "far fa-right-long-to-line",
          "far fa-right-to-bracket",
          "far fa-right-to-line",
          "far fa-ring",
          "far fa-ring-diamond",
          "far fa-rings-wedding",
          "far fa-road",
          "far fa-road-barrier",
          "far fa-road-bridge",
          "far fa-road-circle-check",
          "far fa-road-circle-exclamation",
          "far fa-road-circle-xmark",
          "far fa-road-lock",
          "far fa-road-spikes",
          "far fa-robot",
          "far fa-robot-astromech",
          "far fa-rocket",
          "far fa-rocket-launch",
          "far fa-roller-coaster",
          "far fa-rotate",
          "far fa-rotate-exclamation",
          "far fa-rotate-left",
          "far fa-rotate-right",
          "far fa-route",
          "far fa-route-highway",
          "far fa-route-interstate",
          "far fa-router",
          "far fa-rss",
          "far fa-ruble-sign",
          "far fa-rug",
          "far fa-rugby-ball",
          "far fa-ruler",
          "far fa-ruler-combined",
          "far fa-ruler-horizontal",
          "far fa-ruler-triangle",
          "far fa-ruler-vertical",
          "far fa-rupee-sign",
          "far fa-rupiah-sign",
          "far fa-rv",
          "far fa-s",
          "far fa-sack",
          "far fa-sack-dollar",
          "far fa-sack-xmark",
          "far fa-sailboat",
          "far fa-salad",
          "far fa-salt-shaker",
          "far fa-sandwich",
          "far fa-satellite",
          "far fa-satellite-dish",
          "far fa-sausage",
          "far fa-saxophone",
          "far fa-saxophone-fire",
          "far fa-scale-balanced",
          "far fa-scale-unbalanced",
          "far fa-scale-unbalanced-flip",
          "far fa-scalpel",
          "far fa-scalpel-line-dashed",
          "far fa-scanner-gun",
          "far fa-scanner-image",
          "far fa-scanner-keyboard",
          "far fa-scanner-touchscreen",
          "far fa-scarecrow",
          "far fa-scarf",
          "far fa-school",
          "far fa-school-circle-check",
          "far fa-school-circle-exclamation",
          "far fa-school-circle-xmark",
          "far fa-school-flag",
          "far fa-school-lock",
          "far fa-scissors",
          "far fa-screen-users",
          "far fa-screencast",
          "far fa-screwdriver",
          "far fa-screwdriver-wrench",
          "far fa-scribble",
          "far fa-scroll",
          "far fa-scroll-old",
          "far fa-scroll-torah",
          "far fa-scrubber",
          "far fa-scythe",
          "far fa-sd-card",
          "far fa-sd-cards",
          "far fa-seal",
          "far fa-seal-exclamation",
          "far fa-seal-question",
          "far fa-seat-airline",
          "far fa-section",
          "far fa-seedling",
          "far fa-semicolon",
          "far fa-send-back",
          "far fa-send-backward",
          "far fa-sensor",
          "far fa-sensor-cloud",
          "far fa-sensor-fire",
          "far fa-sensor-on",
          "far fa-sensor-triangle-exclamation",
          "far fa-server",
          "far fa-shapes",
          "far fa-share",
          "far fa-share-all",
          "far fa-share-from-square",
          "far fa-share-nodes",
          "far fa-sheep",
          "far fa-sheet-plastic",
          "far fa-shekel-sign",
          "far fa-shelves",
          "far fa-shelves-empty",
          "far fa-shield",
          "far fa-shield-cat",
          "far fa-shield-check",
          "far fa-shield-cross",
          "far fa-shield-dog",
          "far fa-shield-exclamation",
          "far fa-shield-halved",
          "far fa-shield-heart",
          "far fa-shield-keyhole",
          "far fa-shield-minus",
          "far fa-shield-plus",
          "far fa-shield-quartered",
          "far fa-shield-slash",
          "far fa-shield-virus",
          "far fa-shield-xmark",
          "far fa-ship",
          "far fa-shirt",
          "far fa-shirt-long-sleeve",
          "far fa-shirt-running",
          "far fa-shirt-tank-top",
          "far fa-shish-kebab",
          "far fa-shoe-prints",
          "far fa-shop",
          "far fa-shop-lock",
          "far fa-shop-slash",
          "far fa-shovel",
          "far fa-shovel-snow",
          "far fa-shower",
          "far fa-shower-down",
          "far fa-shredder",
          "far fa-shrimp",
          "far fa-shuffle",
          "far fa-shutters",
          "far fa-shuttle-space",
          "far fa-shuttlecock",
          "far fa-sickle",
          "far fa-sidebar",
          "far fa-sidebar-flip",
          "far fa-sigma",
          "far fa-sign-hanging",
          "far fa-signal",
          "far fa-signal-bars",
          "far fa-signal-bars-fair",
          "far fa-signal-bars-good",
          "far fa-signal-bars-slash",
          "far fa-signal-bars-weak",
          "far fa-signal-fair",
          "far fa-signal-good",
          "far fa-signal-slash",
          "far fa-signal-stream",
          "far fa-signal-stream-slash",
          "far fa-signal-strong",
          "far fa-signal-weak",
          "far fa-signature",
          "far fa-signature-lock",
          "far fa-signature-slash",
          "far fa-signs-post",
          "far fa-sim-card",
          "far fa-sim-cards",
          "far fa-sink",
          "far fa-siren",
          "far fa-siren-on",
          "far fa-sitemap",
          "far fa-skeleton",
          "far fa-skeleton-ribs",
          "far fa-ski-boot",
          "far fa-ski-boot-ski",
          "far fa-skull",
          "far fa-skull-cow",
          "far fa-skull-crossbones",
          "far fa-slash",
          "far fa-slash-back",
          "far fa-slash-forward",
          "far fa-sleigh",
          "far fa-slider",
          "far fa-sliders",
          "far fa-sliders-simple",
          "far fa-sliders-up",
          "far fa-slot-machine",
          "far fa-smog",
          "far fa-smoke",
          "far fa-smoking",
          "far fa-snake",
          "far fa-snooze",
          "far fa-snow-blowing",
          "far fa-snowflake",
          "far fa-snowflake-droplets",
          "far fa-snowflakes",
          "far fa-snowman",
          "far fa-snowman-head",
          "far fa-snowplow",
          "far fa-soap",
          "far fa-socks",
          "far fa-soft-serve",
          "far fa-solar-panel",
          "far fa-solar-system",
          "far fa-sort",
          "far fa-sort-down",
          "far fa-sort-up",
          "far fa-spa",
          "far fa-space-station-moon",
          "far fa-space-station-moon-construction",
          "far fa-spade",
          "far fa-spaghetti-monster-flying",
          "far fa-sparkle",
          "far fa-sparkles",
          "far fa-speaker",
          "far fa-speakers",
          "far fa-spell-check",
          "far fa-spider",
          "far fa-spider-black-widow",
          "far fa-spider-web",
          "far fa-spinner",
          "far fa-spinner-third",
          "far fa-split",
          "far fa-splotch",
          "far fa-spoon",
          "far fa-sportsball",
          "far fa-spray-can",
          "far fa-spray-can-sparkles",
          "far fa-sprinkler",
          "far fa-sprinkler-ceiling",
          "far fa-square",
          "far fa-square-0",
          "far fa-square-1",
          "far fa-square-2",
          "far fa-square-3",
          "far fa-square-4",
          "far fa-square-5",
          "far fa-square-6",
          "far fa-square-7",
          "far fa-square-8",
          "far fa-square-9",
          "far fa-square-a",
          "far fa-square-a-lock",
          "far fa-square-ampersand",
          "far fa-square-arrow-down",
          "far fa-square-arrow-down-left",
          "far fa-square-arrow-down-right",
          "far fa-square-arrow-left",
          "far fa-square-arrow-right",
          "far fa-square-arrow-up",
          "far fa-square-arrow-up-left",
          "far fa-square-arrow-up-right",
          "far fa-square-b",
          "far fa-square-bolt",
          "far fa-square-c",
          "far fa-square-caret-down",
          "far fa-square-caret-left",
          "far fa-square-caret-right",
          "far fa-square-caret-up",
          "far fa-square-check",
          "far fa-square-chevron-down",
          "far fa-square-chevron-left",
          "far fa-square-chevron-right",
          "far fa-square-chevron-up",
          "far fa-square-code",
          "far fa-square-d",
          "far fa-square-dashed",
          "far fa-square-dashed-circle-plus",
          "far fa-square-divide",
          "far fa-square-dollar",
          "far fa-square-down",
          "far fa-square-down-left",
          "far fa-square-down-right",
          "far fa-square-e",
          "far fa-square-ellipsis",
          "far fa-square-ellipsis-vertical",
          "far fa-square-envelope",
          "far fa-square-exclamation",
          "far fa-square-f",
          "far fa-square-fragile",
          "far fa-square-full",
          "far fa-square-g",
          "far fa-square-h",
          "far fa-square-heart",
          "far fa-square-i",
          "far fa-square-info",
          "far fa-square-j",
          "far fa-square-k",
          "far fa-square-kanban",
          "far fa-square-l",
          "far fa-square-left",
          "far fa-square-list",
          "far fa-square-m",
          "far fa-square-minus",
          "far fa-square-n",
          "far fa-square-nfi",
          "far fa-square-o",
          "far fa-square-p",
          "far fa-square-parking",
          "far fa-square-parking-slash",
          "far fa-square-pen",
          "far fa-square-person-confined",
          "far fa-square-phone",
          "far fa-square-phone-flip",
          "far fa-square-phone-hangup",
          "far fa-square-plus",
          "far fa-square-poll-horizontal",
          "far fa-square-poll-vertical",
          "far fa-square-q",
          "far fa-square-quarters",
          "far fa-square-question",
          "far fa-square-quote",
          "far fa-square-r",
          "far fa-square-right",
          "far fa-square-ring",
          "far fa-square-root",
          "far fa-square-root-variable",
          "far fa-square-rss",
          "far fa-square-s",
          "far fa-square-share-nodes",
          "far fa-square-sliders",
          "far fa-square-sliders-vertical",
          "far fa-square-small",
          "far fa-square-star",
          "far fa-square-t",
          "far fa-square-terminal",
          "far fa-square-this-way-up",
          "far fa-square-u",
          "far fa-square-up",
          "far fa-square-up-left",
          "far fa-square-up-right",
          "far fa-square-user",
          "far fa-square-v",
          "far fa-square-virus",
          "far fa-square-w",
          "far fa-square-x",
          "far fa-square-xmark",
          "far fa-square-y",
          "far fa-square-z",
          "far fa-squid",
          "far fa-squirrel",
          "far fa-staff",
          "far fa-staff-snake",
          "far fa-stairs",
          "far fa-stamp",
          "far fa-standard-definition",
          "far fa-stapler",
          "far fa-star",
          "far fa-star-and-crescent",
          "far fa-star-christmas",
          "far fa-star-exclamation",
          "far fa-star-half",
          "far fa-star-half-stroke",
          "far fa-star-of-david",
          "far fa-star-of-life",
          "far fa-star-sharp",
          "far fa-star-sharp-half",
          "far fa-star-sharp-half-stroke",
          "far fa-star-shooting",
          "far fa-starfighter",
          "far fa-starfighter-twin-ion-engine",
          "far fa-starfighter-twin-ion-engine-advanced",
          "far fa-stars",
          "far fa-starship",
          "far fa-starship-freighter",
          "far fa-steak",
          "far fa-steering-wheel",
          "far fa-sterling-sign",
          "far fa-stethoscope",
          "far fa-stocking",
          "far fa-stomach",
          "far fa-stop",
          "far fa-stopwatch",
          "far fa-stopwatch-20",
          "far fa-store",
          "far fa-store-lock",
          "far fa-store-slash",
          "far fa-strawberry",
          "far fa-street-view",
          "far fa-stretcher",
          "far fa-strikethrough",
          "far fa-stroopwafel",
          "far fa-subscript",
          "far fa-suitcase",
          "far fa-suitcase-medical",
          "far fa-suitcase-rolling",
          "far fa-sun",
          "far fa-sun-bright",
          "far fa-sun-cloud",
          "far fa-sun-dust",
          "far fa-sun-haze",
          "far fa-sun-plant-wilt",
          "far fa-sunglasses",
          "far fa-sunrise",
          "far fa-sunset",
          "far fa-superscript",
          "far fa-sushi",
          "far fa-sushi-roll",
          "far fa-swatchbook",
          "far fa-sword",
          "far fa-sword-laser",
          "far fa-sword-laser-alt",
          "far fa-swords",
          "far fa-swords-laser",
          "far fa-symbols",
          "far fa-synagogue",
          "far fa-syringe",
          "far fa-t",
          "far fa-table",
          "far fa-table-cells",
          "far fa-table-cells-large",
          "far fa-table-columns",
          "far fa-table-layout",
          "far fa-table-list",
          "far fa-table-picnic",
          "far fa-table-pivot",
          "far fa-table-rows",
          "far fa-table-tennis-paddle-ball",
          "far fa-table-tree",
          "far fa-tablet",
          "far fa-tablet-button",
          "far fa-tablet-rugged",
          "far fa-tablet-screen",
          "far fa-tablet-screen-button",
          "far fa-tablets",
          "far fa-tachograph-digital",
          "far fa-taco",
          "far fa-tag",
          "far fa-tags",
          "far fa-tally",
          "far fa-tally-1",
          "far fa-tally-2",
          "far fa-tally-3",
          "far fa-tally-4",
          "far fa-tamale",
          "far fa-tank-water",
          "far fa-tape",
          "far fa-tarp",
          "far fa-tarp-droplet",
          "far fa-taxi",
          "far fa-taxi-bus",
          "far fa-teddy-bear",
          "far fa-teeth",
          "far fa-teeth-open",
          "far fa-telescope",
          "far fa-temperature-arrow-down",
          "far fa-temperature-arrow-up",
          "far fa-temperature-empty",
          "far fa-temperature-full",
          "far fa-temperature-half",
          "far fa-temperature-high",
          "far fa-temperature-list",
          "far fa-temperature-low",
          "far fa-temperature-quarter",
          "far fa-temperature-snow",
          "far fa-temperature-sun",
          "far fa-temperature-three-quarters",
          "far fa-tenge-sign",
          "far fa-tennis-ball",
          "far fa-tent",
          "far fa-tent-arrow-down-to-line",
          "far fa-tent-arrow-left-right",
          "far fa-tent-arrow-turn-left",
          "far fa-tent-arrows-down",
          "far fa-tents",
          "far fa-terminal",
          "far fa-text",
          "far fa-text-height",
          "far fa-text-size",
          "far fa-text-slash",
          "far fa-text-width",
          "far fa-thermometer",
          "far fa-theta",
          "far fa-thought-bubble",
          "far fa-thumbs-down",
          "far fa-thumbs-up",
          "far fa-thumbtack",
          "far fa-tick",
          "far fa-ticket",
          "far fa-ticket-airline",
          "far fa-ticket-simple",
          "far fa-tickets-airline",
          "far fa-tilde",
          "far fa-timeline",
          "far fa-timeline-arrow",
          "far fa-timer",
          "far fa-tire",
          "far fa-tire-flat",
          "far fa-tire-pressure-warning",
          "far fa-tire-rugged",
          "far fa-toggle-large-off",
          "far fa-toggle-large-on",
          "far fa-toggle-off",
          "far fa-toggle-on",
          "far fa-toilet",
          "far fa-toilet-paper",
          "far fa-toilet-paper-blank",
          "far fa-toilet-paper-blank-under",
          "far fa-toilet-paper-check",
          "far fa-toilet-paper-slash",
          "far fa-toilet-paper-under",
          "far fa-toilet-paper-under-slash",
          "far fa-toilet-paper-xmark",
          "far fa-toilet-portable",
          "far fa-toilets-portable",
          "far fa-tomato",
          "far fa-tombstone",
          "far fa-tombstone-blank",
          "far fa-toolbox",
          "far fa-tooth",
          "far fa-toothbrush",
          "far fa-torii-gate",
          "far fa-tornado",
          "far fa-tower-broadcast",
          "far fa-tower-cell",
          "far fa-tower-control",
          "far fa-tower-observation",
          "far fa-tractor",
          "far fa-trademark",
          "far fa-traffic-cone",
          "far fa-traffic-light",
          "far fa-traffic-light-go",
          "far fa-traffic-light-slow",
          "far fa-traffic-light-stop",
          "far fa-trailer",
          "far fa-train",
          "far fa-train-subway",
          "far fa-train-subway-tunnel",
          "far fa-train-track",
          "far fa-train-tram",
          "far fa-train-tunnel",
          "far fa-transformer-bolt",
          "far fa-transgender",
          "far fa-transporter",
          "far fa-transporter-1",
          "far fa-transporter-2",
          "far fa-transporter-3",
          "far fa-transporter-4",
          "far fa-transporter-5",
          "far fa-transporter-6",
          "far fa-transporter-7",
          "far fa-transporter-empty",
          "far fa-trash",
          "far fa-trash-arrow-up",
          "far fa-trash-can",
          "far fa-trash-can-arrow-up",
          "far fa-trash-can-check",
          "far fa-trash-can-clock",
          "far fa-trash-can-list",
          "far fa-trash-can-plus",
          "far fa-trash-can-slash",
          "far fa-trash-can-undo",
          "far fa-trash-can-xmark",
          "far fa-trash-check",
          "far fa-trash-clock",
          "far fa-trash-list",
          "far fa-trash-plus",
          "far fa-trash-slash",
          "far fa-trash-undo",
          "far fa-trash-xmark",
          "far fa-treasure-chest",
          "far fa-tree",
          "far fa-tree-christmas",
          "far fa-tree-city",
          "far fa-tree-deciduous",
          "far fa-tree-decorated",
          "far fa-tree-large",
          "far fa-tree-palm",
          "far fa-trees",
          "far fa-triangle",
          "far fa-triangle-exclamation",
          "far fa-triangle-instrument",
          "far fa-triangle-person-digging",
          "far fa-tricycle",
          "far fa-tricycle-adult",
          "far fa-trillium",
          "far fa-trophy",
          "far fa-trophy-star",
          "far fa-trowel",
          "far fa-trowel-bricks",
          "far fa-truck",
          "far fa-truck-arrow-right",
          "far fa-truck-bolt",
          "far fa-truck-clock",
          "far fa-truck-container",
          "far fa-truck-container-empty",
          "far fa-truck-droplet",
          "far fa-truck-fast",
          "far fa-truck-field",
          "far fa-truck-field-un",
          "far fa-truck-flatbed",
          "far fa-truck-front",
          "far fa-truck-medical",
          "far fa-truck-monster",
          "far fa-truck-moving",
          "far fa-truck-pickup",
          "far fa-truck-plane",
          "far fa-truck-plow",
          "far fa-truck-ramp",
          "far fa-truck-ramp-box",
          "far fa-truck-ramp-couch",
          "far fa-truck-tow",
          "far fa-trumpet",
          "far fa-tty",
          "far fa-tty-answer",
          "far fa-tugrik-sign",
          "far fa-turkey",
          "far fa-turkish-lira-sign",
          "far fa-turn-down",
          "far fa-turn-down-left",
          "far fa-turn-down-right",
          "far fa-turn-up",
          "far fa-turntable",
          "far fa-turtle",
          "far fa-tv",
          "far fa-tv-music",
          "far fa-tv-retro",
          "far fa-typewriter",
          "far fa-u",
          "far fa-ufo",
          "far fa-ufo-beam",
          "far fa-umbrella",
          "far fa-umbrella-beach",
          "far fa-umbrella-simple",
          "far fa-underline",
          "far fa-unicorn",
          "far fa-uniform-martial-arts",
          "far fa-union",
          "far fa-universal-access",
          "far fa-unlock",
          "far fa-unlock-keyhole",
          "far fa-up",
          "far fa-up-down",
          "far fa-up-down-left-right",
          "far fa-up-from-bracket",
          "far fa-up-from-dotted-line",
          "far fa-up-from-line",
          "far fa-up-left",
          "far fa-up-long",
          "far fa-up-right",
          "far fa-up-right-and-down-left-from-center",
          "far fa-up-right-from-square",
          "far fa-up-to-dotted-line",
          "far fa-up-to-line",
          "far fa-upload",
          "far fa-usb-drive",
          "far fa-user",
          "far fa-user-alien",
          "far fa-user-astronaut",
          "far fa-user-bounty-hunter",
          "far fa-user-check",
          "far fa-user-chef",
          "far fa-user-clock",
          "far fa-user-cowboy",
          "far fa-user-crown",
          "far fa-user-doctor",
          "far fa-user-doctor-hair",
          "far fa-user-doctor-hair-long",
          "far fa-user-doctor-message",
          "far fa-user-gear",
          "far fa-user-graduate",
          "far fa-user-group",
          "far fa-user-group-crown",
          "far fa-user-hair",
          "far fa-user-hair-buns",
          "far fa-user-hair-long",
          "far fa-user-hair-mullet",
          "far fa-user-headset",
          "far fa-user-helmet-safety",
          "far fa-user-injured",
          "far fa-user-large",
          "far fa-user-large-slash",
          "far fa-user-lock",
          "far fa-user-magnifying-glass",
          "far fa-user-minus",
          "far fa-user-music",
          "far fa-user-ninja",
          "far fa-user-nurse",
          "far fa-user-nurse-hair",
          "far fa-user-nurse-hair-long",
          "far fa-user-pen",
          "far fa-user-pilot",
          "far fa-user-pilot-tie",
          "far fa-user-plus",
          "far fa-user-police",
          "far fa-user-police-tie",
          "far fa-user-robot",
          "far fa-user-robot-xmarks",
          "far fa-user-secret",
          "far fa-user-shakespeare",
          "far fa-user-shield",
          "far fa-user-slash",
          "far fa-user-tag",
          "far fa-user-tie",
          "far fa-user-tie-hair",
          "far fa-user-tie-hair-long",
          "far fa-user-unlock",
          "far fa-user-visor",
          "far fa-user-vneck",
          "far fa-user-vneck-hair",
          "far fa-user-vneck-hair-long",
          "far fa-user-xmark",
          "far fa-users",
          "far fa-users-between-lines",
          "far fa-users-gear",
          "far fa-users-line",
          "far fa-users-medical",
          "far fa-users-rays",
          "far fa-users-rectangle",
          "far fa-users-slash",
          "far fa-users-viewfinder",
          "far fa-utensils",
          "far fa-utensils-slash",
          "far fa-utility-pole",
          "far fa-utility-pole-double",
          "far fa-v",
          "far fa-vacuum",
          "far fa-vacuum-robot",
          "far fa-value-absolute",
          "far fa-van-shuttle",
          "far fa-vault",
          "far fa-vector-circle",
          "far fa-vector-polygon",
          "far fa-vector-square",
          "far fa-vent-damper",
          "far fa-venus",
          "far fa-venus-double",
          "far fa-venus-mars",
          "far fa-vest",
          "far fa-vest-patches",
          "far fa-vial",
          "far fa-vial-circle-check",
          "far fa-vial-virus",
          "far fa-vials",
          "far fa-video",
          "far fa-video-arrow-down-left",
          "far fa-video-arrow-up-right",
          "far fa-video-plus",
          "far fa-video-slash",
          "far fa-vihara",
          "far fa-violin",
          "far fa-virus",
          "far fa-virus-covid",
          "far fa-virus-covid-slash",
          "far fa-virus-slash",
          "far fa-viruses",
          "far fa-voicemail",
          "far fa-volcano",
          "far fa-volleyball",
          "far fa-volume",
          "far fa-volume-high",
          "far fa-volume-low",
          "far fa-volume-off",
          "far fa-volume-slash",
          "far fa-volume-xmark",
          "far fa-vr-cardboard",
          "far fa-w",
          "far fa-waffle",
          "far fa-wagon-covered",
          "far fa-walker",
          "far fa-walkie-talkie",
          "far fa-wallet",
          "far fa-wand",
          "far fa-wand-magic",
          "far fa-wand-magic-sparkles",
          "far fa-wand-sparkles",
          "far fa-warehouse",
          "far fa-warehouse-full",
          "far fa-washing-machine",
          "far fa-watch",
          "far fa-watch-apple",
          "far fa-watch-calculator",
          "far fa-watch-fitness",
          "far fa-watch-smart",
          "far fa-water",
          "far fa-water-arrow-down",
          "far fa-water-arrow-up",
          "far fa-water-ladder",
          "far fa-watermelon-slice",
          "far fa-wave-pulse",
          "far fa-wave-sine",
          "far fa-wave-square",
          "far fa-wave-triangle",
          "far fa-waveform",
          "far fa-waveform-lines",
          "far fa-webhook",
          "far fa-weight-hanging",
          "far fa-weight-scale",
          "far fa-whale",
          "far fa-wheat",
          "far fa-wheat-awn",
          "far fa-wheat-awn-circle-exclamation",
          "far fa-wheat-awn-slash",
          "far fa-wheat-slash",
          "far fa-wheelchair",
          "far fa-wheelchair-move",
          "far fa-whiskey-glass",
          "far fa-whiskey-glass-ice",
          "far fa-whistle",
          "far fa-wifi",
          "far fa-wifi-exclamation",
          "far fa-wifi-fair",
          "far fa-wifi-slash",
          "far fa-wifi-weak",
          "far fa-wind",
          "far fa-wind-turbine",
          "far fa-wind-warning",
          "far fa-window",
          "far fa-window-flip",
          "far fa-window-frame",
          "far fa-window-frame-open",
          "far fa-window-maximize",
          "far fa-window-minimize",
          "far fa-window-restore",
          "far fa-windsock",
          "far fa-wine-bottle",
          "far fa-wine-glass",
          "far fa-wine-glass-crack",
          "far fa-wine-glass-empty",
          "far fa-won-sign",
          "far fa-worm",
          "far fa-wreath",
          "far fa-wreath-laurel",
          "far fa-wrench",
          "far fa-wrench-simple",
          "far fa-x",
          "far fa-x-ray",
          "far fa-xmark",
          "far fa-xmark-large",
          "far fa-xmark-to-slot",
          "far fa-xmarks-lines",
          "far fa-y",
          "far fa-yen-sign",
          "far fa-yin-yang",
          "far fa-z"
        ]
      },
      solid: {
        title: 'Solid',
        prefix: 'fas fa-',
        iconstyle: 'fas',
        listicon: 'fas fa-solid fa-flag',        
        icons: [
          "fas fa-0",
          "fas fa-1",
          "fas fa-2",
          "fas fa-3",
          "fas fa-4",
          "fas fa-5",
          "fas fa-6",
          "fas fa-7",
          "fas fa-8",
          "fas fa-9",
          "fas fa-00",
          "fas fa-360-degrees",
          "fas fa-a",
          "fas fa-abacus",
          "fas fa-accent-grave",
          "fas fa-acorn",
          "fas fa-address-book",
          "fas fa-address-card",
          "fas fa-air-conditioner",
          "fas fa-airplay",
          "fas fa-alarm-clock",
          "fas fa-alarm-exclamation",
          "fas fa-alarm-plus",
          "fas fa-alarm-snooze",
          "fas fa-album",
          "fas fa-album-circle-plus",
          "fas fa-album-circle-user",
          "fas fa-album-collection",
          "fas fa-album-collection-circle-plus",
          "fas fa-album-collection-circle-user",
          "fas fa-alicorn",
          "fas fa-alien",
          "fas fa-alien-8bit",
          "fas fa-align-center",
          "fas fa-align-justify",
          "fas fa-align-left",
          "fas fa-align-right",
          "fas fa-align-slash",
          "fas fa-alt",
          "fas fa-amp-guitar",
          "fas fa-ampersand",
          "fas fa-anchor",
          "fas fa-anchor-circle-check",
          "fas fa-anchor-circle-exclamation",
          "fas fa-anchor-circle-xmark",
          "fas fa-anchor-lock",
          "fas fa-angel",
          "fas fa-angle",
          "fas fa-angle-90",
          "fas fa-angle-down",
          "fas fa-angle-left",
          "fas fa-angle-right",
          "fas fa-angle-up",
          "fas fa-angles-down",
          "fas fa-angles-left",
          "fas fa-angles-right",
          "fas fa-angles-up",
          "fas fa-ankh",
          "fas fa-apartment",
          "fas fa-aperture",
          "fas fa-apostrophe",
          "fas fa-apple-core",
          "fas fa-apple-whole",
          "fas fa-archway",
          "fas fa-arrow-down",
          "fas fa-arrow-down-1-9",
          "fas fa-arrow-down-9-1",
          "fas fa-arrow-down-a-z",
          "fas fa-arrow-down-arrow-up",
          "fas fa-arrow-down-big-small",
          "fas fa-arrow-down-from-dotted-line",
          "fas fa-arrow-down-from-line",
          "fas fa-arrow-down-left",
          "fas fa-arrow-down-left-and-arrow-up-right-to-center",
          "fas fa-arrow-down-long",
          "fas fa-arrow-down-right",
          "fas fa-arrow-down-short-wide",
          "fas fa-arrow-down-small-big",
          "fas fa-arrow-down-square-triangle",
          "fas fa-arrow-down-to-arc",
          "fas fa-arrow-down-to-bracket",
          "fas fa-arrow-down-to-dotted-line",
          "fas fa-arrow-down-to-line",
          "fas fa-arrow-down-to-square",
          "fas fa-arrow-down-triangle-square",
          "fas fa-arrow-down-up-across-line",
          "fas fa-arrow-down-up-lock",
          "fas fa-arrow-down-wide-short",
          "fas fa-arrow-down-z-a",
          "fas fa-arrow-left",
          "fas fa-arrow-left-from-line",
          "fas fa-arrow-left-long",
          "fas fa-arrow-left-long-to-line",
          "fas fa-arrow-left-to-line",
          "fas fa-arrow-pointer",
          "fas fa-arrow-right",
          "fas fa-arrow-right-arrow-left",
          "fas fa-arrow-right-from-arc",
          "fas fa-arrow-right-from-bracket",
          "fas fa-arrow-right-from-line",
          "fas fa-arrow-right-long",
          "fas fa-arrow-right-long-to-line",
          "fas fa-arrow-right-to-arc",
          "fas fa-arrow-right-to-bracket",
          "fas fa-arrow-right-to-city",
          "fas fa-arrow-right-to-line",
          "fas fa-arrow-rotate-left",
          "fas fa-arrow-rotate-right",
          "fas fa-arrow-trend-down",
          "fas fa-arrow-trend-up",
          "fas fa-arrow-turn-down",
          "fas fa-arrow-turn-down-left",
          "fas fa-arrow-turn-down-right",
          "fas fa-arrow-turn-up",
          "fas fa-arrow-up",
          "fas fa-arrow-up-1-9",
          "fas fa-arrow-up-9-1",
          "fas fa-arrow-up-a-z",
          "fas fa-arrow-up-arrow-down",
          "fas fa-arrow-up-big-small",
          "fas fa-arrow-up-from-arc",
          "fas fa-arrow-up-from-bracket",
          "fas fa-arrow-up-from-dotted-line",
          "fas fa-arrow-up-from-ground-water",
          "fas fa-arrow-up-from-line",
          "fas fa-arrow-up-from-square",
          "fas fa-arrow-up-from-water-pump",
          "fas fa-arrow-up-left",
          "fas fa-arrow-up-left-from-circle",
          "fas fa-arrow-up-long",
          "fas fa-arrow-up-right",
          "fas fa-arrow-up-right-and-arrow-down-left-from-center",
          "fas fa-arrow-up-right-dots",
          "fas fa-arrow-up-right-from-square",
          "fas fa-arrow-up-short-wide",
          "fas fa-arrow-up-small-big",
          "fas fa-arrow-up-square-triangle",
          "fas fa-arrow-up-to-dotted-line",
          "fas fa-arrow-up-to-line",
          "fas fa-arrow-up-triangle-square",
          "fas fa-arrow-up-wide-short",
          "fas fa-arrow-up-z-a",
          "fas fa-arrows-cross",
          "fas fa-arrows-down-to-line",
          "fas fa-arrows-down-to-people",
          "fas fa-arrows-from-dotted-line",
          "fas fa-arrows-from-line",
          "fas fa-arrows-left-right",
          "fas fa-arrows-left-right-to-line",
          "fas fa-arrows-maximize",
          "fas fa-arrows-minimize",
          "fas fa-arrows-repeat",
          "fas fa-arrows-repeat-1",
          "fas fa-arrows-retweet",
          "fas fa-arrows-rotate",
          "fas fa-arrows-spin",
          "fas fa-arrows-split-up-and-left",
          "fas fa-arrows-to-circle",
          "fas fa-arrows-to-dot",
          "fas fa-arrows-to-dotted-line",
          "fas fa-arrows-to-eye",
          "fas fa-arrows-to-line",
          "fas fa-arrows-turn-right",
          "fas fa-arrows-turn-to-dots",
          "fas fa-arrows-up-down",
          "fas fa-arrows-up-down-left-right",
          "fas fa-arrows-up-to-line",
          "fas fa-asterisk",
          "fas fa-at",
          "fas fa-atom",
          "fas fa-atom-simple",
          "fas fa-audio-description",
          "fas fa-audio-description-slash",
          "fas fa-austral-sign",
          "fas fa-avocado",
          "fas fa-award",
          "fas fa-award-simple",
          "fas fa-axe",
          "fas fa-axe-battle",
          "fas fa-b",
          "fas fa-baby",
          "fas fa-baby-carriage",
          "fas fa-backpack",
          "fas fa-backward",
          "fas fa-backward-fast",
          "fas fa-backward-step",
          "fas fa-bacon",
          "fas fa-bacteria",
          "fas fa-bacterium",
          "fas fa-badge",
          "fas fa-badge-check",
          "fas fa-badge-dollar",
          "fas fa-badge-percent",
          "fas fa-badge-sheriff",
          "fas fa-badger-honey",
          "fas fa-badminton",
          "fas fa-bag-shopping",
          "fas fa-bagel",
          "fas fa-bags-shopping",
          "fas fa-baguette",
          "fas fa-bahai",
          "fas fa-baht-sign",
          "fas fa-ball-pile",
          "fas fa-balloon",
          "fas fa-balloons",
          "fas fa-ballot",
          "fas fa-ballot-check",
          "fas fa-ban",
          "fas fa-ban-bug",
          "fas fa-ban-parking",
          "fas fa-ban-smoking",
          "fas fa-banana",
          "fas fa-bandage",
          "fas fa-bangladeshi-taka-sign",
          "fas fa-banjo",
          "fas fa-barcode",
          "fas fa-barcode-read",
          "fas fa-barcode-scan",
          "fas fa-bars",
          "fas fa-bars-filter",
          "fas fa-bars-progress",
          "fas fa-bars-sort",
          "fas fa-bars-staggered",
          "fas fa-baseball",
          "fas fa-baseball-bat-ball",
          "fas fa-basket-shopping",
          "fas fa-basket-shopping-simple",
          "fas fa-basketball",
          "fas fa-basketball-hoop",
          "fas fa-bat",
          "fas fa-bath",
          "fas fa-battery-bolt",
          "fas fa-battery-empty",
          "fas fa-battery-exclamation",
          "fas fa-battery-full",
          "fas fa-battery-half",
          "fas fa-battery-low",
          "fas fa-battery-quarter",
          "fas fa-battery-slash",
          "fas fa-battery-three-quarters",
          "fas fa-bed",
          "fas fa-bed-bunk",
          "fas fa-bed-empty",
          "fas fa-bed-front",
          "fas fa-bed-pulse",
          "fas fa-bee",
          "fas fa-beer-mug",
          "fas fa-beer-mug-empty",
          "fas fa-bell",
          "fas fa-bell-concierge",
          "fas fa-bell-exclamation",
          "fas fa-bell-on",
          "fas fa-bell-plus",
          "fas fa-bell-school",
          "fas fa-bell-school-slash",
          "fas fa-bell-slash",
          "fas fa-bells",
          "fas fa-bench-tree",
          "fas fa-bezier-curve",
          "fas fa-bicycle",
          "fas fa-billboard",
          "fas fa-binary",
          "fas fa-binary-circle-check",
          "fas fa-binary-lock",
          "fas fa-binary-slash",
          "fas fa-binoculars",
          "fas fa-biohazard",
          "fas fa-bird",
          "fas fa-bitcoin-sign",
          "fas fa-blanket",
          "fas fa-blanket-fire",
          "fas fa-blender",
          "fas fa-blender-phone",
          "fas fa-blinds",
          "fas fa-blinds-open",
          "fas fa-blinds-raised",
          "fas fa-block",
          "fas fa-block-brick",
          "fas fa-block-brick-fire",
          "fas fa-block-question",
          "fas fa-block-quote",
          "fas fa-blog",
          "fas fa-blueberries",
          "fas fa-bluetooth",
          "fas fa-bold",
          "fas fa-bolt",
          "fas fa-bolt-auto",
          "fas fa-bolt-lightning",
          "fas fa-bolt-slash",
          "fas fa-bomb",
          "fas fa-bone",
          "fas fa-bone-break",
          "fas fa-bong",
          "fas fa-book",
          "fas fa-book-arrow-right",
          "fas fa-book-arrow-up",
          "fas fa-book-atlas",
          "fas fa-book-bible",
          "fas fa-book-blank",
          "fas fa-book-bookmark",
          "fas fa-book-circle-arrow-right",
          "fas fa-book-circle-arrow-up",
          "fas fa-book-copy",
          "fas fa-book-font",
          "fas fa-book-heart",
          "fas fa-book-journal-whills",
          "fas fa-book-medical",
          "fas fa-book-open",
          "fas fa-book-open-cover",
          "fas fa-book-open-reader",
          "fas fa-book-quran",
          "fas fa-book-section",
          "fas fa-book-skull",
          "fas fa-book-sparkles",
          "fas fa-book-tanakh",
          "fas fa-book-user",
          "fas fa-bookmark",
          "fas fa-bookmark-slash",
          "fas fa-books",
          "fas fa-books-medical",
          "fas fa-boombox",
          "fas fa-boot",
          "fas fa-boot-heeled",
          "fas fa-booth-curtain",
          "fas fa-border-all",
          "fas fa-border-bottom",
          "fas fa-border-bottom-right",
          "fas fa-border-center-h",
          "fas fa-border-center-v",
          "fas fa-border-inner",
          "fas fa-border-left",
          "fas fa-border-none",
          "fas fa-border-outer",
          "fas fa-border-right",
          "fas fa-border-top",
          "fas fa-border-top-left",
          "fas fa-bore-hole",
          "fas fa-bottle-droplet",
          "fas fa-bottle-water",
          "fas fa-bow-arrow",
          "fas fa-bowl-chopsticks",
          "fas fa-bowl-chopsticks-noodles",
          "fas fa-bowl-food",
          "fas fa-bowl-hot",
          "fas fa-bowl-rice",
          "fas fa-bowl-scoop",
          "fas fa-bowl-scoops",
          "fas fa-bowl-soft-serve",
          "fas fa-bowl-spoon",
          "fas fa-bowling-ball",
          "fas fa-bowling-ball-pin",
          "fas fa-bowling-pins",
          "fas fa-box",
          "fas fa-box-archive",
          "fas fa-box-ballot",
          "fas fa-box-check",
          "fas fa-box-circle-check",
          "fas fa-box-dollar",
          "fas fa-box-heart",
          "fas fa-box-open",
          "fas fa-box-open-full",
          "fas fa-box-taped",
          "fas fa-box-tissue",
          "fas fa-boxes-packing",
          "fas fa-boxes-stacked",
          "fas fa-boxing-glove",
          "fas fa-bracket-curly",
          "fas fa-bracket-curly-right",
          "fas fa-bracket-round",
          "fas fa-bracket-round-right",
          "fas fa-bracket-square",
          "fas fa-bracket-square-right",
          "fas fa-brackets-curly",
          "fas fa-brackets-round",
          "fas fa-brackets-square",
          "fas fa-braille",
          "fas fa-brain",
          "fas fa-brain-arrow-curved-right",
          "fas fa-brain-circuit",
          "fas fa-brake-warning",
          "fas fa-brazilian-real-sign",
          "fas fa-bread-loaf",
          "fas fa-bread-slice",
          "fas fa-bread-slice-butter",
          "fas fa-bridge",
          "fas fa-bridge-circle-check",
          "fas fa-bridge-circle-exclamation",
          "fas fa-bridge-circle-xmark",
          "fas fa-bridge-lock",
          "fas fa-bridge-suspension",
          "fas fa-bridge-water",
          "fas fa-briefcase",
          "fas fa-briefcase-arrow-right",
          "fas fa-briefcase-blank",
          "fas fa-briefcase-medical",
          "fas fa-brightness",
          "fas fa-brightness-low",
          "fas fa-bring-forward",
          "fas fa-bring-front",
          "fas fa-broccoli",
          "fas fa-broom",
          "fas fa-broom-ball",
          "fas fa-broom-wide",
          "fas fa-browser",
          "fas fa-browsers",
          "fas fa-brush",
          "fas fa-bucket",
          "fas fa-bug",
          "fas fa-bug-slash",
          "fas fa-bugs",
          "fas fa-building",
          "fas fa-building-circle-arrow-right",
          "fas fa-building-circle-check",
          "fas fa-building-circle-exclamation",
          "fas fa-building-circle-xmark",
          "fas fa-building-columns",
          "fas fa-building-flag",
          "fas fa-building-lock",
          "fas fa-building-ngo",
          "fas fa-building-shield",
          "fas fa-building-un",
          "fas fa-building-user",
          "fas fa-building-wheat",
          "fas fa-buildings",
          "fas fa-bullhorn",
          "fas fa-bullseye",
          "fas fa-bullseye-arrow",
          "fas fa-bullseye-pointer",
          "fas fa-buoy",
          "fas fa-buoy-mooring",
          "fas fa-burger",
          "fas fa-burger-cheese",
          "fas fa-burger-fries",
          "fas fa-burger-glass",
          "fas fa-burger-lettuce",
          "fas fa-burger-soda",
          "fas fa-burrito",
          "fas fa-burst",
          "fas fa-bus",
          "fas fa-bus-school",
          "fas fa-bus-simple",
          "fas fa-business-time",
          "fas fa-butter",
          "fas fa-c",
          "fas fa-cabin",
          "fas fa-cabinet-filing",
          "fas fa-cable-car",
          "fas fa-cactus",
          "fas fa-cake-candles",
          "fas fa-cake-slice",
          "fas fa-calculator",
          "fas fa-calculator-simple",
          "fas fa-calendar",
          "fas fa-calendar-arrow-down",
          "fas fa-calendar-arrow-up",
          "fas fa-calendar-check",
          "fas fa-calendar-circle-exclamation",
          "fas fa-calendar-circle-minus",
          "fas fa-calendar-circle-plus",
          "fas fa-calendar-circle-user",
          "fas fa-calendar-clock",
          "fas fa-calendar-day",
          "fas fa-calendar-days",
          "fas fa-calendar-exclamation",
          "fas fa-calendar-heart",
          "fas fa-calendar-image",
          "fas fa-calendar-lines",
          "fas fa-calendar-lines-pen",
          "fas fa-calendar-minus",
          "fas fa-calendar-pen",
          "fas fa-calendar-plus",
          "fas fa-calendar-range",
          "fas fa-calendar-star",
          "fas fa-calendar-week",
          "fas fa-calendar-xmark",
          "fas fa-calendars",
          "fas fa-camcorder",
          "fas fa-camera",
          "fas fa-camera-cctv",
          "fas fa-camera-movie",
          "fas fa-camera-polaroid",
          "fas fa-camera-retro",
          "fas fa-camera-rotate",
          "fas fa-camera-security",
          "fas fa-camera-slash",
          "fas fa-camera-viewfinder",
          "fas fa-camera-web",
          "fas fa-camera-web-slash",
          "fas fa-campfire",
          "fas fa-campground",
          "fas fa-can-food",
          "fas fa-candle-holder",
          "fas fa-candy",
          "fas fa-candy-bar",
          "fas fa-candy-cane",
          "fas fa-candy-corn",
          "fas fa-cannabis",
          "fas fa-capsules",
          "fas fa-car",
          "fas fa-car-battery",
          "fas fa-car-bolt",
          "fas fa-car-building",
          "fas fa-car-bump",
          "fas fa-car-burst",
          "fas fa-car-bus",
          "fas fa-car-circle-bolt",
          "fas fa-car-garage",
          "fas fa-car-mirrors",
          "fas fa-car-on",
          "fas fa-car-rear",
          "fas fa-car-side",
          "fas fa-car-side-bolt",
          "fas fa-car-tilt",
          "fas fa-car-tunnel",
          "fas fa-car-wash",
          "fas fa-car-wrench",
          "fas fa-caravan",
          "fas fa-caravan-simple",
          "fas fa-card-club",
          "fas fa-card-diamond",
          "fas fa-card-heart",
          "fas fa-card-spade",
          "fas fa-cards",
          "fas fa-cards-blank",
          "fas fa-caret-down",
          "fas fa-caret-left",
          "fas fa-caret-right",
          "fas fa-caret-up",
          "fas fa-carrot",
          "fas fa-cars",
          "fas fa-cart-arrow-down",
          "fas fa-cart-arrow-up",
          "fas fa-cart-circle-arrow-down",
          "fas fa-cart-circle-arrow-up",
          "fas fa-cart-circle-check",
          "fas fa-cart-circle-exclamation",
          "fas fa-cart-circle-plus",
          "fas fa-cart-circle-xmark",
          "fas fa-cart-flatbed",
          "fas fa-cart-flatbed-boxes",
          "fas fa-cart-flatbed-empty",
          "fas fa-cart-flatbed-suitcase",
          "fas fa-cart-minus",
          "fas fa-cart-plus",
          "fas fa-cart-shopping",
          "fas fa-cart-shopping-fast",
          "fas fa-cart-xmark",
          "fas fa-cash-register",
          "fas fa-cassette-betamax",
          "fas fa-cassette-tape",
          "fas fa-cassette-vhs",
          "fas fa-castle",
          "fas fa-cat",
          "fas fa-cat-space",
          "fas fa-cauldron",
          "fas fa-cedi-sign",
          "fas fa-cent-sign",
          "fas fa-certificate",
          "fas fa-chair",
          "fas fa-chair-office",
          "fas fa-chalkboard",
          "fas fa-chalkboard-user",
          "fas fa-champagne-glass",
          "fas fa-champagne-glasses",
          "fas fa-charging-station",
          "fas fa-chart-area",
          "fas fa-chart-bar",
          "fas fa-chart-bullet",
          "fas fa-chart-candlestick",
          "fas fa-chart-column",
          "fas fa-chart-gantt",
          "fas fa-chart-line",
          "fas fa-chart-line-down",
          "fas fa-chart-line-up",
          "fas fa-chart-mixed",
          "fas fa-chart-network",
          "fas fa-chart-pie",
          "fas fa-chart-pie-simple",
          "fas fa-chart-pyramid",
          "fas fa-chart-radar",
          "fas fa-chart-scatter",
          "fas fa-chart-scatter-3d",
          "fas fa-chart-scatter-bubble",
          "fas fa-chart-simple",
          "fas fa-chart-simple-horizontal",
          "fas fa-chart-tree-map",
          "fas fa-chart-user",
          "fas fa-chart-waterfall",
          "fas fa-check",
          "fas fa-check-double",
          "fas fa-check-to-slot",
          "fas fa-cheese",
          "fas fa-cheese-swiss",
          "fas fa-cherries",
          "fas fa-chess",
          "fas fa-chess-bishop",
          "fas fa-chess-bishop-piece",
          "fas fa-chess-board",
          "fas fa-chess-clock",
          "fas fa-chess-clock-flip",
          "fas fa-chess-king",
          "fas fa-chess-king-piece",
          "fas fa-chess-knight",
          "fas fa-chess-knight-piece",
          "fas fa-chess-pawn",
          "fas fa-chess-pawn-piece",
          "fas fa-chess-queen",
          "fas fa-chess-queen-piece",
          "fas fa-chess-rook",
          "fas fa-chess-rook-piece",
          "fas fa-chestnut",
          "fas fa-chevron-down",
          "fas fa-chevron-left",
          "fas fa-chevron-right",
          "fas fa-chevron-up",
          "fas fa-chevrons-down",
          "fas fa-chevrons-left",
          "fas fa-chevrons-right",
          "fas fa-chevrons-up",
          "fas fa-child",
          "fas fa-child-combatant",
          "fas fa-child-dress",
          "fas fa-child-reaching",
          "fas fa-children",
          "fas fa-chimney",
          "fas fa-chopsticks",
          "fas fa-church",
          "fas fa-circle",
          "fas fa-circle-0",
          "fas fa-circle-1",
          "fas fa-circle-2",
          "fas fa-circle-3",
          "fas fa-circle-4",
          "fas fa-circle-5",
          "fas fa-circle-6",
          "fas fa-circle-7",
          "fas fa-circle-8",
          "fas fa-circle-9",
          "fas fa-circle-a",
          "fas fa-circle-ampersand",
          "fas fa-circle-arrow-down",
          "fas fa-circle-arrow-down-left",
          "fas fa-circle-arrow-down-right",
          "fas fa-circle-arrow-left",
          "fas fa-circle-arrow-right",
          "fas fa-circle-arrow-up",
          "fas fa-circle-arrow-up-left",
          "fas fa-circle-arrow-up-right",
          "fas fa-circle-b",
          "fas fa-circle-bolt",
          "fas fa-circle-book-open",
          "fas fa-circle-bookmark",
          "fas fa-circle-c",
          "fas fa-circle-calendar",
          "fas fa-circle-camera",
          "fas fa-circle-caret-down",
          "fas fa-circle-caret-left",
          "fas fa-circle-caret-right",
          "fas fa-circle-caret-up",
          "fas fa-circle-check",
          "fas fa-circle-chevron-down",
          "fas fa-circle-chevron-left",
          "fas fa-circle-chevron-right",
          "fas fa-circle-chevron-up",
          "fas fa-circle-d",
          "fas fa-circle-dashed",
          "fas fa-circle-divide",
          "fas fa-circle-dollar",
          "fas fa-circle-dollar-to-slot",
          "fas fa-circle-dot",
          "fas fa-circle-down",
          "fas fa-circle-down-left",
          "fas fa-circle-down-right",
          "fas fa-circle-e",
          "fas fa-circle-ellipsis",
          "fas fa-circle-ellipsis-vertical",
          "fas fa-circle-envelope",
          "fas fa-circle-euro",
          "fas fa-circle-exclamation",
          "fas fa-circle-exclamation-check",
          "fas fa-circle-f",
          "fas fa-circle-g",
          "fas fa-circle-h",
          "fas fa-circle-half",
          "fas fa-circle-half-stroke",
          "fas fa-circle-heart",
          "fas fa-circle-i",
          "fas fa-circle-info",
          "fas fa-circle-j",
          "fas fa-circle-k",
          "fas fa-circle-l",
          "fas fa-circle-left",
          "fas fa-circle-location-arrow",
          "fas fa-circle-m",
          "fas fa-circle-microphone",
          "fas fa-circle-microphone-lines",
          "fas fa-circle-minus",
          "fas fa-circle-n",
          "fas fa-circle-nodes",
          "fas fa-circle-notch",
          "fas fa-circle-o",
          "fas fa-circle-p",
          "fas fa-circle-parking",
          "fas fa-circle-pause",
          "fas fa-circle-phone",
          "fas fa-circle-phone-flip",
          "fas fa-circle-phone-hangup",
          "fas fa-circle-play",
          "fas fa-circle-plus",
          "fas fa-circle-q",
          "fas fa-circle-quarter",
          "fas fa-circle-quarter-stroke",
          "fas fa-circle-quarters",
          "fas fa-circle-question",
          "fas fa-circle-r",
          "fas fa-circle-radiation",
          "fas fa-circle-right",
          "fas fa-circle-s",
          "fas fa-circle-small",
          "fas fa-circle-sort",
          "fas fa-circle-sort-down",
          "fas fa-circle-sort-up",
          "fas fa-circle-star",
          "fas fa-circle-sterling",
          "fas fa-circle-stop",
          "fas fa-circle-t",
          "fas fa-circle-three-quarters",
          "fas fa-circle-three-quarters-stroke",
          "fas fa-circle-trash",
          "fas fa-circle-u",
          "fas fa-circle-up",
          "fas fa-circle-up-left",
          "fas fa-circle-up-right",
          "fas fa-circle-user",
          "fas fa-circle-v",
          "fas fa-circle-video",
          "fas fa-circle-w",
          "fas fa-circle-waveform-lines",
          "fas fa-circle-x",
          "fas fa-circle-xmark",
          "fas fa-circle-y",
          "fas fa-circle-yen",
          "fas fa-circle-z",
          "fas fa-citrus",
          "fas fa-citrus-slice",
          "fas fa-city",
          "fas fa-clapperboard",
          "fas fa-clapperboard-play",
          "fas fa-clarinet",
          "fas fa-claw-marks",
          "fas fa-clipboard",
          "fas fa-clipboard-check",
          "fas fa-clipboard-list",
          "fas fa-clipboard-list-check",
          "fas fa-clipboard-medical",
          "fas fa-clipboard-prescription",
          "fas fa-clipboard-question",
          "fas fa-clipboard-user",
          "fas fa-clock",
          "fas fa-clock-desk",
          "fas fa-clock-eight",
          "fas fa-clock-eight-thirty",
          "fas fa-clock-eleven",
          "fas fa-clock-eleven-thirty",
          "fas fa-clock-five",
          "fas fa-clock-five-thirty",
          "fas fa-clock-four-thirty",
          "fas fa-clock-nine",
          "fas fa-clock-nine-thirty",
          "fas fa-clock-one",
          "fas fa-clock-one-thirty",
          "fas fa-clock-rotate-left",
          "fas fa-clock-seven",
          "fas fa-clock-seven-thirty",
          "fas fa-clock-six",
          "fas fa-clock-six-thirty",
          "fas fa-clock-ten",
          "fas fa-clock-ten-thirty",
          "fas fa-clock-three",
          "fas fa-clock-three-thirty",
          "fas fa-clock-twelve",
          "fas fa-clock-twelve-thirty",
          "fas fa-clock-two",
          "fas fa-clock-two-thirty",
          "fas fa-clone",
          "fas fa-closed-captioning",
          "fas fa-closed-captioning-slash",
          "fas fa-clothes-hanger",
          "fas fa-cloud",
          "fas fa-cloud-arrow-down",
          "fas fa-cloud-arrow-up",
          "fas fa-cloud-bolt",
          "fas fa-cloud-bolt-moon",
          "fas fa-cloud-bolt-sun",
          "fas fa-cloud-check",
          "fas fa-cloud-drizzle",
          "fas fa-cloud-exclamation",
          "fas fa-cloud-fog",
          "fas fa-cloud-hail",
          "fas fa-cloud-hail-mixed",
          "fas fa-cloud-meatball",
          "fas fa-cloud-minus",
          "fas fa-cloud-moon",
          "fas fa-cloud-moon-rain",
          "fas fa-cloud-music",
          "fas fa-cloud-plus",
          "fas fa-cloud-question",
          "fas fa-cloud-rain",
          "fas fa-cloud-rainbow",
          "fas fa-cloud-showers",
          "fas fa-cloud-showers-heavy",
          "fas fa-cloud-showers-water",
          "fas fa-cloud-slash",
          "fas fa-cloud-sleet",
          "fas fa-cloud-snow",
          "fas fa-cloud-sun",
          "fas fa-cloud-sun-rain",
          "fas fa-cloud-word",
          "fas fa-cloud-xmark",
          "fas fa-clouds",
          "fas fa-clouds-moon",
          "fas fa-clouds-sun",
          "fas fa-clover",
          "fas fa-club",
          "fas fa-coconut",
          "fas fa-code",
          "fas fa-code-branch",
          "fas fa-code-commit",
          "fas fa-code-compare",
          "fas fa-code-fork",
          "fas fa-code-merge",
          "fas fa-code-pull-request",
          "fas fa-code-pull-request-closed",
          "fas fa-code-pull-request-draft",
          "fas fa-code-simple",
          "fas fa-coffee-bean",
          "fas fa-coffee-beans",
          "fas fa-coffee-pot",
          "fas fa-coffin",
          "fas fa-coffin-cross",
          "fas fa-coin",
          "fas fa-coin-blank",
          "fas fa-coin-front",
          "fas fa-coin-vertical",
          "fas fa-coins",
          "fas fa-colon",
          "fas fa-colon-sign",
          "fas fa-columns-3",
          "fas fa-comet",
          "fas fa-comma",
          "fas fa-command",
          "fas fa-comment",
          "fas fa-comment-arrow-down",
          "fas fa-comment-arrow-up",
          "fas fa-comment-arrow-up-right",
          "fas fa-comment-captions",
          "fas fa-comment-check",
          "fas fa-comment-code",
          "fas fa-comment-dollar",
          "fas fa-comment-dots",
          "fas fa-comment-exclamation",
          "fas fa-comment-heart",
          "fas fa-comment-image",
          "fas fa-comment-lines",
          "fas fa-comment-medical",
          "fas fa-comment-middle",
          "fas fa-comment-middle-top",
          "fas fa-comment-minus",
          "fas fa-comment-music",
          "fas fa-comment-pen",
          "fas fa-comment-plus",
          "fas fa-comment-question",
          "fas fa-comment-quote",
          "fas fa-comment-slash",
          "fas fa-comment-smile",
          "fas fa-comment-sms",
          "fas fa-comment-text",
          "fas fa-comment-xmark",
          "fas fa-comments",
          "fas fa-comments-dollar",
          "fas fa-comments-question",
          "fas fa-comments-question-check",
          "fas fa-compact-disc",
          "fas fa-compass",
          "fas fa-compass-drafting",
          "fas fa-compass-slash",
          "fas fa-compress",
          "fas fa-compress-wide",
          "fas fa-computer",
          "fas fa-computer-classic",
          "fas fa-computer-mouse",
          "fas fa-computer-mouse-scrollwheel",
          "fas fa-computer-speaker",
          "fas fa-container-storage",
          "fas fa-conveyor-belt",
          "fas fa-conveyor-belt-boxes",
          "fas fa-conveyor-belt-empty",
          "fas fa-cookie",
          "fas fa-cookie-bite",
          "fas fa-copy",
          "fas fa-copyright",
          "fas fa-corn",
          "fas fa-corner",
          "fas fa-couch",
          "fas fa-cow",
          "fas fa-cowbell",
          "fas fa-cowbell-circle-plus",
          "fas fa-crab",
          "fas fa-crate-apple",
          "fas fa-crate-empty",
          "fas fa-credit-card",
          "fas fa-credit-card-blank",
          "fas fa-credit-card-front",
          "fas fa-cricket-bat-ball",
          "fas fa-croissant",
          "fas fa-crop",
          "fas fa-crop-simple",
          "fas fa-cross",
          "fas fa-crosshairs",
          "fas fa-crosshairs-simple",
          "fas fa-crow",
          "fas fa-crown",
          "fas fa-crutch",
          "fas fa-crutches",
          "fas fa-cruzeiro-sign",
          "fas fa-crystal-ball",
          "fas fa-cube",
          "fas fa-cubes",
          "fas fa-cubes-stacked",
          "fas fa-cucumber",
          "fas fa-cup-straw",
          "fas fa-cup-straw-swoosh",
          "fas fa-cup-togo",
          "fas fa-cupcake",
          "fas fa-curling-stone",
          "fas fa-custard",
          "fas fa-d",
          "fas fa-dagger",
          "fas fa-dash",
          "fas fa-database",
          "fas fa-deer",
          "fas fa-deer-rudolph",
          "fas fa-delete-left",
          "fas fa-delete-right",
          "fas fa-democrat",
          "fas fa-desktop",
          "fas fa-desktop-arrow-down",
          "fas fa-dharmachakra",
          "fas fa-diagram-cells",
          "fas fa-diagram-lean-canvas",
          "fas fa-diagram-nested",
          "fas fa-diagram-next",
          "fas fa-diagram-predecessor",
          "fas fa-diagram-previous",
          "fas fa-diagram-project",
          "fas fa-diagram-sankey",
          "fas fa-diagram-subtask",
          "fas fa-diagram-successor",
          "fas fa-diagram-venn",
          "fas fa-dial",
          "fas fa-dial-high",
          "fas fa-dial-low",
          "fas fa-dial-max",
          "fas fa-dial-med",
          "fas fa-dial-med-low",
          "fas fa-dial-min",
          "fas fa-dial-off",
          "fas fa-diamond",
          "fas fa-diamond-exclamation",
          "fas fa-diamond-half",
          "fas fa-diamond-half-stroke",
          "fas fa-diamond-turn-right",
          "fas fa-dice",
          "fas fa-dice-d10",
          "fas fa-dice-d12",
          "fas fa-dice-d20",
          "fas fa-dice-d4",
          "fas fa-dice-d6",
          "fas fa-dice-d8",
          "fas fa-dice-five",
          "fas fa-dice-four",
          "fas fa-dice-one",
          "fas fa-dice-six",
          "fas fa-dice-three",
          "fas fa-dice-two",
          "fas fa-diploma",
          "fas fa-disc-drive",
          "fas fa-disease",
          "fas fa-display",
          "fas fa-display-arrow-down",
          "fas fa-display-code",
          "fas fa-display-medical",
          "fas fa-display-slash",
          "fas fa-distribute-spacing-horizontal",
          "fas fa-distribute-spacing-vertical",
          "fas fa-ditto",
          "fas fa-divide",
          "fas fa-dna",
          "fas fa-do-not-enter",
          "fas fa-dog",
          "fas fa-dog-leashed",
          "fas fa-dollar-sign",
          "fas fa-dolly",
          "fas fa-dolly-empty",
          "fas fa-dolphin",
          "fas fa-dong-sign",
          "fas fa-donut",
          "fas fa-door-closed",
          "fas fa-door-open",
          "fas fa-dove",
          "fas fa-down",
          "fas fa-down-from-dotted-line",
          "fas fa-down-from-line",
          "fas fa-down-left",
          "fas fa-down-left-and-up-right-to-center",
          "fas fa-down-long",
          "fas fa-down-right",
          "fas fa-down-to-bracket",
          "fas fa-down-to-dotted-line",
          "fas fa-down-to-line",
          "fas fa-download",
          "fas fa-dragon",
          "fas fa-draw-circle",
          "fas fa-draw-polygon",
          "fas fa-draw-square",
          "fas fa-dreidel",
          "fas fa-drone",
          "fas fa-drone-front",
          "fas fa-droplet",
          "fas fa-droplet-degree",
          "fas fa-droplet-percent",
          "fas fa-droplet-slash",
          "fas fa-drum",
          "fas fa-drum-steelpan",
          "fas fa-drumstick",
          "fas fa-drumstick-bite",
          "fas fa-dryer",
          "fas fa-dryer-heat",
          "fas fa-duck",
          "fas fa-dumbbell",
          "fas fa-dumpster",
          "fas fa-dumpster-fire",
          "fas fa-dungeon",
          "fas fa-e",
          "fas fa-ear",
          "fas fa-ear-deaf",
          "fas fa-ear-listen",
          "fas fa-ear-muffs",
          "fas fa-earth-africa",
          "fas fa-earth-americas",
          "fas fa-earth-asia",
          "fas fa-earth-europe",
          "fas fa-earth-oceania",
          "fas fa-eclipse",
          "fas fa-egg",
          "fas fa-egg-fried",
          "fas fa-eggplant",
          "fas fa-eject",
          "fas fa-elephant",
          "fas fa-elevator",
          "fas fa-ellipsis",
          "fas fa-ellipsis-stroke",
          "fas fa-ellipsis-stroke-vertical",
          "fas fa-ellipsis-vertical",
          "fas fa-empty-set",
          "fas fa-engine",
          "fas fa-engine-warning",
          "fas fa-envelope",
          "fas fa-envelope-circle-check",
          "fas fa-envelope-dot",
          "fas fa-envelope-open",
          "fas fa-envelope-open-dollar",
          "fas fa-envelope-open-text",
          "fas fa-envelopes",
          "fas fa-envelopes-bulk",
          "fas fa-equals",
          "fas fa-eraser",
          "fas fa-escalator",
          "fas fa-ethernet",
          "fas fa-euro-sign",
          "fas fa-exclamation",
          "fas fa-expand",
          "fas fa-expand-wide",
          "fas fa-explosion",
          "fas fa-eye",
          "fas fa-eye-dropper",
          "fas fa-eye-dropper-full",
          "fas fa-eye-dropper-half",
          "fas fa-eye-evil",
          "fas fa-eye-low-vision",
          "fas fa-eye-slash",
          "fas fa-eyes",
          "fas fa-f",
          "fas fa-face-angry",
          "fas fa-face-angry-horns",
          "fas fa-face-anguished",
          "fas fa-face-anxious-sweat",
          "fas fa-face-astonished",
          "fas fa-face-awesome",
          "fas fa-face-beam-hand-over-mouth",
          "fas fa-face-clouds",
          "fas fa-face-confounded",
          "fas fa-face-confused",
          "fas fa-face-cowboy-hat",
          "fas fa-face-diagonal-mouth",
          "fas fa-face-disappointed",
          "fas fa-face-disguise",
          "fas fa-face-dizzy",
          "fas fa-face-dotted",
          "fas fa-face-downcast-sweat",
          "fas fa-face-drooling",
          "fas fa-face-exhaling",
          "fas fa-face-explode",
          "fas fa-face-expressionless",
          "fas fa-face-eyes-xmarks",
          "fas fa-face-fearful",
          "fas fa-face-flushed",
          "fas fa-face-frown",
          "fas fa-face-frown-open",
          "fas fa-face-frown-slight",
          "fas fa-face-glasses",
          "fas fa-face-grimace",
          "fas fa-face-grin",
          "fas fa-face-grin-beam",
          "fas fa-face-grin-beam-sweat",
          "fas fa-face-grin-hearts",
          "fas fa-face-grin-squint",
          "fas fa-face-grin-squint-tears",
          "fas fa-face-grin-stars",
          "fas fa-face-grin-tears",
          "fas fa-face-grin-tongue",
          "fas fa-face-grin-tongue-squint",
          "fas fa-face-grin-tongue-wink",
          "fas fa-face-grin-wide",
          "fas fa-face-grin-wink",
          "fas fa-face-hand-over-mouth",
          "fas fa-face-hand-peeking",
          "fas fa-face-hand-yawn",
          "fas fa-face-head-bandage",
          "fas fa-face-holding-back-tears",
          "fas fa-face-hushed",
          "fas fa-face-icicles",
          "fas fa-face-kiss",
          "fas fa-face-kiss-beam",
          "fas fa-face-kiss-closed-eyes",
          "fas fa-face-kiss-wink-heart",
          "fas fa-face-laugh",
          "fas fa-face-laugh-beam",
          "fas fa-face-laugh-squint",
          "fas fa-face-laugh-wink",
          "fas fa-face-lying",
          "fas fa-face-mask",
          "fas fa-face-meh",
          "fas fa-face-meh-blank",
          "fas fa-face-melting",
          "fas fa-face-monocle",
          "fas fa-face-nauseated",
          "fas fa-face-nose-steam",
          "fas fa-face-party",
          "fas fa-face-pensive",
          "fas fa-face-persevering",
          "fas fa-face-pleading",
          "fas fa-face-pouting",
          "fas fa-face-raised-eyebrow",
          "fas fa-face-relieved",
          "fas fa-face-rolling-eyes",
          "fas fa-face-sad-cry",
          "fas fa-face-sad-sweat",
          "fas fa-face-sad-tear",
          "fas fa-face-saluting",
          "fas fa-face-scream",
          "fas fa-face-shush",
          "fas fa-face-sleeping",
          "fas fa-face-sleepy",
          "fas fa-face-smile",
          "fas fa-face-smile-beam",
          "fas fa-face-smile-halo",
          "fas fa-face-smile-hearts",
          "fas fa-face-smile-horns",
          "fas fa-face-smile-plus",
          "fas fa-face-smile-relaxed",
          "fas fa-face-smile-tear",
          "fas fa-face-smile-tongue",
          "fas fa-face-smile-upside-down",
          "fas fa-face-smile-wink",
          "fas fa-face-smiling-hands",
          "fas fa-face-smirking",
          "fas fa-face-spiral-eyes",
          "fas fa-face-sunglasses",
          "fas fa-face-surprise",
          "fas fa-face-swear",
          "fas fa-face-thermometer",
          "fas fa-face-thinking",
          "fas fa-face-tired",
          "fas fa-face-tissue",
          "fas fa-face-tongue-money",
          "fas fa-face-tongue-sweat",
          "fas fa-face-unamused",
          "fas fa-face-viewfinder",
          "fas fa-face-vomit",
          "fas fa-face-weary",
          "fas fa-face-woozy",
          "fas fa-face-worried",
          "fas fa-face-zany",
          "fas fa-face-zipper",
          "fas fa-falafel",
          "fas fa-family",
          "fas fa-family-dress",
          "fas fa-family-pants",
          "fas fa-fan",
          "fas fa-fan-table",
          "fas fa-farm",
          "fas fa-faucet",
          "fas fa-faucet-drip",
          "fas fa-fax",
          "fas fa-feather",
          "fas fa-feather-pointed",
          "fas fa-fence",
          "fas fa-ferris-wheel",
          "fas fa-ferry",
          "fas fa-field-hockey-stick-ball",
          "fas fa-file",
          "fas fa-file-arrow-down",
          "fas fa-file-arrow-up",
          "fas fa-file-audio",
          "fas fa-file-binary",
          "fas fa-file-certificate",
          "fas fa-file-chart-column",
          "fas fa-file-chart-pie",
          "fas fa-file-check",
          "fas fa-file-circle-check",
          "fas fa-file-circle-exclamation",
          "fas fa-file-circle-info",
          "fas fa-file-circle-minus",
          "fas fa-file-circle-plus",
          "fas fa-file-circle-question",
          "fas fa-file-circle-xmark",
          "fas fa-file-code",
          "fas fa-file-contract",
          "fas fa-file-csv",
          "fas fa-file-dashed-line",
          "fas fa-file-excel",
          "fas fa-file-exclamation",
          "fas fa-file-export",
          "fas fa-file-heart",
          "fas fa-file-image",
          "fas fa-file-import",
          "fas fa-file-invoice",
          "fas fa-file-invoice-dollar",
          "fas fa-file-lines",
          "fas fa-file-lock",
          "fas fa-file-magnifying-glass",
          "fas fa-file-medical",
          "fas fa-file-minus",
          "fas fa-file-music",
          "fas fa-file-pdf",
          "fas fa-file-pen",
          "fas fa-file-plus",
          "fas fa-file-plus-minus",
          "fas fa-file-powerpoint",
          "fas fa-file-prescription",
          "fas fa-file-shield",
          "fas fa-file-signature",
          "fas fa-file-slash",
          "fas fa-file-spreadsheet",
          "fas fa-file-user",
          "fas fa-file-video",
          "fas fa-file-waveform",
          "fas fa-file-word",
          "fas fa-file-xmark",
          "fas fa-file-zipper",
          "fas fa-files",
          "fas fa-files-medical",
          "fas fa-fill",
          "fas fa-fill-drip",
          "fas fa-film",
          "fas fa-film-canister",
          "fas fa-film-simple",
          "fas fa-film-slash",
          "fas fa-films",
          "fas fa-filter",
          "fas fa-filter-circle-dollar",
          "fas fa-filter-circle-xmark",
          "fas fa-filter-list",
          "fas fa-filter-slash",
          "fas fa-filters",
          "fas fa-fingerprint",
          "fas fa-fire",
          "fas fa-fire-burner",
          "fas fa-fire-extinguisher",
          "fas fa-fire-flame",
          "fas fa-fire-flame-curved",
          "fas fa-fire-flame-simple",
          "fas fa-fire-hydrant",
          "fas fa-fire-smoke",
          "fas fa-fireplace",
          "fas fa-fish",
          "fas fa-fish-bones",
          "fas fa-fish-cooked",
          "fas fa-fish-fins",
          "fas fa-fishing-rod",
          "fas fa-flag",
          "fas fa-flag-checkered",
          "fas fa-flag-pennant",
          "fas fa-flag-swallowtail",
          "fas fa-flag-usa",
          "fas fa-flashlight",
          "fas fa-flask",
          "fas fa-flask-round-poison",
          "fas fa-flask-round-potion",
          "fas fa-flask-vial",
          "fas fa-flatbread",
          "fas fa-flatbread-stuffed",
          "fas fa-floppy-disk",
          "fas fa-floppy-disk-circle-arrow-right",
          "fas fa-floppy-disk-circle-xmark",
          "fas fa-floppy-disk-pen",
          "fas fa-floppy-disks",
          "fas fa-florin-sign",
          "fas fa-flower",
          "fas fa-flower-daffodil",
          "fas fa-flower-tulip",
          "fas fa-flute",
          "fas fa-flux-capacitor",
          "fas fa-flying-disc",
          "fas fa-folder",
          "fas fa-folder-arrow-down",
          "fas fa-folder-arrow-up",
          "fas fa-folder-bookmark",
          "fas fa-folder-closed",
          "fas fa-folder-gear",
          "fas fa-folder-grid",
          "fas fa-folder-heart",
          "fas fa-folder-image",
          "fas fa-folder-magnifying-glass",
          "fas fa-folder-medical",
          "fas fa-folder-minus",
          "fas fa-folder-music",
          "fas fa-folder-open",
          "fas fa-folder-plus",
          "fas fa-folder-tree",
          "fas fa-folder-user",
          "fas fa-folder-xmark",
          "fas fa-folders",
          "fas fa-fondue-pot",
          "fas fa-font",
          "fas fa-font-awesome",
          "fas fa-font-case",
          "fas fa-football",
          "fas fa-football-helmet",
          "fas fa-fork",
          "fas fa-fork-knife",
          "fas fa-forklift",
          "fas fa-fort",
          "fas fa-forward",
          "fas fa-forward-fast",
          "fas fa-forward-step",
          "fas fa-frame",
          "fas fa-franc-sign",
          "fas fa-french-fries",
          "fas fa-frog",
          "fas fa-function",
          "fas fa-futbol",
          "fas fa-g",
          "fas fa-galaxy",
          "fas fa-gallery-thumbnails",
          "fas fa-game-board",
          "fas fa-game-board-simple",
          "fas fa-game-console-handheld",
          "fas fa-game-console-handheld-crank",
          "fas fa-gamepad",
          "fas fa-gamepad-modern",
          "fas fa-garage",
          "fas fa-garage-car",
          "fas fa-garage-open",
          "fas fa-garlic",
          "fas fa-gas-pump",
          "fas fa-gas-pump-slash",
          "fas fa-gauge",
          "fas fa-gauge-circle-bolt",
          "fas fa-gauge-circle-minus",
          "fas fa-gauge-circle-plus",
          "fas fa-gauge-high",
          "fas fa-gauge-low",
          "fas fa-gauge-max",
          "fas fa-gauge-min",
          "fas fa-gauge-simple",
          "fas fa-gauge-simple-high",
          "fas fa-gauge-simple-low",
          "fas fa-gauge-simple-max",
          "fas fa-gauge-simple-min",
          "fas fa-gavel",
          "fas fa-gear",
          "fas fa-gears",
          "fas fa-gem",
          "fas fa-genderless",
          "fas fa-ghost",
          "fas fa-gif",
          "fas fa-gift",
          "fas fa-gift-card",
          "fas fa-gifts",
          "fas fa-gingerbread-man",
          "fas fa-glass",
          "fas fa-glass-citrus",
          "fas fa-glass-empty",
          "fas fa-glass-half",
          "fas fa-glass-water",
          "fas fa-glass-water-droplet",
          "fas fa-glasses",
          "fas fa-glasses-round",
          "fas fa-globe",
          "fas fa-globe-snow",
          "fas fa-globe-stand",
          "fas fa-goal-net",
          "fas fa-golf-ball-tee",
          "fas fa-golf-club",
          "fas fa-golf-flag-hole",
          "fas fa-gopuram",
          "fas fa-graduation-cap",
          "fas fa-gramophone",
          "fas fa-grapes",
          "fas fa-grate",
          "fas fa-grate-droplet",
          "fas fa-greater-than",
          "fas fa-greater-than-equal",
          "fas fa-grid",
          "fas fa-grid-2",
          "fas fa-grid-2-plus",
          "fas fa-grid-4",
          "fas fa-grid-5",
          "fas fa-grid-dividers",
          "fas fa-grid-horizontal",
          "fas fa-grill",
          "fas fa-grill-fire",
          "fas fa-grill-hot",
          "fas fa-grip",
          "fas fa-grip-dots",
          "fas fa-grip-dots-vertical",
          "fas fa-grip-lines",
          "fas fa-grip-lines-vertical",
          "fas fa-grip-vertical",
          "fas fa-group-arrows-rotate",
          "fas fa-guarani-sign",
          "fas fa-guitar",
          "fas fa-guitar-electric",
          "fas fa-guitars",
          "fas fa-gun",
          "fas fa-gun-slash",
          "fas fa-gun-squirt",
          "fas fa-h",
          "fas fa-h1",
          "fas fa-h2",
          "fas fa-h3",
          "fas fa-h4",
          "fas fa-h5",
          "fas fa-h6",
          "fas fa-hammer",
          "fas fa-hammer-crash",
          "fas fa-hammer-war",
          "fas fa-hamsa",
          "fas fa-hand",
          "fas fa-hand-back-fist",
          "fas fa-hand-back-point-down",
          "fas fa-hand-back-point-left",
          "fas fa-hand-back-point-ribbon",
          "fas fa-hand-back-point-right",
          "fas fa-hand-back-point-up",
          "fas fa-hand-dots",
          "fas fa-hand-fingers-crossed",
          "fas fa-hand-fist",
          "fas fa-hand-heart",
          "fas fa-hand-holding",
          "fas fa-hand-holding-box",
          "fas fa-hand-holding-dollar",
          "fas fa-hand-holding-droplet",
          "fas fa-hand-holding-hand",
          "fas fa-hand-holding-heart",
          "fas fa-hand-holding-magic",
          "fas fa-hand-holding-medical",
          "fas fa-hand-holding-seedling",
          "fas fa-hand-holding-skull",
          "fas fa-hand-horns",
          "fas fa-hand-lizard",
          "fas fa-hand-love",
          "fas fa-hand-middle-finger",
          "fas fa-hand-peace",
          "fas fa-hand-point-down",
          "fas fa-hand-point-left",
          "fas fa-hand-point-ribbon",
          "fas fa-hand-point-right",
          "fas fa-hand-point-up",
          "fas fa-hand-pointer",
          "fas fa-hand-scissors",
          "fas fa-hand-sparkles",
          "fas fa-hand-spock",
          "fas fa-hand-wave",
          "fas fa-handcuffs",
          "fas fa-hands",
          "fas fa-hands-asl-interpreting",
          "fas fa-hands-bound",
          "fas fa-hands-bubbles",
          "fas fa-hands-clapping",
          "fas fa-hands-holding",
          "fas fa-hands-holding-child",
          "fas fa-hands-holding-circle",
          "fas fa-hands-holding-diamond",
          "fas fa-hands-holding-dollar",
          "fas fa-hands-holding-heart",
          "fas fa-hands-praying",
          "fas fa-handshake",
          "fas fa-handshake-angle",
          "fas fa-handshake-simple",
          "fas fa-handshake-simple-slash",
          "fas fa-handshake-slash",
          "fas fa-hanukiah",
          "fas fa-hard-drive",
          "fas fa-hashtag",
          "fas fa-hashtag-lock",
          "fas fa-hat-chef",
          "fas fa-hat-cowboy",
          "fas fa-hat-cowboy-side",
          "fas fa-hat-santa",
          "fas fa-hat-winter",
          "fas fa-hat-witch",
          "fas fa-hat-wizard",
          "fas fa-head-side",
          "fas fa-head-side-brain",
          "fas fa-head-side-cough",
          "fas fa-head-side-cough-slash",
          "fas fa-head-side-goggles",
          "fas fa-head-side-headphones",
          "fas fa-head-side-heart",
          "fas fa-head-side-mask",
          "fas fa-head-side-medical",
          "fas fa-head-side-virus",
          "fas fa-heading",
          "fas fa-headphones",
          "fas fa-headphones-simple",
          "fas fa-headset",
          "fas fa-heart",
          "fas fa-heart-circle-bolt",
          "fas fa-heart-circle-check",
          "fas fa-heart-circle-exclamation",
          "fas fa-heart-circle-minus",
          "fas fa-heart-circle-plus",
          "fas fa-heart-circle-xmark",
          "fas fa-heart-crack",
          "fas fa-heart-half",
          "fas fa-heart-half-stroke",
          "fas fa-heart-pulse",
          "fas fa-heat",
          "fas fa-helicopter",
          "fas fa-helicopter-symbol",
          "fas fa-helmet-battle",
          "fas fa-helmet-safety",
          "fas fa-helmet-un",
          "fas fa-hexagon",
          "fas fa-hexagon-check",
          "fas fa-hexagon-divide",
          "fas fa-hexagon-exclamation",
          "fas fa-hexagon-image",
          "fas fa-hexagon-minus",
          "fas fa-hexagon-plus",
          "fas fa-hexagon-vertical-nft",
          "fas fa-hexagon-vertical-nft-slanted",
          "fas fa-hexagon-xmark",
          "fas fa-high-definition",
          "fas fa-highlighter",
          "fas fa-highlighter-line",
          "fas fa-hill-avalanche",
          "fas fa-hill-rockslide",
          "fas fa-hippo",
          "fas fa-hockey-mask",
          "fas fa-hockey-puck",
          "fas fa-hockey-stick-puck",
          "fas fa-hockey-sticks",
          "fas fa-holly-berry",
          "fas fa-honey-pot",
          "fas fa-hood-cloak",
          "fas fa-horizontal-rule",
          "fas fa-horse",
          "fas fa-horse-head",
          "fas fa-horse-saddle",
          "fas fa-hose",
          "fas fa-hose-reel",
          "fas fa-hospital",
          "fas fa-hospital-user",
          "fas fa-hospitals",
          "fas fa-hot-tub-person",
          "fas fa-hotdog",
          "fas fa-hotel",
          "fas fa-hourglass",
          "fas fa-hourglass-clock",
          "fas fa-hourglass-end",
          "fas fa-hourglass-half",
          "fas fa-hourglass-start",
          "fas fa-house",
          "fas fa-house-blank",
          "fas fa-house-building",
          "fas fa-house-chimney",
          "fas fa-house-chimney-blank",
          "fas fa-house-chimney-crack",
          "fas fa-house-chimney-heart",
          "fas fa-house-chimney-medical",
          "fas fa-house-chimney-user",
          "fas fa-house-chimney-window",
          "fas fa-house-circle-check",
          "fas fa-house-circle-exclamation",
          "fas fa-house-circle-xmark",
          "fas fa-house-crack",
          "fas fa-house-day",
          "fas fa-house-fire",
          "fas fa-house-flag",
          "fas fa-house-flood-water",
          "fas fa-house-flood-water-circle-arrow-right",
          "fas fa-house-heart",
          "fas fa-house-laptop",
          "fas fa-house-lock",
          "fas fa-house-medical",
          "fas fa-house-medical-circle-check",
          "fas fa-house-medical-circle-exclamation",
          "fas fa-house-medical-circle-xmark",
          "fas fa-house-medical-flag",
          "fas fa-house-night",
          "fas fa-house-person-leave",
          "fas fa-house-person-return",
          "fas fa-house-signal",
          "fas fa-house-tree",
          "fas fa-house-tsunami",
          "fas fa-house-turret",
          "fas fa-house-user",
          "fas fa-house-water",
          "fas fa-house-window",
          "fas fa-hryvnia-sign",
          "fas fa-hundred-points",
          "fas fa-hurricane",
          "fas fa-hyphen",
          "fas fa-i",
          "fas fa-i-cursor",
          "fas fa-ice-cream",
          "fas fa-ice-skate",
          "fas fa-icicles",
          "fas fa-icons",
          "fas fa-id-badge",
          "fas fa-id-card",
          "fas fa-id-card-clip",
          "fas fa-igloo",
          "fas fa-image",
          "fas fa-image-landscape",
          "fas fa-image-polaroid",
          "fas fa-image-polaroid-user",
          "fas fa-image-portrait",
          "fas fa-image-slash",
          "fas fa-image-user",
          "fas fa-images",
          "fas fa-images-user",
          "fas fa-inbox",
          "fas fa-inbox-full",
          "fas fa-inbox-in",
          "fas fa-inbox-out",
          "fas fa-inboxes",
          "fas fa-indent",
          "fas fa-indian-rupee-sign",
          "fas fa-industry",
          "fas fa-industry-windows",
          "fas fa-infinity",
          "fas fa-info",
          "fas fa-inhaler",
          "fas fa-input-numeric",
          "fas fa-input-pipe",
          "fas fa-input-text",
          "fas fa-integral",
          "fas fa-interrobang",
          "fas fa-intersection",
          "fas fa-island-tropical",
          "fas fa-italic",
          "fas fa-j",
          "fas fa-jack-o-lantern",
          "fas fa-jar",
          "fas fa-jar-wheat",
          "fas fa-jedi",
          "fas fa-jet-fighter",
          "fas fa-jet-fighter-up",
          "fas fa-joint",
          "fas fa-joystick",
          "fas fa-jug",
          "fas fa-jug-detergent",
          "fas fa-k",
          "fas fa-kaaba",
          "fas fa-kazoo",
          "fas fa-kerning",
          "fas fa-key",
          "fas fa-key-skeleton",
          "fas fa-key-skeleton-left-right",
          "fas fa-keyboard",
          "fas fa-keyboard-brightness",
          "fas fa-keyboard-brightness-low",
          "fas fa-keyboard-down",
          "fas fa-keyboard-left",
          "fas fa-keynote",
          "fas fa-khanda",
          "fas fa-kidneys",
          "fas fa-kip-sign",
          "fas fa-kit-medical",
          "fas fa-kitchen-set",
          "fas fa-kite",
          "fas fa-kiwi-bird",
          "fas fa-kiwi-fruit",
          "fas fa-knife",
          "fas fa-knife-kitchen",
          "fas fa-l",
          "fas fa-lacrosse-stick",
          "fas fa-lacrosse-stick-ball",
          "fas fa-lambda",
          "fas fa-lamp",
          "fas fa-lamp-desk",
          "fas fa-lamp-floor",
          "fas fa-lamp-street",
          "fas fa-land-mine-on",
          "fas fa-landmark",
          "fas fa-landmark-dome",
          "fas fa-landmark-flag",
          "fas fa-language",
          "fas fa-laptop",
          "fas fa-laptop-arrow-down",
          "fas fa-laptop-code",
          "fas fa-laptop-file",
          "fas fa-laptop-medical",
          "fas fa-laptop-mobile",
          "fas fa-laptop-slash",
          "fas fa-lari-sign",
          "fas fa-lasso",
          "fas fa-lasso-sparkles",
          "fas fa-layer-group",
          "fas fa-layer-minus",
          "fas fa-layer-plus",
          "fas fa-leaf",
          "fas fa-leaf-heart",
          "fas fa-leaf-maple",
          "fas fa-leaf-oak",
          "fas fa-leafy-green",
          "fas fa-left",
          "fas fa-left-from-line",
          "fas fa-left-long",
          "fas fa-left-long-to-line",
          "fas fa-left-right",
          "fas fa-left-to-line",
          "fas fa-lemon",
          "fas fa-less-than",
          "fas fa-less-than-equal",
          "fas fa-life-ring",
          "fas fal-ceiling",
          "fas fal-emergency",
          "fas fal-emergency-on",
          "fas fal-switch",
          "fas fal-switch-off",
          "fas fal-switch-on",
          "fas falbulb",
          "fas falbulb-cfl",
          "fas falbulb-cfl-on",
          "fas falbulb-dollar",
          "fas falbulb-exclamation",
          "fas falbulb-exclamation-on",
          "fas falbulb-on",
          "fas falbulb-slash",
          "fas fals-holiday",
          "fas fa-line-columns",
          "fas fa-line-height",
          "fas fa-lines-leaning",
          "fas fa-link",
          "fas fa-link-horizontal",
          "fas fa-link-horizontal-slash",
          "fas fa-link-simple",
          "fas fa-link-simple-slash",
          "fas fa-link-slash",
          "fas fa-lips",
          "fas fa-lira-sign",
          "fas fa-list",
          "fas fa-list-check",
          "fas fa-list-dropdown",
          "fas fa-list-music",
          "fas fa-list-ol",
          "fas fa-list-radio",
          "fas fa-list-timeline",
          "fas fa-list-tree",
          "fas fa-list-ul",
          "fas fa-litecoin-sign",
          "fas fa-loader",
          "fas fa-lobster",
          "fas fa-location-arrow",
          "fas fa-location-check",
          "fas fa-location-crosshairs",
          "fas fa-location-crosshairs-slash",
          "fas fa-location-dot",
          "fas fa-location-dot-slash",
          "fas fa-location-exclamation",
          "fas fa-location-minus",
          "fas fa-location-pen",
          "fas fa-location-pin",
          "fas fa-location-pin-lock",
          "fas fa-location-pin-slash",
          "fas fa-location-plus",
          "fas fa-location-question",
          "fas fa-location-smile",
          "fas fa-location-xmark",
          "fas fa-lock",
          "fas fa-lock-a",
          "fas fa-lock-hashtag",
          "fas fa-lock-keyhole",
          "fas fa-lock-keyhole-open",
          "fas fa-lock-open",
          "fas fa-locust",
          "fas fa-lollipop",
          "fas fa-loveseat",
          "fas fa-luchador-mask",
          "fas fa-lungs",
          "fas fa-lungs-virus",
          "fas fa-m",
          "fas fa-mace",
          "fas fa-magnet",
          "fas fa-magnifying-glass",
          "fas fa-magnifying-glass-arrow-right",
          "fas fa-magnifying-glass-chart",
          "fas fa-magnifying-glass-dollar",
          "fas fa-magnifying-glass-location",
          "fas fa-magnifying-glass-minus",
          "fas fa-magnifying-glass-plus",
          "fas fa-mailbox",
          "fas fa-mailbox-flag-up",
          "fas fa-manat-sign",
          "fas fa-mandolin",
          "fas fa-mango",
          "fas fa-manhole",
          "fas fa-map",
          "fas fa-map-location",
          "fas fa-map-location-dot",
          "fas fa-map-pin",
          "fas fa-marker",
          "fas fa-mars",
          "fas fa-mars-and-venus",
          "fas fa-mars-and-venus-burst",
          "fas fa-mars-double",
          "fas fa-mars-stroke",
          "fas fa-mars-stroke-right",
          "fas fa-mars-stroke-up",
          "fas fa-martini-glass",
          "fas fa-martini-glass-citrus",
          "fas fa-martini-glass-empty",
          "fas fa-mask",
          "fas fa-mask-face",
          "fas fa-mask-snorkel",
          "fas fa-mask-ventilator",
          "fas fa-masks-theater",
          "fas fa-mattress-pillow",
          "fas fa-maximize",
          "fas fa-meat",
          "fas fa-medal",
          "fas fa-megaphone",
          "fas fa-melon",
          "fas fa-melon-slice",
          "fas fa-memo",
          "fas fa-memo-circle-check",
          "fas fa-memo-circle-info",
          "fas fa-memo-pad",
          "fas fa-memory",
          "fas fa-menorah",
          "fas fa-mercury",
          "fas fa-merge",
          "fas fa-message",
          "fas fa-message-arrow-down",
          "fas fa-message-arrow-up",
          "fas fa-message-arrow-up-right",
          "fas fa-message-bot",
          "fas fa-message-captions",
          "fas fa-message-check",
          "fas fa-message-code",
          "fas fa-message-dollar",
          "fas fa-message-dots",
          "fas fa-message-exclamation",
          "fas fa-message-heart",
          "fas fa-message-image",
          "fas fa-message-lines",
          "fas fa-message-medical",
          "fas fa-message-middle",
          "fas fa-message-middle-top",
          "fas fa-message-minus",
          "fas fa-message-music",
          "fas fa-message-pen",
          "fas fa-message-plus",
          "fas fa-message-question",
          "fas fa-message-quote",
          "fas fa-message-slash",
          "fas fa-message-smile",
          "fas fa-message-sms",
          "fas fa-message-text",
          "fas fa-message-xmark",
          "fas fa-messages",
          "fas fa-messages-dollar",
          "fas fa-messages-question",
          "fas fa-meteor",
          "fas fa-meter",
          "fas fa-meter-bolt",
          "fas fa-meter-droplet",
          "fas fa-meter-fire",
          "fas fa-microchip",
          "fas fa-microchip-ai",
          "fas fa-microphone",
          "fas fa-microphone-lines",
          "fas fa-microphone-lines-slash",
          "fas fa-microphone-slash",
          "fas fa-microphone-stand",
          "fas fa-microscope",
          "fas fa-microwave",
          "fas fa-mill-sign",
          "fas fa-minimize",
          "fas fa-minus",
          "fas fa-mistletoe",
          "fas fa-mitten",
          "fas fa-mobile",
          "fas fa-mobile-button",
          "fas fa-mobile-notch",
          "fas fa-mobile-retro",
          "fas fa-mobile-screen",
          "fas fa-mobile-screen-button",
          "fas fa-mobile-signal",
          "fas fa-mobile-signal-out",
          "fas fa-money-bill",
          "fas fa-money-bill-1",
          "fas fa-money-bill-1-wave",
          "fas fa-money-bill-simple",
          "fas fa-money-bill-simple-wave",
          "fas fa-money-bill-transfer",
          "fas fa-money-bill-trend-up",
          "fas fa-money-bill-wave",
          "fas fa-money-bill-wheat",
          "fas fa-money-bills",
          "fas fa-money-bills-simple",
          "fas fa-money-check",
          "fas fa-money-check-dollar",
          "fas fa-money-check-dollar-pen",
          "fas fa-money-check-pen",
          "fas fa-money-from-bracket",
          "fas fa-money-simple-from-bracket",
          "fas fa-monitor-waveform",
          "fas fa-monkey",
          "fas fa-monument",
          "fas fa-moon",
          "fas fa-moon-cloud",
          "fas fa-moon-over-sun",
          "fas fa-moon-stars",
          "fas fa-moped",
          "fas fa-mortar-pestle",
          "fas fa-mosque",
          "fas fa-mosquito",
          "fas fa-mosquito-net",
          "fas fa-motorcycle",
          "fas fa-mound",
          "fas fa-mountain",
          "fas fa-mountain-city",
          "fas fa-mountain-sun",
          "fas fa-mountains",
          "fas fa-mouse-field",
          "fas fa-mp3-player",
          "fas fa-mug",
          "fas fa-mug-hot",
          "fas fa-mug-marshmallows",
          "fas fa-mug-saucer",
          "fas fa-mug-tea",
          "fas fa-mug-tea-saucer",
          "fas fa-mushroom",
          "fas fa-music",
          "fas fa-music-note",
          "fas fa-music-note-slash",
          "fas fa-music-slash",
          "fas fa-mustache",
          "fas fa-n",
          "fas fa-naira-sign",
          "fas fa-narwhal",
          "fas fa-nesting-dolls",
          "fas fa-network-wired",
          "fas fa-neuter",
          "fas fa-newspaper",
          "fas fa-nfc",
          "fas fa-nfc-lock",
          "fas fa-nfc-magnifying-glass",
          "fas fa-nfc-pen",
          "fas fa-nfc-signal",
          "fas fa-nfc-slash",
          "fas fa-nfc-symbol",
          "fas fa-nfc-trash",
          "fas fa-nose",
          "fas fa-not-equal",
          "fas fa-notdef",
          "fas fa-note",
          "fas fa-note-medical",
          "fas fa-note-sticky",
          "fas fa-notebook",
          "fas fa-notes",
          "fas fa-notes-medical",
          "fas fa-o",
          "fas fa-object-exclude",
          "fas fa-object-group",
          "fas fa-object-intersect",
          "fas fa-object-subtract",
          "fas fa-object-ungroup",
          "fas fa-object-union",
          "fas fa-objects-align-bottom",
          "fas fa-objects-align-center-horizontal",
          "fas fa-objects-align-center-vertical",
          "fas fa-objects-align-left",
          "fas fa-objects-align-right",
          "fas fa-objects-align-top",
          "fas fa-objects-column",
          "fas fa-octagon",
          "fas fa-octagon-check",
          "fas fa-octagon-divide",
          "fas fa-octagon-exclamation",
          "fas fa-octagon-minus",
          "fas fa-octagon-plus",
          "fas fa-octagon-xmark",
          "fas fa-oil-can",
          "fas fa-oil-can-drip",
          "fas fa-oil-temperature",
          "fas fa-oil-well",
          "fas fa-olive",
          "fas fa-olive-branch",
          "fas fa-om",
          "fas fa-omega",
          "fas fa-onion",
          "fas fa-option",
          "fas fa-ornament",
          "fas fa-otter",
          "fas fa-outdent",
          "fas fa-outlet",
          "fas fa-oven",
          "fas fa-overline",
          "fas fa-p",
          "fas fa-page",
          "fas fa-page-caret-down",
          "fas fa-page-caret-up",
          "fas fa-pager",
          "fas fa-paint-roller",
          "fas fa-paintbrush",
          "fas fa-paintbrush-fine",
          "fas fa-paintbrush-pencil",
          "fas fa-palette",
          "fas fa-pallet",
          "fas fa-pallet-box",
          "fas fa-pallet-boxes",
          "fas fa-pan-food",
          "fas fa-pan-frying",
          "fas fa-pancakes",
          "fas fa-panel-ews",
          "fas fa-panel-fire",
          "fas fa-panorama",
          "fas fa-paper-plane",
          "fas fa-paper-plane-top",
          "fas fa-paperclip",
          "fas fa-paperclip-vertical",
          "fas fa-parachute-box",
          "fas fa-paragraph",
          "fas fa-paragraph-left",
          "fas fa-party-bell",
          "fas fa-party-horn",
          "fas fa-passport",
          "fas fa-paste",
          "fas fa-pause",
          "fas fa-paw",
          "fas fa-paw-claws",
          "fas fa-paw-simple",
          "fas fa-peace",
          "fas fa-peach",
          "fas fa-peanut",
          "fas fa-peanuts",
          "fas fa-peapod",
          "fas fa-pear",
          "fas fa-pedestal",
          "fas fa-pegasus",
          "fas fa-pen",
          "fas fa-pen-circle",
          "fas fa-pen-clip",
          "fas fa-pen-clip-slash",
          "fas fa-pen-fancy",
          "fas fa-pen-fancy-slash",
          "fas fa-pen-field",
          "fas fa-pen-line",
          "fas fa-pen-nib",
          "fas fa-pen-nib-slash",
          "fas fa-pen-paintbrush",
          "fas fa-pen-ruler",
          "fas fa-pen-slash",
          "fas fa-pen-swirl",
          "fas fa-pen-to-square",
          "fas fa-pencil",
          "fas fa-pencil-mechanical",
          "fas fa-pencil-slash",
          "fas fa-people",
          "fas fa-people-arrows",
          "fas fa-people-carry-box",
          "fas fa-people-dress",
          "fas fa-people-dress-simple",
          "fas fa-people-group",
          "fas fa-people-line",
          "fas fa-people-pants",
          "fas fa-people-pants-simple",
          "fas fa-people-pulling",
          "fas fa-people-robbery",
          "fas fa-people-roof",
          "fas fa-people-simple",
          "fas fa-pepper",
          "fas fa-pepper-hot",
          "fas fa-percent",
          "fas fa-period",
          "fas fa-person",
          "fas fa-person-arrow-down-to-line",
          "fas fa-person-arrow-up-from-line",
          "fas fa-person-biking",
          "fas fa-person-biking-mountain",
          "fas fa-person-booth",
          "fas fa-person-breastfeeding",
          "fas fa-person-burst",
          "fas fa-person-cane",
          "fas fa-person-carry-box",
          "fas fa-person-chalkboard",
          "fas fa-person-circle-check",
          "fas fa-person-circle-exclamation",
          "fas fa-person-circle-minus",
          "fas fa-person-circle-plus",
          "fas fa-person-circle-question",
          "fas fa-person-circle-xmark",
          "fas fa-person-digging",
          "fas fa-person-dolly",
          "fas fa-person-dolly-empty",
          "fas fa-person-dots-from-line",
          "fas fa-person-dress",
          "fas fa-person-dress-burst",
          "fas fa-person-dress-simple",
          "fas fa-person-drowning",
          "fas fa-person-falling",
          "fas fa-person-falling-burst",
          "fas fa-person-from-portal",
          "fas fa-person-half-dress",
          "fas fa-person-harassing",
          "fas fa-person-hiking",
          "fas fa-person-military-pointing",
          "fas fa-person-military-rifle",
          "fas fa-person-military-to-person",
          "fas fa-person-pinball",
          "fas fa-person-praying",
          "fas fa-person-pregnant",
          "fas fa-person-rays",
          "fas fa-person-rifle",
          "fas fa-person-running",
          "fas fa-person-seat",
          "fas fa-person-seat-reclined",
          "fas fa-person-shelter",
          "fas fa-person-sign",
          "fas fa-person-simple",
          "fas fa-person-skating",
          "fas fa-person-ski-jumping",
          "fas fa-person-ski-lift",
          "fas fa-person-skiing",
          "fas fa-person-skiing-nordic",
          "fas fa-person-sledding",
          "fas fa-person-snowboarding",
          "fas fa-person-snowmobiling",
          "fas fa-person-swimming",
          "fas fa-person-through-window",
          "fas fa-person-to-door",
          "fas fa-person-to-portal",
          "fas fa-person-walking",
          "fas fa-person-walking-arrow-loop-left",
          "fas fa-person-walking-arrow-right",
          "fas fa-person-walking-dashed-line-arrow-right",
          "fas fa-person-walking-luggage",
          "fas fa-person-walking-with-cane",
          "fas fa-peseta-sign",
          "fas fa-peso-sign",
          "fas fa-phone",
          "fas fa-phone-arrow-down-left",
          "fas fa-phone-arrow-right",
          "fas fa-phone-arrow-up-right",
          "fas fa-phone-flip",
          "fas fa-phone-hangup",
          "fas fa-phone-intercom",
          "fas fa-phone-missed",
          "fas fa-phone-office",
          "fas fa-phone-plus",
          "fas fa-phone-rotary",
          "fas fa-phone-slash",
          "fas fa-phone-volume",
          "fas fa-phone-xmark",
          "fas fa-photo-film",
          "fas fa-photo-film-music",
          "fas fa-pi",
          "fas fa-piano",
          "fas fa-piano-keyboard",
          "fas fa-pickaxe",
          "fas fa-pickleball",
          "fas fa-pie",
          "fas fa-pig",
          "fas fa-piggy-bank",
          "fas fa-pills",
          "fas fa-pinata",
          "fas fa-pinball",
          "fas fa-pineapple",
          "fas fa-pipe",
          "fas fa-pipe-circle-check",
          "fas fa-pipe-collar",
          "fas fa-pipe-section",
          "fas fa-pipe-smoking",
          "fas fa-pipe-valve",
          "fas fa-pizza",
          "fas fa-pizza-slice",
          "fas fa-place-of-worship",
          "fas fa-plane",
          "fas fa-plane-arrival",
          "fas fa-plane-circle-check",
          "fas fa-plane-circle-exclamation",
          "fas fa-plane-circle-xmark",
          "fas fa-plane-departure",
          "fas fa-plane-engines",
          "fas fa-plane-lock",
          "fas fa-plane-prop",
          "fas fa-plane-slash",
          "fas fa-plane-tail",
          "fas fa-plane-up",
          "fas fa-plane-up-slash",
          "fas fa-planet-moon",
          "fas fa-planet-ringed",
          "fas fa-plant-wilt",
          "fas fa-plate-utensils",
          "fas fa-plate-wheat",
          "fas fa-play",
          "fas fa-play-pause",
          "fas fa-plug",
          "fas fa-plug-circle-bolt",
          "fas fa-plug-circle-check",
          "fas fa-plug-circle-exclamation",
          "fas fa-plug-circle-minus",
          "fas fa-plug-circle-plus",
          "fas fa-plug-circle-xmark",
          "fas fa-plus",
          "fas fa-plus-large",
          "fas fa-plus-minus",
          "fas fa-podcast",
          "fas fa-podium",
          "fas fa-podium-star",
          "fas fa-police-box",
          "fas fa-poll-people",
          "fas fa-pompebled",
          "fas fa-poo",
          "fas fa-poo-storm",
          "fas fa-pool-8-ball",
          "fas fa-poop",
          "fas fa-popcorn",
          "fas fa-popsicle",
          "fas fa-pot-food",
          "fas fa-potato",
          "fas fa-power-off",
          "fas fa-prescription",
          "fas fa-prescription-bottle",
          "fas fa-prescription-bottle-medical",
          "fas fa-prescription-bottle-pill",
          "fas fa-presentation-screen",
          "fas fa-pretzel",
          "fas fa-print",
          "fas fa-print-magnifying-glass",
          "fas fa-print-slash",
          "fas fa-projector",
          "fas fa-pump",
          "fas fa-pump-medical",
          "fas fa-pump-soap",
          "fas fa-pumpkin",
          "fas fa-puzzle",
          "fas fa-puzzle-piece",
          "fas fa-puzzle-piece-simple",
          "fas fa-q",
          "fas fa-qrcode",
          "fas fa-question",
          "fas fa-quote-left",
          "fas fa-quote-right",
          "fas fa-quotes",
          "fas fa-r",
          "fas fa-rabbit",
          "fas fa-rabbit-running",
          "fas fa-racquet",
          "fas fa-radar",
          "fas fa-radiation",
          "fas fa-radio",
          "fas fa-radio-tuner",
          "fas fa-rainbow",
          "fas fa-raindrops",
          "fas fa-ram",
          "fas fa-ramp-loading",
          "fas fa-ranking-star",
          "fas fa-raygun",
          "fas fa-receipt",
          "fas fa-record-vinyl",
          "fas fa-rectangle",
          "fas fa-rectangle-ad",
          "fas fa-rectangle-barcode",
          "fas fa-rectangle-code",
          "fas fa-rectangle-history",
          "fas fa-rectangle-history-circle-plus",
          "fas fa-rectangle-history-circle-user",
          "fas fa-rectangle-list",
          "fas fa-rectangle-pro",
          "fas fa-rectangle-terminal",
          "fas fa-rectangle-vertical",
          "fas fa-rectangle-vertical-history",
          "fas fa-rectangle-wide",
          "fas fa-rectangle-xmark",
          "fas fa-rectangles-mixed",
          "fas fa-recycle",
          "fas fa-reel",
          "fas fa-refrigerator",
          "fas fa-registered",
          "fas fa-repeat",
          "fas fa-repeat-1",
          "fas fa-reply",
          "fas fa-reply-all",
          "fas fa-reply-clock",
          "fas fa-republican",
          "fas fa-restroom",
          "fas fa-restroom-simple",
          "fas fa-retweet",
          "fas fa-rhombus",
          "fas fa-ribbon",
          "fas fa-right",
          "fas fa-right-from-bracket",
          "fas fa-right-from-line",
          "fas fa-right-left",
          "fas fa-right-long",
          "fas fa-right-long-to-line",
          "fas fa-right-to-bracket",
          "fas fa-right-to-line",
          "fas fa-ring",
          "fas fa-ring-diamond",
          "fas fa-rings-wedding",
          "fas fa-road",
          "fas fa-road-barrier",
          "fas fa-road-bridge",
          "fas fa-road-circle-check",
          "fas fa-road-circle-exclamation",
          "fas fa-road-circle-xmark",
          "fas fa-road-lock",
          "fas fa-road-spikes",
          "fas fa-robot",
          "fas fa-robot-astromech",
          "fas fa-rocket",
          "fas fa-rocket-launch",
          "fas fa-roller-coaster",
          "fas fa-rotate",
          "fas fa-rotate-exclamation",
          "fas fa-rotate-left",
          "fas fa-rotate-right",
          "fas fa-route",
          "fas fa-route-highway",
          "fas fa-route-interstate",
          "fas fa-router",
          "fas fa-rss",
          "fas fa-ruble-sign",
          "fas fa-rug",
          "fas fa-rugby-ball",
          "fas fa-ruler",
          "fas fa-ruler-combined",
          "fas fa-ruler-horizontal",
          "fas fa-ruler-triangle",
          "fas fa-ruler-vertical",
          "fas fa-rupee-sign",
          "fas fa-rupiah-sign",
          "fas fa-rv",
          "fas fa-s",
          "fas fa-sack",
          "fas fa-sack-dollar",
          "fas fa-sack-xmark",
          "fas fa-sailboat",
          "fas fa-salad",
          "fas fa-salt-shaker",
          "fas fa-sandwich",
          "fas fa-satellite",
          "fas fa-satellite-dish",
          "fas fa-sausage",
          "fas fa-saxophone",
          "fas fa-saxophone-fire",
          "fas fa-scale-balanced",
          "fas fa-scale-unbalanced",
          "fas fa-scale-unbalanced-flip",
          "fas fa-scalpel",
          "fas fa-scalpel-line-dashed",
          "fas fa-scanner-gun",
          "fas fa-scanner-image",
          "fas fa-scanner-keyboard",
          "fas fa-scanner-touchscreen",
          "fas fa-scarecrow",
          "fas fa-scarf",
          "fas fa-school",
          "fas fa-school-circle-check",
          "fas fa-school-circle-exclamation",
          "fas fa-school-circle-xmark",
          "fas fa-school-flag",
          "fas fa-school-lock",
          "fas fa-scissors",
          "fas fa-screen-users",
          "fas fa-screencast",
          "fas fa-screwdriver",
          "fas fa-screwdriver-wrench",
          "fas fa-scribble",
          "fas fa-scroll",
          "fas fa-scroll-old",
          "fas fa-scroll-torah",
          "fas fa-scrubber",
          "fas fa-scythe",
          "fas fa-sd-card",
          "fas fa-sd-cards",
          "fas fa-seal",
          "fas fa-seal-exclamation",
          "fas fa-seal-question",
          "fas fa-seat-airline",
          "fas fa-section",
          "fas fa-seedling",
          "fas fa-semicolon",
          "fas fa-send-back",
          "fas fa-send-backward",
          "fas fa-sensor",
          "fas fa-sensor-cloud",
          "fas fa-sensor-fire",
          "fas fa-sensor-on",
          "fas fa-sensor-triangle-exclamation",
          "fas fa-server",
          "fas fa-shapes",
          "fas fa-share",
          "fas fa-share-all",
          "fas fa-share-from-square",
          "fas fa-share-nodes",
          "fas fa-sheep",
          "fas fa-sheet-plastic",
          "fas fa-shekel-sign",
          "fas fa-shelves",
          "fas fa-shelves-empty",
          "fas fa-shield",
          "fas fa-shield-cat",
          "fas fa-shield-check",
          "fas fa-shield-cross",
          "fas fa-shield-dog",
          "fas fa-shield-exclamation",
          "fas fa-shield-halved",
          "fas fa-shield-heart",
          "fas fa-shield-keyhole",
          "fas fa-shield-minus",
          "fas fa-shield-plus",
          "fas fa-shield-quartered",
          "fas fa-shield-slash",
          "fas fa-shield-virus",
          "fas fa-shield-xmark",
          "fas fa-ship",
          "fas fa-shirt",
          "fas fa-shirt-long-sleeve",
          "fas fa-shirt-running",
          "fas fa-shirt-tank-top",
          "fas fa-shish-kebab",
          "fas fa-shoe-prints",
          "fas fa-shop",
          "fas fa-shop-lock",
          "fas fa-shop-slash",
          "fas fa-shovel",
          "fas fa-shovel-snow",
          "fas fa-shower",
          "fas fa-shower-down",
          "fas fa-shredder",
          "fas fa-shrimp",
          "fas fa-shuffle",
          "fas fa-shutters",
          "fas fa-shuttle-space",
          "fas fa-shuttlecock",
          "fas fa-sickle",
          "fas fa-sidebar",
          "fas fa-sidebar-flip",
          "fas fa-sigma",
          "fas fa-sign-hanging",
          "fas fa-signal",
          "fas fa-signal-bars",
          "fas fa-signal-bars-fair",
          "fas fa-signal-bars-good",
          "fas fa-signal-bars-slash",
          "fas fa-signal-bars-weak",
          "fas fa-signal-fair",
          "fas fa-signal-good",
          "fas fa-signal-slash",
          "fas fa-signal-stream",
          "fas fa-signal-stream-slash",
          "fas fa-signal-strong",
          "fas fa-signal-weak",
          "fas fa-signature",
          "fas fa-signature-lock",
          "fas fa-signature-slash",
          "fas fa-signs-post",
          "fas fa-sim-card",
          "fas fa-sim-cards",
          "fas fa-sink",
          "fas fa-siren",
          "fas fa-siren-on",
          "fas fa-sitemap",
          "fas fa-skeleton",
          "fas fa-skeleton-ribs",
          "fas fa-ski-boot",
          "fas fa-ski-boot-ski",
          "fas fa-skull",
          "fas fa-skull-cow",
          "fas fa-skull-crossbones",
          "fas fa-slash",
          "fas fa-slash-back",
          "fas fa-slash-forward",
          "fas fa-sleigh",
          "fas fa-slider",
          "fas fa-sliders",
          "fas fa-sliders-simple",
          "fas fa-sliders-up",
          "fas fa-slot-machine",
          "fas fa-smog",
          "fas fa-smoke",
          "fas fa-smoking",
          "fas fa-snake",
          "fas fa-snooze",
          "fas fa-snow-blowing",
          "fas fa-snowflake",
          "fas fa-snowflake-droplets",
          "fas fa-snowflakes",
          "fas fa-snowman",
          "fas fa-snowman-head",
          "fas fa-snowplow",
          "fas fa-soap",
          "fas fa-socks",
          "fas fa-soft-serve",
          "fas fa-solar-panel",
          "fas fa-solar-system",
          "fas fa-sort",
          "fas fa-sort-down",
          "fas fa-sort-up",
          "fas fa-spa",
          "fas fa-space-station-moon",
          "fas fa-space-station-moon-construction",
          "fas fa-spade",
          "fas fa-spaghetti-monster-flying",
          "fas fa-sparkle",
          "fas fa-sparkles",
          "fas fa-speaker",
          "fas fa-speakers",
          "fas fa-spell-check",
          "fas fa-spider",
          "fas fa-spider-black-widow",
          "fas fa-spider-web",
          "fas fa-spinner",
          "fas fa-spinner-third",
          "fas fa-split",
          "fas fa-splotch",
          "fas fa-spoon",
          "fas fa-sportsball",
          "fas fa-spray-can",
          "fas fa-spray-can-sparkles",
          "fas fa-sprinkler",
          "fas fa-sprinkler-ceiling",
          "fas fa-square",
          "fas fa-square-0",
          "fas fa-square-1",
          "fas fa-square-2",
          "fas fa-square-3",
          "fas fa-square-4",
          "fas fa-square-5",
          "fas fa-square-6",
          "fas fa-square-7",
          "fas fa-square-8",
          "fas fa-square-9",
          "fas fa-square-a",
          "fas fa-square-a-lock",
          "fas fa-square-ampersand",
          "fas fa-square-arrow-down",
          "fas fa-square-arrow-down-left",
          "fas fa-square-arrow-down-right",
          "fas fa-square-arrow-left",
          "fas fa-square-arrow-right",
          "fas fa-square-arrow-up",
          "fas fa-square-arrow-up-left",
          "fas fa-square-arrow-up-right",
          "fas fa-square-b",
          "fas fa-square-bolt",
          "fas fa-square-c",
          "fas fa-square-caret-down",
          "fas fa-square-caret-left",
          "fas fa-square-caret-right",
          "fas fa-square-caret-up",
          "fas fa-square-check",
          "fas fa-square-chevron-down",
          "fas fa-square-chevron-left",
          "fas fa-square-chevron-right",
          "fas fa-square-chevron-up",
          "fas fa-square-code",
          "fas fa-square-d",
          "fas fa-square-dashed",
          "fas fa-square-dashed-circle-plus",
          "fas fa-square-divide",
          "fas fa-square-dollar",
          "fas fa-square-down",
          "fas fa-square-down-left",
          "fas fa-square-down-right",
          "fas fa-square-e",
          "fas fa-square-ellipsis",
          "fas fa-square-ellipsis-vertical",
          "fas fa-square-envelope",
          "fas fa-square-exclamation",
          "fas fa-square-f",
          "fas fa-square-fragile",
          "fas fa-square-full",
          "fas fa-square-g",
          "fas fa-square-h",
          "fas fa-square-heart",
          "fas fa-square-i",
          "fas fa-square-info",
          "fas fa-square-j",
          "fas fa-square-k",
          "fas fa-square-kanban",
          "fas fa-square-l",
          "fas fa-square-left",
          "fas fa-square-list",
          "fas fa-square-m",
          "fas fa-square-minus",
          "fas fa-square-n",
          "fas fa-square-nfi",
          "fas fa-square-o",
          "fas fa-square-p",
          "fas fa-square-parking",
          "fas fa-square-parking-slash",
          "fas fa-square-pen",
          "fas fa-square-person-confined",
          "fas fa-square-phone",
          "fas fa-square-phone-flip",
          "fas fa-square-phone-hangup",
          "fas fa-square-plus",
          "fas fa-square-poll-horizontal",
          "fas fa-square-poll-vertical",
          "fas fa-square-q",
          "fas fa-square-quarters",
          "fas fa-square-question",
          "fas fa-square-quote",
          "fas fa-square-r",
          "fas fa-square-right",
          "fas fa-square-ring",
          "fas fa-square-root",
          "fas fa-square-root-variable",
          "fas fa-square-rss",
          "fas fa-square-s",
          "fas fa-square-share-nodes",
          "fas fa-square-sliders",
          "fas fa-square-sliders-vertical",
          "fas fa-square-small",
          "fas fa-square-star",
          "fas fa-square-t",
          "fas fa-square-terminal",
          "fas fa-square-this-way-up",
          "fas fa-square-u",
          "fas fa-square-up",
          "fas fa-square-up-left",
          "fas fa-square-up-right",
          "fas fa-square-user",
          "fas fa-square-v",
          "fas fa-square-virus",
          "fas fa-square-w",
          "fas fa-square-x",
          "fas fa-square-xmark",
          "fas fa-square-y",
          "fas fa-square-z",
          "fas fa-squid",
          "fas fa-squirrel",
          "fas fa-staff",
          "fas fa-staff-snake",
          "fas fa-stairs",
          "fas fa-stamp",
          "fas fa-standard-definition",
          "fas fa-stapler",
          "fas fa-star",
          "fas fa-star-and-crescent",
          "fas fa-star-christmas",
          "fas fa-star-exclamation",
          "fas fa-star-half",
          "fas fa-star-half-stroke",
          "fas fa-star-of-david",
          "fas fa-star-of-life",
          "fas fa-star-sharp",
          "fas fa-star-sharp-half",
          "fas fa-star-sharp-half-stroke",
          "fas fa-star-shooting",
          "fas fa-starfighter",
          "fas fa-starfighter-twin-ion-engine",
          "fas fa-starfighter-twin-ion-engine-advanced",
          "fas fa-stars",
          "fas fa-starship",
          "fas fa-starship-freighter",
          "fas fa-steak",
          "fas fa-steering-wheel",
          "fas fa-sterling-sign",
          "fas fa-stethoscope",
          "fas fa-stocking",
          "fas fa-stomach",
          "fas fa-stop",
          "fas fa-stopwatch",
          "fas fa-stopwatch-20",
          "fas fa-store",
          "fas fa-store-lock",
          "fas fa-store-slash",
          "fas fa-strawberry",
          "fas fa-street-view",
          "fas fa-stretcher",
          "fas fa-strikethrough",
          "fas fa-stroopwafel",
          "fas fa-subscript",
          "fas fa-suitcase",
          "fas fa-suitcase-medical",
          "fas fa-suitcase-rolling",
          "fas fa-sun",
          "fas fa-sun-bright",
          "fas fa-sun-cloud",
          "fas fa-sun-dust",
          "fas fa-sun-haze",
          "fas fa-sun-plant-wilt",
          "fas fa-sunglasses",
          "fas fa-sunrise",
          "fas fa-sunset",
          "fas fa-superscript",
          "fas fa-sushi",
          "fas fa-sushi-roll",
          "fas fa-swatchbook",
          "fas fa-sword",
          "fas fa-sword-laser",
          "fas fa-sword-laser-alt",
          "fas fa-swords",
          "fas fa-swords-laser",
          "fas fa-symbols",
          "fas fa-synagogue",
          "fas fa-syringe",
          "fas fa-t",
          "fas fa-table",
          "fas fa-table-cells",
          "fas fa-table-cells-large",
          "fas fa-table-columns",
          "fas fa-table-layout",
          "fas fa-table-list",
          "fas fa-table-picnic",
          "fas fa-table-pivot",
          "fas fa-table-rows",
          "fas fa-table-tennis-paddle-ball",
          "fas fa-table-tree",
          "fas fa-tablet",
          "fas fa-tablet-button",
          "fas fa-tablet-rugged",
          "fas fa-tablet-screen",
          "fas fa-tablet-screen-button",
          "fas fa-tablets",
          "fas fa-tachograph-digital",
          "fas fa-taco",
          "fas fa-tag",
          "fas fa-tags",
          "fas fa-tally",
          "fas fa-tally-1",
          "fas fa-tally-2",
          "fas fa-tally-3",
          "fas fa-tally-4",
          "fas fa-tamale",
          "fas fa-tank-water",
          "fas fa-tape",
          "fas fa-tarp",
          "fas fa-tarp-droplet",
          "fas fa-taxi",
          "fas fa-taxi-bus",
          "fas fa-teddy-bear",
          "fas fa-teeth",
          "fas fa-teeth-open",
          "fas fa-telescope",
          "fas fa-temperature-arrow-down",
          "fas fa-temperature-arrow-up",
          "fas fa-temperature-empty",
          "fas fa-temperature-full",
          "fas fa-temperature-half",
          "fas fa-temperature-high",
          "fas fa-temperature-list",
          "fas fa-temperature-low",
          "fas fa-temperature-quarter",
          "fas fa-temperature-snow",
          "fas fa-temperature-sun",
          "fas fa-temperature-three-quarters",
          "fas fa-tenge-sign",
          "fas fa-tennis-ball",
          "fas fa-tent",
          "fas fa-tent-arrow-down-to-line",
          "fas fa-tent-arrow-left-right",
          "fas fa-tent-arrow-turn-left",
          "fas fa-tent-arrows-down",
          "fas fa-tents",
          "fas fa-terminal",
          "fas fa-text",
          "fas fa-text-height",
          "fas fa-text-size",
          "fas fa-text-slash",
          "fas fa-text-width",
          "fas fa-thermometer",
          "fas fa-theta",
          "fas fa-thought-bubble",
          "fas fa-thumbs-down",
          "fas fa-thumbs-up",
          "fas fa-thumbtack",
          "fas fa-tick",
          "fas fa-ticket",
          "fas fa-ticket-airline",
          "fas fa-ticket-simple",
          "fas fa-tickets-airline",
          "fas fa-tilde",
          "fas fa-timeline",
          "fas fa-timeline-arrow",
          "fas fa-timer",
          "fas fa-tire",
          "fas fa-tire-flat",
          "fas fa-tire-pressure-warning",
          "fas fa-tire-rugged",
          "fas fa-toggle-large-off",
          "fas fa-toggle-large-on",
          "fas fa-toggle-off",
          "fas fa-toggle-on",
          "fas fa-toilet",
          "fas fa-toilet-paper",
          "fas fa-toilet-paper-blank",
          "fas fa-toilet-paper-blank-under",
          "fas fa-toilet-paper-check",
          "fas fa-toilet-paper-slash",
          "fas fa-toilet-paper-under",
          "fas fa-toilet-paper-under-slash",
          "fas fa-toilet-paper-xmark",
          "fas fa-toilet-portable",
          "fas fa-toilets-portable",
          "fas fa-tomato",
          "fas fa-tombstone",
          "fas fa-tombstone-blank",
          "fas fa-toolbox",
          "fas fa-tooth",
          "fas fa-toothbrush",
          "fas fa-torii-gate",
          "fas fa-tornado",
          "fas fa-tower-broadcast",
          "fas fa-tower-cell",
          "fas fa-tower-control",
          "fas fa-tower-observation",
          "fas fa-tractor",
          "fas fa-trademark",
          "fas fa-traffic-cone",
          "fas fa-traffic-light",
          "fas fa-traffic-light-go",
          "fas fa-traffic-light-slow",
          "fas fa-traffic-light-stop",
          "fas fa-trailer",
          "fas fa-train",
          "fas fa-train-subway",
          "fas fa-train-subway-tunnel",
          "fas fa-train-track",
          "fas fa-train-tram",
          "fas fa-train-tunnel",
          "fas fa-transformer-bolt",
          "fas fa-transgender",
          "fas fa-transporter",
          "fas fa-transporter-1",
          "fas fa-transporter-2",
          "fas fa-transporter-3",
          "fas fa-transporter-4",
          "fas fa-transporter-5",
          "fas fa-transporter-6",
          "fas fa-transporter-7",
          "fas fa-transporter-empty",
          "fas fa-trash",
          "fas fa-trash-arrow-up",
          "fas fa-trash-can",
          "fas fa-trash-can-arrow-up",
          "fas fa-trash-can-check",
          "fas fa-trash-can-clock",
          "fas fa-trash-can-list",
          "fas fa-trash-can-plus",
          "fas fa-trash-can-slash",
          "fas fa-trash-can-undo",
          "fas fa-trash-can-xmark",
          "fas fa-trash-check",
          "fas fa-trash-clock",
          "fas fa-trash-list",
          "fas fa-trash-plus",
          "fas fa-trash-slash",
          "fas fa-trash-undo",
          "fas fa-trash-xmark",
          "fas fa-treasure-chest",
          "fas fa-tree",
          "fas fa-tree-christmas",
          "fas fa-tree-city",
          "fas fa-tree-deciduous",
          "fas fa-tree-decorated",
          "fas fa-tree-large",
          "fas fa-tree-palm",
          "fas fa-trees",
          "fas fa-triangle",
          "fas fa-triangle-exclamation",
          "fas fa-triangle-instrument",
          "fas fa-triangle-person-digging",
          "fas fa-tricycle",
          "fas fa-tricycle-adult",
          "fas fa-trillium",
          "fas fa-trophy",
          "fas fa-trophy-star",
          "fas fa-trowel",
          "fas fa-trowel-bricks",
          "fas fa-truck",
          "fas fa-truck-arrow-right",
          "fas fa-truck-bolt",
          "fas fa-truck-clock",
          "fas fa-truck-container",
          "fas fa-truck-container-empty",
          "fas fa-truck-droplet",
          "fas fa-truck-fast",
          "fas fa-truck-field",
          "fas fa-truck-field-un",
          "fas fa-truck-flatbed",
          "fas fa-truck-front",
          "fas fa-truck-medical",
          "fas fa-truck-monster",
          "fas fa-truck-moving",
          "fas fa-truck-pickup",
          "fas fa-truck-plane",
          "fas fa-truck-plow",
          "fas fa-truck-ramp",
          "fas fa-truck-ramp-box",
          "fas fa-truck-ramp-couch",
          "fas fa-truck-tow",
          "fas fa-trumpet",
          "fas fa-tty",
          "fas fa-tty-answer",
          "fas fa-tugrik-sign",
          "fas fa-turkey",
          "fas fa-turkish-lira-sign",
          "fas fa-turn-down",
          "fas fa-turn-down-left",
          "fas fa-turn-down-right",
          "fas fa-turn-up",
          "fas fa-turntable",
          "fas fa-turtle",
          "fas fa-tv",
          "fas fa-tv-music",
          "fas fa-tv-retro",
          "fas fa-typewriter",
          "fas fa-u",
          "fas fa-ufo",
          "fas fa-ufo-beam",
          "fas fa-umbrella",
          "fas fa-umbrella-beach",
          "fas fa-umbrella-simple",
          "fas fa-underline",
          "fas fa-unicorn",
          "fas fa-uniform-martial-arts",
          "fas fa-union",
          "fas fa-universal-access",
          "fas fa-unlock",
          "fas fa-unlock-keyhole",
          "fas fa-up",
          "fas fa-up-down",
          "fas fa-up-down-left-right",
          "fas fa-up-from-bracket",
          "fas fa-up-from-dotted-line",
          "fas fa-up-from-line",
          "fas fa-up-left",
          "fas fa-up-long",
          "fas fa-up-right",
          "fas fa-up-right-and-down-left-from-center",
          "fas fa-up-right-from-square",
          "fas fa-up-to-dotted-line",
          "fas fa-up-to-line",
          "fas fa-upload",
          "fas fa-usb-drive",
          "fas fa-user",
          "fas fa-user-alien",
          "fas fa-user-astronaut",
          "fas fa-user-bounty-hunter",
          "fas fa-user-check",
          "fas fa-user-chef",
          "fas fa-user-clock",
          "fas fa-user-cowboy",
          "fas fa-user-crown",
          "fas fa-user-doctor",
          "fas fa-user-doctor-hair",
          "fas fa-user-doctor-hair-long",
          "fas fa-user-doctor-message",
          "fas fa-user-gear",
          "fas fa-user-graduate",
          "fas fa-user-group",
          "fas fa-user-group-crown",
          "fas fa-user-hair",
          "fas fa-user-hair-buns",
          "fas fa-user-hair-long",
          "fas fa-user-hair-mullet",
          "fas fa-user-headset",
          "fas fa-user-helmet-safety",
          "fas fa-user-injured",
          "fas fa-user-large",
          "fas fa-user-large-slash",
          "fas fa-user-lock",
          "fas fa-user-magnifying-glass",
          "fas fa-user-minus",
          "fas fa-user-music",
          "fas fa-user-ninja",
          "fas fa-user-nurse",
          "fas fa-user-nurse-hair",
          "fas fa-user-nurse-hair-long",
          "fas fa-user-pen",
          "fas fa-user-pilot",
          "fas fa-user-pilot-tie",
          "fas fa-user-plus",
          "fas fa-user-police",
          "fas fa-user-police-tie",
          "fas fa-user-robot",
          "fas fa-user-robot-xmarks",
          "fas fa-user-secret",
          "fas fa-user-shakespeare",
          "fas fa-user-shield",
          "fas fa-user-slash",
          "fas fa-user-tag",
          "fas fa-user-tie",
          "fas fa-user-tie-hair",
          "fas fa-user-tie-hair-long",
          "fas fa-user-unlock",
          "fas fa-user-visor",
          "fas fa-user-vneck",
          "fas fa-user-vneck-hair",
          "fas fa-user-vneck-hair-long",
          "fas fa-user-xmark",
          "fas fa-users",
          "fas fa-users-between-lines",
          "fas fa-users-gear",
          "fas fa-users-line",
          "fas fa-users-medical",
          "fas fa-users-rays",
          "fas fa-users-rectangle",
          "fas fa-users-slash",
          "fas fa-users-viewfinder",
          "fas fa-utensils",
          "fas fa-utensils-slash",
          "fas fa-utility-pole",
          "fas fa-utility-pole-double",
          "fas fa-v",
          "fas fa-vacuum",
          "fas fa-vacuum-robot",
          "fas fa-value-absolute",
          "fas fa-van-shuttle",
          "fas fa-vault",
          "fas fa-vector-circle",
          "fas fa-vector-polygon",
          "fas fa-vector-square",
          "fas fa-vent-damper",
          "fas fa-venus",
          "fas fa-venus-double",
          "fas fa-venus-mars",
          "fas fa-vest",
          "fas fa-vest-patches",
          "fas fa-vial",
          "fas fa-vial-circle-check",
          "fas fa-vial-virus",
          "fas fa-vials",
          "fas fa-video",
          "fas fa-video-arrow-down-left",
          "fas fa-video-arrow-up-right",
          "fas fa-video-plus",
          "fas fa-video-slash",
          "fas fa-vihara",
          "fas fa-violin",
          "fas fa-virus",
          "fas fa-virus-covid",
          "fas fa-virus-covid-slash",
          "fas fa-virus-slash",
          "fas fa-viruses",
          "fas fa-voicemail",
          "fas fa-volcano",
          "fas fa-volleyball",
          "fas fa-volume",
          "fas fa-volume-high",
          "fas fa-volume-low",
          "fas fa-volume-off",
          "fas fa-volume-slash",
          "fas fa-volume-xmark",
          "fas fa-vr-cardboard",
          "fas fa-w",
          "fas fa-waffle",
          "fas fa-wagon-covered",
          "fas fa-walker",
          "fas fa-walkie-talkie",
          "fas fa-wallet",
          "fas fa-wand",
          "fas fa-wand-magic",
          "fas fa-wand-magic-sparkles",
          "fas fa-wand-sparkles",
          "fas fa-warehouse",
          "fas fa-warehouse-full",
          "fas fa-washing-machine",
          "fas fa-watch",
          "fas fa-watch-apple",
          "fas fa-watch-calculator",
          "fas fa-watch-fitness",
          "fas fa-watch-smart",
          "fas fa-water",
          "fas fa-water-arrow-down",
          "fas fa-water-arrow-up",
          "fas fa-water-ladder",
          "fas fa-watermelon-slice",
          "fas fa-wave-pulse",
          "fas fa-wave-sine",
          "fas fa-wave-square",
          "fas fa-wave-triangle",
          "fas fa-waveform",
          "fas fa-waveform-lines",
          "fas fa-webhook",
          "fas fa-weight-hanging",
          "fas fa-weight-scale",
          "fas fa-whale",
          "fas fa-wheat",
          "fas fa-wheat-awn",
          "fas fa-wheat-awn-circle-exclamation",
          "fas fa-wheat-awn-slash",
          "fas fa-wheat-slash",
          "fas fa-wheelchair",
          "fas fa-wheelchair-move",
          "fas fa-whiskey-glass",
          "fas fa-whiskey-glass-ice",
          "fas fa-whistle",
          "fas fa-wifi",
          "fas fa-wifi-exclamation",
          "fas fa-wifi-fair",
          "fas fa-wifi-slash",
          "fas fa-wifi-weak",
          "fas fa-wind",
          "fas fa-wind-turbine",
          "fas fa-wind-warning",
          "fas fa-window",
          "fas fa-window-flip",
          "fas fa-window-frame",
          "fas fa-window-frame-open",
          "fas fa-window-maximize",
          "fas fa-window-minimize",
          "fas fa-window-restore",
          "fas fa-windsock",
          "fas fa-wine-bottle",
          "fas fa-wine-glass",
          "fas fa-wine-glass-crack",
          "fas fa-wine-glass-empty",
          "fas fa-won-sign",
          "fas fa-worm",
          "fas fa-wreath",
          "fas fa-wreath-laurel",
          "fas fa-wrench",
          "fas fa-wrench-simple",
          "fas fa-x",
          "fas fa-x-ray",
          "fas fa-xmark",
          "fas fa-xmark-large",
          "fas fa-xmark-to-slot",
          "fas fa-xmarks-lines",
          "fas fa-y",
          "fas fa-yen-sign",
          "fas fa-yin-yang",
          "fas fa-z"
        ]
      },
      brands: {
        title: 'Brands',
        prefix: 'fab fa-',
        iconstyle: 'fab',
        listicon: 'fab fa-font-awesome-flag',        
        icons: [
          "fab fa-42-group",
          "fab fa-500px",
          "fab fa-accessible-icon",
          "fab fa-accusoft",
          "fab fa-adn",
          "fab fa-adversal",
          "fab fa-affiliatetheme",
          "fab fa-airbnb",
          "fab fa-algolia",
          "fab fa-alipay",
          "fab fa-amazon",
          "fab fa-amazon-pay",
          "fab fa-amilia",
          "fab fa-android",
          "fab fa-angellist",
          "fab fa-angrycreative",
          "fab fa-angular",
          "fab fa-app-store",
          "fab fa-app-store-ios",
          "fab fa-apper",
          "fab fa-apple",
          "fab fa-apple-pay",
          "fab fa-artstation",
          "fab fa-asymmetrik",
          "fab fa-atlassian",
          "fab fa-audible",
          "fab fa-autoprefixer",
          "fab fa-avianex",
          "fab fa-aviato",
          "fab fa-aws",
          "fab fa-bandcamp",
          "fab fa-battle-net",
          "fab fa-behance",
          "fab fa-bilibili",
          "fab fa-bimobject",
          "fab fa-bitbucket",
          "fab fa-bitcoin",
          "fab fa-bity",
          "fab fa-black-tie",
          "fab fa-blackberry",
          "fab fa-blogger",
          "fab fa-blogger-b",
          "fab fa-bluetooth",
          "fab fa-bluetooth-b",
          "fab fa-bootstrap",
          "fab fa-bots",
          "fab fa-btc",
          "fab fa-buffer",
          "fab fa-buromobelexperte",
          "fab fa-buy-n-large",
          "fab fa-buysellads",
          "fab fa-canadian-maple-leaf",
          "fab fa-cc-amazon-pay",
          "fab fa-cc-amex",
          "fab fa-cc-apple-pay",
          "fab fa-cc-diners-club",
          "fab fa-cc-discover",
          "fab fa-cc-jcb",
          "fab fa-cc-mastercard",
          "fab fa-cc-paypal",
          "fab fa-cc-stripe",
          "fab fa-cc-visa",
          "fab fa-centercode",
          "fab fa-centos",
          "fab fa-chrome",
          "fab fa-chromecast",
          "fab fa-cloudflare",
          "fab fa-cloudscale",
          "fab fa-cloudsmith",
          "fab fa-cloudversify",
          "fab fa-cmplid",
          "fab fa-codepen",
          "fab fa-codiepie",
          "fab fa-confluence",
          "fab fa-connectdevelop",
          "fab fa-contao",
          "fab fa-cotton-bureau",
          "fab fa-cpanel",
          "fab fa-creative-commons",
          "fab fa-creative-commons-by",
          "fab fa-creative-commons-nc",
          "fab fa-creative-commons-nc-eu",
          "fab fa-creative-commons-nc-jp",
          "fab fa-creative-commons-nd",
          "fab fa-creative-commons-pd",
          "fab fa-creative-commons-pd-alt",
          "fab fa-creative-commons-remix",
          "fab fa-creative-commons-sa",
          "fab fa-creative-commons-sampling",
          "fab fa-creative-commons-sampling-plus",
          "fab fa-creative-commons-share",
          "fab fa-creative-commons-zero",
          "fab fa-critical-role",
          "fab fa-css3",
          "fab fa-css3-alt",
          "fab fa-cuttlefish",
          "fab fa-d-and-d",
          "fab fa-d-and-d-beyond",
          "fab fa-dailymotion",
          "fab fa-dashcube",
          "fab fa-deezer",
          "fab fa-delicious",
          "fab fa-deploydog",
          "fab fa-deskpro",
          "fab fa-dev",
          "fab fa-deviantart",
          "fab fa-dhl",
          "fab fa-diaspora",
          "fab fa-digg",
          "fab fa-digital-ocean",
          "fab fa-discord",
          "fab fa-discourse",
          "fab fa-dochub",
          "fab fa-docker",
          "fab fa-draft2digital",
          "fab fa-dribbble",
          "fab fa-dropbox",
          "fab fa-drupal",
          "fab fa-dyalog",
          "fab fa-earlybirds",
          "fab fa-ebay",
          "fab fa-edge",
          "fab fa-edge-legacy",
          "fab fa-elementor",
          "fab fa-ello",
          "fab fa-ember",
          "fab fa-empire",
          "fab fa-envira",
          "fab fa-erlang",
          "fab fa-ethereum",
          "fab fa-etsy",
          "fab fa-evernote",
          "fab fa-expeditedssl",
          "fab fa-facebook",
          "fab fa-facebook-f",
          "fab fa-facebook-messenger",
          "fab fa-fantasy-flight-games",
          "fab fa-fedex",
          "fab fa-fedora",
          "fab fa-figma",
          "fab fa-firefox",
          "fab fa-firefox-browser",
          "fab fa-first-order",
          "fab fa-first-order-alt",
          "fab fa-firstdraft",
          "fab fa-flickr",
          "fab fa-flipboard",
          "fab fa-fly",
          "fab fa-font-awesome",
          "fab fa-fonticons",
          "fab fa-fonticons-fi",
          "fab fa-fort-awesome",
          "fab fa-fort-awesome-alt",
          "fab fa-forumbee",
          "fab fa-foursquare",
          "fab fa-free-code-camp",
          "fab fa-freebsd",
          "fab fa-fulcrum",
          "fab fa-galactic-republic",
          "fab fa-galactic-senate",
          "fab fa-get-pocket",
          "fab fa-gg",
          "fab fa-gg-circle",
          "fab fa-git",
          "fab fa-git-alt",
          "fab fa-github",
          "fab fa-github-alt",
          "fab fa-gitkraken",
          "fab fa-gitlab",
          "fab fa-gitter",
          "fab fa-glide",
          "fab fa-glide-g",
          "fab fa-gofore",
          "fab fa-golang",
          "fab fa-goodreads",
          "fab fa-goodreads-g",
          "fab fa-google",
          "fab fa-google-drive",
          "fab fa-google-pay",
          "fab fa-google-play",
          "fab fa-google-plus",
          "fab fa-google-plus-g",
          "fab fa-google-wallet",
          "fab fa-gratipay",
          "fab fa-grav",
          "fab fa-gripfire",
          "fab fa-grunt",
          "fab fa-guilded",
          "fab fa-gulp",
          "fab fa-hacker-news",
          "fab fa-hackerrank",
          "fab fa-hashnode",
          "fab fa-hips",
          "fab fa-hire-a-helper",
          "fab fa-hive",
          "fab fa-hooli",
          "fab fa-hornbill",
          "fab fa-hotjar",
          "fab fa-houzz",
          "fab fa-html5",
          "fab fa-hubspot",
          "fab fa-ideal",
          "fab fa-imdb",
          "fab fa-instagram",
          "fab fa-instalod",
          "fab fa-intercom",
          "fab fa-internet-explorer",
          "fab fa-invision",
          "fab fa-ioxhost",
          "fab fa-itch-io",
          "fab fa-itunes",
          "fab fa-itunes-note",
          "fab fa-java",
          "fab fa-jedi-order",
          "fab fa-jenkins",
          "fab fa-jira",
          "fab fa-joget",
          "fab fa-joomla",
          "fab fa-js",
          "fab fa-jsfiddle",
          "fab fa-kaggle",
          "fab fa-keybase",
          "fab fa-keycdn",
          "fab fa-kickstarter",
          "fab fa-kickstarter-k",
          "fab fa-korvue",
          "fab fa-laravel",
          "fab fa-lastfm",
          "fab fa-leanpub",
          "fab fa-less",
          "fab fa-line",
          "fab fa-linkedin",
          "fab fa-linkedin-in",
          "fab fa-linode",
          "fab fa-linux",
          "fab fa-lyft",
          "fab fa-magento",
          "fab fa-mailchimp",
          "fab fa-mandalorian",
          "fab fa-markdown",
          "fab fa-mastodon",
          "fab fa-maxcdn",
          "fab fa-mdb",
          "fab fa-medapps",
          "fab fa-medium",
          "fab fa-medrt",
          "fab fa-meetup",
          "fab fa-megaport",
          "fab fa-mendeley",
          "fab fa-meta",
          "fab fa-microblog",
          "fab fa-microsoft",
          "fab fa-mix",
          "fab fa-mixcloud",
          "fab fa-mixer",
          "fab fa-mizuni",
          "fab fa-modx",
          "fab fa-monero",
          "fab fa-napster",
          "fab fa-neos",
          "fab fa-nfc-directional",
          "fab fa-nfc-symbol",
          "fab fa-nimblr",
          "fab fa-node",
          "fab fa-node-js",
          "fab fa-npm",
          "fab fa-ns8",
          "fab fa-nutritionix",
          "fab fa-octopus-deploy",
          "fab fa-odnoklassniki",
          "fab fa-odysee",
          "fab fa-old-republic",
          "fab fa-opencart",
          "fab fa-openid",
          "fab fa-opera",
          "fab fa-optin-monster",
          "fab fa-orcid",
          "fab fa-osi",
          "fab fa-padlet",
          "fab fa-page4",
          "fab fa-pagelines",
          "fab fa-palfed",
          "fab fa-patreon",
          "fab fa-paypal",
          "fab fa-perbyte",
          "fab fa-periscope",
          "fab fa-phabricator",
          "fab fa-phoenix-framework",
          "fab fa-phoenix-squadron",
          "fab fa-php",
          "fab fa-pied-piper",
          "fab fa-pied-piper-alt",
          "fab fa-pied-piper-hat",
          "fab fa-pied-piper-pp",
          "fab fa-pinterest",
          "fab fa-pinterest-p",
          "fab fa-pix",
          "fab fa-playstation",
          "fab fa-product-hunt",
          "fab fa-pushed",
          "fab fa-python",
          "fab fa-qq",
          "fab fa-quinscape",
          "fab fa-quora",
          "fab fa-r-project",
          "fab fa-raspberry-pi",
          "fab fa-ravelry",
          "fab fa-react",
          "fab fa-reacteurope",
          "fab fa-readme",
          "fab fa-rebel",
          "fab fa-red-river",
          "fab fa-reddit",
          "fab fa-reddit-alien",
          "fab fa-redhat",
          "fab fa-renren",
          "fab fa-replyd",
          "fab fa-researchgate",
          "fab fa-resolving",
          "fab fa-rev",
          "fab fa-rocketchat",
          "fab fa-rockrms",
          "fab fa-rust",
          "fab fa-safari",
          "fab fa-salesforce",
          "fab fa-sass",
          "fab fa-schlix",
          "fab fa-screenpal",
          "fab fa-scribd",
          "fab fa-searchengin",
          "fab fa-sellcast",
          "fab fa-sellsy",
          "fab fa-servicestack",
          "fab fa-shirtsinbulk",
          "fab fa-shopify",
          "fab fa-shopware",
          "fab fa-simplybuilt",
          "fab fa-sistrix",
          "fab fa-sith",
          "fab fa-sitrox",
          "fab fa-sketch",
          "fab fa-skyatlas",
          "fab fa-skype",
          "fab fa-slack",
          "fab fa-slideshare",
          "fab fa-snapchat",
          "fab fa-soundcloud",
          "fab fa-sourcetree",
          "fab fa-space-awesome",
          "fab fa-speakap",
          "fab fa-speaker-deck",
          "fab fa-spotify",
          "fab fa-square-behance",
          "fab fa-square-dribbble",
          "fab fa-square-facebook",
          "fab fa-square-font-awesome",
          "fab fa-square-font-awesome-stroke",
          "fab fa-square-git",
          "fab fa-square-github",
          "fab fa-square-gitlab",
          "fab fa-square-google-plus",
          "fab fa-square-hacker-news",
          "fab fa-square-instagram",
          "fab fa-square-js",
          "fab fa-square-lastfm",
          "fab fa-square-odnoklassniki",
          "fab fa-square-pied-piper",
          "fab fa-square-pinterest",
          "fab fa-square-reddit",
          "fab fa-square-snapchat",
          "fab fa-square-steam",
          "fab fa-square-tumblr",
          "fab fa-square-twitter",
          "fab fa-square-viadeo",
          "fab fa-square-vimeo",
          "fab fa-square-whatsapp",
          "fab fa-square-xing",
          "fab fa-square-youtube",
          "fab fa-squarespace",
          "fab fa-stack-exchange",
          "fab fa-stack-overflow",
          "fab fa-stackpath",
          "fab fa-staylinked",
          "fab fa-steam",
          "fab fa-steam-symbol",
          "fab fa-sticker-mule",
          "fab fa-strava",
          "fab fa-stripe",
          "fab fa-stripe-s",
          "fab fa-stubber",
          "fab fa-studiovinari",
          "fab fa-stumbleupon",
          "fab fa-stumbleupon-circle",
          "fab fa-superpowers",
          "fab fa-supple",
          "fab fa-suse",
          "fab fa-swift",
          "fab fa-symfony",
          "fab fa-teamspeak",
          "fab fa-telegram",
          "fab fa-tencent-weibo",
          "fab fa-the-red-yeti",
          "fab fa-themeco",
          "fab fa-themeisle",
          "fab fatk-peaks",
          "fab fa-tiktok",
          "fab fa-trade-federation",
          "fab fa-trello",
          "fab fa-tumblr",
          "fab fa-twitch",
          "fab fa-twitter",
          "fab fa-typo3",
          "fab fa-uber",
          "fab fa-ubuntu",
          "fab fa-uikit",
          "fab fa-umbraco",
          "fab fa-uncharted",
          "fab fa-uniregistry",
          "fab fa-unity",
          "fab fa-unsplash",
          "fab fa-untappd",
          "fab fa-ups",
          "fab fa-usb",
          "fab fa-usps",
          "fab fa-ussunnah",
          "fab fa-vaadin",
          "fab fa-viacoin",
          "fab fa-viadeo",
          "fab fa-viber",
          "fab fa-vimeo",
          "fab fa-vimeo-v",
          "fab fa-vine",
          "fab fa-vk",
          "fab fa-vnv",
          "fab fa-vuejs",
          "fab fa-watchman-monitoring",
          "fab fa-waze",
          "fab fa-weebly",
          "fab fa-weibo",
          "fab fa-weixin",
          "fab fa-whatsapp",
          "fab fa-whmcs",
          "fab fa-wikipedia-w",
          "fab fa-windows",
          "fab fa-wirsindhandwerk",
          "fab fa-wix",
          "fab fa-wizards-of-the-coast",
          "fab fa-wodu",
          "fab fa-wolf-pack-battalion",
          "fab fa-wordpress",
          "fab fa-wordpress-simple",
          "fab fa-wpbeginner",
          "fab fa-wpexplorer",
          "fab fa-wpforms",
          "fab fa-wpressr",
          "fab fa-xbox",
          "fab fa-xing",
          "fab fa-y-combinator",
          "fab fa-yahoo",
          "fab fa-yammer",
          "fab fa-yandex",
          "fab fa-yandex-international",
          "fab fa-yarn",
          "fab fa-yelp",
          "fab fa-yoast",
          "fab fa-youtube",
          "fab fa-zhihu"
        ]
      },
      light: {
        title: 'light',
        prefix: 'fal fa-',
        iconstyle: 'fal',
        listicon: 'fal fa-flag',        
        icons: [
          "fal fa-0",
          "fal fa-1",
          "fal fa-2",
          "fal fa-3",
          "fal fa-4",
          "fal fa-5",
          "fal fa-6",
          "fal fa-7",
          "fal fa-8",
          "fal fa-9",
          "fal fa-00",
          "fal fa-360-degrees",
          "fal fa-a",
          "fal fa-abacus",
          "fal fa-accent-grave",
          "fal fa-acorn",
          "fal fa-address-book",
          "fal fa-address-card",
          "fal fa-air-conditioner",
          "fal fa-airplay",
          "fal fa-alarm-clock",
          "fal fa-alarm-exclamation",
          "fal fa-alarm-plus",
          "fal fa-alarm-snooze",
          "fal fa-album",
          "fal fa-album-circle-plus",
          "fal fa-album-circle-user",
          "fal fa-album-collection",
          "fal fa-album-collection-circle-plus",
          "fal fa-album-collection-circle-user",
          "fal fa-alicorn",
          "fal fa-alien",
          "fal fa-alien-8bit",
          "fal fa-align-center",
          "fal fa-align-justify",
          "fal fa-align-left",
          "fal fa-align-right",
          "fal fa-align-slash",
          "fal fa-alt",
          "fal fa-amp-guitar",
          "fal fa-ampersand",
          "fal fa-anchor",
          "fal fa-anchor-circle-check",
          "fal fa-anchor-circle-exclamation",
          "fal fa-anchor-circle-xmark",
          "fal fa-anchor-lock",
          "fal fa-angel",
          "fal fa-angle",
          "fal fa-angle-90",
          "fal fa-angle-down",
          "fal fa-angle-left",
          "fal fa-angle-right",
          "fal fa-angle-up",
          "fal fa-angles-down",
          "fal fa-angles-left",
          "fal fa-angles-right",
          "fal fa-angles-up",
          "fal fa-ankh",
          "fal fa-apartment",
          "fal fa-aperture",
          "fal fa-apostrophe",
          "fal fa-apple-core",
          "fal fa-apple-whole",
          "fal fa-archway",
          "fal fa-arrow-down",
          "fal fa-arrow-down-1-9",
          "fal fa-arrow-down-9-1",
          "fal fa-arrow-down-a-z",
          "fal fa-arrow-down-arrow-up",
          "fal fa-arrow-down-big-small",
          "fal fa-arrow-down-from-dotted-line",
          "fal fa-arrow-down-from-line",
          "fal fa-arrow-down-left",
          "fal fa-arrow-down-left-and-arrow-up-right-to-center",
          "fal fa-arrow-down-long",
          "fal fa-arrow-down-right",
          "fal fa-arrow-down-short-wide",
          "fal fa-arrow-down-small-big",
          "fal fa-arrow-down-square-triangle",
          "fal fa-arrow-down-to-arc",
          "fal fa-arrow-down-to-bracket",
          "fal fa-arrow-down-to-dotted-line",
          "fal fa-arrow-down-to-line",
          "fal fa-arrow-down-to-square",
          "fal fa-arrow-down-triangle-square",
          "fal fa-arrow-down-up-across-line",
          "fal fa-arrow-down-up-lock",
          "fal fa-arrow-down-wide-short",
          "fal fa-arrow-down-z-a",
          "fal fa-arrow-left",
          "fal fa-arrow-left-from-line",
          "fal fa-arrow-left-long",
          "fal fa-arrow-left-long-to-line",
          "fal fa-arrow-left-to-line",
          "fal fa-arrow-pointer",
          "fal fa-arrow-right",
          "fal fa-arrow-right-arrow-left",
          "fal fa-arrow-right-from-arc",
          "fal fa-arrow-right-from-bracket",
          "fal fa-arrow-right-from-line",
          "fal fa-arrow-right-long",
          "fal fa-arrow-right-long-to-line",
          "fal fa-arrow-right-to-arc",
          "fal fa-arrow-right-to-bracket",
          "fal fa-arrow-right-to-city",
          "fal fa-arrow-right-to-line",
          "fal fa-arrow-rotate-left",
          "fal fa-arrow-rotate-right",
          "fal fa-arrow-trend-down",
          "fal fa-arrow-trend-up",
          "fal fa-arrow-turn-down",
          "fal fa-arrow-turn-down-left",
          "fal fa-arrow-turn-down-right",
          "fal fa-arrow-turn-up",
          "fal fa-arrow-up",
          "fal fa-arrow-up-1-9",
          "fal fa-arrow-up-9-1",
          "fal fa-arrow-up-a-z",
          "fal fa-arrow-up-arrow-down",
          "fal fa-arrow-up-big-small",
          "fal fa-arrow-up-from-arc",
          "fal fa-arrow-up-from-bracket",
          "fal fa-arrow-up-from-dotted-line",
          "fal fa-arrow-up-from-ground-water",
          "fal fa-arrow-up-from-line",
          "fal fa-arrow-up-from-square",
          "fal fa-arrow-up-from-water-pump",
          "fal fa-arrow-up-left",
          "fal fa-arrow-up-left-from-circle",
          "fal fa-arrow-up-long",
          "fal fa-arrow-up-right",
          "fal fa-arrow-up-right-and-arrow-down-left-from-center",
          "fal fa-arrow-up-right-dots",
          "fal fa-arrow-up-right-from-square",
          "fal fa-arrow-up-short-wide",
          "fal fa-arrow-up-small-big",
          "fal fa-arrow-up-square-triangle",
          "fal fa-arrow-up-to-dotted-line",
          "fal fa-arrow-up-to-line",
          "fal fa-arrow-up-triangle-square",
          "fal fa-arrow-up-wide-short",
          "fal fa-arrow-up-z-a",
          "fal fa-arrows-cross",
          "fal fa-arrows-down-to-line",
          "fal fa-arrows-down-to-people",
          "fal fa-arrows-from-dotted-line",
          "fal fa-arrows-from-line",
          "fal fa-arrows-left-right",
          "fal fa-arrows-left-right-to-line",
          "fal fa-arrows-maximize",
          "fal fa-arrows-minimize",
          "fal fa-arrows-repeat",
          "fal fa-arrows-repeat-1",
          "fal fa-arrows-retweet",
          "fal fa-arrows-rotate",
          "fal fa-arrows-spin",
          "fal fa-arrows-split-up-and-left",
          "fal fa-arrows-to-circle",
          "fal fa-arrows-to-dot",
          "fal fa-arrows-to-dotted-line",
          "fal fa-arrows-to-eye",
          "fal fa-arrows-to-line",
          "fal fa-arrows-turn-right",
          "fal fa-arrows-turn-to-dots",
          "fal fa-arrows-up-down",
          "fal fa-arrows-up-down-left-right",
          "fal fa-arrows-up-to-line",
          "fal fa-asterisk",
          "fal fa-at",
          "fal fa-atom",
          "fal fa-atom-simple",
          "fal fa-audio-description",
          "fal fa-audio-description-slash",
          "fal fa-austral-sign",
          "fal fa-avocado",
          "fal fa-award",
          "fal fa-award-simple",
          "fal fa-axe",
          "fal fa-axe-battle",
          "fal fa-b",
          "fal fa-baby",
          "fal fa-baby-carriage",
          "fal fa-backpack",
          "fal fa-backward",
          "fal fa-backward-fast",
          "fal fa-backward-step",
          "fal fa-bacon",
          "fal fa-bacteria",
          "fal fa-bacterium",
          "fal fa-badge",
          "fal fa-badge-check",
          "fal fa-badge-dollar",
          "fal fa-badge-percent",
          "fal fa-badge-sheriff",
          "fal fa-badger-honey",
          "fal fa-badminton",
          "fal fa-bag-shopping",
          "fal fa-bagel",
          "fal fa-bags-shopping",
          "fal fa-baguette",
          "fal fa-bahai",
          "fal fa-baht-sign",
          "fal fa-ball-pile",
          "fal fa-balloon",
          "fal fa-balloons",
          "fal fa-ballot",
          "fal fa-ballot-check",
          "fal fa-ban",
          "fal fa-ban-bug",
          "fal fa-ban-parking",
          "fal fa-ban-smoking",
          "fal fa-banana",
          "fal fa-bandage",
          "fal fa-bangladeshi-taka-sign",
          "fal fa-banjo",
          "fal fa-barcode",
          "fal fa-barcode-read",
          "fal fa-barcode-scan",
          "fal fa-bars",
          "fal fa-bars-filter",
          "fal fa-bars-progress",
          "fal fa-bars-sort",
          "fal fa-bars-staggered",
          "fal fa-baseball",
          "fal fa-baseball-bat-ball",
          "fal fa-basket-shopping",
          "fal fa-basket-shopping-simple",
          "fal fa-basketball",
          "fal fa-basketball-hoop",
          "fal fa-bat",
          "fal fa-bath",
          "fal fa-battery-bolt",
          "fal fa-battery-empty",
          "fal fa-battery-exclamation",
          "fal fa-battery-full",
          "fal fa-battery-half",
          "fal fa-battery-low",
          "fal fa-battery-quarter",
          "fal fa-battery-slash",
          "fal fa-battery-three-quarters",
          "fal fa-bed",
          "fal fa-bed-bunk",
          "fal fa-bed-empty",
          "fal fa-bed-front",
          "fal fa-bed-pulse",
          "fal fa-bee",
          "fal fa-beer-mug",
          "fal fa-beer-mug-empty",
          "fal fa-bell",
          "fal fa-bell-concierge",
          "fal fa-bell-exclamation",
          "fal fa-bell-on",
          "fal fa-bell-plus",
          "fal fa-bell-school",
          "fal fa-bell-school-slash",
          "fal fa-bell-slash",
          "fal fa-bells",
          "fal fa-bench-tree",
          "fal fa-bezier-curve",
          "fal fa-bicycle",
          "fal fa-billboard",
          "fal fa-binary",
          "fal fa-binary-circle-check",
          "fal fa-binary-lock",
          "fal fa-binary-slash",
          "fal fa-binoculars",
          "fal fa-biohazard",
          "fal fa-bird",
          "fal fa-bitcoin-sign",
          "fal fa-blanket",
          "fal fa-blanket-fire",
          "fal fa-blender",
          "fal fa-blender-phone",
          "fal fa-blinds",
          "fal fa-blinds-open",
          "fal fa-blinds-raised",
          "fal fa-block",
          "fal fa-block-brick",
          "fal fa-block-brick-fire",
          "fal fa-block-question",
          "fal fa-block-quote",
          "fal fa-blog",
          "fal fa-blueberries",
          "fal fa-bluetooth",
          "fal fa-bold",
          "fal fa-bolt",
          "fal fa-bolt-auto",
          "fal fa-bolt-lightning",
          "fal fa-bolt-slash",
          "fal fa-bomb",
          "fal fa-bone",
          "fal fa-bone-break",
          "fal fa-bong",
          "fal fa-book",
          "fal fa-book-arrow-right",
          "fal fa-book-arrow-up",
          "fal fa-book-atlas",
          "fal fa-book-bible",
          "fal fa-book-blank",
          "fal fa-book-bookmark",
          "fal fa-book-circle-arrow-right",
          "fal fa-book-circle-arrow-up",
          "fal fa-book-copy",
          "fal fa-book-font",
          "fal fa-book-heart",
          "fal fa-book-journal-whills",
          "fal fa-book-medical",
          "fal fa-book-open",
          "fal fa-book-open-cover",
          "fal fa-book-open-reader",
          "fal fa-book-quran",
          "fal fa-book-section",
          "fal fa-book-skull",
          "fal fa-book-sparkles",
          "fal fa-book-tanakh",
          "fal fa-book-user",
          "fal fa-bookmark",
          "fal fa-bookmark-slash",
          "fal fa-books",
          "fal fa-books-medical",
          "fal fa-boombox",
          "fal fa-boot",
          "fal fa-boot-heeled",
          "fal fa-booth-curtain",
          "fal fa-border-all",
          "fal fa-border-bottom",
          "fal fa-border-bottom-right",
          "fal fa-border-center-h",
          "fal fa-border-center-v",
          "fal fa-border-inner",
          "fal fa-border-left",
          "fal fa-border-none",
          "fal fa-border-outer",
          "fal fa-border-right",
          "fal fa-border-top",
          "fal fa-border-top-left",
          "fal fa-bore-hole",
          "fal fa-bottle-droplet",
          "fal fa-bottle-water",
          "fal fa-bow-arrow",
          "fal fa-bowl-chopsticks",
          "fal fa-bowl-chopsticks-noodles",
          "fal fa-bowl-food",
          "fal fa-bowl-hot",
          "fal fa-bowl-rice",
          "fal fa-bowl-scoop",
          "fal fa-bowl-scoops",
          "fal fa-bowl-soft-serve",
          "fal fa-bowl-spoon",
          "fal fa-bowling-ball",
          "fal fa-bowling-ball-pin",
          "fal fa-bowling-pins",
          "fal fa-box",
          "fal fa-box-archive",
          "fal fa-box-ballot",
          "fal fa-box-check",
          "fal fa-box-circle-check",
          "fal fa-box-dollar",
          "fal fa-box-heart",
          "fal fa-box-open",
          "fal fa-box-open-full",
          "fal fa-box-taped",
          "fal fa-box-tissue",
          "fal fa-boxes-packing",
          "fal fa-boxes-stacked",
          "fal fa-boxing-glove",
          "fal fa-bracket-curly",
          "fal fa-bracket-curly-right",
          "fal fa-bracket-round",
          "fal fa-bracket-round-right",
          "fal fa-bracket-square",
          "fal fa-bracket-square-right",
          "fal fa-brackets-curly",
          "fal fa-brackets-round",
          "fal fa-brackets-square",
          "fal fa-braille",
          "fal fa-brain",
          "fal fa-brain-arrow-curved-right",
          "fal fa-brain-circuit",
          "fal fa-brake-warning",
          "fal fa-brazilian-real-sign",
          "fal fa-bread-loaf",
          "fal fa-bread-slice",
          "fal fa-bread-slice-butter",
          "fal fa-bridge",
          "fal fa-bridge-circle-check",
          "fal fa-bridge-circle-exclamation",
          "fal fa-bridge-circle-xmark",
          "fal fa-bridge-lock",
          "fal fa-bridge-suspension",
          "fal fa-bridge-water",
          "fal fa-briefcase",
          "fal fa-briefcase-arrow-right",
          "fal fa-briefcase-blank",
          "fal fa-briefcase-medical",
          "fal fa-brightness",
          "fal fa-brightness-low",
          "fal fa-bring-forward",
          "fal fa-bring-front",
          "fal fa-broccoli",
          "fal fa-broom",
          "fal fa-broom-ball",
          "fal fa-broom-wide",
          "fal fa-browser",
          "fal fa-browsers",
          "fal fa-brush",
          "fal fa-bucket",
          "fal fa-bug",
          "fal fa-bug-slash",
          "fal fa-bugs",
          "fal fa-building",
          "fal fa-building-circle-arrow-right",
          "fal fa-building-circle-check",
          "fal fa-building-circle-exclamation",
          "fal fa-building-circle-xmark",
          "fal fa-building-columns",
          "fal fa-building-flag",
          "fal fa-building-lock",
          "fal fa-building-ngo",
          "fal fa-building-shield",
          "fal fa-building-un",
          "fal fa-building-user",
          "fal fa-building-wheat",
          "fal fa-buildings",
          "fal fa-bullhorn",
          "fal fa-bullseye",
          "fal fa-bullseye-arrow",
          "fal fa-bullseye-pointer",
          "fal fa-buoy",
          "fal fa-buoy-mooring",
          "fal fa-burger",
          "fal fa-burger-cheese",
          "fal fa-burger-fries",
          "fal fa-burger-glass",
          "fal fa-burger-lettuce",
          "fal fa-burger-soda",
          "fal fa-burrito",
          "fal fa-burst",
          "fal fa-bus",
          "fal fa-bus-school",
          "fal fa-bus-simple",
          "fal fa-business-time",
          "fal fa-butter",
          "fal fa-c",
          "fal fa-cabin",
          "fal fa-cabinet-filing",
          "fal fa-cable-car",
          "fal fa-cactus",
          "fal fa-cake-candles",
          "fal fa-cake-slice",
          "fal fa-calculator",
          "fal fa-calculator-simple",
          "fal fa-calendar",
          "fal fa-calendar-arrow-down",
          "fal fa-calendar-arrow-up",
          "fal fa-calendar-check",
          "fal fa-calendar-circle-exclamation",
          "fal fa-calendar-circle-minus",
          "fal fa-calendar-circle-plus",
          "fal fa-calendar-circle-user",
          "fal fa-calendar-clock",
          "fal fa-calendar-day",
          "fal fa-calendar-days",
          "fal fa-calendar-exclamation",
          "fal fa-calendar-heart",
          "fal fa-calendar-image",
          "fal fa-calendar-lines",
          "fal fa-calendar-lines-pen",
          "fal fa-calendar-minus",
          "fal fa-calendar-pen",
          "fal fa-calendar-plus",
          "fal fa-calendar-range",
          "fal fa-calendar-star",
          "fal fa-calendar-week",
          "fal fa-calendar-xmark",
          "fal fa-calendars",
          "fal fa-camcorder",
          "fal fa-camera",
          "fal fa-camera-cctv",
          "fal fa-camera-movie",
          "fal fa-camera-polaroid",
          "fal fa-camera-retro",
          "fal fa-camera-rotate",
          "fal fa-camera-security",
          "fal fa-camera-slash",
          "fal fa-camera-viewfinder",
          "fal fa-camera-web",
          "fal fa-camera-web-slash",
          "fal fa-campfire",
          "fal fa-campground",
          "fal fa-can-food",
          "fal fa-candle-holder",
          "fal fa-candy",
          "fal fa-candy-bar",
          "fal fa-candy-cane",
          "fal fa-candy-corn",
          "fal fa-cannabis",
          "fal fa-capsules",
          "fal fa-car",
          "fal fa-car-battery",
          "fal fa-car-bolt",
          "fal fa-car-building",
          "fal fa-car-bump",
          "fal fa-car-burst",
          "fal fa-car-bus",
          "fal fa-car-circle-bolt",
          "fal fa-car-garage",
          "fal fa-car-mirrors",
          "fal fa-car-on",
          "fal fa-car-rear",
          "fal fa-car-side",
          "fal fa-car-side-bolt",
          "fal fa-car-tilt",
          "fal fa-car-tunnel",
          "fal fa-car-wash",
          "fal fa-car-wrench",
          "fal fa-caravan",
          "fal fa-caravan-simple",
          "fal fa-card-club",
          "fal fa-card-diamond",
          "fal fa-card-heart",
          "fal fa-card-spade",
          "fal fa-cards",
          "fal fa-cards-blank",
          "fal fa-caret-down",
          "fal fa-caret-left",
          "fal fa-caret-right",
          "fal fa-caret-up",
          "fal fa-carrot",
          "fal fa-cars",
          "fal fa-cart-arrow-down",
          "fal fa-cart-arrow-up",
          "fal fa-cart-circle-arrow-down",
          "fal fa-cart-circle-arrow-up",
          "fal fa-cart-circle-check",
          "fal fa-cart-circle-exclamation",
          "fal fa-cart-circle-plus",
          "fal fa-cart-circle-xmark",
          "fal fa-cart-flatbed",
          "fal fa-cart-flatbed-boxes",
          "fal fa-cart-flatbed-empty",
          "fal fa-cart-flatbed-suitcase",
          "fal fa-cart-minus",
          "fal fa-cart-plus",
          "fal fa-cart-shopping",
          "fal fa-cart-shopping-fast",
          "fal fa-cart-xmark",
          "fal fa-cash-register",
          "fal fa-cassette-betamax",
          "fal fa-cassette-tape",
          "fal fa-cassette-vhs",
          "fal fa-castle",
          "fal fa-cat",
          "fal fa-cat-space",
          "fal fa-cauldron",
          "fal fa-cedi-sign",
          "fal fa-cent-sign",
          "fal fa-certificate",
          "fal fa-chair",
          "fal fa-chair-office",
          "fal fa-chalkboard",
          "fal fa-chalkboard-user",
          "fal fa-champagne-glass",
          "fal fa-champagne-glasses",
          "fal fa-charging-station",
          "fal fa-chart-area",
          "fal fa-chart-bar",
          "fal fa-chart-bullet",
          "fal fa-chart-candlestick",
          "fal fa-chart-column",
          "fal fa-chart-gantt",
          "fal fa-chart-line",
          "fal fa-chart-line-down",
          "fal fa-chart-line-up",
          "fal fa-chart-mixed",
          "fal fa-chart-network",
          "fal fa-chart-pie",
          "fal fa-chart-pie-simple",
          "fal fa-chart-pyramid",
          "fal fa-chart-radar",
          "fal fa-chart-scatter",
          "fal fa-chart-scatter-3d",
          "fal fa-chart-scatter-bubble",
          "fal fa-chart-simple",
          "fal fa-chart-simple-horizontal",
          "fal fa-chart-tree-map",
          "fal fa-chart-user",
          "fal fa-chart-waterfall",
          "fal fa-check",
          "fal fa-check-double",
          "fal fa-check-to-slot",
          "fal fa-cheese",
          "fal fa-cheese-swiss",
          "fal fa-cherries",
          "fal fa-chess",
          "fal fa-chess-bishop",
          "fal fa-chess-bishop-piece",
          "fal fa-chess-board",
          "fal fa-chess-clock",
          "fal fa-chess-clock-flip",
          "fal fa-chess-king",
          "fal fa-chess-king-piece",
          "fal fa-chess-knight",
          "fal fa-chess-knight-piece",
          "fal fa-chess-pawn",
          "fal fa-chess-pawn-piece",
          "fal fa-chess-queen",
          "fal fa-chess-queen-piece",
          "fal fa-chess-rook",
          "fal fa-chess-rook-piece",
          "fal fa-chestnut",
          "fal fa-chevron-down",
          "fal fa-chevron-left",
          "fal fa-chevron-right",
          "fal fa-chevron-up",
          "fal fa-chevrons-down",
          "fal fa-chevrons-left",
          "fal fa-chevrons-right",
          "fal fa-chevrons-up",
          "fal fa-child",
          "fal fa-child-combatant",
          "fal fa-child-dress",
          "fal fa-child-reaching",
          "fal fa-children",
          "fal fa-chimney",
          "fal fa-chopsticks",
          "fal fa-church",
          "fal fa-circle",
          "fal fa-circle-0",
          "fal fa-circle-1",
          "fal fa-circle-2",
          "fal fa-circle-3",
          "fal fa-circle-4",
          "fal fa-circle-5",
          "fal fa-circle-6",
          "fal fa-circle-7",
          "fal fa-circle-8",
          "fal fa-circle-9",
          "fal fa-circle-a",
          "fal fa-circle-ampersand",
          "fal fa-circle-arrow-down",
          "fal fa-circle-arrow-down-left",
          "fal fa-circle-arrow-down-right",
          "fal fa-circle-arrow-left",
          "fal fa-circle-arrow-right",
          "fal fa-circle-arrow-up",
          "fal fa-circle-arrow-up-left",
          "fal fa-circle-arrow-up-right",
          "fal fa-circle-b",
          "fal fa-circle-bolt",
          "fal fa-circle-book-open",
          "fal fa-circle-bookmark",
          "fal fa-circle-c",
          "fal fa-circle-calendar",
          "fal fa-circle-camera",
          "fal fa-circle-caret-down",
          "fal fa-circle-caret-left",
          "fal fa-circle-caret-right",
          "fal fa-circle-caret-up",
          "fal fa-circle-check",
          "fal fa-circle-chevron-down",
          "fal fa-circle-chevron-left",
          "fal fa-circle-chevron-right",
          "fal fa-circle-chevron-up",
          "fal fa-circle-d",
          "fal fa-circle-dashed",
          "fal fa-circle-divide",
          "fal fa-circle-dollar",
          "fal fa-circle-dollar-to-slot",
          "fal fa-circle-dot",
          "fal fa-circle-down",
          "fal fa-circle-down-left",
          "fal fa-circle-down-right",
          "fal fa-circle-e",
          "fal fa-circle-ellipsis",
          "fal fa-circle-ellipsis-vertical",
          "fal fa-circle-envelope",
          "fal fa-circle-euro",
          "fal fa-circle-exclamation",
          "fal fa-circle-exclamation-check",
          "fal fa-circle-f",
          "fal fa-circle-g",
          "fal fa-circle-h",
          "fal fa-circle-half",
          "fal fa-circle-half-stroke",
          "fal fa-circle-heart",
          "fal fa-circle-i",
          "fal fa-circle-info",
          "fal fa-circle-j",
          "fal fa-circle-k",
          "fal fa-circle-l",
          "fal fa-circle-left",
          "fal fa-circle-location-arrow",
          "fal fa-circle-m",
          "fal fa-circle-microphone",
          "fal fa-circle-microphone-lines",
          "fal fa-circle-minus",
          "fal fa-circle-n",
          "fal fa-circle-nodes",
          "fal fa-circle-notch",
          "fal fa-circle-o",
          "fal fa-circle-p",
          "fal fa-circle-parking",
          "fal fa-circle-pause",
          "fal fa-circle-phone",
          "fal fa-circle-phone-flip",
          "fal fa-circle-phone-hangup",
          "fal fa-circle-play",
          "fal fa-circle-plus",
          "fal fa-circle-q",
          "fal fa-circle-quarter",
          "fal fa-circle-quarter-stroke",
          "fal fa-circle-quarters",
          "fal fa-circle-question",
          "fal fa-circle-r",
          "fal fa-circle-radiation",
          "fal fa-circle-right",
          "fal fa-circle-s",
          "fal fa-circle-small",
          "fal fa-circle-sort",
          "fal fa-circle-sort-down",
          "fal fa-circle-sort-up",
          "fal fa-circle-star",
          "fal fa-circle-sterling",
          "fal fa-circle-stop",
          "fal fa-circle-t",
          "fal fa-circle-three-quarters",
          "fal fa-circle-three-quarters-stroke",
          "fal fa-circle-trash",
          "fal fa-circle-u",
          "fal fa-circle-up",
          "fal fa-circle-up-left",
          "fal fa-circle-up-right",
          "fal fa-circle-user",
          "fal fa-circle-v",
          "fal fa-circle-video",
          "fal fa-circle-w",
          "fal fa-circle-waveform-lines",
          "fal fa-circle-x",
          "fal fa-circle-xmark",
          "fal fa-circle-y",
          "fal fa-circle-yen",
          "fal fa-circle-z",
          "fal fa-citrus",
          "fal fa-citrus-slice",
          "fal fa-city",
          "fal fa-clapperboard",
          "fal fa-clapperboard-play",
          "fal fa-clarinet",
          "fal fa-claw-marks",
          "fal fa-clipboard",
          "fal fa-clipboard-check",
          "fal fa-clipboard-list",
          "fal fa-clipboard-list-check",
          "fal fa-clipboard-medical",
          "fal fa-clipboard-prescription",
          "fal fa-clipboard-question",
          "fal fa-clipboard-user",
          "fal fa-clock",
          "fal fa-clock-desk",
          "fal fa-clock-eight",
          "fal fa-clock-eight-thirty",
          "fal fa-clock-eleven",
          "fal fa-clock-eleven-thirty",
          "fal fa-clock-five",
          "fal fa-clock-five-thirty",
          "fal fa-clock-four-thirty",
          "fal fa-clock-nine",
          "fal fa-clock-nine-thirty",
          "fal fa-clock-one",
          "fal fa-clock-one-thirty",
          "fal fa-clock-rotate-left",
          "fal fa-clock-seven",
          "fal fa-clock-seven-thirty",
          "fal fa-clock-six",
          "fal fa-clock-six-thirty",
          "fal fa-clock-ten",
          "fal fa-clock-ten-thirty",
          "fal fa-clock-three",
          "fal fa-clock-three-thirty",
          "fal fa-clock-twelve",
          "fal fa-clock-twelve-thirty",
          "fal fa-clock-two",
          "fal fa-clock-two-thirty",
          "fal fa-clone",
          "fal fa-closed-captioning",
          "fal fa-closed-captioning-slash",
          "fal fa-clothes-hanger",
          "fal fa-cloud",
          "fal fa-cloud-arrow-down",
          "fal fa-cloud-arrow-up",
          "fal fa-cloud-bolt",
          "fal fa-cloud-bolt-moon",
          "fal fa-cloud-bolt-sun",
          "fal fa-cloud-check",
          "fal fa-cloud-drizzle",
          "fal fa-cloud-exclamation",
          "fal fa-cloud-fog",
          "fal fa-cloud-hail",
          "fal fa-cloud-hail-mixed",
          "fal fa-cloud-meatball",
          "fal fa-cloud-minus",
          "fal fa-cloud-moon",
          "fal fa-cloud-moon-rain",
          "fal fa-cloud-music",
          "fal fa-cloud-plus",
          "fal fa-cloud-question",
          "fal fa-cloud-rain",
          "fal fa-cloud-rainbow",
          "fal fa-cloud-showers",
          "fal fa-cloud-showers-heavy",
          "fal fa-cloud-showers-water",
          "fal fa-cloud-slash",
          "fal fa-cloud-sleet",
          "fal fa-cloud-snow",
          "fal fa-cloud-sun",
          "fal fa-cloud-sun-rain",
          "fal fa-cloud-word",
          "fal fa-cloud-xmark",
          "fal fa-clouds",
          "fal fa-clouds-moon",
          "fal fa-clouds-sun",
          "fal fa-clover",
          "fal fa-club",
          "fal fa-coconut",
          "fal fa-code",
          "fal fa-code-branch",
          "fal fa-code-commit",
          "fal fa-code-compare",
          "fal fa-code-fork",
          "fal fa-code-merge",
          "fal fa-code-pull-request",
          "fal fa-code-pull-request-closed",
          "fal fa-code-pull-request-draft",
          "fal fa-code-simple",
          "fal fa-coffee-bean",
          "fal fa-coffee-beans",
          "fal fa-coffee-pot",
          "fal fa-coffin",
          "fal fa-coffin-cross",
          "fal fa-coin",
          "fal fa-coin-blank",
          "fal fa-coin-front",
          "fal fa-coin-vertical",
          "fal fa-coins",
          "fal fa-colon",
          "fal fa-colon-sign",
          "fal fa-columns-3",
          "fal fa-comet",
          "fal fa-comma",
          "fal fa-command",
          "fal fa-comment",
          "fal fa-comment-arrow-down",
          "fal fa-comment-arrow-up",
          "fal fa-comment-arrow-up-right",
          "fal fa-comment-captions",
          "fal fa-comment-check",
          "fal fa-comment-code",
          "fal fa-comment-dollar",
          "fal fa-comment-dots",
          "fal fa-comment-exclamation",
          "fal fa-comment-heart",
          "fal fa-comment-image",
          "fal fa-comment-lines",
          "fal fa-comment-medical",
          "fal fa-comment-middle",
          "fal fa-comment-middle-top",
          "fal fa-comment-minus",
          "fal fa-comment-music",
          "fal fa-comment-pen",
          "fal fa-comment-plus",
          "fal fa-comment-question",
          "fal fa-comment-quote",
          "fal fa-comment-slash",
          "fal fa-comment-smile",
          "fal fa-comment-sms",
          "fal fa-comment-text",
          "fal fa-comment-xmark",
          "fal fa-comments",
          "fal fa-comments-dollar",
          "fal fa-comments-question",
          "fal fa-comments-question-check",
          "fal fa-compact-disc",
          "fal fa-compass",
          "fal fa-compass-drafting",
          "fal fa-compass-slash",
          "fal fa-compress",
          "fal fa-compress-wide",
          "fal fa-computer",
          "fal fa-computer-classic",
          "fal fa-computer-mouse",
          "fal fa-computer-mouse-scrollwheel",
          "fal fa-computer-speaker",
          "fal fa-container-storage",
          "fal fa-conveyor-belt",
          "fal fa-conveyor-belt-boxes",
          "fal fa-conveyor-belt-empty",
          "fal fa-cookie",
          "fal fa-cookie-bite",
          "fal fa-copy",
          "fal fa-copyright",
          "fal fa-corn",
          "fal fa-corner",
          "fal fa-couch",
          "fal fa-cow",
          "fal fa-cowbell",
          "fal fa-cowbell-circle-plus",
          "fal fa-crab",
          "fal fa-crate-apple",
          "fal fa-crate-empty",
          "fal fa-credit-card",
          "fal fa-credit-card-blank",
          "fal fa-credit-card-front",
          "fal fa-cricket-bat-ball",
          "fal fa-croissant",
          "fal fa-crop",
          "fal fa-crop-simple",
          "fal fa-cross",
          "fal fa-crosshairs",
          "fal fa-crosshairs-simple",
          "fal fa-crow",
          "fal fa-crown",
          "fal fa-crutch",
          "fal fa-crutches",
          "fal fa-cruzeiro-sign",
          "fal fa-crystal-ball",
          "fal fa-cube",
          "fal fa-cubes",
          "fal fa-cubes-stacked",
          "fal fa-cucumber",
          "fal fa-cup-straw",
          "fal fa-cup-straw-swoosh",
          "fal fa-cup-togo",
          "fal fa-cupcake",
          "fal fa-curling-stone",
          "fal fa-custard",
          "fal fa-d",
          "fal fa-dagger",
          "fal fa-dash",
          "fal fa-database",
          "fal fa-deer",
          "fal fa-deer-rudolph",
          "fal fa-delete-left",
          "fal fa-delete-right",
          "fal fa-democrat",
          "fal fa-desktop",
          "fal fa-desktop-arrow-down",
          "fal fa-dharmachakra",
          "fal fa-diagram-cells",
          "fal fa-diagram-lean-canvas",
          "fal fa-diagram-nested",
          "fal fa-diagram-next",
          "fal fa-diagram-predecessor",
          "fal fa-diagram-previous",
          "fal fa-diagram-project",
          "fal fa-diagram-sankey",
          "fal fa-diagram-subtask",
          "fal fa-diagram-successor",
          "fal fa-diagram-venn",
          "fal fa-dial",
          "fal fa-dial-high",
          "fal fa-dial-low",
          "fal fa-dial-max",
          "fal fa-dial-med",
          "fal fa-dial-med-low",
          "fal fa-dial-min",
          "fal fa-dial-off",
          "fal fa-diamond",
          "fal fa-diamond-exclamation",
          "fal fa-diamond-half",
          "fal fa-diamond-half-stroke",
          "fal fa-diamond-turn-right",
          "fal fa-dice",
          "fal fa-dice-d10",
          "fal fa-dice-d12",
          "fal fa-dice-d20",
          "fal fa-dice-d4",
          "fal fa-dice-d6",
          "fal fa-dice-d8",
          "fal fa-dice-five",
          "fal fa-dice-four",
          "fal fa-dice-one",
          "fal fa-dice-six",
          "fal fa-dice-three",
          "fal fa-dice-two",
          "fal fa-diploma",
          "fal fa-disc-drive",
          "fal fa-disease",
          "fal fa-display",
          "fal fa-display-arrow-down",
          "fal fa-display-code",
          "fal fa-display-medical",
          "fal fa-display-slash",
          "fal fa-distribute-spacing-horizontal",
          "fal fa-distribute-spacing-vertical",
          "fal fa-ditto",
          "fal fa-divide",
          "fal fa-dna",
          "fal fa-do-not-enter",
          "fal fa-dog",
          "fal fa-dog-leashed",
          "fal fa-dollar-sign",
          "fal fa-dolly",
          "fal fa-dolly-empty",
          "fal fa-dolphin",
          "fal fa-dong-sign",
          "fal fa-donut",
          "fal fa-door-closed",
          "fal fa-door-open",
          "fal fa-dove",
          "fal fa-down",
          "fal fa-down-from-dotted-line",
          "fal fa-down-from-line",
          "fal fa-down-left",
          "fal fa-down-left-and-up-right-to-center",
          "fal fa-down-long",
          "fal fa-down-right",
          "fal fa-down-to-bracket",
          "fal fa-down-to-dotted-line",
          "fal fa-down-to-line",
          "fal fa-download",
          "fal fa-dragon",
          "fal fa-draw-circle",
          "fal fa-draw-polygon",
          "fal fa-draw-square",
          "fal fa-dreidel",
          "fal fa-drone",
          "fal fa-drone-front",
          "fal fa-droplet",
          "fal fa-droplet-degree",
          "fal fa-droplet-percent",
          "fal fa-droplet-slash",
          "fal fa-drum",
          "fal fa-drum-steelpan",
          "fal fa-drumstick",
          "fal fa-drumstick-bite",
          "fal fa-dryer",
          "fal fa-dryer-heat",
          "fal fa-duck",
          "fal fa-dumbbell",
          "fal fa-dumpster",
          "fal fa-dumpster-fire",
          "fal fa-dungeon",
          "fal fa-e",
          "fal fa-ear",
          "fal fa-ear-deaf",
          "fal fa-ear-listen",
          "fal fa-ear-muffs",
          "fal fa-earth-africa",
          "fal fa-earth-americas",
          "fal fa-earth-asia",
          "fal fa-earth-europe",
          "fal fa-earth-oceania",
          "fal fa-eclipse",
          "fal fa-egg",
          "fal fa-egg-fried",
          "fal fa-eggplant",
          "fal fa-eject",
          "fal fa-elephant",
          "fal fa-elevator",
          "fal fa-ellipsis",
          "fal fa-ellipsis-stroke",
          "fal fa-ellipsis-stroke-vertical",
          "fal fa-ellipsis-vertical",
          "fal fa-empty-set",
          "fal fa-engine",
          "fal fa-engine-warning",
          "fal fa-envelope",
          "fal fa-envelope-circle-check",
          "fal fa-envelope-dot",
          "fal fa-envelope-open",
          "fal fa-envelope-open-dollar",
          "fal fa-envelope-open-text",
          "fal fa-envelopes",
          "fal fa-envelopes-bulk",
          "fal fa-equals",
          "fal fa-eraser",
          "fal fa-escalator",
          "fal fa-ethernet",
          "fal fa-euro-sign",
          "fal fa-exclamation",
          "fal fa-expand",
          "fal fa-expand-wide",
          "fal fa-explosion",
          "fal fa-eye",
          "fal fa-eye-dropper",
          "fal fa-eye-dropper-full",
          "fal fa-eye-dropper-half",
          "fal fa-eye-evil",
          "fal fa-eye-low-vision",
          "fal fa-eye-slash",
          "fal fa-eyes",
          "fal fa-f",
          "fal fa-face-angry",
          "fal fa-face-angry-horns",
          "fal fa-face-anguished",
          "fal fa-face-anxious-sweat",
          "fal fa-face-astonished",
          "fal fa-face-awesome",
          "fal fa-face-beam-hand-over-mouth",
          "fal fa-face-clouds",
          "fal fa-face-confounded",
          "fal fa-face-confused",
          "fal fa-face-cowboy-hat",
          "fal fa-face-diagonal-mouth",
          "fal fa-face-disappointed",
          "fal fa-face-disguise",
          "fal fa-face-dizzy",
          "fal fa-face-dotted",
          "fal fa-face-downcast-sweat",
          "fal fa-face-drooling",
          "fal fa-face-exhaling",
          "fal fa-face-explode",
          "fal fa-face-expressionless",
          "fal fa-face-eyes-xmarks",
          "fal fa-face-fearful",
          "fal fa-face-flushed",
          "fal fa-face-frown",
          "fal fa-face-frown-open",
          "fal fa-face-frown-slight",
          "fal fa-face-glasses",
          "fal fa-face-grimace",
          "fal fa-face-grin",
          "fal fa-face-grin-beam",
          "fal fa-face-grin-beam-sweat",
          "fal fa-face-grin-hearts",
          "fal fa-face-grin-squint",
          "fal fa-face-grin-squint-tears",
          "fal fa-face-grin-stars",
          "fal fa-face-grin-tears",
          "fal fa-face-grin-tongue",
          "fal fa-face-grin-tongue-squint",
          "fal fa-face-grin-tongue-wink",
          "fal fa-face-grin-wide",
          "fal fa-face-grin-wink",
          "fal fa-face-hand-over-mouth",
          "fal fa-face-hand-peeking",
          "fal fa-face-hand-yawn",
          "fal fa-face-head-bandage",
          "fal fa-face-holding-back-tears",
          "fal fa-face-hushed",
          "fal fa-face-icicles",
          "fal fa-face-kiss",
          "fal fa-face-kiss-beam",
          "fal fa-face-kiss-closed-eyes",
          "fal fa-face-kiss-wink-heart",
          "fal fa-face-laugh",
          "fal fa-face-laugh-beam",
          "fal fa-face-laugh-squint",
          "fal fa-face-laugh-wink",
          "fal fa-face-lying",
          "fal fa-face-mask",
          "fal fa-face-meh",
          "fal fa-face-meh-blank",
          "fal fa-face-melting",
          "fal fa-face-monocle",
          "fal fa-face-nauseated",
          "fal fa-face-nose-steam",
          "fal fa-face-party",
          "fal fa-face-pensive",
          "fal fa-face-persevering",
          "fal fa-face-pleading",
          "fal fa-face-pouting",
          "fal fa-face-raised-eyebrow",
          "fal fa-face-relieved",
          "fal fa-face-rolling-eyes",
          "fal fa-face-sad-cry",
          "fal fa-face-sad-sweat",
          "fal fa-face-sad-tear",
          "fal fa-face-saluting",
          "fal fa-face-scream",
          "fal fa-face-shush",
          "fal fa-face-sleeping",
          "fal fa-face-sleepy",
          "fal fa-face-smile",
          "fal fa-face-smile-beam",
          "fal fa-face-smile-halo",
          "fal fa-face-smile-hearts",
          "fal fa-face-smile-horns",
          "fal fa-face-smile-plus",
          "fal fa-face-smile-relaxed",
          "fal fa-face-smile-tear",
          "fal fa-face-smile-tongue",
          "fal fa-face-smile-upside-down",
          "fal fa-face-smile-wink",
          "fal fa-face-smiling-hands",
          "fal fa-face-smirking",
          "fal fa-face-spiral-eyes",
          "fal fa-face-sunglasses",
          "fal fa-face-surprise",
          "fal fa-face-swear",
          "fal fa-face-thermometer",
          "fal fa-face-thinking",
          "fal fa-face-tired",
          "fal fa-face-tissue",
          "fal fa-face-tongue-money",
          "fal fa-face-tongue-sweat",
          "fal fa-face-unamused",
          "fal fa-face-viewfinder",
          "fal fa-face-vomit",
          "fal fa-face-weary",
          "fal fa-face-woozy",
          "fal fa-face-worried",
          "fal fa-face-zany",
          "fal fa-face-zipper",
          "fal fa-falafel",
          "fal fa-family",
          "fal fa-family-dress",
          "fal fa-family-pants",
          "fal fa-fan",
          "fal fa-fan-table",
          "fal fa-farm",
          "fal fa-faucet",
          "fal fa-faucet-drip",
          "fal fa-fax",
          "fal fa-feather",
          "fal fa-feather-pointed",
          "fal fa-fence",
          "fal fa-ferris-wheel",
          "fal fa-ferry",
          "fal fa-field-hockey-stick-ball",
          "fal fa-file",
          "fal fa-file-arrow-down",
          "fal fa-file-arrow-up",
          "fal fa-file-audio",
          "fal fa-file-binary",
          "fal fa-file-certificate",
          "fal fa-file-chart-column",
          "fal fa-file-chart-pie",
          "fal fa-file-check",
          "fal fa-file-circle-check",
          "fal fa-file-circle-exclamation",
          "fal fa-file-circle-info",
          "fal fa-file-circle-minus",
          "fal fa-file-circle-plus",
          "fal fa-file-circle-question",
          "fal fa-file-circle-xmark",
          "fal fa-file-code",
          "fal fa-file-contract",
          "fal fa-file-csv",
          "fal fa-file-dashed-line",
          "fal fa-file-excel",
          "fal fa-file-exclamation",
          "fal fa-file-export",
          "fal fa-file-heart",
          "fal fa-file-image",
          "fal fa-file-import",
          "fal fa-file-invoice",
          "fal fa-file-invoice-dollar",
          "fal fa-file-lines",
          "fal fa-file-lock",
          "fal fa-file-magnifying-glass",
          "fal fa-file-medical",
          "fal fa-file-minus",
          "fal fa-file-music",
          "fal fa-file-pdf",
          "fal fa-file-pen",
          "fal fa-file-plus",
          "fal fa-file-plus-minus",
          "fal fa-file-powerpoint",
          "fal fa-file-prescription",
          "fal fa-file-shield",
          "fal fa-file-signature",
          "fal fa-file-slash",
          "fal fa-file-spreadsheet",
          "fal fa-file-user",
          "fal fa-file-video",
          "fal fa-file-waveform",
          "fal fa-file-word",
          "fal fa-file-xmark",
          "fal fa-file-zipper",
          "fal fa-files",
          "fal fa-files-medical",
          "fal fa-fill",
          "fal fa-fill-drip",
          "fal fa-film",
          "fal fa-film-canister",
          "fal fa-film-simple",
          "fal fa-film-slash",
          "fal fa-films",
          "fal fa-filter",
          "fal fa-filter-circle-dollar",
          "fal fa-filter-circle-xmark",
          "fal fa-filter-list",
          "fal fa-filter-slash",
          "fal fa-filters",
          "fal fa-fingerprint",
          "fal fa-fire",
          "fal fa-fire-burner",
          "fal fa-fire-extinguisher",
          "fal fa-fire-flame",
          "fal fa-fire-flame-curved",
          "fal fa-fire-flame-simple",
          "fal fa-fire-hydrant",
          "fal fa-fire-smoke",
          "fal fa-fireplace",
          "fal fa-fish",
          "fal fa-fish-bones",
          "fal fa-fish-cooked",
          "fal fa-fish-fins",
          "fal fa-fishing-rod",
          "fal fa-flag",
          "fal fa-flag-checkered",
          "fal fa-flag-pennant",
          "fal fa-flag-swallowtail",
          "fal fa-flag-usa",
          "fal fa-flashlight",
          "fal fa-flask",
          "fal fa-flask-round-poison",
          "fal fa-flask-round-potion",
          "fal fa-flask-vial",
          "fal fa-flatbread",
          "fal fa-flatbread-stuffed",
          "fal fa-floppy-disk",
          "fal fa-floppy-disk-circle-arrow-right",
          "fal fa-floppy-disk-circle-xmark",
          "fal fa-floppy-disk-pen",
          "fal fa-floppy-disks",
          "fal fa-florin-sign",
          "fal fa-flower",
          "fal fa-flower-daffodil",
          "fal fa-flower-tulip",
          "fal fa-flute",
          "fal fa-flux-capacitor",
          "fal fa-flying-disc",
          "fal fa-folder",
          "fal fa-folder-arrow-down",
          "fal fa-folder-arrow-up",
          "fal fa-folder-bookmark",
          "fal fa-folder-closed",
          "fal fa-folder-gear",
          "fal fa-folder-grid",
          "fal fa-folder-heart",
          "fal fa-folder-image",
          "fal fa-folder-magnifying-glass",
          "fal fa-folder-medical",
          "fal fa-folder-minus",
          "fal fa-folder-music",
          "fal fa-folder-open",
          "fal fa-folder-plus",
          "fal fa-folder-tree",
          "fal fa-folder-user",
          "fal fa-folder-xmark",
          "fal fa-folders",
          "fal fa-fondue-pot",
          "fal fa-font",
          "fal fa-font-awesome",
          "fal fa-font-case",
          "fal fa-football",
          "fal fa-football-helmet",
          "fal fa-fork",
          "fal fa-fork-knife",
          "fal fa-forklift",
          "fal fa-fort",
          "fal fa-forward",
          "fal fa-forward-fast",
          "fal fa-forward-step",
          "fal fa-frame",
          "fal fa-franc-sign",
          "fal fa-french-fries",
          "fal fa-frog",
          "fal fa-function",
          "fal fa-futbol",
          "fal fa-g",
          "fal fa-galaxy",
          "fal fa-gallery-thumbnails",
          "fal fa-game-board",
          "fal fa-game-board-simple",
          "fal fa-game-console-handheld",
          "fal fa-game-console-handheld-crank",
          "fal fa-gamepad",
          "fal fa-gamepad-modern",
          "fal fa-garage",
          "fal fa-garage-car",
          "fal fa-garage-open",
          "fal fa-garlic",
          "fal fa-gas-pump",
          "fal fa-gas-pump-slash",
          "fal fa-gauge",
          "fal fa-gauge-circle-bolt",
          "fal fa-gauge-circle-minus",
          "fal fa-gauge-circle-plus",
          "fal fa-gauge-high",
          "fal fa-gauge-low",
          "fal fa-gauge-max",
          "fal fa-gauge-min",
          "fal fa-gauge-simple",
          "fal fa-gauge-simple-high",
          "fal fa-gauge-simple-low",
          "fal fa-gauge-simple-max",
          "fal fa-gauge-simple-min",
          "fal fa-gavel",
          "fal fa-gear",
          "fal fa-gears",
          "fal fa-gem",
          "fal fa-genderless",
          "fal fa-ghost",
          "fal fa-gif",
          "fal fa-gift",
          "fal fa-gift-card",
          "fal fa-gifts",
          "fal fa-gingerbread-man",
          "fal fa-glass",
          "fal fa-glass-citrus",
          "fal fa-glass-empty",
          "fal fa-glass-half",
          "fal fa-glass-water",
          "fal fa-glass-water-droplet",
          "fal fa-glasses",
          "fal fa-glasses-round",
          "fal fa-globe",
          "fal fa-globe-snow",
          "fal fa-globe-stand",
          "fal fa-goal-net",
          "fal fa-golf-ball-tee",
          "fal fa-golf-club",
          "fal fa-golf-flag-hole",
          "fal fa-gopuram",
          "fal fa-graduation-cap",
          "fal fa-gramophone",
          "fal fa-grapes",
          "fal fa-grate",
          "fal fa-grate-droplet",
          "fal fa-greater-than",
          "fal fa-greater-than-equal",
          "fal fa-grid",
          "fal fa-grid-2",
          "fal fa-grid-2-plus",
          "fal fa-grid-4",
          "fal fa-grid-5",
          "fal fa-grid-dividers",
          "fal fa-grid-horizontal",
          "fal fa-grill",
          "fal fa-grill-fire",
          "fal fa-grill-hot",
          "fal fa-grip",
          "fal fa-grip-dots",
          "fal fa-grip-dots-vertical",
          "fal fa-grip-lines",
          "fal fa-grip-lines-vertical",
          "fal fa-grip-vertical",
          "fal fa-group-arrows-rotate",
          "fal fa-guarani-sign",
          "fal fa-guitar",
          "fal fa-guitar-electric",
          "fal fa-guitars",
          "fal fa-gun",
          "fal fa-gun-slash",
          "fal fa-gun-squirt",
          "fal fa-h",
          "fal fa-h1",
          "fal fa-h2",
          "fal fa-h3",
          "fal fa-h4",
          "fal fa-h5",
          "fal fa-h6",
          "fal fa-hammer",
          "fal fa-hammer-crash",
          "fal fa-hammer-war",
          "fal fa-hamsa",
          "fal fa-hand",
          "fal fa-hand-back-fist",
          "fal fa-hand-back-point-down",
          "fal fa-hand-back-point-left",
          "fal fa-hand-back-point-ribbon",
          "fal fa-hand-back-point-right",
          "fal fa-hand-back-point-up",
          "fal fa-hand-dots",
          "fal fa-hand-fingers-crossed",
          "fal fa-hand-fist",
          "fal fa-hand-heart",
          "fal fa-hand-holding",
          "fal fa-hand-holding-box",
          "fal fa-hand-holding-dollar",
          "fal fa-hand-holding-droplet",
          "fal fa-hand-holding-hand",
          "fal fa-hand-holding-heart",
          "fal fa-hand-holding-magic",
          "fal fa-hand-holding-medical",
          "fal fa-hand-holding-seedling",
          "fal fa-hand-holding-skull",
          "fal fa-hand-horns",
          "fal fa-hand-lizard",
          "fal fa-hand-love",
          "fal fa-hand-middle-finger",
          "fal fa-hand-peace",
          "fal fa-hand-point-down",
          "fal fa-hand-point-left",
          "fal fa-hand-point-ribbon",
          "fal fa-hand-point-right",
          "fal fa-hand-point-up",
          "fal fa-hand-pointer",
          "fal fa-hand-scissors",
          "fal fa-hand-sparkles",
          "fal fa-hand-spock",
          "fal fa-hand-wave",
          "fal fa-handcuffs",
          "fal fa-hands",
          "fal fa-hands-asl-interpreting",
          "fal fa-hands-bound",
          "fal fa-hands-bubbles",
          "fal fa-hands-clapping",
          "fal fa-hands-holding",
          "fal fa-hands-holding-child",
          "fal fa-hands-holding-circle",
          "fal fa-hands-holding-diamond",
          "fal fa-hands-holding-dollar",
          "fal fa-hands-holding-heart",
          "fal fa-hands-praying",
          "fal fa-handshake",
          "fal fa-handshake-angle",
          "fal fa-handshake-simple",
          "fal fa-handshake-simple-slash",
          "fal fa-handshake-slash",
          "fal fa-hanukiah",
          "fal fa-hard-drive",
          "fal fa-hashtag",
          "fal fa-hashtag-lock",
          "fal fa-hat-chef",
          "fal fa-hat-cowboy",
          "fal fa-hat-cowboy-side",
          "fal fa-hat-santa",
          "fal fa-hat-winter",
          "fal fa-hat-witch",
          "fal fa-hat-wizard",
          "fal fa-head-side",
          "fal fa-head-side-brain",
          "fal fa-head-side-cough",
          "fal fa-head-side-cough-slash",
          "fal fa-head-side-goggles",
          "fal fa-head-side-headphones",
          "fal fa-head-side-heart",
          "fal fa-head-side-mask",
          "fal fa-head-side-medical",
          "fal fa-head-side-virus",
          "fal fa-heading",
          "fal fa-headphones",
          "fal fa-headphones-simple",
          "fal fa-headset",
          "fal fa-heart",
          "fal fa-heart-circle-bolt",
          "fal fa-heart-circle-check",
          "fal fa-heart-circle-exclamation",
          "fal fa-heart-circle-minus",
          "fal fa-heart-circle-plus",
          "fal fa-heart-circle-xmark",
          "fal fa-heart-crack",
          "fal fa-heart-half",
          "fal fa-heart-half-stroke",
          "fal fa-heart-pulse",
          "fal fa-heat",
          "fal fa-helicopter",
          "fal fa-helicopter-symbol",
          "fal fa-helmet-battle",
          "fal fa-helmet-safety",
          "fal fa-helmet-un",
          "fal fa-hexagon",
          "fal fa-hexagon-check",
          "fal fa-hexagon-divide",
          "fal fa-hexagon-exclamation",
          "fal fa-hexagon-image",
          "fal fa-hexagon-minus",
          "fal fa-hexagon-plus",
          "fal fa-hexagon-vertical-nft",
          "fal fa-hexagon-vertical-nft-slanted",
          "fal fa-hexagon-xmark",
          "fal fa-high-definition",
          "fal fa-highlighter",
          "fal fa-highlighter-line",
          "fal fa-hill-avalanche",
          "fal fa-hill-rockslide",
          "fal fa-hippo",
          "fal fa-hockey-mask",
          "fal fa-hockey-puck",
          "fal fa-hockey-stick-puck",
          "fal fa-hockey-sticks",
          "fal fa-holly-berry",
          "fal fa-honey-pot",
          "fal fa-hood-cloak",
          "fal fa-horizontal-rule",
          "fal fa-horse",
          "fal fa-horse-head",
          "fal fa-horse-saddle",
          "fal fa-hose",
          "fal fa-hose-reel",
          "fal fa-hospital",
          "fal fa-hospital-user",
          "fal fa-hospitals",
          "fal fa-hot-tub-person",
          "fal fa-hotdog",
          "fal fa-hotel",
          "fal fa-hourglass",
          "fal fa-hourglass-clock",
          "fal fa-hourglass-end",
          "fal fa-hourglass-half",
          "fal fa-hourglass-start",
          "fal fa-house",
          "fal fa-house-blank",
          "fal fa-house-building",
          "fal fa-house-chimney",
          "fal fa-house-chimney-blank",
          "fal fa-house-chimney-crack",
          "fal fa-house-chimney-heart",
          "fal fa-house-chimney-medical",
          "fal fa-house-chimney-user",
          "fal fa-house-chimney-window",
          "fal fa-house-circle-check",
          "fal fa-house-circle-exclamation",
          "fal fa-house-circle-xmark",
          "fal fa-house-crack",
          "fal fa-house-day",
          "fal fa-house-fire",
          "fal fa-house-flag",
          "fal fa-house-flood-water",
          "fal fa-house-flood-water-circle-arrow-right",
          "fal fa-house-heart",
          "fal fa-house-laptop",
          "fal fa-house-lock",
          "fal fa-house-medical",
          "fal fa-house-medical-circle-check",
          "fal fa-house-medical-circle-exclamation",
          "fal fa-house-medical-circle-xmark",
          "fal fa-house-medical-flag",
          "fal fa-house-night",
          "fal fa-house-person-leave",
          "fal fa-house-person-return",
          "fal fa-house-signal",
          "fal fa-house-tree",
          "fal fa-house-tsunami",
          "fal fa-house-turret",
          "fal fa-house-user",
          "fal fa-house-water",
          "fal fa-house-window",
          "fal fa-hryvnia-sign",
          "fal fa-hundred-points",
          "fal fa-hurricane",
          "fal fa-hyphen",
          "fal fa-i",
          "fal fa-i-cursor",
          "fal fa-ice-cream",
          "fal fa-ice-skate",
          "fal fa-icicles",
          "fal fa-icons",
          "fal fa-id-badge",
          "fal fa-id-card",
          "fal fa-id-card-clip",
          "fal fa-igloo",
          "fal fa-image",
          "fal fa-image-landscape",
          "fal fa-image-polaroid",
          "fal fa-image-polaroid-user",
          "fal fa-image-portrait",
          "fal fa-image-slash",
          "fal fa-image-user",
          "fal fa-images",
          "fal fa-images-user",
          "fal fa-inbox",
          "fal fa-inbox-full",
          "fal fa-inbox-in",
          "fal fa-inbox-out",
          "fal fa-inboxes",
          "fal fa-indent",
          "fal fa-indian-rupee-sign",
          "fal fa-industry",
          "fal fa-industry-windows",
          "fal fa-infinity",
          "fal fa-info",
          "fal fa-inhaler",
          "fal fa-input-numeric",
          "fal fa-input-pipe",
          "fal fa-input-text",
          "fal fa-integral",
          "fal fa-interrobang",
          "fal fa-intersection",
          "fal fa-island-tropical",
          "fal fa-italic",
          "fal fa-j",
          "fal fa-jack-o-lantern",
          "fal fa-jar",
          "fal fa-jar-wheat",
          "fal fa-jedi",
          "fal fa-jet-fighter",
          "fal fa-jet-fighter-up",
          "fal fa-joint",
          "fal fa-joystick",
          "fal fa-jug",
          "fal fa-jug-detergent",
          "fal fa-k",
          "fal fa-kaaba",
          "fal fa-kazoo",
          "fal fa-kerning",
          "fal fa-key",
          "fal fa-key-skeleton",
          "fal fa-key-skeleton-left-right",
          "fal fa-keyboard",
          "fal fa-keyboard-brightness",
          "fal fa-keyboard-brightness-low",
          "fal fa-keyboard-down",
          "fal fa-keyboard-left",
          "fal fa-keynote",
          "fal fa-khanda",
          "fal fa-kidneys",
          "fal fa-kip-sign",
          "fal fa-kit-medical",
          "fal fa-kitchen-set",
          "fal fa-kite",
          "fal fa-kiwi-bird",
          "fal fa-kiwi-fruit",
          "fal fa-knife",
          "fal fa-knife-kitchen",
          "fal fa-l",
          "fal fa-lacrosse-stick",
          "fal fa-lacrosse-stick-ball",
          "fal fa-lambda",
          "fal fa-lamp",
          "fal fa-lamp-desk",
          "fal fa-lamp-floor",
          "fal fa-lamp-street",
          "fal fa-land-mine-on",
          "fal fa-landmark",
          "fal fa-landmark-dome",
          "fal fa-landmark-flag",
          "fal fa-language",
          "fal fa-laptop",
          "fal fa-laptop-arrow-down",
          "fal fa-laptop-code",
          "fal fa-laptop-file",
          "fal fa-laptop-medical",
          "fal fa-laptop-mobile",
          "fal fa-laptop-slash",
          "fal fa-lari-sign",
          "fal fa-lasso",
          "fal fa-lasso-sparkles",
          "fal fa-layer-group",
          "fal fa-layer-minus",
          "fal fa-layer-plus",
          "fal fa-leaf",
          "fal fa-leaf-heart",
          "fal fa-leaf-maple",
          "fal fa-leaf-oak",
          "fal fa-leafy-green",
          "fal fa-left",
          "fal fa-left-from-line",
          "fal fa-left-long",
          "fal fa-left-long-to-line",
          "fal fa-left-right",
          "fal fa-left-to-line",
          "fal fa-lemon",
          "fal fa-less-than",
          "fal fa-less-than-equal",
          "fal fa-life-ring",
          "fal fal-ceiling",
          "fal fal-emergency",
          "fal fal-emergency-on",
          "fal fal-switch",
          "fal fal-switch-off",
          "fal fal-switch-on",
          "fal falbulb",
          "fal falbulb-cfl",
          "fal falbulb-cfl-on",
          "fal falbulb-dollar",
          "fal falbulb-exclamation",
          "fal falbulb-exclamation-on",
          "fal falbulb-on",
          "fal falbulb-slash",
          "fal fals-holiday",
          "fal fa-line-columns",
          "fal fa-line-height",
          "fal fa-lines-leaning",
          "fal fa-link",
          "fal fa-link-horizontal",
          "fal fa-link-horizontal-slash",
          "fal fa-link-simple",
          "fal fa-link-simple-slash",
          "fal fa-link-slash",
          "fal fa-lips",
          "fal fa-lira-sign",
          "fal fa-list",
          "fal fa-list-check",
          "fal fa-list-dropdown",
          "fal fa-list-music",
          "fal fa-list-ol",
          "fal fa-list-radio",
          "fal fa-list-timeline",
          "fal fa-list-tree",
          "fal fa-list-ul",
          "fal fa-litecoin-sign",
          "fal fa-loader",
          "fal fa-lobster",
          "fal fa-location-arrow",
          "fal fa-location-check",
          "fal fa-location-crosshairs",
          "fal fa-location-crosshairs-slash",
          "fal fa-location-dot",
          "fal fa-location-dot-slash",
          "fal fa-location-exclamation",
          "fal fa-location-minus",
          "fal fa-location-pen",
          "fal fa-location-pin",
          "fal fa-location-pin-lock",
          "fal fa-location-pin-slash",
          "fal fa-location-plus",
          "fal fa-location-question",
          "fal fa-location-smile",
          "fal fa-location-xmark",
          "fal fa-lock",
          "fal fa-lock-a",
          "fal fa-lock-hashtag",
          "fal fa-lock-keyhole",
          "fal fa-lock-keyhole-open",
          "fal fa-lock-open",
          "fal fa-locust",
          "fal fa-lollipop",
          "fal fa-loveseat",
          "fal fa-luchador-mask",
          "fal fa-lungs",
          "fal fa-lungs-virus",
          "fal fa-m",
          "fal fa-mace",
          "fal fa-magnet",
          "fal fa-magnifying-glass",
          "fal fa-magnifying-glass-arrow-right",
          "fal fa-magnifying-glass-chart",
          "fal fa-magnifying-glass-dollar",
          "fal fa-magnifying-glass-location",
          "fal fa-magnifying-glass-minus",
          "fal fa-magnifying-glass-plus",
          "fal fa-mailbox",
          "fal fa-mailbox-flag-up",
          "fal fa-manat-sign",
          "fal fa-mandolin",
          "fal fa-mango",
          "fal fa-manhole",
          "fal fa-map",
          "fal fa-map-location",
          "fal fa-map-location-dot",
          "fal fa-map-pin",
          "fal fa-marker",
          "fal fa-mars",
          "fal fa-mars-and-venus",
          "fal fa-mars-and-venus-burst",
          "fal fa-mars-double",
          "fal fa-mars-stroke",
          "fal fa-mars-stroke-right",
          "fal fa-mars-stroke-up",
          "fal fa-martini-glass",
          "fal fa-martini-glass-citrus",
          "fal fa-martini-glass-empty",
          "fal fa-mask",
          "fal fa-mask-face",
          "fal fa-mask-snorkel",
          "fal fa-mask-ventilator",
          "fal fa-masks-theater",
          "fal fa-mattress-pillow",
          "fal fa-maximize",
          "fal fa-meat",
          "fal fa-medal",
          "fal fa-megaphone",
          "fal fa-melon",
          "fal fa-melon-slice",
          "fal fa-memo",
          "fal fa-memo-circle-check",
          "fal fa-memo-circle-info",
          "fal fa-memo-pad",
          "fal fa-memory",
          "fal fa-menorah",
          "fal fa-mercury",
          "fal fa-merge",
          "fal fa-message",
          "fal fa-message-arrow-down",
          "fal fa-message-arrow-up",
          "fal fa-message-arrow-up-right",
          "fal fa-message-bot",
          "fal fa-message-captions",
          "fal fa-message-check",
          "fal fa-message-code",
          "fal fa-message-dollar",
          "fal fa-message-dots",
          "fal fa-message-exclamation",
          "fal fa-message-heart",
          "fal fa-message-image",
          "fal fa-message-lines",
          "fal fa-message-medical",
          "fal fa-message-middle",
          "fal fa-message-middle-top",
          "fal fa-message-minus",
          "fal fa-message-music",
          "fal fa-message-pen",
          "fal fa-message-plus",
          "fal fa-message-question",
          "fal fa-message-quote",
          "fal fa-message-slash",
          "fal fa-message-smile",
          "fal fa-message-sms",
          "fal fa-message-text",
          "fal fa-message-xmark",
          "fal fa-messages",
          "fal fa-messages-dollar",
          "fal fa-messages-question",
          "fal fa-meteor",
          "fal fa-meter",
          "fal fa-meter-bolt",
          "fal fa-meter-droplet",
          "fal fa-meter-fire",
          "fal fa-microchip",
          "fal fa-microchip-ai",
          "fal fa-microphone",
          "fal fa-microphone-lines",
          "fal fa-microphone-lines-slash",
          "fal fa-microphone-slash",
          "fal fa-microphone-stand",
          "fal fa-microscope",
          "fal fa-microwave",
          "fal fa-mill-sign",
          "fal fa-minimize",
          "fal fa-minus",
          "fal fa-mistletoe",
          "fal fa-mitten",
          "fal fa-mobile",
          "fal fa-mobile-button",
          "fal fa-mobile-notch",
          "fal fa-mobile-retro",
          "fal fa-mobile-screen",
          "fal fa-mobile-screen-button",
          "fal fa-mobile-signal",
          "fal fa-mobile-signal-out",
          "fal fa-money-bill",
          "fal fa-money-bill-1",
          "fal fa-money-bill-1-wave",
          "fal fa-money-bill-simple",
          "fal fa-money-bill-simple-wave",
          "fal fa-money-bill-transfer",
          "fal fa-money-bill-trend-up",
          "fal fa-money-bill-wave",
          "fal fa-money-bill-wheat",
          "fal fa-money-bills",
          "fal fa-money-bills-simple",
          "fal fa-money-check",
          "fal fa-money-check-dollar",
          "fal fa-money-check-dollar-pen",
          "fal fa-money-check-pen",
          "fal fa-money-from-bracket",
          "fal fa-money-simple-from-bracket",
          "fal fa-monitor-waveform",
          "fal fa-monkey",
          "fal fa-monument",
          "fal fa-moon",
          "fal fa-moon-cloud",
          "fal fa-moon-over-sun",
          "fal fa-moon-stars",
          "fal fa-moped",
          "fal fa-mortar-pestle",
          "fal fa-mosque",
          "fal fa-mosquito",
          "fal fa-mosquito-net",
          "fal fa-motorcycle",
          "fal fa-mound",
          "fal fa-mountain",
          "fal fa-mountain-city",
          "fal fa-mountain-sun",
          "fal fa-mountains",
          "fal fa-mouse-field",
          "fal fa-mp3-player",
          "fal fa-mug",
          "fal fa-mug-hot",
          "fal fa-mug-marshmallows",
          "fal fa-mug-saucer",
          "fal fa-mug-tea",
          "fal fa-mug-tea-saucer",
          "fal fa-mushroom",
          "fal fa-music",
          "fal fa-music-note",
          "fal fa-music-note-slash",
          "fal fa-music-slash",
          "fal fa-mustache",
          "fal fa-n",
          "fal fa-naira-sign",
          "fal fa-narwhal",
          "fal fa-nesting-dolls",
          "fal fa-network-wired",
          "fal fa-neuter",
          "fal fa-newspaper",
          "fal fa-nfc",
          "fal fa-nfc-lock",
          "fal fa-nfc-magnifying-glass",
          "fal fa-nfc-pen",
          "fal fa-nfc-signal",
          "fal fa-nfc-slash",
          "fal fa-nfc-symbol",
          "fal fa-nfc-trash",
          "fal fa-nose",
          "fal fa-not-equal",
          "fal fa-notdef",
          "fal fa-note",
          "fal fa-note-medical",
          "fal fa-note-sticky",
          "fal fa-notebook",
          "fal fa-notes",
          "fal fa-notes-medical",
          "fal fa-o",
          "fal fa-object-exclude",
          "fal fa-object-group",
          "fal fa-object-intersect",
          "fal fa-object-subtract",
          "fal fa-object-ungroup",
          "fal fa-object-union",
          "fal fa-objects-align-bottom",
          "fal fa-objects-align-center-horizontal",
          "fal fa-objects-align-center-vertical",
          "fal fa-objects-align-left",
          "fal fa-objects-align-right",
          "fal fa-objects-align-top",
          "fal fa-objects-column",
          "fal fa-octagon",
          "fal fa-octagon-check",
          "fal fa-octagon-divide",
          "fal fa-octagon-exclamation",
          "fal fa-octagon-minus",
          "fal fa-octagon-plus",
          "fal fa-octagon-xmark",
          "fal fa-oil-can",
          "fal fa-oil-can-drip",
          "fal fa-oil-temperature",
          "fal fa-oil-well",
          "fal fa-olive",
          "fal fa-olive-branch",
          "fal fa-om",
          "fal fa-omega",
          "fal fa-onion",
          "fal fa-option",
          "fal fa-ornament",
          "fal fa-otter",
          "fal fa-outdent",
          "fal fa-outlet",
          "fal fa-oven",
          "fal fa-overline",
          "fal fa-p",
          "fal fa-page",
          "fal fa-page-caret-down",
          "fal fa-page-caret-up",
          "fal fa-pager",
          "fal fa-paint-roller",
          "fal fa-paintbrush",
          "fal fa-paintbrush-fine",
          "fal fa-paintbrush-pencil",
          "fal fa-palette",
          "fal fa-pallet",
          "fal fa-pallet-box",
          "fal fa-pallet-boxes",
          "fal fa-pan-food",
          "fal fa-pan-frying",
          "fal fa-pancakes",
          "fal fa-panel-ews",
          "fal fa-panel-fire",
          "fal fa-panorama",
          "fal fa-paper-plane",
          "fal fa-paper-plane-top",
          "fal fa-paperclip",
          "fal fa-paperclip-vertical",
          "fal fa-parachute-box",
          "fal fa-paragraph",
          "fal fa-paragraph-left",
          "fal fa-party-bell",
          "fal fa-party-horn",
          "fal fa-passport",
          "fal fa-paste",
          "fal fa-pause",
          "fal fa-paw",
          "fal fa-paw-claws",
          "fal fa-paw-simple",
          "fal fa-peace",
          "fal fa-peach",
          "fal fa-peanut",
          "fal fa-peanuts",
          "fal fa-peapod",
          "fal fa-pear",
          "fal fa-pedestal",
          "fal fa-pegasus",
          "fal fa-pen",
          "fal fa-pen-circle",
          "fal fa-pen-clip",
          "fal fa-pen-clip-slash",
          "fal fa-pen-fancy",
          "fal fa-pen-fancy-slash",
          "fal fa-pen-field",
          "fal fa-pen-line",
          "fal fa-pen-nib",
          "fal fa-pen-nib-slash",
          "fal fa-pen-paintbrush",
          "fal fa-pen-ruler",
          "fal fa-pen-slash",
          "fal fa-pen-swirl",
          "fal fa-pen-to-square",
          "fal fa-pencil",
          "fal fa-pencil-mechanical",
          "fal fa-pencil-slash",
          "fal fa-people",
          "fal fa-people-arrows",
          "fal fa-people-carry-box",
          "fal fa-people-dress",
          "fal fa-people-dress-simple",
          "fal fa-people-group",
          "fal fa-people-line",
          "fal fa-people-pants",
          "fal fa-people-pants-simple",
          "fal fa-people-pulling",
          "fal fa-people-robbery",
          "fal fa-people-roof",
          "fal fa-people-simple",
          "fal fa-pepper",
          "fal fa-pepper-hot",
          "fal fa-percent",
          "fal fa-period",
          "fal fa-person",
          "fal fa-person-arrow-down-to-line",
          "fal fa-person-arrow-up-from-line",
          "fal fa-person-biking",
          "fal fa-person-biking-mountain",
          "fal fa-person-booth",
          "fal fa-person-breastfeeding",
          "fal fa-person-burst",
          "fal fa-person-cane",
          "fal fa-person-carry-box",
          "fal fa-person-chalkboard",
          "fal fa-person-circle-check",
          "fal fa-person-circle-exclamation",
          "fal fa-person-circle-minus",
          "fal fa-person-circle-plus",
          "fal fa-person-circle-question",
          "fal fa-person-circle-xmark",
          "fal fa-person-digging",
          "fal fa-person-dolly",
          "fal fa-person-dolly-empty",
          "fal fa-person-dots-from-line",
          "fal fa-person-dress",
          "fal fa-person-dress-burst",
          "fal fa-person-dress-simple",
          "fal fa-person-drowning",
          "fal fa-person-falling",
          "fal fa-person-falling-burst",
          "fal fa-person-from-portal",
          "fal fa-person-half-dress",
          "fal fa-person-harassing",
          "fal fa-person-hiking",
          "fal fa-person-military-pointing",
          "fal fa-person-military-rifle",
          "fal fa-person-military-to-person",
          "fal fa-person-pinball",
          "fal fa-person-praying",
          "fal fa-person-pregnant",
          "fal fa-person-rays",
          "fal fa-person-rifle",
          "fal fa-person-running",
          "fal fa-person-seat",
          "fal fa-person-seat-reclined",
          "fal fa-person-shelter",
          "fal fa-person-sign",
          "fal fa-person-simple",
          "fal fa-person-skating",
          "fal fa-person-ski-jumping",
          "fal fa-person-ski-lift",
          "fal fa-person-skiing",
          "fal fa-person-skiing-nordic",
          "fal fa-person-sledding",
          "fal fa-person-snowboarding",
          "fal fa-person-snowmobiling",
          "fal fa-person-swimming",
          "fal fa-person-through-window",
          "fal fa-person-to-door",
          "fal fa-person-to-portal",
          "fal fa-person-walking",
          "fal fa-person-walking-arrow-loop-left",
          "fal fa-person-walking-arrow-right",
          "fal fa-person-walking-dashed-line-arrow-right",
          "fal fa-person-walking-luggage",
          "fal fa-person-walking-with-cane",
          "fal fa-peseta-sign",
          "fal fa-peso-sign",
          "fal fa-phone",
          "fal fa-phone-arrow-down-left",
          "fal fa-phone-arrow-right",
          "fal fa-phone-arrow-up-right",
          "fal fa-phone-flip",
          "fal fa-phone-hangup",
          "fal fa-phone-intercom",
          "fal fa-phone-missed",
          "fal fa-phone-office",
          "fal fa-phone-plus",
          "fal fa-phone-rotary",
          "fal fa-phone-slash",
          "fal fa-phone-volume",
          "fal fa-phone-xmark",
          "fal fa-photo-film",
          "fal fa-photo-film-music",
          "fal fa-pi",
          "fal fa-piano",
          "fal fa-piano-keyboard",
          "fal fa-pickaxe",
          "fal fa-pickleball",
          "fal fa-pie",
          "fal fa-pig",
          "fal fa-piggy-bank",
          "fal fa-pills",
          "fal fa-pinata",
          "fal fa-pinball",
          "fal fa-pineapple",
          "fal fa-pipe",
          "fal fa-pipe-circle-check",
          "fal fa-pipe-collar",
          "fal fa-pipe-section",
          "fal fa-pipe-smoking",
          "fal fa-pipe-valve",
          "fal fa-pizza",
          "fal fa-pizza-slice",
          "fal fa-place-of-worship",
          "fal fa-plane",
          "fal fa-plane-arrival",
          "fal fa-plane-circle-check",
          "fal fa-plane-circle-exclamation",
          "fal fa-plane-circle-xmark",
          "fal fa-plane-departure",
          "fal fa-plane-engines",
          "fal fa-plane-lock",
          "fal fa-plane-prop",
          "fal fa-plane-slash",
          "fal fa-plane-tail",
          "fal fa-plane-up",
          "fal fa-plane-up-slash",
          "fal fa-planet-moon",
          "fal fa-planet-ringed",
          "fal fa-plant-wilt",
          "fal fa-plate-utensils",
          "fal fa-plate-wheat",
          "fal fa-play",
          "fal fa-play-pause",
          "fal fa-plug",
          "fal fa-plug-circle-bolt",
          "fal fa-plug-circle-check",
          "fal fa-plug-circle-exclamation",
          "fal fa-plug-circle-minus",
          "fal fa-plug-circle-plus",
          "fal fa-plug-circle-xmark",
          "fal fa-plus",
          "fal fa-plus-large",
          "fal fa-plus-minus",
          "fal fa-podcast",
          "fal fa-podium",
          "fal fa-podium-star",
          "fal fa-police-box",
          "fal fa-poll-people",
          "fal fa-pompebled",
          "fal fa-poo",
          "fal fa-poo-storm",
          "fal fa-pool-8-ball",
          "fal fa-poop",
          "fal fa-popcorn",
          "fal fa-popsicle",
          "fal fa-pot-food",
          "fal fa-potato",
          "fal fa-power-off",
          "fal fa-prescription",
          "fal fa-prescription-bottle",
          "fal fa-prescription-bottle-medical",
          "fal fa-prescription-bottle-pill",
          "fal fa-presentation-screen",
          "fal fa-pretzel",
          "fal fa-print",
          "fal fa-print-magnifying-glass",
          "fal fa-print-slash",
          "fal fa-projector",
          "fal fa-pump",
          "fal fa-pump-medical",
          "fal fa-pump-soap",
          "fal fa-pumpkin",
          "fal fa-puzzle",
          "fal fa-puzzle-piece",
          "fal fa-puzzle-piece-simple",
          "fal fa-q",
          "fal fa-qrcode",
          "fal fa-question",
          "fal fa-quote-left",
          "fal fa-quote-right",
          "fal fa-quotes",
          "fal fa-r",
          "fal fa-rabbit",
          "fal fa-rabbit-running",
          "fal fa-racquet",
          "fal fa-radar",
          "fal fa-radiation",
          "fal fa-radio",
          "fal fa-radio-tuner",
          "fal fa-rainbow",
          "fal fa-raindrops",
          "fal fa-ram",
          "fal fa-ramp-loading",
          "fal fa-ranking-star",
          "fal fa-raygun",
          "fal fa-receipt",
          "fal fa-record-vinyl",
          "fal fa-rectangle",
          "fal fa-rectangle-ad",
          "fal fa-rectangle-barcode",
          "fal fa-rectangle-code",
          "fal fa-rectangle-history",
          "fal fa-rectangle-history-circle-plus",
          "fal fa-rectangle-history-circle-user",
          "fal fa-rectangle-list",
          "fal fa-rectangle-pro",
          "fal fa-rectangle-terminal",
          "fal fa-rectangle-vertical",
          "fal fa-rectangle-vertical-history",
          "fal fa-rectangle-wide",
          "fal fa-rectangle-xmark",
          "fal fa-rectangles-mixed",
          "fal fa-recycle",
          "fal fa-reel",
          "fal fa-refrigerator",
          "fal fa-registered",
          "fal fa-repeat",
          "fal fa-repeat-1",
          "fal fa-reply",
          "fal fa-reply-all",
          "fal fa-reply-clock",
          "fal fa-republican",
          "fal fa-restroom",
          "fal fa-restroom-simple",
          "fal fa-retweet",
          "fal fa-rhombus",
          "fal fa-ribbon",
          "fal fa-right",
          "fal fa-right-from-bracket",
          "fal fa-right-from-line",
          "fal fa-right-left",
          "fal fa-right-long",
          "fal fa-right-long-to-line",
          "fal fa-right-to-bracket",
          "fal fa-right-to-line",
          "fal fa-ring",
          "fal fa-ring-diamond",
          "fal fa-rings-wedding",
          "fal fa-road",
          "fal fa-road-barrier",
          "fal fa-road-bridge",
          "fal fa-road-circle-check",
          "fal fa-road-circle-exclamation",
          "fal fa-road-circle-xmark",
          "fal fa-road-lock",
          "fal fa-road-spikes",
          "fal fa-robot",
          "fal fa-robot-astromech",
          "fal fa-rocket",
          "fal fa-rocket-launch",
          "fal fa-roller-coaster",
          "fal fa-rotate",
          "fal fa-rotate-exclamation",
          "fal fa-rotate-left",
          "fal fa-rotate-right",
          "fal fa-route",
          "fal fa-route-highway",
          "fal fa-route-interstate",
          "fal fa-router",
          "fal fa-rss",
          "fal fa-ruble-sign",
          "fal fa-rug",
          "fal fa-rugby-ball",
          "fal fa-ruler",
          "fal fa-ruler-combined",
          "fal fa-ruler-horizontal",
          "fal fa-ruler-triangle",
          "fal fa-ruler-vertical",
          "fal fa-rupee-sign",
          "fal fa-rupiah-sign",
          "fal fa-rv",
          "fal fa-s",
          "fal fa-sack",
          "fal fa-sack-dollar",
          "fal fa-sack-xmark",
          "fal fa-sailboat",
          "fal fa-salad",
          "fal fa-salt-shaker",
          "fal fa-sandwich",
          "fal fa-satellite",
          "fal fa-satellite-dish",
          "fal fa-sausage",
          "fal fa-saxophone",
          "fal fa-saxophone-fire",
          "fal fa-scale-balanced",
          "fal fa-scale-unbalanced",
          "fal fa-scale-unbalanced-flip",
          "fal fa-scalpel",
          "fal fa-scalpel-line-dashed",
          "fal fa-scanner-gun",
          "fal fa-scanner-image",
          "fal fa-scanner-keyboard",
          "fal fa-scanner-touchscreen",
          "fal fa-scarecrow",
          "fal fa-scarf",
          "fal fa-school",
          "fal fa-school-circle-check",
          "fal fa-school-circle-exclamation",
          "fal fa-school-circle-xmark",
          "fal fa-school-flag",
          "fal fa-school-lock",
          "fal fa-scissors",
          "fal fa-screen-users",
          "fal fa-screencast",
          "fal fa-screwdriver",
          "fal fa-screwdriver-wrench",
          "fal fa-scribble",
          "fal fa-scroll",
          "fal fa-scroll-old",
          "fal fa-scroll-torah",
          "fal fa-scrubber",
          "fal fa-scythe",
          "fal fa-sd-card",
          "fal fa-sd-cards",
          "fal fa-seal",
          "fal fa-seal-exclamation",
          "fal fa-seal-question",
          "fal fa-seat-airline",
          "fal fa-section",
          "fal fa-seedling",
          "fal fa-semicolon",
          "fal fa-send-back",
          "fal fa-send-backward",
          "fal fa-sensor",
          "fal fa-sensor-cloud",
          "fal fa-sensor-fire",
          "fal fa-sensor-on",
          "fal fa-sensor-triangle-exclamation",
          "fal fa-server",
          "fal fa-shapes",
          "fal fa-share",
          "fal fa-share-all",
          "fal fa-share-from-square",
          "fal fa-share-nodes",
          "fal fa-sheep",
          "fal fa-sheet-plastic",
          "fal fa-shekel-sign",
          "fal fa-shelves",
          "fal fa-shelves-empty",
          "fal fa-shield",
          "fal fa-shield-cat",
          "fal fa-shield-check",
          "fal fa-shield-cross",
          "fal fa-shield-dog",
          "fal fa-shield-exclamation",
          "fal fa-shield-halved",
          "fal fa-shield-heart",
          "fal fa-shield-keyhole",
          "fal fa-shield-minus",
          "fal fa-shield-plus",
          "fal fa-shield-quartered",
          "fal fa-shield-slash",
          "fal fa-shield-virus",
          "fal fa-shield-xmark",
          "fal fa-ship",
          "fal fa-shirt",
          "fal fa-shirt-long-sleeve",
          "fal fa-shirt-running",
          "fal fa-shirt-tank-top",
          "fal fa-shish-kebab",
          "fal fa-shoe-prints",
          "fal fa-shop",
          "fal fa-shop-lock",
          "fal fa-shop-slash",
          "fal fa-shovel",
          "fal fa-shovel-snow",
          "fal fa-shower",
          "fal fa-shower-down",
          "fal fa-shredder",
          "fal fa-shrimp",
          "fal fa-shuffle",
          "fal fa-shutters",
          "fal fa-shuttle-space",
          "fal fa-shuttlecock",
          "fal fa-sickle",
          "fal fa-sidebar",
          "fal fa-sidebar-flip",
          "fal fa-sigma",
          "fal fa-sign-hanging",
          "fal fa-signal",
          "fal fa-signal-bars",
          "fal fa-signal-bars-fair",
          "fal fa-signal-bars-good",
          "fal fa-signal-bars-slash",
          "fal fa-signal-bars-weak",
          "fal fa-signal-fair",
          "fal fa-signal-good",
          "fal fa-signal-slash",
          "fal fa-signal-stream",
          "fal fa-signal-stream-slash",
          "fal fa-signal-strong",
          "fal fa-signal-weak",
          "fal fa-signature",
          "fal fa-signature-lock",
          "fal fa-signature-slash",
          "fal fa-signs-post",
          "fal fa-sim-card",
          "fal fa-sim-cards",
          "fal fa-sink",
          "fal fa-siren",
          "fal fa-siren-on",
          "fal fa-sitemap",
          "fal fa-skeleton",
          "fal fa-skeleton-ribs",
          "fal fa-ski-boot",
          "fal fa-ski-boot-ski",
          "fal fa-skull",
          "fal fa-skull-cow",
          "fal fa-skull-crossbones",
          "fal fa-slash",
          "fal fa-slash-back",
          "fal fa-slash-forward",
          "fal fa-sleigh",
          "fal fa-slider",
          "fal fa-sliders",
          "fal fa-sliders-simple",
          "fal fa-sliders-up",
          "fal fa-slot-machine",
          "fal fa-smog",
          "fal fa-smoke",
          "fal fa-smoking",
          "fal fa-snake",
          "fal fa-snooze",
          "fal fa-snow-blowing",
          "fal fa-snowflake",
          "fal fa-snowflake-droplets",
          "fal fa-snowflakes",
          "fal fa-snowman",
          "fal fa-snowman-head",
          "fal fa-snowplow",
          "fal fa-soap",
          "fal fa-socks",
          "fal fa-soft-serve",
          "fal fa-solar-panel",
          "fal fa-solar-system",
          "fal fa-sort",
          "fal fa-sort-down",
          "fal fa-sort-up",
          "fal fa-spa",
          "fal fa-space-station-moon",
          "fal fa-space-station-moon-construction",
          "fal fa-spade",
          "fal fa-spaghetti-monster-flying",
          "fal fa-sparkle",
          "fal fa-sparkles",
          "fal fa-speaker",
          "fal fa-speakers",
          "fal fa-spell-check",
          "fal fa-spider",
          "fal fa-spider-black-widow",
          "fal fa-spider-web",
          "fal fa-spinner",
          "fal fa-spinner-third",
          "fal fa-split",
          "fal fa-splotch",
          "fal fa-spoon",
          "fal fa-sportsball",
          "fal fa-spray-can",
          "fal fa-spray-can-sparkles",
          "fal fa-sprinkler",
          "fal fa-sprinkler-ceiling",
          "fal fa-square",
          "fal fa-square-0",
          "fal fa-square-1",
          "fal fa-square-2",
          "fal fa-square-3",
          "fal fa-square-4",
          "fal fa-square-5",
          "fal fa-square-6",
          "fal fa-square-7",
          "fal fa-square-8",
          "fal fa-square-9",
          "fal fa-square-a",
          "fal fa-square-a-lock",
          "fal fa-square-ampersand",
          "fal fa-square-arrow-down",
          "fal fa-square-arrow-down-left",
          "fal fa-square-arrow-down-right",
          "fal fa-square-arrow-left",
          "fal fa-square-arrow-right",
          "fal fa-square-arrow-up",
          "fal fa-square-arrow-up-left",
          "fal fa-square-arrow-up-right",
          "fal fa-square-b",
          "fal fa-square-bolt",
          "fal fa-square-c",
          "fal fa-square-caret-down",
          "fal fa-square-caret-left",
          "fal fa-square-caret-right",
          "fal fa-square-caret-up",
          "fal fa-square-check",
          "fal fa-square-chevron-down",
          "fal fa-square-chevron-left",
          "fal fa-square-chevron-right",
          "fal fa-square-chevron-up",
          "fal fa-square-code",
          "fal fa-square-d",
          "fal fa-square-dashed",
          "fal fa-square-dashed-circle-plus",
          "fal fa-square-divide",
          "fal fa-square-dollar",
          "fal fa-square-down",
          "fal fa-square-down-left",
          "fal fa-square-down-right",
          "fal fa-square-e",
          "fal fa-square-ellipsis",
          "fal fa-square-ellipsis-vertical",
          "fal fa-square-envelope",
          "fal fa-square-exclamation",
          "fal fa-square-f",
          "fal fa-square-fragile",
          "fal fa-square-full",
          "fal fa-square-g",
          "fal fa-square-h",
          "fal fa-square-heart",
          "fal fa-square-i",
          "fal fa-square-info",
          "fal fa-square-j",
          "fal fa-square-k",
          "fal fa-square-kanban",
          "fal fa-square-l",
          "fal fa-square-left",
          "fal fa-square-list",
          "fal fa-square-m",
          "fal fa-square-minus",
          "fal fa-square-n",
          "fal fa-square-nfi",
          "fal fa-square-o",
          "fal fa-square-p",
          "fal fa-square-parking",
          "fal fa-square-parking-slash",
          "fal fa-square-pen",
          "fal fa-square-person-confined",
          "fal fa-square-phone",
          "fal fa-square-phone-flip",
          "fal fa-square-phone-hangup",
          "fal fa-square-plus",
          "fal fa-square-poll-horizontal",
          "fal fa-square-poll-vertical",
          "fal fa-square-q",
          "fal fa-square-quarters",
          "fal fa-square-question",
          "fal fa-square-quote",
          "fal fa-square-r",
          "fal fa-square-right",
          "fal fa-square-ring",
          "fal fa-square-root",
          "fal fa-square-root-variable",
          "fal fa-square-rss",
          "fal fa-square-s",
          "fal fa-square-share-nodes",
          "fal fa-square-sliders",
          "fal fa-square-sliders-vertical",
          "fal fa-square-small",
          "fal fa-square-star",
          "fal fa-square-t",
          "fal fa-square-terminal",
          "fal fa-square-this-way-up",
          "fal fa-square-u",
          "fal fa-square-up",
          "fal fa-square-up-left",
          "fal fa-square-up-right",
          "fal fa-square-user",
          "fal fa-square-v",
          "fal fa-square-virus",
          "fal fa-square-w",
          "fal fa-square-x",
          "fal fa-square-xmark",
          "fal fa-square-y",
          "fal fa-square-z",
          "fal fa-squid",
          "fal fa-squirrel",
          "fal fa-staff",
          "fal fa-staff-snake",
          "fal fa-stairs",
          "fal fa-stamp",
          "fal fa-standard-definition",
          "fal fa-stapler",
          "fal fa-star",
          "fal fa-star-and-crescent",
          "fal fa-star-christmas",
          "fal fa-star-exclamation",
          "fal fa-star-half",
          "fal fa-star-half-stroke",
          "fal fa-star-of-david",
          "fal fa-star-of-life",
          "fal fa-star-sharp",
          "fal fa-star-sharp-half",
          "fal fa-star-sharp-half-stroke",
          "fal fa-star-shooting",
          "fal fa-starfighter",
          "fal fa-starfighter-twin-ion-engine",
          "fal fa-starfighter-twin-ion-engine-advanced",
          "fal fa-stars",
          "fal fa-starship",
          "fal fa-starship-freighter",
          "fal fa-steak",
          "fal fa-steering-wheel",
          "fal fa-sterling-sign",
          "fal fa-stethoscope",
          "fal fa-stocking",
          "fal fa-stomach",
          "fal fa-stop",
          "fal fa-stopwatch",
          "fal fa-stopwatch-20",
          "fal fa-store",
          "fal fa-store-lock",
          "fal fa-store-slash",
          "fal fa-strawberry",
          "fal fa-street-view",
          "fal fa-stretcher",
          "fal fa-strikethrough",
          "fal fa-stroopwafel",
          "fal fa-subscript",
          "fal fa-suitcase",
          "fal fa-suitcase-medical",
          "fal fa-suitcase-rolling",
          "fal fa-sun",
          "fal fa-sun-bright",
          "fal fa-sun-cloud",
          "fal fa-sun-dust",
          "fal fa-sun-haze",
          "fal fa-sun-plant-wilt",
          "fal fa-sunglasses",
          "fal fa-sunrise",
          "fal fa-sunset",
          "fal fa-superscript",
          "fal fa-sushi",
          "fal fa-sushi-roll",
          "fal fa-swatchbook",
          "fal fa-sword",
          "fal fa-sword-laser",
          "fal fa-sword-laser-alt",
          "fal fa-swords",
          "fal fa-swords-laser",
          "fal fa-symbols",
          "fal fa-synagogue",
          "fal fa-syringe",
          "fal fa-t",
          "fal fa-table",
          "fal fa-table-cells",
          "fal fa-table-cells-large",
          "fal fa-table-columns",
          "fal fa-table-layout",
          "fal fa-table-list",
          "fal fa-table-picnic",
          "fal fa-table-pivot",
          "fal fa-table-rows",
          "fal fa-table-tennis-paddle-ball",
          "fal fa-table-tree",
          "fal fa-tablet",
          "fal fa-tablet-button",
          "fal fa-tablet-rugged",
          "fal fa-tablet-screen",
          "fal fa-tablet-screen-button",
          "fal fa-tablets",
          "fal fa-tachograph-digital",
          "fal fa-taco",
          "fal fa-tag",
          "fal fa-tags",
          "fal fa-tally",
          "fal fa-tally-1",
          "fal fa-tally-2",
          "fal fa-tally-3",
          "fal fa-tally-4",
          "fal fa-tamale",
          "fal fa-tank-water",
          "fal fa-tape",
          "fal fa-tarp",
          "fal fa-tarp-droplet",
          "fal fa-taxi",
          "fal fa-taxi-bus",
          "fal fa-teddy-bear",
          "fal fa-teeth",
          "fal fa-teeth-open",
          "fal fa-telescope",
          "fal fa-temperature-arrow-down",
          "fal fa-temperature-arrow-up",
          "fal fa-temperature-empty",
          "fal fa-temperature-full",
          "fal fa-temperature-half",
          "fal fa-temperature-high",
          "fal fa-temperature-list",
          "fal fa-temperature-low",
          "fal fa-temperature-quarter",
          "fal fa-temperature-snow",
          "fal fa-temperature-sun",
          "fal fa-temperature-three-quarters",
          "fal fa-tenge-sign",
          "fal fa-tennis-ball",
          "fal fa-tent",
          "fal fa-tent-arrow-down-to-line",
          "fal fa-tent-arrow-left-right",
          "fal fa-tent-arrow-turn-left",
          "fal fa-tent-arrows-down",
          "fal fa-tents",
          "fal fa-terminal",
          "fal fa-text",
          "fal fa-text-height",
          "fal fa-text-size",
          "fal fa-text-slash",
          "fal fa-text-width",
          "fal fa-thermometer",
          "fal fa-theta",
          "fal fa-thought-bubble",
          "fal fa-thumbs-down",
          "fal fa-thumbs-up",
          "fal fa-thumbtack",
          "fal fa-tick",
          "fal fa-ticket",
          "fal fa-ticket-airline",
          "fal fa-ticket-simple",
          "fal fa-tickets-airline",
          "fal fa-tilde",
          "fal fa-timeline",
          "fal fa-timeline-arrow",
          "fal fa-timer",
          "fal fa-tire",
          "fal fa-tire-flat",
          "fal fa-tire-pressure-warning",
          "fal fa-tire-rugged",
          "fal fa-toggle-large-off",
          "fal fa-toggle-large-on",
          "fal fa-toggle-off",
          "fal fa-toggle-on",
          "fal fa-toilet",
          "fal fa-toilet-paper",
          "fal fa-toilet-paper-blank",
          "fal fa-toilet-paper-blank-under",
          "fal fa-toilet-paper-check",
          "fal fa-toilet-paper-slash",
          "fal fa-toilet-paper-under",
          "fal fa-toilet-paper-under-slash",
          "fal fa-toilet-paper-xmark",
          "fal fa-toilet-portable",
          "fal fa-toilets-portable",
          "fal fa-tomato",
          "fal fa-tombstone",
          "fal fa-tombstone-blank",
          "fal fa-toolbox",
          "fal fa-tooth",
          "fal fa-toothbrush",
          "fal fa-torii-gate",
          "fal fa-tornado",
          "fal fa-tower-broadcast",
          "fal fa-tower-cell",
          "fal fa-tower-control",
          "fal fa-tower-observation",
          "fal fa-tractor",
          "fal fa-trademark",
          "fal fa-traffic-cone",
          "fal fa-traffic-light",
          "fal fa-traffic-light-go",
          "fal fa-traffic-light-slow",
          "fal fa-traffic-light-stop",
          "fal fa-trailer",
          "fal fa-train",
          "fal fa-train-subway",
          "fal fa-train-subway-tunnel",
          "fal fa-train-track",
          "fal fa-train-tram",
          "fal fa-train-tunnel",
          "fal fa-transformer-bolt",
          "fal fa-transgender",
          "fal fa-transporter",
          "fal fa-transporter-1",
          "fal fa-transporter-2",
          "fal fa-transporter-3",
          "fal fa-transporter-4",
          "fal fa-transporter-5",
          "fal fa-transporter-6",
          "fal fa-transporter-7",
          "fal fa-transporter-empty",
          "fal fa-trash",
          "fal fa-trash-arrow-up",
          "fal fa-trash-can",
          "fal fa-trash-can-arrow-up",
          "fal fa-trash-can-check",
          "fal fa-trash-can-clock",
          "fal fa-trash-can-list",
          "fal fa-trash-can-plus",
          "fal fa-trash-can-slash",
          "fal fa-trash-can-undo",
          "fal fa-trash-can-xmark",
          "fal fa-trash-check",
          "fal fa-trash-clock",
          "fal fa-trash-list",
          "fal fa-trash-plus",
          "fal fa-trash-slash",
          "fal fa-trash-undo",
          "fal fa-trash-xmark",
          "fal fa-treasure-chest",
          "fal fa-tree",
          "fal fa-tree-christmas",
          "fal fa-tree-city",
          "fal fa-tree-deciduous",
          "fal fa-tree-decorated",
          "fal fa-tree-large",
          "fal fa-tree-palm",
          "fal fa-trees",
          "fal fa-triangle",
          "fal fa-triangle-exclamation",
          "fal fa-triangle-instrument",
          "fal fa-triangle-person-digging",
          "fal fa-tricycle",
          "fal fa-tricycle-adult",
          "fal fa-trillium",
          "fal fa-trophy",
          "fal fa-trophy-star",
          "fal fa-trowel",
          "fal fa-trowel-bricks",
          "fal fa-truck",
          "fal fa-truck-arrow-right",
          "fal fa-truck-bolt",
          "fal fa-truck-clock",
          "fal fa-truck-container",
          "fal fa-truck-container-empty",
          "fal fa-truck-droplet",
          "fal fa-truck-fast",
          "fal fa-truck-field",
          "fal fa-truck-field-un",
          "fal fa-truck-flatbed",
          "fal fa-truck-front",
          "fal fa-truck-medical",
          "fal fa-truck-monster",
          "fal fa-truck-moving",
          "fal fa-truck-pickup",
          "fal fa-truck-plane",
          "fal fa-truck-plow",
          "fal fa-truck-ramp",
          "fal fa-truck-ramp-box",
          "fal fa-truck-ramp-couch",
          "fal fa-truck-tow",
          "fal fa-trumpet",
          "fal fa-tty",
          "fal fa-tty-answer",
          "fal fa-tugrik-sign",
          "fal fa-turkey",
          "fal fa-turkish-lira-sign",
          "fal fa-turn-down",
          "fal fa-turn-down-left",
          "fal fa-turn-down-right",
          "fal fa-turn-up",
          "fal fa-turntable",
          "fal fa-turtle",
          "fal fa-tv",
          "fal fa-tv-music",
          "fal fa-tv-retro",
          "fal fa-typewriter",
          "fal fa-u",
          "fal fa-ufo",
          "fal fa-ufo-beam",
          "fal fa-umbrella",
          "fal fa-umbrella-beach",
          "fal fa-umbrella-simple",
          "fal fa-underline",
          "fal fa-unicorn",
          "fal fa-uniform-martial-arts",
          "fal fa-union",
          "fal fa-universal-access",
          "fal fa-unlock",
          "fal fa-unlock-keyhole",
          "fal fa-up",
          "fal fa-up-down",
          "fal fa-up-down-left-right",
          "fal fa-up-from-bracket",
          "fal fa-up-from-dotted-line",
          "fal fa-up-from-line",
          "fal fa-up-left",
          "fal fa-up-long",
          "fal fa-up-right",
          "fal fa-up-right-and-down-left-from-center",
          "fal fa-up-right-from-square",
          "fal fa-up-to-dotted-line",
          "fal fa-up-to-line",
          "fal fa-upload",
          "fal fa-usb-drive",
          "fal fa-user",
          "fal fa-user-alien",
          "fal fa-user-astronaut",
          "fal fa-user-bounty-hunter",
          "fal fa-user-check",
          "fal fa-user-chef",
          "fal fa-user-clock",
          "fal fa-user-cowboy",
          "fal fa-user-crown",
          "fal fa-user-doctor",
          "fal fa-user-doctor-hair",
          "fal fa-user-doctor-hair-long",
          "fal fa-user-doctor-message",
          "fal fa-user-gear",
          "fal fa-user-graduate",
          "fal fa-user-group",
          "fal fa-user-group-crown",
          "fal fa-user-hair",
          "fal fa-user-hair-buns",
          "fal fa-user-hair-long",
          "fal fa-user-hair-mullet",
          "fal fa-user-headset",
          "fal fa-user-helmet-safety",
          "fal fa-user-injured",
          "fal fa-user-large",
          "fal fa-user-large-slash",
          "fal fa-user-lock",
          "fal fa-user-magnifying-glass",
          "fal fa-user-minus",
          "fal fa-user-music",
          "fal fa-user-ninja",
          "fal fa-user-nurse",
          "fal fa-user-nurse-hair",
          "fal fa-user-nurse-hair-long",
          "fal fa-user-pen",
          "fal fa-user-pilot",
          "fal fa-user-pilot-tie",
          "fal fa-user-plus",
          "fal fa-user-police",
          "fal fa-user-police-tie",
          "fal fa-user-robot",
          "fal fa-user-robot-xmarks",
          "fal fa-user-secret",
          "fal fa-user-shakespeare",
          "fal fa-user-shield",
          "fal fa-user-slash",
          "fal fa-user-tag",
          "fal fa-user-tie",
          "fal fa-user-tie-hair",
          "fal fa-user-tie-hair-long",
          "fal fa-user-unlock",
          "fal fa-user-visor",
          "fal fa-user-vneck",
          "fal fa-user-vneck-hair",
          "fal fa-user-vneck-hair-long",
          "fal fa-user-xmark",
          "fal fa-users",
          "fal fa-users-between-lines",
          "fal fa-users-gear",
          "fal fa-users-line",
          "fal fa-users-medical",
          "fal fa-users-rays",
          "fal fa-users-rectangle",
          "fal fa-users-slash",
          "fal fa-users-viewfinder",
          "fal fa-utensils",
          "fal fa-utensils-slash",
          "fal fa-utility-pole",
          "fal fa-utility-pole-double",
          "fal fa-v",
          "fal fa-vacuum",
          "fal fa-vacuum-robot",
          "fal fa-value-absolute",
          "fal fa-van-shuttle",
          "fal fa-vault",
          "fal fa-vector-circle",
          "fal fa-vector-polygon",
          "fal fa-vector-square",
          "fal fa-vent-damper",
          "fal fa-venus",
          "fal fa-venus-double",
          "fal fa-venus-mars",
          "fal fa-vest",
          "fal fa-vest-patches",
          "fal fa-vial",
          "fal fa-vial-circle-check",
          "fal fa-vial-virus",
          "fal fa-vials",
          "fal fa-video",
          "fal fa-video-arrow-down-left",
          "fal fa-video-arrow-up-right",
          "fal fa-video-plus",
          "fal fa-video-slash",
          "fal fa-vihara",
          "fal fa-violin",
          "fal fa-virus",
          "fal fa-virus-covid",
          "fal fa-virus-covid-slash",
          "fal fa-virus-slash",
          "fal fa-viruses",
          "fal fa-voicemail",
          "fal fa-volcano",
          "fal fa-volleyball",
          "fal fa-volume",
          "fal fa-volume-high",
          "fal fa-volume-low",
          "fal fa-volume-off",
          "fal fa-volume-slash",
          "fal fa-volume-xmark",
          "fal fa-vr-cardboard",
          "fal fa-w",
          "fal fa-waffle",
          "fal fa-wagon-covered",
          "fal fa-walker",
          "fal fa-walkie-talkie",
          "fal fa-wallet",
          "fal fa-wand",
          "fal fa-wand-magic",
          "fal fa-wand-magic-sparkles",
          "fal fa-wand-sparkles",
          "fal fa-warehouse",
          "fal fa-warehouse-full",
          "fal fa-washing-machine",
          "fal fa-watch",
          "fal fa-watch-apple",
          "fal fa-watch-calculator",
          "fal fa-watch-fitness",
          "fal fa-watch-smart",
          "fal fa-water",
          "fal fa-water-arrow-down",
          "fal fa-water-arrow-up",
          "fal fa-water-ladder",
          "fal fa-watermelon-slice",
          "fal fa-wave-pulse",
          "fal fa-wave-sine",
          "fal fa-wave-square",
          "fal fa-wave-triangle",
          "fal fa-waveform",
          "fal fa-waveform-lines",
          "fal fa-webhook",
          "fal fa-weight-hanging",
          "fal fa-weight-scale",
          "fal fa-whale",
          "fal fa-wheat",
          "fal fa-wheat-awn",
          "fal fa-wheat-awn-circle-exclamation",
          "fal fa-wheat-awn-slash",
          "fal fa-wheat-slash",
          "fal fa-wheelchair",
          "fal fa-wheelchair-move",
          "fal fa-whiskey-glass",
          "fal fa-whiskey-glass-ice",
          "fal fa-whistle",
          "fal fa-wifi",
          "fal fa-wifi-exclamation",
          "fal fa-wifi-fair",
          "fal fa-wifi-slash",
          "fal fa-wifi-weak",
          "fal fa-wind",
          "fal fa-wind-turbine",
          "fal fa-wind-warning",
          "fal fa-window",
          "fal fa-window-flip",
          "fal fa-window-frame",
          "fal fa-window-frame-open",
          "fal fa-window-maximize",
          "fal fa-window-minimize",
          "fal fa-window-restore",
          "fal fa-windsock",
          "fal fa-wine-bottle",
          "fal fa-wine-glass",
          "fal fa-wine-glass-crack",
          "fal fa-wine-glass-empty",
          "fal fa-won-sign",
          "fal fa-worm",
          "fal fa-wreath",
          "fal fa-wreath-laurel",
          "fal fa-wrench",
          "fal fa-wrench-simple",
          "fal fa-x",
          "fal fa-x-ray",
          "fal fa-xmark",
          "fal fa-xmark-large",
          "fal fa-xmark-to-slot",
          "fal fa-xmarks-lines",
          "fal fa-y",
          "fal fa-yen-sign",
          "fal fa-yin-yang",
          "fal fa-z"
        ]
      },
      thin: {
        title: 'thin',
        prefix: 'fat fa-',
        iconstyle: 'fat',
        listicon: 'fat fa-flag',        
        icons: [
          "fat fa-0",
          "fat fa-1",
          "fat fa-2",
          "fat fa-3",
          "fat fa-4",
          "fat fa-5",
          "fat fa-6",
          "fat fa-7",
          "fat fa-8",
          "fat fa-9",
          "fat fa-00",
          "fat fa-360-degrees",
          "fat fa-a",
          "fat fa-abacus",
          "fat fa-accent-grave",
          "fat fa-acorn",
          "fat fa-address-book",
          "fat fa-address-card",
          "fat fa-air-conditioner",
          "fat fa-airplay",
          "fat fa-alarm-clock",
          "fat fa-alarm-exclamation",
          "fat fa-alarm-plus",
          "fat fa-alarm-snooze",
          "fat fa-album",
          "fat fa-album-circle-plus",
          "fat fa-album-circle-user",
          "fat fa-album-collection",
          "fat fa-album-collection-circle-plus",
          "fat fa-album-collection-circle-user",
          "fat fa-alicorn",
          "fat fa-alien",
          "fat fa-alien-8bit",
          "fat fa-align-center",
          "fat fa-align-justify",
          "fat fa-align-left",
          "fat fa-align-right",
          "fat fa-align-slash",
          "fat fa-alt",
          "fat fa-amp-guitar",
          "fat fa-ampersand",
          "fat fa-anchor",
          "fat fa-anchor-circle-check",
          "fat fa-anchor-circle-exclamation",
          "fat fa-anchor-circle-xmark",
          "fat fa-anchor-lock",
          "fat fa-angel",
          "fat fa-angle",
          "fat fa-angle-90",
          "fat fa-angle-down",
          "fat fa-angle-left",
          "fat fa-angle-right",
          "fat fa-angle-up",
          "fat fa-angles-down",
          "fat fa-angles-left",
          "fat fa-angles-right",
          "fat fa-angles-up",
          "fat fa-ankh",
          "fat fa-apartment",
          "fat fa-aperture",
          "fat fa-apostrophe",
          "fat fa-apple-core",
          "fat fa-apple-whole",
          "fat fa-archway",
          "fat fa-arrow-down",
          "fat fa-arrow-down-1-9",
          "fat fa-arrow-down-9-1",
          "fat fa-arrow-down-a-z",
          "fat fa-arrow-down-arrow-up",
          "fat fa-arrow-down-big-small",
          "fat fa-arrow-down-from-dotted-line",
          "fat fa-arrow-down-from-line",
          "fat fa-arrow-down-left",
          "fat fa-arrow-down-left-and-arrow-up-right-to-center",
          "fat fa-arrow-down-long",
          "fat fa-arrow-down-right",
          "fat fa-arrow-down-short-wide",
          "fat fa-arrow-down-small-big",
          "fat fa-arrow-down-square-triangle",
          "fat fa-arrow-down-to-arc",
          "fat fa-arrow-down-to-bracket",
          "fat fa-arrow-down-to-dotted-line",
          "fat fa-arrow-down-to-line",
          "fat fa-arrow-down-to-square",
          "fat fa-arrow-down-triangle-square",
          "fat fa-arrow-down-up-across-line",
          "fat fa-arrow-down-up-lock",
          "fat fa-arrow-down-wide-short",
          "fat fa-arrow-down-z-a",
          "fat fa-arrow-left",
          "fat fa-arrow-left-from-line",
          "fat fa-arrow-left-long",
          "fat fa-arrow-left-long-to-line",
          "fat fa-arrow-left-to-line",
          "fat fa-arrow-pointer",
          "fat fa-arrow-right",
          "fat fa-arrow-right-arrow-left",
          "fat fa-arrow-right-from-arc",
          "fat fa-arrow-right-from-bracket",
          "fat fa-arrow-right-from-line",
          "fat fa-arrow-right-long",
          "fat fa-arrow-right-long-to-line",
          "fat fa-arrow-right-to-arc",
          "fat fa-arrow-right-to-bracket",
          "fat fa-arrow-right-to-city",
          "fat fa-arrow-right-to-line",
          "fat fa-arrow-rotate-left",
          "fat fa-arrow-rotate-right",
          "fat fa-arrow-trend-down",
          "fat fa-arrow-trend-up",
          "fat fa-arrow-turn-down",
          "fat fa-arrow-turn-down-left",
          "fat fa-arrow-turn-down-right",
          "fat fa-arrow-turn-up",
          "fat fa-arrow-up",
          "fat fa-arrow-up-1-9",
          "fat fa-arrow-up-9-1",
          "fat fa-arrow-up-a-z",
          "fat fa-arrow-up-arrow-down",
          "fat fa-arrow-up-big-small",
          "fat fa-arrow-up-from-arc",
          "fat fa-arrow-up-from-bracket",
          "fat fa-arrow-up-from-dotted-line",
          "fat fa-arrow-up-from-ground-water",
          "fat fa-arrow-up-from-line",
          "fat fa-arrow-up-from-square",
          "fat fa-arrow-up-from-water-pump",
          "fat fa-arrow-up-left",
          "fat fa-arrow-up-left-from-circle",
          "fat fa-arrow-up-long",
          "fat fa-arrow-up-right",
          "fat fa-arrow-up-right-and-arrow-down-left-from-center",
          "fat fa-arrow-up-right-dots",
          "fat fa-arrow-up-right-from-square",
          "fat fa-arrow-up-short-wide",
          "fat fa-arrow-up-small-big",
          "fat fa-arrow-up-square-triangle",
          "fat fa-arrow-up-to-dotted-line",
          "fat fa-arrow-up-to-line",
          "fat fa-arrow-up-triangle-square",
          "fat fa-arrow-up-wide-short",
          "fat fa-arrow-up-z-a",
          "fat fa-arrows-cross",
          "fat fa-arrows-down-to-line",
          "fat fa-arrows-down-to-people",
          "fat fa-arrows-from-dotted-line",
          "fat fa-arrows-from-line",
          "fat fa-arrows-left-right",
          "fat fa-arrows-left-right-to-line",
          "fat fa-arrows-maximize",
          "fat fa-arrows-minimize",
          "fat fa-arrows-repeat",
          "fat fa-arrows-repeat-1",
          "fat fa-arrows-retweet",
          "fat fa-arrows-rotate",
          "fat fa-arrows-spin",
          "fat fa-arrows-split-up-and-left",
          "fat fa-arrows-to-circle",
          "fat fa-arrows-to-dot",
          "fat fa-arrows-to-dotted-line",
          "fat fa-arrows-to-eye",
          "fat fa-arrows-to-line",
          "fat fa-arrows-turn-right",
          "fat fa-arrows-turn-to-dots",
          "fat fa-arrows-up-down",
          "fat fa-arrows-up-down-left-right",
          "fat fa-arrows-up-to-line",
          "fat fa-asterisk",
          "fat fa-at",
          "fat fa-atom",
          "fat fa-atom-simple",
          "fat fa-audio-description",
          "fat fa-audio-description-slash",
          "fat fa-austral-sign",
          "fat fa-avocado",
          "fat fa-award",
          "fat fa-award-simple",
          "fat fa-axe",
          "fat fa-axe-battle",
          "fat fa-b",
          "fat fa-baby",
          "fat fa-baby-carriage",
          "fat fa-backpack",
          "fat fa-backward",
          "fat fa-backward-fast",
          "fat fa-backward-step",
          "fat fa-bacon",
          "fat fa-bacteria",
          "fat fa-bacterium",
          "fat fa-badge",
          "fat fa-badge-check",
          "fat fa-badge-dollar",
          "fat fa-badge-percent",
          "fat fa-badge-sheriff",
          "fat fa-badger-honey",
          "fat fa-badminton",
          "fat fa-bag-shopping",
          "fat fa-bagel",
          "fat fa-bags-shopping",
          "fat fa-baguette",
          "fat fa-bahai",
          "fat fa-baht-sign",
          "fat fa-ball-pile",
          "fat fa-balloon",
          "fat fa-balloons",
          "fat fa-ballot",
          "fat fa-ballot-check",
          "fat fa-ban",
          "fat fa-ban-bug",
          "fat fa-ban-parking",
          "fat fa-ban-smoking",
          "fat fa-banana",
          "fat fa-bandage",
          "fat fa-bangladeshi-taka-sign",
          "fat fa-banjo",
          "fat fa-barcode",
          "fat fa-barcode-read",
          "fat fa-barcode-scan",
          "fat fa-bars",
          "fat fa-bars-filter",
          "fat fa-bars-progress",
          "fat fa-bars-sort",
          "fat fa-bars-staggered",
          "fat fa-baseball",
          "fat fa-baseball-bat-ball",
          "fat fa-basket-shopping",
          "fat fa-basket-shopping-simple",
          "fat fa-basketball",
          "fat fa-basketball-hoop",
          "fat fa-bat",
          "fat fa-bath",
          "fat fa-battery-bolt",
          "fat fa-battery-empty",
          "fat fa-battery-exclamation",
          "fat fa-battery-full",
          "fat fa-battery-half",
          "fat fa-battery-low",
          "fat fa-battery-quarter",
          "fat fa-battery-slash",
          "fat fa-battery-three-quarters",
          "fat fa-bed",
          "fat fa-bed-bunk",
          "fat fa-bed-empty",
          "fat fa-bed-front",
          "fat fa-bed-pulse",
          "fat fa-bee",
          "fat fa-beer-mug",
          "fat fa-beer-mug-empty",
          "fat fa-bell",
          "fat fa-bell-concierge",
          "fat fa-bell-exclamation",
          "fat fa-bell-on",
          "fat fa-bell-plus",
          "fat fa-bell-school",
          "fat fa-bell-school-slash",
          "fat fa-bell-slash",
          "fat fa-bells",
          "fat fa-bench-tree",
          "fat fa-bezier-curve",
          "fat fa-bicycle",
          "fat fa-billboard",
          "fat fa-binary",
          "fat fa-binary-circle-check",
          "fat fa-binary-lock",
          "fat fa-binary-slash",
          "fat fa-binoculars",
          "fat fa-biohazard",
          "fat fa-bird",
          "fat fa-bitcoin-sign",
          "fat fa-blanket",
          "fat fa-blanket-fire",
          "fat fa-blender",
          "fat fa-blender-phone",
          "fat fa-blinds",
          "fat fa-blinds-open",
          "fat fa-blinds-raised",
          "fat fa-block",
          "fat fa-block-brick",
          "fat fa-block-brick-fire",
          "fat fa-block-question",
          "fat fa-block-quote",
          "fat fa-blog",
          "fat fa-blueberries",
          "fat fa-bluetooth",
          "fat fa-bold",
          "fat fa-bolt",
          "fat fa-bolt-auto",
          "fat fa-bolt-lightning",
          "fat fa-bolt-slash",
          "fat fa-bomb",
          "fat fa-bone",
          "fat fa-bone-break",
          "fat fa-bong",
          "fat fa-book",
          "fat fa-book-arrow-right",
          "fat fa-book-arrow-up",
          "fat fa-book-atlas",
          "fat fa-book-bible",
          "fat fa-book-blank",
          "fat fa-book-bookmark",
          "fat fa-book-circle-arrow-right",
          "fat fa-book-circle-arrow-up",
          "fat fa-book-copy",
          "fat fa-book-font",
          "fat fa-book-heart",
          "fat fa-book-journal-whills",
          "fat fa-book-medical",
          "fat fa-book-open",
          "fat fa-book-open-cover",
          "fat fa-book-open-reader",
          "fat fa-book-quran",
          "fat fa-book-section",
          "fat fa-book-skull",
          "fat fa-book-sparkles",
          "fat fa-book-tanakh",
          "fat fa-book-user",
          "fat fa-bookmark",
          "fat fa-bookmark-slash",
          "fat fa-books",
          "fat fa-books-medical",
          "fat fa-boombox",
          "fat fa-boot",
          "fat fa-boot-heeled",
          "fat fa-booth-curtain",
          "fat fa-border-all",
          "fat fa-border-bottom",
          "fat fa-border-bottom-right",
          "fat fa-border-center-h",
          "fat fa-border-center-v",
          "fat fa-border-inner",
          "fat fa-border-left",
          "fat fa-border-none",
          "fat fa-border-outer",
          "fat fa-border-right",
          "fat fa-border-top",
          "fat fa-border-top-left",
          "fat fa-bore-hole",
          "fat fa-bottle-droplet",
          "fat fa-bottle-water",
          "fat fa-bow-arrow",
          "fat fa-bowl-chopsticks",
          "fat fa-bowl-chopsticks-noodles",
          "fat fa-bowl-food",
          "fat fa-bowl-hot",
          "fat fa-bowl-rice",
          "fat fa-bowl-scoop",
          "fat fa-bowl-scoops",
          "fat fa-bowl-soft-serve",
          "fat fa-bowl-spoon",
          "fat fa-bowling-ball",
          "fat fa-bowling-ball-pin",
          "fat fa-bowling-pins",
          "fat fa-box",
          "fat fa-box-archive",
          "fat fa-box-ballot",
          "fat fa-box-check",
          "fat fa-box-circle-check",
          "fat fa-box-dollar",
          "fat fa-box-heart",
          "fat fa-box-open",
          "fat fa-box-open-full",
          "fat fa-box-taped",
          "fat fa-box-tissue",
          "fat fa-boxes-packing",
          "fat fa-boxes-stacked",
          "fat fa-boxing-glove",
          "fat fa-bracket-curly",
          "fat fa-bracket-curly-right",
          "fat fa-bracket-round",
          "fat fa-bracket-round-right",
          "fat fa-bracket-square",
          "fat fa-bracket-square-right",
          "fat fa-brackets-curly",
          "fat fa-brackets-round",
          "fat fa-brackets-square",
          "fat fa-braille",
          "fat fa-brain",
          "fat fa-brain-arrow-curved-right",
          "fat fa-brain-circuit",
          "fat fa-brake-warning",
          "fat fa-brazilian-real-sign",
          "fat fa-bread-loaf",
          "fat fa-bread-slice",
          "fat fa-bread-slice-butter",
          "fat fa-bridge",
          "fat fa-bridge-circle-check",
          "fat fa-bridge-circle-exclamation",
          "fat fa-bridge-circle-xmark",
          "fat fa-bridge-lock",
          "fat fa-bridge-suspension",
          "fat fa-bridge-water",
          "fat fa-briefcase",
          "fat fa-briefcase-arrow-right",
          "fat fa-briefcase-blank",
          "fat fa-briefcase-medical",
          "fat fa-brightness",
          "fat fa-brightness-low",
          "fat fa-bring-forward",
          "fat fa-bring-front",
          "fat fa-broccoli",
          "fat fa-broom",
          "fat fa-broom-ball",
          "fat fa-broom-wide",
          "fat fa-browser",
          "fat fa-browsers",
          "fat fa-brush",
          "fat fa-bucket",
          "fat fa-bug",
          "fat fa-bug-slash",
          "fat fa-bugs",
          "fat fa-building",
          "fat fa-building-circle-arrow-right",
          "fat fa-building-circle-check",
          "fat fa-building-circle-exclamation",
          "fat fa-building-circle-xmark",
          "fat fa-building-columns",
          "fat fa-building-flag",
          "fat fa-building-lock",
          "fat fa-building-ngo",
          "fat fa-building-shield",
          "fat fa-building-un",
          "fat fa-building-user",
          "fat fa-building-wheat",
          "fat fa-buildings",
          "fat fa-bullhorn",
          "fat fa-bullseye",
          "fat fa-bullseye-arrow",
          "fat fa-bullseye-pointer",
          "fat fa-buoy",
          "fat fa-buoy-mooring",
          "fat fa-burger",
          "fat fa-burger-cheese",
          "fat fa-burger-fries",
          "fat fa-burger-glass",
          "fat fa-burger-lettuce",
          "fat fa-burger-soda",
          "fat fa-burrito",
          "fat fa-burst",
          "fat fa-bus",
          "fat fa-bus-school",
          "fat fa-bus-simple",
          "fat fa-business-time",
          "fat fa-butter",
          "fat fa-c",
          "fat fa-cabin",
          "fat fa-cabinet-filing",
          "fat fa-cable-car",
          "fat fa-cactus",
          "fat fa-cake-candles",
          "fat fa-cake-slice",
          "fat fa-calculator",
          "fat fa-calculator-simple",
          "fat fa-calendar",
          "fat fa-calendar-arrow-down",
          "fat fa-calendar-arrow-up",
          "fat fa-calendar-check",
          "fat fa-calendar-circle-exclamation",
          "fat fa-calendar-circle-minus",
          "fat fa-calendar-circle-plus",
          "fat fa-calendar-circle-user",
          "fat fa-calendar-clock",
          "fat fa-calendar-day",
          "fat fa-calendar-days",
          "fat fa-calendar-exclamation",
          "fat fa-calendar-heart",
          "fat fa-calendar-image",
          "fat fa-calendar-lines",
          "fat fa-calendar-lines-pen",
          "fat fa-calendar-minus",
          "fat fa-calendar-pen",
          "fat fa-calendar-plus",
          "fat fa-calendar-range",
          "fat fa-calendar-star",
          "fat fa-calendar-week",
          "fat fa-calendar-xmark",
          "fat fa-calendars",
          "fat fa-camcorder",
          "fat fa-camera",
          "fat fa-camera-cctv",
          "fat fa-camera-movie",
          "fat fa-camera-polaroid",
          "fat fa-camera-retro",
          "fat fa-camera-rotate",
          "fat fa-camera-security",
          "fat fa-camera-slash",
          "fat fa-camera-viewfinder",
          "fat fa-camera-web",
          "fat fa-camera-web-slash",
          "fat fa-campfire",
          "fat fa-campground",
          "fat fa-can-food",
          "fat fa-candle-holder",
          "fat fa-candy",
          "fat fa-candy-bar",
          "fat fa-candy-cane",
          "fat fa-candy-corn",
          "fat fa-cannabis",
          "fat fa-capsules",
          "fat fa-car",
          "fat fa-car-battery",
          "fat fa-car-bolt",
          "fat fa-car-building",
          "fat fa-car-bump",
          "fat fa-car-burst",
          "fat fa-car-bus",
          "fat fa-car-circle-bolt",
          "fat fa-car-garage",
          "fat fa-car-mirrors",
          "fat fa-car-on",
          "fat fa-car-rear",
          "fat fa-car-side",
          "fat fa-car-side-bolt",
          "fat fa-car-tilt",
          "fat fa-car-tunnel",
          "fat fa-car-wash",
          "fat fa-car-wrench",
          "fat fa-caravan",
          "fat fa-caravan-simple",
          "fat fa-card-club",
          "fat fa-card-diamond",
          "fat fa-card-heart",
          "fat fa-card-spade",
          "fat fa-cards",
          "fat fa-cards-blank",
          "fat fa-caret-down",
          "fat fa-caret-left",
          "fat fa-caret-right",
          "fat fa-caret-up",
          "fat fa-carrot",
          "fat fa-cars",
          "fat fa-cart-arrow-down",
          "fat fa-cart-arrow-up",
          "fat fa-cart-circle-arrow-down",
          "fat fa-cart-circle-arrow-up",
          "fat fa-cart-circle-check",
          "fat fa-cart-circle-exclamation",
          "fat fa-cart-circle-plus",
          "fat fa-cart-circle-xmark",
          "fat fa-cart-flatbed",
          "fat fa-cart-flatbed-boxes",
          "fat fa-cart-flatbed-empty",
          "fat fa-cart-flatbed-suitcase",
          "fat fa-cart-minus",
          "fat fa-cart-plus",
          "fat fa-cart-shopping",
          "fat fa-cart-shopping-fast",
          "fat fa-cart-xmark",
          "fat fa-cash-register",
          "fat fa-cassette-betamax",
          "fat fa-cassette-tape",
          "fat fa-cassette-vhs",
          "fat fa-castle",
          "fat fa-cat",
          "fat fa-cat-space",
          "fat fa-cauldron",
          "fat fa-cedi-sign",
          "fat fa-cent-sign",
          "fat fa-certificate",
          "fat fa-chair",
          "fat fa-chair-office",
          "fat fa-chalkboard",
          "fat fa-chalkboard-user",
          "fat fa-champagne-glass",
          "fat fa-champagne-glasses",
          "fat fa-charging-station",
          "fat fa-chart-area",
          "fat fa-chart-bar",
          "fat fa-chart-bullet",
          "fat fa-chart-candlestick",
          "fat fa-chart-column",
          "fat fa-chart-gantt",
          "fat fa-chart-line",
          "fat fa-chart-line-down",
          "fat fa-chart-line-up",
          "fat fa-chart-mixed",
          "fat fa-chart-network",
          "fat fa-chart-pie",
          "fat fa-chart-pie-simple",
          "fat fa-chart-pyramid",
          "fat fa-chart-radar",
          "fat fa-chart-scatter",
          "fat fa-chart-scatter-3d",
          "fat fa-chart-scatter-bubble",
          "fat fa-chart-simple",
          "fat fa-chart-simple-horizontal",
          "fat fa-chart-tree-map",
          "fat fa-chart-user",
          "fat fa-chart-waterfall",
          "fat fa-check",
          "fat fa-check-double",
          "fat fa-check-to-slot",
          "fat fa-cheese",
          "fat fa-cheese-swiss",
          "fat fa-cherries",
          "fat fa-chess",
          "fat fa-chess-bishop",
          "fat fa-chess-bishop-piece",
          "fat fa-chess-board",
          "fat fa-chess-clock",
          "fat fa-chess-clock-flip",
          "fat fa-chess-king",
          "fat fa-chess-king-piece",
          "fat fa-chess-knight",
          "fat fa-chess-knight-piece",
          "fat fa-chess-pawn",
          "fat fa-chess-pawn-piece",
          "fat fa-chess-queen",
          "fat fa-chess-queen-piece",
          "fat fa-chess-rook",
          "fat fa-chess-rook-piece",
          "fat fa-chestnut",
          "fat fa-chevron-down",
          "fat fa-chevron-left",
          "fat fa-chevron-right",
          "fat fa-chevron-up",
          "fat fa-chevrons-down",
          "fat fa-chevrons-left",
          "fat fa-chevrons-right",
          "fat fa-chevrons-up",
          "fat fa-child",
          "fat fa-child-combatant",
          "fat fa-child-dress",
          "fat fa-child-reaching",
          "fat fa-children",
          "fat fa-chimney",
          "fat fa-chopsticks",
          "fat fa-church",
          "fat fa-circle",
          "fat fa-circle-0",
          "fat fa-circle-1",
          "fat fa-circle-2",
          "fat fa-circle-3",
          "fat fa-circle-4",
          "fat fa-circle-5",
          "fat fa-circle-6",
          "fat fa-circle-7",
          "fat fa-circle-8",
          "fat fa-circle-9",
          "fat fa-circle-a",
          "fat fa-circle-ampersand",
          "fat fa-circle-arrow-down",
          "fat fa-circle-arrow-down-left",
          "fat fa-circle-arrow-down-right",
          "fat fa-circle-arrow-left",
          "fat fa-circle-arrow-right",
          "fat fa-circle-arrow-up",
          "fat fa-circle-arrow-up-left",
          "fat fa-circle-arrow-up-right",
          "fat fa-circle-b",
          "fat fa-circle-bolt",
          "fat fa-circle-book-open",
          "fat fa-circle-bookmark",
          "fat fa-circle-c",
          "fat fa-circle-calendar",
          "fat fa-circle-camera",
          "fat fa-circle-caret-down",
          "fat fa-circle-caret-left",
          "fat fa-circle-caret-right",
          "fat fa-circle-caret-up",
          "fat fa-circle-check",
          "fat fa-circle-chevron-down",
          "fat fa-circle-chevron-left",
          "fat fa-circle-chevron-right",
          "fat fa-circle-chevron-up",
          "fat fa-circle-d",
          "fat fa-circle-dashed",
          "fat fa-circle-divide",
          "fat fa-circle-dollar",
          "fat fa-circle-dollar-to-slot",
          "fat fa-circle-dot",
          "fat fa-circle-down",
          "fat fa-circle-down-left",
          "fat fa-circle-down-right",
          "fat fa-circle-e",
          "fat fa-circle-ellipsis",
          "fat fa-circle-ellipsis-vertical",
          "fat fa-circle-envelope",
          "fat fa-circle-euro",
          "fat fa-circle-exclamation",
          "fat fa-circle-exclamation-check",
          "fat fa-circle-f",
          "fat fa-circle-g",
          "fat fa-circle-h",
          "fat fa-circle-half",
          "fat fa-circle-half-stroke",
          "fat fa-circle-heart",
          "fat fa-circle-i",
          "fat fa-circle-info",
          "fat fa-circle-j",
          "fat fa-circle-k",
          "fat fa-circle-l",
          "fat fa-circle-left",
          "fat fa-circle-location-arrow",
          "fat fa-circle-m",
          "fat fa-circle-microphone",
          "fat fa-circle-microphone-lines",
          "fat fa-circle-minus",
          "fat fa-circle-n",
          "fat fa-circle-nodes",
          "fat fa-circle-notch",
          "fat fa-circle-o",
          "fat fa-circle-p",
          "fat fa-circle-parking",
          "fat fa-circle-pause",
          "fat fa-circle-phone",
          "fat fa-circle-phone-flip",
          "fat fa-circle-phone-hangup",
          "fat fa-circle-play",
          "fat fa-circle-plus",
          "fat fa-circle-q",
          "fat fa-circle-quarter",
          "fat fa-circle-quarter-stroke",
          "fat fa-circle-quarters",
          "fat fa-circle-question",
          "fat fa-circle-r",
          "fat fa-circle-radiation",
          "fat fa-circle-right",
          "fat fa-circle-s",
          "fat fa-circle-small",
          "fat fa-circle-sort",
          "fat fa-circle-sort-down",
          "fat fa-circle-sort-up",
          "fat fa-circle-star",
          "fat fa-circle-sterling",
          "fat fa-circle-stop",
          "fat fa-circle-t",
          "fat fa-circle-three-quarters",
          "fat fa-circle-three-quarters-stroke",
          "fat fa-circle-trash",
          "fat fa-circle-u",
          "fat fa-circle-up",
          "fat fa-circle-up-left",
          "fat fa-circle-up-right",
          "fat fa-circle-user",
          "fat fa-circle-v",
          "fat fa-circle-video",
          "fat fa-circle-w",
          "fat fa-circle-waveform-lines",
          "fat fa-circle-x",
          "fat fa-circle-xmark",
          "fat fa-circle-y",
          "fat fa-circle-yen",
          "fat fa-circle-z",
          "fat fa-citrus",
          "fat fa-citrus-slice",
          "fat fa-city",
          "fat fa-clapperboard",
          "fat fa-clapperboard-play",
          "fat fa-clarinet",
          "fat fa-claw-marks",
          "fat fa-clipboard",
          "fat fa-clipboard-check",
          "fat fa-clipboard-list",
          "fat fa-clipboard-list-check",
          "fat fa-clipboard-medical",
          "fat fa-clipboard-prescription",
          "fat fa-clipboard-question",
          "fat fa-clipboard-user",
          "fat fa-clock",
          "fat fa-clock-desk",
          "fat fa-clock-eight",
          "fat fa-clock-eight-thirty",
          "fat fa-clock-eleven",
          "fat fa-clock-eleven-thirty",
          "fat fa-clock-five",
          "fat fa-clock-five-thirty",
          "fat fa-clock-four-thirty",
          "fat fa-clock-nine",
          "fat fa-clock-nine-thirty",
          "fat fa-clock-one",
          "fat fa-clock-one-thirty",
          "fat fa-clock-rotate-left",
          "fat fa-clock-seven",
          "fat fa-clock-seven-thirty",
          "fat fa-clock-six",
          "fat fa-clock-six-thirty",
          "fat fa-clock-ten",
          "fat fa-clock-ten-thirty",
          "fat fa-clock-three",
          "fat fa-clock-three-thirty",
          "fat fa-clock-twelve",
          "fat fa-clock-twelve-thirty",
          "fat fa-clock-two",
          "fat fa-clock-two-thirty",
          "fat fa-clone",
          "fat fa-closed-captioning",
          "fat fa-closed-captioning-slash",
          "fat fa-clothes-hanger",
          "fat fa-cloud",
          "fat fa-cloud-arrow-down",
          "fat fa-cloud-arrow-up",
          "fat fa-cloud-bolt",
          "fat fa-cloud-bolt-moon",
          "fat fa-cloud-bolt-sun",
          "fat fa-cloud-check",
          "fat fa-cloud-drizzle",
          "fat fa-cloud-exclamation",
          "fat fa-cloud-fog",
          "fat fa-cloud-hail",
          "fat fa-cloud-hail-mixed",
          "fat fa-cloud-meatball",
          "fat fa-cloud-minus",
          "fat fa-cloud-moon",
          "fat fa-cloud-moon-rain",
          "fat fa-cloud-music",
          "fat fa-cloud-plus",
          "fat fa-cloud-question",
          "fat fa-cloud-rain",
          "fat fa-cloud-rainbow",
          "fat fa-cloud-showers",
          "fat fa-cloud-showers-heavy",
          "fat fa-cloud-showers-water",
          "fat fa-cloud-slash",
          "fat fa-cloud-sleet",
          "fat fa-cloud-snow",
          "fat fa-cloud-sun",
          "fat fa-cloud-sun-rain",
          "fat fa-cloud-word",
          "fat fa-cloud-xmark",
          "fat fa-clouds",
          "fat fa-clouds-moon",
          "fat fa-clouds-sun",
          "fat fa-clover",
          "fat fa-club",
          "fat fa-coconut",
          "fat fa-code",
          "fat fa-code-branch",
          "fat fa-code-commit",
          "fat fa-code-compare",
          "fat fa-code-fork",
          "fat fa-code-merge",
          "fat fa-code-pull-request",
          "fat fa-code-pull-request-closed",
          "fat fa-code-pull-request-draft",
          "fat fa-code-simple",
          "fat fa-coffee-bean",
          "fat fa-coffee-beans",
          "fat fa-coffee-pot",
          "fat fa-coffin",
          "fat fa-coffin-cross",
          "fat fa-coin",
          "fat fa-coin-blank",
          "fat fa-coin-front",
          "fat fa-coin-vertical",
          "fat fa-coins",
          "fat fa-colon",
          "fat fa-colon-sign",
          "fat fa-columns-3",
          "fat fa-comet",
          "fat fa-comma",
          "fat fa-command",
          "fat fa-comment",
          "fat fa-comment-arrow-down",
          "fat fa-comment-arrow-up",
          "fat fa-comment-arrow-up-right",
          "fat fa-comment-captions",
          "fat fa-comment-check",
          "fat fa-comment-code",
          "fat fa-comment-dollar",
          "fat fa-comment-dots",
          "fat fa-comment-exclamation",
          "fat fa-comment-heart",
          "fat fa-comment-image",
          "fat fa-comment-lines",
          "fat fa-comment-medical",
          "fat fa-comment-middle",
          "fat fa-comment-middle-top",
          "fat fa-comment-minus",
          "fat fa-comment-music",
          "fat fa-comment-pen",
          "fat fa-comment-plus",
          "fat fa-comment-question",
          "fat fa-comment-quote",
          "fat fa-comment-slash",
          "fat fa-comment-smile",
          "fat fa-comment-sms",
          "fat fa-comment-text",
          "fat fa-comment-xmark",
          "fat fa-comments",
          "fat fa-comments-dollar",
          "fat fa-comments-question",
          "fat fa-comments-question-check",
          "fat fa-compact-disc",
          "fat fa-compass",
          "fat fa-compass-drafting",
          "fat fa-compass-slash",
          "fat fa-compress",
          "fat fa-compress-wide",
          "fat fa-computer",
          "fat fa-computer-classic",
          "fat fa-computer-mouse",
          "fat fa-computer-mouse-scrollwheel",
          "fat fa-computer-speaker",
          "fat fa-container-storage",
          "fat fa-conveyor-belt",
          "fat fa-conveyor-belt-boxes",
          "fat fa-conveyor-belt-empty",
          "fat fa-cookie",
          "fat fa-cookie-bite",
          "fat fa-copy",
          "fat fa-copyright",
          "fat fa-corn",
          "fat fa-corner",
          "fat fa-couch",
          "fat fa-cow",
          "fat fa-cowbell",
          "fat fa-cowbell-circle-plus",
          "fat fa-crab",
          "fat fa-crate-apple",
          "fat fa-crate-empty",
          "fat fa-credit-card",
          "fat fa-credit-card-blank",
          "fat fa-credit-card-front",
          "fat fa-cricket-bat-ball",
          "fat fa-croissant",
          "fat fa-crop",
          "fat fa-crop-simple",
          "fat fa-cross",
          "fat fa-crosshairs",
          "fat fa-crosshairs-simple",
          "fat fa-crow",
          "fat fa-crown",
          "fat fa-crutch",
          "fat fa-crutches",
          "fat fa-cruzeiro-sign",
          "fat fa-crystal-ball",
          "fat fa-cube",
          "fat fa-cubes",
          "fat fa-cubes-stacked",
          "fat fa-cucumber",
          "fat fa-cup-straw",
          "fat fa-cup-straw-swoosh",
          "fat fa-cup-togo",
          "fat fa-cupcake",
          "fat fa-curling-stone",
          "fat fa-custard",
          "fat fa-d",
          "fat fa-dagger",
          "fat fa-dash",
          "fat fa-database",
          "fat fa-deer",
          "fat fa-deer-rudolph",
          "fat fa-delete-left",
          "fat fa-delete-right",
          "fat fa-democrat",
          "fat fa-desktop",
          "fat fa-desktop-arrow-down",
          "fat fa-dharmachakra",
          "fat fa-diagram-cells",
          "fat fa-diagram-lean-canvas",
          "fat fa-diagram-nested",
          "fat fa-diagram-next",
          "fat fa-diagram-predecessor",
          "fat fa-diagram-previous",
          "fat fa-diagram-project",
          "fat fa-diagram-sankey",
          "fat fa-diagram-subtask",
          "fat fa-diagram-successor",
          "fat fa-diagram-venn",
          "fat fa-dial",
          "fat fa-dial-high",
          "fat fa-dial-low",
          "fat fa-dial-max",
          "fat fa-dial-med",
          "fat fa-dial-med-low",
          "fat fa-dial-min",
          "fat fa-dial-off",
          "fat fa-diamond",
          "fat fa-diamond-exclamation",
          "fat fa-diamond-half",
          "fat fa-diamond-half-stroke",
          "fat fa-diamond-turn-right",
          "fat fa-dice",
          "fat fa-dice-d10",
          "fat fa-dice-d12",
          "fat fa-dice-d20",
          "fat fa-dice-d4",
          "fat fa-dice-d6",
          "fat fa-dice-d8",
          "fat fa-dice-five",
          "fat fa-dice-four",
          "fat fa-dice-one",
          "fat fa-dice-six",
          "fat fa-dice-three",
          "fat fa-dice-two",
          "fat fa-diploma",
          "fat fa-disc-drive",
          "fat fa-disease",
          "fat fa-display",
          "fat fa-display-arrow-down",
          "fat fa-display-code",
          "fat fa-display-medical",
          "fat fa-display-slash",
          "fat fa-distribute-spacing-horizontal",
          "fat fa-distribute-spacing-vertical",
          "fat fa-ditto",
          "fat fa-divide",
          "fat fa-dna",
          "fat fa-do-not-enter",
          "fat fa-dog",
          "fat fa-dog-leashed",
          "fat fa-dollar-sign",
          "fat fa-dolly",
          "fat fa-dolly-empty",
          "fat fa-dolphin",
          "fat fa-dong-sign",
          "fat fa-donut",
          "fat fa-door-closed",
          "fat fa-door-open",
          "fat fa-dove",
          "fat fa-down",
          "fat fa-down-from-dotted-line",
          "fat fa-down-from-line",
          "fat fa-down-left",
          "fat fa-down-left-and-up-right-to-center",
          "fat fa-down-long",
          "fat fa-down-right",
          "fat fa-down-to-bracket",
          "fat fa-down-to-dotted-line",
          "fat fa-down-to-line",
          "fat fa-download",
          "fat fa-dragon",
          "fat fa-draw-circle",
          "fat fa-draw-polygon",
          "fat fa-draw-square",
          "fat fa-dreidel",
          "fat fa-drone",
          "fat fa-drone-front",
          "fat fa-droplet",
          "fat fa-droplet-degree",
          "fat fa-droplet-percent",
          "fat fa-droplet-slash",
          "fat fa-drum",
          "fat fa-drum-steelpan",
          "fat fa-drumstick",
          "fat fa-drumstick-bite",
          "fat fa-dryer",
          "fat fa-dryer-heat",
          "fat fa-duck",
          "fat fa-dumbbell",
          "fat fa-dumpster",
          "fat fa-dumpster-fire",
          "fat fa-dungeon",
          "fat fa-e",
          "fat fa-ear",
          "fat fa-ear-deaf",
          "fat fa-ear-listen",
          "fat fa-ear-muffs",
          "fat fa-earth-africa",
          "fat fa-earth-americas",
          "fat fa-earth-asia",
          "fat fa-earth-europe",
          "fat fa-earth-oceania",
          "fat fa-eclipse",
          "fat fa-egg",
          "fat fa-egg-fried",
          "fat fa-eggplant",
          "fat fa-eject",
          "fat fa-elephant",
          "fat fa-elevator",
          "fat fa-ellipsis",
          "fat fa-ellipsis-stroke",
          "fat fa-ellipsis-stroke-vertical",
          "fat fa-ellipsis-vertical",
          "fat fa-empty-set",
          "fat fa-engine",
          "fat fa-engine-warning",
          "fat fa-envelope",
          "fat fa-envelope-circle-check",
          "fat fa-envelope-dot",
          "fat fa-envelope-open",
          "fat fa-envelope-open-dollar",
          "fat fa-envelope-open-text",
          "fat fa-envelopes",
          "fat fa-envelopes-bulk",
          "fat fa-equals",
          "fat fa-eraser",
          "fat fa-escalator",
          "fat fa-ethernet",
          "fat fa-euro-sign",
          "fat fa-exclamation",
          "fat fa-expand",
          "fat fa-expand-wide",
          "fat fa-explosion",
          "fat fa-eye",
          "fat fa-eye-dropper",
          "fat fa-eye-dropper-full",
          "fat fa-eye-dropper-half",
          "fat fa-eye-evil",
          "fat fa-eye-low-vision",
          "fat fa-eye-slash",
          "fat fa-eyes",
          "fat fa-f",
          "fat fa-face-angry",
          "fat fa-face-angry-horns",
          "fat fa-face-anguished",
          "fat fa-face-anxious-sweat",
          "fat fa-face-astonished",
          "fat fa-face-awesome",
          "fat fa-face-beam-hand-over-mouth",
          "fat fa-face-clouds",
          "fat fa-face-confounded",
          "fat fa-face-confused",
          "fat fa-face-cowboy-hat",
          "fat fa-face-diagonal-mouth",
          "fat fa-face-disappointed",
          "fat fa-face-disguise",
          "fat fa-face-dizzy",
          "fat fa-face-dotted",
          "fat fa-face-downcast-sweat",
          "fat fa-face-drooling",
          "fat fa-face-exhaling",
          "fat fa-face-explode",
          "fat fa-face-expressionless",
          "fat fa-face-eyes-xmarks",
          "fat fa-face-fearful",
          "fat fa-face-flushed",
          "fat fa-face-frown",
          "fat fa-face-frown-open",
          "fat fa-face-frown-slight",
          "fat fa-face-glasses",
          "fat fa-face-grimace",
          "fat fa-face-grin",
          "fat fa-face-grin-beam",
          "fat fa-face-grin-beam-sweat",
          "fat fa-face-grin-hearts",
          "fat fa-face-grin-squint",
          "fat fa-face-grin-squint-tears",
          "fat fa-face-grin-stars",
          "fat fa-face-grin-tears",
          "fat fa-face-grin-tongue",
          "fat fa-face-grin-tongue-squint",
          "fat fa-face-grin-tongue-wink",
          "fat fa-face-grin-wide",
          "fat fa-face-grin-wink",
          "fat fa-face-hand-over-mouth",
          "fat fa-face-hand-peeking",
          "fat fa-face-hand-yawn",
          "fat fa-face-head-bandage",
          "fat fa-face-holding-back-tears",
          "fat fa-face-hushed",
          "fat fa-face-icicles",
          "fat fa-face-kiss",
          "fat fa-face-kiss-beam",
          "fat fa-face-kiss-closed-eyes",
          "fat fa-face-kiss-wink-heart",
          "fat fa-face-laugh",
          "fat fa-face-laugh-beam",
          "fat fa-face-laugh-squint",
          "fat fa-face-laugh-wink",
          "fat fa-face-lying",
          "fat fa-face-mask",
          "fat fa-face-meh",
          "fat fa-face-meh-blank",
          "fat fa-face-melting",
          "fat fa-face-monocle",
          "fat fa-face-nauseated",
          "fat fa-face-nose-steam",
          "fat fa-face-party",
          "fat fa-face-pensive",
          "fat fa-face-persevering",
          "fat fa-face-pleading",
          "fat fa-face-pouting",
          "fat fa-face-raised-eyebrow",
          "fat fa-face-relieved",
          "fat fa-face-rolling-eyes",
          "fat fa-face-sad-cry",
          "fat fa-face-sad-sweat",
          "fat fa-face-sad-tear",
          "fat fa-face-saluting",
          "fat fa-face-scream",
          "fat fa-face-shush",
          "fat fa-face-sleeping",
          "fat fa-face-sleepy",
          "fat fa-face-smile",
          "fat fa-face-smile-beam",
          "fat fa-face-smile-halo",
          "fat fa-face-smile-hearts",
          "fat fa-face-smile-horns",
          "fat fa-face-smile-plus",
          "fat fa-face-smile-relaxed",
          "fat fa-face-smile-tear",
          "fat fa-face-smile-tongue",
          "fat fa-face-smile-upside-down",
          "fat fa-face-smile-wink",
          "fat fa-face-smiling-hands",
          "fat fa-face-smirking",
          "fat fa-face-spiral-eyes",
          "fat fa-face-sunglasses",
          "fat fa-face-surprise",
          "fat fa-face-swear",
          "fat fa-face-thermometer",
          "fat fa-face-thinking",
          "fat fa-face-tired",
          "fat fa-face-tissue",
          "fat fa-face-tongue-money",
          "fat fa-face-tongue-sweat",
          "fat fa-face-unamused",
          "fat fa-face-viewfinder",
          "fat fa-face-vomit",
          "fat fa-face-weary",
          "fat fa-face-woozy",
          "fat fa-face-worried",
          "fat fa-face-zany",
          "fat fa-face-zipper",
          "fat fa-falafel",
          "fat fa-family",
          "fat fa-family-dress",
          "fat fa-family-pants",
          "fat fa-fan",
          "fat fa-fan-table",
          "fat fa-farm",
          "fat fa-faucet",
          "fat fa-faucet-drip",
          "fat fa-fax",
          "fat fa-feather",
          "fat fa-feather-pointed",
          "fat fa-fence",
          "fat fa-ferris-wheel",
          "fat fa-ferry",
          "fat fa-field-hockey-stick-ball",
          "fat fa-file",
          "fat fa-file-arrow-down",
          "fat fa-file-arrow-up",
          "fat fa-file-audio",
          "fat fa-file-binary",
          "fat fa-file-certificate",
          "fat fa-file-chart-column",
          "fat fa-file-chart-pie",
          "fat fa-file-check",
          "fat fa-file-circle-check",
          "fat fa-file-circle-exclamation",
          "fat fa-file-circle-info",
          "fat fa-file-circle-minus",
          "fat fa-file-circle-plus",
          "fat fa-file-circle-question",
          "fat fa-file-circle-xmark",
          "fat fa-file-code",
          "fat fa-file-contract",
          "fat fa-file-csv",
          "fat fa-file-dashed-line",
          "fat fa-file-excel",
          "fat fa-file-exclamation",
          "fat fa-file-export",
          "fat fa-file-heart",
          "fat fa-file-image",
          "fat fa-file-import",
          "fat fa-file-invoice",
          "fat fa-file-invoice-dollar",
          "fat fa-file-lines",
          "fat fa-file-lock",
          "fat fa-file-magnifying-glass",
          "fat fa-file-medical",
          "fat fa-file-minus",
          "fat fa-file-music",
          "fat fa-file-pdf",
          "fat fa-file-pen",
          "fat fa-file-plus",
          "fat fa-file-plus-minus",
          "fat fa-file-powerpoint",
          "fat fa-file-prescription",
          "fat fa-file-shield",
          "fat fa-file-signature",
          "fat fa-file-slash",
          "fat fa-file-spreadsheet",
          "fat fa-file-user",
          "fat fa-file-video",
          "fat fa-file-waveform",
          "fat fa-file-word",
          "fat fa-file-xmark",
          "fat fa-file-zipper",
          "fat fa-files",
          "fat fa-files-medical",
          "fat fa-fill",
          "fat fa-fill-drip",
          "fat fa-film",
          "fat fa-film-canister",
          "fat fa-film-simple",
          "fat fa-film-slash",
          "fat fa-films",
          "fat fa-filter",
          "fat fa-filter-circle-dollar",
          "fat fa-filter-circle-xmark",
          "fat fa-filter-list",
          "fat fa-filter-slash",
          "fat fa-filters",
          "fat fa-fingerprint",
          "fat fa-fire",
          "fat fa-fire-burner",
          "fat fa-fire-extinguisher",
          "fat fa-fire-flame",
          "fat fa-fire-flame-curved",
          "fat fa-fire-flame-simple",
          "fat fa-fire-hydrant",
          "fat fa-fire-smoke",
          "fat fa-fireplace",
          "fat fa-fish",
          "fat fa-fish-bones",
          "fat fa-fish-cooked",
          "fat fa-fish-fins",
          "fat fa-fishing-rod",
          "fat fa-flag",
          "fat fa-flag-checkered",
          "fat fa-flag-pennant",
          "fat fa-flag-swallowtail",
          "fat fa-flag-usa",
          "fat fa-flashlight",
          "fat fa-flask",
          "fat fa-flask-round-poison",
          "fat fa-flask-round-potion",
          "fat fa-flask-vial",
          "fat fa-flatbread",
          "fat fa-flatbread-stuffed",
          "fat fa-floppy-disk",
          "fat fa-floppy-disk-circle-arrow-right",
          "fat fa-floppy-disk-circle-xmark",
          "fat fa-floppy-disk-pen",
          "fat fa-floppy-disks",
          "fat fa-florin-sign",
          "fat fa-flower",
          "fat fa-flower-daffodil",
          "fat fa-flower-tulip",
          "fat fa-flute",
          "fat fa-flux-capacitor",
          "fat fa-flying-disc",
          "fat fa-folder",
          "fat fa-folder-arrow-down",
          "fat fa-folder-arrow-up",
          "fat fa-folder-bookmark",
          "fat fa-folder-closed",
          "fat fa-folder-gear",
          "fat fa-folder-grid",
          "fat fa-folder-heart",
          "fat fa-folder-image",
          "fat fa-folder-magnifying-glass",
          "fat fa-folder-medical",
          "fat fa-folder-minus",
          "fat fa-folder-music",
          "fat fa-folder-open",
          "fat fa-folder-plus",
          "fat fa-folder-tree",
          "fat fa-folder-user",
          "fat fa-folder-xmark",
          "fat fa-folders",
          "fat fa-fondue-pot",
          "fat fa-font",
          "fat fa-font-awesome",
          "fat fa-font-case",
          "fat fa-football",
          "fat fa-football-helmet",
          "fat fa-fork",
          "fat fa-fork-knife",
          "fat fa-forklift",
          "fat fa-fort",
          "fat fa-forward",
          "fat fa-forward-fast",
          "fat fa-forward-step",
          "fat fa-frame",
          "fat fa-franc-sign",
          "fat fa-french-fries",
          "fat fa-frog",
          "fat fa-function",
          "fat fa-futbol",
          "fat fa-g",
          "fat fa-galaxy",
          "fat fa-gallery-thumbnails",
          "fat fa-game-board",
          "fat fa-game-board-simple",
          "fat fa-game-console-handheld",
          "fat fa-game-console-handheld-crank",
          "fat fa-gamepad",
          "fat fa-gamepad-modern",
          "fat fa-garage",
          "fat fa-garage-car",
          "fat fa-garage-open",
          "fat fa-garlic",
          "fat fa-gas-pump",
          "fat fa-gas-pump-slash",
          "fat fa-gauge",
          "fat fa-gauge-circle-bolt",
          "fat fa-gauge-circle-minus",
          "fat fa-gauge-circle-plus",
          "fat fa-gauge-high",
          "fat fa-gauge-low",
          "fat fa-gauge-max",
          "fat fa-gauge-min",
          "fat fa-gauge-simple",
          "fat fa-gauge-simple-high",
          "fat fa-gauge-simple-low",
          "fat fa-gauge-simple-max",
          "fat fa-gauge-simple-min",
          "fat fa-gavel",
          "fat fa-gear",
          "fat fa-gears",
          "fat fa-gem",
          "fat fa-genderless",
          "fat fa-ghost",
          "fat fa-gif",
          "fat fa-gift",
          "fat fa-gift-card",
          "fat fa-gifts",
          "fat fa-gingerbread-man",
          "fat fa-glass",
          "fat fa-glass-citrus",
          "fat fa-glass-empty",
          "fat fa-glass-half",
          "fat fa-glass-water",
          "fat fa-glass-water-droplet",
          "fat fa-glasses",
          "fat fa-glasses-round",
          "fat fa-globe",
          "fat fa-globe-snow",
          "fat fa-globe-stand",
          "fat fa-goal-net",
          "fat fa-golf-ball-tee",
          "fat fa-golf-club",
          "fat fa-golf-flag-hole",
          "fat fa-gopuram",
          "fat fa-graduation-cap",
          "fat fa-gramophone",
          "fat fa-grapes",
          "fat fa-grate",
          "fat fa-grate-droplet",
          "fat fa-greater-than",
          "fat fa-greater-than-equal",
          "fat fa-grid",
          "fat fa-grid-2",
          "fat fa-grid-2-plus",
          "fat fa-grid-4",
          "fat fa-grid-5",
          "fat fa-grid-dividers",
          "fat fa-grid-horizontal",
          "fat fa-grill",
          "fat fa-grill-fire",
          "fat fa-grill-hot",
          "fat fa-grip",
          "fat fa-grip-dots",
          "fat fa-grip-dots-vertical",
          "fat fa-grip-lines",
          "fat fa-grip-lines-vertical",
          "fat fa-grip-vertical",
          "fat fa-group-arrows-rotate",
          "fat fa-guarani-sign",
          "fat fa-guitar",
          "fat fa-guitar-electric",
          "fat fa-guitars",
          "fat fa-gun",
          "fat fa-gun-slash",
          "fat fa-gun-squirt",
          "fat fa-h",
          "fat fa-h1",
          "fat fa-h2",
          "fat fa-h3",
          "fat fa-h4",
          "fat fa-h5",
          "fat fa-h6",
          "fat fa-hammer",
          "fat fa-hammer-crash",
          "fat fa-hammer-war",
          "fat fa-hamsa",
          "fat fa-hand",
          "fat fa-hand-back-fist",
          "fat fa-hand-back-point-down",
          "fat fa-hand-back-point-left",
          "fat fa-hand-back-point-ribbon",
          "fat fa-hand-back-point-right",
          "fat fa-hand-back-point-up",
          "fat fa-hand-dots",
          "fat fa-hand-fingers-crossed",
          "fat fa-hand-fist",
          "fat fa-hand-heart",
          "fat fa-hand-holding",
          "fat fa-hand-holding-box",
          "fat fa-hand-holding-dollar",
          "fat fa-hand-holding-droplet",
          "fat fa-hand-holding-hand",
          "fat fa-hand-holding-heart",
          "fat fa-hand-holding-magic",
          "fat fa-hand-holding-medical",
          "fat fa-hand-holding-seedling",
          "fat fa-hand-holding-skull",
          "fat fa-hand-horns",
          "fat fa-hand-lizard",
          "fat fa-hand-love",
          "fat fa-hand-middle-finger",
          "fat fa-hand-peace",
          "fat fa-hand-point-down",
          "fat fa-hand-point-left",
          "fat fa-hand-point-ribbon",
          "fat fa-hand-point-right",
          "fat fa-hand-point-up",
          "fat fa-hand-pointer",
          "fat fa-hand-scissors",
          "fat fa-hand-sparkles",
          "fat fa-hand-spock",
          "fat fa-hand-wave",
          "fat fa-handcuffs",
          "fat fa-hands",
          "fat fa-hands-asl-interpreting",
          "fat fa-hands-bound",
          "fat fa-hands-bubbles",
          "fat fa-hands-clapping",
          "fat fa-hands-holding",
          "fat fa-hands-holding-child",
          "fat fa-hands-holding-circle",
          "fat fa-hands-holding-diamond",
          "fat fa-hands-holding-dollar",
          "fat fa-hands-holding-heart",
          "fat fa-hands-praying",
          "fat fa-handshake",
          "fat fa-handshake-angle",
          "fat fa-handshake-simple",
          "fat fa-handshake-simple-slash",
          "fat fa-handshake-slash",
          "fat fa-hanukiah",
          "fat fa-hard-drive",
          "fat fa-hashtag",
          "fat fa-hashtag-lock",
          "fat fa-hat-chef",
          "fat fa-hat-cowboy",
          "fat fa-hat-cowboy-side",
          "fat fa-hat-santa",
          "fat fa-hat-winter",
          "fat fa-hat-witch",
          "fat fa-hat-wizard",
          "fat fa-head-side",
          "fat fa-head-side-brain",
          "fat fa-head-side-cough",
          "fat fa-head-side-cough-slash",
          "fat fa-head-side-goggles",
          "fat fa-head-side-headphones",
          "fat fa-head-side-heart",
          "fat fa-head-side-mask",
          "fat fa-head-side-medical",
          "fat fa-head-side-virus",
          "fat fa-heading",
          "fat fa-headphones",
          "fat fa-headphones-simple",
          "fat fa-headset",
          "fat fa-heart",
          "fat fa-heart-circle-bolt",
          "fat fa-heart-circle-check",
          "fat fa-heart-circle-exclamation",
          "fat fa-heart-circle-minus",
          "fat fa-heart-circle-plus",
          "fat fa-heart-circle-xmark",
          "fat fa-heart-crack",
          "fat fa-heart-half",
          "fat fa-heart-half-stroke",
          "fat fa-heart-pulse",
          "fat fa-heat",
          "fat fa-helicopter",
          "fat fa-helicopter-symbol",
          "fat fa-helmet-battle",
          "fat fa-helmet-safety",
          "fat fa-helmet-un",
          "fat fa-hexagon",
          "fat fa-hexagon-check",
          "fat fa-hexagon-divide",
          "fat fa-hexagon-exclamation",
          "fat fa-hexagon-image",
          "fat fa-hexagon-minus",
          "fat fa-hexagon-plus",
          "fat fa-hexagon-vertical-nft",
          "fat fa-hexagon-vertical-nft-slanted",
          "fat fa-hexagon-xmark",
          "fat fa-high-definition",
          "fat fa-highlighter",
          "fat fa-highlighter-line",
          "fat fa-hill-avalanche",
          "fat fa-hill-rockslide",
          "fat fa-hippo",
          "fat fa-hockey-mask",
          "fat fa-hockey-puck",
          "fat fa-hockey-stick-puck",
          "fat fa-hockey-sticks",
          "fat fa-holly-berry",
          "fat fa-honey-pot",
          "fat fa-hood-cloak",
          "fat fa-horizontal-rule",
          "fat fa-horse",
          "fat fa-horse-head",
          "fat fa-horse-saddle",
          "fat fa-hose",
          "fat fa-hose-reel",
          "fat fa-hospital",
          "fat fa-hospital-user",
          "fat fa-hospitals",
          "fat fa-hot-tub-person",
          "fat fa-hotdog",
          "fat fa-hotel",
          "fat fa-hourglass",
          "fat fa-hourglass-clock",
          "fat fa-hourglass-end",
          "fat fa-hourglass-half",
          "fat fa-hourglass-start",
          "fat fa-house",
          "fat fa-house-blank",
          "fat fa-house-building",
          "fat fa-house-chimney",
          "fat fa-house-chimney-blank",
          "fat fa-house-chimney-crack",
          "fat fa-house-chimney-heart",
          "fat fa-house-chimney-medical",
          "fat fa-house-chimney-user",
          "fat fa-house-chimney-window",
          "fat fa-house-circle-check",
          "fat fa-house-circle-exclamation",
          "fat fa-house-circle-xmark",
          "fat fa-house-crack",
          "fat fa-house-day",
          "fat fa-house-fire",
          "fat fa-house-flag",
          "fat fa-house-flood-water",
          "fat fa-house-flood-water-circle-arrow-right",
          "fat fa-house-heart",
          "fat fa-house-laptop",
          "fat fa-house-lock",
          "fat fa-house-medical",
          "fat fa-house-medical-circle-check",
          "fat fa-house-medical-circle-exclamation",
          "fat fa-house-medical-circle-xmark",
          "fat fa-house-medical-flag",
          "fat fa-house-night",
          "fat fa-house-person-leave",
          "fat fa-house-person-return",
          "fat fa-house-signal",
          "fat fa-house-tree",
          "fat fa-house-tsunami",
          "fat fa-house-turret",
          "fat fa-house-user",
          "fat fa-house-water",
          "fat fa-house-window",
          "fat fa-hryvnia-sign",
          "fat fa-hundred-points",
          "fat fa-hurricane",
          "fat fa-hyphen",
          "fat fa-i",
          "fat fa-i-cursor",
          "fat fa-ice-cream",
          "fat fa-ice-skate",
          "fat fa-icicles",
          "fat fa-icons",
          "fat fa-id-badge",
          "fat fa-id-card",
          "fat fa-id-card-clip",
          "fat fa-igloo",
          "fat fa-image",
          "fat fa-image-landscape",
          "fat fa-image-polaroid",
          "fat fa-image-polaroid-user",
          "fat fa-image-portrait",
          "fat fa-image-slash",
          "fat fa-image-user",
          "fat fa-images",
          "fat fa-images-user",
          "fat fa-inbox",
          "fat fa-inbox-full",
          "fat fa-inbox-in",
          "fat fa-inbox-out",
          "fat fa-inboxes",
          "fat fa-indent",
          "fat fa-indian-rupee-sign",
          "fat fa-industry",
          "fat fa-industry-windows",
          "fat fa-infinity",
          "fat fa-info",
          "fat fa-inhaler",
          "fat fa-input-numeric",
          "fat fa-input-pipe",
          "fat fa-input-text",
          "fat fa-integral",
          "fat fa-interrobang",
          "fat fa-intersection",
          "fat fa-island-tropical",
          "fat fa-italic",
          "fat fa-j",
          "fat fa-jack-o-lantern",
          "fat fa-jar",
          "fat fa-jar-wheat",
          "fat fa-jedi",
          "fat fa-jet-fighter",
          "fat fa-jet-fighter-up",
          "fat fa-joint",
          "fat fa-joystick",
          "fat fa-jug",
          "fat fa-jug-detergent",
          "fat fa-k",
          "fat fa-kaaba",
          "fat fa-kazoo",
          "fat fa-kerning",
          "fat fa-key",
          "fat fa-key-skeleton",
          "fat fa-key-skeleton-left-right",
          "fat fa-keyboard",
          "fat fa-keyboard-brightness",
          "fat fa-keyboard-brightness-low",
          "fat fa-keyboard-down",
          "fat fa-keyboard-left",
          "fat fa-keynote",
          "fat fa-khanda",
          "fat fa-kidneys",
          "fat fa-kip-sign",
          "fat fa-kit-medical",
          "fat fa-kitchen-set",
          "fat fa-kite",
          "fat fa-kiwi-bird",
          "fat fa-kiwi-fruit",
          "fat fa-knife",
          "fat fa-knife-kitchen",
          "fat fa-l",
          "fat fa-lacrosse-stick",
          "fat fa-lacrosse-stick-ball",
          "fat fa-lambda",
          "fat fa-lamp",
          "fat fa-lamp-desk",
          "fat fa-lamp-floor",
          "fat fa-lamp-street",
          "fat fa-land-mine-on",
          "fat fa-landmark",
          "fat fa-landmark-dome",
          "fat fa-landmark-flag",
          "fat fa-language",
          "fat fa-laptop",
          "fat fa-laptop-arrow-down",
          "fat fa-laptop-code",
          "fat fa-laptop-file",
          "fat fa-laptop-medical",
          "fat fa-laptop-mobile",
          "fat fa-laptop-slash",
          "fat fa-lari-sign",
          "fat fa-lasso",
          "fat fa-lasso-sparkles",
          "fat fa-layer-group",
          "fat fa-layer-minus",
          "fat fa-layer-plus",
          "fat fa-leaf",
          "fat fa-leaf-heart",
          "fat fa-leaf-maple",
          "fat fa-leaf-oak",
          "fat fa-leafy-green",
          "fat fa-left",
          "fat fa-left-from-line",
          "fat fa-left-long",
          "fat fa-left-long-to-line",
          "fat fa-left-right",
          "fat fa-left-to-line",
          "fat fa-lemon",
          "fat fa-less-than",
          "fat fa-less-than-equal",
          "fat fa-life-ring",
          "fat fa-light-ceiling",
          "fat fa-light-emergency",
          "fat fa-light-emergency-on",
          "fat fa-light-switch",
          "fat fa-light-switch-off",
          "fat fa-light-switch-on",
          "fat fa-lightbulb",
          "fat fa-lightbulb-cfl",
          "fat fa-lightbulb-cfl-on",
          "fat fa-lightbulb-dollar",
          "fat fa-lightbulb-exclamation",
          "fat fa-lightbulb-exclamation-on",
          "fat fa-lightbulb-on",
          "fat fa-lightbulb-slash",
          "fat fa-lights-holiday",
          "fat fa-line-columns",
          "fat fa-line-height",
          "fat fa-lines-leaning",
          "fat fa-link",
          "fat fa-link-horizontal",
          "fat fa-link-horizontal-slash",
          "fat fa-link-simple",
          "fat fa-link-simple-slash",
          "fat fa-link-slash",
          "fat fa-lips",
          "fat fa-lira-sign",
          "fat fa-list",
          "fat fa-list-check",
          "fat fa-list-dropdown",
          "fat fa-list-music",
          "fat fa-list-ol",
          "fat fa-list-radio",
          "fat fa-list-timeline",
          "fat fa-list-tree",
          "fat fa-list-ul",
          "fat fa-litecoin-sign",
          "fat fa-loader",
          "fat fa-lobster",
          "fat fa-location-arrow",
          "fat fa-location-check",
          "fat fa-location-crosshairs",
          "fat fa-location-crosshairs-slash",
          "fat fa-location-dot",
          "fat fa-location-dot-slash",
          "fat fa-location-exclamation",
          "fat fa-location-minus",
          "fat fa-location-pen",
          "fat fa-location-pin",
          "fat fa-location-pin-lock",
          "fat fa-location-pin-slash",
          "fat fa-location-plus",
          "fat fa-location-question",
          "fat fa-location-smile",
          "fat fa-location-xmark",
          "fat fa-lock",
          "fat fa-lock-a",
          "fat fa-lock-hashtag",
          "fat fa-lock-keyhole",
          "fat fa-lock-keyhole-open",
          "fat fa-lock-open",
          "fat fa-locust",
          "fat fa-lollipop",
          "fat fa-loveseat",
          "fat fa-luchador-mask",
          "fat fa-lungs",
          "fat fa-lungs-virus",
          "fat fa-m",
          "fat fa-mace",
          "fat fa-magnet",
          "fat fa-magnifying-glass",
          "fat fa-magnifying-glass-arrow-right",
          "fat fa-magnifying-glass-chart",
          "fat fa-magnifying-glass-dollar",
          "fat fa-magnifying-glass-location",
          "fat fa-magnifying-glass-minus",
          "fat fa-magnifying-glass-plus",
          "fat fa-mailbox",
          "fat fa-mailbox-flag-up",
          "fat fa-manat-sign",
          "fat fa-mandolin",
          "fat fa-mango",
          "fat fa-manhole",
          "fat fa-map",
          "fat fa-map-location",
          "fat fa-map-location-dot",
          "fat fa-map-pin",
          "fat fa-marker",
          "fat fa-mars",
          "fat fa-mars-and-venus",
          "fat fa-mars-and-venus-burst",
          "fat fa-mars-double",
          "fat fa-mars-stroke",
          "fat fa-mars-stroke-right",
          "fat fa-mars-stroke-up",
          "fat fa-martini-glass",
          "fat fa-martini-glass-citrus",
          "fat fa-martini-glass-empty",
          "fat fa-mask",
          "fat fa-mask-face",
          "fat fa-mask-snorkel",
          "fat fa-mask-ventilator",
          "fat fa-masks-theater",
          "fat fa-mattress-pillow",
          "fat fa-maximize",
          "fat fa-meat",
          "fat fa-medal",
          "fat fa-megaphone",
          "fat fa-melon",
          "fat fa-melon-slice",
          "fat fa-memo",
          "fat fa-memo-circle-check",
          "fat fa-memo-circle-info",
          "fat fa-memo-pad",
          "fat fa-memory",
          "fat fa-menorah",
          "fat fa-mercury",
          "fat fa-merge",
          "fat fa-message",
          "fat fa-message-arrow-down",
          "fat fa-message-arrow-up",
          "fat fa-message-arrow-up-right",
          "fat fa-message-bot",
          "fat fa-message-captions",
          "fat fa-message-check",
          "fat fa-message-code",
          "fat fa-message-dollar",
          "fat fa-message-dots",
          "fat fa-message-exclamation",
          "fat fa-message-heart",
          "fat fa-message-image",
          "fat fa-message-lines",
          "fat fa-message-medical",
          "fat fa-message-middle",
          "fat fa-message-middle-top",
          "fat fa-message-minus",
          "fat fa-message-music",
          "fat fa-message-pen",
          "fat fa-message-plus",
          "fat fa-message-question",
          "fat fa-message-quote",
          "fat fa-message-slash",
          "fat fa-message-smile",
          "fat fa-message-sms",
          "fat fa-message-text",
          "fat fa-message-xmark",
          "fat fa-messages",
          "fat fa-messages-dollar",
          "fat fa-messages-question",
          "fat fa-meteor",
          "fat fa-meter",
          "fat fa-meter-bolt",
          "fat fa-meter-droplet",
          "fat fa-meter-fire",
          "fat fa-microchip",
          "fat fa-microchip-ai",
          "fat fa-microphone",
          "fat fa-microphone-lines",
          "fat fa-microphone-lines-slash",
          "fat fa-microphone-slash",
          "fat fa-microphone-stand",
          "fat fa-microscope",
          "fat fa-microwave",
          "fat fa-mill-sign",
          "fat fa-minimize",
          "fat fa-minus",
          "fat fa-mistletoe",
          "fat fa-mitten",
          "fat fa-mobile",
          "fat fa-mobile-button",
          "fat fa-mobile-notch",
          "fat fa-mobile-retro",
          "fat fa-mobile-screen",
          "fat fa-mobile-screen-button",
          "fat fa-mobile-signal",
          "fat fa-mobile-signal-out",
          "fat fa-money-bill",
          "fat fa-money-bill-1",
          "fat fa-money-bill-1-wave",
          "fat fa-money-bill-simple",
          "fat fa-money-bill-simple-wave",
          "fat fa-money-bill-transfer",
          "fat fa-money-bill-trend-up",
          "fat fa-money-bill-wave",
          "fat fa-money-bill-wheat",
          "fat fa-money-bills",
          "fat fa-money-bills-simple",
          "fat fa-money-check",
          "fat fa-money-check-dollar",
          "fat fa-money-check-dollar-pen",
          "fat fa-money-check-pen",
          "fat fa-money-from-bracket",
          "fat fa-money-simple-from-bracket",
          "fat fa-monitor-waveform",
          "fat fa-monkey",
          "fat fa-monument",
          "fat fa-moon",
          "fat fa-moon-cloud",
          "fat fa-moon-over-sun",
          "fat fa-moon-stars",
          "fat fa-moped",
          "fat fa-mortar-pestle",
          "fat fa-mosque",
          "fat fa-mosquito",
          "fat fa-mosquito-net",
          "fat fa-motorcycle",
          "fat fa-mound",
          "fat fa-mountain",
          "fat fa-mountain-city",
          "fat fa-mountain-sun",
          "fat fa-mountains",
          "fat fa-mouse-field",
          "fat fa-mp3-player",
          "fat fa-mug",
          "fat fa-mug-hot",
          "fat fa-mug-marshmallows",
          "fat fa-mug-saucer",
          "fat fa-mug-tea",
          "fat fa-mug-tea-saucer",
          "fat fa-mushroom",
          "fat fa-music",
          "fat fa-music-note",
          "fat fa-music-note-slash",
          "fat fa-music-slash",
          "fat fa-mustache",
          "fat fa-n",
          "fat fa-naira-sign",
          "fat fa-narwhal",
          "fat fa-nesting-dolls",
          "fat fa-network-wired",
          "fat fa-neuter",
          "fat fa-newspaper",
          "fat fa-nfc",
          "fat fa-nfc-lock",
          "fat fa-nfc-magnifying-glass",
          "fat fa-nfc-pen",
          "fat fa-nfc-signal",
          "fat fa-nfc-slash",
          "fat fa-nfc-symbol",
          "fat fa-nfc-trash",
          "fat fa-nose",
          "fat fa-not-equal",
          "fat fa-notdef",
          "fat fa-note",
          "fat fa-note-medical",
          "fat fa-note-sticky",
          "fat fa-notebook",
          "fat fa-notes",
          "fat fa-notes-medical",
          "fat fa-o",
          "fat fa-object-exclude",
          "fat fa-object-group",
          "fat fa-object-intersect",
          "fat fa-object-subtract",
          "fat fa-object-ungroup",
          "fat fa-object-union",
          "fat fa-objects-align-bottom",
          "fat fa-objects-align-center-horizontal",
          "fat fa-objects-align-center-vertical",
          "fat fa-objects-align-left",
          "fat fa-objects-align-right",
          "fat fa-objects-align-top",
          "fat fa-objects-column",
          "fat fa-octagon",
          "fat fa-octagon-check",
          "fat fa-octagon-divide",
          "fat fa-octagon-exclamation",
          "fat fa-octagon-minus",
          "fat fa-octagon-plus",
          "fat fa-octagon-xmark",
          "fat fa-oil-can",
          "fat fa-oil-can-drip",
          "fat fa-oil-temperature",
          "fat fa-oil-well",
          "fat fa-olive",
          "fat fa-olive-branch",
          "fat fa-om",
          "fat fa-omega",
          "fat fa-onion",
          "fat fa-option",
          "fat fa-ornament",
          "fat fa-otter",
          "fat fa-outdent",
          "fat fa-outlet",
          "fat fa-oven",
          "fat fa-overline",
          "fat fa-p",
          "fat fa-page",
          "fat fa-page-caret-down",
          "fat fa-page-caret-up",
          "fat fa-pager",
          "fat fa-paint-roller",
          "fat fa-paintbrush",
          "fat fa-paintbrush-fine",
          "fat fa-paintbrush-pencil",
          "fat fa-palette",
          "fat fa-pallet",
          "fat fa-pallet-box",
          "fat fa-pallet-boxes",
          "fat fa-pan-food",
          "fat fa-pan-frying",
          "fat fa-pancakes",
          "fat fa-panel-ews",
          "fat fa-panel-fire",
          "fat fa-panorama",
          "fat fa-paper-plane",
          "fat fa-paper-plane-top",
          "fat fa-paperclip",
          "fat fa-paperclip-vertical",
          "fat fa-parachute-box",
          "fat fa-paragraph",
          "fat fa-paragraph-left",
          "fat fa-party-bell",
          "fat fa-party-horn",
          "fat fa-passport",
          "fat fa-paste",
          "fat fa-pause",
          "fat fa-paw",
          "fat fa-paw-claws",
          "fat fa-paw-simple",
          "fat fa-peace",
          "fat fa-peach",
          "fat fa-peanut",
          "fat fa-peanuts",
          "fat fa-peapod",
          "fat fa-pear",
          "fat fa-pedestal",
          "fat fa-pegasus",
          "fat fa-pen",
          "fat fa-pen-circle",
          "fat fa-pen-clip",
          "fat fa-pen-clip-slash",
          "fat fa-pen-fancy",
          "fat fa-pen-fancy-slash",
          "fat fa-pen-field",
          "fat fa-pen-line",
          "fat fa-pen-nib",
          "fat fa-pen-nib-slash",
          "fat fa-pen-paintbrush",
          "fat fa-pen-ruler",
          "fat fa-pen-slash",
          "fat fa-pen-swirl",
          "fat fa-pen-to-square",
          "fat fa-pencil",
          "fat fa-pencil-mechanical",
          "fat fa-pencil-slash",
          "fat fa-people",
          "fat fa-people-arrows",
          "fat fa-people-carry-box",
          "fat fa-people-dress",
          "fat fa-people-dress-simple",
          "fat fa-people-group",
          "fat fa-people-line",
          "fat fa-people-pants",
          "fat fa-people-pants-simple",
          "fat fa-people-pulling",
          "fat fa-people-robbery",
          "fat fa-people-roof",
          "fat fa-people-simple",
          "fat fa-pepper",
          "fat fa-pepper-hot",
          "fat fa-percent",
          "fat fa-period",
          "fat fa-person",
          "fat fa-person-arrow-down-to-line",
          "fat fa-person-arrow-up-from-line",
          "fat fa-person-biking",
          "fat fa-person-biking-mountain",
          "fat fa-person-booth",
          "fat fa-person-breastfeeding",
          "fat fa-person-burst",
          "fat fa-person-cane",
          "fat fa-person-carry-box",
          "fat fa-person-chalkboard",
          "fat fa-person-circle-check",
          "fat fa-person-circle-exclamation",
          "fat fa-person-circle-minus",
          "fat fa-person-circle-plus",
          "fat fa-person-circle-question",
          "fat fa-person-circle-xmark",
          "fat fa-person-digging",
          "fat fa-person-dolly",
          "fat fa-person-dolly-empty",
          "fat fa-person-dots-from-line",
          "fat fa-person-dress",
          "fat fa-person-dress-burst",
          "fat fa-person-dress-simple",
          "fat fa-person-drowning",
          "fat fa-person-falling",
          "fat fa-person-falling-burst",
          "fat fa-person-from-portal",
          "fat fa-person-half-dress",
          "fat fa-person-harassing",
          "fat fa-person-hiking",
          "fat fa-person-military-pointing",
          "fat fa-person-military-rifle",
          "fat fa-person-military-to-person",
          "fat fa-person-pinball",
          "fat fa-person-praying",
          "fat fa-person-pregnant",
          "fat fa-person-rays",
          "fat fa-person-rifle",
          "fat fa-person-running",
          "fat fa-person-seat",
          "fat fa-person-seat-reclined",
          "fat fa-person-shelter",
          "fat fa-person-sign",
          "fat fa-person-simple",
          "fat fa-person-skating",
          "fat fa-person-ski-jumping",
          "fat fa-person-ski-lift",
          "fat fa-person-skiing",
          "fat fa-person-skiing-nordic",
          "fat fa-person-sledding",
          "fat fa-person-snowboarding",
          "fat fa-person-snowmobiling",
          "fat fa-person-swimming",
          "fat fa-person-through-window",
          "fat fa-person-to-door",
          "fat fa-person-to-portal",
          "fat fa-person-walking",
          "fat fa-person-walking-arrow-loop-left",
          "fat fa-person-walking-arrow-right",
          "fat fa-person-walking-dashed-line-arrow-right",
          "fat fa-person-walking-luggage",
          "fat fa-person-walking-with-cane",
          "fat fa-peseta-sign",
          "fat fa-peso-sign",
          "fat fa-phone",
          "fat fa-phone-arrow-down-left",
          "fat fa-phone-arrow-right",
          "fat fa-phone-arrow-up-right",
          "fat fa-phone-flip",
          "fat fa-phone-hangup",
          "fat fa-phone-intercom",
          "fat fa-phone-missed",
          "fat fa-phone-office",
          "fat fa-phone-plus",
          "fat fa-phone-rotary",
          "fat fa-phone-slash",
          "fat fa-phone-volume",
          "fat fa-phone-xmark",
          "fat fa-photo-film",
          "fat fa-photo-film-music",
          "fat fa-pi",
          "fat fa-piano",
          "fat fa-piano-keyboard",
          "fat fa-pickaxe",
          "fat fa-pickleball",
          "fat fa-pie",
          "fat fa-pig",
          "fat fa-piggy-bank",
          "fat fa-pills",
          "fat fa-pinata",
          "fat fa-pinball",
          "fat fa-pineapple",
          "fat fa-pipe",
          "fat fa-pipe-circle-check",
          "fat fa-pipe-collar",
          "fat fa-pipe-section",
          "fat fa-pipe-smoking",
          "fat fa-pipe-valve",
          "fat fa-pizza",
          "fat fa-pizza-slice",
          "fat fa-place-of-worship",
          "fat fa-plane",
          "fat fa-plane-arrival",
          "fat fa-plane-circle-check",
          "fat fa-plane-circle-exclamation",
          "fat fa-plane-circle-xmark",
          "fat fa-plane-departure",
          "fat fa-plane-engines",
          "fat fa-plane-lock",
          "fat fa-plane-prop",
          "fat fa-plane-slash",
          "fat fa-plane-tail",
          "fat fa-plane-up",
          "fat fa-plane-up-slash",
          "fat fa-planet-moon",
          "fat fa-planet-ringed",
          "fat fa-plant-wilt",
          "fat fa-plate-utensils",
          "fat fa-plate-wheat",
          "fat fa-play",
          "fat fa-play-pause",
          "fat fa-plug",
          "fat fa-plug-circle-bolt",
          "fat fa-plug-circle-check",
          "fat fa-plug-circle-exclamation",
          "fat fa-plug-circle-minus",
          "fat fa-plug-circle-plus",
          "fat fa-plug-circle-xmark",
          "fat fa-plus",
          "fat fa-plus-large",
          "fat fa-plus-minus",
          "fat fa-podcast",
          "fat fa-podium",
          "fat fa-podium-star",
          "fat fa-police-box",
          "fat fa-poll-people",
          "fat fa-pompebled",
          "fat fa-poo",
          "fat fa-poo-storm",
          "fat fa-pool-8-ball",
          "fat fa-poop",
          "fat fa-popcorn",
          "fat fa-popsicle",
          "fat fa-pot-food",
          "fat fa-potato",
          "fat fa-power-off",
          "fat fa-prescription",
          "fat fa-prescription-bottle",
          "fat fa-prescription-bottle-medical",
          "fat fa-prescription-bottle-pill",
          "fat fa-presentation-screen",
          "fat fa-pretzel",
          "fat fa-print",
          "fat fa-print-magnifying-glass",
          "fat fa-print-slash",
          "fat fa-projector",
          "fat fa-pump",
          "fat fa-pump-medical",
          "fat fa-pump-soap",
          "fat fa-pumpkin",
          "fat fa-puzzle",
          "fat fa-puzzle-piece",
          "fat fa-puzzle-piece-simple",
          "fat fa-q",
          "fat fa-qrcode",
          "fat fa-question",
          "fat fa-quote-left",
          "fat fa-quote-right",
          "fat fa-quotes",
          "fat fa-r",
          "fat fa-rabbit",
          "fat fa-rabbit-running",
          "fat fa-racquet",
          "fat fa-radar",
          "fat fa-radiation",
          "fat fa-radio",
          "fat fa-radio-tuner",
          "fat fa-rainbow",
          "fat fa-raindrops",
          "fat fa-ram",
          "fat fa-ramp-loading",
          "fat fa-ranking-star",
          "fat fa-raygun",
          "fat fa-receipt",
          "fat fa-record-vinyl",
          "fat fa-rectangle",
          "fat fa-rectangle-ad",
          "fat fa-rectangle-barcode",
          "fat fa-rectangle-code",
          "fat fa-rectangle-history",
          "fat fa-rectangle-history-circle-plus",
          "fat fa-rectangle-history-circle-user",
          "fat fa-rectangle-list",
          "fat fa-rectangle-pro",
          "fat fa-rectangle-terminal",
          "fat fa-rectangle-vertical",
          "fat fa-rectangle-vertical-history",
          "fat fa-rectangle-wide",
          "fat fa-rectangle-xmark",
          "fat fa-rectangles-mixed",
          "fat fa-recycle",
          "fat fa-reel",
          "fat fa-refrigerator",
          "fat fa-registered",
          "fat fa-repeat",
          "fat fa-repeat-1",
          "fat fa-reply",
          "fat fa-reply-all",
          "fat fa-reply-clock",
          "fat fa-republican",
          "fat fa-restroom",
          "fat fa-restroom-simple",
          "fat fa-retweet",
          "fat fa-rhombus",
          "fat fa-ribbon",
          "fat fa-right",
          "fat fa-right-from-bracket",
          "fat fa-right-from-line",
          "fat fa-right-left",
          "fat fa-right-long",
          "fat fa-right-long-to-line",
          "fat fa-right-to-bracket",
          "fat fa-right-to-line",
          "fat fa-ring",
          "fat fa-ring-diamond",
          "fat fa-rings-wedding",
          "fat fa-road",
          "fat fa-road-barrier",
          "fat fa-road-bridge",
          "fat fa-road-circle-check",
          "fat fa-road-circle-exclamation",
          "fat fa-road-circle-xmark",
          "fat fa-road-lock",
          "fat fa-road-spikes",
          "fat fa-robot",
          "fat fa-robot-astromech",
          "fat fa-rocket",
          "fat fa-rocket-launch",
          "fat fa-roller-coaster",
          "fat fa-rotate",
          "fat fa-rotate-exclamation",
          "fat fa-rotate-left",
          "fat fa-rotate-right",
          "fat fa-route",
          "fat fa-route-highway",
          "fat fa-route-interstate",
          "fat fa-router",
          "fat fa-rss",
          "fat fa-ruble-sign",
          "fat fa-rug",
          "fat fa-rugby-ball",
          "fat fa-ruler",
          "fat fa-ruler-combined",
          "fat fa-ruler-horizontal",
          "fat fa-ruler-triangle",
          "fat fa-ruler-vertical",
          "fat fa-rupee-sign",
          "fat fa-rupiah-sign",
          "fat fa-rv",
          "fat fa-s",
          "fat fa-sack",
          "fat fa-sack-dollar",
          "fat fa-sack-xmark",
          "fat fa-sailboat",
          "fat fa-salad",
          "fat fa-salt-shaker",
          "fat fa-sandwich",
          "fat fa-satellite",
          "fat fa-satellite-dish",
          "fat fa-sausage",
          "fat fa-saxophone",
          "fat fa-saxophone-fire",
          "fat fa-scale-balanced",
          "fat fa-scale-unbalanced",
          "fat fa-scale-unbalanced-flip",
          "fat fa-scalpel",
          "fat fa-scalpel-line-dashed",
          "fat fa-scanner-gun",
          "fat fa-scanner-image",
          "fat fa-scanner-keyboard",
          "fat fa-scanner-touchscreen",
          "fat fa-scarecrow",
          "fat fa-scarf",
          "fat fa-school",
          "fat fa-school-circle-check",
          "fat fa-school-circle-exclamation",
          "fat fa-school-circle-xmark",
          "fat fa-school-flag",
          "fat fa-school-lock",
          "fat fa-scissors",
          "fat fa-screen-users",
          "fat fa-screencast",
          "fat fa-screwdriver",
          "fat fa-screwdriver-wrench",
          "fat fa-scribble",
          "fat fa-scroll",
          "fat fa-scroll-old",
          "fat fa-scroll-torah",
          "fat fa-scrubber",
          "fat fa-scythe",
          "fat fa-sd-card",
          "fat fa-sd-cards",
          "fat fa-seal",
          "fat fa-seal-exclamation",
          "fat fa-seal-question",
          "fat fa-seat-airline",
          "fat fa-section",
          "fat fa-seedling",
          "fat fa-semicolon",
          "fat fa-send-back",
          "fat fa-send-backward",
          "fat fa-sensor",
          "fat fa-sensor-cloud",
          "fat fa-sensor-fire",
          "fat fa-sensor-on",
          "fat fa-sensor-triangle-exclamation",
          "fat fa-server",
          "fat fa-shapes",
          "fat fa-share",
          "fat fa-share-all",
          "fat fa-share-from-square",
          "fat fa-share-nodes",
          "fat fa-sheep",
          "fat fa-sheet-plastic",
          "fat fa-shekel-sign",
          "fat fa-shelves",
          "fat fa-shelves-empty",
          "fat fa-shield",
          "fat fa-shield-cat",
          "fat fa-shield-check",
          "fat fa-shield-cross",
          "fat fa-shield-dog",
          "fat fa-shield-exclamation",
          "fat fa-shield-halved",
          "fat fa-shield-heart",
          "fat fa-shield-keyhole",
          "fat fa-shield-minus",
          "fat fa-shield-plus",
          "fat fa-shield-quartered",
          "fat fa-shield-slash",
          "fat fa-shield-virus",
          "fat fa-shield-xmark",
          "fat fa-ship",
          "fat fa-shirt",
          "fat fa-shirt-long-sleeve",
          "fat fa-shirt-running",
          "fat fa-shirt-tank-top",
          "fat fa-shish-kebab",
          "fat fa-shoe-prints",
          "fat fa-shop",
          "fat fa-shop-lock",
          "fat fa-shop-slash",
          "fat fa-shovel",
          "fat fa-shovel-snow",
          "fat fa-shower",
          "fat fa-shower-down",
          "fat fa-shredder",
          "fat fa-shrimp",
          "fat fa-shuffle",
          "fat fa-shutters",
          "fat fa-shuttle-space",
          "fat fa-shuttlecock",
          "fat fa-sickle",
          "fat fa-sidebar",
          "fat fa-sidebar-flip",
          "fat fa-sigma",
          "fat fa-sign-hanging",
          "fat fa-signal",
          "fat fa-signal-bars",
          "fat fa-signal-bars-fair",
          "fat fa-signal-bars-good",
          "fat fa-signal-bars-slash",
          "fat fa-signal-bars-weak",
          "fat fa-signal-fair",
          "fat fa-signal-good",
          "fat fa-signal-slash",
          "fat fa-signal-stream",
          "fat fa-signal-stream-slash",
          "fat fa-signal-strong",
          "fat fa-signal-weak",
          "fat fa-signature",
          "fat fa-signature-lock",
          "fat fa-signature-slash",
          "fat fa-signs-post",
          "fat fa-sim-card",
          "fat fa-sim-cards",
          "fat fa-sink",
          "fat fa-siren",
          "fat fa-siren-on",
          "fat fa-sitemap",
          "fat fa-skeleton",
          "fat fa-skeleton-ribs",
          "fat fa-ski-boot",
          "fat fa-ski-boot-ski",
          "fat fa-skull",
          "fat fa-skull-cow",
          "fat fa-skull-crossbones",
          "fat fa-slash",
          "fat fa-slash-back",
          "fat fa-slash-forward",
          "fat fa-sleigh",
          "fat fa-slider",
          "fat fa-sliders",
          "fat fa-sliders-simple",
          "fat fa-sliders-up",
          "fat fa-slot-machine",
          "fat fa-smog",
          "fat fa-smoke",
          "fat fa-smoking",
          "fat fa-snake",
          "fat fa-snooze",
          "fat fa-snow-blowing",
          "fat fa-snowflake",
          "fat fa-snowflake-droplets",
          "fat fa-snowflakes",
          "fat fa-snowman",
          "fat fa-snowman-head",
          "fat fa-snowplow",
          "fat fa-soap",
          "fat fa-socks",
          "fat fa-soft-serve",
          "fat fa-solar-panel",
          "fat fa-solar-system",
          "fat fa-sort",
          "fat fa-sort-down",
          "fat fa-sort-up",
          "fat fa-spa",
          "fat fa-space-station-moon",
          "fat fa-space-station-moon-construction",
          "fat fa-spade",
          "fat fa-spaghetti-monster-flying",
          "fat fa-sparkle",
          "fat fa-sparkles",
          "fat fa-speaker",
          "fat fa-speakers",
          "fat fa-spell-check",
          "fat fa-spider",
          "fat fa-spider-black-widow",
          "fat fa-spider-web",
          "fat fa-spinner",
          "fat fa-spinner-third",
          "fat fa-split",
          "fat fa-splotch",
          "fat fa-spoon",
          "fat fa-sportsball",
          "fat fa-spray-can",
          "fat fa-spray-can-sparkles",
          "fat fa-sprinkler",
          "fat fa-sprinkler-ceiling",
          "fat fa-square",
          "fat fa-square-0",
          "fat fa-square-1",
          "fat fa-square-2",
          "fat fa-square-3",
          "fat fa-square-4",
          "fat fa-square-5",
          "fat fa-square-6",
          "fat fa-square-7",
          "fat fa-square-8",
          "fat fa-square-9",
          "fat fa-square-a",
          "fat fa-square-a-lock",
          "fat fa-square-ampersand",
          "fat fa-square-arrow-down",
          "fat fa-square-arrow-down-left",
          "fat fa-square-arrow-down-right",
          "fat fa-square-arrow-left",
          "fat fa-square-arrow-right",
          "fat fa-square-arrow-up",
          "fat fa-square-arrow-up-left",
          "fat fa-square-arrow-up-right",
          "fat fa-square-b",
          "fat fa-square-bolt",
          "fat fa-square-c",
          "fat fa-square-caret-down",
          "fat fa-square-caret-left",
          "fat fa-square-caret-right",
          "fat fa-square-caret-up",
          "fat fa-square-check",
          "fat fa-square-chevron-down",
          "fat fa-square-chevron-left",
          "fat fa-square-chevron-right",
          "fat fa-square-chevron-up",
          "fat fa-square-code",
          "fat fa-square-d",
          "fat fa-square-dashed",
          "fat fa-square-dashed-circle-plus",
          "fat fa-square-divide",
          "fat fa-square-dollar",
          "fat fa-square-down",
          "fat fa-square-down-left",
          "fat fa-square-down-right",
          "fat fa-square-e",
          "fat fa-square-ellipsis",
          "fat fa-square-ellipsis-vertical",
          "fat fa-square-envelope",
          "fat fa-square-exclamation",
          "fat fa-square-f",
          "fat fa-square-fragile",
          "fat fa-square-full",
          "fat fa-square-g",
          "fat fa-square-h",
          "fat fa-square-heart",
          "fat fa-square-i",
          "fat fa-square-info",
          "fat fa-square-j",
          "fat fa-square-k",
          "fat fa-square-kanban",
          "fat fa-square-l",
          "fat fa-square-left",
          "fat fa-square-list",
          "fat fa-square-m",
          "fat fa-square-minus",
          "fat fa-square-n",
          "fat fa-square-nfi",
          "fat fa-square-o",
          "fat fa-square-p",
          "fat fa-square-parking",
          "fat fa-square-parking-slash",
          "fat fa-square-pen",
          "fat fa-square-person-confined",
          "fat fa-square-phone",
          "fat fa-square-phone-flip",
          "fat fa-square-phone-hangup",
          "fat fa-square-plus",
          "fat fa-square-poll-horizontal",
          "fat fa-square-poll-vertical",
          "fat fa-square-q",
          "fat fa-square-quarters",
          "fat fa-square-question",
          "fat fa-square-quote",
          "fat fa-square-r",
          "fat fa-square-right",
          "fat fa-square-ring",
          "fat fa-square-root",
          "fat fa-square-root-variable",
          "fat fa-square-rss",
          "fat fa-square-s",
          "fat fa-square-share-nodes",
          "fat fa-square-sliders",
          "fat fa-square-sliders-vertical",
          "fat fa-square-small",
          "fat fa-square-star",
          "fat fa-square-t",
          "fat fa-square-terminal",
          "fat fa-square-this-way-up",
          "fat fa-square-u",
          "fat fa-square-up",
          "fat fa-square-up-left",
          "fat fa-square-up-right",
          "fat fa-square-user",
          "fat fa-square-v",
          "fat fa-square-virus",
          "fat fa-square-w",
          "fat fa-square-x",
          "fat fa-square-xmark",
          "fat fa-square-y",
          "fat fa-square-z",
          "fat fa-squid",
          "fat fa-squirrel",
          "fat fa-staff",
          "fat fa-staff-snake",
          "fat fa-stairs",
          "fat fa-stamp",
          "fat fa-standard-definition",
          "fat fa-stapler",
          "fat fa-star",
          "fat fa-star-and-crescent",
          "fat fa-star-christmas",
          "fat fa-star-exclamation",
          "fat fa-star-half",
          "fat fa-star-half-stroke",
          "fat fa-star-of-david",
          "fat fa-star-of-life",
          "fat fa-star-sharp",
          "fat fa-star-sharp-half",
          "fat fa-star-sharp-half-stroke",
          "fat fa-star-shooting",
          "fat fa-starfighter",
          "fat fa-starfighter-twin-ion-engine",
          "fat fa-starfighter-twin-ion-engine-advanced",
          "fat fa-stars",
          "fat fa-starship",
          "fat fa-starship-freighter",
          "fat fa-steak",
          "fat fa-steering-wheel",
          "fat fa-sterling-sign",
          "fat fa-stethoscope",
          "fat fa-stocking",
          "fat fa-stomach",
          "fat fa-stop",
          "fat fa-stopwatch",
          "fat fa-stopwatch-20",
          "fat fa-store",
          "fat fa-store-lock",
          "fat fa-store-slash",
          "fat fa-strawberry",
          "fat fa-street-view",
          "fat fa-stretcher",
          "fat fa-strikethrough",
          "fat fa-stroopwafel",
          "fat fa-subscript",
          "fat fa-suitcase",
          "fat fa-suitcase-medical",
          "fat fa-suitcase-rolling",
          "fat fa-sun",
          "fat fa-sun-bright",
          "fat fa-sun-cloud",
          "fat fa-sun-dust",
          "fat fa-sun-haze",
          "fat fa-sun-plant-wilt",
          "fat fa-sunglasses",
          "fat fa-sunrise",
          "fat fa-sunset",
          "fat fa-superscript",
          "fat fa-sushi",
          "fat fa-sushi-roll",
          "fat fa-swatchbook",
          "fat fa-sword",
          "fat fa-sword-laser",
          "fat fa-sword-laser-alt",
          "fat fa-swords",
          "fat fa-swords-laser",
          "fat fa-symbols",
          "fat fa-synagogue",
          "fat fa-syringe",
          "fat fa-t",
          "fat fa-table",
          "fat fa-table-cells",
          "fat fa-table-cells-large",
          "fat fa-table-columns",
          "fat fa-table-layout",
          "fat fa-table-list",
          "fat fa-table-picnic",
          "fat fa-table-pivot",
          "fat fa-table-rows",
          "fat fa-table-tennis-paddle-ball",
          "fat fa-table-tree",
          "fat fa-tablet",
          "fat fa-tablet-button",
          "fat fa-tablet-rugged",
          "fat fa-tablet-screen",
          "fat fa-tablet-screen-button",
          "fat fa-tablets",
          "fat fa-tachograph-digital",
          "fat fa-taco",
          "fat fa-tag",
          "fat fa-tags",
          "fat fa-tally",
          "fat fa-tally-1",
          "fat fa-tally-2",
          "fat fa-tally-3",
          "fat fa-tally-4",
          "fat fa-tamale",
          "fat fa-tank-water",
          "fat fa-tape",
          "fat fa-tarp",
          "fat fa-tarp-droplet",
          "fat fa-taxi",
          "fat fa-taxi-bus",
          "fat fa-teddy-bear",
          "fat fa-teeth",
          "fat fa-teeth-open",
          "fat fa-telescope",
          "fat fa-temperature-arrow-down",
          "fat fa-temperature-arrow-up",
          "fat fa-temperature-empty",
          "fat fa-temperature-full",
          "fat fa-temperature-half",
          "fat fa-temperature-high",
          "fat fa-temperature-list",
          "fat fa-temperature-low",
          "fat fa-temperature-quarter",
          "fat fa-temperature-snow",
          "fat fa-temperature-sun",
          "fat fa-temperature-three-quarters",
          "fat fa-tenge-sign",
          "fat fa-tennis-ball",
          "fat fa-tent",
          "fat fa-tent-arrow-down-to-line",
          "fat fa-tent-arrow-left-right",
          "fat fa-tent-arrow-turn-left",
          "fat fa-tent-arrows-down",
          "fat fa-tents",
          "fat fa-terminal",
          "fat fa-text",
          "fat fa-text-height",
          "fat fa-text-size",
          "fat fa-text-slash",
          "fat fa-text-width",
          "fat fa-thermometer",
          "fat fa-theta",
          "fat fa-thought-bubble",
          "fat fa-thumbs-down",
          "fat fa-thumbs-up",
          "fat fa-thumbtack",
          "fat fa-tick",
          "fat fa-ticket",
          "fat fa-ticket-airline",
          "fat fa-ticket-simple",
          "fat fa-tickets-airline",
          "fat fa-tilde",
          "fat fa-timeline",
          "fat fa-timeline-arrow",
          "fat fa-timer",
          "fat fa-tire",
          "fat fa-tire-flat",
          "fat fa-tire-pressure-warning",
          "fat fa-tire-rugged",
          "fat fa-toggle-large-off",
          "fat fa-toggle-large-on",
          "fat fa-toggle-off",
          "fat fa-toggle-on",
          "fat fa-toilet",
          "fat fa-toilet-paper",
          "fat fa-toilet-paper-blank",
          "fat fa-toilet-paper-blank-under",
          "fat fa-toilet-paper-check",
          "fat fa-toilet-paper-slash",
          "fat fa-toilet-paper-under",
          "fat fa-toilet-paper-under-slash",
          "fat fa-toilet-paper-xmark",
          "fat fa-toilet-portable",
          "fat fa-toilets-portable",
          "fat fa-tomato",
          "fat fa-tombstone",
          "fat fa-tombstone-blank",
          "fat fa-toolbox",
          "fat fa-tooth",
          "fat fa-toothbrush",
          "fat fa-torii-gate",
          "fat fa-tornado",
          "fat fa-tower-broadcast",
          "fat fa-tower-cell",
          "fat fa-tower-control",
          "fat fa-tower-observation",
          "fat fa-tractor",
          "fat fa-trademark",
          "fat fa-traffic-cone",
          "fat fa-traffic-light",
          "fat fa-traffic-light-go",
          "fat fa-traffic-light-slow",
          "fat fa-traffic-light-stop",
          "fat fa-trailer",
          "fat fa-train",
          "fat fa-train-subway",
          "fat fa-train-subway-tunnel",
          "fat fa-train-track",
          "fat fa-train-tram",
          "fat fa-train-tunnel",
          "fat fa-transformer-bolt",
          "fat fa-transgender",
          "fat fa-transporter",
          "fat fa-transporter-1",
          "fat fa-transporter-2",
          "fat fa-transporter-3",
          "fat fa-transporter-4",
          "fat fa-transporter-5",
          "fat fa-transporter-6",
          "fat fa-transporter-7",
          "fat fa-transporter-empty",
          "fat fa-trash",
          "fat fa-trash-arrow-up",
          "fat fa-trash-can",
          "fat fa-trash-can-arrow-up",
          "fat fa-trash-can-check",
          "fat fa-trash-can-clock",
          "fat fa-trash-can-list",
          "fat fa-trash-can-plus",
          "fat fa-trash-can-slash",
          "fat fa-trash-can-undo",
          "fat fa-trash-can-xmark",
          "fat fa-trash-check",
          "fat fa-trash-clock",
          "fat fa-trash-list",
          "fat fa-trash-plus",
          "fat fa-trash-slash",
          "fat fa-trash-undo",
          "fat fa-trash-xmark",
          "fat fa-treasure-chest",
          "fat fa-tree",
          "fat fa-tree-christmas",
          "fat fa-tree-city",
          "fat fa-tree-deciduous",
          "fat fa-tree-decorated",
          "fat fa-tree-large",
          "fat fa-tree-palm",
          "fat fa-trees",
          "fat fa-triangle",
          "fat fa-triangle-exclamation",
          "fat fa-triangle-instrument",
          "fat fa-triangle-person-digging",
          "fat fa-tricycle",
          "fat fa-tricycle-adult",
          "fat fa-trillium",
          "fat fa-trophy",
          "fat fa-trophy-star",
          "fat fa-trowel",
          "fat fa-trowel-bricks",
          "fat fa-truck",
          "fat fa-truck-arrow-right",
          "fat fa-truck-bolt",
          "fat fa-truck-clock",
          "fat fa-truck-container",
          "fat fa-truck-container-empty",
          "fat fa-truck-droplet",
          "fat fa-truck-fast",
          "fat fa-truck-field",
          "fat fa-truck-field-un",
          "fat fa-truck-flatbed",
          "fat fa-truck-front",
          "fat fa-truck-medical",
          "fat fa-truck-monster",
          "fat fa-truck-moving",
          "fat fa-truck-pickup",
          "fat fa-truck-plane",
          "fat fa-truck-plow",
          "fat fa-truck-ramp",
          "fat fa-truck-ramp-box",
          "fat fa-truck-ramp-couch",
          "fat fa-truck-tow",
          "fat fa-trumpet",
          "fat fa-tty",
          "fat fa-tty-answer",
          "fat fa-tugrik-sign",
          "fat fa-turkey",
          "fat fa-turkish-lira-sign",
          "fat fa-turn-down",
          "fat fa-turn-down-left",
          "fat fa-turn-down-right",
          "fat fa-turn-up",
          "fat fa-turntable",
          "fat fa-turtle",
          "fat fa-tv",
          "fat fa-tv-music",
          "fat fa-tv-retro",
          "fat fa-typewriter",
          "fat fa-u",
          "fat fa-ufo",
          "fat fa-ufo-beam",
          "fat fa-umbrella",
          "fat fa-umbrella-beach",
          "fat fa-umbrella-simple",
          "fat fa-underline",
          "fat fa-unicorn",
          "fat fa-uniform-martial-arts",
          "fat fa-union",
          "fat fa-universal-access",
          "fat fa-unlock",
          "fat fa-unlock-keyhole",
          "fat fa-up",
          "fat fa-up-down",
          "fat fa-up-down-left-right",
          "fat fa-up-from-bracket",
          "fat fa-up-from-dotted-line",
          "fat fa-up-from-line",
          "fat fa-up-left",
          "fat fa-up-long",
          "fat fa-up-right",
          "fat fa-up-right-and-down-left-from-center",
          "fat fa-up-right-from-square",
          "fat fa-up-to-dotted-line",
          "fat fa-up-to-line",
          "fat fa-upload",
          "fat fa-usb-drive",
          "fat fa-user",
          "fat fa-user-alien",
          "fat fa-user-astronaut",
          "fat fa-user-bounty-hunter",
          "fat fa-user-check",
          "fat fa-user-chef",
          "fat fa-user-clock",
          "fat fa-user-cowboy",
          "fat fa-user-crown",
          "fat fa-user-doctor",
          "fat fa-user-doctor-hair",
          "fat fa-user-doctor-hair-long",
          "fat fa-user-doctor-message",
          "fat fa-user-gear",
          "fat fa-user-graduate",
          "fat fa-user-group",
          "fat fa-user-group-crown",
          "fat fa-user-hair",
          "fat fa-user-hair-buns",
          "fat fa-user-hair-long",
          "fat fa-user-hair-mullet",
          "fat fa-user-headset",
          "fat fa-user-helmet-safety",
          "fat fa-user-injured",
          "fat fa-user-large",
          "fat fa-user-large-slash",
          "fat fa-user-lock",
          "fat fa-user-magnifying-glass",
          "fat fa-user-minus",
          "fat fa-user-music",
          "fat fa-user-ninja",
          "fat fa-user-nurse",
          "fat fa-user-nurse-hair",
          "fat fa-user-nurse-hair-long",
          "fat fa-user-pen",
          "fat fa-user-pilot",
          "fat fa-user-pilot-tie",
          "fat fa-user-plus",
          "fat fa-user-police",
          "fat fa-user-police-tie",
          "fat fa-user-robot",
          "fat fa-user-robot-xmarks",
          "fat fa-user-secret",
          "fat fa-user-shakespeare",
          "fat fa-user-shield",
          "fat fa-user-slash",
          "fat fa-user-tag",
          "fat fa-user-tie",
          "fat fa-user-tie-hair",
          "fat fa-user-tie-hair-long",
          "fat fa-user-unlock",
          "fat fa-user-visor",
          "fat fa-user-vneck",
          "fat fa-user-vneck-hair",
          "fat fa-user-vneck-hair-long",
          "fat fa-user-xmark",
          "fat fa-users",
          "fat fa-users-between-lines",
          "fat fa-users-gear",
          "fat fa-users-line",
          "fat fa-users-medical",
          "fat fa-users-rays",
          "fat fa-users-rectangle",
          "fat fa-users-slash",
          "fat fa-users-viewfinder",
          "fat fa-utensils",
          "fat fa-utensils-slash",
          "fat fa-utility-pole",
          "fat fa-utility-pole-double",
          "fat fa-v",
          "fat fa-vacuum",
          "fat fa-vacuum-robot",
          "fat fa-value-absolute",
          "fat fa-van-shuttle",
          "fat fa-vault",
          "fat fa-vector-circle",
          "fat fa-vector-polygon",
          "fat fa-vector-square",
          "fat fa-vent-damper",
          "fat fa-venus",
          "fat fa-venus-double",
          "fat fa-venus-mars",
          "fat fa-vest",
          "fat fa-vest-patches",
          "fat fa-vial",
          "fat fa-vial-circle-check",
          "fat fa-vial-virus",
          "fat fa-vials",
          "fat fa-video",
          "fat fa-video-arrow-down-left",
          "fat fa-video-arrow-up-right",
          "fat fa-video-plus",
          "fat fa-video-slash",
          "fat fa-vihara",
          "fat fa-violin",
          "fat fa-virus",
          "fat fa-virus-covid",
          "fat fa-virus-covid-slash",
          "fat fa-virus-slash",
          "fat fa-viruses",
          "fat fa-voicemail",
          "fat fa-volcano",
          "fat fa-volleyball",
          "fat fa-volume",
          "fat fa-volume-high",
          "fat fa-volume-low",
          "fat fa-volume-off",
          "fat fa-volume-slash",
          "fat fa-volume-xmark",
          "fat fa-vr-cardboard",
          "fat fa-w",
          "fat fa-waffle",
          "fat fa-wagon-covered",
          "fat fa-walker",
          "fat fa-walkie-talkie",
          "fat fa-wallet",
          "fat fa-wand",
          "fat fa-wand-magic",
          "fat fa-wand-magic-sparkles",
          "fat fa-wand-sparkles",
          "fat fa-warehouse",
          "fat fa-warehouse-full",
          "fat fa-washing-machine",
          "fat fa-watch",
          "fat fa-watch-apple",
          "fat fa-watch-calculator",
          "fat fa-watch-fitness",
          "fat fa-watch-smart",
          "fat fa-water",
          "fat fa-water-arrow-down",
          "fat fa-water-arrow-up",
          "fat fa-water-ladder",
          "fat fa-watermelon-slice",
          "fat fa-wave-pulse",
          "fat fa-wave-sine",
          "fat fa-wave-square",
          "fat fa-wave-triangle",
          "fat fa-waveform",
          "fat fa-waveform-lines",
          "fat fa-webhook",
          "fat fa-weight-hanging",
          "fat fa-weight-scale",
          "fat fa-whale",
          "fat fa-wheat",
          "fat fa-wheat-awn",
          "fat fa-wheat-awn-circle-exclamation",
          "fat fa-wheat-awn-slash",
          "fat fa-wheat-slash",
          "fat fa-wheelchair",
          "fat fa-wheelchair-move",
          "fat fa-whiskey-glass",
          "fat fa-whiskey-glass-ice",
          "fat fa-whistle",
          "fat fa-wifi",
          "fat fa-wifi-exclamation",
          "fat fa-wifi-fair",
          "fat fa-wifi-slash",
          "fat fa-wifi-weak",
          "fat fa-wind",
          "fat fa-wind-turbine",
          "fat fa-wind-warning",
          "fat fa-window",
          "fat fa-window-flip",
          "fat fa-window-frame",
          "fat fa-window-frame-open",
          "fat fa-window-maximize",
          "fat fa-window-minimize",
          "fat fa-window-restore",
          "fat fa-windsock",
          "fat fa-wine-bottle",
          "fat fa-wine-glass",
          "fat fa-wine-glass-crack",
          "fat fa-wine-glass-empty",
          "fat fa-won-sign",
          "fat fa-worm",
          "fat fa-wreath",
          "fat fa-wreath-laurel",
          "fat fa-wrench",
          "fat fa-wrench-simple",
          "fat fa-x",
          "fat fa-x-ray",
          "fat fa-xmark",
          "fat fa-xmark-large",
          "fat fa-xmark-to-slot",
          "fat fa-xmarks-lines",
          "fat fa-y",
          "fat fa-yen-sign",
          "fat fa-yin-yang",
          "fat fa-z"
        ],
      },
      duotone: {
        title: 'duotone',
        prefix: 'fad fa-',
        iconstyle: 'fad',
        listicon: 'fad fa-flag',        
        icons: [
          "fad fa-0",
          "fad fa-1",
          "fad fa-2",
          "fad fa-3",
          "fad fa-4",
          "fad fa-5",
          "fad fa-6",
          "fad fa-7",
          "fad fa-8",
          "fad fa-9",
          "fad fa-00",
          "fad fa-360-degrees",
          "fad fa-a",
          "fad fa-abacus",
          "fad fa-accent-grave",
          "fad fa-acorn",
          "fad fa-address-book",
          "fad fa-address-card",
          "fad fa-air-conditioner",
          "fad fa-airplay",
          "fad fa-alarm-clock",
          "fad fa-alarm-exclamation",
          "fad fa-alarm-plus",
          "fad fa-alarm-snooze",
          "fad fa-album",
          "fad fa-album-circle-plus",
          "fad fa-album-circle-user",
          "fad fa-album-collection",
          "fad fa-album-collection-circle-plus",
          "fad fa-album-collection-circle-user",
          "fad fa-alicorn",
          "fad fa-alien",
          "fad fa-alien-8bit",
          "fad fa-align-center",
          "fad fa-align-justify",
          "fad fa-align-left",
          "fad fa-align-right",
          "fad fa-align-slash",
          "fad fa-alt",
          "fad fa-amp-guitar",
          "fad fa-ampersand",
          "fad fa-anchor",
          "fad fa-anchor-circle-check",
          "fad fa-anchor-circle-exclamation",
          "fad fa-anchor-circle-xmark",
          "fad fa-anchor-lock",
          "fad fa-angel",
          "fad fa-angle",
          "fad fa-angle-90",
          "fad fa-angle-down",
          "fad fa-angle-left",
          "fad fa-angle-right",
          "fad fa-angle-up",
          "fad fa-angles-down",
          "fad fa-angles-left",
          "fad fa-angles-right",
          "fad fa-angles-up",
          "fad fa-ankh",
          "fad fa-apartment",
          "fad fa-aperture",
          "fad fa-apostrophe",
          "fad fa-apple-core",
          "fad fa-apple-whole",
          "fad fa-archway",
          "fad fa-arrow-down",
          "fad fa-arrow-down-1-9",
          "fad fa-arrow-down-9-1",
          "fad fa-arrow-down-a-z",
          "fad fa-arrow-down-arrow-up",
          "fad fa-arrow-down-big-small",
          "fad fa-arrow-down-from-dotted-line",
          "fad fa-arrow-down-from-line",
          "fad fa-arrow-down-left",
          "fad fa-arrow-down-left-and-arrow-up-right-to-center",
          "fad fa-arrow-down-long",
          "fad fa-arrow-down-right",
          "fad fa-arrow-down-short-wide",
          "fad fa-arrow-down-small-big",
          "fad fa-arrow-down-square-triangle",
          "fad fa-arrow-down-to-arc",
          "fad fa-arrow-down-to-bracket",
          "fad fa-arrow-down-to-dotted-line",
          "fad fa-arrow-down-to-line",
          "fad fa-arrow-down-to-square",
          "fad fa-arrow-down-triangle-square",
          "fad fa-arrow-down-up-across-line",
          "fad fa-arrow-down-up-lock",
          "fad fa-arrow-down-wide-short",
          "fad fa-arrow-down-z-a",
          "fad fa-arrow-left",
          "fad fa-arrow-left-from-line",
          "fad fa-arrow-left-long",
          "fad fa-arrow-left-long-to-line",
          "fad fa-arrow-left-to-line",
          "fad fa-arrow-pointer",
          "fad fa-arrow-right",
          "fad fa-arrow-right-arrow-left",
          "fad fa-arrow-right-from-arc",
          "fad fa-arrow-right-from-bracket",
          "fad fa-arrow-right-from-line",
          "fad fa-arrow-right-long",
          "fad fa-arrow-right-long-to-line",
          "fad fa-arrow-right-to-arc",
          "fad fa-arrow-right-to-bracket",
          "fad fa-arrow-right-to-city",
          "fad fa-arrow-right-to-line",
          "fad fa-arrow-rotate-left",
          "fad fa-arrow-rotate-right",
          "fad fa-arrow-trend-down",
          "fad fa-arrow-trend-up",
          "fad fa-arrow-turn-down",
          "fad fa-arrow-turn-down-left",
          "fad fa-arrow-turn-down-right",
          "fad fa-arrow-turn-up",
          "fad fa-arrow-up",
          "fad fa-arrow-up-1-9",
          "fad fa-arrow-up-9-1",
          "fad fa-arrow-up-a-z",
          "fad fa-arrow-up-arrow-down",
          "fad fa-arrow-up-big-small",
          "fad fa-arrow-up-from-arc",
          "fad fa-arrow-up-from-bracket",
          "fad fa-arrow-up-from-dotted-line",
          "fad fa-arrow-up-from-ground-water",
          "fad fa-arrow-up-from-line",
          "fad fa-arrow-up-from-square",
          "fad fa-arrow-up-from-water-pump",
          "fad fa-arrow-up-left",
          "fad fa-arrow-up-left-from-circle",
          "fad fa-arrow-up-long",
          "fad fa-arrow-up-right",
          "fad fa-arrow-up-right-and-arrow-down-left-from-center",
          "fad fa-arrow-up-right-dots",
          "fad fa-arrow-up-right-from-square",
          "fad fa-arrow-up-short-wide",
          "fad fa-arrow-up-small-big",
          "fad fa-arrow-up-square-triangle",
          "fad fa-arrow-up-to-dotted-line",
          "fad fa-arrow-up-to-line",
          "fad fa-arrow-up-triangle-square",
          "fad fa-arrow-up-wide-short",
          "fad fa-arrow-up-z-a",
          "fad fa-arrows-cross",
          "fad fa-arrows-down-to-line",
          "fad fa-arrows-down-to-people",
          "fad fa-arrows-from-dotted-line",
          "fad fa-arrows-from-line",
          "fad fa-arrows-left-right",
          "fad fa-arrows-left-right-to-line",
          "fad fa-arrows-maximize",
          "fad fa-arrows-minimize",
          "fad fa-arrows-repeat",
          "fad fa-arrows-repeat-1",
          "fad fa-arrows-retweet",
          "fad fa-arrows-rotate",
          "fad fa-arrows-spin",
          "fad fa-arrows-split-up-and-left",
          "fad fa-arrows-to-circle",
          "fad fa-arrows-to-dot",
          "fad fa-arrows-to-dotted-line",
          "fad fa-arrows-to-eye",
          "fad fa-arrows-to-line",
          "fad fa-arrows-turn-right",
          "fad fa-arrows-turn-to-dots",
          "fad fa-arrows-up-down",
          "fad fa-arrows-up-down-left-right",
          "fad fa-arrows-up-to-line",
          "fad fa-asterisk",
          "fad fa-at",
          "fad fa-atom",
          "fad fa-atom-simple",
          "fad fa-audio-description",
          "fad fa-audio-description-slash",
          "fad fa-austral-sign",
          "fad fa-avocado",
          "fad fa-award",
          "fad fa-award-simple",
          "fad fa-axe",
          "fad fa-axe-battle",
          "fad fa-b",
          "fad fa-baby",
          "fad fa-baby-carriage",
          "fad fa-backpack",
          "fad fa-backward",
          "fad fa-backward-fast",
          "fad fa-backward-step",
          "fad fa-bacon",
          "fad fa-bacteria",
          "fad fa-bacterium",
          "fad fa-badge",
          "fad fa-badge-check",
          "fad fa-badge-dollar",
          "fad fa-badge-percent",
          "fad fa-badge-sheriff",
          "fad fa-badger-honey",
          "fad fa-badminton",
          "fad fa-bag-shopping",
          "fad fa-bagel",
          "fad fa-bags-shopping",
          "fad fa-baguette",
          "fad fa-bahai",
          "fad fa-baht-sign",
          "fad fa-ball-pile",
          "fad fa-balloon",
          "fad fa-balloons",
          "fad fa-ballot",
          "fad fa-ballot-check",
          "fad fa-ban",
          "fad fa-ban-bug",
          "fad fa-ban-parking",
          "fad fa-ban-smoking",
          "fad fa-banana",
          "fad fa-bandage",
          "fad fa-bangladeshi-taka-sign",
          "fad fa-banjo",
          "fad fa-barcode",
          "fad fa-barcode-read",
          "fad fa-barcode-scan",
          "fad fa-bars",
          "fad fa-bars-filter",
          "fad fa-bars-progress",
          "fad fa-bars-sort",
          "fad fa-bars-staggered",
          "fad fa-baseball",
          "fad fa-baseball-bat-ball",
          "fad fa-basket-shopping",
          "fad fa-basket-shopping-simple",
          "fad fa-basketball",
          "fad fa-basketball-hoop",
          "fad fa-bat",
          "fad fa-bath",
          "fad fa-battery-bolt",
          "fad fa-battery-empty",
          "fad fa-battery-exclamation",
          "fad fa-battery-full",
          "fad fa-battery-half",
          "fad fa-battery-low",
          "fad fa-battery-quarter",
          "fad fa-battery-slash",
          "fad fa-battery-three-quarters",
          "fad fa-bed",
          "fad fa-bed-bunk",
          "fad fa-bed-empty",
          "fad fa-bed-front",
          "fad fa-bed-pulse",
          "fad fa-bee",
          "fad fa-beer-mug",
          "fad fa-beer-mug-empty",
          "fad fa-bell",
          "fad fa-bell-concierge",
          "fad fa-bell-exclamation",
          "fad fa-bell-on",
          "fad fa-bell-plus",
          "fad fa-bell-school",
          "fad fa-bell-school-slash",
          "fad fa-bell-slash",
          "fad fa-bells",
          "fad fa-bench-tree",
          "fad fa-bezier-curve",
          "fad fa-bicycle",
          "fad fa-billboard",
          "fad fa-binary",
          "fad fa-binary-circle-check",
          "fad fa-binary-lock",
          "fad fa-binary-slash",
          "fad fa-binoculars",
          "fad fa-biohazard",
          "fad fa-bird",
          "fad fa-bitcoin-sign",
          "fad fa-blanket",
          "fad fa-blanket-fire",
          "fad fa-blender",
          "fad fa-blender-phone",
          "fad fa-blinds",
          "fad fa-blinds-open",
          "fad fa-blinds-raised",
          "fad fa-block",
          "fad fa-block-brick",
          "fad fa-block-brick-fire",
          "fad fa-block-question",
          "fad fa-block-quote",
          "fad fa-blog",
          "fad fa-blueberries",
          "fad fa-bluetooth",
          "fad fa-bold",
          "fad fa-bolt",
          "fad fa-bolt-auto",
          "fad fa-bolt-lightning",
          "fad fa-bolt-slash",
          "fad fa-bomb",
          "fad fa-bone",
          "fad fa-bone-break",
          "fad fa-bong",
          "fad fa-book",
          "fad fa-book-arrow-right",
          "fad fa-book-arrow-up",
          "fad fa-book-atlas",
          "fad fa-book-bible",
          "fad fa-book-blank",
          "fad fa-book-bookmark",
          "fad fa-book-circle-arrow-right",
          "fad fa-book-circle-arrow-up",
          "fad fa-book-copy",
          "fad fa-book-font",
          "fad fa-book-heart",
          "fad fa-book-journal-whills",
          "fad fa-book-medical",
          "fad fa-book-open",
          "fad fa-book-open-cover",
          "fad fa-book-open-reader",
          "fad fa-book-quran",
          "fad fa-book-section",
          "fad fa-book-skull",
          "fad fa-book-sparkles",
          "fad fa-book-tanakh",
          "fad fa-book-user",
          "fad fa-bookmark",
          "fad fa-bookmark-slash",
          "fad fa-books",
          "fad fa-books-medical",
          "fad fa-boombox",
          "fad fa-boot",
          "fad fa-boot-heeled",
          "fad fa-booth-curtain",
          "fad fa-border-all",
          "fad fa-border-bottom",
          "fad fa-border-bottom-right",
          "fad fa-border-center-h",
          "fad fa-border-center-v",
          "fad fa-border-inner",
          "fad fa-border-left",
          "fad fa-border-none",
          "fad fa-border-outer",
          "fad fa-border-right",
          "fad fa-border-top",
          "fad fa-border-top-left",
          "fad fa-bore-hole",
          "fad fa-bottle-droplet",
          "fad fa-bottle-water",
          "fad fa-bow-arrow",
          "fad fa-bowl-chopsticks",
          "fad fa-bowl-chopsticks-noodles",
          "fad fa-bowl-food",
          "fad fa-bowl-hot",
          "fad fa-bowl-rice",
          "fad fa-bowl-scoop",
          "fad fa-bowl-scoops",
          "fad fa-bowl-soft-serve",
          "fad fa-bowl-spoon",
          "fad fa-bowling-ball",
          "fad fa-bowling-ball-pin",
          "fad fa-bowling-pins",
          "fad fa-box",
          "fad fa-box-archive",
          "fad fa-box-ballot",
          "fad fa-box-check",
          "fad fa-box-circle-check",
          "fad fa-box-dollar",
          "fad fa-box-heart",
          "fad fa-box-open",
          "fad fa-box-open-full",
          "fad fa-box-taped",
          "fad fa-box-tissue",
          "fad fa-boxes-packing",
          "fad fa-boxes-stacked",
          "fad fa-boxing-glove",
          "fad fa-bracket-curly",
          "fad fa-bracket-curly-right",
          "fad fa-bracket-round",
          "fad fa-bracket-round-right",
          "fad fa-bracket-square",
          "fad fa-bracket-square-right",
          "fad fa-brackets-curly",
          "fad fa-brackets-round",
          "fad fa-brackets-square",
          "fad fa-braille",
          "fad fa-brain",
          "fad fa-brain-arrow-curved-right",
          "fad fa-brain-circuit",
          "fad fa-brake-warning",
          "fad fa-brazilian-real-sign",
          "fad fa-bread-loaf",
          "fad fa-bread-slice",
          "fad fa-bread-slice-butter",
          "fad fa-bridge",
          "fad fa-bridge-circle-check",
          "fad fa-bridge-circle-exclamation",
          "fad fa-bridge-circle-xmark",
          "fad fa-bridge-lock",
          "fad fa-bridge-suspension",
          "fad fa-bridge-water",
          "fad fa-briefcase",
          "fad fa-briefcase-arrow-right",
          "fad fa-briefcase-blank",
          "fad fa-briefcase-medical",
          "fad fa-brightness",
          "fad fa-brightness-low",
          "fad fa-bring-forward",
          "fad fa-bring-front",
          "fad fa-broccoli",
          "fad fa-broom",
          "fad fa-broom-ball",
          "fad fa-broom-wide",
          "fad fa-browser",
          "fad fa-browsers",
          "fad fa-brush",
          "fad fa-bucket",
          "fad fa-bug",
          "fad fa-bug-slash",
          "fad fa-bugs",
          "fad fa-building",
          "fad fa-building-circle-arrow-right",
          "fad fa-building-circle-check",
          "fad fa-building-circle-exclamation",
          "fad fa-building-circle-xmark",
          "fad fa-building-columns",
          "fad fa-building-flag",
          "fad fa-building-lock",
          "fad fa-building-ngo",
          "fad fa-building-shield",
          "fad fa-building-un",
          "fad fa-building-user",
          "fad fa-building-wheat",
          "fad fa-buildings",
          "fad fa-bullhorn",
          "fad fa-bullseye",
          "fad fa-bullseye-arrow",
          "fad fa-bullseye-pointer",
          "fad fa-buoy",
          "fad fa-buoy-mooring",
          "fad fa-burger",
          "fad fa-burger-cheese",
          "fad fa-burger-fries",
          "fad fa-burger-glass",
          "fad fa-burger-lettuce",
          "fad fa-burger-soda",
          "fad fa-burrito",
          "fad fa-burst",
          "fad fa-bus",
          "fad fa-bus-school",
          "fad fa-bus-simple",
          "fad fa-business-time",
          "fad fa-butter",
          "fad fa-c",
          "fad fa-cabin",
          "fad fa-cabinet-filing",
          "fad fa-cable-car",
          "fad fa-cactus",
          "fad fa-cake-candles",
          "fad fa-cake-slice",
          "fad fa-calculator",
          "fad fa-calculator-simple",
          "fad fa-calendar",
          "fad fa-calendar-arrow-down",
          "fad fa-calendar-arrow-up",
          "fad fa-calendar-check",
          "fad fa-calendar-circle-exclamation",
          "fad fa-calendar-circle-minus",
          "fad fa-calendar-circle-plus",
          "fad fa-calendar-circle-user",
          "fad fa-calendar-clock",
          "fad fa-calendar-day",
          "fad fa-calendar-days",
          "fad fa-calendar-exclamation",
          "fad fa-calendar-heart",
          "fad fa-calendar-image",
          "fad fa-calendar-lines",
          "fad fa-calendar-lines-pen",
          "fad fa-calendar-minus",
          "fad fa-calendar-pen",
          "fad fa-calendar-plus",
          "fad fa-calendar-range",
          "fad fa-calendar-star",
          "fad fa-calendar-week",
          "fad fa-calendar-xmark",
          "fad fa-calendars",
          "fad fa-camcorder",
          "fad fa-camera",
          "fad fa-camera-cctv",
          "fad fa-camera-movie",
          "fad fa-camera-polaroid",
          "fad fa-camera-retro",
          "fad fa-camera-rotate",
          "fad fa-camera-security",
          "fad fa-camera-slash",
          "fad fa-camera-viewfinder",
          "fad fa-camera-web",
          "fad fa-camera-web-slash",
          "fad fa-campfire",
          "fad fa-campground",
          "fad fa-can-food",
          "fad fa-candle-holder",
          "fad fa-candy",
          "fad fa-candy-bar",
          "fad fa-candy-cane",
          "fad fa-candy-corn",
          "fad fa-cannabis",
          "fad fa-capsules",
          "fad fa-car",
          "fad fa-car-battery",
          "fad fa-car-bolt",
          "fad fa-car-building",
          "fad fa-car-bump",
          "fad fa-car-burst",
          "fad fa-car-bus",
          "fad fa-car-circle-bolt",
          "fad fa-car-garage",
          "fad fa-car-mirrors",
          "fad fa-car-on",
          "fad fa-car-rear",
          "fad fa-car-side",
          "fad fa-car-side-bolt",
          "fad fa-car-tilt",
          "fad fa-car-tunnel",
          "fad fa-car-wash",
          "fad fa-car-wrench",
          "fad fa-caravan",
          "fad fa-caravan-simple",
          "fad fa-card-club",
          "fad fa-card-diamond",
          "fad fa-card-heart",
          "fad fa-card-spade",
          "fad fa-cards",
          "fad fa-cards-blank",
          "fad fa-caret-down",
          "fad fa-caret-left",
          "fad fa-caret-right",
          "fad fa-caret-up",
          "fad fa-carrot",
          "fad fa-cars",
          "fad fa-cart-arrow-down",
          "fad fa-cart-arrow-up",
          "fad fa-cart-circle-arrow-down",
          "fad fa-cart-circle-arrow-up",
          "fad fa-cart-circle-check",
          "fad fa-cart-circle-exclamation",
          "fad fa-cart-circle-plus",
          "fad fa-cart-circle-xmark",
          "fad fa-cart-flatbed",
          "fad fa-cart-flatbed-boxes",
          "fad fa-cart-flatbed-empty",
          "fad fa-cart-flatbed-suitcase",
          "fad fa-cart-minus",
          "fad fa-cart-plus",
          "fad fa-cart-shopping",
          "fad fa-cart-shopping-fast",
          "fad fa-cart-xmark",
          "fad fa-cash-register",
          "fad fa-cassette-betamax",
          "fad fa-cassette-tape",
          "fad fa-cassette-vhs",
          "fad fa-castle",
          "fad fa-cat",
          "fad fa-cat-space",
          "fad fa-cauldron",
          "fad fa-cedi-sign",
          "fad fa-cent-sign",
          "fad fa-certificate",
          "fad fa-chair",
          "fad fa-chair-office",
          "fad fa-chalkboard",
          "fad fa-chalkboard-user",
          "fad fa-champagne-glass",
          "fad fa-champagne-glasses",
          "fad fa-charging-station",
          "fad fa-chart-area",
          "fad fa-chart-bar",
          "fad fa-chart-bullet",
          "fad fa-chart-candlestick",
          "fad fa-chart-column",
          "fad fa-chart-gantt",
          "fad fa-chart-line",
          "fad fa-chart-line-down",
          "fad fa-chart-line-up",
          "fad fa-chart-mixed",
          "fad fa-chart-network",
          "fad fa-chart-pie",
          "fad fa-chart-pie-simple",
          "fad fa-chart-pyramid",
          "fad fa-chart-radar",
          "fad fa-chart-scatter",
          "fad fa-chart-scatter-3d",
          "fad fa-chart-scatter-bubble",
          "fad fa-chart-simple",
          "fad fa-chart-simple-horizontal",
          "fad fa-chart-tree-map",
          "fad fa-chart-user",
          "fad fa-chart-waterfall",
          "fad fa-check",
          "fad fa-check-double",
          "fad fa-check-to-slot",
          "fad fa-cheese",
          "fad fa-cheese-swiss",
          "fad fa-cherries",
          "fad fa-chess",
          "fad fa-chess-bishop",
          "fad fa-chess-bishop-piece",
          "fad fa-chess-board",
          "fad fa-chess-clock",
          "fad fa-chess-clock-flip",
          "fad fa-chess-king",
          "fad fa-chess-king-piece",
          "fad fa-chess-knight",
          "fad fa-chess-knight-piece",
          "fad fa-chess-pawn",
          "fad fa-chess-pawn-piece",
          "fad fa-chess-queen",
          "fad fa-chess-queen-piece",
          "fad fa-chess-rook",
          "fad fa-chess-rook-piece",
          "fad fa-chestnut",
          "fad fa-chevron-down",
          "fad fa-chevron-left",
          "fad fa-chevron-right",
          "fad fa-chevron-up",
          "fad fa-chevrons-down",
          "fad fa-chevrons-left",
          "fad fa-chevrons-right",
          "fad fa-chevrons-up",
          "fad fa-child",
          "fad fa-child-combatant",
          "fad fa-child-dress",
          "fad fa-child-reaching",
          "fad fa-children",
          "fad fa-chimney",
          "fad fa-chopsticks",
          "fad fa-church",
          "fad fa-circle",
          "fad fa-circle-0",
          "fad fa-circle-1",
          "fad fa-circle-2",
          "fad fa-circle-3",
          "fad fa-circle-4",
          "fad fa-circle-5",
          "fad fa-circle-6",
          "fad fa-circle-7",
          "fad fa-circle-8",
          "fad fa-circle-9",
          "fad fa-circle-a",
          "fad fa-circle-ampersand",
          "fad fa-circle-arrow-down",
          "fad fa-circle-arrow-down-left",
          "fad fa-circle-arrow-down-right",
          "fad fa-circle-arrow-left",
          "fad fa-circle-arrow-right",
          "fad fa-circle-arrow-up",
          "fad fa-circle-arrow-up-left",
          "fad fa-circle-arrow-up-right",
          "fad fa-circle-b",
          "fad fa-circle-bolt",
          "fad fa-circle-book-open",
          "fad fa-circle-bookmark",
          "fad fa-circle-c",
          "fad fa-circle-calendar",
          "fad fa-circle-camera",
          "fad fa-circle-caret-down",
          "fad fa-circle-caret-left",
          "fad fa-circle-caret-right",
          "fad fa-circle-caret-up",
          "fad fa-circle-check",
          "fad fa-circle-chevron-down",
          "fad fa-circle-chevron-left",
          "fad fa-circle-chevron-right",
          "fad fa-circle-chevron-up",
          "fad fa-circle-d",
          "fad fa-circle-dashed",
          "fad fa-circle-divide",
          "fad fa-circle-dollar",
          "fad fa-circle-dollar-to-slot",
          "fad fa-circle-dot",
          "fad fa-circle-down",
          "fad fa-circle-down-left",
          "fad fa-circle-down-right",
          "fad fa-circle-e",
          "fad fa-circle-ellipsis",
          "fad fa-circle-ellipsis-vertical",
          "fad fa-circle-envelope",
          "fad fa-circle-euro",
          "fad fa-circle-exclamation",
          "fad fa-circle-exclamation-check",
          "fad fa-circle-f",
          "fad fa-circle-g",
          "fad fa-circle-h",
          "fad fa-circle-half",
          "fad fa-circle-half-stroke",
          "fad fa-circle-heart",
          "fad fa-circle-i",
          "fad fa-circle-info",
          "fad fa-circle-j",
          "fad fa-circle-k",
          "fad fa-circle-l",
          "fad fa-circle-left",
          "fad fa-circle-location-arrow",
          "fad fa-circle-m",
          "fad fa-circle-microphone",
          "fad fa-circle-microphone-lines",
          "fad fa-circle-minus",
          "fad fa-circle-n",
          "fad fa-circle-nodes",
          "fad fa-circle-notch",
          "fad fa-circle-o",
          "fad fa-circle-p",
          "fad fa-circle-parking",
          "fad fa-circle-pause",
          "fad fa-circle-phone",
          "fad fa-circle-phone-flip",
          "fad fa-circle-phone-hangup",
          "fad fa-circle-play",
          "fad fa-circle-plus",
          "fad fa-circle-q",
          "fad fa-circle-quarter",
          "fad fa-circle-quarter-stroke",
          "fad fa-circle-quarters",
          "fad fa-circle-question",
          "fad fa-circle-r",
          "fad fa-circle-radiation",
          "fad fa-circle-right",
          "fad fa-circle-s",
          "fad fa-circle-small",
          "fad fa-circle-sort",
          "fad fa-circle-sort-down",
          "fad fa-circle-sort-up",
          "fad fa-circle-star",
          "fad fa-circle-sterling",
          "fad fa-circle-stop",
          "fad fa-circle-t",
          "fad fa-circle-three-quarters",
          "fad fa-circle-three-quarters-stroke",
          "fad fa-circle-trash",
          "fad fa-circle-u",
          "fad fa-circle-up",
          "fad fa-circle-up-left",
          "fad fa-circle-up-right",
          "fad fa-circle-user",
          "fad fa-circle-v",
          "fad fa-circle-video",
          "fad fa-circle-w",
          "fad fa-circle-waveform-lines",
          "fad fa-circle-x",
          "fad fa-circle-xmark",
          "fad fa-circle-y",
          "fad fa-circle-yen",
          "fad fa-circle-z",
          "fad fa-citrus",
          "fad fa-citrus-slice",
          "fad fa-city",
          "fad fa-clapperboard",
          "fad fa-clapperboard-play",
          "fad fa-clarinet",
          "fad fa-claw-marks",
          "fad fa-clipboard",
          "fad fa-clipboard-check",
          "fad fa-clipboard-list",
          "fad fa-clipboard-list-check",
          "fad fa-clipboard-medical",
          "fad fa-clipboard-prescription",
          "fad fa-clipboard-question",
          "fad fa-clipboard-user",
          "fad fa-clock",
          "fad fa-clock-desk",
          "fad fa-clock-eight",
          "fad fa-clock-eight-thirty",
          "fad fa-clock-eleven",
          "fad fa-clock-eleven-thirty",
          "fad fa-clock-five",
          "fad fa-clock-five-thirty",
          "fad fa-clock-four-thirty",
          "fad fa-clock-nine",
          "fad fa-clock-nine-thirty",
          "fad fa-clock-one",
          "fad fa-clock-one-thirty",
          "fad fa-clock-rotate-left",
          "fad fa-clock-seven",
          "fad fa-clock-seven-thirty",
          "fad fa-clock-six",
          "fad fa-clock-six-thirty",
          "fad fa-clock-ten",
          "fad fa-clock-ten-thirty",
          "fad fa-clock-three",
          "fad fa-clock-three-thirty",
          "fad fa-clock-twelve",
          "fad fa-clock-twelve-thirty",
          "fad fa-clock-two",
          "fad fa-clock-two-thirty",
          "fad fa-clone",
          "fad fa-closed-captioning",
          "fad fa-closed-captioning-slash",
          "fad fa-clothes-hanger",
          "fad fa-cloud",
          "fad fa-cloud-arrow-down",
          "fad fa-cloud-arrow-up",
          "fad fa-cloud-bolt",
          "fad fa-cloud-bolt-moon",
          "fad fa-cloud-bolt-sun",
          "fad fa-cloud-check",
          "fad fa-cloud-drizzle",
          "fad fa-cloud-exclamation",
          "fad fa-cloud-fog",
          "fad fa-cloud-hail",
          "fad fa-cloud-hail-mixed",
          "fad fa-cloud-meatball",
          "fad fa-cloud-minus",
          "fad fa-cloud-moon",
          "fad fa-cloud-moon-rain",
          "fad fa-cloud-music",
          "fad fa-cloud-plus",
          "fad fa-cloud-question",
          "fad fa-cloud-rain",
          "fad fa-cloud-rainbow",
          "fad fa-cloud-showers",
          "fad fa-cloud-showers-heavy",
          "fad fa-cloud-showers-water",
          "fad fa-cloud-slash",
          "fad fa-cloud-sleet",
          "fad fa-cloud-snow",
          "fad fa-cloud-sun",
          "fad fa-cloud-sun-rain",
          "fad fa-cloud-word",
          "fad fa-cloud-xmark",
          "fad fa-clouds",
          "fad fa-clouds-moon",
          "fad fa-clouds-sun",
          "fad fa-clover",
          "fad fa-club",
          "fad fa-coconut",
          "fad fa-code",
          "fad fa-code-branch",
          "fad fa-code-commit",
          "fad fa-code-compare",
          "fad fa-code-fork",
          "fad fa-code-merge",
          "fad fa-code-pull-request",
          "fad fa-code-pull-request-closed",
          "fad fa-code-pull-request-draft",
          "fad fa-code-simple",
          "fad fa-coffee-bean",
          "fad fa-coffee-beans",
          "fad fa-coffee-pot",
          "fad fa-coffin",
          "fad fa-coffin-cross",
          "fad fa-coin",
          "fad fa-coin-blank",
          "fad fa-coin-front",
          "fad fa-coin-vertical",
          "fad fa-coins",
          "fad fa-colon",
          "fad fa-colon-sign",
          "fad fa-columns-3",
          "fad fa-comet",
          "fad fa-comma",
          "fad fa-command",
          "fad fa-comment",
          "fad fa-comment-arrow-down",
          "fad fa-comment-arrow-up",
          "fad fa-comment-arrow-up-right",
          "fad fa-comment-captions",
          "fad fa-comment-check",
          "fad fa-comment-code",
          "fad fa-comment-dollar",
          "fad fa-comment-dots",
          "fad fa-comment-exclamation",
          "fad fa-comment-heart",
          "fad fa-comment-image",
          "fad fa-comment-lines",
          "fad fa-comment-medical",
          "fad fa-comment-middle",
          "fad fa-comment-middle-top",
          "fad fa-comment-minus",
          "fad fa-comment-music",
          "fad fa-comment-pen",
          "fad fa-comment-plus",
          "fad fa-comment-question",
          "fad fa-comment-quote",
          "fad fa-comment-slash",
          "fad fa-comment-smile",
          "fad fa-comment-sms",
          "fad fa-comment-text",
          "fad fa-comment-xmark",
          "fad fa-comments",
          "fad fa-comments-dollar",
          "fad fa-comments-question",
          "fad fa-comments-question-check",
          "fad fa-compact-disc",
          "fad fa-compass",
          "fad fa-compass-drafting",
          "fad fa-compass-slash",
          "fad fa-compress",
          "fad fa-compress-wide",
          "fad fa-computer",
          "fad fa-computer-classic",
          "fad fa-computer-mouse",
          "fad fa-computer-mouse-scrollwheel",
          "fad fa-computer-speaker",
          "fad fa-container-storage",
          "fad fa-conveyor-belt",
          "fad fa-conveyor-belt-boxes",
          "fad fa-conveyor-belt-empty",
          "fad fa-cookie",
          "fad fa-cookie-bite",
          "fad fa-copy",
          "fad fa-copyright",
          "fad fa-corn",
          "fad fa-corner",
          "fad fa-couch",
          "fad fa-cow",
          "fad fa-cowbell",
          "fad fa-cowbell-circle-plus",
          "fad fa-crab",
          "fad fa-crate-apple",
          "fad fa-crate-empty",
          "fad fa-credit-card",
          "fad fa-credit-card-blank",
          "fad fa-credit-card-front",
          "fad fa-cricket-bat-ball",
          "fad fa-croissant",
          "fad fa-crop",
          "fad fa-crop-simple",
          "fad fa-cross",
          "fad fa-crosshairs",
          "fad fa-crosshairs-simple",
          "fad fa-crow",
          "fad fa-crown",
          "fad fa-crutch",
          "fad fa-crutches",
          "fad fa-cruzeiro-sign",
          "fad fa-crystal-ball",
          "fad fa-cube",
          "fad fa-cubes",
          "fad fa-cubes-stacked",
          "fad fa-cucumber",
          "fad fa-cup-straw",
          "fad fa-cup-straw-swoosh",
          "fad fa-cup-togo",
          "fad fa-cupcake",
          "fad fa-curling-stone",
          "fad fa-custard",
          "fad fa-d",
          "fad fa-dagger",
          "fad fa-dash",
          "fad fa-database",
          "fad fa-deer",
          "fad fa-deer-rudolph",
          "fad fa-delete-left",
          "fad fa-delete-right",
          "fad fa-democrat",
          "fad fa-desktop",
          "fad fa-desktop-arrow-down",
          "fad fa-dharmachakra",
          "fad fa-diagram-cells",
          "fad fa-diagram-lean-canvas",
          "fad fa-diagram-nested",
          "fad fa-diagram-next",
          "fad fa-diagram-predecessor",
          "fad fa-diagram-previous",
          "fad fa-diagram-project",
          "fad fa-diagram-sankey",
          "fad fa-diagram-subtask",
          "fad fa-diagram-successor",
          "fad fa-diagram-venn",
          "fad fa-dial",
          "fad fa-dial-high",
          "fad fa-dial-low",
          "fad fa-dial-max",
          "fad fa-dial-med",
          "fad fa-dial-med-low",
          "fad fa-dial-min",
          "fad fa-dial-off",
          "fad fa-diamond",
          "fad fa-diamond-exclamation",
          "fad fa-diamond-half",
          "fad fa-diamond-half-stroke",
          "fad fa-diamond-turn-right",
          "fad fa-dice",
          "fad fa-dice-d10",
          "fad fa-dice-d12",
          "fad fa-dice-d20",
          "fad fa-dice-d4",
          "fad fa-dice-d6",
          "fad fa-dice-d8",
          "fad fa-dice-five",
          "fad fa-dice-four",
          "fad fa-dice-one",
          "fad fa-dice-six",
          "fad fa-dice-three",
          "fad fa-dice-two",
          "fad fa-diploma",
          "fad fa-disc-drive",
          "fad fa-disease",
          "fad fa-display",
          "fad fa-display-arrow-down",
          "fad fa-display-code",
          "fad fa-display-medical",
          "fad fa-display-slash",
          "fad fa-distribute-spacing-horizontal",
          "fad fa-distribute-spacing-vertical",
          "fad fa-ditto",
          "fad fa-divide",
          "fad fa-dna",
          "fad fa-do-not-enter",
          "fad fa-dog",
          "fad fa-dog-leashed",
          "fad fa-dollar-sign",
          "fad fa-dolly",
          "fad fa-dolly-empty",
          "fad fa-dolphin",
          "fad fa-dong-sign",
          "fad fa-donut",
          "fad fa-door-closed",
          "fad fa-door-open",
          "fad fa-dove",
          "fad fa-down",
          "fad fa-down-from-dotted-line",
          "fad fa-down-from-line",
          "fad fa-down-left",
          "fad fa-down-left-and-up-right-to-center",
          "fad fa-down-long",
          "fad fa-down-right",
          "fad fa-down-to-bracket",
          "fad fa-down-to-dotted-line",
          "fad fa-down-to-line",
          "fad fa-download",
          "fad fa-dragon",
          "fad fa-draw-circle",
          "fad fa-draw-polygon",
          "fad fa-draw-square",
          "fad fa-dreidel",
          "fad fa-drone",
          "fad fa-drone-front",
          "fad fa-droplet",
          "fad fa-droplet-degree",
          "fad fa-droplet-percent",
          "fad fa-droplet-slash",
          "fad fa-drum",
          "fad fa-drum-steelpan",
          "fad fa-drumstick",
          "fad fa-drumstick-bite",
          "fad fa-dryer",
          "fad fa-dryer-heat",
          "fad fa-duck",
          "fad fa-dumbbell",
          "fad fa-dumpster",
          "fad fa-dumpster-fire",
          "fad fa-dungeon",
          "fad fa-e",
          "fad fa-ear",
          "fad fa-ear-deaf",
          "fad fa-ear-listen",
          "fad fa-ear-muffs",
          "fad fa-earth-africa",
          "fad fa-earth-americas",
          "fad fa-earth-asia",
          "fad fa-earth-europe",
          "fad fa-earth-oceania",
          "fad fa-eclipse",
          "fad fa-egg",
          "fad fa-egg-fried",
          "fad fa-eggplant",
          "fad fa-eject",
          "fad fa-elephant",
          "fad fa-elevator",
          "fad fa-ellipsis",
          "fad fa-ellipsis-stroke",
          "fad fa-ellipsis-stroke-vertical",
          "fad fa-ellipsis-vertical",
          "fad fa-empty-set",
          "fad fa-engine",
          "fad fa-engine-warning",
          "fad fa-envelope",
          "fad fa-envelope-circle-check",
          "fad fa-envelope-dot",
          "fad fa-envelope-open",
          "fad fa-envelope-open-dollar",
          "fad fa-envelope-open-text",
          "fad fa-envelopes",
          "fad fa-envelopes-bulk",
          "fad fa-equals",
          "fad fa-eraser",
          "fad fa-escalator",
          "fad fa-ethernet",
          "fad fa-euro-sign",
          "fad fa-exclamation",
          "fad fa-expand",
          "fad fa-expand-wide",
          "fad fa-explosion",
          "fad fa-eye",
          "fad fa-eye-dropper",
          "fad fa-eye-dropper-full",
          "fad fa-eye-dropper-half",
          "fad fa-eye-evil",
          "fad fa-eye-low-vision",
          "fad fa-eye-slash",
          "fad fa-eyes",
          "fad fa-f",
          "fad fa-face-angry",
          "fad fa-face-angry-horns",
          "fad fa-face-anguished",
          "fad fa-face-anxious-sweat",
          "fad fa-face-astonished",
          "fad fa-face-awesome",
          "fad fa-face-beam-hand-over-mouth",
          "fad fa-face-clouds",
          "fad fa-face-confounded",
          "fad fa-face-confused",
          "fad fa-face-cowboy-hat",
          "fad fa-face-diagonal-mouth",
          "fad fa-face-disappointed",
          "fad fa-face-disguise",
          "fad fa-face-dizzy",
          "fad fa-face-dotted",
          "fad fa-face-downcast-sweat",
          "fad fa-face-drooling",
          "fad fa-face-exhaling",
          "fad fa-face-explode",
          "fad fa-face-expressionless",
          "fad fa-face-eyes-xmarks",
          "fad fa-face-fearful",
          "fad fa-face-flushed",
          "fad fa-face-frown",
          "fad fa-face-frown-open",
          "fad fa-face-frown-slight",
          "fad fa-face-glasses",
          "fad fa-face-grimace",
          "fad fa-face-grin",
          "fad fa-face-grin-beam",
          "fad fa-face-grin-beam-sweat",
          "fad fa-face-grin-hearts",
          "fad fa-face-grin-squint",
          "fad fa-face-grin-squint-tears",
          "fad fa-face-grin-stars",
          "fad fa-face-grin-tears",
          "fad fa-face-grin-tongue",
          "fad fa-face-grin-tongue-squint",
          "fad fa-face-grin-tongue-wink",
          "fad fa-face-grin-wide",
          "fad fa-face-grin-wink",
          "fad fa-face-hand-over-mouth",
          "fad fa-face-hand-peeking",
          "fad fa-face-hand-yawn",
          "fad fa-face-head-bandage",
          "fad fa-face-holding-back-tears",
          "fad fa-face-hushed",
          "fad fa-face-icicles",
          "fad fa-face-kiss",
          "fad fa-face-kiss-beam",
          "fad fa-face-kiss-closed-eyes",
          "fad fa-face-kiss-wink-heart",
          "fad fa-face-laugh",
          "fad fa-face-laugh-beam",
          "fad fa-face-laugh-squint",
          "fad fa-face-laugh-wink",
          "fad fa-face-lying",
          "fad fa-face-mask",
          "fad fa-face-meh",
          "fad fa-face-meh-blank",
          "fad fa-face-melting",
          "fad fa-face-monocle",
          "fad fa-face-nauseated",
          "fad fa-face-nose-steam",
          "fad fa-face-party",
          "fad fa-face-pensive",
          "fad fa-face-persevering",
          "fad fa-face-pleading",
          "fad fa-face-pouting",
          "fad fa-face-raised-eyebrow",
          "fad fa-face-relieved",
          "fad fa-face-rolling-eyes",
          "fad fa-face-sad-cry",
          "fad fa-face-sad-sweat",
          "fad fa-face-sad-tear",
          "fad fa-face-saluting",
          "fad fa-face-scream",
          "fad fa-face-shush",
          "fad fa-face-sleeping",
          "fad fa-face-sleepy",
          "fad fa-face-smile",
          "fad fa-face-smile-beam",
          "fad fa-face-smile-halo",
          "fad fa-face-smile-hearts",
          "fad fa-face-smile-horns",
          "fad fa-face-smile-plus",
          "fad fa-face-smile-relaxed",
          "fad fa-face-smile-tear",
          "fad fa-face-smile-tongue",
          "fad fa-face-smile-upside-down",
          "fad fa-face-smile-wink",
          "fad fa-face-smiling-hands",
          "fad fa-face-smirking",
          "fad fa-face-spiral-eyes",
          "fad fa-face-sunglasses",
          "fad fa-face-surprise",
          "fad fa-face-swear",
          "fad fa-face-thermometer",
          "fad fa-face-thinking",
          "fad fa-face-tired",
          "fad fa-face-tissue",
          "fad fa-face-tongue-money",
          "fad fa-face-tongue-sweat",
          "fad fa-face-unamused",
          "fad fa-face-viewfinder",
          "fad fa-face-vomit",
          "fad fa-face-weary",
          "fad fa-face-woozy",
          "fad fa-face-worried",
          "fad fa-face-zany",
          "fad fa-face-zipper",
          "fad fa-falafel",
          "fad fa-family",
          "fad fa-family-dress",
          "fad fa-family-pants",
          "fad fa-fan",
          "fad fa-fan-table",
          "fad fa-farm",
          "fad fa-faucet",
          "fad fa-faucet-drip",
          "fad fa-fax",
          "fad fa-feather",
          "fad fa-feather-pointed",
          "fad fa-fence",
          "fad fa-ferris-wheel",
          "fad fa-ferry",
          "fad fa-field-hockey-stick-ball",
          "fad fa-file",
          "fad fa-file-arrow-down",
          "fad fa-file-arrow-up",
          "fad fa-file-audio",
          "fad fa-file-binary",
          "fad fa-file-certificate",
          "fad fa-file-chart-column",
          "fad fa-file-chart-pie",
          "fad fa-file-check",
          "fad fa-file-circle-check",
          "fad fa-file-circle-exclamation",
          "fad fa-file-circle-info",
          "fad fa-file-circle-minus",
          "fad fa-file-circle-plus",
          "fad fa-file-circle-question",
          "fad fa-file-circle-xmark",
          "fad fa-file-code",
          "fad fa-file-contract",
          "fad fa-file-csv",
          "fad fa-file-dashed-line",
          "fad fa-file-excel",
          "fad fa-file-exclamation",
          "fad fa-file-export",
          "fad fa-file-heart",
          "fad fa-file-image",
          "fad fa-file-import",
          "fad fa-file-invoice",
          "fad fa-file-invoice-dollar",
          "fad fa-file-lines",
          "fad fa-file-lock",
          "fad fa-file-magnifying-glass",
          "fad fa-file-medical",
          "fad fa-file-minus",
          "fad fa-file-music",
          "fad fa-file-pdf",
          "fad fa-file-pen",
          "fad fa-file-plus",
          "fad fa-file-plus-minus",
          "fad fa-file-powerpoint",
          "fad fa-file-prescription",
          "fad fa-file-shield",
          "fad fa-file-signature",
          "fad fa-file-slash",
          "fad fa-file-spreadsheet",
          "fad fa-file-user",
          "fad fa-file-video",
          "fad fa-file-waveform",
          "fad fa-file-word",
          "fad fa-file-xmark",
          "fad fa-file-zipper",
          "fad fa-files",
          "fad fa-files-medical",
          "fad fa-fill",
          "fad fa-fill-drip",
          "fad fa-film",
          "fad fa-film-canister",
          "fad fa-film-simple",
          "fad fa-film-slash",
          "fad fa-films",
          "fad fa-filter",
          "fad fa-filter-circle-dollar",
          "fad fa-filter-circle-xmark",
          "fad fa-filter-list",
          "fad fa-filter-slash",
          "fad fa-filters",
          "fad fa-fingerprint",
          "fad fa-fire",
          "fad fa-fire-burner",
          "fad fa-fire-extinguisher",
          "fad fa-fire-flame",
          "fad fa-fire-flame-curved",
          "fad fa-fire-flame-simple",
          "fad fa-fire-hydrant",
          "fad fa-fire-smoke",
          "fad fa-fireplace",
          "fad fa-fish",
          "fad fa-fish-bones",
          "fad fa-fish-cooked",
          "fad fa-fish-fins",
          "fad fa-fishing-rod",
          "fad fa-flag",
          "fad fa-flag-checkered",
          "fad fa-flag-pennant",
          "fad fa-flag-swallowtail",
          "fad fa-flag-usa",
          "fad fa-flashlight",
          "fad fa-flask",
          "fad fa-flask-round-poison",
          "fad fa-flask-round-potion",
          "fad fa-flask-vial",
          "fad fa-flatbread",
          "fad fa-flatbread-stuffed",
          "fad fa-floppy-disk",
          "fad fa-floppy-disk-circle-arrow-right",
          "fad fa-floppy-disk-circle-xmark",
          "fad fa-floppy-disk-pen",
          "fad fa-floppy-disks",
          "fad fa-florin-sign",
          "fad fa-flower",
          "fad fa-flower-daffodil",
          "fad fa-flower-tulip",
          "fad fa-flute",
          "fad fa-flux-capacitor",
          "fad fa-flying-disc",
          "fad fa-folder",
          "fad fa-folder-arrow-down",
          "fad fa-folder-arrow-up",
          "fad fa-folder-bookmark",
          "fad fa-folder-closed",
          "fad fa-folder-gear",
          "fad fa-folder-grid",
          "fad fa-folder-heart",
          "fad fa-folder-image",
          "fad fa-folder-magnifying-glass",
          "fad fa-folder-medical",
          "fad fa-folder-minus",
          "fad fa-folder-music",
          "fad fa-folder-open",
          "fad fa-folder-plus",
          "fad fa-folder-tree",
          "fad fa-folder-user",
          "fad fa-folder-xmark",
          "fad fa-folders",
          "fad fa-fondue-pot",
          "fad fa-font",
          "fad fa-font-awesome",
          "fad fa-font-case",
          "fad fa-football",
          "fad fa-football-helmet",
          "fad fa-fork",
          "fad fa-fork-knife",
          "fad fa-forklift",
          "fad fa-fort",
          "fad fa-forward",
          "fad fa-forward-fast",
          "fad fa-forward-step",
          "fad fa-frame",
          "fad fa-franc-sign",
          "fad fa-french-fries",
          "fad fa-frog",
          "fad fa-function",
          "fad fa-futbol",
          "fad fa-g",
          "fad fa-galaxy",
          "fad fa-gallery-thumbnails",
          "fad fa-game-board",
          "fad fa-game-board-simple",
          "fad fa-game-console-handheld",
          "fad fa-game-console-handheld-crank",
          "fad fa-gamepad",
          "fad fa-gamepad-modern",
          "fad fa-garage",
          "fad fa-garage-car",
          "fad fa-garage-open",
          "fad fa-garlic",
          "fad fa-gas-pump",
          "fad fa-gas-pump-slash",
          "fad fa-gauge",
          "fad fa-gauge-circle-bolt",
          "fad fa-gauge-circle-minus",
          "fad fa-gauge-circle-plus",
          "fad fa-gauge-high",
          "fad fa-gauge-low",
          "fad fa-gauge-max",
          "fad fa-gauge-min",
          "fad fa-gauge-simple",
          "fad fa-gauge-simple-high",
          "fad fa-gauge-simple-low",
          "fad fa-gauge-simple-max",
          "fad fa-gauge-simple-min",
          "fad fa-gavel",
          "fad fa-gear",
          "fad fa-gears",
          "fad fa-gem",
          "fad fa-genderless",
          "fad fa-ghost",
          "fad fa-gif",
          "fad fa-gift",
          "fad fa-gift-card",
          "fad fa-gifts",
          "fad fa-gingerbread-man",
          "fad fa-glass",
          "fad fa-glass-citrus",
          "fad fa-glass-empty",
          "fad fa-glass-half",
          "fad fa-glass-water",
          "fad fa-glass-water-droplet",
          "fad fa-glasses",
          "fad fa-glasses-round",
          "fad fa-globe",
          "fad fa-globe-snow",
          "fad fa-globe-stand",
          "fad fa-goal-net",
          "fad fa-golf-ball-tee",
          "fad fa-golf-club",
          "fad fa-golf-flag-hole",
          "fad fa-gopuram",
          "fad fa-graduation-cap",
          "fad fa-gramophone",
          "fad fa-grapes",
          "fad fa-grate",
          "fad fa-grate-droplet",
          "fad fa-greater-than",
          "fad fa-greater-than-equal",
          "fad fa-grid",
          "fad fa-grid-2",
          "fad fa-grid-2-plus",
          "fad fa-grid-4",
          "fad fa-grid-5",
          "fad fa-grid-dividers",
          "fad fa-grid-horizontal",
          "fad fa-grill",
          "fad fa-grill-fire",
          "fad fa-grill-hot",
          "fad fa-grip",
          "fad fa-grip-dots",
          "fad fa-grip-dots-vertical",
          "fad fa-grip-lines",
          "fad fa-grip-lines-vertical",
          "fad fa-grip-vertical",
          "fad fa-group-arrows-rotate",
          "fad fa-guarani-sign",
          "fad fa-guitar",
          "fad fa-guitar-electric",
          "fad fa-guitars",
          "fad fa-gun",
          "fad fa-gun-slash",
          "fad fa-gun-squirt",
          "fad fa-h",
          "fad fa-h1",
          "fad fa-h2",
          "fad fa-h3",
          "fad fa-h4",
          "fad fa-h5",
          "fad fa-h6",
          "fad fa-hammer",
          "fad fa-hammer-crash",
          "fad fa-hammer-war",
          "fad fa-hamsa",
          "fad fa-hand",
          "fad fa-hand-back-fist",
          "fad fa-hand-back-point-down",
          "fad fa-hand-back-point-left",
          "fad fa-hand-back-point-ribbon",
          "fad fa-hand-back-point-right",
          "fad fa-hand-back-point-up",
          "fad fa-hand-dots",
          "fad fa-hand-fingers-crossed",
          "fad fa-hand-fist",
          "fad fa-hand-heart",
          "fad fa-hand-holding",
          "fad fa-hand-holding-box",
          "fad fa-hand-holding-dollar",
          "fad fa-hand-holding-droplet",
          "fad fa-hand-holding-hand",
          "fad fa-hand-holding-heart",
          "fad fa-hand-holding-magic",
          "fad fa-hand-holding-medical",
          "fad fa-hand-holding-seedling",
          "fad fa-hand-holding-skull",
          "fad fa-hand-horns",
          "fad fa-hand-lizard",
          "fad fa-hand-love",
          "fad fa-hand-middle-finger",
          "fad fa-hand-peace",
          "fad fa-hand-point-down",
          "fad fa-hand-point-left",
          "fad fa-hand-point-ribbon",
          "fad fa-hand-point-right",
          "fad fa-hand-point-up",
          "fad fa-hand-pointer",
          "fad fa-hand-scissors",
          "fad fa-hand-sparkles",
          "fad fa-hand-spock",
          "fad fa-hand-wave",
          "fad fa-handcuffs",
          "fad fa-hands",
          "fad fa-hands-asl-interpreting",
          "fad fa-hands-bound",
          "fad fa-hands-bubbles",
          "fad fa-hands-clapping",
          "fad fa-hands-holding",
          "fad fa-hands-holding-child",
          "fad fa-hands-holding-circle",
          "fad fa-hands-holding-diamond",
          "fad fa-hands-holding-dollar",
          "fad fa-hands-holding-heart",
          "fad fa-hands-praying",
          "fad fa-handshake",
          "fad fa-handshake-angle",
          "fad fa-handshake-simple",
          "fad fa-handshake-simple-slash",
          "fad fa-handshake-slash",
          "fad fa-hanukiah",
          "fad fa-hard-drive",
          "fad fa-hashtag",
          "fad fa-hashtag-lock",
          "fad fa-hat-chef",
          "fad fa-hat-cowboy",
          "fad fa-hat-cowboy-side",
          "fad fa-hat-santa",
          "fad fa-hat-winter",
          "fad fa-hat-witch",
          "fad fa-hat-wizard",
          "fad fa-head-side",
          "fad fa-head-side-brain",
          "fad fa-head-side-cough",
          "fad fa-head-side-cough-slash",
          "fad fa-head-side-goggles",
          "fad fa-head-side-headphones",
          "fad fa-head-side-heart",
          "fad fa-head-side-mask",
          "fad fa-head-side-medical",
          "fad fa-head-side-virus",
          "fad fa-heading",
          "fad fa-headphones",
          "fad fa-headphones-simple",
          "fad fa-headset",
          "fad fa-heart",
          "fad fa-heart-circle-bolt",
          "fad fa-heart-circle-check",
          "fad fa-heart-circle-exclamation",
          "fad fa-heart-circle-minus",
          "fad fa-heart-circle-plus",
          "fad fa-heart-circle-xmark",
          "fad fa-heart-crack",
          "fad fa-heart-half",
          "fad fa-heart-half-stroke",
          "fad fa-heart-pulse",
          "fad fa-heat",
          "fad fa-helicopter",
          "fad fa-helicopter-symbol",
          "fad fa-helmet-battle",
          "fad fa-helmet-safety",
          "fad fa-helmet-un",
          "fad fa-hexagon",
          "fad fa-hexagon-check",
          "fad fa-hexagon-divide",
          "fad fa-hexagon-exclamation",
          "fad fa-hexagon-image",
          "fad fa-hexagon-minus",
          "fad fa-hexagon-plus",
          "fad fa-hexagon-vertical-nft",
          "fad fa-hexagon-vertical-nft-slanted",
          "fad fa-hexagon-xmark",
          "fad fa-high-definition",
          "fad fa-highlighter",
          "fad fa-highlighter-line",
          "fad fa-hill-avalanche",
          "fad fa-hill-rockslide",
          "fad fa-hippo",
          "fad fa-hockey-mask",
          "fad fa-hockey-puck",
          "fad fa-hockey-stick-puck",
          "fad fa-hockey-sticks",
          "fad fa-holly-berry",
          "fad fa-honey-pot",
          "fad fa-hood-cloak",
          "fad fa-horizontal-rule",
          "fad fa-horse",
          "fad fa-horse-head",
          "fad fa-horse-saddle",
          "fad fa-hose",
          "fad fa-hose-reel",
          "fad fa-hospital",
          "fad fa-hospital-user",
          "fad fa-hospitals",
          "fad fa-hot-tub-person",
          "fad fa-hotdog",
          "fad fa-hotel",
          "fad fa-hourglass",
          "fad fa-hourglass-clock",
          "fad fa-hourglass-end",
          "fad fa-hourglass-half",
          "fad fa-hourglass-start",
          "fad fa-house",
          "fad fa-house-blank",
          "fad fa-house-building",
          "fad fa-house-chimney",
          "fad fa-house-chimney-blank",
          "fad fa-house-chimney-crack",
          "fad fa-house-chimney-heart",
          "fad fa-house-chimney-medical",
          "fad fa-house-chimney-user",
          "fad fa-house-chimney-window",
          "fad fa-house-circle-check",
          "fad fa-house-circle-exclamation",
          "fad fa-house-circle-xmark",
          "fad fa-house-crack",
          "fad fa-house-day",
          "fad fa-house-fire",
          "fad fa-house-flag",
          "fad fa-house-flood-water",
          "fad fa-house-flood-water-circle-arrow-right",
          "fad fa-house-heart",
          "fad fa-house-laptop",
          "fad fa-house-lock",
          "fad fa-house-medical",
          "fad fa-house-medical-circle-check",
          "fad fa-house-medical-circle-exclamation",
          "fad fa-house-medical-circle-xmark",
          "fad fa-house-medical-flag",
          "fad fa-house-night",
          "fad fa-house-person-leave",
          "fad fa-house-person-return",
          "fad fa-house-signal",
          "fad fa-house-tree",
          "fad fa-house-tsunami",
          "fad fa-house-turret",
          "fad fa-house-user",
          "fad fa-house-water",
          "fad fa-house-window",
          "fad fa-hryvnia-sign",
          "fad fa-hundred-points",
          "fad fa-hurricane",
          "fad fa-hyphen",
          "fad fa-i",
          "fad fa-i-cursor",
          "fad fa-ice-cream",
          "fad fa-ice-skate",
          "fad fa-icicles",
          "fad fa-icons",
          "fad fa-id-badge",
          "fad fa-id-card",
          "fad fa-id-card-clip",
          "fad fa-igloo",
          "fad fa-image",
          "fad fa-image-landscape",
          "fad fa-image-polaroid",
          "fad fa-image-polaroid-user",
          "fad fa-image-portrait",
          "fad fa-image-slash",
          "fad fa-image-user",
          "fad fa-images",
          "fad fa-images-user",
          "fad fa-inbox",
          "fad fa-inbox-full",
          "fad fa-inbox-in",
          "fad fa-inbox-out",
          "fad fa-inboxes",
          "fad fa-indent",
          "fad fa-indian-rupee-sign",
          "fad fa-industry",
          "fad fa-industry-windows",
          "fad fa-infinity",
          "fad fa-info",
          "fad fa-inhaler",
          "fad fa-input-numeric",
          "fad fa-input-pipe",
          "fad fa-input-text",
          "fad fa-integral",
          "fad fa-interrobang",
          "fad fa-intersection",
          "fad fa-island-tropical",
          "fad fa-italic",
          "fad fa-j",
          "fad fa-jack-o-lantern",
          "fad fa-jar",
          "fad fa-jar-wheat",
          "fad fa-jedi",
          "fad fa-jet-fighter",
          "fad fa-jet-fighter-up",
          "fad fa-joint",
          "fad fa-joystick",
          "fad fa-jug",
          "fad fa-jug-detergent",
          "fad fa-k",
          "fad fa-kaaba",
          "fad fa-kazoo",
          "fad fa-kerning",
          "fad fa-key",
          "fad fa-key-skeleton",
          "fad fa-key-skeleton-left-right",
          "fad fa-keyboard",
          "fad fa-keyboard-brightness",
          "fad fa-keyboard-brightness-low",
          "fad fa-keyboard-down",
          "fad fa-keyboard-left",
          "fad fa-keynote",
          "fad fa-khanda",
          "fad fa-kidneys",
          "fad fa-kip-sign",
          "fad fa-kit-medical",
          "fad fa-kitchen-set",
          "fad fa-kite",
          "fad fa-kiwi-bird",
          "fad fa-kiwi-fruit",
          "fad fa-knife",
          "fad fa-knife-kitchen",
          "fad fa-l",
          "fad fa-lacrosse-stick",
          "fad fa-lacrosse-stick-ball",
          "fad fa-lambda",
          "fad fa-lamp",
          "fad fa-lamp-desk",
          "fad fa-lamp-floor",
          "fad fa-lamp-street",
          "fad fa-land-mine-on",
          "fad fa-landmark",
          "fad fa-landmark-dome",
          "fad fa-landmark-flag",
          "fad fa-language",
          "fad fa-laptop",
          "fad fa-laptop-arrow-down",
          "fad fa-laptop-code",
          "fad fa-laptop-file",
          "fad fa-laptop-medical",
          "fad fa-laptop-mobile",
          "fad fa-laptop-slash",
          "fad fa-lari-sign",
          "fad fa-lasso",
          "fad fa-lasso-sparkles",
          "fad fa-layer-group",
          "fad fa-layer-minus",
          "fad fa-layer-plus",
          "fad fa-leaf",
          "fad fa-leaf-heart",
          "fad fa-leaf-maple",
          "fad fa-leaf-oak",
          "fad fa-leafy-green",
          "fad fa-left",
          "fad fa-left-from-line",
          "fad fa-left-long",
          "fad fa-left-long-to-line",
          "fad fa-left-right",
          "fad fa-left-to-line",
          "fad fa-lemon",
          "fad fa-less-than",
          "fad fa-less-than-equal",
          "fad fa-life-ring",
          "fad fa-light-ceiling",
          "fad fa-light-emergency",
          "fad fa-light-emergency-on",
          "fad fa-light-switch",
          "fad fa-light-switch-off",
          "fad fa-light-switch-on",
          "fad fa-lightbulb",
          "fad fa-lightbulb-cfl",
          "fad fa-lightbulb-cfl-on",
          "fad fa-lightbulb-dollar",
          "fad fa-lightbulb-exclamation",
          "fad fa-lightbulb-exclamation-on",
          "fad fa-lightbulb-on",
          "fad fa-lightbulb-slash",
          "fad fa-lights-holiday",
          "fad fa-line-columns",
          "fad fa-line-height",
          "fad fa-lines-leaning",
          "fad fa-link",
          "fad fa-link-horizontal",
          "fad fa-link-horizontal-slash",
          "fad fa-link-simple",
          "fad fa-link-simple-slash",
          "fad fa-link-slash",
          "fad fa-lips",
          "fad fa-lira-sign",
          "fad fa-list",
          "fad fa-list-check",
          "fad fa-list-dropdown",
          "fad fa-list-music",
          "fad fa-list-ol",
          "fad fa-list-radio",
          "fad fa-list-timeline",
          "fad fa-list-tree",
          "fad fa-list-ul",
          "fad fa-litecoin-sign",
          "fad fa-loader",
          "fad fa-lobster",
          "fad fa-location-arrow",
          "fad fa-location-check",
          "fad fa-location-crosshairs",
          "fad fa-location-crosshairs-slash",
          "fad fa-location-dot",
          "fad fa-location-dot-slash",
          "fad fa-location-exclamation",
          "fad fa-location-minus",
          "fad fa-location-pen",
          "fad fa-location-pin",
          "fad fa-location-pin-lock",
          "fad fa-location-pin-slash",
          "fad fa-location-plus",
          "fad fa-location-question",
          "fad fa-location-smile",
          "fad fa-location-xmark",
          "fad fa-lock",
          "fad fa-lock-a",
          "fad fa-lock-hashtag",
          "fad fa-lock-keyhole",
          "fad fa-lock-keyhole-open",
          "fad fa-lock-open",
          "fad fa-locust",
          "fad fa-lollipop",
          "fad fa-loveseat",
          "fad fa-luchador-mask",
          "fad fa-lungs",
          "fad fa-lungs-virus",
          "fad fa-m",
          "fad fa-mace",
          "fad fa-magnet",
          "fad fa-magnifying-glass",
          "fad fa-magnifying-glass-arrow-right",
          "fad fa-magnifying-glass-chart",
          "fad fa-magnifying-glass-dollar",
          "fad fa-magnifying-glass-location",
          "fad fa-magnifying-glass-minus",
          "fad fa-magnifying-glass-plus",
          "fad fa-mailbox",
          "fad fa-mailbox-flag-up",
          "fad fa-manat-sign",
          "fad fa-mandolin",
          "fad fa-mango",
          "fad fa-manhole",
          "fad fa-map",
          "fad fa-map-location",
          "fad fa-map-location-dot",
          "fad fa-map-pin",
          "fad fa-marker",
          "fad fa-mars",
          "fad fa-mars-and-venus",
          "fad fa-mars-and-venus-burst",
          "fad fa-mars-double",
          "fad fa-mars-stroke",
          "fad fa-mars-stroke-right",
          "fad fa-mars-stroke-up",
          "fad fa-martini-glass",
          "fad fa-martini-glass-citrus",
          "fad fa-martini-glass-empty",
          "fad fa-mask",
          "fad fa-mask-face",
          "fad fa-mask-snorkel",
          "fad fa-mask-ventilator",
          "fad fa-masks-theater",
          "fad fa-mattress-pillow",
          "fad fa-maximize",
          "fad fa-meat",
          "fad fa-medal",
          "fad fa-megaphone",
          "fad fa-melon",
          "fad fa-melon-slice",
          "fad fa-memo",
          "fad fa-memo-circle-check",
          "fad fa-memo-circle-info",
          "fad fa-memo-pad",
          "fad fa-memory",
          "fad fa-menorah",
          "fad fa-mercury",
          "fad fa-merge",
          "fad fa-message",
          "fad fa-message-arrow-down",
          "fad fa-message-arrow-up",
          "fad fa-message-arrow-up-right",
          "fad fa-message-bot",
          "fad fa-message-captions",
          "fad fa-message-check",
          "fad fa-message-code",
          "fad fa-message-dollar",
          "fad fa-message-dots",
          "fad fa-message-exclamation",
          "fad fa-message-heart",
          "fad fa-message-image",
          "fad fa-message-lines",
          "fad fa-message-medical",
          "fad fa-message-middle",
          "fad fa-message-middle-top",
          "fad fa-message-minus",
          "fad fa-message-music",
          "fad fa-message-pen",
          "fad fa-message-plus",
          "fad fa-message-question",
          "fad fa-message-quote",
          "fad fa-message-slash",
          "fad fa-message-smile",
          "fad fa-message-sms",
          "fad fa-message-text",
          "fad fa-message-xmark",
          "fad fa-messages",
          "fad fa-messages-dollar",
          "fad fa-messages-question",
          "fad fa-meteor",
          "fad fa-meter",
          "fad fa-meter-bolt",
          "fad fa-meter-droplet",
          "fad fa-meter-fire",
          "fad fa-microchip",
          "fad fa-microchip-ai",
          "fad fa-microphone",
          "fad fa-microphone-lines",
          "fad fa-microphone-lines-slash",
          "fad fa-microphone-slash",
          "fad fa-microphone-stand",
          "fad fa-microscope",
          "fad fa-microwave",
          "fad fa-mill-sign",
          "fad fa-minimize",
          "fad fa-minus",
          "fad fa-mistletoe",
          "fad fa-mitten",
          "fad fa-mobile",
          "fad fa-mobile-button",
          "fad fa-mobile-notch",
          "fad fa-mobile-retro",
          "fad fa-mobile-screen",
          "fad fa-mobile-screen-button",
          "fad fa-mobile-signal",
          "fad fa-mobile-signal-out",
          "fad fa-money-bill",
          "fad fa-money-bill-1",
          "fad fa-money-bill-1-wave",
          "fad fa-money-bill-simple",
          "fad fa-money-bill-simple-wave",
          "fad fa-money-bill-transfer",
          "fad fa-money-bill-trend-up",
          "fad fa-money-bill-wave",
          "fad fa-money-bill-wheat",
          "fad fa-money-bills",
          "fad fa-money-bills-simple",
          "fad fa-money-check",
          "fad fa-money-check-dollar",
          "fad fa-money-check-dollar-pen",
          "fad fa-money-check-pen",
          "fad fa-money-from-bracket",
          "fad fa-money-simple-from-bracket",
          "fad fa-monitor-waveform",
          "fad fa-monkey",
          "fad fa-monument",
          "fad fa-moon",
          "fad fa-moon-cloud",
          "fad fa-moon-over-sun",
          "fad fa-moon-stars",
          "fad fa-moped",
          "fad fa-mortar-pestle",
          "fad fa-mosque",
          "fad fa-mosquito",
          "fad fa-mosquito-net",
          "fad fa-motorcycle",
          "fad fa-mound",
          "fad fa-mountain",
          "fad fa-mountain-city",
          "fad fa-mountain-sun",
          "fad fa-mountains",
          "fad fa-mouse-field",
          "fad fa-mp3-player",
          "fad fa-mug",
          "fad fa-mug-hot",
          "fad fa-mug-marshmallows",
          "fad fa-mug-saucer",
          "fad fa-mug-tea",
          "fad fa-mug-tea-saucer",
          "fad fa-mushroom",
          "fad fa-music",
          "fad fa-music-note",
          "fad fa-music-note-slash",
          "fad fa-music-slash",
          "fad fa-mustache",
          "fad fa-n",
          "fad fa-naira-sign",
          "fad fa-narwhal",
          "fad fa-nesting-dolls",
          "fad fa-network-wired",
          "fad fa-neuter",
          "fad fa-newspaper",
          "fad fa-nfc",
          "fad fa-nfc-lock",
          "fad fa-nfc-magnifying-glass",
          "fad fa-nfc-pen",
          "fad fa-nfc-signal",
          "fad fa-nfc-slash",
          "fad fa-nfc-symbol",
          "fad fa-nfc-trash",
          "fad fa-nose",
          "fad fa-not-equal",
          "fad fa-notdef",
          "fad fa-note",
          "fad fa-note-medical",
          "fad fa-note-sticky",
          "fad fa-notebook",
          "fad fa-notes",
          "fad fa-notes-medical",
          "fad fa-o",
          "fad fa-object-exclude",
          "fad fa-object-group",
          "fad fa-object-intersect",
          "fad fa-object-subtract",
          "fad fa-object-ungroup",
          "fad fa-object-union",
          "fad fa-objects-align-bottom",
          "fad fa-objects-align-center-horizontal",
          "fad fa-objects-align-center-vertical",
          "fad fa-objects-align-left",
          "fad fa-objects-align-right",
          "fad fa-objects-align-top",
          "fad fa-objects-column",
          "fad fa-octagon",
          "fad fa-octagon-check",
          "fad fa-octagon-divide",
          "fad fa-octagon-exclamation",
          "fad fa-octagon-minus",
          "fad fa-octagon-plus",
          "fad fa-octagon-xmark",
          "fad fa-oil-can",
          "fad fa-oil-can-drip",
          "fad fa-oil-temperature",
          "fad fa-oil-well",
          "fad fa-olive",
          "fad fa-olive-branch",
          "fad fa-om",
          "fad fa-omega",
          "fad fa-onion",
          "fad fa-option",
          "fad fa-ornament",
          "fad fa-otter",
          "fad fa-outdent",
          "fad fa-outlet",
          "fad fa-oven",
          "fad fa-overline",
          "fad fa-p",
          "fad fa-page",
          "fad fa-page-caret-down",
          "fad fa-page-caret-up",
          "fad fa-pager",
          "fad fa-paint-roller",
          "fad fa-paintbrush",
          "fad fa-paintbrush-fine",
          "fad fa-paintbrush-pencil",
          "fad fa-palette",
          "fad fa-pallet",
          "fad fa-pallet-box",
          "fad fa-pallet-boxes",
          "fad fa-pan-food",
          "fad fa-pan-frying",
          "fad fa-pancakes",
          "fad fa-panel-ews",
          "fad fa-panel-fire",
          "fad fa-panorama",
          "fad fa-paper-plane",
          "fad fa-paper-plane-top",
          "fad fa-paperclip",
          "fad fa-paperclip-vertical",
          "fad fa-parachute-box",
          "fad fa-paragraph",
          "fad fa-paragraph-left",
          "fad fa-party-bell",
          "fad fa-party-horn",
          "fad fa-passport",
          "fad fa-paste",
          "fad fa-pause",
          "fad fa-paw",
          "fad fa-paw-claws",
          "fad fa-paw-simple",
          "fad fa-peace",
          "fad fa-peach",
          "fad fa-peanut",
          "fad fa-peanuts",
          "fad fa-peapod",
          "fad fa-pear",
          "fad fa-pedestal",
          "fad fa-pegasus",
          "fad fa-pen",
          "fad fa-pen-circle",
          "fad fa-pen-clip",
          "fad fa-pen-clip-slash",
          "fad fa-pen-fancy",
          "fad fa-pen-fancy-slash",
          "fad fa-pen-field",
          "fad fa-pen-line",
          "fad fa-pen-nib",
          "fad fa-pen-nib-slash",
          "fad fa-pen-paintbrush",
          "fad fa-pen-ruler",
          "fad fa-pen-slash",
          "fad fa-pen-swirl",
          "fad fa-pen-to-square",
          "fad fa-pencil",
          "fad fa-pencil-mechanical",
          "fad fa-pencil-slash",
          "fad fa-people",
          "fad fa-people-arrows",
          "fad fa-people-carry-box",
          "fad fa-people-dress",
          "fad fa-people-dress-simple",
          "fad fa-people-group",
          "fad fa-people-line",
          "fad fa-people-pants",
          "fad fa-people-pants-simple",
          "fad fa-people-pulling",
          "fad fa-people-robbery",
          "fad fa-people-roof",
          "fad fa-people-simple",
          "fad fa-pepper",
          "fad fa-pepper-hot",
          "fad fa-percent",
          "fad fa-period",
          "fad fa-person",
          "fad fa-person-arrow-down-to-line",
          "fad fa-person-arrow-up-from-line",
          "fad fa-person-biking",
          "fad fa-person-biking-mountain",
          "fad fa-person-booth",
          "fad fa-person-breastfeeding",
          "fad fa-person-burst",
          "fad fa-person-cane",
          "fad fa-person-carry-box",
          "fad fa-person-chalkboard",
          "fad fa-person-circle-check",
          "fad fa-person-circle-exclamation",
          "fad fa-person-circle-minus",
          "fad fa-person-circle-plus",
          "fad fa-person-circle-question",
          "fad fa-person-circle-xmark",
          "fad fa-person-digging",
          "fad fa-person-dolly",
          "fad fa-person-dolly-empty",
          "fad fa-person-dots-from-line",
          "fad fa-person-dress",
          "fad fa-person-dress-burst",
          "fad fa-person-dress-simple",
          "fad fa-person-drowning",
          "fad fa-person-falling",
          "fad fa-person-falling-burst",
          "fad fa-person-from-portal",
          "fad fa-person-half-dress",
          "fad fa-person-harassing",
          "fad fa-person-hiking",
          "fad fa-person-military-pointing",
          "fad fa-person-military-rifle",
          "fad fa-person-military-to-person",
          "fad fa-person-pinball",
          "fad fa-person-praying",
          "fad fa-person-pregnant",
          "fad fa-person-rays",
          "fad fa-person-rifle",
          "fad fa-person-running",
          "fad fa-person-seat",
          "fad fa-person-seat-reclined",
          "fad fa-person-shelter",
          "fad fa-person-sign",
          "fad fa-person-simple",
          "fad fa-person-skating",
          "fad fa-person-ski-jumping",
          "fad fa-person-ski-lift",
          "fad fa-person-skiing",
          "fad fa-person-skiing-nordic",
          "fad fa-person-sledding",
          "fad fa-person-snowboarding",
          "fad fa-person-snowmobiling",
          "fad fa-person-swimming",
          "fad fa-person-through-window",
          "fad fa-person-to-door",
          "fad fa-person-to-portal",
          "fad fa-person-walking",
          "fad fa-person-walking-arrow-loop-left",
          "fad fa-person-walking-arrow-right",
          "fad fa-person-walking-dashed-line-arrow-right",
          "fad fa-person-walking-luggage",
          "fad fa-person-walking-with-cane",
          "fad fa-peseta-sign",
          "fad fa-peso-sign",
          "fad fa-phone",
          "fad fa-phone-arrow-down-left",
          "fad fa-phone-arrow-right",
          "fad fa-phone-arrow-up-right",
          "fad fa-phone-flip",
          "fad fa-phone-hangup",
          "fad fa-phone-intercom",
          "fad fa-phone-missed",
          "fad fa-phone-office",
          "fad fa-phone-plus",
          "fad fa-phone-rotary",
          "fad fa-phone-slash",
          "fad fa-phone-volume",
          "fad fa-phone-xmark",
          "fad fa-photo-film",
          "fad fa-photo-film-music",
          "fad fa-pi",
          "fad fa-piano",
          "fad fa-piano-keyboard",
          "fad fa-pickaxe",
          "fad fa-pickleball",
          "fad fa-pie",
          "fad fa-pig",
          "fad fa-piggy-bank",
          "fad fa-pills",
          "fad fa-pinata",
          "fad fa-pinball",
          "fad fa-pineapple",
          "fad fa-pipe",
          "fad fa-pipe-circle-check",
          "fad fa-pipe-collar",
          "fad fa-pipe-section",
          "fad fa-pipe-smoking",
          "fad fa-pipe-valve",
          "fad fa-pizza",
          "fad fa-pizza-slice",
          "fad fa-place-of-worship",
          "fad fa-plane",
          "fad fa-plane-arrival",
          "fad fa-plane-circle-check",
          "fad fa-plane-circle-exclamation",
          "fad fa-plane-circle-xmark",
          "fad fa-plane-departure",
          "fad fa-plane-engines",
          "fad fa-plane-lock",
          "fad fa-plane-prop",
          "fad fa-plane-slash",
          "fad fa-plane-tail",
          "fad fa-plane-up",
          "fad fa-plane-up-slash",
          "fad fa-planet-moon",
          "fad fa-planet-ringed",
          "fad fa-plant-wilt",
          "fad fa-plate-utensils",
          "fad fa-plate-wheat",
          "fad fa-play",
          "fad fa-play-pause",
          "fad fa-plug",
          "fad fa-plug-circle-bolt",
          "fad fa-plug-circle-check",
          "fad fa-plug-circle-exclamation",
          "fad fa-plug-circle-minus",
          "fad fa-plug-circle-plus",
          "fad fa-plug-circle-xmark",
          "fad fa-plus",
          "fad fa-plus-large",
          "fad fa-plus-minus",
          "fad fa-podcast",
          "fad fa-podium",
          "fad fa-podium-star",
          "fad fa-police-box",
          "fad fa-poll-people",
          "fad fa-pompebled",
          "fad fa-poo",
          "fad fa-poo-storm",
          "fad fa-pool-8-ball",
          "fad fa-poop",
          "fad fa-popcorn",
          "fad fa-popsicle",
          "fad fa-pot-food",
          "fad fa-potato",
          "fad fa-power-off",
          "fad fa-prescription",
          "fad fa-prescription-bottle",
          "fad fa-prescription-bottle-medical",
          "fad fa-prescription-bottle-pill",
          "fad fa-presentation-screen",
          "fad fa-pretzel",
          "fad fa-print",
          "fad fa-print-magnifying-glass",
          "fad fa-print-slash",
          "fad fa-projector",
          "fad fa-pump",
          "fad fa-pump-medical",
          "fad fa-pump-soap",
          "fad fa-pumpkin",
          "fad fa-puzzle",
          "fad fa-puzzle-piece",
          "fad fa-puzzle-piece-simple",
          "fad fa-q",
          "fad fa-qrcode",
          "fad fa-question",
          "fad fa-quote-left",
          "fad fa-quote-right",
          "fad fa-quotes",
          "fad fa-r",
          "fad fa-rabbit",
          "fad fa-rabbit-running",
          "fad fa-racquet",
          "fad fa-radar",
          "fad fa-radiation",
          "fad fa-radio",
          "fad fa-radio-tuner",
          "fad fa-rainbow",
          "fad fa-raindrops",
          "fad fa-ram",
          "fad fa-ramp-loading",
          "fad fa-ranking-star",
          "fad fa-raygun",
          "fad fa-receipt",
          "fad fa-record-vinyl",
          "fad fa-rectangle",
          "fad fa-rectangle-ad",
          "fad fa-rectangle-barcode",
          "fad fa-rectangle-code",
          "fad fa-rectangle-history",
          "fad fa-rectangle-history-circle-plus",
          "fad fa-rectangle-history-circle-user",
          "fad fa-rectangle-list",
          "fad fa-rectangle-pro",
          "fad fa-rectangle-terminal",
          "fad fa-rectangle-vertical",
          "fad fa-rectangle-vertical-history",
          "fad fa-rectangle-wide",
          "fad fa-rectangle-xmark",
          "fad fa-rectangles-mixed",
          "fad fa-recycle",
          "fad fa-reel",
          "fad fa-refrigerator",
          "fad fa-registered",
          "fad fa-repeat",
          "fad fa-repeat-1",
          "fad fa-reply",
          "fad fa-reply-all",
          "fad fa-reply-clock",
          "fad fa-republican",
          "fad fa-restroom",
          "fad fa-restroom-simple",
          "fad fa-retweet",
          "fad fa-rhombus",
          "fad fa-ribbon",
          "fad fa-right",
          "fad fa-right-from-bracket",
          "fad fa-right-from-line",
          "fad fa-right-left",
          "fad fa-right-long",
          "fad fa-right-long-to-line",
          "fad fa-right-to-bracket",
          "fad fa-right-to-line",
          "fad fa-ring",
          "fad fa-ring-diamond",
          "fad fa-rings-wedding",
          "fad fa-road",
          "fad fa-road-barrier",
          "fad fa-road-bridge",
          "fad fa-road-circle-check",
          "fad fa-road-circle-exclamation",
          "fad fa-road-circle-xmark",
          "fad fa-road-lock",
          "fad fa-road-spikes",
          "fad fa-robot",
          "fad fa-robot-astromech",
          "fad fa-rocket",
          "fad fa-rocket-launch",
          "fad fa-roller-coaster",
          "fad fa-rotate",
          "fad fa-rotate-exclamation",
          "fad fa-rotate-left",
          "fad fa-rotate-right",
          "fad fa-route",
          "fad fa-route-highway",
          "fad fa-route-interstate",
          "fad fa-router",
          "fad fa-rss",
          "fad fa-ruble-sign",
          "fad fa-rug",
          "fad fa-rugby-ball",
          "fad fa-ruler",
          "fad fa-ruler-combined",
          "fad fa-ruler-horizontal",
          "fad fa-ruler-triangle",
          "fad fa-ruler-vertical",
          "fad fa-rupee-sign",
          "fad fa-rupiah-sign",
          "fad fa-rv",
          "fad fa-s",
          "fad fa-sack",
          "fad fa-sack-dollar",
          "fad fa-sack-xmark",
          "fad fa-sailboat",
          "fad fa-salad",
          "fad fa-salt-shaker",
          "fad fa-sandwich",
          "fad fa-satellite",
          "fad fa-satellite-dish",
          "fad fa-sausage",
          "fad fa-saxophone",
          "fad fa-saxophone-fire",
          "fad fa-scale-balanced",
          "fad fa-scale-unbalanced",
          "fad fa-scale-unbalanced-flip",
          "fad fa-scalpel",
          "fad fa-scalpel-line-dashed",
          "fad fa-scanner-gun",
          "fad fa-scanner-image",
          "fad fa-scanner-keyboard",
          "fad fa-scanner-touchscreen",
          "fad fa-scarecrow",
          "fad fa-scarf",
          "fad fa-school",
          "fad fa-school-circle-check",
          "fad fa-school-circle-exclamation",
          "fad fa-school-circle-xmark",
          "fad fa-school-flag",
          "fad fa-school-lock",
          "fad fa-scissors",
          "fad fa-screen-users",
          "fad fa-screencast",
          "fad fa-screwdriver",
          "fad fa-screwdriver-wrench",
          "fad fa-scribble",
          "fad fa-scroll",
          "fad fa-scroll-old",
          "fad fa-scroll-torah",
          "fad fa-scrubber",
          "fad fa-scythe",
          "fad fa-sd-card",
          "fad fa-sd-cards",
          "fad fa-seal",
          "fad fa-seal-exclamation",
          "fad fa-seal-question",
          "fad fa-seat-airline",
          "fad fa-section",
          "fad fa-seedling",
          "fad fa-semicolon",
          "fad fa-send-back",
          "fad fa-send-backward",
          "fad fa-sensor",
          "fad fa-sensor-cloud",
          "fad fa-sensor-fire",
          "fad fa-sensor-on",
          "fad fa-sensor-triangle-exclamation",
          "fad fa-server",
          "fad fa-shapes",
          "fad fa-share",
          "fad fa-share-all",
          "fad fa-share-from-square",
          "fad fa-share-nodes",
          "fad fa-sheep",
          "fad fa-sheet-plastic",
          "fad fa-shekel-sign",
          "fad fa-shelves",
          "fad fa-shelves-empty",
          "fad fa-shield",
          "fad fa-shield-cat",
          "fad fa-shield-check",
          "fad fa-shield-cross",
          "fad fa-shield-dog",
          "fad fa-shield-exclamation",
          "fad fa-shield-halved",
          "fad fa-shield-heart",
          "fad fa-shield-keyhole",
          "fad fa-shield-minus",
          "fad fa-shield-plus",
          "fad fa-shield-quartered",
          "fad fa-shield-slash",
          "fad fa-shield-virus",
          "fad fa-shield-xmark",
          "fad fa-ship",
          "fad fa-shirt",
          "fad fa-shirt-long-sleeve",
          "fad fa-shirt-running",
          "fad fa-shirt-tank-top",
          "fad fa-shish-kebab",
          "fad fa-shoe-prints",
          "fad fa-shop",
          "fad fa-shop-lock",
          "fad fa-shop-slash",
          "fad fa-shovel",
          "fad fa-shovel-snow",
          "fad fa-shower",
          "fad fa-shower-down",
          "fad fa-shredder",
          "fad fa-shrimp",
          "fad fa-shuffle",
          "fad fa-shutters",
          "fad fa-shuttle-space",
          "fad fa-shuttlecock",
          "fad fa-sickle",
          "fad fa-sidebar",
          "fad fa-sidebar-flip",
          "fad fa-sigma",
          "fad fa-sign-hanging",
          "fad fa-signal",
          "fad fa-signal-bars",
          "fad fa-signal-bars-fair",
          "fad fa-signal-bars-good",
          "fad fa-signal-bars-slash",
          "fad fa-signal-bars-weak",
          "fad fa-signal-fair",
          "fad fa-signal-good",
          "fad fa-signal-slash",
          "fad fa-signal-stream",
          "fad fa-signal-stream-slash",
          "fad fa-signal-strong",
          "fad fa-signal-weak",
          "fad fa-signature",
          "fad fa-signature-lock",
          "fad fa-signature-slash",
          "fad fa-signs-post",
          "fad fa-sim-card",
          "fad fa-sim-cards",
          "fad fa-sink",
          "fad fa-siren",
          "fad fa-siren-on",
          "fad fa-sitemap",
          "fad fa-skeleton",
          "fad fa-skeleton-ribs",
          "fad fa-ski-boot",
          "fad fa-ski-boot-ski",
          "fad fa-skull",
          "fad fa-skull-cow",
          "fad fa-skull-crossbones",
          "fad fa-slash",
          "fad fa-slash-back",
          "fad fa-slash-forward",
          "fad fa-sleigh",
          "fad fa-slider",
          "fad fa-sliders",
          "fad fa-sliders-simple",
          "fad fa-sliders-up",
          "fad fa-slot-machine",
          "fad fa-smog",
          "fad fa-smoke",
          "fad fa-smoking",
          "fad fa-snake",
          "fad fa-snooze",
          "fad fa-snow-blowing",
          "fad fa-snowflake",
          "fad fa-snowflake-droplets",
          "fad fa-snowflakes",
          "fad fa-snowman",
          "fad fa-snowman-head",
          "fad fa-snowplow",
          "fad fa-soap",
          "fad fa-socks",
          "fad fa-soft-serve",
          "fad fa-solar-panel",
          "fad fa-solar-system",
          "fad fa-sort",
          "fad fa-sort-down",
          "fad fa-sort-up",
          "fad fa-spa",
          "fad fa-space-station-moon",
          "fad fa-space-station-moon-construction",
          "fad fa-spade",
          "fad fa-spaghetti-monster-flying",
          "fad fa-sparkle",
          "fad fa-sparkles",
          "fad fa-speaker",
          "fad fa-speakers",
          "fad fa-spell-check",
          "fad fa-spider",
          "fad fa-spider-black-widow",
          "fad fa-spider-web",
          "fad fa-spinner",
          "fad fa-spinner-third",
          "fad fa-split",
          "fad fa-splotch",
          "fad fa-spoon",
          "fad fa-sportsball",
          "fad fa-spray-can",
          "fad fa-spray-can-sparkles",
          "fad fa-sprinkler",
          "fad fa-sprinkler-ceiling",
          "fad fa-square",
          "fad fa-square-0",
          "fad fa-square-1",
          "fad fa-square-2",
          "fad fa-square-3",
          "fad fa-square-4",
          "fad fa-square-5",
          "fad fa-square-6",
          "fad fa-square-7",
          "fad fa-square-8",
          "fad fa-square-9",
          "fad fa-square-a",
          "fad fa-square-a-lock",
          "fad fa-square-ampersand",
          "fad fa-square-arrow-down",
          "fad fa-square-arrow-down-left",
          "fad fa-square-arrow-down-right",
          "fad fa-square-arrow-left",
          "fad fa-square-arrow-right",
          "fad fa-square-arrow-up",
          "fad fa-square-arrow-up-left",
          "fad fa-square-arrow-up-right",
          "fad fa-square-b",
          "fad fa-square-bolt",
          "fad fa-square-c",
          "fad fa-square-caret-down",
          "fad fa-square-caret-left",
          "fad fa-square-caret-right",
          "fad fa-square-caret-up",
          "fad fa-square-check",
          "fad fa-square-chevron-down",
          "fad fa-square-chevron-left",
          "fad fa-square-chevron-right",
          "fad fa-square-chevron-up",
          "fad fa-square-code",
          "fad fa-square-d",
          "fad fa-square-dashed",
          "fad fa-square-dashed-circle-plus",
          "fad fa-square-divide",
          "fad fa-square-dollar",
          "fad fa-square-down",
          "fad fa-square-down-left",
          "fad fa-square-down-right",
          "fad fa-square-e",
          "fad fa-square-ellipsis",
          "fad fa-square-ellipsis-vertical",
          "fad fa-square-envelope",
          "fad fa-square-exclamation",
          "fad fa-square-f",
          "fad fa-square-fragile",
          "fad fa-square-full",
          "fad fa-square-g",
          "fad fa-square-h",
          "fad fa-square-heart",
          "fad fa-square-i",
          "fad fa-square-info",
          "fad fa-square-j",
          "fad fa-square-k",
          "fad fa-square-kanban",
          "fad fa-square-l",
          "fad fa-square-left",
          "fad fa-square-list",
          "fad fa-square-m",
          "fad fa-square-minus",
          "fad fa-square-n",
          "fad fa-square-nfi",
          "fad fa-square-o",
          "fad fa-square-p",
          "fad fa-square-parking",
          "fad fa-square-parking-slash",
          "fad fa-square-pen",
          "fad fa-square-person-confined",
          "fad fa-square-phone",
          "fad fa-square-phone-flip",
          "fad fa-square-phone-hangup",
          "fad fa-square-plus",
          "fad fa-square-poll-horizontal",
          "fad fa-square-poll-vertical",
          "fad fa-square-q",
          "fad fa-square-quarters",
          "fad fa-square-question",
          "fad fa-square-quote",
          "fad fa-square-r",
          "fad fa-square-right",
          "fad fa-square-ring",
          "fad fa-square-root",
          "fad fa-square-root-variable",
          "fad fa-square-rss",
          "fad fa-square-s",
          "fad fa-square-share-nodes",
          "fad fa-square-sliders",
          "fad fa-square-sliders-vertical",
          "fad fa-square-small",
          "fad fa-square-star",
          "fad fa-square-t",
          "fad fa-square-terminal",
          "fad fa-square-this-way-up",
          "fad fa-square-u",
          "fad fa-square-up",
          "fad fa-square-up-left",
          "fad fa-square-up-right",
          "fad fa-square-user",
          "fad fa-square-v",
          "fad fa-square-virus",
          "fad fa-square-w",
          "fad fa-square-x",
          "fad fa-square-xmark",
          "fad fa-square-y",
          "fad fa-square-z",
          "fad fa-squid",
          "fad fa-squirrel",
          "fad fa-staff",
          "fad fa-staff-snake",
          "fad fa-stairs",
          "fad fa-stamp",
          "fad fa-standard-definition",
          "fad fa-stapler",
          "fad fa-star",
          "fad fa-star-and-crescent",
          "fad fa-star-christmas",
          "fad fa-star-exclamation",
          "fad fa-star-half",
          "fad fa-star-half-stroke",
          "fad fa-star-of-david",
          "fad fa-star-of-life",
          "fad fa-star-sharp",
          "fad fa-star-sharp-half",
          "fad fa-star-sharp-half-stroke",
          "fad fa-star-shooting",
          "fad fa-starfighter",
          "fad fa-starfighter-twin-ion-engine",
          "fad fa-starfighter-twin-ion-engine-advanced",
          "fad fa-stars",
          "fad fa-starship",
          "fad fa-starship-freighter",
          "fad fa-steak",
          "fad fa-steering-wheel",
          "fad fa-sterling-sign",
          "fad fa-stethoscope",
          "fad fa-stocking",
          "fad fa-stomach",
          "fad fa-stop",
          "fad fa-stopwatch",
          "fad fa-stopwatch-20",
          "fad fa-store",
          "fad fa-store-lock",
          "fad fa-store-slash",
          "fad fa-strawberry",
          "fad fa-street-view",
          "fad fa-stretcher",
          "fad fa-strikethrough",
          "fad fa-stroopwafel",
          "fad fa-subscript",
          "fad fa-suitcase",
          "fad fa-suitcase-medical",
          "fad fa-suitcase-rolling",
          "fad fa-sun",
          "fad fa-sun-bright",
          "fad fa-sun-cloud",
          "fad fa-sun-dust",
          "fad fa-sun-haze",
          "fad fa-sun-plant-wilt",
          "fad fa-sunglasses",
          "fad fa-sunrise",
          "fad fa-sunset",
          "fad fa-superscript",
          "fad fa-sushi",
          "fad fa-sushi-roll",
          "fad fa-swatchbook",
          "fad fa-sword",
          "fad fa-sword-laser",
          "fad fa-sword-laser-alt",
          "fad fa-swords",
          "fad fa-swords-laser",
          "fad fa-symbols",
          "fad fa-synagogue",
          "fad fa-syringe",
          "fad fa-t",
          "fad fa-table",
          "fad fa-table-cells",
          "fad fa-table-cells-large",
          "fad fa-table-columns",
          "fad fa-table-layout",
          "fad fa-table-list",
          "fad fa-table-picnic",
          "fad fa-table-pivot",
          "fad fa-table-rows",
          "fad fa-table-tennis-paddle-ball",
          "fad fa-table-tree",
          "fad fa-tablet",
          "fad fa-tablet-button",
          "fad fa-tablet-rugged",
          "fad fa-tablet-screen",
          "fad fa-tablet-screen-button",
          "fad fa-tablets",
          "fad fa-tachograph-digital",
          "fad fa-taco",
          "fad fa-tag",
          "fad fa-tags",
          "fad fa-tally",
          "fad fa-tally-1",
          "fad fa-tally-2",
          "fad fa-tally-3",
          "fad fa-tally-4",
          "fad fa-tamale",
          "fad fa-tank-water",
          "fad fa-tape",
          "fad fa-tarp",
          "fad fa-tarp-droplet",
          "fad fa-taxi",
          "fad fa-taxi-bus",
          "fad fa-teddy-bear",
          "fad fa-teeth",
          "fad fa-teeth-open",
          "fad fa-telescope",
          "fad fa-temperature-arrow-down",
          "fad fa-temperature-arrow-up",
          "fad fa-temperature-empty",
          "fad fa-temperature-full",
          "fad fa-temperature-half",
          "fad fa-temperature-high",
          "fad fa-temperature-list",
          "fad fa-temperature-low",
          "fad fa-temperature-quarter",
          "fad fa-temperature-snow",
          "fad fa-temperature-sun",
          "fad fa-temperature-three-quarters",
          "fad fa-tenge-sign",
          "fad fa-tennis-ball",
          "fad fa-tent",
          "fad fa-tent-arrow-down-to-line",
          "fad fa-tent-arrow-left-right",
          "fad fa-tent-arrow-turn-left",
          "fad fa-tent-arrows-down",
          "fad fa-tents",
          "fad fa-terminal",
          "fad fa-text",
          "fad fa-text-height",
          "fad fa-text-size",
          "fad fa-text-slash",
          "fad fa-text-width",
          "fad fa-thermometer",
          "fad fa-theta",
          "fad fa-thought-bubble",
          "fad fa-thumbs-down",
          "fad fa-thumbs-up",
          "fad fa-thumbtack",
          "fad fa-tick",
          "fad fa-ticket",
          "fad fa-ticket-airline",
          "fad fa-ticket-simple",
          "fad fa-tickets-airline",
          "fad fa-tilde",
          "fad fa-timeline",
          "fad fa-timeline-arrow",
          "fad fa-timer",
          "fad fa-tire",
          "fad fa-tire-flat",
          "fad fa-tire-pressure-warning",
          "fad fa-tire-rugged",
          "fad fa-toggle-large-off",
          "fad fa-toggle-large-on",
          "fad fa-toggle-off",
          "fad fa-toggle-on",
          "fad fa-toilet",
          "fad fa-toilet-paper",
          "fad fa-toilet-paper-blank",
          "fad fa-toilet-paper-blank-under",
          "fad fa-toilet-paper-check",
          "fad fa-toilet-paper-slash",
          "fad fa-toilet-paper-under",
          "fad fa-toilet-paper-under-slash",
          "fad fa-toilet-paper-xmark",
          "fad fa-toilet-portable",
          "fad fa-toilets-portable",
          "fad fa-tomato",
          "fad fa-tombstone",
          "fad fa-tombstone-blank",
          "fad fa-toolbox",
          "fad fa-tooth",
          "fad fa-toothbrush",
          "fad fa-torii-gate",
          "fad fa-tornado",
          "fad fa-tower-broadcast",
          "fad fa-tower-cell",
          "fad fa-tower-control",
          "fad fa-tower-observation",
          "fad fa-tractor",
          "fad fa-trademark",
          "fad fa-traffic-cone",
          "fad fa-traffic-light",
          "fad fa-traffic-light-go",
          "fad fa-traffic-light-slow",
          "fad fa-traffic-light-stop",
          "fad fa-trailer",
          "fad fa-train",
          "fad fa-train-subway",
          "fad fa-train-subway-tunnel",
          "fad fa-train-track",
          "fad fa-train-tram",
          "fad fa-train-tunnel",
          "fad fa-transformer-bolt",
          "fad fa-transgender",
          "fad fa-transporter",
          "fad fa-transporter-1",
          "fad fa-transporter-2",
          "fad fa-transporter-3",
          "fad fa-transporter-4",
          "fad fa-transporter-5",
          "fad fa-transporter-6",
          "fad fa-transporter-7",
          "fad fa-transporter-empty",
          "fad fa-trash",
          "fad fa-trash-arrow-up",
          "fad fa-trash-can",
          "fad fa-trash-can-arrow-up",
          "fad fa-trash-can-check",
          "fad fa-trash-can-clock",
          "fad fa-trash-can-list",
          "fad fa-trash-can-plus",
          "fad fa-trash-can-slash",
          "fad fa-trash-can-undo",
          "fad fa-trash-can-xmark",
          "fad fa-trash-check",
          "fad fa-trash-clock",
          "fad fa-trash-list",
          "fad fa-trash-plus",
          "fad fa-trash-slash",
          "fad fa-trash-undo",
          "fad fa-trash-xmark",
          "fad fa-treasure-chest",
          "fad fa-tree",
          "fad fa-tree-christmas",
          "fad fa-tree-city",
          "fad fa-tree-deciduous",
          "fad fa-tree-decorated",
          "fad fa-tree-large",
          "fad fa-tree-palm",
          "fad fa-trees",
          "fad fa-triangle",
          "fad fa-triangle-exclamation",
          "fad fa-triangle-instrument",
          "fad fa-triangle-person-digging",
          "fad fa-tricycle",
          "fad fa-tricycle-adult",
          "fad fa-trillium",
          "fad fa-trophy",
          "fad fa-trophy-star",
          "fad fa-trowel",
          "fad fa-trowel-bricks",
          "fad fa-truck",
          "fad fa-truck-arrow-right",
          "fad fa-truck-bolt",
          "fad fa-truck-clock",
          "fad fa-truck-container",
          "fad fa-truck-container-empty",
          "fad fa-truck-droplet",
          "fad fa-truck-fast",
          "fad fa-truck-field",
          "fad fa-truck-field-un",
          "fad fa-truck-flatbed",
          "fad fa-truck-front",
          "fad fa-truck-medical",
          "fad fa-truck-monster",
          "fad fa-truck-moving",
          "fad fa-truck-pickup",
          "fad fa-truck-plane",
          "fad fa-truck-plow",
          "fad fa-truck-ramp",
          "fad fa-truck-ramp-box",
          "fad fa-truck-ramp-couch",
          "fad fa-truck-tow",
          "fad fa-trumpet",
          "fad fa-tty",
          "fad fa-tty-answer",
          "fad fa-tugrik-sign",
          "fad fa-turkey",
          "fad fa-turkish-lira-sign",
          "fad fa-turn-down",
          "fad fa-turn-down-left",
          "fad fa-turn-down-right",
          "fad fa-turn-up",
          "fad fa-turntable",
          "fad fa-turtle",
          "fad fa-tv",
          "fad fa-tv-music",
          "fad fa-tv-retro",
          "fad fa-typewriter",
          "fad fa-u",
          "fad fa-ufo",
          "fad fa-ufo-beam",
          "fad fa-umbrella",
          "fad fa-umbrella-beach",
          "fad fa-umbrella-simple",
          "fad fa-underline",
          "fad fa-unicorn",
          "fad fa-uniform-martial-arts",
          "fad fa-union",
          "fad fa-universal-access",
          "fad fa-unlock",
          "fad fa-unlock-keyhole",
          "fad fa-up",
          "fad fa-up-down",
          "fad fa-up-down-left-right",
          "fad fa-up-from-bracket",
          "fad fa-up-from-dotted-line",
          "fad fa-up-from-line",
          "fad fa-up-left",
          "fad fa-up-long",
          "fad fa-up-right",
          "fad fa-up-right-and-down-left-from-center",
          "fad fa-up-right-from-square",
          "fad fa-up-to-dotted-line",
          "fad fa-up-to-line",
          "fad fa-upload",
          "fad fa-usb-drive",
          "fad fa-user",
          "fad fa-user-alien",
          "fad fa-user-astronaut",
          "fad fa-user-bounty-hunter",
          "fad fa-user-check",
          "fad fa-user-chef",
          "fad fa-user-clock",
          "fad fa-user-cowboy",
          "fad fa-user-crown",
          "fad fa-user-doctor",
          "fad fa-user-doctor-hair",
          "fad fa-user-doctor-hair-long",
          "fad fa-user-doctor-message",
          "fad fa-user-gear",
          "fad fa-user-graduate",
          "fad fa-user-group",
          "fad fa-user-group-crown",
          "fad fa-user-hair",
          "fad fa-user-hair-buns",
          "fad fa-user-hair-long",
          "fad fa-user-hair-mullet",
          "fad fa-user-headset",
          "fad fa-user-helmet-safety",
          "fad fa-user-injured",
          "fad fa-user-large",
          "fad fa-user-large-slash",
          "fad fa-user-lock",
          "fad fa-user-magnifying-glass",
          "fad fa-user-minus",
          "fad fa-user-music",
          "fad fa-user-ninja",
          "fad fa-user-nurse",
          "fad fa-user-nurse-hair",
          "fad fa-user-nurse-hair-long",
          "fad fa-user-pen",
          "fad fa-user-pilot",
          "fad fa-user-pilot-tie",
          "fad fa-user-plus",
          "fad fa-user-police",
          "fad fa-user-police-tie",
          "fad fa-user-robot",
          "fad fa-user-robot-xmarks",
          "fad fa-user-secret",
          "fad fa-user-shakespeare",
          "fad fa-user-shield",
          "fad fa-user-slash",
          "fad fa-user-tag",
          "fad fa-user-tie",
          "fad fa-user-tie-hair",
          "fad fa-user-tie-hair-long",
          "fad fa-user-unlock",
          "fad fa-user-visor",
          "fad fa-user-vneck",
          "fad fa-user-vneck-hair",
          "fad fa-user-vneck-hair-long",
          "fad fa-user-xmark",
          "fad fa-users",
          "fad fa-users-between-lines",
          "fad fa-users-gear",
          "fad fa-users-line",
          "fad fa-users-medical",
          "fad fa-users-rays",
          "fad fa-users-rectangle",
          "fad fa-users-slash",
          "fad fa-users-viewfinder",
          "fad fa-utensils",
          "fad fa-utensils-slash",
          "fad fa-utility-pole",
          "fad fa-utility-pole-double",
          "fad fa-v",
          "fad fa-vacuum",
          "fad fa-vacuum-robot",
          "fad fa-value-absolute",
          "fad fa-van-shuttle",
          "fad fa-vault",
          "fad fa-vector-circle",
          "fad fa-vector-polygon",
          "fad fa-vector-square",
          "fad fa-vent-damper",
          "fad fa-venus",
          "fad fa-venus-double",
          "fad fa-venus-mars",
          "fad fa-vest",
          "fad fa-vest-patches",
          "fad fa-vial",
          "fad fa-vial-circle-check",
          "fad fa-vial-virus",
          "fad fa-vials",
          "fad fa-video",
          "fad fa-video-arrow-down-left",
          "fad fa-video-arrow-up-right",
          "fad fa-video-plus",
          "fad fa-video-slash",
          "fad fa-vihara",
          "fad fa-violin",
          "fad fa-virus",
          "fad fa-virus-covid",
          "fad fa-virus-covid-slash",
          "fad fa-virus-slash",
          "fad fa-viruses",
          "fad fa-voicemail",
          "fad fa-volcano",
          "fad fa-volleyball",
          "fad fa-volume",
          "fad fa-volume-high",
          "fad fa-volume-low",
          "fad fa-volume-off",
          "fad fa-volume-slash",
          "fad fa-volume-xmark",
          "fad fa-vr-cardboard",
          "fad fa-w",
          "fad fa-waffle",
          "fad fa-wagon-covered",
          "fad fa-walker",
          "fad fa-walkie-talkie",
          "fad fa-wallet",
          "fad fa-wand",
          "fad fa-wand-magic",
          "fad fa-wand-magic-sparkles",
          "fad fa-wand-sparkles",
          "fad fa-warehouse",
          "fad fa-warehouse-full",
          "fad fa-washing-machine",
          "fad fa-watch",
          "fad fa-watch-apple",
          "fad fa-watch-calculator",
          "fad fa-watch-fitness",
          "fad fa-watch-smart",
          "fad fa-water",
          "fad fa-water-arrow-down",
          "fad fa-water-arrow-up",
          "fad fa-water-ladder",
          "fad fa-watermelon-slice",
          "fad fa-wave-pulse",
          "fad fa-wave-sine",
          "fad fa-wave-square",
          "fad fa-wave-triangle",
          "fad fa-waveform",
          "fad fa-waveform-lines",
          "fad fa-webhook",
          "fad fa-weight-hanging",
          "fad fa-weight-scale",
          "fad fa-whale",
          "fad fa-wheat",
          "fad fa-wheat-awn",
          "fad fa-wheat-awn-circle-exclamation",
          "fad fa-wheat-awn-slash",
          "fad fa-wheat-slash",
          "fad fa-wheelchair",
          "fad fa-wheelchair-move",
          "fad fa-whiskey-glass",
          "fad fa-whiskey-glass-ice",
          "fad fa-whistle",
          "fad fa-wifi",
          "fad fa-wifi-exclamation",
          "fad fa-wifi-fair",
          "fad fa-wifi-slash",
          "fad fa-wifi-weak",
          "fad fa-wind",
          "fad fa-wind-turbine",
          "fad fa-wind-warning",
          "fad fa-window",
          "fad fa-window-flip",
          "fad fa-window-frame",
          "fad fa-window-frame-open",
          "fad fa-window-maximize",
          "fad fa-window-minimize",
          "fad fa-window-restore",
          "fad fa-windsock",
          "fad fa-wine-bottle",
          "fad fa-wine-glass",
          "fad fa-wine-glass-crack",
          "fad fa-wine-glass-empty",
          "fad fa-won-sign",
          "fad fa-worm",
          "fad fa-wreath",
          "fad fa-wreath-laurel",
          "fad fa-wrench",
          "fad fa-wrench-simple",
          "fad fa-x",
          "fad fa-x-ray",
          "fad fa-xmark",
          "fad fa-xmark-large",
          "fad fa-xmark-to-slot",
          "fad fa-xmarks-lines",
          "fad fa-y",
          "fad fa-yen-sign",
          "fad fa-yin-yang",
          "fad fa-z"
        ],
      }
    }
  }
}
