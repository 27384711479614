<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
      <trashed-message v-if="digitalcenters.deleted_at" class="mb-6" @restore="restore"> This Category has been deleted. </trashed-message>
      <div class="max-w-2xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="update">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.name" :error="form.errors.name" class="pb-8 pr-6 w-full lg:w-1/2"  label="Name" required="true" placeholder="i.e Techzone Philippines"/>
            <text-input v-model="form.phone" :error="form.errors.phone" class="pb-8 pr-6 w-full lg:w-1/2" label="Phone" required="true" placeholder="i.e 02 8817 0389 "/>
            <text-input v-model="form.address" :error="form.errors.address" class="pb-8 pr-6 w-full lg:w-1/2" label="Address" required="true" placeholder="i.e Techzone, Malugay, Makati, Metro Manila, Philippines"/>
            <text-input v-model="form.latitude" :error="form.errors.latitude" class="pb-8 pr-6 w-full lg:w-1/2" label="Latitude" required="true" placeholder="i.e 14.578352150200645"/>
            <text-input v-model="form.longitude" :error="form.errors.longitude" class="pb-8 pr-6 w-full lg:w-1/2" label="Longitude" required="true" placeholder="i.e 121.00917410468901"/>
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status" class="pb-8 pr-6 w-full lg:w-1/2 mt-5"></status-toogle-button>
          </div>
          <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100 md:mt-2">
            <button v-if="!digitalcenters.deleted_at && permission.edit>0" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
            <Button v-if="permission.edit>0" :type="type" :name="head" :url="createUrl" :loading="form.processing"></Button>
          </div>
        </form>
      </div>
    </div>
  </template>

  <script>
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import Button from '@/Shared/Button'
  import DeleteIcon from '@/Shared/DeleteIcon'
  import Icon from '@/Shared/Icon'
  import Layout from '@/Shared/Layout'
  import LoadingButton from '@/Shared/LoadingButton'
  import SelectInput from '@/Shared/SelectInput'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import TextareaInput from '@/Shared/TextareaInput'
  import TextInput from '@/Shared/TextInput'
  import TrashedMessage from '@/Shared/TrashedMessage'
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import { ref, toRefs,inject } from 'vue'
    export default {
      components: {
        Head,
        Icon,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        TrashedMessage,
        StatusToogleButton,
        Breadcrumbs,
        DeleteIcon,
        Button,
        TextareaInput
      },
      layout: Layout,
      props: {
        digitalcenters: Object,
          permission: Object
      },
      remember: 'form',
      setup(props){
        const { digitalcenters,permission} = toRefs(props)
        const type = ref('edit')
        const title = ref(digitalcenters.value.name)
        const head = ref('Digital Center')
        const url = ref('/backend/digitalcenters')
        const createUrl = ref('')
        const swal = inject('$swal')
        const elm = ref(this)
        const form = useForm({
          name:digitalcenters.value.name,
          phone: digitalcenters.value.phone,
          address: digitalcenters.value.address,
          latitude: digitalcenters.value.latitude,
          longitude: digitalcenters.value.longitude,
          status: digitalcenters.value.status
        })
        const slugBuild = () => {
          form.slug = form.title.replace(/\s/g,'-').toLowerCase()
        }
        const update = () => {
          swal({
              title: 'Are you sure?',
              text: "You want to proceed with this action",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Confirm',
              cancelButtonText: 'Cancel',
            }).then((result) => {
              if (result.isConfirmed) {
                form.put(`${url.value}/${digitalcenters.value.id}`)
                title.value = form.title
                Swal.fire(
                  'Updated!',
                  'Your infomation has been Updated.',
                  'success'
                )
              }
            });
        }
        
        const destroy = () => {
          swal({
              title: 'Are you sure?',
              text: "Do you really want to delete this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
              if (result.isConfirmed) {
                form.delete(`${url.value}/${digitalcenters.value.id}`)
                Swal.fire(
                  'Deleted!',
                  'Your infomation has been deleted.',
                  'success'
                )
              }
            });
        }
        const restore = () => {
          swal({
              title: 'Are you sure?',
              text: "You want to restore this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, Resore it!'
            }).then((result) => {
              if (result.isConfirmed) {
                form.put(`${url.value}/${digitalcenters.value.id}/restore`)
                Swal.fire(
                  'Restored!',
                  'Your infomation has been Restored.',
                  'success'
                )
              }
            });
        }
        
        return {
          form,
          digitalcenters,
          type,
          title,
          head,
          url,
          createUrl,
          update,
          destroy,
          restore,
          slugBuild,
          swal,
          permission
        }
      }
    }
  </script>
