<template>    
        <div class="form_info_block p-10">
            <a  href="/">
                <logo />
            </a>
            <form  @submit.prevent="login">
                <div class="login_form_block">
                    <label class="block mt-6 mb-4">Sign In</label>
                    <template v-if="err_msg">
                        <ErrorMsg :error_data="form_data_error" />
                    </template>
                    <div class="input_block login_block">
                        <label>Email</label>
                        <div>
                            <input v-model="form.username" type="text" placeholder="i.e. yousuf@gmail.com"  />
                        </div>
                    </div>
                    <div class="input_block password_block mt-4">
                        <label>Password</label>
                        <div>
                            <input v-if="password_open" v-model="form.password" type="input" placeholder="i.e. password#123" />
                            <input v-else type="password" v-model="form.password" placeholder="i.e. password#123" />
                            <i :class="['fa','fa-eye',{active:password_open}]" @click="password_view_status(false)"></i>
                            <i :class="['fa','fa-eye-slash',{active:!password_open}]" @click="password_view_status(true)"></i>
                        </div>
                    </div>
                    <div v-if="form.errors.username || form.errors.password" class="px-10 py-5">
                        <div v-for="error in form.errors" class="flex items-center gap-1 err">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                        </svg>
                        <small v-if="error">{{ error }}</small>
                        </div>
                    </div>
                    <div class="flex flex-row gap-5 mt-5 items-center">
                        <div v-if="preloader" class="flex flex-row items-center justify-center gap-1">
                            <i class="fa fa-cog fa-spin"></i>
                            <span>Loading...wait</span>
                        </div>
                        <button v-else class="flex flex-row items-center gap-2 ml-auto submit-btn" role="button" tabindex="0" type="submit">
                            <i class="fa fa-sign-in"></i>
                            <span>Sign In</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
</template>
<script>
import { ref, toRefs } from 'vue'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import Logo from '@/Shared/Logo'
import ErrorMsg from '@/Shared/ErrorMsg'
import TextInput from '@/Shared/TextInput'
import LoadingButton from '@/Shared/LoadingButton'
import UserIcon from '@/svg/UserIcon'
import LockIcon from '@/svg/LockIcon'
export default {
  components: {
    Head,
    LoadingButton,
    Logo,
    TextInput,
    UserIcon,
    LockIcon,
    ErrorMsg,
  },
  data() {
    return {
      form: this.$inertia.form({
        username: '',
        password: '',
        remember: false,
      }),
      window: {
        width: 0,
        height: 0
      },
      total_slides: 2,
      showPassword:false,
      sel_slide: 1,
      slider_interval: '',
      mobilestat:false,
      password_open:false,
      preloader : false
    }
  },
  props:{
    error: Object
  },
  created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
  },
  destroyed() {
      window.removeEventListener('resize', this.handleResize);
  },
  mounted(){
    this.bgSliderOn()
  },
  computed: {
    buttonLabel() {
      return (this.showPassword) ? "Hide" : "Show";
    }
  },
  methods: {
    bgSliderOn(){
      this.slider_interval = setInterval(() => {
        if(this.sel_slide==this.total_slides) this.sel_slide = 1
        else this.sel_slide++
      }, 1000 * 10)
    }, 
    password_view_status() {
        this.password_open = !this.password_open;
    },
    async login() {
      this.preloader = true
      await this.form.post('/backend/login')
      this.preloader = false
    },
    handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
            if(this.window.width<750){
              this.mobilestat= false;
            }else{
              this.mobilestat= true;
            }
            
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    }
  },
  beforeDestory(){
    clearInterval(this.slider_interval)
  }
}
</script>
<style  lang="scss" scoped>
.login_form_block{
    & > label{
        font-size: 16px;
        font-weight: 600;
        color: #005b3c
    }        

    & > .input_block{
        position: relative;
        border: 2px solid #ddd;
        border-radius: 5px;

        & > label{
            position: absolute;
            top: -10px;
            left: 10px;
            padding: 0 5px;
            background-color: #fff;
            font-size:12px;
            color: #888;
        }

        & > div{
            display: flex;
            align-items: center;
            height: 36px;
            padding-top: 3px;

            & > input{
                border: none;
                padding: 0 10px;
                outline: none;
                width: calc(100% - 30px);
                color: #0b3609;
            }
            & > i{
                display: none;             
                width: 30px;
                text-align: center;
                transition: all 0.4s;
                color: #888;

                &.active{
                    display: block;
                }
            }
        }

        &.err_msg{
            border-color: #CD0000
        }
    }

    .forgot-password,
    .submit-btn {            
        font-size: 11px;
        font-weight: 500;
        color: #0c6930;

        &.submit-btn {
            height: 36px;
            font-weight: 400;
            padding: 0 25px;
            background-color: #019966;
            color: #fff;
            border-radius: 3px;
        }
    }
    .divider{
        position: relative;
        border-bottom: 1px solid #ddd;
        color: #888;
        span{
            position: relative;
            margin-bottom: -9px;
            font-size: 12px;
            background-color: #fff;
            padding: 0 10px;
        }
    }
    .signup_info{
        font-size: 11px;
        font-weight: 600;

        & > a {
            color: #ac7012
        }
    }
}

.err{
    color: red;
}
</style>