<template>
  <TransitionRoot as="template" :show="attachmentopen">
    <Dialog as="div" class="relative z-[9999]" @click="attachmentopen = false">
      <div class="fixed inset-0" />
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
              leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                  <div class="h-0 flex-1 overflow-y-auto">
                    <div class="bg-indigo-700 px-4 py-6 sm:px-6">
                      <div class="flex items-center justify-between">
                        <DialogTitle class="text-base font-semibold leading-6 text-white">Attachment</DialogTitle>
                        <div class="ml-3 flex h-7 items-center">
                          <button type="button"
                            class="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            @click="attachmentopen = false">
                            <span class="absolute -inset-2.5" />
                            <span class="sr-only">Close panel</span>
                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div class="mt-1">
                        <p class="text-sm text-indigo-300">Select Attachments for this post.</p>
                      </div>
                    </div>
                    <div class="flex flex-1 flex-col justify-between">
                      <div class="divide-y divide-gray-200 px-4 sm:px-6">
                        <div class="space-y-6 pb-5 pt-6">
                          <div class="mt-1 rounded-md border border-gray-300 p-3" >
                            <div class="grid gap-y-2 gap-x-1 mb-1">
                              <div class="font-14 pt-3 pb-3 font-bold">List Of Attachments</div>
                            </div>
                            <hr>
                            <div class="grid grid-cols-1 gap-y-2 gap-x-1 mt-1  max-h-[322px] overflow-y-auto">

                              <div class="form-check form-check-inline flex" v-for="attachment in attachments_list"
                                :key="attachment.id">
                                <div class=" ">
                                  <input
                                    class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                    type="checkbox" :id="'decisioninfo-' + attachment.id" v-model="attachments_data"
                                    :value='attachment.id'>
                                </div>
                                <div>
                                  <label class="form-check-label inline-block text-gray-800"
                                    :for="'decisioninfo-' + attachment.id">
                                    {{ attachment.title }}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="flex flex-shrink-0 justify-end px-4 py-4" v-if="edit == false">
                    <button type="button" @click="attachmentopen = false"
                      class="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>


import axios from 'axios';
import { ref, toRefs, inject, onMounted, watch } from 'vue'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import FileInput from '@/Shared/FileInput'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import Button from '@/Shared/Button'
import PhotoBlock from './Photo';
import FloatingActionBtnFix from "@/Shared/Buttons/FloatingActionBtnFix"
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/vue/20/solid'
const props = defineProps(['attachments', 'attachmentopen'])
const attachments_data = ref(props.attachments)
const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
const edit = ref(false)
const swal = inject('$swal')  
const preloader = ref(false)
const message = ref('');

const emit = defineEmits(['UpdateAttachmentPopup', 'UpdateAttachment'])

const attachments_list = ref([])
const load_attachments = async () => {
    await axios.post(`/${backendPrefix.value}/serviceinfos/get/attachments`)
        .then(function (response) {
          ////console.log(response);
            attachments_list.value = response.data
        }.bind(this));
}

const open_edit = () => {
  edit.value = true
}

const save = () => {
  emit('UpdateAttachmentPopup', props.attachmentopen)
  emit('UpdateAttachment', attachments_data)
}


watch(() => props.attachmentopen, () => {
  emit('UpdateAttachmentPopup', props.attachmentopen)
}, { deep: true })
watch(() => attachments_data, () => {
  emit('UpdateAttachment', attachments_data)
}, { deep: true })

onMounted(() => {
  load_attachments()
})


</script>