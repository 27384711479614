<template>
    <div class="properties_block">
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Column Type</label>
            <div>
                <select class="px-2" v-model="property_form_data.type" @change="add_properties">
                    <option>th</option>
                    <option>td</option>
                </select>
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Cell Hide</label>
            <div class="form-check form-switch">
                <input class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.hidden" @change="add_properties" />
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Content Type</label>
            <div>
                <select class="px-2" v-model="property_form_data.content_type" @change="add_properties">
                    <option v-for="(value,id) in content_types" :value="id">{{ value }}</option>
                </select>
            </div>
        </div>
        <div v-if="property_form_data.content_type!=='3'" class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Content</label>            
            <div v-if="property_form_data.content_type=='2'">
                <textarea class="p-2" v-model="property_form_data.content" placeholder="Enter Content" @input="add_properties"></textarea>
            </div>
            <div v-else>
                <input class="px-2" type="text" v-model="property_form_data.content" placeholder="Enter Content" @input="add_properties" />
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Rowspan</label>
            <div>
                <input class="pl-2" type="number" v-model="property_form_data.rowspan" placeholder="i.e. 1" min="1" :max="max_rowspan" step="1" @input="add_properties" />
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Colspan</label>
            <div>
                <input class="pl-2" type="number" v-model="property_form_data.colspan" placeholder="i.e. 1" min="1" :max="max_colspan" step="1" @input="add_properties" />
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Text Alignment</label>
            <div>
                <select class="px-2" v-model="property_form_data.align" @change="add_properties">
                    <option v-for="value in text_align_list">{{ value }}</option>
                </select>
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Vertical Alignment</label>
            <div>
                <select class="px-2" v-model="property_form_data.valign" @change="add_properties">
                    <option v-for="value in valign_list">{{ value }}</option>
                </select>
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Add More Effect</label>
            <div>
                <select class="px-2" v-model="property_form_data.add_more_effect" @change="add_properties">
                    <option v-for="(value,id) in add_more_effect_list" :value="id">{{ value }}</option>
                </select>
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Carry dynamic values</label>
            <div class="form-check form-switch">
                <input class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.carry_dynamic_values" @change="add_properties" />
            </div>
        </div>        
        <div v-if="property_form_data.carry_dynamic_values" class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Enter dynamic vars</label>
            <!-- <div><input class="px-2" type="text" v-model="property_form_data.dynamic_vars" placeholder="i.e. Enter name" @keyup="add_properties" /></div> -->
            <div>
                <select class="px-2" v-model="property_form_data.dynamic_vars" @change="add_properties">
                    <option value="">None</option>
                    <option v-for="value in dynamic_vars">{{ value }}</option>
                </select>
            </div>
        </div>
        <div v-if="property_form_data.carry_dynamic_values" class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Caculate with dynamic vars</label>
            <div>
                <select class="px-2" v-model="property_form_data.operator_type" @change="add_properties">
                    <option value="">None</option>
                    <option v-for="value in basic_operator_list">{{ value }}</option>
                </select>
            </div>            
        </div>
        <div v-if="property_form_data.carry_dynamic_values" class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Prefix</label>
            <div><input type="text" class="px-2" v-model="property_form_data.prefix" placeholder="Enter prefix" @keyup="add_properties" @change="add_properties" /></div>
        </div>
        <div v-if="property_form_data.carry_dynamic_values" class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Suffix</label>
            <div><input type="text" class="px-2" v-model="property_form_data.suffix" placeholder="Enter suffix" @keyup="add_properties" @change="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Font Size</label>
            <div class="flex items-center gap-1">
                <input class="text-center pl-1" type="number" v-model="property_form_data.font_size" min="10" @keyup="add_properties" @change="add_properties" />
                <select class="text-center" v-model="property_form_data.font_size_type" @change="add_properties">
                    <option v-for="text in font_size_types" :value="text">{{ text }}</option>
                </select>
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Font Weight</label>
            <div><select class="px-1" v-model="property_form_data.font_weight" @change="add_properties">
                <option v-for="(text,val) in font_weight_list" :value="val">{{ text }}</option>
            </select></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Line Height</label>
            <div class="flex items-center gap-1">
                <input class="text-center pl-1" type="number" v-model="property_form_data.line_height" min="10" @keyup="add_properties" @change="add_properties" />
                <select class="text-center" v-model="property_form_data.line_height_type" @change="add_properties">
                    <option v-for="text in font_size_types" :value="text">{{ text }}</option>
                </select>
            </div>
        </div>
        <!-- <pre>{{ props }}</pre> -->
    </div>
</template>
<script setup>
    import { computed, ref, watch } from 'vue'
    const emit = defineEmits(['addCellProperties'])
    const props = defineProps({
        data: Object,
        dynamic_vars: Array,
        max_rowspan: Number,
        max_colspan: Number
    })

    const font_size_types = ref(['%','em','rem','vh','px'])    
    const font_weight_list = ref({
        '100': '100',
        '200': '200',
        '300': '300',
        '400': '400',
        '500': '500',
        '600': '600',
        '700': '700',
        '800': '800',
        '900': '900',
        'thin': 'Thin',
        'light': 'Light',             
        'normal': 'Normal',        
        'medium': 'Medium',        
        'semimbold': 'Semibold',
        'bold': 'Bold',
        'extrabold': 'Extrabold'
    })

    const content_types = ref({
        1: 'Plain Text',
        2: 'HTML',
        3: 'Dynamic Input'
    })

    const add_more_effect_list = ref({
        0: 'None',
        1: 'First Row/Column Copy to Others',
        2: 'Only Single Row/Column Allow',
        3: 'Only Single Row/Column with Rowspan'
    })

    const basic_operator_list = ref(['Addition','Subtraction','Multiplication','Division'])
    const text_align_list = ref(['center','left','right','justify'])
    const valign_list = ref(['top','middle','bottom','baseline'])

    const property_form_data = ref({
        type: props.data.type,
        content_type: props.data.content_type,
        content: props.data.content,
        dynamic_content: props.data.dynamic_content,
        rowspan: props.data.rowspan,
        colspan: props.data.colspan,
        align: props.data.align,
        valign: props.data.valign,
        hidden: props.data.hidden,
        add_more_effect: props.data.add_more_effect,
        carry_dynamic_values: props.data.carry_dynamic_values,
        dynamic_vars: props.data.dynamic_vars,
        operator_type: props.data.operator_type,
        prefix: props.data.prefix,
        suffix: props.data.suffix,
        font_size: props.data && props.data.font_size?props.data.font_size:12,
        font_size_type: props.data && props.data.font_size_type?props.data.font_size_type:'px',
        font_weight: props.data && props.data.font_weight?props.data.font_weight:'normal',
        line_height: props.data && props.data.line_height?props.data.line_height:16,
        line_height_type: props.data && props.data.line_height_type?props.data.line_height_type:'px'
    })

    watch(() => props.data, (updateData) => {
        property_form_data.value = {
            type: updateData.type,
            content_type: updateData.content_type,
            content: updateData.content,
            dynamic_content: updateData.dynamic_content,
            rowspan: updateData.rowspan,
            colspan: updateData.colspan,
            align: updateData.align,
            valign: updateData.valign,
            hidden: updateData.hidden,
            add_more_effect: updateData.add_more_effect,
            carry_dynamic_values: updateData.carry_dynamic_values,
            dynamic_vars: updateData.dynamic_vars,
            operator_type: updateData.operator_type,
            prefix: updateData.prefix,
            suffix: updateData.suffix,
            font_size: updateData && updateData.font_size?updateData.font_size:12,
            font_size_type: updateData && updateData.font_size_type?updateData.font_size_type:'px',
            font_weight: updateData && updateData.font_weight?updateData.font_weight:'normal',
            line_height: updateData && updateData.line_height?updateData.line_height:16,
            line_height_type: updateData && updateData.line_height_type?updateData.line_height_type:'px'
        }
    })

    const add_properties = () => {
        emit('addCellProperties', property_form_data.value)
    }
</script>
<style lang="scss" scoped>
    .properties_block{
        font-size: 12px;    
        input,textarea,select{
            width: 100%;
            height: 26px;
            border: 1px solid #ddd;
            border-radius: 5px;

            &.switch_btn{
                width: 50px;
                height: 26px;
                border-radius: 25px;
                margin-left: -30px;
            }
        }
        textarea{
            height: 48px;
        }
    }
</style>