<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>   
      
      <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="store">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.office_layer_name" :error="form.errors.office_layer_name" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Office Layer" required="true" placeholder="i.e Layer One" />
            <text-input v-model="form.name_eng_short" :error="form.errors.name_eng_short" class="pb-8 pr-6 w-full lg:w-1/2" label="Short Name" placeholder="i.e LO"/>
            <text-input v-model="form.reference_code" :error="form.errors.reference_code" class="pb-8 pr-6 w-full lg:w-1/2" label="Code" placeholder="i.e 1001"/>
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Office Layer" class="mt-6 pb-8 pr-6 w-full lg:w-1/2"></status-toogle-button>          
          </div>
          <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import { ref, toRefs, inject } from 'vue'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import Button from '@/Shared/Button'
  export default {
    components: {
      Head,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      StatusToogleButton,
      Breadcrumbs,
      Button
    },
    props: {
      layerLevels: Array,
    }, 
    layout: Layout,
    remember: 'form',
    setup(props){
      const { layerLevels } = toRefs(props)
      const form = useForm({
        office_layer_name: null,
        name_eng_short: null,
        reference_code: null,
        status: true,
        layer_level:0
      })
      const type = ref('create')
      const title = ref('Layer')
      const url = ref('/backend/officelayers')
      const createUrl = ref('')      
      const swal = inject('$swal')
      const store = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}`, {forceFormData: true,})
              Swal.fire(
                'Added!',
                'Your infomation has been Added.',
                'success'
              )
            }
          });
        
      }
      return {
        form,
        layerLevels,
        type,
        title,
        url,
        createUrl,
        store
      }
    }
  }
  </script>
  