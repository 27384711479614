<template>
    <div class="geo_mapping_block">
        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
        <div class="container m-auto grid grid-cols-2">
            <div class="max-w-2xl bg-white rounded-md shadow">
                <div class="max-w-2xl overflow-hidden">
                    <OfficeListMapBlock
                    :sel_offices="sel_offices"
                    :OfficeLayers="OfficeLayers"
                    @selOfficeLayer="sel_office_layer"
                    @selOffice="sel_office"
                    @resetData="reset_data" />
                </div>                
            </div>
            <!-- {{ sel_office_id }} -->
            <!-- {{ sel_ministry_offices }} -->
            <div v-if="sel_office_id" class="max-w-2xl ml-4">
                <div v-if="(sel_office_layer_id==1)" class="max-w-2xl bg-white rounded-md shadow overflow-hidden">
                    <MinistryListMapBlock
                    :sel_ministry_offices="sel_ministry_offices" 
                    :MinistryOffices="MinistryOffices" 
                    @selMinistryOffice="sel_ministry_office" />
                </div>
                <div v-else class="max-w-2xl bg-white rounded-md shadow overflow-hidden">
                    <GeoListMapBlock                     
                    :GeoRegions="GeoRegions"
                    :sel_regions="sel_regions"
                    :sel_provinces="sel_provinces"
                    :sel_municipalities="sel_municipalities"
                    :sel_barangays="sel_barangays"
                    @selRegion="sel_region"
                    @selProvience="sel_province"
                    @selMunicipality="sel_municipality"
                    @selBarangay="sel_barangay" />
                </div>
            </div>
        </div>
        <FloatingActionBtn v-if="(Object.keys(sel_offices).length > 0 && (
            sel_ministry_offices ||
            sel_regions ||
            sel_provinces ||
            sel_municipalities ||
            sel_barangays                        
        ))" :loader="preloader" :icon="'fa fa-check'" :message="message" @removeMessage="remove_msg" @dataSubmit="submit_data" />
    </div>
</template>
<script>
    import axios from "axios"
    import { ref, toRefs, computed } from 'vue'    
    import { Head, Link } from '@inertiajs/inertia-vue3'
    import Layout from '@/Shared/Layout'
    import Breadcrumbs from '@/Shared/Breadcrumbs'
    import StatusLevel from '@/Shared/StatusLevel'
    import FloatingActionBtn from "@/Shared/Buttons/FloatingActionBtn"
    import OfficeListMapBlock from './components/OfficeListMap'
    import MinistryListMapBlock from './components/MinistryListMap'
    import GeoListMapBlock from './components/GeoListMap'        
    
    export default {
        name: 'GeomappingBlock',
        props: {
            OfficeLayers: Array,
            MinistryOffices: Array,
            GeoRegions: Array,
        },
        components: {
            Head,            
            Link,            
            Breadcrumbs,
            StatusLevel,
            FloatingActionBtn,
            OfficeListMapBlock,
            MinistryListMapBlock,
            GeoListMapBlock
        },
        layout: Layout,
        provide(){
            return {
                office_layer_id: computed( () => this.sel_office_layer_id)
            }
        },
        setup(props){
            const { OfficeLayers, MinistryOffices, GeoRegions } = toRefs(props)      
            const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
            const preloader = ref(false)
            const type = ref('index')
            const title = ref('GEO Mapping')
            const url = ref('')
            const sel_office_layer_id = ref('')
            // const sel_ministry_office_id = ref('')
            const sel_ministry_offices = ref({})
            const sel_offices = ref({})
            const sel_office_id = ref('')
            const sel_regions = ref({})
            const sel_provinces = ref({})
            const sel_municipalities = ref({})
            const sel_barangays = ref({})
            const message = ref('')

            const sel_office_layer = (layer_id) => {
                sel_office_layer_id.value = layer_id
            }
            
            const sel_office = (office_id) => {
                if(sel_offices.value[office_id]) {
                    delete sel_offices.value[office_id]
                    sel_office_id.value = ''
                }else {
                    sel_offices.value = {}
                    sel_offices.value[office_id] = true
                    sel_office_id.value = office_id
                    
                    /**
                     * Load geomapping
                     */
                    let formData = {
                        office_layer_id: sel_office_layer_id.value,
                        office_id: sel_office_id.value
                    }
                    axios.post(`/${backendPrefix.value}/offices/office-geo-mapping`, formData).then( async (response) => {
                        //console.log(response)                        
                        preloader.value = false

                        // for Ministry Office Data                        
                        let getMinistryOfficeMap = response.data.OfficeMinistryMap
                        //console.log(getMinistryOfficeMap)
                        sel_ministry_offices.value = {}
                        await getMinistryOfficeMap.forEach( async v => {
                            sel_ministry_offices.value[v.ministry_id] = true
                        })
                        
                        // for GEO Region
                        let getOfficeRegionMap = response.data.OfficeRegionMap
                        //console.log(getOfficeRegionMap)
                        sel_regions.value = {}
                        await getOfficeRegionMap.forEach( async v => {
                            sel_regions.value[v.region_id] = true
                        })

                        // for GEO Province
                        let getOfficeProvinceMap = response.data.OfficeProvinceMap
                        sel_provinces.value = {}
                        await getOfficeProvinceMap.forEach( async v => {
                            sel_provinces.value[v.province_id] = true
                        })

                        // for GEO Municipality
                        let getOfficeMunicipalityMap = response.data.OfficeMunicipalityMap
                        //console.log(getOfficeMunicipalityMap)
                        sel_municipalities.value = {}
                        await getOfficeMunicipalityMap.forEach( async v => {
                            sel_municipalities.value[v.municipality_id] = true
                        })

                        // for GEO Barangay
                        let getOfficeBarangayMap = response.data.OfficeBarangayMap
                        sel_barangays.value = {}
                        await getOfficeBarangayMap.forEach( async v => {
                            sel_barangays.value[v.barangay_id] = true
                        })
                    }).catch(error => {                    
                        //console.log(error.response.data.error)
                        preloader.value = false
                    })   
                }                
            }

            // const load_geo_mapping = () => {                
                             
            // }
            
            const sel_ministry_office = (ministry_id) => {
                // if(sel_ministry_office_id.value==ministry_id) sel_ministry_office_id.value = ''
                // else sel_ministry_office_id.value = ministry_id
                if(sel_ministry_offices.value[ministry_id]) delete sel_ministry_offices.value[ministry_id]
                else sel_ministry_offices.value[ministry_id] = true
            }

            const sel_region = (region_id) => {
                // //console.log(region_id)
                if(sel_regions.value[region_id]) delete sel_regions.value[region_id]
                else sel_regions.value[region_id] = true
            }

            const sel_province = (province_id) => {
                // //console.log(province_id)
                if(sel_provinces.value[province_id]) delete sel_provinces.value[province_id]
                else sel_provinces.value[province_id] = true
                // //console.log(sel_provinces.value)
            }

            const sel_municipality = (municipility_id) => {
                //console.log(municipility_id)
                if(sel_municipalities.value[municipility_id]) delete sel_municipalities.value[municipility_id]
                else sel_municipalities.value[municipility_id] = true
            }

            const sel_barangay = (barangay_id) => {
                // //console.log('barangay', barangay_id)
                if(sel_barangays.value[barangay_id]) delete sel_barangays.value[barangay_id]
                else sel_barangays.value[barangay_id] = true
            }            

            const submit_data = () => {
                let formData = {
                    sel_office_layer_id: sel_office_layer_id.value,
                    sel_office_id: sel_office_id.value,
                    // sel_ministry_office_id: sel_ministry_office_id.value,
                    sel_ministry_offices: sel_ministry_offices.value,
                    sel_regions: sel_regions.value,
                    sel_provinces: sel_provinces.value,
                    sel_municipalities: sel_municipalities.value,
                    sel_barangays: sel_barangays.value,
                }
                preloader.value = true
                axios.post(`/${backendPrefix.value}/geomapping`, {data:formData}).then( async (response) => {
                    //console.log(response)
                    message.value = response.data.message
                    preloader.value = false
                }).catch(error => {
                    preloader.value = false
                    //console.log(error.response.data.error)
                })
            }

            const remove_msg = () => {                
                message.value = ''
            }

            const reset_data = () => {                
                sel_office_id.value = ''
                // sel_ministry_office_id.value = ''
                sel_ministry_offices.value = {}
                sel_offices.value = {}             
                sel_regions.value = {}
                sel_provinces.value = {}
                sel_municipalities.value = {}
                sel_barangays.value = {}
                sel_municipalities.value = {}
            }

            return {
                preloader,
                OfficeLayers,
                MinistryOffices,
                GeoRegions,
                type,
                title,
                url,
                sel_office_layer_id,
                sel_office_layer,
                sel_offices,
                sel_office_id,
                sel_office,
                // sel_ministry_office_id,
                sel_ministry_offices,
                sel_ministry_office,
                sel_regions,
                sel_region,
                sel_provinces,
                sel_province,
                sel_municipalities,
                sel_municipality,
                sel_barangays,
                sel_barangay,
                submit_data,
                reset_data,
                remove_msg,
                message
                // load_geo_mapping
            }
        }
    }    
</script>
<style lang="scss" scoped>
    .geo_mapping_block{
        position: relative;
        & > :deep(.action_btn){
            position: fixed;
            bottom: 15px
        }
    }
</style>
