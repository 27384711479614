<template>
    <div>
        <div class="office_emp_map_block">
            <ContentHeaderSectionButton :title="'Office Setup'" :icon="'fa fa-cogs'" :buttontext="'Assign New Office'" :action="service_assign" />
            <div class="grid grid-cols-6 gap-4">               
                <div class="col-end-7 col-span-1 ..."><a class="btn-indigo" href="#" type="button" @click="service_assign(serviceinfos,'N')" ><i class="fa fa-plus-circle"></i><span>Assign</span><span class="hidden md:inline">&nbsp;Office</span></a></div>
            </div>              
            <div class="p-5 select_geo_map_block">
                <div class="bg-white rounded-md shadow overflow-x-auto">
                    <table class="w-full whitespace-nowrap">
                    <thead>
                        <tr class="text-left font-bold">
                        <th class="pb-2 pt-3 px-3">Service Name</th>
                        <th class="pb-2 pt-3 px-3" >Office Layer</th>
                        <th class="pb-2 pt-3 px-3">Location Name</th>
                        <th class="pb-2 pt-3 px-3">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="ministry_map in serviceinfos.service_ministry_map" :key="ministry_map.id"  class="hover:bg-gray-100 focus-within:bg-gray-100">
                            <td class="pb-2 pt-3 px-3 border-t">{{ service_name }}</td>
                            <td class="pb-2 pt-3 px-3 border-t">{{ ministry_map.officelayer.office_layer_name }}</td>
                            <td class="pb-2 pt-3 px-3 border-t">{{ ministry_map.ministry.ministry_name }}</td>
                            <td class="pb-2 pt-3 px-3 border-t">
                                <div class="flex items-center px-2">
                                    <div class="flex items-center px-4"  tabindex="0" @click="service_assign(serviceinfos,'M',ministry_map.id)">
                                        <i class="fa fa-edit"></i>
                                    </div>
                                    <div class="flex items-center px-4"  tabindex="0" @click="delete_service_map(ministry_map.id,'M')">
                                        <i class="fa fa-trash"></i>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr v-for="region_map in serviceinfos.service_region_map" :key="region_map.id"  class="hover:bg-gray-100 focus-within:bg-gray-100">
                            <td class="pb-2 pt-3 px-3 border-t">{{ service_name }}</td>
                            <td class="pb-2 pt-3 px-3 border-t">{{ region_map.officelayer.office_layer_name }}</td>
                            <td class="pb-2 pt-3 px-3 border-t">{{ region_map.region.region_name }}</td>
                            <td class="pb-2 pt-3 px-3 border-t">
                                <div class="flex items-center px-2">
                                    <div class="flex items-center px-4"  tabindex="0" @click="service_assign(serviceinfos,'R',region_map.id)">
                                        <i class="fa fa-edit"></i>
                                    </div>
                                    <div class="flex items-center px-4"  tabindex="0" @click="delete_service_map(region_map.id,'R')">
                                        <i class="fa fa-trash"></i>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr v-for="province_map in serviceinfos.service_province_map" :key="province_map.id"  class="hover:bg-gray-100 focus-within:bg-gray-100">
                            <td class="pb-2 pt-3 px-3 border-t">{{ service_name }}</td>
                            <td class="pb-2 pt-3 px-3 border-t">{{ province_map.officelayer.office_layer_name }}</td>
                            <td class="pb-2 pt-3 px-3 border-t">{{ province_map.provience.provience_name }}</td>
                            <td class="pb-2 pt-3 px-3 border-t">
                                <div class="flex items-center px-2">
                                    <div class="flex items-center px-4"  tabindex="0" @click="service_assign(serviceinfos,'P',province_map.id)">
                                        <i class="fa fa-edit"></i>
                                    </div>
                                    <div class="flex items-center px-4"  tabindex="0" @click="delete_service_map(province_map.id,'P')">
                                        <i class="fa fa-trash"></i>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr v-for="municipality_map in serviceinfos.service_municipality_map" :key="municipality_map.id"  class="hover:bg-gray-100 focus-within:bg-gray-100">
                            <td class="pb-2 pt-3 px-3 border-t">{{ service_name }}</td>
                            <td class="pb-2 pt-3 px-3 border-t">{{ municipality_map.officelayer.office_layer_name }}</td>
                            <td class="pb-2 pt-3 px-3 border-t">{{ municipality_map.municipality.municipality_name }}</td>
                            <td class="pb-2 pt-3 px-3 border-t">
                                <div class="flex items-center px-2">
                                    <div class="flex items-center px-4"  tabindex="0" @click="service_assign(serviceinfos,'MU',municipality_map.id)">
                                        <i class="fa fa-edit"></i>
                                    </div>
                                    <div class="flex items-center px-4"  tabindex="0" @click="delete_service_map(municipality_map.id,'MU')">
                                        <i class="fa fa-trash"></i>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr v-for="barangay_map in serviceinfos.service_barangay_map" :key="barangay_map.id"  class="hover:bg-gray-100 focus-within:bg-gray-100">
                            <td class="pb-2 pt-3 px-3 border-t">{{ service_name }}</td>
                            <td class="pb-2 pt-3 px-3 border-t">{{ barangay_map.officelayer.office_layer_name }}</td>
                            <td class="pb-2 pt-3 px-3 border-t">{{ barangay_map.barangay.barangay_name }}</td>
                            <td class="pb-2 pt-3 px-3 border-t">
                                <div class="flex items-center px-2">
                                    <div class="flex items-center px-4"  tabindex="0" @click="service_assign(serviceinfos,'B',barangay_map.id)">
                                        <i class="fa fa-edit"></i>
                                    </div>
                                    <div class="flex items-center px-4"  tabindex="0" @click="delete_service_map(barangay_map.id,'B')">
                                        <i class="fa fa-trash"></i>
                                    </div>
                                </div>
                            </td>
                        </tr>

                    </tbody>
                    </table>
                </div>
                <!-- <pre>
                    {{ serviceinfos }}            
                </pre> -->
                
            </div>        
        </div>
    </div>
   
    <template v-if="assign_on">
        <!-- <div class="black_overlay" @click="remove_assign"></div> -->
        <div :class="['assign_setup_block', {active:assign_on}]">       
          <div class="flex items-center justify-center close_btn_assign" @click="remove_assign"><i class="fa fa-times"></i></div>
          <div class="assign_info">Service Name : {{ sel_service_name }}</div>
            <div class="grid grid-flow-col gap-3">
              <div>
                <officeAssign :servicedata="servicedata" :paymentdata="payment_data" :service_name="sel_service_name" :service_id="service_id" :sel_type="ser_type" :sel_id="ser_id" :employee_geo_map="geodata" :others="othersdata" />
              </div>
              
            </div>
        </div>
    </template>
</template>
<script>
    import axios from "axios"
    import { ref, toRefs,onMounted  } from "vue"
    import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
    import ContentHeaderSectionButton from "@/Shared/ContentHeaderSectionButton"
    import FloatingActionBtn from "@/Shared/Buttons/FloatingActionBtn"
    import SelectInputFunction from '@/Shared/SelectInputFunction'
    import SelectInput from '@/Shared/SelectInput'
    import officeAssign from './officeAssign'
    export default {
        name: "OfficeLayerBlock",
        props: {
            service_id: Number,
            service_name: String,
            payment_data: Object,
        },
        components: {
            ContentHeaderSectionButton,
            FloatingActionBtn,
            Head,
            Link,
            SelectInputFunction,
            SelectInput, 
            officeAssign           
        },
        setup(props, { emit }){            
            const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
            const { service_id, service_name,payment_data } = toRefs(props)
            const preloader = ref(false) 
            const serviceinfos=ref({})
            const paymentdata=ref({}) 
            const message = ref('');
            const sel_service_name = ref('');
            const assign_on = ref(false) 
            const ser_type=ref('')
            const ser_id=ref('')
            const servicedata=ref({}) 
            const geodata=ref({
                ministry_id: null,
                region_id: 1,
                province_id:null,
                municipality_id:null,
                barangay_id:null,
                office_layer_id:null,
                office_id:null,

                fixed_amount:0,
                pre_amount:null,
                amount:null,
                account_info:null
            })         
            const othersdata=ref({})         
            const loaddata = (id) => {  
                preloader.value = true
                let data={id:id}
                axios.post(`/${backendPrefix.value}/serviceinfos/getservicemap`, data)
                .then(function (response) {
                    if(response.data.status==true){
                        serviceinfos.value=response.data.data;  
                    }
                    preloader.value = false
                });
            }
            onMounted(() => {
                loaddata(service_id.value);
            })
            const service_assign = async (serviceinfos,type,id=null) => {
                ser_type.value = type
                ser_id.value = id      
                servicedata.value = serviceinfos 
                sel_service_name.value =service_name.value
                paymentdata.value = payment_data
                if(type!='' && id>0){
                    let data={
                        id:id,
                        sel_type:type,
                    }
                   await axios.post(`/${backendPrefix.value}/service-map/selected-data`, data)
                    .then(function (response) {
                        geodata.value=response.data.data.geos
                        othersdata.value=response.data.data
                       
                        if(response.data.data.geos!=''){
                            assign_on.value = true 
                        }else{
                        }
                                               
                    });
                }else{
                    assign_on.value = true 
                }

                ////console.log('id:'+id);
            }
            const delete_service_map = (id,type) => {
                if (confirm('Do you really want to delete this?')) {
                    let data={id:id}
                    let url=''
                    if(type=='M'){
                         url=`/${backendPrefix.value}/service-map/delete-ministry-map`;
                    }else if(type=='R'){
                         url=`/${backendPrefix.value}/service-map/delete-region-map`;
                    }
                    else if(type=='P'){
                         url=`/${backendPrefix.value}/service-map/delete-province-map`;
                    }
                    else if(type=='MU'){
                         url=`/${backendPrefix.value}/service-map/delete-municipality-map`;
                    }
                    else if(type=='B'){
                         url=`/${backendPrefix.value}/service-map/delete-barangay-map`;
                    }
                    if(url!=''){
                        axios.post(url, data)
                        .then( async (response) => {   
                           // //console.log(response);           
                            message.value = response.data.message
                            loaddata(service_id.value);
                            preloader.value = false
                        }).catch(error => {
                            ////console.log(error);  
                            preloader.value = false
                        });
                    }
                }

            }
            const remove_assign = () => {
                assign_on.value = false                   
            }
            const remove_msg = () => {                
                message.value = ''
            }
            return {
                preloader,
                backendPrefix,
                service_id, 
                sel_service_name,
                loaddata,
                serviceinfos,
                service_assign,
                remove_assign,
                assign_on,
                ser_type,
                ser_id,
                servicedata,
                delete_service_map,
                remove_msg,
                message, 
                geodata,
                othersdata,
                payment_data,
                paymentdata      
            }
        },
        created: function(){
        }
    }
    
</script>
<style lang="scss" scoped>
    $checkbox_size: 20;
    .office_emp_map_block{
        display: block;
        position: relative;
        // padding-bottom: 50px;
        .select_geo_map_block{
            display: block;

            :deep(.action_btn){
                right: 20px;
                bottom: 20px;
            }

            & > div{
                display: flex;
                gap: 10px;
                align-items: center;
                .checkbox_block{
                    display: inline-block;
                    width: #{$checkbox_size}px; height: #{$checkbox_size}px;
                    line-height: #{$checkbox_size}px; text-align: center; 
                    font-size: 10px;
                    border: 1px solid #ddd;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: all 0.4s;

                    & > i{
                        display: none;
                        opacity: 0; line-height: #{$checkbox_size}px;
                        transition: all 0.4s;
                    }

                    &:hover{
                        color: #ccc;
                        border-color: #ccc;

                        & > i.checked{
                            display: block;
                            opacity: 1;
                        }
                    }
                    &.active{
                        color: #006699;
                        border-color: #006699;
                        & > i.checked{
                            display: block;
                            opacity: 1;
                        }

                        &:hover{
                            color: #CD0000;
                            border-color: #CD0000;
                            & > i.checked{
                                display: none;
                                opacity: 0
                            }
                            & > i.remove{
                                display: block;
                                opacity: 1;
                            }
                        }
                    }
                }
                .info_block{
                    &.active{
                        font-weight: 600;
                    }
                }
            }
        }        
    }
    
    .assign_setup_block{
      position: fixed;
      min-width:1200px;
      background-color: #fff;
      padding: 25px;
      right: 0; top: 0; height: 100%;
      overflow-y: auto;
      z-index: 11000;
      transition: all 0.4s;
  
      &.active{
        right: 0;
      }
  
      & > .assign_info{
        font-size: 14px;
        font-weight: 600;
        height: 46px;
        color: #666;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        border-bottom: 1px solid #ddd;
        justify-content: center;
        border-radius: 15px;
        position: absolute;
        width: calc(100% - 70px);
        left: 40px;
        top: 0;
      }
  
      & > .close_btn_assign{
        position: sticky;
        margin-left: -25px;
        margin-top: -25px;
        top: -25px;
        width: 40px;
        height: 47px;
        background-color: #00000080;      
        color: #fff;      
        cursor: pointer;
        border-radius: 0 0px 15px 0;
        z-index: 5;
      }
    }
    .organogram_btn{
        background-color: #152b74;
        color: #fff;
        border-radius: 25px;
        cursor: pointer;
        transition: all 0.4s;
        height: 22px;
        margin: 10px;
        font-size: 10px;
    
        &:hover{
          background-color: #2d6f90;      
        }
      }
    i.fa.fa-edit {
        cursor: pointer;
    }
    i.fa.fa-trash {
        cursor: pointer;
    }
</style>