<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
      <div class="flex justify-start mb-8 max-w-3xl">
        <img v-if="service.logo" class="block ml-4 w-8 h-8 rounded-full" :src="service.logo" />
      </div>
      <trashed-message v-if="service.deleted_at" class="mb-6" @restore="restore"> This Service Info has been deleted. </trashed-message>
      <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="update">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.service_name" :error="form.errors.service_name" class="pb-8 pr-6 w-full lg:w-1/2" label="Service Name"  required="true" placeholder="i.e Certificate of Live Birth"/>
            <select-input v-model="form.scat_id" :error="form.errors.scat_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Service Category"  required="true">
              <option value=null >Select Service Category</option>
              <option v-for="servicecategory in servicecategories" :key="servicecategory.id" :value='servicecategory.id'>{{ servicecategory.cat_title }}</option>
            </select-input>
            <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full lg:w-1/2" label="Service Slug"  required="true" placeholder="i.e business-permit"/>
            <text-input type="color" v-model="form.color" :error="form.errors.color" class="pb-8 pr-6 w-full lg:w-1/2" label="Dashboard Service Color"  required="true" placeholder="i.e #ffffff"/>
            <file-input v-model="form.logo" :error="form.errors.logo" class="pb-8 pr-6 w-full lg:w-1/2" type="file" accept="image/*" label="Logo" />

            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Service Status &nbsp;&nbsp;&nbsp;&nbsp;" class="pb-8 mt-6 pr-6 w-full lg:w-1/2"></status-toogle-button>
          </div>
          <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
            <button v-if="!service.deleted_at && permission.delete>0" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
            <Button v-if="permission.edit>0" :type="type" :name="head" :url="createUrl" :loading="form.processing"></Button>
          </div>
        </form>
      </div>
    </div>
  </template>

  <script>
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import { ref, toRefs,onMounted, watchEffect,inject } from 'vue'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import ContentHeaderSection from "@/Shared/ContentHeaderSection"
  import InputTag from '@/Shared/InputTag'
  import DeleteIcon from '@/Shared/DeleteIcon'
  import Button from '@/Shared/Button'
  import FileInput from '@/Shared/FileInput'
  export default {
    components: {
      Head,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      Breadcrumbs,
      StatusToogleButton,
      Button,
      ContentHeaderSection,
      FileInput,
      DeleteIcon
    },
    layout: Layout,
    props: {
      servicecategories: Array,
      service: Object,
      permission : Object,
    },
    setup(props){
      const { service,servicecategories,permission} = toRefs(props)
      const type = ref('edit')
      const title = ref(service.value.service_name)
      const head = ref('Service')
      const url = ref('/backend/services')
      const createUrl = ref('')
      const elm = ref(this)
      const swal = inject('$swal')
      const form = useForm({
          id: service.value.id,
          service_name: service.value.service_name,
          status: service.value.status,
          scat_id: service.value.scat_id,
          logo:null,
          slug:service.value.slug,
          color:service.value.color
      })
      const update = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}/${service.value.id}`, {forceFormData: true,})
              title.value = form.service_name
              Swal.fire(
                'Updated!',
                'Your infomation has been Updated.',
                'success'
              )
            }
          });
      }

      const destroy = () => {
        swal({
            title: 'Are you sure?',
            text: "Do you really want to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.delete(`${url.value}/${service.value.id}`)
              Swal.fire(
                'Deleted!',
                'Your infomation has been deleted.',
                'success'
              )
            }
          });
      }
      const restore = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to restore this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Resore it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.put(`${url.value}/${service.value.id}/restore`)
              Swal.fire(
                'Restored!',
                'Your infomation has been Restored.',
                'success'
              )
            }
          });
      }
      const statusCheck = () => {
        if(service.value.status==1){
          form.status=true;
          return true;
        }else{
          form.status=false;
          return false;
        }
      }
      return {
        form,
        service,
        type,
        title,
        head,
        url,
        createUrl,
        update,
        destroy,
        restore,
        statusCheck,
        swal,
        permission,
        servicecategories
      }
    },
    created: function(){
      this.statusCheck();
    }
  }
  </script>
