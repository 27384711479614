<template>
    <div class="w-full lg:w-1/1">
        <fieldset class="border border-solid border-gray-300">
            <legend class="text-sm">Payment Info</legend>
            <div class="flex flex-wrap -mb-8 -mr-6 p-8 " v-for="(payment, counter) in payments" v-bind:key="counter">
                <div class="pb-8 pr-6 w-full lg:w-1/2">
                    <label class="form-label">Payment Options</label>
                    <div class="flex justify-center">
                        <div>
                            <div class="form-check">
                                <input
                                    class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                    type="checkbox" v-model="payment.payment_method" id="payment_method1" value="1" />
                                <label for="payment_method1"
                                    class="form-check-label inline-block text-gray-800">GAMSWITCH</label>
                            </div>

                            <div class="form-check">
                                <input
                                    class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                    type="checkbox" v-model="payment.payment_method" id="payment_method5" value="5" />
                                <label class="form-check-label inline-block text-gray-800" for="payment_method5">Payment
                                    Slip (Offline)</label>
                            </div>

                        </div>
                    </div>

                </div>
                <div class="pb-8 pr-6 w-full lg:w-1/2">
                    <label class="form-label">Payment Type</label>
                    <div class="justify-center ">
                        <div class="form-check">
                            <input
                                class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                v-model="payment.payment_type" id="payment_type1" type="radio" value="1" />
                            <label for="payment_type1"
                                class="form-check-label inline-block text-gray-800">Pre-Payment</label>
                        </div>
                        <div class="form-check">
                            <input
                                class="form-check-input  appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                v-model="payment.payment_type" type="radio" id="payment_type2" value="2" />
                            <label class="form-check-label inline-block text-gray-800" for="payment_type2">Post
                                Payment</label>
                        </div>
                        <div class="form-check">
                            <input
                                class="form-check-input  appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                v-model="payment.payment_type" type="radio" id="payment_type3" value="3" />
                            <label class="form-check-label inline-block text-gray-800" for="payment_type3">Custom
                                Payment</label>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="pb-8 pr-6 w-full lg:w-1/1">
                    <div class="form-check">
                        <input checked
                            class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                            type="checkbox" true-value="1" false-value="false" v-model="payment.fixed_amount"
                            id="fixed_amount" />
                        <label for="fixed_amount" class="form-check-label inline-block text-gray-800">Fixed
                            Amount</label>
                    </div>
                </div>
                <template v-if="payment.fixed_amount == 1 && (payment.payment_type == 1 || payment.payment_type == 3)">
                    <text-input v-model="payment.pre_amount" class="pb-8 pr-6 w-full lg:w-1/2" label="Pre-Amount"
                        type="number" placeholder="i.e 100" step="any" />
                </template>
                <template v-if="payment.fixed_amount == 1 && (payment.payment_type == 2 || payment.payment_type == 3)">
                    <text-input v-model="payment.amount" class="pb-8 pr-6 w-full lg:w-1/2" label="Post-Amount"
                        type="number" placeholder="i.e 100" step="any" />
                </template>
                <div class="pb-8 pr-6 w-full lg:w-1/1">
                    <label class="form-label" for="content">Payment Instruction <span
                            class="custom-error">*</span></label>
                    <vue-editor v-model="payment.instruction" placeholder="i.e Instruction" />
                </div>
                <!-- <div class="flex flex-wrap items-stretch w-full mb-4 relative">
              <div class="flex -mr-px">
                <span class="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark text-sm">@</span>
              </div>
              <input type="text" class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative focus:border-blue focus:shadow" placeholder="Username">
            </div> -->
                <!-- <select-input class="pb-8 pr-6 w-full lg:w-1/2" label="Payment Gateway"   v-model="payment.gateway_id"  >
              <option value=null >Select Payment Gateway</option>
              <option v-for="payment_gateway in payment_gateways" :key="payment_gateway.id" :value='payment_gateway.id'>{{ payment_gateway.name }}</option>
            </select-input> -->
                <!-- <text-input v-model="payment.amount" class="pb-8 pr-6 w-full lg:w-1/2" label="Amount" type="number" placeholder="i.e 100" oninput="this.value = Math.abs(this.value)" /> -->
            </div>
        </fieldset>
    </div>
</template>
<script>
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject } from 'vue'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Button from '@/Shared/Button'
import ContentHeaderSection from "@/Shared/ContentHeaderSection"
import { VueEditor, Quill } from "vue3-editor";
export default {
    components: {
        Head,
        Link,
        SelectInput,
        TextInput,
        StatusToogleButton,
        Button,
        ContentHeaderSection,
        VueEditor,
        Quill
    },
    remember: 'form',
    props: {
        payments: Array,
        payment_gateways: Array
    },
    setup(props) {
        const { payments, payment_gateways } = toRefs(props)
        return {
            payments,
            payment_gateways,
        }
    },
}
</script>
<style>
legend.text-sm {
    font-size: 14px;
    font-weight: 500;
    padding-left: 5px;
    padding-right: 5px;
}

.form-check {
    padding: 2px;
}

.ql-container.ql-snow {
    height: auto !important;
}

.ql-toolbar .ql-formats .ql-image {
    display: none;
}
</style>
