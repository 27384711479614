<template>
    <div>
        <div class="emp_organogram_block">
            <ContentHeaderSection :title="'Decision List'" :icon="'fa fa-bar'" />

            <div class="" v-if="(designation_data.length > 0)">
                <app-accordion class="block p-2 rounded-lg shadow-lg bg-white w-full mt-2"
                    v-for="(designation, n_index) in designation_data" :key="designation.id">
                    <template v-slot:title>
                        <span class="font-semibold text-sm">
                            {{ designation.serial }} . {{ designation.name }}
                        </span>
                    </template>
                    <template v-slot:content>
                        <status-toogle-button v-model="designation.can_print" label="Print"
                            class=""></status-toogle-button>
                        <status-toogle-button v-model="designation.draft_print" label="Draft Print"
                            class=""></status-toogle-button>
                        <status-toogle-button v-model="designation.summary_view" label="Summary"
                            class=""></status-toogle-button>
                        <p class="text-gray-700 text-base mb-1 mt-2">
                            <Multiselect v-model="designation.decision" mode="tags" placeholder="Select Decision Step"
                                :options="decison" :search="true" @select="onChangeAddData(n_index)"
                                @deselect="onChangeRemoveData($event, n_index)" />
                        </p>
                        <ul class="w-full rounded-lg mt-2 mb-3 text-blue-800">
                            <template v-for="(deci, s_index) in designation.alldecision" :key="deci.id">
                                <li class="mb-1">
                                    <div class="flex items-center gap-2 px-1 designation_block active justify">
                                        <div class="input_block"><i class="fa fa-check check"></i></div>
                                        <div class="designation">
                                            {{ getDecisionName(decison, deci.id) }}
                                        </div>
                                        <div class=" ml-auto w-[10rem] mt-2"
                                            v-if="designation.decision.includes(deci.id) && (deci.id == 1 || deci.id == 22)">
                                            <template v-if="(deci.id == 1 || deci.id == 22)">
                                                <label class="form-label">Desk</label>
                                                <select v-model="deci.designation" class="form-select">
                                                    <option value="0">Open</option>
                                                    <!-- <template v-if="deci.id == 1">
                                                        <template v-for="(designation_op, s_index) in designation_data"
                                                            :key="designation_op.id">
                                                            <option v-if="designation.serial < designation_op.serial"
                                                                :value="designation_op.id">{{ designation_op.name }}</option>
                                                        </template>
</template> -->
                                                    <template v-for="(designation_op, s_index) in designation_data"
                                                        :key="designation_op.id">
                                                        <option v-if="designation.serial != designation_op.serial"
                                                            :value="designation_op.id">{{ designation_op.name }}
                                                        </option>
                                                    </template>
                                                </select>
                                            </template>
                                        </div>
                                        <div class="ml-auto mt-8"
                                            v-if="designation.decision.includes(deci.id) && ((deci.id == 1 || deci.id == 22))">
                                            Or</div>
                                        <div class=" ml-auto w-[10rem] mt-2"
                                            v-if="designation.decision.includes(deci.id) && ((deci.id == 1 || deci.id == 22))">
                                            <template v-if="(deci.id == 1 || deci.id == 22)">
                                                <label class="form-label">Office</label>
                                                <select v-model="deci.org_id" class="form-select"
                                                    @change="loadOffice($event, deci.org_id, n_index, s_index)">
                                                    <option value=null>Select Office</option>
                                                    <template v-for="(layer, s_index) in layerData" :key="layer.id">
                                                        <option :value="layer.id" :data="layer.layer">{{ layer.name }}
                                                        </option>
                                                    </template>
                                                </select>
                                            </template>
                                        </div>
                                        <div class=" ml-auto w-[10rem] mt-2"
                                            v-if="designation.decision.includes(deci.id) && ((deci.id == 1 || deci.id == 22))">
                                            <template v-if="(deci.id == 1 || deci.id == 22)">
                                                <label class="form-label">Desk</label>
                                                <select v-model="deci.designation" class="form-select">
                                                    <option value=null>Select Desk</option>
                                                    <template v-for="(office, s_index) in deci.officeData"
                                                        :key="office.id">
                                                        <option :value="office.id">{{ office.name }}</option>
                                                    </template>
                                                </select>
                                            </template>
                                        </div>
                                        <div class=" ml-auto w-[10rem]"
                                            v-else-if="designation.decision.includes(deci.id) && (deci.id == 2)">
                                            <template v-if="deci.id == 2">
                                                <label class="form-label">Desk</label>
                                                <select v-model="deci.designation" class="form-select">
                                                    <option value="0">Open</option>
                                                    <template v-if="deci.id == 2">
                                                        <template v-for="(designation_op, s_index) in designation_data"
                                                            :key="designation_op.id">
                                                            <option v-if="designation.serial != designation_op.serial"
                                                                :value="designation_op.id">{{ designation_op.name }}
                                                            </option>
                                                        </template>
                                                    </template>
                                                </select>
                                            </template>
                                        </div>
                                    </div>
                                    <status-toogle-button v-model="deci.is_open" label="Open "
                                        class="px-1"></status-toogle-button>
                                    <div class="flex items-center gap-2 px-1 designation_block active justify"
                                        v-if="s_index > 0">
                                        <div>
                                            <status-toogle-button v-model="deci.has_prerequisite" label="Prerequisite"
                                                class=""></status-toogle-button>
                                        </div>
                                        <div class=" ml-auto w-[10rem]" v-if="deci.has_prerequisite > 0">
                                            <label class="form-label">Decision</label>
                                            <select v-model="deci.prerequisite" class="form-select">
                                                <option value=null>Please Select Decision</option>
                                                <template v-for="(decisonInfo, s_index) in decisonInfos"
                                                    :key="decisonInfo.id">
                                                    <option :value="decisonInfo.id">{{ decisonInfo.decision_title }}
                                                    </option>
                                                </template>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="flex mt-5 px-1">
                                        <div class="designation">Data Input</div>
                                        <div class="ml-auto">
                                            <ul class="grid grid-cols-2 gap-x-2  mx-auto ">
                                                <li class="relative">
                                                    <input class="sr-only peer" type="radio" value="yes"
                                                        v-model="deci.approve_status"
                                                        :id="designation.serial + '_answer_yes_f_' + deci.id">
                                                    <label
                                                        class="flex p-1 px-4 bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-green-500 peer-checked:ring-2 peer-checked:border-transparent"
                                                        :for="designation.serial + '_answer_yes_f_' + deci.id">Yes</label>
                                                    <div
                                                        class="absolute hidden w-2 h-2 peer-checked:block top-[4px] right-2">
                                                        <i class="fa-solid fa-check"></i>
                                                    </div>
                                                </li>
                                                <li class="relative">
                                                    <input class="sr-only peer" type="radio" value="no"
                                                        v-model="deci.approve_status"
                                                        :id="designation.serial + 'answer_no_f_' + deci.id">
                                                    <label
                                                        class="flex p-1 px-4 bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-red-500 peer-checked:ring-2 peer-checked:border-transparent"
                                                        :for="designation.serial + 'answer_no_f_' + deci.id">No</label>
                                                    <div
                                                        class="absolute hidden w-2 h-2 peer-checked:block top-[4px] right-2">
                                                        <i class="fa-solid fa-xmark"></i>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="flex mt-3 px-1" v-if="deci.approve_status == 'yes'">
                                        <div class="designation">Data Input Fields</div>
                                        <div class="flex mx-2  ">
                                            <div class="grid grid-cols-2">
                                                <!-- <pre>{{ deci }}</pre> -->
                                                <div class="flex flex-row items-center gap-1 form-check form-check-inline px-2 mb-2 h-8"
                                                    v-for="(approvedata, s_index) in approvedatas"
                                                    :key="approvedata.id">
                                                    <input type="checkbox" v-model="deci.approve_data"
                                                        :id="approvedata.id"
                                                        class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
                                                        :value='approvedata.id'
                                                        @change="approve_data_update(deci)">&nbsp;
                                                    <label :for="approvedata.id"
                                                        class="form-check-label inline-block text-gray-600">{{
                                                        approvedata.value
                                                        }}</label>
                                                    <status-toogle-button v-model="deci.optional[approvedata.id]"
                                                        label="" class="px-1 w-[30px]"></status-toogle-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex mt-3 px-1" v-if="deci.approve_status == 'yes'">
                                        <div class="designation">Data</div>
                                        <div class="ml-auto">
                                            <ul class="grid grid-cols-2 gap-x-2  mx-auto ">
                                                <li class="relative">
                                                    <input class="sr-only peer" type="radio" value="view"
                                                        v-model="deci.mode" name="field_mode"
                                                        :id="designation.serial + 'field_mode_view_f_' + deci.id">
                                                    <label
                                                        class="flex p-1 px-4 bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-green-500 peer-checked:ring-2 peer-checked:border-transparent"
                                                        :for="designation.serial + 'field_mode_view_f_' + deci.id">View</label>
                                                    <div
                                                        class="absolute hidden w-2 h-2 peer-checked:block top-[4px] right-2">
                                                        <i class="fa-regular fa-eye"></i>
                                                    </div>
                                                </li>
                                                <li class="relative">
                                                    <input class="sr-only peer" type="radio" value="write"
                                                        v-model="deci.mode"
                                                        :id="designation.serial + 'field_mode_write_f_' + deci.id">

                                                    <label
                                                        class="flex p-1 px-4 bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-indigo-500 peer-checked:ring-2 peer-checked:border-transparent"
                                                        :for="designation.serial + 'field_mode_write_f_' + deci.id">Write</label>
                                                    <div
                                                        class="absolute hidden w-2 h-2 peer-checked:block top-[4px] right-[6px]">
                                                        <i class="fa-regular fa-pen-to-square"></i>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="flex items-center gap-2 px-1 designation_block active justify mt-2"
                                        v-if="designation.decision.includes(deci.id) && ((deci.id == 6) || (deci.id == 9))">
                                        <div>
                                            <status-toogle-button v-model="deci.has_proceed" label="Proceed "
                                                class=""></status-toogle-button>
                                        </div>

                                        <div class=" ml-auto w-[10rem]">
                                            <template v-if="deci.has_proceed > 0">
                                                <label class="form-label">Desk</label>
                                                <select v-model="deci.proceed" class="form-select">
                                                    <option value=null>None</option>
                                                    <template v-if="deci.has_proceed > 0">
                                                        <template v-for="(designation_op, s_index) in designation_data"
                                                            :key="designation_op.id">
                                                            <option v-if="designation.serial != designation_op.serial"
                                                                :value="designation_op.id">{{ designation_op.name }}
                                                            </option>
                                                        </template>
                                                    </template>
                                                </select>
                                            </template>
                                        </div>
                                    </div>
                                </li>
                            </template>
                        </ul>
                    </template>
                </app-accordion>

                <div class="mt-2">
                    <FloatingActionBtnFix :loader="preloader" :icon="'fa fa-check'" :message="message"
                        @dataSubmit="data_submit" @removeMessage="remove_msg" />
                </div>

            </div>
            <div v-else class="list_block m-5">
                <div class="office_block mt-4 p-4">
                    <div class="empty_content_block">
                        <div>
                            <i class="fa fa-box-open"></i>
                        </div>
                        <div class="mt-2">There is no data. Choose Organogram to get Decision List

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import axios from 'axios'
import { ref, toRefs, watchEffect, onMounted, watch } from 'vue'
import ContentHeaderSection from "@/Shared/ContentHeaderSection"
import FloatingActionBtnFix from "@/Shared/Buttons/FloatingActionBtnFix"
import { VueDraggableNext } from 'vue-draggable-next'
import Multiselect from '@vueform/multiselect'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import AppAccordion from "@/Shared/AppAccordion";
export default {
    name: 'EmpOrganogramBlock',
    props: {
        designation_ids: Object,
        designation_data: Array,
        service_id: Number,
        geo_infos: Object
    },
    components: {
        ContentHeaderSection,
        FloatingActionBtnFix,
        draggable: VueDraggableNext,
        Multiselect,
        StatusToogleButton,
        AppAccordion
    },
    setup(props) {
        const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
        const { service_id, designation_ids, designation_data, geo_infos } = toRefs(props)
        const preloader = ref(false)
        const sel_designations = ref(designation_ids)
        const message = ref('');
        const approvedatas = ref([])
        const decisions = ref([]);
        const value = ref([]);
        const dec_data = ref([]);
        const decison = ref([])
        const decisonInfos = ref([])
        const sel_designations_data = ref(designation_data)
        const deskassign = ref(designation_data)
        const layerData = ref([])
        const officeData = ref([])

        const remove_msg = () => {
            message.value = ''
        }
        const onSelect = (event, index) => {
            let obj = {
                id: event,
                designation: null,
                layer: null,
                approve_status: null,
                mode: null,
                approve_data: [],
                officeData: [],
                optional: {}
            }
            sel_designations_data.value[index].alldecision.push(obj)
        }

        const approve_data_update = (data) => {
            if (!data.optional) data.optional = {}
            for (var i = 0; i < data.approve_data.length; i++) {
                data.optional[data.approve_data[i]] = false
            }

            //   //console.log('Get update data', data)
        }

        const onChangeAddData = (i) => {
            let v = designation_data.value[i].alldecision
            let d = designation_data.value[i].decision
            let obj = {
                id: designation_data.value[i].decision[d.length - 1],
                designation: v.designation ? v.designation : null,
                layer: v.layer ? v.layer : null,
                approve_status: v.approve_status ? v.approve_status : null,
                mode: v.mode ? v.mode : null,
                approve_data: v.approve_data ? v.approve_data : [],
                officeData: v.officeData ? v.officeData : [],
                optional: v.optional ? v.optional : {}
            }
            sel_designations_data.value[i].alldecision.push(obj)
        }

        const onChangeRemoveData = (e, i) => {
            ////console.log(e)
            ////console.log(i)
            sel_designations_data.value[i].alldecision.forEach((v, ri) => {
                if (v.id == e) {
                    sel_designations_data.value[i].alldecision.splice(ri, 1)
                    return true
                }
            })
        }

        const getDecisionName = (decision, deci_id) => {
            // //console.log(decision)
            // return 'hello'
            // decision.find(obj => obj.value === deci_id ? obj.label:'')
            let str = ''
            for (let index in decision) {
                if (decision[index].value == deci_id) {
                    str = decision[index].label
                    break
                }
            }

            return str
        }

        const multilelect = {
            mode: 'tags',
            placeholder: 'Select options',
            closeOnSelect: false,
            searchable: true,
            object: true,
            resolveOnLoad: false,
            delay: 0,
            minChars: 1,
            options: decisions
        }
        const loaddision = async () => {
            preloader.value = true
            let data = {
                service_id: service_id
            }
            await axios.post(`/${backendPrefix.value}/serviceinfos/getallDesion`, data)
                .then(function (response) {
                    // //console.log('=======');
                    // //console.log(response);
                    // //console.log('=======');
                    response.data.data.forEach(element => {
                        if (element != null) {
                            let obj = {
                                value: element.id, label: element.d_title
                            }
                            decison.value.push(obj);
                        }

                    });
                    //   //console.log(decison);
                    //  decisions.value=response.data.data;
                    preloader.value = false
                });
        }
        const loaddisionInfo = async () => {
            preloader.value = true
            let data = {
                service_id: service_id
            }
            await axios.post(`/${backendPrefix.value}/serviceinfos/getAllDesionInfo`, data)
                .then(function (response) {
                    decisonInfos.value = response.data.data
                    //  //console.log(decisonInfos);
                    //  decisions.value=response.data.data;
                    preloader.value = false
                });
        }
        const loadOfficeLayer = async () => {
            preloader.value = true
            let data = {
                service_id: service_id.value,
                officeLayer: geo_infos.value.office_layer_id
            }
            await axios.post(`/${backendPrefix.value}/serviceinfos/getOfficeLayer`, data)
                .then(function (response) {
                    layerData.value = response.data.data
                    preloader.value = false
                });
            //loadOffice();
        }


        const loadOffice = async (event, org_id, parent_index = null, child_index = null) => {
            preloader.value = true
            let data = {
                officeLayer: event.target.options[event.target.options.selectedIndex].getAttribute('data'),
                org_id: org_id
            }
            await axios.post(`/${backendPrefix.value}/serviceinfos/getDesk`, data)
                .then(function (response) {
                    if (response.status == false) {
                        officeData.value = [];
                    } else {
                        sel_designations_data.value[parent_index].alldecision[child_index].officeData = response.data.data

                        const index = layerData.value.findIndex(p => p.id == org_id);

                        sel_designations_data.value[parent_index].alldecision[child_index].layer = layerData.value[index].layer

                        officeData.value = response.data.data
                    }
                    preloader.value = false
                });
        }

        const approvedata = async () => {
            preloader.value = true
            let data = {
                service_id: service_id.value
            }
            await axios.post(`/${backendPrefix.value}/serviceinfos/approvedata`, data)
                .then(function (response) {
                    approvedatas.value = response.data.data;
                    preloader.value = false
                });
        }
        watch(props.geo_infos, (newValue, oldValue) => {
            loadOfficeLayer();
        })
        onMounted(() => {
            loaddision();
            approvedata();
            loaddisionInfo();
            loadOfficeLayer();
        })

        const data_submit = () => {
            preloader.value = true
            axios.post(`/${backendPrefix.value}/service-map`, {
                designation_data: designation_data.value,
                geos: geo_infos.value,
                service_id: service_id.value
            }).then(async (response) => {
                ////console.log(response);
                message.value = response.data.message
                preloader.value = false
            }).catch(error => {
                // //console.log(error);
                preloader.value = false
            })
        }

        return {
            preloader,
            service_id,
            designation_ids,
            message,
            remove_msg,
            backendPrefix,
            loaddision,
            designation_data,
            decisions,
            deskassign,
            multilelect,
            decison,
            data_submit,
            geo_infos,
            dec_data,
            approvedata,
            onSelect,
            approvedatas,
            sel_designations_data,
            getDecisionName,
            onChangeAddData,
            onChangeRemoveData,
            approve_data_update,
            decisonInfos,
            loaddisionInfo,
            loadOfficeLayer,
            layerData,
            loadOffice,
            officeData
        }
    },

}
</script>
<style lang="scss" scoped>
.designation_block {
    min-height: 24px;

    &>.input_block {
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        border: 1px solid #999;
        justify-content: center;
        border-radius: 5px;
    }

    &.active,
    &:hover {
        color: #006699;

        &>.input_block {
            border-color: #006699;

            &>i.remove {
                display: none;
            }

            &>i.check {
                display: block;
                color: #006699
            }
        }
    }

}
</style>
<style>
span.action_button {
    width: 100%;
}

.listofdes {
    margin: 3px 0px;
    border: 1px solid #eee;
    border-radius: 8px;
}

.listofdes>.input_block i.rounded-full {
    border: 1px solid #999;
}

.listofdes>.input_block i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    font-size: 10px;
}

.office_block {
    border-radius: 10px;
}

.empty_content_block>div i,
.loader_content_block>div i {
    font-size: 34px;
    color: #ccc;
}

.empty_content_block>div,
.loader_content_block>div {
    display: block;
    text-align: center;
}

.emp_organogram_block .list_block {
    border: 1px solid #ddd;
    border-radius: 5px;
}

li.mb-1 {
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    padding: 8px;
}

.block.p-2.rounded-lg.shadow-lg.bg-white.w-full {
    border: 1px solid #c7c4c4;
}
</style>
