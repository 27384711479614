<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs> 
      <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="store">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <select-input v-model="form.office_layer_id" :error="form.errors.office_layer_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Office layer">
              <option value='0' >Select Office Layer</option>
              <option v-for="officelayer in officelayers" :key="officelayer.id" :value='officelayer.id'>{{ officelayer.office_layer_name }}</option>
            </select-input>
            <select-input v-model="form.ministry_id" :error="form.errors.ministry_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Ministry">
              <option value='0' >Select Ministry</option>
              <option v-for="ministry in ministries" :key="ministry.id" :value='ministry.id'>{{ ministry.ministry_name }}</option>
            </select-input>
            <select-input-function v-model="form.region_id" :error="form.errors.region_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Region"  :action="loadProvinces">
              <option value='0' >Select Region</option>
              <option v-for="region in regions" :key="region.id" :value='region.id'>{{ region.region_name }}</option>
            </select-input-function>
            <select-input-function v-model="form.provience_id" :error="form.errors.provience_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Provience" :action="loadMunicipality">
              <option value='0' >Select Provience</option>
              <option v-for="province in provinces" :key="province.id" :value='province.id'>{{ province.provience_name }}</option>
            </select-input-function> 
            <select-input-function v-model="form.municipality_id" :error="form.errors.municipality_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Municipality" :action="loadBarangay">
              <option value='0' >Select Municipality</option>
              <option v-for="municipal in municipality" :key="municipal.id" :value='municipal.id'>{{ municipal.municipality_name }}</option>
            </select-input-function>
            <select-input v-model="form.barangay_id" :error="form.errors.barangay_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Barangay" >
              <option value='0' >Select Barangay</option>
              <option v-for="barangay in barangays" :key="barangay.id" :value='barangay.id'>{{ barangay.barangay_name }}</option>
            </select-input>                       
            <select-input v-model="form.office_id" :error="form.errors.office_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Office" >
              <option value='0' >Select Office</option>
              <option v-for="office in offices" :key="office.id" :value='office.id'>{{ office.office_name }}</option>
            </select-input>
            <select-input v-model="form.sections_id" :error="form.errors.sections_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Section" >
              <option value='0' >Select Section</option>
              <option v-for="section in sections" :key="section.id" :value='section.id'>{{ section.section_name }}</option>
            </select-input>
            <select-input v-model="form.designation_id" :error="form.errors.designation_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Designation" >
              <option value='0' >Select Designation</option>
              <option v-for="designation in designations" :key="designation.id" :value='designation.id'>{{ designation.designation_name }}</option>
            </select-input>
           
            <text-input v-model="form.hierarchy" :error="form.errors.hierarchy" class="pb-8 pr-6 w-full lg:w-1/2" label="Hierarchy" type="number" oninput="this.value = Math.abs(this.value)" min="0" />
                               
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status Of Organograms" class="pb-8 pr-6 w-full lg:w-1/2"></status-toogle-button>          
          </div>
          <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
            <loading-button :loading="form.processing" class="btn-indigo" type="submit">Create Office Organograms</loading-button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { Head, Link } from '@inertiajs/inertia-vue3'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import SelectInputFunction from '@/Shared/SelectInputFunction'
  export default {
    components: {
      Head,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      StatusToogleButton,
      Breadcrumbs,
      SelectInputFunction
    },
    layout: Layout,
    remember: 'form',
    data() {
      return {
        form: this.$inertia.form({
          office_layer_id: null,
          ministry_id: null,
          region_id: null,
          provience_id: null,
          municipality_id: true,
          barangay_id: null,
          office_id: null,         
          designation_id: null,
          sections_id: null,
          hierarchy: null,
          status: true,
        }),
        type:"create",
        title:"Office Organograms",
        url:'/backend/organograms',
        provinces: Array,
        municipality: Array,
        barangays: Array,
      }
    },
    props: {
      regions: Array,      
      ministries: Array,
      officelayers: Array,
      offices: Array,
      sections: Array,
      designations: Array,
    }, 
    methods: {
      store() {
        this.form.post('/backend/organograms')
      },
      async loadProvinces(event){
        if(event.target.value>0){
          let data={
            geo_region_id:event.target.value
          }
          await axios.post('../provinces/activeprovinces', data)
          .then(function (response) {           
              this.provinces = response.data;
          }.bind(this));   
        }else{
          this.provinces =[];
        }
      },
      async loadMunicipality(event){
        if(event.target.value>0){
          let data={
            geo_provience_id:event.target.value
          }
          await axios.post('../municipalities/activemunicipalities', data)
          .then(function (response) {
              this.municipality = response.data;
          }.bind(this));   
        }else{
          this.municipality =[];
        }
      },
      async loadBarangay(event){
        if(event.target.value>0){
          let data={
            geo_municipality_id:event.target.value
          }
          await axios.post('../barangays/activebarangays', data)
          .then(function (response) {
            ////console.log(response);
              this.barangays = response.data;
          }.bind(this));   
        }else{
          this.barangays =[];
        }
      },
    },
  }
  </script>
  