<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs> 
      <trashed-message v-if="designation.deleted_at" class="mb-6" @restore="restore"> This designation has been deleted. </trashed-message>
      <div class="max-w-2xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="update">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.designation_name" :error="form.errors.designation_name" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Designation"  required="true" placeholder="i.e Mayor"/>    
            <text-input v-model="form.designation_short_name" :error="form.errors.designation_short_name" class="pb-8 pr-6 w-full lg:w-1/2" label="Short Designation"  required="true" placeholder="i.e BPLO"/> 
            <text-input v-model="form.designation_order" :error="form.errors.designation_order" class="pb-8 pr-6 w-full lg:w-1/2" label="Designation Order"  required="true" placeholder="i.e 1"/>   
            <select-input v-model="form.reporting_designation" :error="form.errors.reporting_designation" class="pb-8 pr-6 w-full lg:w-1/2" label="Reporting Designation" >
              <option value='0' >Select Reporting Designation</option>
              <option v-for="sindesignation in designations" :key="sindesignation.id" :value='sindesignation.id'>{{ sindesignation.designation_name }}</option>
            </select-input>      
            <select-input v-model="form.section_id" :error="form.errors.section_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Divison" required="true">
              <option value='0' >Select Section</option>
              <option v-for="officeSection in officeSections" :key="officeSection.id" :value='officeSection.id'>{{ officeSection.section_name }}</option>
            </select-input>  
            <select-input v-model="form.type" :error="form.errors.type" class="pb-8 pr-6 w-full lg:w-1/2" label="Type" required="true">
              <option value=null >Select type</option>
              <option value='1' >Senior</option>
              <option value='2' >Others</option>              
            </select-input>  
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Designation" class="pb-8 pr-6 w-full lg:w-1/1" :checked="statusCheck"></status-toogle-button>          
          </div>
          <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
            <!-- <button v-if="!designation.deleted_at" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button> -->
            <Button v-if="permission.edit>0" :type="type" :name="head" :url="createUrl" :loading="form.processing"></Button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import { ref, toRefs, inject } from 'vue'
  import Icon from '@/Shared/Icon'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import TrashedMessage from '@/Shared/TrashedMessage'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import DeleteIcon from '@/Shared/DeleteIcon'
  import Button from '@/Shared/Button'
  export default {
    components: {
      Head,
      Icon,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      TrashedMessage,
      StatusToogleButton,
      Breadcrumbs,
      DeleteIcon,
      Button
    },
    layout: Layout,
    props: {
      designation: Object,
      officeSections: Array,
      designations: Array,
      permission: Object, 
    },
    remember: 'form',
    setup(props){
      const { officeSections,designation,permission,designations } = toRefs(props)
      const type = ref('edit')
      const title = ref(designation.value.designation_name)
      const head = ref('Designation')
      const url = ref('/backend/designations')
      const createUrl = ref('')
      const elm = ref(this)
      const swal = inject('$swal')
      const form = useForm({
        designation_name: designation.value.designation_name,          
        designation_short_name: designation.value.designation_short_name,          
        status: designation.value.status, 
        type: designation.value.type, 
        section_id: designation.value.section_id,
        designation_order: designation.value.designation_order,
        reporting_designation: designation.value.reporting_designation
      })
      const update = () => {
          swal({
              title: 'Are you sure?',
              text: "You want to proceed with this action",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Confirm',
              cancelButtonText: 'Cancel',
            }).then((result) => {
              if (result.isConfirmed) {
                form.put(`${url.value}/${designation.value.id}`)
                title.value = form.designation_name
                Swal.fire(
                  'Updated!',
                  'Your infomation has been Updated.',
                  'success'
                )
              }
            });
      }
      const destroy = () => {
        swal({
            title: 'Are you sure?',
            text: "Do you really want to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.delete(`${url.value}/${designation.value.id}`)
              Swal.fire(
                'Deleted!',
                'Your infomation has been deleted.',
                'success'
              )
            }
          });
      }
      const restore = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to restore this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Resore it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.put(`${url.value}/${designation.value.id}/restore`)
              Swal.fire(
                'Restored!',
                'Your infomation has been Restored.',
                'success'
              )
            }
          });
      }
      const statusCheck = () => {
        if(designation.value.status==1){
          form.status=true;
          return true;
        }else{
          form.status=false;
          return false;
        }        
      }
      return {
        form,
        officeSections,
        designation,
        type,
        title,
        head,
        url,
        createUrl,
        update,
        destroy,
        restore,
        statusCheck,
        designations,
        swal,
        permission
      }
    },    
    created: function(){
      this.statusCheck();
    }
  }
  </script>
  