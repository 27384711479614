<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs> 
      <trashed-message v-if="decisioninfo.deleted_at" class="mb-6" @restore="restore"> This Religion has been deleted. </trashed-message>
      <div class="max-w-lg bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="update">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.d_title" :error="form.errors.d_title" class="pb-8 pr-6 w-full lg:w-1/1" label="Decision Step Title"  required="true" placeholder="i.e Approved" />            
            <div class="pb-8 pr-6 w-full lg:w-1/1">
              <Multiselect
              v-model="form.decision_step"
              mode="tags"
              placeholder="Select Decisions"
              :options="desionStep"
              :search="true"
              />
             </div>
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Decision Step" class="mt-10 pb-8 pr-6 w-full lg:w-1/1"></status-toogle-button>                 
          </div>
          <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
            <button v-if="!decisioninfo.deleted_at && permission.edit>0" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
            <Button v-if="permission.edit>0" :type="type" :name="head" :url="createUrl" :loading="form.processing"></Button>
          </div>
        </form>
      </div>
    </div>    
  </template>
  
  <script>
  import { ref, toRefs, onMounted, inject } from 'vue'
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import axios from 'axios';
  import Icon from '@/Shared/Icon'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import TrashedMessage from '@/Shared/TrashedMessage'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import DeleteIcon from '@/Shared/DeleteIcon'
  import Button from '@/Shared/Button'
  import Multiselect from '@vueform/multiselect'
  export default {
    components: {
      Head,
      Icon,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      TrashedMessage,
      StatusToogleButton,
      Breadcrumbs,
      DeleteIcon,
      Button,
      Multiselect
    },
    layout: Layout,
    props: {
      decisioninfo: Object,
      desionStep: Array,
      permission: Object,
    },
    remember: 'form',
    setup(props){
      const { decisioninfo,desionStep, permission} = toRefs(props)
      const type = ref('edit')
      const title = ref(decisioninfo.value.d_title)
      const head = ref('Decision Step')
      const url = ref('/backend/decisioninfos')
      const createUrl = ref('')
      const elm = ref(this) 
      const swal = inject('$swal')
      const form = useForm({
        d_title: decisioninfo.value.d_title,
        decision_step: decisioninfo.value.decision_step,
        status: decisioninfo.value.status,   
      })
      const update = () => {
          swal({
              title: 'Are you sure?',
              text: "You want to proceed with this action",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Confirm',
              cancelButtonText: 'Cancel',
            }).then((result) => {
              if (result.isConfirmed) {
                form.put(`${url.value}/${decisioninfo.value.id}`)
                title.value = form.d_title
                Swal.fire(
                  'Updated!',
                  'Your infomation has been Updated.',
                  'success'
                )
              }
            });
      }
      const destroy = () => {
        swal({
            title: 'Are you sure?',
            text: "Do you really want to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.delete(`${url.value}/${decisioninfo.value.id}`)
              Swal.fire(
                'Deleted!',
                'Your infomation has been deleted.',
                'success'
              )
            }
          });
      }
      const restore = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to restore this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Resore it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.put(`${url.value}/${decisioninfo.value.id}/restore`)
              Swal.fire(
                'Restored!',
                'Your infomation has been Restored.',
                'success'
              )
            }
          });
      }
     
      const statusCheck = () => {
        if(decisioninfo.value.status==1){
          form.status=true;
          return true;
        }else{
          form.status=false;
          return false;
        }        
      }
      return {
        form,
        decisioninfo,
        type,
        title,
        head,
        url,
        createUrl,
        update,
        destroy,
        restore,
        statusCheck,
        desionStep,
        swal,
        permission
      }
    },    
    created: function(){
      this.statusCheck();
    }
  }
  </script>
  