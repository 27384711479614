<template>
    <div :class="['action_btn',{fixed:position=='fixed'}]" tabindex="0" @click="content_submit">
        <i v-if="loader" class="fa fa-cog fa-spin"></i>
        <i v-else :class="icon"></i>
        <!-- {{ message }} -->
    </div>
    <div v-if="message" class="flex items-center gap-2 message_block success"><i class="fa fa-check"></i> {{ message }}<i class="fa fa-times close_btn" @click="remove_msg"></i></div>
</template>
<script>
    import { ref, toRefs, onMounted } from 'vue'
    export default {
        name: 'FloatingActionButton',
        props: {
            position: String,
            loader: Boolean,
            icon: String,
            message: String
        },
        data(){
            return {
                getInterval: ''
            }
        },
        // mounted(){
        //     this.getInterval = setInterval( () => {
        //         this.message = ''                
        //         clearInterval(this.getInterval)
        //     }, 1000 * 2)
        // },
        setup(props, { emit }){
            const { loader, icon, message } = toRefs(props)
            const content_submit = () => {
                if(!loader.value) emit('dataSubmit')
            }
            const getInterval = ref('')

            // onMounted(() => {
            //     getInterval.value = setInterval( () => {
            //         emit('removeMessage')
            //         clearInterval(getInterval.value)
            //     }, 1000 * 2)
            // })
            const remove_msg = () => {
                emit('removeMessage')
            }

            return {
                content_submit,
                icon,
                loader,
                message,
                remove_msg,
                getInterval
            }
        }
    }
</script>
<style lang="scss" scoped>
    .action_btn{
        position: absolute;
        right: 25px; bottom: 25px;
        bottom: 25px;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background-color: #5661b3aa;
        color: #fff;
        font-size: 16px;
        border: 1px solid #ddd;
        box-shadow: 0 0 15px #ccc;
        cursor: pointer;
        border-radius: 50%;
        transition: all 0.4s;

        &.fixed{
            position: fixed;
        }

        &:hover{
            background-color: #5661b3;
        }
    }
    .message_block{
        position: fixed;
        z-index: 9999;
        left: -300px;
        bottom: 25px;        
        color: #fff;
        height: 40px;        
        padding: 0 15px;
        border-radius: 5px;
        transition: all 0.4s;

        & > .close_btn{
            position: absolute;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            background-color: #5e9412;
            border: 1px solid #fff;
            border-radius: 50%;
            cursor: pointer;
            right: 5px;
            top: -10px;
            font-size: 8px;
            z-index: 5;
        }
        &.success{
            left: auto;
            right: 10%;
            background-color: #39c939;
        }
    }
</style>