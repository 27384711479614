<template>
  <Head title="Login" />
  <div class="grid grid-cols-2">
    <div>
      <div class="flex items-center justify-center p-6 min-h-screen bg-indigo-800">
        <div class="w-full max-w-md">
          <logo class="block mx-auto w-full max-w-xs fill-white" height="50" />
        </div>
      </div>    
    </div>
    <div >
      <div class="flex items-center justify-center p-6 min-h-screen bg-indigo-800">
        <div class="w-full max-w-md">        
          <form class="mt-8 bg-white rounded-lg shadow-xl overflow-hidden" @submit.prevent="login">
            <div class="px-10 py-12">
              <h1 class="text-center text-3xl font-bold">Welcome Back!</h1>
              <div class="mt-6 mx-auto w-24 border-b-2" />
              <text-input v-model="form.username" :error="form.errors.username" class="mt-10" label="Email" type="text" autofocus autocapitalize="off" />
              <text-input v-model="form.password" :error="form.errors.password" class="mt-6" label="Password" type="password" />
              <label class="flex items-center mt-6 select-none" for="remember">
                <input id="remember" v-model="form.remember" class="mr-1" type="checkbox" />
                <span class="text-sm">Remember Me</span>
              </label>
            </div>
            <div class="flex px-10 py-4 bg-gray-100 border-t border-gray-100">
              <loading-button :loading="form.processing" class="btn-indigo ml-auto" type="submit">Login</loading-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import Logo from '@/Shared/Logo'
import TextInput from '@/Shared/TextInput'
import LoadingButton from '@/Shared/LoadingButton'

export default {
  components: {
    Head,
    LoadingButton,
    Logo,
    TextInput,
  },
  data() {
    return {
      form: this.$inertia.form({
        username: '',
        password: '',
        remember: false,
      }),
    }
  },
  props:{
    error: Object
  },
  methods: {
    login() {
      this.form.post('/backend/login')
    },
  },
}
</script>
