<template>
    <button type="button" @click="shownew = true">
      <slot />
      <teleport v-if="shownew" to="#dropdownnew">
        <div>
          <div style="position: fixed; top: 0; right: 0; left: 0; bottom: 0; z-index: 99998; background: black; opacity: 0.2" @click="shownew = false" />
          <div ref="dropdownnew" style="position: absolute; z-index: 99999" @click.stop="shownew = !autoClose">
            <slot name="dropdownnew" />
          </div>
        </div>
      </teleport>
    </button>
  </template>
  
  <script>
  import { createPopper } from '@popperjs/core'
  
  export default {
    props: {
      placement: {
        type: String,
        default: 'bottom-end',
      },
      autoClose: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        shownew: false,
      }
    },
    watch: {
    shownew(shownew) {
        if (shownew) {
          this.$nextTick(() => {
            console.log(this.$el+','+ this.$refs.dropdownnew);
            this.popper = createPopper(this.$el, this.$refs.dropdownnew, {
              placement: this.placement,
              modifiers: [
                {
                  name: 'preventOverflow',
                  options: {
                    altBoundary: true,
                  },
                },
              ],
            })
          })
        } else if (this.popper) {
          setTimeout(() => this.popper.destroy(), 100)
        }
      },
    },
    mounted() {
      document.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
          this.shownew = false
        }
      })
    },
  }
  </script>
  