<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>   
        <div class="content_entry_form_block">
            <div class="grid  grid-cols-3 gap-4 ">
                <div class="col-span-2">
                    <div v-if="form_loader" class="grid  grid-cols-3 gap-4 ">
                        <div class="col-span-2">
                            <div class="form_block">
                                <FormBlockLoader :cols="1" :height="65" :r1="true" :r2="true" :r1_w="50" :r2_w="100" :r1_h="20" :r2_h="25" />
                                <FormBlockLoader class="mt-2" :cols="1" :height="65" :r1="true" :r2="true" :r1_w="50" :r2_w="100" :r1_h="20" :r2_h="25" />
                                <FormBlockLoader class="mt-2" :cols="1" :height="65" :r1="true" :r2="true" :r1_w="50" :r2_w="100" :r1_h="20" :r2_h="25" />
                            </div>
                        </div>
                    </div>
                    <div v-else class="form_block">                    
                        
                        <text-input v-model="form.content_title" :error="form.errors.content_title" class="pb-8 pr-6 w-full lg:w-1/1" label="Enter content title" required="true" placeholder="i.e Enter content title" />
                        <div class="item_block">
                            <div class="sm:col-span-2">                
                                <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Media Item </label>
                                <div class="mt-1 flex flex-wrap justify-center rounded-md border-2 border-dashed border-gray-300 dark:border-black dark:bg-white dark:text-black px-6 pt-5 pb-6">
                                    <div class="space-y-1 text-center">
                                      <PhotoInputBlock :getPhoto="form.content" @SetPhoto="set_photo" />  
                                    </div>
                                </div>
                              </div>                           
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div v-if="form_loader" class="grid  grid-cols-3 gap-4 ">
                        <div class="col-span-2">
                            <div class="info_block">
                                <FormBlockLoader :cols="1" :height="65" :r1="true" :r2="true" :r1_w="50" :r2_w="100" :r1_h="20" :r2_h="25" />
                                <FormBlockLoader class="mt-2" :cols="1" :height="65" :r1="true" :r2="true" :r1_w="50" :r2_w="100" :r1_h="20" :r2_h="25" />
                                <FormBlockLoader class="mt-2" :cols="1" :height="65" :r1="true" :r2="true" :r1_w="50" :r2_w="100" :r1_h="20" :r2_h="25" />
                            </div>
                        </div>
                    </div>
                    <div v-else class="info_block"> 
                        
                        <select-input v-model="form.cat_id" :error="form.errors.cat_id" class="pb-8 pr-6 w-full lg:w-1/1" label="Choose Categories" >
                            <option value=null>None</option>
                            <option v-for="(item,index) in $parent.category_list" :value="item.id" :key="index">{{ item.category_name }}</option>  
                        </select-input> 
                        <select-input v-model="form.content_type" :error="form.errors.content_type" class="pb-8 pr-6 w-full lg:w-1/1" label="Choose Categories" >                            
                            <!-- <option value="">Select One</option> -->
                            <option value="1">Photo</option>
                            <option value="2">Video</option>    
                        </select-input>
                        
                        <div class="mb-4">
                            <label>Allow Watermark</label>
                            <SwithcBtn :status="form.watermark" :index="'watermark'" />
                            <input type="hidden" v-model="form.watermark" />
                        </div>
                        <div v-if="form.watermark" class="mb-4">
                            <label>Watermark Position</label>
                            <select v-model="form.watermark_pos" class="form-control">
                                <option value="center">Center</option>
                                <option value="top-left">Top Left</option>
                                <option value="top-right">Top Right</option>
                                <option value="bottom-left">Bottom Left</option>
                                <option value="bottom-right">Bottom Right</option>
                            </select>
                        </div>
                        <div>
                            <label>Status</label>
                            <SwithcBtn :status="form.status" :index="'status'" />
                            <input type="hidden" v-model="form.status" />
                        </div>                    
                    </div>

                    <div v-if="external_req" class="submit-btn-block mt-4">
                        <button type="button" class="save_btn" @click="formSubmit">
                            <i class="far fa-check-circle"></i> Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SwithcBtn from '@/components/action_buttons/SwitchBtn'
import PhotoInputBlock from './components/photo_input_block'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject } from 'vue'
import Layout from '@/Shared/Layout'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import Button from '@/Shared/Button'
import FileInput from '@/Shared/FileInput'
import TextInput from '@/Shared/TextInput'
export default {
    name: 'ContentEntryFromBlock',
    props: {
        edit_content_id: Number,
        external_req: Boolean
    },
    components: {
      Head,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      StatusToogleButton,
      Breadcrumbs,
      Button,
      FileInput,
      SwithcBtn,
      PhotoInputBlock
    },
    layout: Layout,
    remember: 'form',
    setup(props){
      const { regions } = toRefs(props)
      const form = useForm({
            content_title: null,
            cat_id: null,
            content_type: 1,
            content: null,
            exist_content: null,
            watermark: false,
            watermark_pos: 'center',
            status: true
      })
      const type = ref('create')
      const title = ref('Media')
      const url = ref('/backend/medias')
      const createUrl = ref('')
      const form_loader = ref(false)
      const req_submit = ref(false)
      const form_action = ref('save')
      const swal = inject('$swal')
      const store = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}`, {forceFormData: true,})
              Swal.fire(
                'Added!',
                'Your infomation has been Added.',
                'success'
              )
            }
          });
        
      }
      const switch_data =(index,status)=>{
        this.form[index] = status
      }       
      const form_reset =()=>{
            this.form = {
                content_title: '',
                cat_id: '',
                content_type: '',
                content: null,
                exist_content: null,
                watermark: false,
                watermark_pos: 'center',
                status: true
            }            
        }
        const set_photo = (val) => {
            form.content = val
        }
      return {
        form,
        regions,
        type,
        title,
        url,
        createUrl,
        store,
        switch_data,
        form_reset,
        form_loader,
        req_submit,
        form_action,
        set_photo
      }
    }  
  
    
}
</script>
<style scoped>
    .form_block,.info_block{
        display: block;
        background-color: #fff;
        border: 1px solid #ddd;
        padding: 20px;
    }
    .submit-btn-block{
        text-align: right;
    }
    .submit-btn-block > button{                
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        outline: 0px;
        color: #fff;
        border: none;
        padding: 5px 15px;
        border-radius: 25px;
        cursor: pointer;
        transition: all 0.4s;
    }
    .submit-btn-block > button i{
        margin-right: 5px;
    }
    .submit-btn-block > button.add_btn{
        background-color: #0097f7;
    }
    .submit-btn-block > button.add_btn:hover{
        background-color: #006699;
    }
    .submit-btn-block > button.cancel_btn{
        background-color: #CD0000;
    }
    .submit-btn-block > button.cancel_btn:hover{
        background-color: darkorange;
    }
    .submit-btn-block > button.save_btn{
        background-color: green;
    }
    .submit-btn-block > button.save_btn:hover{
        background-color: lightseagreen;
    }
    .submit-btn-block.sticky{
        position: fixed;
        z-index: 100;
        right: 0px;
        top: 0;
        background-color: #fff;
        height: 59px;
        padding: 13px 25px 0 0;
    }
</style>