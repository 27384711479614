<template>
    <div class="geo_list_map_block">
        <ContentHeaderSection :title="'GEO Mapping'" :icon="'fa fa-cogs'" />
        <div class="p-5 geo_map_block">
            <!-- <pre>{{ GeoRegions }}</pre> -->
            <div class="flex items-center gap-2 geo_label">
                <i v-if="region_tree_on" class="fa fa-minus" @click="tree_on_status(false)"></i>
                <i v-else class="fa fa-plus" @click="tree_on_status(true)"></i>
                <span>Regions</span>
            </div>
            <div :class="['geo_tree',{active:region_tree_on}]">
                <div v-for="region in GeoRegions">
                    <div :class="['flex','items-center','gap-2','input_block',{active:cur_office_layer_id==2 && sel_regions && sel_regions[region.id]}]" @click="sel_region(cur_office_layer_id==2?region.id:'')">
                        <span v-if="(cur_office_layer_id>2)" class="square_block disable">
                            <i class="fa fa-cog check"></i>
                        </span>
                        <span v-else class="square_block">
                            <i class="fa fa-check check"></i>
                            <i class="fa fa-times remove"></i>
                        </span>
                        <div>{{ region.region_name }}</div>
                    </div>
                    <GeoProvinceMapBlock v-if="(cur_office_layer_id>=3)"
                    :region_id="region.id"
                    :sel_provinces="sel_provinces"
                    :sel_municipalities="sel_municipalities"
                    :sel_barangays="sel_barangays"
                    @selProvience="sel_province"
                    @selMunicipality="sel_municipality"
                    @selBarangay="sel_barangay" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>    
    import { ref, toRefs, inject } from 'vue'
    import ContentHeaderSection from "@/Shared/ContentHeaderSection"
    import GeoProvinceMapBlock from "./Geo/ProvinceMap"
    export default {
        name: "GeoListMapBlock",
        inject: ['office_layer_id'],
        props: {
            GeoRegions: Array,
            sel_regions: Object,
            sel_provinces: Object,
            sel_municipalities: Object,
            sel_barangays: Object            
        },
        components: {
            ContentHeaderSection,
            GeoProvinceMapBlock
        },
        data(){
            return {
                cur_office_layer_id: this.office_layer_id
            }
        },
        setup( props, { emit }) {
            const { GeoRegions, sel_regions, sel_provinces, sel_municipalities, sel_barangays } = toRefs(props)            
            const region_tree_on = ref(true)

            const tree_on_status = (status) => {
                region_tree_on.value = status
            }

            const sel_region = (region_id) => {
                // //console.log(region_id)
                if(region_id) emit('selRegion', region_id)
            }            

            const sel_province = (province_id) => {
                // //console.log(province_id)
                emit('selProvience', province_id)
            }

            const sel_municipality = (municipality_id) => {
                // //console.log(municipality_id)
                emit('selMunicipality', municipality_id)
            }

            const sel_barangay = (barangay_id) => {
                // //console.log('barangay', barangay_id)
                emit('selBarangay', barangay_id)
            }

            return {
                region_tree_on, 
                GeoRegions,
                sel_regions,
                sel_provinces,
                sel_municipalities,
                sel_barangays,
                sel_region,
                sel_province,
                sel_municipality,
                sel_barangay,
                tree_on_status
            }
        }
    }
</script>
<style lang="scss" scoped>
    $tree_layer_gap_x: 22;
    $tree_layer_gap_y: 16;
    .input_block{
        position: relative;
        height: 30px;
        font-size: 12px;
        cursor: pointer;

        & > .square_block{
            display: flex;
            align-items: center;
            justify-content: center;            
            width: 20px; height: 20px;
            border: 1px solid #999;
            border-radius: 5px;

            & > i{
                display: none;
                font-size: 10px;                    
            }

            &.disable{                
                & > i{
                    display: block;
                }
            }
        }

        &:hover{
            font-weight: 600;
            .square_block{
                & > i.check {
                    display: block;
                }
            }
        }

        &.active{
            color: #006699;
            font-weight: 600;
            .square_block{
                border-color: #006699;
                & > i.check{
                    display: block;
                    color: #006699
                }
            }
            &:hover{
                color: #CD0000;                    
                .square_block{
                    border-color: #CD0000;
                    & > i.check{
                        display: none;
                    }
                    & > i.remove{
                        display: block;
                        color: #CD0000
                    }
                }
            }
        }
    }
    .geo_label{
        position: relative;
        font-size: 14px;
        font-weight: 600;        
        height: 34px;

        // &::before{
        //     position: absolute;
        //     content: "";
        //     width: 20px;
        //     height: 24px;
        //     border-bottom: 1px solid #999;
        //     border-left: 1px solid #999;
        //     border-radius: 0 0 0 5px;
        //     left: 0px;
        //     top: -5px;
        // }

        & > i{
            position: relative;
            left: 0;
            width: 22px;
            height: 22px;
            border: 1px solid #999;
            border-radius: 5px;
            line-height: 22px;
            text-align: center;
            font-size: 10px;
            color: #666;
            cursor: pointer;
        }
    }
    .geo_tree{
        display: none;
        position: relative;
        margin-left: 10px;
        transition: all 0.4s;
        &.active{
            display: block;
        }
        &::before{
            position: absolute;
            content: "";
            width: 5px;
            height: calc(100% - 8px);
            border-left: 1px solid #999;
            border-bottom: 1px solid #999;
            border-radius: 0 0 0 5px;
            left: 0;
            top: -6px;
        }
        & > div{
            position: relative;
            padding-left: #{$tree_layer_gap_x}px;

            &::before{
                position: absolute;
                content: "";
                width: #{$tree_layer_gap_x}px;
                height: #{$tree_layer_gap_y}px;
                border-bottom: 1px solid #999;
                border-left: 1px solid #999;
                border-radius: 0 0 0 5px;
                left: 0;
                top: 0;
            }            
        }
    }
</style>