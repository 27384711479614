<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs> 
      <div class="flex items-center justify-between mb-6">
        <search-filter v-model="form.search" class="mr-4 w-full max-w-xl" @reset="reset">
          <template v-slot:filter>
            <select v-model="form.trashed" class="form-select  w-28">
              <option :value="null" >Filter</option>
              <option value="with">With Trashed</option>
              <option value="only">Only Trashed</option>
            </select>
          </template>
          <template v-slot:page>
            <select v-model="form.length" class="form-select  w-20">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
              <option value="150">150</option>
              <option value="200">200</option>
            </select>
          </template>
          <template v-slot:status>
            <select v-model="form.status" class="form-select w-32">
              <option :value="null">Status</option>
              <option value="1">Active</option>
              <option value="2">Inactive</option>
            </select>
          </template>
        </search-filter>
        <Button :type="type" :name="title" :url="createUrl" v-if="permission.add>0"></Button>
      </div>
      <div class="bg-white rounded-md shadow overflow-x-auto">
        <!-- {{ activeEmployees.data }} -->
        <table class="w-full whitespace-nowrap">
          <thead>
            <tr class="text-left font-bold">
              <th class="pb-1 pt-1 px-1" width="20">Sl.</th>
              <th class="pb-2 pt-3 px-3">Info</th>
              <th class="pb-2 pt-3 px-3">Join Date</th>
              <th class="pb-2 pt-3 px-3">Discontinue Date</th>
              <th class="pb-2 pt-3 px-3 text-center">Status</th>
              <th class="pb-2 pt-3 px-3 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(activeEmployee,index) in activeEmployees.data" :key="activeEmployee.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
              <td class="border-t pb-1 pt-1 px-3 text-center">
                {{ (index + activeEmployees.from) }}
              </td>
              <td class="border-t">                
                <div class="flex items-center px-3 py-1 focus:text-indigo-500" >
                  <div class="flex items-center  gap-2">
                    <div>
                      <p class="bold flex font-14 font-bold items-center ">
                        <div>{{ activeEmployee.first_name }}&nbsp;{{ activeEmployee.middle_name }}&nbsp;{{ activeEmployee.last_name }}&nbsp;{{ activeEmployee.surfix }}</div>
                      </p>
                      <p class="flex items-center "  v-if="activeEmployee.designation!=null">
                        <div>Designation: {{activeEmployee.designation}}</div>
                      </p>                     
                      <p class="flex items-center " v-if="activeEmployee.ministry_name!=null">
                        <div>Ministry: {{ activeEmployee.ministry_name }}</div>
                      </p>
                      <p class="flex items-center " v-if="activeEmployee.region_name!=null">
                        <div>Region: {{ activeEmployee.region_name }}</div>
                      </p>
                      <p class="flex items-center " v-if="activeEmployee.provience_name!=null">
                        <div>Province: {{ activeEmployee.provience_name }}</div>
                      </p>
                      <p class="flex items-center " v-if="activeEmployee.municipality_name!=null">
                        <div>Municipality: {{ activeEmployee.municipality_name }}</div>
                      </p>
                      <p class="flex items-center " v-if="activeEmployee.barangay_name!=null">
                        <div>Barangay: {{ activeEmployee.barangay_name }}</div>
                      </p>
                    </div>
                  </div>
                  <icon v-if="activeEmployee.deleted_at" name="trash" class="flex-shrink-0 ml-2 w-3 h-3 fill-gray-400" />
                </div>                
              </td>
              <td class="border-t">
                <div class="flex items-center px-3 py-1 focus:text-indigo-500" >
                  {{ activeEmployee.join_date }}                  
                </div>
              </td>
              <td class="border-t">
                <div class="flex items-center px-3 py-1 focus:text-indigo-500" >
                  {{ activeEmployee.discontinue_date }}                  
                </div>
              </td>
              <td class="border-t pb-1 pt-1 px-1 text-center">
                <status-level :status="activeEmployee.status"></status-level>
              </td>              
              <td class="w-px border-t text-center">
                <div class="flex items-center px-2" v-if="permission.edit>0">
                  <Link class="px-4 py-2" :href="`/backend/active-employees/${activeEmployee.id}/edit`" tabindex="-1">
                    <i class="fa fa-edit"></i>
                  </Link>                
                </div> 
              </td>
            </tr>
            <tr v-if="activeEmployees.data.length === 0">
              <td class="px-3 py-1 border-t" colspan="4">No Employee found.</td>
            </tr>
          </tbody>
        </table>
      </div>
      
      <pagination class="mt-6" :links="activeEmployees.links" />
    </div>    
   
    
  </template>
  
  <script>
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import { ref, toRefs, inject } from 'vue'
  import Icon from '@/Shared/Icon'
  import pickBy from 'lodash/pickBy'
  import Layout from '@/Shared/Layout'
  import throttle from 'lodash/throttle'
  import mapValues from 'lodash/mapValues'
  import Pagination from '@/Shared/Pagination'
  import SearchFilter from '@/Shared/SearchFilter'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import StatusLevel from '@/Shared/StatusLevel'
  import Button from '@/Shared/Button'
 
  export default {
    components: {
      Head,
      Icon,
      Link,
      Pagination,
      SearchFilter,
      Breadcrumbs,
      StatusLevel,
      Button
    },
    layout: Layout,
    props: {
      filters: Object,
      activeEmployees: Object,
      permission: Object,
    },
    setup(props){
      const { filters, activeEmployees,permission } = toRefs(props)
      const form = ref({
        search: filters.value.search,
        trashed: filters.value.trashed,
        length: filters.value.length?filters.value.length:15,
        status: filters.value.status,
      })
      const type = ref('index')
      const title = ref('Employee')
      const url = ref('')
      const createUrl = ref('/backend/active-employees/create')
      const reset = () => {
        form.value = mapValues(form, () => null)
      }
    

      return {
        filters,
        activeEmployees,
        form,
        type,
        title,
        url,
        createUrl,
        reset,
        permission
      }
    },    
    watch: {
      form: {
        deep: true,
        handler: throttle(function () {
          this.$inertia.get('/backend/active-employees', pickBy(this.form), { preserveState: true })
        }, 150),
      },
    }
  }
  </script>
