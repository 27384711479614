<template>
    <div class="properties_block">
        <template v-if="sel_table_row_number && sel_table_column_number && sel_table_content_index>=0 && table_cell_data.dynamic_content && table_cell_data.dynamic_content[sel_table_content_index]">
            <!-- <pre>{{ sel_table_content_index }} {{ table_cell_data.dynamic_content[sel_table_content_index] }}</pre> -->
            <InputProperties
                :data="table_cell_data.dynamic_content[sel_table_content_index]"
                :certificate_data="{}"
                :input_type="table_cell_data.dynamic_content[sel_table_content_index].type"
                :sel_table_row_number="sel_table_row_number"
                :sel_table_column_number="sel_table_column_number"
                :sel_table_content_index="sel_table_content_index"
                @addFieldProperties="add_field_properties"
            />
        </template>
        <template v-else>
            <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                <label>Name</label>
                <div><input class="px-2" type="text" v-model="property_form_data.name" placeholder="Enter Name" @keyup="add_properties" /></div>
            </div>
            <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                <label>Caption</label>
                <div><input class="px-2" type="text" v-model="property_form_data.caption" placeholder="Enter Caption" @keyup="add_properties" /></div>
            </div>
            <div v-if="property_form_data.caption" class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                <label>Caption Position</label>
                <div><select class="px-1" v-model="property_form_data.caption_position" @change="add_properties">
                    <option v-for="(text,val) in {'caption-top':'Top','caption-bottom':'Bottom'}" :value="val">{{ text }}</option>
                </select></div>
            </div>
            <!-- {{ sel_table_content_index }} -->
            <template v-if="sel_table_row_number && sel_table_column_number">
                <CellProperties
                    :data="table_cell_data"
                    :dynamic_vars="data.dynamic_vars"
                    :max_rowspan="max_rowspan"
                    :max_colspan="max_colspan"
                    @addCellProperties="add_cell_properties"
                />
            </template>
            <template v-else>
                <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                    <label>Allow Add More Rows</label>
                    <div class="form-check form-switch">
                        <input class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.allow_add_more_rows" @change="add_properties" />
                    </div>
                </div>
                <template v-if="property_form_data.allow_add_more_rows">
                    <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                        <label>Maximum Rows Allow</label>
                        <div><input class="px-2" type="number" v-model="property_form_data.max_rows_allow" min="1" step="1" placeholder="i.e. 2" @keyup="add_properties" /></div>
                    </div>
                    <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                        <label>Row Number for Add More</label>
                        <div><input class="px-2" type="number" v-model="property_form_data.add_more_row_number" min="1" :max="data.rows" step="1" placeholder="i.e. 2" @keyup="add_properties" /></div>
                    </div>                    
                </template>
                <div class="block border border-collapse p-2">
                    <label>Dynamic Vars</label>
                    <template v-if="property_form_data.dynamic_vars.length>0">                        
                        <div v-for="(option,index) in property_form_data.dynamic_vars" class="grid grid-cols-7 items-center gap-2 mt-3">
                            <div class="col-span-6"><input type="text" class="px-2" v-model="property_form_data.dynamic_vars[index]" placeholder="Enter Name" @keyup="add_properties" @change="add_properties" /></div>
                            <div class="text-center text-[10px] text-red-900 cursor-pointer" @click="remove_vars(index)"><i class="fa fa-trash-alt"></i></div>
                        </div>                        
                    </template>
                    <div class="flex items-center justify-center gap-1 h-8 rounded-md bg-gray-200 transition-all hover:bg-gray-300 hover:transition-shadow border cursor-pointer add_opt_btn mt-3" @click="add_new_vars">
                        <i class="fa fa-plus-circle"></i>
                        <span>Add New</span>
                    </div>
                </div>
                <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                    <label>For Certificate <sup v-if="certificate_data[property_form_data.name]">*</sup></label>
                    <div class="form-check form-switch">
                        <input class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.for_certificate" @change="add_properties" />
                    </div>
                </div>
                <!-- <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                    <label>Font Size</label>
                    <div class="flex items-center gap-1">
                        <input class="text-center pl-1" type="number" v-model="property_form_data.font_size" min="10" @keyup="add_properties" @change="add_properties" />
                        <select class="text-center" v-model="property_form_data.font_size_type" @change="add_properties">
                            <option v-for="text in font_size_types" :value="text">{{ text }}</option>
                        </select>
                    </div>
                </div>
                <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                    <label>Font Weight</label>
                    <div><select class="px-1" v-model="property_form_data.font_weight" @change="add_properties">
                        <option v-for="(text,val) in font_weight_list" :value="val">{{ text }}</option>
                    </select></div>
                </div>
                <div v-if="property_form_data.mode=='normal' || property_form_data.mode=='paragraph'" class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                    <label>Line Height</label>
                    <div class="flex items-center gap-1">
                        <input class="text-center pl-1" type="number" v-model="property_form_data.line_height" min="10" @keyup="add_properties" @change="add_properties" />
                        <select class="text-center" v-model="property_form_data.line_height_type" @change="add_properties">
                            <option v-for="text in font_size_types" :value="text">{{ text }}</option>
                        </select>
                    </div>
                </div> -->
            </template>
        </template>
        <!-- {{ sel_table_row_number }}
        {{ sel_table_column_number }} -->
        <!-- <pre>{{ table_cell_data }}</pre> -->
        <!-- <pre>{{ property_form_data.html }}</pre> -->
        <!-- <pre>{{ property_form_data.html[sel_table_row_number - 1] }}</pre> -->
        <!-- <pre>{{ property_form_data.html[sel_table_row_number - 1].cols }}</pre> -->
        <!-- <pre>{{ property_form_data.html[sel_table_row_number - 1].cols[sel_table_column_number - 1] }}</pre> -->
        <!-- <pre>{{ table_cell_data }}</pre> -->
        <!-- <pre>{{ sel_table_row_number }}</pre>
        <pre>{{ sel_table_column_number }}</pre> -->
    </div>
</template>
<script setup>
    import { computed, ref, watch } from 'vue'
    import CellProperties from './CellProperties'
    import InputProperties from './InputProperties'
    const props = defineProps({
        data: Object,
        certificate_data: Object,
        sel_table_row_number: Number,
        sel_table_column_number: Number,
        sel_table_content_index: Number
    })
    const emit = defineEmits(['addProperties'])

    const max_rowspan = computed(() => (props.data.rows - (props.sel_table_row_number - 1)))
    const max_colspan = computed(() => (props.data.cols - (props.sel_table_column_number - 1)))

    const property_form_data = ref({
        name: props.data && props.data.name?props.data.name:'',
        caption: props.data && props.data.caption?props.data.caption:'',
        caption_position: props.data && props.data.caption_position?props.data.caption_position:'caption-top',
        allow_add_more_rows: props.data && props.data.allow_add_more_rows?props.data.allow_add_more_rows:false,
        add_more_row_number: props.data && props.data.add_more_row_number?props.data.add_more_row_number:2,
        dynamic_vars: props.data && props.data.dynamic_vars?props.data.dynamic_vars:[],
        max_rows_allow: props.data && props.data.max_rows_allow?props.data.max_rows_allow:1,
        html: props.data && props.data.html?props.data.html:[{}],
        for_certificate: props.data && props.data.for_certificate?props.data.for_certificate:false,
        // rows: props.data && props.data.rows?props.data.rows:1,
        // cols: props.data && props.data.cols?props.data.cols:1,
        // font_size: props.data && props.data.font_size?props.data.font_size:12,
        // font_size_type: props.data && props.data.font_size_type?props.data.font_size_type:'px',
        // font_weight: props.data && props.data.font_weight?props.data.font_weight:'normal',
        // line_height: props.data && props.data.line_height?props.data.line_height:14,
        // line_height_type: props.data && props.data.line_height_type?props.data.line_height_type:'px'
    })    

    watch(() => props.data, (updateData, oldValue) => {
        // //console.log(updateData, oldValue)
        property_form_data.value = {
            name: updateData && updateData.name?updateData.name:'',
            caption: updateData && updateData.caption?updateData.caption:'',
            caption_position: updateData && updateData.caption_position?updateData.caption_position:'caption-top',
            allow_add_more_rows: updateData && updateData.allow_add_more_rows?props.data.allow_add_more_rows:false,
            add_more_row_number: updateData && updateData.add_more_row_number?updateData.add_more_row_number:2,
            dynamic_vars: updateData && updateData.dynamic_vars?props.data.dynamic_vars:[],
            max_rows_allow: updateData && updateData.max_rows_allow?props.data.max_rows_allow:1,
            html: updateData && updateData.html?updateData.html:[{}],
            for_certificate: updateData && updateData.for_certificate?updateData.for_certificate:false,
            // rows: updateData && updateData.rows?updateData.rows:1,
            // cols: updateData && updateData.cols?updateData.cols:1,
            // font_size: updateData && updateData.font_size?updateData.font_size:12,
            // font_size_type: updateData && updateData.font_size_type?updateData.font_size_type:'px',
            // font_weight: updateData && updateData.font_weight?updateData.font_weight:'normal',
            // line_height: updateData && updateData.line_height?updateData.line_height:14,
            // line_height_type: updateData && updateData.line_height_type?updateData.line_height_type:'px'
        }
    });
    
    const table_cell_data = computed( () => props.data.html[props.sel_table_row_number - 1]?props.data.html[props.sel_table_row_number - 1].cols[props.sel_table_column_number - 1]:{})
    watch(() => table_cell_data.value, (updateData) => {
        if(updateData){
            /**
             * For row re-arrangements
             */
            let getRows = updateData.rowspan?(updateData.rowspan - 1):0
            if(getRows){                
                for(let i=0; i<getRows; i++) {
                    // //console.log('Cols Object',(props.sel_table_row_number + i),props.data.cols, props.data.html[props.sel_table_row_number + i].cols[props.data.cols - 1])
                    props.data.html[props.sel_table_row_number + i].cols[props.data.cols - 1].hidden = true
                }
            }

            /**
             * For column re-arrangements
             */
            // let getCols = updateData.colspan?(updateData.colspan - 1):0
            // if(getCols){                
            //     for(let i=0; i<getCols; i++) {
            //         // //console.log('Cols Object',(props.sel_table_row_number + i),props.data.cols, props.data.html[props.sel_table_row_number + i].cols[props.data.cols - 1])
            //         props.data.html[props.sel_table_row_number - 1].cols[props.data.cols - (1+i)].hidden = true
            //     }
            // }

            // //console.log(getRows,'Get table cell data', updateData)
            add_properties()
        }
    })
    
    const font_size_types = ref(['%','em','rem','vh','px'])    
    const font_weight_list = ref({
        '100': '100',
        '200': '200',
        '300': '300',
        '400': '400',
        '500': '500',
        '600': '600',
        '700': '700',
        '800': '800',
        '900': '900',
        'bold': 'Bold',
        'bolder': 'Bolder',
        'lighter': 'Lighter',
        'normal': 'Normal'
    })

    const remove_vars = (index) => {
        property_form_data.value.dynamic_vars.splice(index, 1)
    }

    const add_new_vars = () => {
        let next_number = property_form_data.value.dynamic_vars.length + 1        

        property_form_data.value.dynamic_vars.push('vars-' + next_number)
    }

    const add_field_properties = (data) => {
        // //console.log(property_form_data.value.html[props.sel_table_row_number - 1].cols[props.sel_table_column_number - 1].dynamic_content[props.sel_table_content_index])
        // //console.log(data)
        property_form_data.value.html[props.sel_table_row_number - 1].cols[props.sel_table_column_number - 1].dynamic_content[props.sel_table_content_index] = data

        add_properties()
    }

    const add_cell_properties = (data) => {
        property_form_data.value.html[props.sel_table_row_number - 1].cols[props.sel_table_column_number - 1] = data

        /**
         * Row cell hidden properties update
         */
        for(let i = props.sel_table_row_number; i < props.data.rows; i++){
            //console.log('Cols Object {else con}', props.data.html[i].cols[props.data.cols - 1])
            props.data.html[i].cols[props.data.cols - 1].hidden = false
        }

        /**
         * Column cell hidden properties update
         */
        // for(let i = props.sel_table_column_number; i < props.data.cols; i++){
        //     //console.log('Cols Object {else con}', props.data.html[props.sel_table_row_number - 1].cols[i])
        //     props.data.html[props.sel_table_row_number - 1].cols[i].hidden = false
        // }

        add_properties()
    }

    const add_properties = () => {
        emit('addProperties', property_form_data.value)
    }
</script>
<style lang="scss" scoped>
    .properties_block{
        font-size: 12px;    
        input,textarea,select{
            width: 100%;
            height: 26px;
            border: 1px solid #ddd;
            border-radius: 5px;

            &.switch_btn{
                width: 50px;
                height: 26px;
                border-radius: 25px;
                margin-left: -30px;
            }
        }
        textarea{
            height: 48px;
        }
    }
</style>