<template>
    <div :class="['grid','grid-cols-1','max-h-[300px]','overflow-y-auto','field_properties',{hidden:field_property_collapsed}]">
        <TxtInputProperties v-if="input_type=='txt_input'" :data="data" :certificate_data="certificate_data" :field_validation_types="field_validation_types" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data" />
        
        <NumberInputProperties v-else-if="input_type=='number_input'" :data="data" :certificate_data="certificate_data" :field_validation_types="field_validation_types" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data" />
        
        <TextareaInputProperties v-else-if="input_type=='textarea'" :data="data" :certificate_data="certificate_data" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data" />
        
        <SelectboxInputProperties v-else-if="input_type=='selectbox'" :data="data" :certificate_data="certificate_data" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data" />
        
        <CheckboxInputProperties v-else-if="input_type=='checkbox'" :data="data" :certificate_data="certificate_data" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data" />
        
        <RadioInputProperties v-else-if="input_type=='radio'" :data="data" :certificate_data="certificate_data" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data" />
        
        <DatePickerProperties v-else-if="input_type=='datepicker'" :data="data" :certificate_data="certificate_data" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data" />

        <DateRangeProperties v-else-if="input_type=='daterange'" :data="data" :certificate_data="certificate_data" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data" />

        <TimePickerProperties v-else-if="input_type=='timepicker'" :data="data" :certificate_data="certificate_data" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data" />

        <SignatureProperties v-else-if="input_type=='signature'" :data="data" :certificate_data="certificate_data" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data" />

        <GenderInputProperties v-else-if="input_type=='gender'" :data="data" :certificate_data="certificate_data" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data" />

        <ReligionInputProperties v-else-if="input_type=='religion'" :data="data" :certificate_data="certificate_data" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data" />

        <GeoInputProperties v-else-if="input_type=='geo'" :data="data" :certificate_data="certificate_data" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data" />

        <NationalityInputProperties v-else-if="input_type=='nationality'" :data="data" :certificate_data="certificate_data" @addProperties="add_properties" @modifyCertificateData="modify_certificate_data" @removeCertificateData="remove_certificate_data" />
        
        <TextProperties v-else-if="input_type=='text'"
            :data="data"
            @addProperties="add_properties"
            @modifyCertificateData="modify_certificate_data"
            @removeCertificateData="remove_certificate_data"
        />
    </div>
</template>
<script setup>
    import TxtInputProperties from "../TxtInput"
    import NumberInputProperties from "../NumberInput"
    import TextareaInputProperties from "../TextareaInput"
    import SelectboxInputProperties from "../SelectBoxInput"
    import CheckboxInputProperties from "../CheckboxInput"
    import RadioInputProperties from "../RadioInput"
    import DatePickerProperties from "../DatePickerInput"
    import DateRangeProperties from "../DateRangeInput"
    import TimePickerProperties from "../TimePickerInput"
    import SignatureProperties from '../SignatureInput'
    import GenderInputProperties from "../GenderInput"
    import ReligionInputProperties from "../ReligionInput"
    import GeoInputProperties from "../GeoInput"
    import NationalityInputProperties from "../NationalityInput"
    import TextProperties from "../Text"
    import { ref } from 'vue'

    const emit = defineEmits(['addFieldProperties'])
    const props = defineProps({
        data: Object,
        certificate_data: Object,
        input_type: String,
        elm_index: Number,
        elm_col_index: Number,
        sel_table_row_number: Number,
        sel_table_column_number: Number,
        sel_table_content_index: Number
    })

    const field_property_collapsed = ref(false)    
    const field_validation_types = ref({
        'email': 'Email',
        'mobile': 'Mobile'
    })

    const add_properties = (data) => {
        emit('addFieldProperties', data)
    }

    const modify_certificate_data = (index) => {
        // emit('modifyCertificateData', index)
    }
    const remove_certificate_data = (index) => {        
        // emit('removeCertificateData', index)
    }
</script>