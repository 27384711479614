<template>
    <div class="w-full lg:w-1/1">
        <fieldset class="border border-solid border-gray-300 ">
            <legend class="text-sm">Content Info</legend>
            <div class="flex flex-wrap -mb-8 -mr-6 p-8 previous" v-for="(content, counter) in contents"
                v-bind:key="counter">
                <span class="deleteAttachment" @click="deleteContent(counter, content.id)"><delete-icon /></span>
                <text-input v-model="content.title" class="pb-8 pr-6 w-full lg:w-1/2" label="Content Title"
                    placeholder="i.e Request Process Guide" />
                <select-input v-model="content.type" class="pb-8 pr-6 w-full lg:w-1/2" label="Content Type"
                    @change="changeLebel($event, counter)">
                    <option value=null>Select Content Type</option>
                    <option v-for="(type, counter) in contentdata" :value="counter">
                        {{ type }}
                    </option>
                </select-input>
                <div class="pb-8 pr-6 w-full lg:w-1/1" v-if="content.type == 10">
                    <div class="mt-1 flex items-center">
                        <PhotoBlock :getPhoto="content.description" @SetPhoto="set_photo" :index="counter" />
                    </div>
                </div>
                <div class="pb-8 pr-6 w-full lg:w-1/1" v-else>
                    <label class="form-label" for="content">Content <span class="custom-error">*</span></label>
                    <vue-editor v-model="content.description" placeholder="i.e Content" />
                </div>
            </div>

            <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
                <button class="btn-indigo" @click="addContent" type="button">
                    <i class="fa fa-plus-circle"></i>
                    <span>Add</span>
                    <span class="hidden md:inline">&nbsp;{{ name }}</span>More</button>
            </div>
        </fieldset>

    </div>
</template>
<script>
import axios from "axios"
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, onMounted } from 'vue'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Button from '@/Shared/Button'
import ContentHeaderSection from "@/Shared/ContentHeaderSection"
import DeleteIcon from '@/Shared/DeleteIcon'
import TextareaInput from '@/Shared/TextareaInput'
import { VueEditor, Quill } from "vue3-editor";
import PhotoBlock from './Photo';
export default {
    components: {
        Head,
        Link,
        SelectInput,
        TextInput,
        StatusToogleButton,
        Button,
        ContentHeaderSection,
        DeleteIcon,
        TextareaInput,
        VueEditor,
        Quill,
        PhotoBlock
    },
    remember: 'form',
    props: {
        contents: Array,
    },
    setup(props) {
        const { contents } = toRefs(props)
        const addContent = () => {
            contents.value.push({
                title: null,
                type: null,
                description: null,
            })
        }
        const descriptionlebel = ref([]);

        const contentdata = {
            '1': 'Hyperlink',
            '2': 'List',
            '3': 'Description',
            '4': 'Iframe',
            '5': 'Video',
            '6': 'Gallery',
            '7': 'Table',
            '8': 'Calendar',
            '9': 'Location',
            '10': 'File'
        }
        const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)

        const deleteContent = (counter, id = null) => {
            if (id != null) {
                let data = {
                    id: id
                }
                ////console.log(data)
                axios.post(`/${backendPrefix.value}/serviceinfos/contentdelete`, data).then(async (response) => {
                    //     //console.log(response)
                }).catch(error => {
                    //   //console.log(error.response.data.error)
                })
            }
            contents.value.splice(counter, 1);
        }
        const changeLebel = (event, id) => {
            let obj = { id: contentdata[event.target.value] }


            descriptionlebel.value.push({ id: contentdata[event.target.value] })
            ////console.log(descriptionlebel );
        }

        const set_photo = (val,index) => {
            contents.value[index].description = val
        }
        return {
            contents,
            addContent,
            deleteContent,
            contentdata,
            changeLebel,
            descriptionlebel,
            set_photo
        }
    },
}
</script>
<style>
.deleteAttachment {
    position: absolute;
    right: 53px;
    top: 22px;
}

.previous {
    position: relative;
}

.deleteAttachment {
    position: absolute;
    right: 53px;
    top: 22px;
}

legend.text-sm {
    font-size: 14px;
    font-weight: 500;
    padding-left: 5px;
    padding-right: 5px;
}

.ql-container.ql-snow {
    height: auto !important;
}

.ql-toolbar .ql-formats .ql-image {
    display: none;
}
</style>
