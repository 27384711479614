<template>
  <div>
    <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs> 
   
    <trashed-message v-if="countryinfo.deleted_at" class="mb-6" @restore="restore"> This Barangay has been deleted. </trashed-message>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="update">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <text-input v-model="form.en_short_name" :error="form.errors.en_short_name" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Country" required="true" placeholder="i.e Afghanistan"/>  
          <text-input v-model="form.nationality" :error="form.errors.nationality" class="pb-8 pr-6 w-full lg:w-1/2" label="Nationality" required="true" placeholder="i.e Afghan"/> 
          <text-input v-model="form.alpha_2_code" :error="form.errors.alpha_2_code" class="pb-8 pr-6 w-full lg:w-1/2" label="Code Length Two" required="true" placeholder="i.e AF"/> 
          <text-input v-model="form.alpha_3_code" :error="form.errors.alpha_3_code" class="pb-8 pr-6 w-full lg:w-1/2" label="Code Length Three" required="true" placeholder="i.e AFG"/>
          <text-input v-model="form.num_code" :error="form.errors.num_code" class="pb-8 pr-6 w-full lg:w-1/2" label="Code in Number" required="true" placeholder="i.e 4"/>
          <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Barangay" class="pb-8 mt-6 pr-6 w-full lg:w-1/2"></status-toogle-button>        
        </div>
        <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
          <button v-if="!countryinfo.deleted_at && permission.edit>0" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
          <loading-button v-if="permission.edit>0" :loading="form.processing" class="btn-indigo ml-auto" type="submit"><i class="fa fa-check-circle"></i> Update Country</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, inject } from 'vue'
import axios from 'axios';
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import TrashedMessage from '@/Shared/TrashedMessage'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import DeleteIcon from '@/Shared/DeleteIcon'
import SelectInputFunction from '@/Shared/SelectInputFunction'
import Button from '@/Shared/Button'
import FileInput from '@/Shared/FileInput'
export default {
  components: {
    Head,
    Icon,
    Link,
    LoadingButton,
    SelectInput,
    TextInput,
    TrashedMessage,      
    StatusToogleButton,
    Breadcrumbs,
    DeleteIcon,
    SelectInputFunction,
    Button,
    FileInput
  },
  layout: Layout,
  props: {    
    countryinfo: Object,
    permission: Object,
  },
  remember: 'form',
  setup(props){
      const {countryinfo, permission } = toRefs(props)
      // const regions = ref([])
      const type = ref('edit')
      const title = ref(countryinfo.value.en_short_name)
      const head = ref('Country')
      const url = ref('/backend/countries')
      const createUrl = ref('')
      const swal = inject('$swal')
      const elm = ref(this)
      const form = useForm({
        num_code: countryinfo.value.num_code,
        alpha_2_code: countryinfo.value.alpha_2_code,
        alpha_3_code: countryinfo.value.alpha_3_code,
        en_short_name: countryinfo.value.en_short_name,
        nationality: countryinfo.value.nationality,
        status: countryinfo.value.status
      })      
      const update = () => {
          swal({
              title: 'Are you sure?',
              text: "You want to proceed with this action",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Confirm',
              cancelButtonText: 'Cancel',
            }).then((result) => {
              if (result.isConfirmed) {
                form.put(`${url.value}/${countryinfo.value.id}`)
                title.value = form.en_short_name
                Swal.fire(
                  'Updated!',
                  'Your infomation has been Updated.',
                  'success'
                )
              }
            });
      }
      const destroy = () => {
        swal({
            title: 'Are you sure?',
            text: "Do you really want to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.delete(`${url.value}/${countryinfo.value.id}`)
              Swal.fire(
                'Deleted!',
                'Your infomation has been deleted.',
                'success'
              )
            }
          });
      }
      const restore = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to restore this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Resore it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.put(`${url.value}/${countryinfo.value.id}/restore`)
              Swal.fire(
                'Restored!',
                'Your infomation has been Restored.',
                'success'
              )
            }
          });
      }
         
      return {
        form,        
        type,
        title,
        head,
        url,
        createUrl,
        update,
        destroy,
        restore,
        swal,
        permission
      }
    }
}
</script>
