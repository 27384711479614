<template>
    <div>
   
      <Breadcrumbs :type="type" :header="title" :url="url" :head="head" />
      
      <trashed-message v-if="mediacategory.deleted_at" class="mb-6" @restore="restore"> This mediacategory has been deleted. </trashed-message>
      <div class="max-w-2xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="update">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.category_name" :error="form.errors.category_name" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Category" required="true" placeholder="i.e "/>
            <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Slug" required="true" placeholder="i.e "/>
            <select-input v-model="form.parent_id" :error="form.errors.parent_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Parent" >
              <option value=null >Select Parent</option>
              <option v-for="mediaCategory in mediaCategorys" :key="mediaCategory.id" :value='mediaCategory.id'>{{ mediaCategory.category_name  }}</option>
            </select-input>
            <div class="pb-8 pr-6 w-full lg:w-1/2 grid grid-cols-12">
              <text-input v-model="form.icon" :error="form.errors.icon" class="col-span-11" label="Icon" placeholder="i.e 1" disabled/>
              <vue3-icon-picker v-model="form.icon" class="mt-5"/>
            </div> 
            
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Category" class="pb-8 pr-6 w-full lg:w-1/2"></status-toogle-button> 
            
            <div class="sm:col-span-2">                
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Pictute </label>
              <div class="mt-1 flex flex-wrap justify-center rounded-md border-2 border-dashed border-gray-300 dark:border-black dark:bg-white dark:text-black px-6 pt-5 pb-6">
                  <div class="space-y-1 text-center">
                    <PhotoBlock :getPhoto="form.banner_image" @SetPhoto="set_photo" />  
                  </div>
              </div>
            </div>
          </div>
          
          <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
            <!-- <button v-if="!mediacategory.deleted_at && permission.delete>0" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button> -->
            <Button v-if="permission.edit>0" :type="type" :name="head" :url="createUrl" :loading="form.processing"></Button>
            <!-- <loading-button :loading="form.processing" class="btn-indigo ml-auto" type="submit"><i class="fa fa-check-circle"></i> Update Province</loading-button> -->
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { ref, toRefs, inject  } from 'vue'
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import Icon from '@/Shared/Icon'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import TrashedMessage from '@/Shared/TrashedMessage'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import DeleteIcon from '@/Shared/DeleteIcon'
  import Button from '@/Shared/Button'
  import FileInput from '@/Shared/FileInput'
  import Vue3IconPicker from '@/Shared/components/Vue3IconPicker'
  import PhotoBlock from './Photo';
  export default {
    components: {
      Head,
      Icon,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      TrashedMessage,
      Breadcrumbs,
      DeleteIcon,
      StatusToogleButton,
      Button,
      FileInput,
      Vue3IconPicker,
      PhotoBlock
    },
    layout: Layout,
    props: {
      mediacategory: Object,
      permission: Object,
      mediacategoryGroups: Array,
    },
    remember: 'form',
    setup(props){
      const { mediacategory, mediaCategorys, permission,mediacategoryGroups } = toRefs(props)
      const type = ref('edit')
      const title = ref(mediacategory.value.category_name)
      const head = ref('Category')
      const url = ref('/backend/media-categories')
      const createUrl = ref('')
      const elm = ref(this)
      const swal = inject('$swal')
      const form = useForm({
        parent_id: mediacategory.value.parent_id,
        slug: mediacategory.value.slug,
        category_name: mediacategory.value.category_name,
        icon: mediacategory.value.icon?mediacategory.value.icon:'fas fa-upload',    
        status: mediacategory.value.status,
        banner_image:mediacategory.value.banner_image,
      }) 
      const update = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}/${mediacategory.value.id}`, {forceFormData: true,})
              title.value = form.category_name
              Swal.fire(
                'Updated!',
                'Your infomation has been Updated.',
                'success'
              )
            }
          });
      }
      const set_photo = (val) => {
            form.banner_image = val
        }
      const destroy = () => {
        swal({
            title: 'Are you sure?',
            text: "Do you really want to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.delete(`${url.value}/${mediacategory.value.id}`)
              Swal.fire(
                'Deleted!',
                'Your infomation has been deleted.',
                'success'
              )
            }
          });
      }
      const restore = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to restore this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Resore it!'
          }).then((result) => {
            if (result.isConfirmed) {
              form.put(`${url.value}/${mediacategory.value.id}/restore`)
              Swal.fire(
                'Restored!',
                'Your infomation has been Restored.',
                'success'
              )
            }
          });
      }
      return {
        form,
        mediaCategorys,
        mediacategoryGroups,
        type,
        title,
        head,
        url,
        createUrl,
        update,
        destroy,
        restore,
        swal,
        permission,
        set_photo
      }
    }
  }
  </script>
  