<template>
    <div class="flex flex-row gap-4 my-5 form_builder_block">
        <div v-if="!sel_preview_status" class="left_block">
            <FormComponentsBlock @addFormElement="add_form_element" />
        </div>
        <div :class="['content_block',{full:sel_preview_status}]">
            <div class="flex flex-row items-center header_bar_block">
                <div :class="['laptop_device',{active:sel_device_id==1}]" @click="sel_device(1)"><i class="fa fa-laptop" title="Laptop View"></i></div>
                <div :class="['mobile_device',{active:sel_device_id==2}]" @click="sel_device(2)" title="Mobile View"><i class="fa fa-mobile-screen"></i></div>
                <!-- <div class="both_device">
                    <i class="fa fa-mobile-screen _mobile"></i>
                    <i class="fa fa-laptop _laptop"></i>
                </div> -->
                <template v-if="formLayoutData.length > 0">
                    <div :class="['layout_btn ml-auto',{active:!sel_preview_status}]" @click="sel_preview(false)" title="Build Mode"><i class="fa fa-palette"></i></div>
                    <div :class="['preview_btn',{active:sel_preview_status}]" @click="sel_preview(true)" title="Preview Mode"><i class="fa fa-play"></i></div>
                </template>
            </div>
            <FormPreviewBlock v-if="sel_preview_status" :data="formLayoutData" />
            <FormLayoutBlock v-else :data="formLayoutData"
                @selInputFieldProperty="sel_input_field_property"
                @modifyFormElement="modify_form_element"
                @removeFormElement="remove_form_element"
            />
        </div>
        <div v-if="!sel_preview_status" class="right_block">
            <FieldPropertyBlock :input_type="sel_input_field_type" :index="sel_form_element_index" @addFieldProperties="add_field_properties" />
        </div>
    </div>
</template>
<script setup>
    import { ref } from 'vue'
    import FormComponentsBlock from './Components/FormComponentsBlock'
    import FormLayoutBlock from './Components/FormLayoutBlock'    
    import FormPreviewBlock from './Components/FormPreviewBlock'
    import FieldPropertyBlock from './Components/FieldPropertyBlock'

    const formLayoutData = ref([])
    const sel_device_id = ref(1)
    const sel_preview_status = ref(false)
    const sel_input_field_type = ref('')
    const sel_form_element_index = ref('')

    const sel_device = (val) => {
        sel_device_id.value = val
    }

    const sel_preview = (status) => {
        sel_preview_status.value = status
    }

    const sel_input_field_property = (type, index) => {
        sel_input_field_type.value = type
        sel_form_element_index.value = index
    }

    const add_field_properties = (data, index) => {
        //console.log(data)
        Object.keys(data).forEach(attr => {
            formLayoutData.value[index][attr] = data[attr]
        })        
    }
    
    const add_form_element = (data) => {
        formLayoutData.value.push(data)
    }

    const modify_form_element = (data,index) => {
        formLayoutData.value[index] = data
    }

    const remove_form_element = (index) => {
        formLayoutData.value.splice(index, 1)
    }
</script>
<style lang="scss" scoped>
    $left_side_width: 200;
    $right_side_width: 225;
    .form_builder_block{        
        transition: all 0.4s;
        .left_block{
            width: #{$left_side_width}px;
        }
        .content_block{
            width: calc(100% - #{($left_side_width + $right_side_width + 50)}px);
            transition: all 0.4s;
            &.full{
                width: 100%
            }

            & > .header_bar_block{
                position: sticky;
                top: 0;
                background-color: #f7f7f7;
                border: 1px solid #eee;
                padding: 5px;
                z-index: 1;
                & > div{
                    position: relative;
                    padding: 0 5px;
                    height: 22px;
                    line-height: 22px;                
                    color: #666;
                    background-color: #fff;
                    border: 1px solid #ddd;
                    font-size: 12px;
                    
                    cursor: pointer;

                    &:hover{
                        color: #1890ff;
                        border-color: #1890ff;
                    }

                    &.active{
                        color: #ccc;
                        background: #eee;                    

                        &:hover{
                            color: #ccc;
                            border-color: #ddd;
                            cursor: auto;
                        }
                    }

                    // &.both_device{                    
                    //     background-color: #eee;
                    //     width: 36px;

                    //     & > i{
                    //         top: 6px;
                    //         left: 20%;
                    //         position: absolute;
                    //         background-color: #eee;
                    //         z-index: 1;
                    //         font-size: 10px;
                    //         &._laptop{
                    //             z-index: 2;
                    //         }
                    //     }
                    // }
                }
            }
        }
        .right_block{
            width: #{$right_side_width}px;
        }
    }
</style>