<template>
    <div class="profile_edit_content_block">
        <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
        <trashed-message v-if="host.deleted_at" class="mb-6" @restore="restore"> This host has been deleted.
        </trashed-message>
        <div class="w-full bg-white rounded-md shadow overflow-hidden edit_form_block">
            <form @submit.prevent="update">
                <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 mt-3 p-4">
                    <div class="sm:col-span-3">
                        <div class="mt-1 flex items-center h-[225px]">
                           
                            <div
                                class="mt-1 flex flex-wrap justify-center rounded-md border-2 border-dashed border-gray-300 dark:border-black dark:bg-white dark:text-black px-6 pt-5 pb-6">
                                <div class="space-y-1 text-center">
                                    <PhotoBlock :getPhoto="form.image_path" @SetPhoto="set_photo" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sm:col-span-3"></div>
                    <text-input v-model="form.full_name" :error="form.errors.full_name" class="sm:col-span-2" label="Name"
                        required="true" placeholder="i.e. MOHAMMAD Kabir Hasan" />
                    <text-input v-model="form.email" :error="form.errors.email" class="sm:col-span-2" label="Email"
                        :type="email" required="true" placeholder="i.e example@test.com" />
                    <text-input v-model="form.mobile" :error="form.errors.mobile" class="sm:col-span-2" label="Mobile"
                        required="true" placeholder="i.e +63-2-1234-5678" />
                    <text-input v-model="form.registration_number" :error="form.errors.registration_number"
                        class="sm:col-span-2" label="Registration Number" placeholder="i.e. 2332224" />
                    <select-input v-model="form.owner_id" :error="form.errors.owner_id" class="sm:col-span-2" label="Owner" >
                        <option :value=null>Select Owner</option>
                        <option v-for="owner in owners" :key="owner.id" :value='owner.id'>{{ owner.name }}</option>
                    </select-input>
                    <select-input v-model="form.language" :error="form.errors.language" class="sm:col-span-2"
                        label="Language">
                        <option :value=null>Select Language</option>
                        <option v-for="language in languages" :key="language.id" :value='language.id'>{{ language.lang_title
                        }}</option>
                    </select-input>
                    <text-input v-model="form.response_rate" :error="form.errors.response_rate" class="sm:col-span-2"
                        label="Response Rate" type="number" oninput="this.value = Math.abs(this.value)" min="0"
                        placeholder="i.e. 100" />
                    <text-input v-model="form.response_time" :error="form.errors.response_time" class="sm:col-span-2"
                        label="Response Time" type="number" oninput="this.value = Math.abs(this.value)" min="0"
                        placeholder="i.e. 60" />
                    <text-input v-model="form.joining_date" :error="form.errors.joining_date" class="sm:col-span-2"
                        label="Joining Date" type="date" placeholder="i.e. " />
                    <textarea-input v-model="form.bio" :error="form.errors.bio" class="sm:col-span-2" label="Bio"
                        placeholder="i.e " />
                    <status-toogle-button v-model="form.verified" :error="form.errors.verified" label="Verified "
                        class="sm:col-span-2 "></status-toogle-button>
                    <status-toogle-button v-model="form.super_host" :error="form.errors.super_host" label="Is Superhost "
                        class="sm:col-span-2"></status-toogle-button>
                    <status-toogle-button v-model="form.status" :error="form.errors.status" label="Host Status"
                        class="sm:col-span-2 "></status-toogle-button>
                    <div class="sm:col-span-6">
                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Present Address</label>
                        <div class="mt-1 rounded-md border border-gray-300 p-3">
                            <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                <div class="sm:col-span-2">
                                    <label
                                        class="block text-sm font-medium text-gray-700 dark:text-gray-400">Country</label>
                                    <div class="mt-1">
                                        <select v-model="form.pre_country_id"
                                            class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                            @change="check_pre_country">
                                            <option value="">Choose One</option>
                                            <option v-for="country in countries" :key="country.id" :value='country.id'>{{
                                                country.en_short_name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="sm:col-span-4">
                                    <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Street
                                        Address</label>
                                    <div class="mt-1">
                                        <input type="text"
                                            class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                            v-model="form.pre_address" placeholder="">
                                    </div>
                                </div>
                                <template v-if="sel_default_pre_country">
                                    <div class="sm:col-span-2">
                                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose
                                            Region</label>
                                        <div class="mt-1">
                                            <!-- <pre>{{ form }}</pre> -->
                                            <select v-model="form.pre_region_id"
                                                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                                @change="load_province(1)">
                                                <option value=null>Choose One</option>
                                                <option v-for="region in regions" :value="region.id">{{ region.region_name
                                                }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="sm:col-span-2">
                                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose
                                            Province</label>
                                        <div class="mt-1">
                                            <!-- <pre>{{ form }}</pre> -->
                                            <select v-model="form.pre_province_id"
                                                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                                @change="load_municipality(1)">
                                                <option value=null>Choose One</option>
                                                <option v-for="province in pre_province_list" :value="province.id">{{
                                                    province.provience_name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="sm:col-span-2">
                                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose
                                            Municipality</label>
                                        <div class="mt-1">
                                            <select v-model="form.pre_municipality_id"
                                                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                                @change="load_barangay(1)">
                                                <option value=null>Choose One</option>
                                                <option v-for="municipality in pre_municipality_list"
                                                    :value="municipality.id">{{ municipality.municipality_name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="sm:col-span-2">
                                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose
                                            Barangay</label>
                                        <div class="mt-1">
                                            <select v-model="form.pre_barangay_id"
                                                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm">
                                                <option value=null>Choose One</option>
                                                <option v-for="barangay in pre_barangay_list" :value="barangay.id">{{
                                                    barangay.barangay_name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="sm:col-span-2">
                                        <label
                                            class="block text-sm font-medium text-gray-700 dark:text-gray-400">City</label>
                                        <div class="mt-1">
                                            <input type="text"
                                                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                                v-model="form.pre_city" placeholder="" />
                                        </div>
                                    </div>
                                    <div class="sm:col-span-2">
                                        <label
                                            class="block text-sm font-medium text-gray-700 dark:text-gray-400">State/Province</label>
                                        <div class="mt-1">
                                            <input type="text"
                                                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                                v-model="form.pre_state" placeholder="">
                                        </div>
                                    </div>
                                </template>

                            </div>
                        </div>
                    </div>
                    <div class="sm:col-span-6">
                        <status-toogle-button v-model="form.same_as_pre_address" :error="form.errors.same_as_pre_address"
                            label="Same as Present Address" class="sm:col-span-2 mb-3"></status-toogle-button>
                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Permanent Address</label>
                        <div class="mt-1 rounded-md border border-gray-300 p-3">
                            <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                <div class="sm:col-span-2">
                                    <label
                                        class="block text-sm font-medium text-gray-700 dark:text-gray-400">Country</label>
                                    <div class="mt-1">
                                        <select v-model="form.per_country_id"
                                            class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                            @change="check_per_country">
                                            <option value="">Choose One</option>
                                            <option v-for="country in countries" :key="country.id" :value='country.id'>{{
                                                country.en_short_name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="sm:col-span-4">
                                    <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Street
                                        Address</label>
                                    <div class="mt-1">
                                        <input type="text"
                                            class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                            v-model="form.per_address" placeholder="">
                                    </div>
                                </div>
                                <template v-if="sel_default_per_country">
                                    <div class="sm:col-span-2">
                                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose
                                            Region</label>
                                        <div class="mt-1">

                                            <select v-model="form.per_region_id"
                                                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                                @change="load_province(2)">
                                                <option value=null>Choose One</option>
                                                <option v-for="region in regions" :value="region.id">{{ region.region_name
                                                }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="sm:col-span-2">
                                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose
                                            Province</label>
                                        <div class="mt-1">
                                            <select v-model="form.per_province_id"
                                                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                                @change="load_municipality(2)">
                                                <option value=null>Choose One</option>
                                                <option v-for="province in per_province_list" :value="province.id">{{
                                                    province.provience_name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="sm:col-span-2">
                                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose
                                            Municipality</label>
                                        <div class="mt-1">
                                            <select v-model="form.per_municipality_id"
                                                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                                @change="load_barangay(2)">
                                                <option value=null>Choose One</option>
                                                <option v-for="municipality in per_municipality_list"
                                                    :value="municipality.id">{{ municipality.municipality_name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="sm:col-span-2">
                                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose
                                            Barangay</label>
                                        <div class="mt-1">
                                            <select v-model="form.per_barangay_id"
                                                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm">
                                                <option value=null>Choose One</option>
                                                <option v-for="barangay in per_barangay_list" :value="barangay.id">{{
                                                    barangay.barangay_name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="sm:col-span-2">
                                        <label
                                            class="block text-sm font-medium text-gray-700 dark:text-gray-400">City</label>
                                        <div class="mt-1">
                                            <input type="text"
                                                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                                v-model="form.per_city" placeholder="" />
                                        </div>
                                    </div>
                                    <div class="sm:col-span-2">
                                        <label
                                            class="block text-sm font-medium text-gray-700 dark:text-gray-400">State/Province</label>
                                        <div class="mt-1">
                                            <input type="text"
                                                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                                v-model="form.per_state" placeholder="">
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
                    <Button v-if="permission.edit > 0" :type="type" :name="head" :url="createUrl"
                        :loading="form.processing"></Button>
                </div>
            </form>
        </div>
        <!-- <pre>
        {{ form }}
      </pre> -->
    </div>
</template>
  
<script>
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject, onMounted, watch } from 'vue'
import axios from 'axios';
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import TextareaInput from '@/Shared/TextareaInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import TrashedMessage from '@/Shared/TrashedMessage'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import DeleteIcon from '@/Shared/DeleteIcon'
import Button from '@/Shared/Button'
import PhotoBlock from './Photo';
export default {
    components: {
        Head,
        Icon,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        TrashedMessage,
        StatusToogleButton,
        Breadcrumbs,
        DeleteIcon,
        Button,
        TextareaInput,
        PhotoBlock
    },
    layout: Layout,
    props: {
        host: Object,
        regions: Array,
        countries: Array,
        languages: Array,
        permission: Object,
        owners: Array,
    },
    remember: 'form',
    setup(props) {
        const { host, permission, regions, countries, languages ,owners } = toRefs(props)
        const type = ref('edit')
        const title = ref(host.value.full_name)
        const head = ref('Host')
        const url = ref('/backend/hosts')
        const createUrl = ref('')
        const swal = inject('$swal')
        const elm = ref(this)
        const form = useForm({
            full_name: host.value.full_name,
            email: host.value.email,
            mobile: host.value.mobile,
            bio: host.value.bio,
            status: host.value.status,
            pre_region_id: host.value.pre_region_id,
            pre_province_id: host.value.pre_province_id,
            pre_municipality_id: host.value.pre_municipality_id,
            pre_barangay_id: host.value.pre_barangay_id,
            per_region_id: host.value.per_region_id,
            per_province_id: host.value.per_province_id,
            per_municipality_id: host.value.per_municipality_id,
            per_barangay_id: host.value.per_barangay_id,
            pre_country_id: host.value.pre_country_id ? host.value.pre_country_id : 175,
            per_country_id: host.value.per_country_id ? host.value.per_country_id : 175,
            verified: host.value.verified,
            registration_number: host.value.registration_number,
            language: host.value.language,
            response_rate: host.value.response_rate,
            response_time: host.value.response_time,
            super_host: host.value.super_host,
            joining_date: host.value.joining_date,
            pre_address: host.value.pre_address,
            per_address: host.value.per_address,
            same_as_pre_address: host.value.same_as_pre_address,
            image_path: host.value.image_path,
            owner_id: host.value.owner_id,
        })
        const update = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.put(`${url.value}/${host.value.id}`)
                    title.value = form.full_name
                    swal.fire(
                        'Updated!',
                        'Your infomation has been Updated.',
                        'success'
                    )
                }
            });
        }
        const destroy = () => {
            swal({
                title: 'Are you sure?',
                text: "Do you really want to delete this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    form.delete(`${url.value}/${host.value.id}`)
                    swal.fire(
                        'Deleted!',
                        'Your infomation has been deleted.',
                        'success'
                    )
                }
            });
        }
        const restore = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to restore this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Resore it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    form.put(`${url.value}/${host.value.id}/restore`)
                    swal.fire(
                        'Restored!',
                        'Your infomation has been Restored.',
                        'success'
                    )
                }
            });
        }

        const pre_province_list = ref([])
        const per_province_list = ref([])
        const load_province = async (req_for, onload = null) => {
            if (req_for == 2) per_province_list.value = []
            else pre_province_list.value = []
            let data = {
                geo_region_id: req_for == 2 ? form.per_region_id : form.pre_region_id
            }
            await axios.post('../../provinces/activeprovinces', data)
                .then(function (response) {
                    if (req_for == 2) {
                        per_province_list.value = response.data
                        if (onload == null) {
                            form.per_province_id = null
                            form.per_municipality_id = null
                            form.per_barangay_id = null
                        }
                        if (form.per_province_id) load_municipality(2, onload)

                    } else {
                        pre_province_list.value = response.data
                        if (onload == null) {
                            form.pre_province_id = null
                            form.pre_municipality_id = null
                            form.pre_barangay_id = null
                        }

                        if (form.pre_province_id) load_municipality(1, onload)
                    }
                }.bind(this));

        }
        const pre_municipality_list = ref([])
        const per_municipality_list = ref([])
        const load_municipality = async (req_for, onload = null) => {
            if (req_for == 2) per_municipality_list.value = []
            else pre_municipality_list.value = []
            let data = {
                geo_provience_id: req_for == 2 ? form.per_province_id : form.pre_province_id
            }
            await axios.post('../../municipalities/activemunicipalities', data)
                .then(function (response) {
                    if (req_for == 2) {
                        if (onload == null) {
                            form.per_municipality_id = null
                            form.per_barangay_id = null
                        }
                        per_municipality_list.value = response.data
                        if (form.per_municipality_id) load_barangay(2, onload)
                    } else {
                        if (onload == null) {
                            form.pre_municipality_id = null
                            form.pre_barangay_id = null
                        }
                        pre_municipality_list.value = response.data
                        if (form.pre_municipality_id) load_barangay(1, onload)
                    }
                }.bind(this));
        }

        const pre_barangay_list = ref([])
        const per_barangay_list = ref([])
        const load_barangay = async (req_for, onload = null) => {
            if (req_for == 2) per_barangay_list.value = []
            else pre_barangay_list.value = []
            let data = {
                geo_municipality_id: req_for == 2 ? form.per_municipality_id : form.pre_municipality_id
            }
            await axios.post('../../barangays/activebarangays', data)
                .then(function (response) {
                    if (req_for == 2) {
                        if (onload == null) {
                            form.per_barangay_id = null
                        }
                        per_barangay_list.value = response.data
                    } else {
                        if (onload == null) {
                            form.pre_barangay_id = null
                        }
                        pre_barangay_list.value = response.data
                    }
                }.bind(this));
        }

        const sel_default_pre_country = ref(false)
        const check_pre_country = () => {
            if (form.pre_country_id == 175) {
                sel_default_pre_country.value = true
            } else {
                sel_default_pre_country.value = false
                pre_province_list.value = []
            }
        }

        const sel_default_per_country = ref(false)
        const check_per_country = () => {
            if (form.per_country_id == 175) {
                sel_default_per_country.value = true
            } else {
                sel_default_per_country.value = false
                per_province_list.value = []
            }
        }
        const sameAsPresentAddress = () => {
            if (form.same_as_pre_address == 1) {
                per_province_list.value = pre_province_list.value
                per_municipality_list.value = pre_municipality_list.value
                per_barangay_list.value = pre_barangay_list.value
                form.per_country_id = form.pre_country_id
                form.per_state = form.pre_state
                form.per_region_id = form.pre_region_id
                form.per_province_id = form.pre_province_id
                form.per_municipality_id = form.pre_municipality_id
                form.per_barangay_id = form.pre_barangay_id
                form.per_address = form.pre_address
            }

        }

        const set_photo = (val) => {
            form.image_path = val
        }
        watch(() => form, () => {
            sameAsPresentAddress()
        }, { deep: true })

        onMounted(() => {
            check_pre_country()
            check_per_country()
            load_province(1, 1)
            load_province(2, 1)
            // sameAsPresentAddress()           
        })

        return {
            form,
            host,
            type,
            title,
            head,
            url,
            createUrl,
            update,
            destroy,
            restore,
            swal,
            permission,
            regions,
            countries,
            languages,
            permission,
            pre_province_list,
            per_province_list,
            load_province,
            pre_municipality_list,
            per_municipality_list,
            load_municipality,
            pre_barangay_list,
            per_barangay_list,
            load_barangay,
            sel_default_pre_country,
            check_pre_country,
            sel_default_per_country,
            check_per_country,
            sameAsPresentAddress,
            set_photo,
            owners
        }
    }
}
</script>
<style lang="scss" scoped>$button_height: 36;

.profile_edit_content_block {
    display: block;

    .edit_form_block {
        background-color: #ffffff80;

        input,
        textarea,
        select {
            width: 100%;
            border: 1px solid #ddd;
            padding: 5px 10px;
            border-radius: 5px;
        }

        i {
            display: none;
            width: 30px;
            text-align: center;
            transition: all 0.4s;
            color: #888;
            position: absolute;
            margin-left: 265px !important;
            margin-top: -23px;

            &.active {
                display: block;
            }
        }

        .err_msg {
            border-color: #CD0000 !important
        }
    }
}

.save_btn,
.cancel_btn {
    height: #{$button_height}px;
    background-color: #0f8050;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;

    &.cancel_btn {
        border: 1px solid #CD0000;
        color: #CD0000;
        background-color: #fff;
    }
}


.dark {
    .profile_edit_content_block {

        input,
        textarea,
        select {
            background-color: #222;
        }
    }
}

.password_block,
.confirm_password_block {
    p {
        align-items: baseline;
        color: #666;
        display: flex;
        gap: 5px;
        font-size: 10px !important;

        span {
            color: gold !important;
            font-size: 10px !important;
        }
    }
}</style>
  