<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>

        <trashed-message v-if="citizen_profile.deleted_at" class="mb-6" @restore="restore"> This Data has been deleted.
        </trashed-message>
        <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
            <form @submit.prevent="update">
                <div class="flex flex-wrap -mb-8 -mr-6 p-8">
                    <text-input v-model="form.title" :error="form.errors.title" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Title" required="true" placeholder="i.e First Name" />
                    <text-input v-model="form.name" :error="form.errors.name" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Name Of Field" required="true" placeholder="i.e first_name" />
                    <status-toogle-button v-model="form.required" :error="form.errors.required" label="Required Status"
                        class="pb-8 mt-6 pr-6 w-full lg:w-1/2"></status-toogle-button>
                    <status-toogle-button v-model="form.can_edit" :error="form.errors.can_edit" label="Can Edit Field "
                        class="pb-8 mt-6 pr-6 w-full lg:w-1/2"></status-toogle-button>
                    <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Data"
                        class="pb-8 mt-6 pr-6 w-full lg:w-1/2"></status-toogle-button>
                </div>
                <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
                    <button v-if="!citizen_profile.deleted_at && permission.edit > 0" class="text-red-600 hover:underline"
                        tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
                    <loading-button v-if="permission.edit > 0" :loading="form.processing" class="btn-indigo ml-auto"
                        type="submit"><i class="fa fa-check-circle"></i> Update Citizen Profile Update Field</loading-button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { ref, toRefs, inject } from 'vue'
import axios from 'axios';
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import TrashedMessage from '@/Shared/TrashedMessage'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import DeleteIcon from '@/Shared/DeleteIcon'
import SelectInputFunction from '@/Shared/SelectInputFunction'
import Button from '@/Shared/Button'
import FileInput from '@/Shared/FileInput'
export default {
    components: {
        Head,
        Icon,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        TrashedMessage,
        StatusToogleButton,
        Breadcrumbs,
        DeleteIcon,
        SelectInputFunction,
        Button,
        FileInput
    },
    layout: Layout,
    props: {
        citizen_profile: Object,
        permission: Object,
    },
    remember: 'form',
    setup(props) {
        const { citizen_profile, permission } = toRefs(props)
        // const regions = ref([])
        const type = ref('edit')
        const title = ref(citizen_profile.value.title)
        const head = ref('Citizen Profile Update Field')
        const url = ref('/backend/citizen-profiles')
        const createUrl = ref('')
        const swal = inject('$swal')
        const elm = ref(this)
        const form = useForm({
            title: citizen_profile.value.title,
            required: citizen_profile.value.required,
            can_edit: citizen_profile.value.can_edit,
            name: citizen_profile.value.name,
            status: citizen_profile.value.status
        })
        const update = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.put(`${url.value}/${citizen_profile.value.id}`)
                    title.value = form.title
                    swal.fire(
                        'Updated!',
                        'Your infomation has been Updated.',
                        'success'
                    )
                }
            });
        }
        const destroy = () => {
            swal({
                title: 'Are you sure?',
                text: "Do you really want to delete this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    form.delete(`${url.value}/${citizen_profile.value.id}`)
                    swal.fire(
                        'Deleted!',
                        'Your infomation has been deleted.',
                        'success'
                    )
                }
            });
        }
        const restore = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to restore this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Resore it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    form.put(`${url.value}/${citizen_profile.value.id}/restore`)
                    swal.fire(
                        'Restored!',
                        'Your infomation has been Restored.',
                        'success'
                    )
                }
            });
        }

        return {
            form,
            type,
            title,
            head,
            url,
            createUrl,
            update,
            destroy,
            restore,
            swal,
            permission
        }
    }
}
</script>
