<template>
    <div class="office_layer_map_block">
        <ContentHeaderSection :title="'Office Layer Setup'" :icon="'fa fa-cogs'" />
        <div class="p-5 office_layer_setup_block">
            <div v-for="office_layer in officelayers" class="mb-4">
                <div :class="['checkbox_block',{active:sel_layers[office_layer.id]}]" tabindex="0"
                    @mouseover="layer_sel_item(office_layer.id,'hover')"
                    @mouseout="layer_sel_item(office_layer.id,'out')"
                    @click="layer_sel_item(office_layer.id,'add')"
                >
                    <i :class="['fa','fa-times',{remove:sel_layers[office_layer.id]}]"></i>
                    <i class="fa fa-check checked"></i>
                </div>
                <div :class="['info_block',{active:sel_layers[office_layer.id]}]">{{ office_layer.office_layer_name }}</div>
            </div>
            <!-- <pre>{{ sel_layers }}</pre> -->
            <!-- <pre>{{ layermap }}</pre> -->
        </div>
        <!-- <FloatingActionBtn v-if="((Object.keys(sel_layers).length > 0) || layermap.length > 0)" :loader="preloader" :icon="'fa fa-check'" :message="message" @dataSubmit="submit_data" @removeMessage="remove_msg" /> -->
    </div>
</template>
<script>
    import axios from "axios"
    import { ref, toRefs } from "vue"
    import ContentHeaderSection from "@/Shared/ContentHeaderSection"
    import FloatingActionBtn from "@/Shared/Buttons/FloatingActionBtn"

    export default {
        name: "OfficeLayerBlock",
        props: {
            officelayers: Array,
            layermap: Array,
            office_id: Number
        },
        components: {
            ContentHeaderSection,
            FloatingActionBtn
        },
        setup(props, { emit }){
            const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
            const { officelayers, layermap, office_id } = toRefs(props)
            const preloader = ref(false)
            const h_layer_id = ref('')
            const sel_layers = ref({})
            const message = ref('')
            
            if(layermap.value && layermap.value.length > 0){
                layermap.value.forEach(v => {
                    sel_layers.value[v.id] = true
                })
            }

            const layer_sel_item = (id, req_type) => {
                if(req_type=='hover') h_layer_id.value = id
                else if(req_type=='out') h_layer_id.value = ''
                else {
                    if(sel_layers.value[id]) delete sel_layers.value[id] 
                    else sel_layers.value[id] = true

                    emit('getLayers', sel_layers.value)
                }                
            }

            const submit_data = () => {
                //console.log('Office layer data', sel_layers)
                let formData = {
                    office_id: office_id.value,
                    office_layers: sel_layers.value
                }
                preloader.value = true
                axios.post(`/${backendPrefix.value}/office-layer-map`, {
                    data: formData
                }).then( async (response) => {
                    //console.log(response)
                    message.value = response.data.message
                    preloader.value = false
                }).catch(error => {
                    preloader.value = false
                    //console.log(error.response.data.error)
                })
            }

            const remove_msg = () => {                
                message.value = ''
            }

            return {
                officelayers,
                layermap,
                h_layer_id,
                sel_layers,
                preloader,
                message,
                layer_sel_item,
                submit_data,
                remove_msg
            }
        }
    }
</script>
<style lang="scss" scoped>
    $checkbox_size: 20;
    .office_layer_map_block{
        display: block;
        position: relative;
        // padding-bottom: 50px;
        .office_layer_setup_block{
            display: block;

            & > div{
                display: flex;
                gap: 10px;
                align-items: center;
                .checkbox_block{
                    display: inline-block;
                    width: #{$checkbox_size}px; height: #{$checkbox_size}px;
                    line-height: #{$checkbox_size}px; text-align: center; 
                    font-size: 10px;
                    border: 1px solid #ddd;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: all 0.4s;

                    & > i{
                        display: none;
                        opacity: 0; line-height: #{$checkbox_size}px;
                        transition: all 0.4s;
                    }

                    &:hover{
                        color: #ccc;
                        border-color: #ccc;

                        & > i.checked{
                            display: block;
                            opacity: 1;
                        }
                    }
                    &.active{
                        color: #006699;
                        border-color: #006699;
                        & > i.checked{
                            display: block;
                            opacity: 1;
                        }

                        &:hover{
                            color: #CD0000;
                            border-color: #CD0000;
                            & > i.checked{
                                display: none;
                                opacity: 0
                            }
                            & > i.remove{
                                display: block;
                                opacity: 1;
                            }
                        }
                    }
                }
                .info_block{
                    &.active{
                        font-weight: 600;
                    }
                }
            }
        }        
    }
</style>